import { RequestCleaningGuard } from './service/request-cleaning.guard';
import { CreateRequestCleaningComponent } from './views/manager-service/cleaning-service-manager/create-request-cleaning/create-request-cleaning.component';
import { ResidentServiceManagerComponent } from './views/resident-service-manager/resident-service-manager.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { FunctionComponent } from './views/system-manager/function/function.component';
import { RoleComponent } from './views/system-manager/role/role.component';
import { UserComponent } from './views/system-manager/user/user.component';
import { ProjectComponent } from './views/project-category/project/project.component';
import { TowerComponent } from './views/project-category/tower/tower.component';
import { ApartmentComponent } from './views/project-category/apartment/apartment.component';
import { FloorComponent } from './views/project-category/floor/floor.component';
import { ResidentsComponent } from './views/project-category/residents/residents.component';
import { TypeAttributeComponent } from './views/common/type-attribute/type-attribute.component';
import { DepartmentsComponent } from './views/manager-category/departments/departments.component';
import { PositionsComponent } from './views/manager-category/positions/positions.component';
import { EmployeesComponent } from './views/manager-category/employees/employees.component';
import { CountryComponent } from './views/common/country/country.component';
import { ProvinceComponent } from './views/common/province/province.component';
import { DistrictComponent } from './views/common/district/district.component';
import { WardComponent } from './views/common/ward/ward.component';
import { ContactsComponent } from './views/common/contacts/contacts.component';
import { HotlineComponent } from './views/common/hotline/hotline.component';
import { RequireSupportComponent } from './views/require-support-manager/require-support/require-support.component';
import { RequireSupportHistoryComponent } from './views/require-support-manager/require-support-history/require-support-history.component';
import { NotebookCategoryComponent } from './views/notebook-manager/notebook-category/notebook-category.component';
import { NotebookComponent } from './views/notebook-manager/notebook/notebook.component';
import { UtilityComponent } from './views/utility/utility.component';
import { BannerComponent } from './views/manager-category/banner/banner.component';
import { OrderComponent } from './views/payment/order/order.component';
import { SearchFeeComponent } from './views/payment/search-fee/search-fee.component';
import { CarParkingComponent } from './views/car-parking-manager/car-parking/car-parking.component';
import { CarParkingUpdateComponent } from './views/car-parking-manager/car-parking-update/car-parking-update.component';
import { CarParkingCancelComponent } from './views/car-parking-manager/car-parking-cancel/car-parking-cancel.component';
import { ServicePlusComponent } from './views/service-plus-manager/service-plus/service-plus.component';
import { ServicePlusMappingComponent } from './views/service-plus-manager/service-plus-mapping/service-plus-mapping.component';
import { SearchPaymentComponent } from './views/payment/search-payment/search-payment.component';

import { AuthGuard } from './auth.guard';
import { UserManagementComponent } from './views/system-manager/user-management/user-management.component';
import { UserResidentComponent } from './views/system-manager/user-resident/user-resident.component';
import { NotificationComponent } from './views/notification-manager/notification/notification.component';
import { NotificationTempComponent } from './views/notification-manager/notification-temp/notification-temp.component';
import { NotificationHistoryComponent } from './views/notification-manager/notification-history/notification-history.component';
import { ReportSupportComponent } from './views/report/report-support/report-support.component';
import { NotificationFeeComponent } from './views/notification-manager/notification-fee/notification-fee.component';
import { ReportCashComponent } from './views/report/report-cash/report-cash.component';
import { ReportSmsComponent } from './views/report/report-sms/report-sms.component';
import { EditResidentServiceComponent } from './views/resident-service-manager/edit-resident-service/edit-resident-service.component';
import { ConfigPaymentComponent } from './views/payment/config-payment/config-payment.component';
import { EditCardChangeInfoComponent } from './views/resident-service-manager/edit-card-change-info/edit-card-change-info.component';
import { EditCardCancelComponent } from './views/resident-service-manager/edit-card-cancel/edit-card-cancel.component';
import { EditCardRenewComponent } from './views/resident-service-manager/edit-card-renew/edit-card-renew.component';
import { TransferGoodsComponent } from './views/transfer-goods/transfer-goods.component';
import { NewTransferGoodsComponent } from './views/transfer-goods/new-transfer-goods/new-transfer-goods.component';
import { ConfigRateComponent } from './views/manager-category/config-rate/config-rate.component';
import { ServiceTechComponent } from './views/manager-service/service-tech/service-tech.component';
import { ServiceCategoryComponent } from './views/manager-category/service-category/service-category.component';
import { NewServiceTechComponent } from './views/manager-service/service-tech/new-service-tech/new-service-tech.component';
import { AcceptanceConstructionComponent } from './views/register-construction/acceptance/acceptance.component';
import { ContractorComponent } from './views/register-construction/contractor/contractor.component';
import { ExtensionConstructionComponent } from './views/register-construction/extension-construction/extension-construction.component';
import { RegisterConstructionFormComponent } from './views/register-construction/register-construction-form/register-construction-form.component';
import { RegisterConstructionComponent } from './views/register-construction/register-construction.component';
import { RegistrationTermComponent } from './views/manager-category/registration-term/registration-term.component';
import { RentalApartmentComponent } from './views/manager-service/rental-apartment/rental-apartment.component';
import { SlaConfigComponent } from './views/common/sla-config/sla-config.component';
import { ActionRentalApartmentComponent } from './views/manager-service/rental-apartment/action-rental-apartment/action-rental-apartment.component';
import { ConfigRentalApartmentComponent } from './views/manager-service/rental-apartment/config-rental-apartment/config-rental-apartment.component';
import { CleaningServiceManagerComponent } from './views/manager-service/cleaning-service-manager/cleaning-service-manager.component';
import { CleaningServiceCategoryComponent } from './views/manager-category/cleaning-service-category/cleaning-service-category.component';
import { OrderServiceUtilitiesComponent } from './views/payment/order-service-utilities/order-service-utilities.component';
import { ConfigUtilitiesComponent } from './views/system-manager/config-utilities/config-utilities.component';
import { LogSystemComponent } from './views/log-system/log-system.component';
import { DetailRequestCleaningComponent } from './views/manager-service/cleaning-service-manager/detail-request-cleaning/detail-request-cleaning.component';
import { TechnicalServiceManagerComponent } from './views/manager-service/technical-service-manager/technical-service-manager.component';
import { DetailRequestTechnicalComponent } from './views/manager-service/technical-service-manager/detail-request-technical/detail-request-technical.component';
import { RequestTechnicalGuard } from './service/request-technical.guard';
import { CreateRequestTechnicalComponent } from './views/manager-service/technical-service-manager/create-request-technical/create-request-technical.component';
import { EditCardLockComponent } from './views/resident-service-manager/edit-card-lock/edit-card-lock.component';
import { EditCardUnLockComponent } from './views/resident-service-manager/edit-card-unlock/edit-card-unlock.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Đăng nhập'
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: ''
    },
    children:
      [
        {
          path: 'dashboard',
          component: DashboardComponent,
          canActivate: [AuthGuard],
          data: {
            title: ''
          }
        },
        {
          path: 'utilities/resident-service',
          component: ResidentServiceManagerComponent,
          data: {
            title: 'Đăng ký thẻ/cư dân về ở'
          }
        },
        {
          path: 'utilities/resident-service/request-group-registration/:id',
          component: EditResidentServiceComponent,
          data: {
            title: 'Chi tiết đăng ký cấp mới'
          }
        },
        {
          path: 'utilities/resident-service/request-group-registration',
          component: EditResidentServiceComponent,
          data: {
            title: 'Đăng ký cấp mới'
          }
        },
        {
          path: 'utilities/resident-service/request-group-change/:id',
          component: EditCardChangeInfoComponent,
          data: {
            title: 'Chi tiết thay đổi thông tin thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-change',
          component: EditCardChangeInfoComponent,
          data: {
            title: 'Đăng ký thay đổi thông tin thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-cancel/:id',
          component: EditCardCancelComponent,
          data: {
            title: 'Chi tiết đăng ký hủy thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-cancel',
          component: EditCardCancelComponent,
          data: {
            title: 'Đăng ký hủy thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-renew/:id',
          component: EditCardRenewComponent,
          data: {
            title: 'Chi tiết đăng ký cấp lại thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-renew',
          component: EditCardRenewComponent,
          data: {
            title: 'Đăng ký cấp lại thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-lock/:id',
          component: EditCardLockComponent,
          data: {
            title: 'Chi tiết đăng ký khóa thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-lock',
          component: EditCardLockComponent,
          data: {
            title: 'Đăng ký khóa thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-unlock/:id',
          component: EditCardUnLockComponent,
          data: {
            title: 'Chi tiết đăng ký mở thẻ'
          }
        },
        {
          path: 'utilities/resident-service/request-group-unlock',
          component: EditCardUnLockComponent,
          data: {
            title: 'Đăng ký mở thẻ'
          }
        },
        {
          path: 'manager-service/cleaning-service',
          component: CleaningServiceManagerComponent,
          data: {
            title: 'Danh sách đơn đăng ký dịch vụ vệ sinh'
          }
        },
        {
          path: 'manager-service/registration-cleaning/create',
          component: CreateRequestCleaningComponent,
          canActivate: [RequestCleaningGuard],
          data: {
            title: 'Đăng ký dịch vụ vệ sinh',
            typeUser: 'Admin'
          }
        },
        {
          path: 'manager-service/registration-cleaning/detail/:id',
          component: DetailRequestCleaningComponent,
          canActivate: [RequestCleaningGuard],
          data: {
            title: 'Chi tiết đăng ký dịch vụ vệ sinh',
            typeUser: 'All'
          }
        },
        {
          path: 'manager-service/registration-technical/create',
          component: CreateRequestTechnicalComponent,
          canActivate: [RequestTechnicalGuard],
          data: {
            title: 'Chi tiết đăng ký dịch vụ kỹ thuật',
            typeUser: 'All'
          }
        },
        {
          path: 'manager-service/registration-technical/detail/:id',
          component: DetailRequestTechnicalComponent,
          canActivate: [RequestTechnicalGuard],
          data: {
            title: 'Chi tiết đăng ký dịch vụ kỹ thuật',
            typeUser: 'All'
          }
        },
        {
          path: 'config-category-service/config-cleaning-category',
          component: CleaningServiceCategoryComponent,
          data: {
            title: 'Quản lý cấu hình loại dịch vụ vệ sinh'
          }
        },
        {
          path: 'system/function',
          component: FunctionComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý chức năng'
          }
        },
        {
          path: 'system/role',
          component: RoleComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý phân quyền'
          }
        },
        {
          path: 'system/user',
          component: UserComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Người dùng'
          }
        },
        {
          path: 'system/user-management',
          component: UserManagementComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Người dùng BQL'
          }
        },
        {
          path: 'system/config-utilities',
          component: ConfigUtilitiesComponent,
          //canActivate: [AuthGuard],
          data: {
            title: 'Cấu hình dịch vụ - tiện ích'
          }
        },
        {
          path: 'system/user-resident',
          component: UserResidentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Người dùng cư dân'
          }
        },
        {
          path: 'project-category/projects',
          component: ProjectComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý dự án'
          }
        },
        {
          path: 'project-category/towers',
          component: TowerComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý tòa nhà'
          }
        },
        {
          path: 'project-category/floors',
          component: FloorComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý tầng'
          }
        },
        {
          path: 'project-category/apartments',
          component: ApartmentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý căn hộ'
          }
        },
        {
          path: 'project-category/residents',
          component: ResidentsComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý cư dân'
          }
        },
        {
          path: 'common/countries',
          component: CountryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý quốc gia'
          }
        },
        {
          path: 'common/provinces',
          component: ProvinceComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý tỉnh/thành phố'
          }
        },
        {
          path: 'common/districts',
          component: DistrictComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý quận/huyện'
          }
        },
        {
          path: 'common/wards',
          component: WardComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý phường/xã'
          }
        },
        {
          path: 'manager-category/contacts',
          component: ContactsComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lí danh bạ khu đô thị'
          }
        },
        {
          path: 'common/type-attribute',
          component: TypeAttributeComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý loại hình'
          }
        },
        {
          path: 'manager-category/departments',
          component: DepartmentsComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý phòng ban'
          }
        },
        {
          path: 'manager-category/positions',
          component: PositionsComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý chức vụ'
          }
        },
        {
          path: 'manager-category/employees',
          component: EmployeesComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý nhân viên'
          }
        },
        {
          path: 'require-support-manager/require-support',
          component: RequireSupportComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý yêu cầu hỗ trợ'
          }
        },
        {
          path: 'require-support-manager/require-support/:id',
          component: RequireSupportComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Quản lý yêu cầu hỗ trợ'
          }
        },
        {
          path: 'notification-manager/notification',
          component: NotificationComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách thông báo'
          }
        },
        {
          path: 'notification-manager/notification-temp',
          component: NotificationTempComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách biểu mẫu thông báo'
          }
        },
        {
          path: 'notification-manager/notification-history',
          component: NotificationHistoryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Lịch sử gửi thông báo'
          }
        },
        {
          path: 'require-support-history',
          component: RequireSupportHistoryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Lịch sử xử lý yêu cầu hỗ trợ'
          }
        },
        {
          path: 'manager-category/notebook-category',
          component: NotebookCategoryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh mục sổ tay cư dân'
          }
        },
        {
          path: 'manager-category/notebook',
          component: NotebookComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Bài viết sổ tay cư dân'
          }
        },
        {
          path: 'require-support-manager/report-support',
          component: ReportSupportComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo yêu cầu hỗ trợ'
          }
        },
        {
          path: 'manager-category/utility',
          component: UtilityComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Tiện ích'
          }
        },
        {
          path: 'manager-category/banner',
          component: BannerComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý banner'
          }
        },
        {
          path: 'notification-manager/notification-fee',
          component: NotificationFeeComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Thông báo phí'
          }
        },
        {
          path: 'payment/order',
          component: OrderComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Hóa đơn OneS'
          }
        },
        {
          path: 'payment/search-fee',
          component: SearchFeeComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Tra cứu phí'
          }
        },
        {
          path: 'car-parking-manager/car-parking',
          component: CarParkingComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách đăng ký thẻ xe'
          }
        },
        {
          path: 'car-parking-manager/car-parking-update',
          component: CarParkingUpdateComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách cấp lại thẻ xe'
          }
        },
        {
          path: 'car-parking-manager/car-parking-cancel',
          component: CarParkingCancelComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách hủy thẻ xe'
          }
        },
        {
          path: 'manager-category/service-plus',
          component: ServicePlusComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Dịch vụ'
          }
        },
        {
          path: 'manager-category/register-service',
          component: ServicePlusMappingComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Đăng ký dịch vụ'
          }
        },
        {
          path: 'payment/report-cash',
          component: ReportCashComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo thanh toán'
          }
        },
        {
          path: 'payment/search-payment',
          component: SearchPaymentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Tra cứu thanh toán'
          }
        },
        {
          path: 'payment/report-sms',
          component: ReportSmsComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo sms'
          }
        },
        {
          path: 'payment/config-payment',
          component: ConfigPaymentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Cấu hình thanh toán'
          }
        },
        {
          path: 'utilities/transpost-request',
          component: TransferGoodsComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Danh sách đăng ký chuyển hàng hóa vào ra'
          }
        },
        // {
        //   path: 'utilities/transpost-request/create',
        //   component: NewTransferGoodsComponent,
        //   // canActivate: [AuthGuard],
        //   data: {
        //     title: 'Đăng ký chuyển hàng hóa vào ra'
        //   }
        // },
        {
          path: 'utilities/transpost-request/update/:id',
          component: NewTransferGoodsComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Chi tiết chuyển hàng hóa vào ra'
          }
        },
        {
          path: 'notification-manager/notification-history',
          component: NotificationHistoryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Lịch sử gửi thông báo'
          }
        },
        {
          path: 'require-support-history',
          component: RequireSupportHistoryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Lịch sử xử lý yêu cầu hỗ trợ'
          }
        },
        {
          path: 'manager-category/notebook-category',
          component: NotebookCategoryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh mục sổ tay cư dân'
          }
        },
        {
          path: 'manager-category/notebook',
          component: NotebookComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Bài viết sổ tay cư dân'
          }
        },
        {
          path: 'require-support-manager/report-support',
          component: ReportSupportComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo yêu cầu hỗ trợ'
          }
        },
        {
          path: 'manager-category/utility',
          component: UtilityComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Tiện ích'
          }
        },
        {
          path: 'manager-category/banner',
          component: BannerComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Quản lý banner'
          }
        },
        {
          path: 'notification-manager/notification-fee',
          component: NotificationFeeComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Thông báo phí'
          }
        },
        {
          path: 'payment/order',
          component: OrderComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Hóa đơn OneS'
          }
        },
        {
          path: 'payment/search-fee',
          component: SearchFeeComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Tra cứu phí'
          }
        },
        {
          path: 'car-parking-manager/car-parking',
          component: CarParkingComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách đăng ký thẻ xe'
          }
        },
        {
          path: 'car-parking-manager/car-parking-update',
          component: CarParkingUpdateComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách cấp lại thẻ xe'
          }
        },
        {
          path: 'car-parking-manager/car-parking-cancel',
          component: CarParkingCancelComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách hủy thẻ xe'
          }
        },
        {
          path: 'manager-category/service-plus',
          component: ServicePlusComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Dịch vụ'
          }
        },
        {
          path: 'manager-category/register-service',
          component: ServicePlusMappingComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Đăng ký dịch vụ'
          }
        },
        {
          path: 'payment/report-cash',
          component: ReportCashComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo thanh toán'
          }
        },
        {
          path: 'payment/search-payment',
          component: SearchPaymentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Tra cứu thanh toán'
          }
        },
        {
          path: 'payment/report-sms',
          component: ReportSmsComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo sms'
          }
        },
        {
          path: 'payment/config-payment',
          component: ConfigPaymentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Cấu hình thanh toán'
          }
        },
        {
          path: 'utilities/transpost-request',
          component: TransferGoodsComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Danh sách đăng ký chuyển hàng hóa vào ra'
          }
        },
        {
          path: 'utilities/transpost-request/create/in',
          component: NewTransferGoodsComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Đăng ký chuyển hàng hóa vào'
          }
        },
        {
          path: 'utilities/transpost-request/create/out',
          component: NewTransferGoodsComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Đăng ký chuyển hàng hóa ra'
          }
        },
        {
          path: 'utilities/transpost-request/update/in/:id',
          component: NewTransferGoodsComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Chi tiết chuyển hàng hóa vào'
          }
        },
        {
          path: 'utilities/transpost-request/update/out/:id',
          component: NewTransferGoodsComponent,
          // canActivate: [AuthGuard],
          data: {
            title: 'Chi tiết chuyển hàng hóa ra'
          }
        },
        {
          path: 'payment/report-cash',
          component: ReportCashComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo thanh toán'
          }
        },
        {
          path: 'payment/search-payment',
          component: SearchPaymentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Tra cứu thanh toán'
          }
        },
        {
          path: 'payment/report-sms',
          component: ReportSmsComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Báo cáo sms'
          }
        },
        {
          path: 'payment/config-payment',
          component: ConfigPaymentComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Cấu hình thanh toán'
          }
        },
        {
          path: 'config-category-service/service-category',
          component: ServiceCategoryComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách loại dịch vụ'
          }
        },
        {
          path: 'manager-service/service-tech',
          component: TechnicalServiceManagerComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách đăng ký dịch vụ kỹ thuật'
          }
        },
        {
          path: 'manager-service/service-tech/create',
          component: NewServiceTechComponent,
          data: {
            title: 'Đăng ký dịch vụ kỹ thuật'
          }
        },
        {
          path: 'manager-category/registration-term',
          component: RegistrationTermComponent,
          data: {
            title: 'Danh sách điều khoản đăng ký'
          }
        },
        {
          path: 'manager-service/service-tech/update/:id/:statusProcessId',
          component: NewServiceTechComponent,
          data: {
            title: 'Chi tiết đăng ký dịch vụ kỹ thuật'
          }
        },
        {
          path: 'manager-category/config-rate',
          component: ConfigRateComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Cấu hình tiêu chí đánh giá'
          }
        },
        {
          path: 'utilities/registration-construction-form/create',
          component: RegisterConstructionFormComponent,
          data: {
            title: 'Thêm mới đơn đăng ký thi công'
          }
        },
        {
          path: 'utilities/registration-construction-form/update/:id',
          component: RegisterConstructionFormComponent,
          data: {
            title: 'Xem/Cập nhật đơn đăng ký thi công'
          }
        },
        {
          path: 'utilities/registration-construction',
          component: RegisterConstructionComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Danh sách phiếu đăng ký thi công'
          }
        },
        {
          path: 'utilities/registration-construction-form/create',
          component: RegisterConstructionFormComponent,
          data: {
            title: 'Thêm mới đơn đăng ký thi công'
          }
        },
        {
          path: 'utilities/registration-construction-form/update/:id',
          component: RegisterConstructionFormComponent,
          data: {
            title: 'Xem/Cập nhật đơn đăng ký thi công'
          }
        },
        {
          path: 'utilities/registration-construction/contractor/:id',
          component: ContractorComponent,
          data: {
            title: 'Danh sách nhà thầu'
          }
        },
        {
          path: 'utilities/contractor/:id/:contractorId',
          component: ExtensionConstructionComponent,
          data: {
            title: 'Danh sách gia hạn thi công'
          }
        },
        {
          path: 'utilities/registration-construction/acceptance/:id',
          component: AcceptanceConstructionComponent,
          data: {
            title: 'Danh sách đơn đăng ký nghiệm thu'
          }
        },
        {
          path: 'manager-service/rental-apartment',
          component: RentalApartmentComponent,
          data: {
            title: 'Danh sách phiếu đăng ký dịch vụ cho thuê/bán nhà'
          }
        },
        {
          path: 'manager-service/rental-apartment/create',
          component: ActionRentalApartmentComponent,
          data: {
            title: 'Thêm mới đơn đăng ký dịch vụ cho thuê/bán nhà'
          }
        },
        {
          path: 'manager-service/rental-apartment/update/:id',
          component: ActionRentalApartmentComponent,
          data: {
            title: 'Chi tiết đơn đăng ký dịch vụ cho thuê/bán nhà'
          }
        },
        {
          path: 'common/sla-config',
          component: SlaConfigComponent,
          data: {
            title: 'Cấu hình SLA'
          }
        },
        {
          path: 'config-category-service/rental-config',
          component: ConfigRentalApartmentComponent,
          data: {
            title: 'Cấu hình ưu đãi dv cho thuê/bán nhà'
          }
        },
        {
          path: 'payment/order-service-utilities',
          component: OrderServiceUtilitiesComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Hóa đơn dịch vụ, tiện ích'
          }
        },
        {
          path: 'log-activity',
          component: LogSystemComponent,
          canActivate: [AuthGuard],
          data: {
            title: 'Nhật ký hoạt động'
          }
        }
      ]
  },
  {
    path: '**',
    component: P404Component,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
