import { RequestCleaningService } from './service/request-cleaning.service';
import { IvyGalleryModule } from 'angular-gallery';
import { HeaderService } from './service/header.service';
import { ChangeCarCardComponent } from './views/resident-service-manager/dialogs/change-car-card/change-car-card.component';
import { ChangeMotorcycleCardComponent } from './views/resident-service-manager/dialogs/change-motorcycle-card/change-motorcycle-card.component';
import { CustomDateParserFormatterService } from './service/custom-date-parser-formatter.service';
import { CustomAdapterService } from './service/custom-adapter.service';
import { AppInterceptor } from './interceptors/app.interceptor';
import { EditResidentServiceComponent } from './views/resident-service-manager/edit-resident-service/edit-resident-service.component';
import { ResidentManagerService } from './service/resident-manager.service';
import { RegisterConstructionService } from './service/register-construction.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF, DatePipe, CommonModule } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

import { InterceptorService } from 'ng2-interceptors';
import { XHRBackend, RequestOptions, HttpModule } from '@angular/http';

export function interceptorFactory(xhrBackend: XHRBackend, requestOptions: RequestOptions) {
    let service = new InterceptorService(xhrBackend, requestOptions);
    // Add interceptors here with service.addInterceptor(interceptor)
    return service;
}

//firebase
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';

import { MessagingService } from './service/messaging.service';
import { DownloadService } from './service/download.service';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

const APP_CONTAINERS = [
    DefaultLayoutComponent
];

import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,

} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { PreCliDirective } from './directive/preventClick/pre-cli.directive';
import { OlComponent } from './directive/ol/ol.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { FunctionComponent } from './views/system-manager/function/function.component';
import { RoleComponent } from './views/system-manager/role/role.component';
import { UserComponent } from './views/system-manager/user/user.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxMaskModule } from 'ngx-mask';
import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { InputMaskModule } from 'racoon-mask-raw';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { FilterStatusPipe } from './pipe/filter-status.pipe';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProjectComponent } from './views/project-category/project/project.component';
import { TowerComponent } from './views/project-category/tower/tower.component';
import { ApartmentComponent } from './views/project-category/apartment/apartment.component';
import { FloorComponent } from './views/project-category/floor/floor.component';
import { UserManagementComponent } from './views/system-manager/user-management/user-management.component';
import { UserResidentComponent } from './views/system-manager/user-resident/user-resident.component';
import { ResidentsComponent } from './views/project-category/residents/residents.component';
import { TypeAttributeComponent } from './views/common/type-attribute/type-attribute.component';
import { DepartmentsComponent } from './views/manager-category/departments/departments.component';
import { PositionsComponent } from './views/manager-category/positions/positions.component';
import { EmployeesComponent } from './views/manager-category/employees/employees.component';
import { CountryComponent } from './views/common/country/country.component';
import { ProvinceComponent } from './views/common/province/province.component';
import { DistrictComponent } from './views/common/district/district.component';
import { WardComponent } from './views/common/ward/ward.component';
import { ContactsComponent } from './views/common/contacts/contacts.component';
import { HotlineComponent } from './views/common/hotline/hotline.component';
import { RequireSupportComponent } from './views/require-support-manager/require-support/require-support.component';
import { RequireSupportHistoryComponent } from './views/require-support-manager/require-support-history/require-support-history.component';
import { NotificationComponent } from './views/notification-manager/notification/notification.component';
import { NotificationTempComponent } from './views/notification-manager/notification-temp/notification-temp.component';
import { NotificationHistoryComponent } from './views/notification-manager/notification-history/notification-history.component';
import { NotebookCategoryComponent } from './views/notebook-manager/notebook-category/notebook-category.component';
import { NotebookComponent } from './views/notebook-manager/notebook/notebook.component';
import { ReportSupportComponent } from './views/report/report-support/report-support.component';
import { UtilityComponent } from './views/utility/utility.component';
import { NotificationFeeComponent } from './views/notification-manager/notification-fee/notification-fee.component';
import { BannerComponent } from './views/manager-category/banner/banner.component';
import { OrderComponent } from './views/payment/order/order.component';
import { SearchFeeComponent } from './views/payment/search-fee/search-fee.component';
import { CarParkingComponent } from './views/car-parking-manager/car-parking/car-parking.component';
import { ServicePlusComponent } from './views/service-plus-manager/service-plus/service-plus.component';
import { ServicePlusMappingComponent } from './views/service-plus-manager/service-plus-mapping/service-plus-mapping.component';
import { CarParkingUpdateComponent } from './views/car-parking-manager/car-parking-update/car-parking-update.component';
import { CarParkingCancelComponent } from './views/car-parking-manager/car-parking-cancel/car-parking-cancel.component';
import { ReportCashComponent } from './views/report/report-cash/report-cash.component';
import { SearchPaymentComponent } from './views/payment/search-payment/search-payment.component';
import { ReportSmsComponent } from './views/report/report-sms/report-sms.component';
import { ResidentServiceManagerComponent } from './views/resident-service-manager/resident-service-manager.component';
import { ResidentMoveInComponent } from './views/resident-service-manager/dialogs/resident-move-in/resident-move-in.component';
import { ResidentCardComponent } from './views/resident-service-manager/dialogs/resident-card/resident-card.component';
import { CarCardComponent } from './views/resident-service-manager/dialogs/car-card/car-card.component';
import { ConfigPaymentComponent } from './views/payment/config-payment/config-payment.component';
import { CommonService } from './service/common.service';
import { AuthService } from './service/auth.service';
import { MotorcycleCardComponent } from './views/resident-service-manager/dialogs/motorcycle-card/motorcycle-card.component';
import { EditCardChangeInfoComponent } from './views/resident-service-manager/edit-card-change-info/edit-card-change-info.component';
import { ChangeResidentCardComponent } from './views/resident-service-manager/dialogs/change-resident-card/change-resident-card.component';
import { EditCardCancelComponent } from './views/resident-service-manager/edit-card-cancel/edit-card-cancel.component';
import { EditCardRenewComponent } from './views/resident-service-manager/edit-card-renew/edit-card-renew.component';
import { EditCardLockComponent } from './views/resident-service-manager/edit-card-lock/edit-card-lock.component';
import { EditCardUnLockComponent } from './views/resident-service-manager/edit-card-unlock/edit-card-unlock.component';
import { TransferGoodsComponent } from './views/transfer-goods/transfer-goods.component';
import { ItemDialogComponent } from './views/transfer-goods/dialog/item-dialog/item-dialog.component';
import { NewTransferGoodsComponent } from './views/transfer-goods/new-transfer-goods/new-transfer-goods.component';
import { TranspostRequestService } from './service/transpost-request.service';
import { ServiceCategoryComponent } from './views/manager-category/service-category/service-category.component';
import { ServiceTechComponent } from './views/manager-service/service-tech/service-tech.component';
import { FilterDropdownDirective } from './directive/filter-dropdown.directive';
import { ConfigRateComponent } from './views/manager-category/config-rate/config-rate.component';
import { NewServiceTechComponent } from './views/manager-service/service-tech/new-service-tech/new-service-tech.component';
import { ServiceTechEmpComponent } from './views/manager-service/service-tech/dialogs/service-tech-emp/service-tech-emp.component';
import { ServiceTechPlanComponent } from './views/manager-service/service-tech/dialogs/service-tech-plan/service-tech-plan.component';
import { ServiceTechProductComponent } from './views/manager-service/service-tech/dialogs/service-tech-product/service-tech-product.component';
import { ServiceTechPriceComponent } from './views/manager-service/service-tech/dialogs/service-tech-price/service-tech-price.component';
import { ServiceTechItemComponent } from './views/manager-service/service-tech/dialogs/service-tech-item/service-tech-item.component';
import { RegisterConstructionComponent } from './views/register-construction/register-construction.component';
import { RegisterConstructionFormComponent } from './views/register-construction/register-construction-form/register-construction-form.component';
import { RegisterConstructionDocComponent } from './views/register-construction/dialogs/register-construction-doc/register-construction-doc.component';
import { ContractorComponent } from './views/register-construction/contractor/contractor.component';
import { ContractorDialogComponent } from './views/register-construction/dialogs/contractor-dialog/contractor-dialog.component';
import { CancelRequestGroupComponent } from './views/resident-service-manager/dialogs/cancel-request-group/cancel-request-group.component';
import { ChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { AcceptanceConstructionComponent } from './views/register-construction/acceptance/acceptance.component';
import { AcceptanceDialogComponent } from './views/register-construction/dialogs/acceptance-dialog/acceptance-dialog.component';
import { DepositDialogComponent } from './views/register-construction/dialogs/deposit-dialog/deposit-dialog.component';
import { ExtensionConstructionDialogComponent } from './views/register-construction/dialogs/extension-construction-dialog/extension-construction-dialog.component';
import { ExtensionConstructionComponent } from './views/register-construction/extension-construction/extension-construction.component';
import { FeedBackDialogComponent } from './views/register-construction/dialogs/feedback-dialog/feedback-dialog.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { RegistrationTermComponent } from './views/manager-category/registration-term/registration-term.component';

//Directive
import { PhoneMaskDirective } from './directive/common/phone-mask.directive';
import { MaskDateDirective } from './directive/common/mask-date.directive';

import { TextMaskModule } from 'angular2-text-mask';
import { CustomDatepickerI18n, I18n } from './service/date-picker-i18n';
import { ServiceTechNotificationComponent } from './views/manager-service/service-tech/dialogs/service-tech-notification/service-tech-notification.component';
import { ServiceTechHistoryComponent } from './views/manager-service/service-tech/dialogs/service-tech-history/service-tech-history.component';
import { ServiceTechAcceptanceComponent } from './views/manager-service/service-tech/dialogs/service-tech-acceptance/service-tech-acceptance.component';
import { ServiceTechServiceComponent } from './views/manager-service/service-tech/dialogs/service-tech-service/service-tech-service.component';
import { RateServiceRentalApartmentComponent } from './views/manager-service/rental-apartment/dialogs/rate-service/rate-service.component';
import { CancelServiceComponent } from './views/manager-service/rental-apartment/dialogs/cancel-service/cancel-service.component';


import { CleaningServiceCategoryComponent } from './views/manager-category/cleaning-service-category/cleaning-service-category.component';
import { RentalApartmentService } from './service/rental-apartment';
import { SlaConfigComponent } from './views/common/sla-config/sla-config.component';
import { ActionRentalApartmentComponent } from './views/manager-service/rental-apartment/action-rental-apartment/action-rental-apartment.component';
import { ConfigRentalApartmentComponent } from './views/manager-service/rental-apartment/config-rental-apartment/config-rental-apartment.component';
import { RentalApartmentInformComponent } from './views/manager-service/rental-apartment/dialogs/rental-apartment-inform/rental-apartment-inform.component';
import { RentalApartmentComponent } from './views/manager-service/rental-apartment/rental-apartment.component';
import { ServiceTechCancelComponent } from './views/manager-service/service-tech/dialogs/service-tech-cancel/service-tech-cancel.component';

import { OrderServiceUtilitiesComponent } from './views/payment/order-service-utilities/order-service-utilities.component';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfigUtilitiesComponent } from './views/system-manager/config-utilities/config-utilities.component';
import { LogSystemComponent } from './views/log-system/log-system.component';
import { CleaningNewService } from './service/cleaning-new.service';
import { TechnicalNewService } from './service/technical-new.service';

import { AssignmentWorkDialogComponent } from './views/manager-service/cleaning-service-manager/dialogs/assignment-work-dialog/assignment-work-dialog.component';
import { AddQuotationItemDialogComponent } from './views/manager-service/cleaning-service-manager/dialogs/add-quotation-item-dialog/add-quotation-item-dialog.component';
import { AcceptanceRequestDialogComponent } from './views/manager-service/cleaning-service-manager/dialogs/acceptance-request-dialog/acceptance-request-dialog.component';
import { AddQuatilyEvalutionDialogComponent } from './views/manager-service/cleaning-service-manager/dialogs/add-quatily-evalution-dialog/add-quatily-evalution-dialog.component';
import { CleaningServiceManagerComponent } from './views/manager-service/cleaning-service-manager/cleaning-service-manager.component';
import { HistoriesMessageDialogComponent } from './views/manager-service/cleaning-service-manager/dialogs/histories-message-dialog/histories-message-dialog.component';
import { CancelCleaningRequestDialogComponent } from './views/manager-service/cleaning-service-manager/dialogs/cancel-cleaning-request-dialog/cancel-cleaning-request-dialog.component';
import { QuotationDetailDialogComponent } from './views/manager-service/cleaning-service-manager/dialogs/quotation-detail-dialog/quotation-detail-dialog.component';
import { DetailRequestCleaningComponent } from './views/manager-service/cleaning-service-manager/detail-request-cleaning/detail-request-cleaning.component';
import { CreateRequestCleaningComponent } from './views/manager-service/cleaning-service-manager/create-request-cleaning/create-request-cleaning.component';

import { TechnicalServiceManagerComponent } from './views/manager-service/technical-service-manager/technical-service-manager.component';
import { DetailRequestTechnicalComponent } from './views/manager-service/technical-service-manager/detail-request-technical/detail-request-technical.component';
import { CancelTechnicalRequestDialogComponent } from './views/manager-service/technical-service-manager/dialogs/cancel-technical-request-dialog/cancel-technical-request-dialog.component';
import { CreateRequestTechnicalComponent } from './views/manager-service/technical-service-manager/create-request-technical/create-request-technical.component';

@NgModule({
    imports: [
        HttpModule,
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ChartsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        ToastrModule.forRoot(),
        LoadingBarHttpClientModule,
        ModalDialogModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        NgSelectModule,
        TooltipModule.forRoot(),
        ButtonsModule,
        PopoverModule.forRoot(),
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxMaskModule.forRoot(),
        CarouselModule.forRoot(),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.firebase),
        //AngularFireAnalyticsModule,
        AngularFireMessagingModule,
        AngularFirestoreModule,
        CKEditorModule,
        GalleryModule.forRoot(),
        IvyGalleryModule,
        TextMaskModule,
        InputMaskModule,
        ProgressbarModule.forRoot(),
        NgCircleProgressModule.forRoot({
            "radius": 60,
            "space": -10,
            "outerStrokeGradient": false,
            "outerStrokeWidth": 10,
            "outerStrokeColor": "#4882c2",
            "outerStrokeGradientStopColor": "#53a9ff",
            "innerStrokeColor": "#e7e8ea",
            "innerStrokeWidth": 10,
            "title": "SLA (Phút)",
            "animateTitle": false,
            "animationDuration": 1000,
            "showUnits": false,
            "showBackground": false,
            "clockwise": false,
            "responsive": true,
            "lazy": true
        }),
        InfiniteScrollModule,
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
        LoginComponent,
        DashboardComponent,
        FunctionComponent,
        RoleComponent,
        UserComponent,
        OlComponent,
        PreCliDirective,
        FilterStatusPipe,
        ProjectComponent,
        TowerComponent,
        ApartmentComponent,
        FloorComponent,
        UserManagementComponent,
        UserResidentComponent,
        ResidentsComponent,
        TypeAttributeComponent,
        DepartmentsComponent,
        PositionsComponent,
        EmployeesComponent,
        CountryComponent,
        ProvinceComponent,
        DistrictComponent,
        WardComponent,
        ContactsComponent,
        HotlineComponent,
        RequireSupportComponent,
        RequireSupportHistoryComponent,
        NotificationComponent,
        NotificationTempComponent,
        NotificationHistoryComponent,
        NotebookCategoryComponent,
        NotebookComponent,
        ReportSupportComponent,
        UtilityComponent,
        NotificationFeeComponent,
        BannerComponent,
        OrderComponent,
        SearchFeeComponent,
        CarParkingComponent,
        ServicePlusComponent,
        ServicePlusMappingComponent,
        CarParkingUpdateComponent,
        CarParkingCancelComponent,
        ReportCashComponent,
        SearchPaymentComponent,
        ReportSmsComponent,
        ResidentServiceManagerComponent,
        EditResidentServiceComponent,
        ResidentMoveInComponent,
        ResidentCardComponent,
        CarCardComponent,
        ConfigPaymentComponent,
        MotorcycleCardComponent,
        EditCardChangeInfoComponent,
        ChangeResidentCardComponent,
        ChangeMotorcycleCardComponent,
        ChangeCarCardComponent,
        EditCardCancelComponent,
        EditCardRenewComponent,
        EditCardLockComponent,
        EditCardUnLockComponent,
        TransferGoodsComponent,
        ItemDialogComponent,
        NewTransferGoodsComponent,
        ServiceCategoryComponent,
        ServiceTechComponent,
        FilterDropdownDirective,
        ConfigRateComponent,
        NewServiceTechComponent,
        ServiceTechEmpComponent,
        ServiceTechPlanComponent,
        ServiceTechProductComponent,
        ServiceTechPriceComponent,
        ServiceTechItemComponent,
        RegisterConstructionComponent,
        ContractorComponent,
        RegisterConstructionFormComponent,
        RegisterConstructionDocComponent,
        ContractorDialogComponent,
        CancelRequestGroupComponent,
        ExtensionConstructionComponent,
        ExtensionConstructionDialogComponent,
        AcceptanceConstructionComponent,
        AcceptanceDialogComponent,
        DepositDialogComponent,
        FeedBackDialogComponent,
        RegistrationTermComponent,
        PhoneMaskDirective,
        ServiceTechNotificationComponent,
        ServiceTechHistoryComponent,
        ServiceTechAcceptanceComponent,
        ServiceTechServiceComponent,
        MaskDateDirective,
        RentalApartmentComponent,
        SlaConfigComponent,
        ActionRentalApartmentComponent,
        RentalApartmentInformComponent,
        ServiceTechCancelComponent,
        ConfigRentalApartmentComponent,
        RateServiceRentalApartmentComponent,
        CancelServiceComponent,
        CreateRequestCleaningComponent,
        DetailRequestCleaningComponent,
        CleaningServiceManagerComponent,
        HistoriesMessageDialogComponent,
        CancelCleaningRequestDialogComponent,
        QuotationDetailDialogComponent,
        CleaningServiceCategoryComponent,
        AssignmentWorkDialogComponent,
        AddQuotationItemDialogComponent,
        AcceptanceRequestDialogComponent,
        AddQuatilyEvalutionDialogComponent,
        OrderServiceUtilitiesComponent,
        ConfigUtilitiesComponent,
        LogSystemComponent,
        TechnicalServiceManagerComponent,
        DetailRequestTechnicalComponent,
        CancelTechnicalRequestDialogComponent,
        CreateRequestTechnicalComponent
    ],
    entryComponents: [
        ResidentMoveInComponent,
        ResidentCardComponent,
        CarCardComponent,
        MotorcycleCardComponent,
        ChangeResidentCardComponent,
        ChangeMotorcycleCardComponent,
        ChangeCarCardComponent,
        ItemDialogComponent,
        RegisterConstructionDocComponent,
        ContractorDialogComponent,
        CancelRequestGroupComponent,
        ExtensionConstructionDialogComponent,
        AcceptanceDialogComponent,
        DepositDialogComponent,
        ServiceTechEmpComponent,
        ServiceTechPlanComponent,
        ServiceTechProductComponent,
        ServiceTechPriceComponent,
        ServiceTechItemComponent,
        ServiceTechAcceptanceComponent,
        ServiceTechHistoryComponent,
        ServiceTechNotificationComponent,
        ServiceTechServiceComponent,
        FeedBackDialogComponent,
        ActionRentalApartmentComponent,
        RentalApartmentInformComponent,
        ServiceTechCancelComponent,
        ConfigRentalApartmentComponent,
        RateServiceRentalApartmentComponent,
        CancelServiceComponent,
        HistoriesMessageDialogComponent,
        QuotationDetailDialogComponent,
        AssignmentWorkDialogComponent,
        AddQuotationItemDialogComponent,
        AcceptanceRequestDialogComponent,
        CancelCleaningRequestDialogComponent,
        AddQuatilyEvalutionDialogComponent
    ],
    exports: [PreCliDirective, PhoneMaskDirective],
    providers: [
        AuthGuard,
        AuthService,
        MessagingService,
        DownloadService,
        CookieService,
        DatePipe,
        { provide: APP_BASE_HREF, useValue: '' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptor,
            multi: true
        },
        ResidentManagerService,
        RegisterConstructionService,
        RentalApartmentService,
        TranspostRequestService,
        CommonService,
        { provide: NgbDateAdapter, useClass: CustomAdapterService },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },
        HeaderService,
        {
            provide: InterceptorService,
            useFactory: interceptorFactory,
            deps: [XHRBackend, RequestOptions]
        },
        I18n,
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        RequestCleaningService,
        CleaningNewService,
        TechnicalNewService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
