import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceOrder } from '../../../../../data/Model';

@Component({
  selector: 'app-service-tech-notification',
  templateUrl: './service-tech-notification.component.html',
  styleUrls: ['./service-tech-notification.component.scss']
})
export class ServiceTechNotificationComponent implements OnInit {
  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  public Item: ServiceOrder;

  constructor(
    public modal: NgbActiveModal,
  ) { 
    this.Item = new ServiceOrder();
  }

  ngOnInit() {
    
    if (this.itemDetail) {
      this.Item = this.itemDetail;
    }
  }

  onSubmit() {
    // if (!this.Item.Name) {
    //   this.toastWarning("Vui lòng nhập lần gia hạn");
    //   return;
    // }
    // if (!this.Item.DatePlan) {
    //   this.toastWarning("Vui lòng nhập thời gian gia hạn");
    //   return;
    // } 
    // let data = Object.assign({}, this.Item);
    // data.createdById = parseInt(localStorage.getItem("userId"));
    // if (data.DatePlan) {
    //   let obj = new Date(data.DatePlan);
    //   data.DatePlan = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + obj.getHours() + ":" + obj.getMinutes() + ":0";
    // }
    this.itemCommon.emit(this.Item);
    this.modal.close();
  }

}
