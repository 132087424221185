import { HttpClient, HttpHeaders } from '@angular/common/http';
import { domainApi, RegConstructionStatus, ApiConstant, LstStatusRequestGroup, LstStatusPaymentRequestGroup } from '../../../data/const';
import { RegisterConstructionService } from '../../../service/register-construction.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../service/common.service';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { registerConstructionForm, feedBackInfo } from '../../../data/Model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractorDialogComponent } from '../dialogs/contractor-dialog/contractor-dialog.component';
import { FeedBackDialogComponent } from '../dialogs/feedback-dialog/feedback-dialog.component';
import { HeaderService } from './../../../service/header.service';
import { DatePipe } from '@angular/common';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-register-construction-contractor',
    templateUrl: './contractor.component.html',
    styleUrls: ['./contractor.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class ContractorComponent implements OnInit {
    @ViewChild('toggleFilter') toggleFilter: ElementRef;

    public q: QueryFilter;
    public lstStatus = LstStatusRequestGroup;
    public lstStatusPayment = LstStatusPaymentRequestGroup;
    public listCommon = [];
    public lstIdDeletes: number[] = [];
    public paging: Paging;
    public isShowFilter = false;
    public isCheckedDelete = false;
    public isToggleTypeCreate = false;
    public CheckAll: boolean;
    public httpOptions: any;
    public regConstructionStatus = RegConstructionStatus;
    public messageError = '';
    public dateAppointment: Date;
    public dateFinish: Date;
    public statusPayment: number;
    public item: registerConstructionForm;
    public numberItemDelete = 0;
    public isKSTDKTC = localStorage.getItem("roleCode") == "KSTDKTC" ? true : false;

    constructor(
        private readonly routerUrl: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        public titleService: Title,
        private renderer: Renderer2,
        private readonly registerConstructionService: RegisterConstructionService,
        private readonly commonService: CommonService,
        public router: Router,
        private readonly http: HttpClient,
        public modalDialogService: ModalDialogService,
        private readonly toastr: ToastrService,
        private readonly headerService: HeaderService,
        public datePipe: DatePipe,
        public viewRef: ViewContainerRef,) {

        this.q = new QueryFilter();
        this.q.txtSearch = "";
        this.q.StatusProcessId = -1;
        this.q.TypeRequireId = 0;
        this.q.DateTimeStart = null;
        this.q.DateTimeEnd = null;

        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 10;

        this.q.TypeRequireId = null;
        this.q.StatusProcessId = null;

        this.item = new registerConstructionForm();

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Danh sách nhà thầu");

        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.onGetDetail(id);
            this.q.TargetId = parseInt(id);
        } else {
            this.routerUrl.navigate(['/utilities/registration-construction']);
        }
        this.onFilter();
    }

    showTitle() {
        this.headerService.setTitleComponent("Danh sách nhà thầu - Đơn đăng ký thi công(" + this.item.ResidentName + " - " + this.item.ApartmentName + " - " + this.datePipe.transform(this.item.RegisterDate, 'dd/MM/yyyy hh:mm') + ")");
    }

    openToggleFilter() {
        this.isShowFilter = !this.isShowFilter;
    }


    openToggleTypeCreate() {
        this.isToggleTypeCreate = !this.isToggleTypeCreate;
    }

    onDelete(item: any) {
        if (item.ContractorStatus == 1) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer',
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Bạn chắc muốn xóa nhà thầu này không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',
                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            this.http.delete(ApiConstant.DeleteContractor + item["Id"])
                                .subscribe(
                                    (res) => {
                                        if (res["meta"]["error_code"] == 200) {
                                            this.toastSuccess("Xóa thành công!");
                                            this.onFilter();
                                        }
                                    },
                                    (err) => {
                                        console.log("Error: connect to API");
                                    });

                            this.viewRef.clear();
                        }
                    }
                ],

            });
        }
        else {
            this.toastWarning("Không thể xóa! yêu cầu này đã khóa.")
        }
    }

    onDeletes() {
        let data = [];
        this.listCommon.forEach(item => {
            if (item.isChecked == true) {
                data.push(item.Id);
            }
        });

        if (data.length > 0) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer'
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Bạn chắc muốn xóa các thông tin nhà thầu đã chọn không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',
                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            this.http.put(ApiConstant.DeleteContractor + "deletes", data)
                                .subscribe(
                                    (res) => {
                                        if (res["meta"]["error_code"] == 200) {
                                            this.onFilter();
                                        }
                                    },
                                    (err) => {
                                        this.toastWarning(`Xóa danh sách thông tin nhà thầu thất bại. ${err["meta"]["error_message"]}`);
                                    });

                            this.viewRef.clear();
                        }
                    }
                ],
            });
        }
    }

    PageChanged(event) {
        this.paging.page = event.page;
        this.lstIdDeletes = [];
        this.CheckAll = false;

        this.onFilter();
    }

    onFilter() {
        this.messageError = '';
        this.listCommon = [];
        this.q.StatusProcessId = this.q.StatusProcessId || null;

        if (this.q.DateTimeStart && !this.q.DateTimeEnd) {
            this.q.DateTimeEnd = new Date();
        } else if (!this.q.DateTimeStart && this.q.DateTimeEnd) {
            this.toastWarning("Vui lòng chọn ngày bắt đầu lọc");
            return;
        }

        this.registerConstructionService.getContractorByPage(this.q, this.paging).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                if (res["data"].length) {
                    this.listCommon = res["data"];
                    this.listCommon.map(s => s.isChecked = false);
                    this.paging.item_count = res["metadata"];
                } else {
                    this.listCommon = [];
                    this.paging.item_count = 0;
                    this.messageError = 'Không có dữ liệu';
                }
            }
            else {
                this.listCommon = [];
                this.paging.item_count = 0;
                this.messageError = 'Không có dữ liệu';
            }
        },
            (error) => {
                this.toastError('Api Error!');
                this.paging.item_count = 0;
            })
    }

    onRemoveDate(typeDate) {
        if (typeDate == 1) {
            this.q.DateTimeStart = null;
        } else {
            this.q.DateTimeEnd = null;
        }
    }

    CheckActionTable(id) {
        if (id == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
                if(item.ContractorStatus == 1)
				    item.isChecked = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].isChecked && (this.listCommon[i].ContractorStatus == 1)) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}

        this.numberItemDelete = this.listCommon.filter(x => x.isChecked == true).length;
    }

    onCheckDisabledCheckbox(status) {
        return status != 1 ? true : false;
    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    showRegConstructionStatus(constructionStatus) {
        let current = this.regConstructionStatus.filter(x => x.Id == constructionStatus)[0];
        return current != undefined ? current.Name : "";
    }

    onGetDetail(id) {
        this.registerConstructionService.getDetail(id).subscribe(
            (res) => {
                if (res) {
                    this.item = new registerConstructionForm();
                    this.item.Id = res['Id'];
                    this.item.Status = res['Status'];
                    this.item.ProjectId = res['ProjectId'];
                    this.item.TowerId = res['TowerId'];
                    this.item.FloorId = res['FloorId'];
                    this.item.ApartmentId = res['ApartmentId'];
                    this.item.ApartmentName = res['ApartmentName'];
                    this.item.ResidentId = res['ResidentId'];
                    this.item.ResidentName = res['ResidentName'];
                    this.item.ResidentPhone = res['ResidentPhone'];
                    this.item.ResidentIdentityId = res['ResidentIdentityId'];
                    this.item.IdentityIdDate = res['IdentityIdDate'];
                    this.item.IdentityIdPlace = res['IdentityIdPlace'];
                    this.item.Note = res['Note'];
                    this.item.AuthorizedPersonName = res['AuthorizedPersonName'];
                    this.item.AuthorizedPersonPhone = res['AuthorizedPersonPhone'];
                    this.item.AuthorizedPersonId = res['AuthorizedPersonId'];
                    this.item.AuthorizedPersonAddress = res['AuthorizedPersonAddress'];
                    this.item.TransactionDateStart = res['TransactionDateStart'];
                    this.item.TransactionDateEnd = res['TransactionDateEnd'];
                    this.item.TransactionNote = res['TransactionNote'];
                    this.item.ConstructionStatus = res['ConstructionStatus'];
                    this.item.RegConstructionStatus = res['RegConstructionStatus'];
                    this.item.ConstructionDateStart = res['ConstructionDateStart'];
                    this.item.ConstructionDateEnd = res['ConstructionDateEnd'];
                    this.item.ConstructionDateStop = res['ConstructionDateStop'];
                    this.item.IsDeposit = res['IsDeposit'];
                    this.item.AmountDeposit = res['AmountDeposit'];
                    this.item.DepositStatus = res['DepositStatus'];
                    this.item.ReceiveMoneyStatus = res['ReceiveMoneyStatus'];
                    this.item.RegisterDate = res['RegisterDate'];
                    this.item.FeedBackToResident = res['FeedBackToResident'];
                    this.item.registerConstructionDocs = res['registerConstructionDocs'];
                    this.item.objectFiles = res['objectFiles'];

                    this.showTitle();
                } else {

                    this.modalDialogService.openDialog(this.viewRef, {
                        title: 'Thông báo',
                        settings: {
                            footerClass: 'footer-dialog modal-footer'
                        },
                        childComponent: SimpleModalComponent,
                        data: {
                            text: "Đơn đăng ký không tồn tại. Vui lòng kiểm tra lại!"
                        },
                        actionButtons: [
                            {
                                text: 'Quay lại',
                                buttonClass: 'btn btn-info',
                                onAction: () => {
                                    this.routerUrl.navigate(['/utilities/registration-construction']);
                                }
                            },
                        ],
                    });
                }

            },
            (err) => {
                this.routerUrl.navigate(["**"])
            });
    }

    openContractorModal(item) {
        const dialofRef = this.modalService.open(ContractorDialogComponent, { size: <any>'xxl' });
        dialofRef.componentInstance.RegisterConstructionFormId = this.item.Id;

        if(item) {
            dialofRef.componentInstance.itemDetail = JSON.parse(JSON.stringify(item));
        }

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            this.onFilter();
        });
    }

    openExtensionConstructionModal(item) {
        this.routerUrl.navigate(['/utilities/contractor/' + item.RegisterConstructionFormId + '/' + item.Id]);
    }

    managmentFeedBack(itemInput) {
        const dialofRef = this.modalService.open(FeedBackDialogComponent, { size: 'lg' });
        let item = new feedBackInfo();
        item.RegisterConstructionFormId = itemInput.RegisterConstructionFormId;
        item.ContractorId = itemInput.Id;
        item.TypeFeedBack = 2;
        item.TargetStatus = itemInput.ContractorStatus;

        dialofRef.componentInstance.itemDetail = item;

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            this.http.post(ApiConstant.FeedBackContractor, res).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Thêm thông tin phản hồi tới cư dân thành công!");

                        this.onFilter();
                    } else {
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.toastError("Error Api");
                })
        });
    }
}
