import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ServiceOrderItem } from '../../../../../data/Model';

@Component({
  selector: 'app-service-tech-product',
  templateUrl: './service-tech-product.component.html',
  styleUrls: ['./service-tech-product.component.scss']
})
export class ServiceTechProductComponent implements OnInit {

  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  public Item: ServiceOrderItem;
  public listEmployees = [];
  public listPositions = [];

  public httpOptions: any;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.Item = new ServiceOrderItem();
    this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
   }

  ngOnInit() {
    //console.log(this.itemDetail)
    if (this.itemDetail) {
      this.Item.Contents = this.itemDetail["Contents"];
      this.Item.Quantity = this.itemDetail["Quantity"];
    }

  }

  
  onSubmit() {
    if (!this.Item.Contents) {
      this.toastWarning("Vui lòng nhập tên vật tư sử dụng");
      return;
    }
    this.itemCommon.emit(this.Item);
    this.modal.close();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }

}
