import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ServiceOrderItem } from '../../../../../data/Model';
import { domainApi } from '../../../../../data/const';
import { Paging, QueryFilter } from '../../../../../data/dt';

@Component({
  selector: 'app-service-tech-item',
  templateUrl: './service-tech-item.component.html',
  styleUrls: ['./service-tech-item.component.scss']
})
export class ServiceTechItemComponent implements OnInit {

  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Output() checkSend: EventEmitter<any> = new EventEmitter();
  @Input() listServices: any;
  @Input() projectId: any;
  @Input() towerId: any;
  public Item: ServiceOrderItem;
  public ItemS: ServiceOrderItem;
  public listItems = [];
  //public listServices = [];
  public listTypes = [];
  public listCategorys = [];
  //public listServices2 = [];
  //public checkSend = false;

  public paging: Paging;
	public q: QueryFilter;
  public httpOptions: any;
  public isTypeOther: boolean;
  public CheckAll: boolean;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.Item = new ServiceOrderItem();
    this.ItemS = new ServiceOrderItem();

    this.isTypeOther = false;
    //this.checkSend = false;

    this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "Id Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
    
    this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
   }

  ngOnInit() {
    //console.log(this.listServices)
    // if (this.itemDetail) {
    //   // this.Item.EmployeeId = this.itemDetail["EmployeeId"];
    //   // this.Item.PositionId = this.itemDetail["PositionId"];
    // }
    this.GetListServiceType();
		// this.GetListServiceCategory();

  }

  GetListService() {
    this.http.get(domainApi + '/serviceFee/GetByPage?page=' + this.paging.page + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listItems = res["data"];
          for (let i = 0; i < this.listItems.length; i++) {
              for (let j = 0; j < this.listServices.length; j++) {
                if(this.listServices[j].ServiceId == this.listItems[i].Id){
                  this.listItems[i].Action = true;
                  break;
                }
              }
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  GetListServiceType() {
    this.http.get(domainApi + '/typeAttributeItem/codeAttribute/LPDV', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listTypes = res["data"];
          this.q.Type = this.listTypes[0].Id;
          this.GetListServiceCategory();
          //this.QueryChanged();
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }
  
  GetListServiceCategory() {
    this.http.get(domainApi + '/typeAttributeItem/codeAttribute/HMDV', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listCategorys = res["data"];
          this.q.TargetId = this.listCategorys[0].Id;
          this.QueryChanged();
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  QueryChanged() {
		let query = 'ProjectId='+this.projectId+" AND TowerId="+this.towerId;
    if(this.q.Type != 208){
      this.isTypeOther=false;
      if (this.q.Type != undefined && this.q.Type != -1) {
        query += ' and Type=' + this.q.Type;
      }
      if (this.q.TargetId != undefined && this.q.TargetId != -1) {
        query += ' and CategoryId=' + this.q.TargetId;
      }
    }
    else{
      this.isTypeOther=true;
      if (this.q.Type != undefined) {
        query += 'Type=' + this.q.Type;
      }
    }
	  this.paging.query = query;

		this.GetListService();
  }

  CheckActionTable(Id) {
		// if (Id == undefined) {
		// 	let CheckAll = this.CheckAll;
		// 	this.listItems.forEach(item => {
		// 		item.Action = CheckAll;
		// 	});
		// }
		// else {
		// 	let CheckAll = true;
		// 	for (let i = 0; i < this.listItems.length; i++) {
		// 		if (!this.listItems[i].Action) {
		// 			CheckAll = false;
		// 			break;
		// 		}
		// 	}
		// 	this.CheckAll = CheckAll == true ? true : false;
		// }
    //thêm vào list service
    
    for (let i = 0; i < this.listItems.length; i++) {
      //debugger;
      if (this.listItems[i].Action) {
        let check=false;
        for (let j = 0; j < this.listServices.length; j++) {
          if(this.listServices[j].ServiceId == this.listItems[i].Id){
            check = true;
            break;
          }
        }
        if(!check){
          this.ItemS = new ServiceOrderItem();
          this.ItemS.ServiceId = this.listItems[i].Id;
          this.ItemS.Name = this.listItems[i].Name;
          this.ItemS.Contents = this.listItems[i].Note;
          this.ItemS.Price = this.listItems[i].TotalPrice;
          this.ItemS.TotalPrice = this.listItems[i].TotalPrice;
          this.ItemS.TypeService = this.listItems[i].Type;
          this.listServices.push(this.ItemS);
        }
      }
      else{
        this.listServices = this.listServices.filter(s => s.ServiceId !== this.listItems[i].Id);
      }
    }
    
    //console.log(this.listServices)


	}
  
  onSubmit() {
    //this.listServices2 = this.listServices;
    // this.checkSend=true;
    // if(this.checkSend){
      this.checkSend.emit(true);
      this.itemCommon.emit(this.listServices);
    //}
    this.modal.close();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }


}
