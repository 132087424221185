import { TypeUserNote, ProcessStatusRequestCleaning } from './../../../../../data/const';
import { Component, Input, OnInit, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { AddQuotationItemDialogComponent } from '../add-quotation-item-dialog/add-quotation-item-dialog.component';

@Component({
  selector: 'app-quotation-detail-dialog',
  templateUrl: './quotation-detail-dialog.component.html',
  styleUrls: ['./quotation-detail-dialog.component.scss']
})
export class QuotationDetailDialogComponent implements OnInit {

  @Output() lstQuotDetailUpdated: EventEmitter<any> = new EventEmitter();
  @Input() lstQuotDetails: Array<any> = [];
  @Input() isLockedAction: boolean = false;
  @Input() totalPrice: string = "0.00";
  public typeUser = TypeUserNote;
  public constProcessStatus = ProcessStatusRequestCleaning;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    public modalDialogService: ModalDialogService,
    private modalService: NgbModal,
    public viewRef: ViewContainerRef,
  ) { }

  ngOnInit() {

  }

  openAddQuotationModal(item, index) {
    const dialofRef = this.modalService.open(AddQuotationItemDialogComponent, { size: 'lg', windowClass: 'modal-chatbox' });
    if(item != null){
      dialofRef.componentInstance.itemDetail = item;
    }
    dialofRef.componentInstance.itemCommon.subscribe((res) => {
      if (res['isCreate']) {
        this.lstQuotDetails.push(res['data']);
      } else {
        this.lstQuotDetails[index] = res['data'];
      }

      this.totalPrice = "0.00";
      this.lstQuotDetails.forEach(item=>{
        this.totalPrice = (parseInt(item.totalPrice) + parseInt(this.totalPrice)).toFixed(2);
      });
      console.log(this.totalPrice);
    });
  }

  deleteQuotationItem(item, index) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      childComponent: SimpleModalComponent,
      data: {
        text: "Bạn có chắc chắn muốn xóa mục này?"
      },
      actionButtons: [
        {
          text: 'Đồng ý',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.lstQuotDetails = this.lstQuotDetails.filter(s => s !== item);

            this.totalPrice = "0.00";
            this.lstQuotDetails.forEach(item=>{
              this.totalPrice = (parseInt(item.totalPrice) + parseInt(this.totalPrice)).toFixed(2);
            });
            this.viewRef.clear();
          }
        },
        {
          text: 'Đóng',
          buttonClass: 'btn btn-default',
        }
      ],
    });
  }

  onSubmit() {
    this.lstQuotDetailUpdated.emit({
      lstData: this.lstQuotDetails,
      totalPrice: this.totalPrice
    });

    this.modal.close();
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
  toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
}
