import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PushToken } from '../data/Model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { domainApi } from './../data/const';

@Injectable()
export class AuthService {
  public ItemToken: PushToken;
  public httpOptions: any;

  constructor(private router: Router, private cookieService: CookieService, private http: HttpClient) { }

  logout(): void {
    this.ItemToken = new PushToken();
    this.ItemToken.UserId = parseInt(localStorage.getItem("userId"));
    this.ItemToken.Token = localStorage.getItem("token_notification");
    this.ItemToken.TypeOs = 1;
    if (this.ItemToken.UserId != undefined && this.ItemToken.UserId != null && this.ItemToken.UserId != NaN && this.ItemToken.Token != '' && this.ItemToken.Token != undefined) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'bearer ' + localStorage.getItem("access_token")
        })
      }
      this.http.post(domainApi + '/user/removeToken', this.ItemToken, this.httpOptions).subscribe(
        (res) => {
        },
        (err) => {
          console.log("Error: connect to API");
        });

    }

    localStorage.setItem('isLoggedIn', "false");
    localStorage.removeItem('data');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_key');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('avata');
    localStorage.removeItem('fullName');
    localStorage.removeItem('companyId');
    localStorage.removeItem('token_notification');
    localStorage.removeItem('languageId');
    localStorage.removeItem('menu');
    localStorage.removeItem('Url');
    this.cookieService.deleteAll();
    this.router.navigate(['/login']);
  }

  public getAccessToken() {
    return localStorage.getItem("access_token");
  }
}
