import { ApiConstant, domainApi } from './../data/const';
import { Paging, QueryFilter } from './../data/dt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResidentManagerService {

  constructor(private readonly http: HttpClient) { }

  getByPage(pageOption: QueryFilter, page: Paging, dateAppointment: Date | null, dateFinish: Date | null, statusPayment: number | null, isCancel: boolean): Observable<any> {
    let query = `?ProcessStatus=${pageOption.StatusProcessId || -1}&Type=${pageOption.TypeRequireId || 0}&CurrentPage=${page.page}&PageSize=${page.page_size}`;

    if (pageOption.ProjectId > 0) {
      query = query.concat(`&ProjectId=${pageOption.ProjectId}`);
    }

    if (pageOption.TowerId > 0) {
      query = query.concat(`&TowerId=${pageOption.TowerId}`);
    }

    if (pageOption.FloorId > 0) {
      query = query.concat(`&FloorId=${pageOption.FloorId}`);
    }

    if (pageOption.ApartmentId > 0) {
      query = query.concat(`&ApartmentId=${pageOption.ApartmentId}`);
    }

    if (pageOption.DateTimeStart) {
      query = query.concat(`&DateStart=${pageOption.DateTimeStart}`);
    }

    if (pageOption.DateTimeEnd) {
      query = query.concat(`&DateEnd=${pageOption.DateTimeEnd}`);
    }

    if (page.query) {
      query = query.concat(`&query=${page.query}`);
    }

    if (dateAppointment) {
      query = query.concat(`appointmentDate=${dateAppointment}`);
    }

    if (dateFinish) {
      query = query.concat(`&finishDate=${dateFinish}`);
    }

    if (page.order_by) {
      query = query.concat(`&orderBy=${page.order_by}`);
    }

    if (statusPayment >= 0) {
      query = query.concat(`&statusPayment=${statusPayment}`);
    }

    query = query.concat(`&isCancel=${isCancel}`);

    return this.http.get(`${ApiConstant.GetRequestGroupByPage}${query}`);
  }

  getDetail(id: number): Observable<any> {
    return this.http.get(ApiConstant.DetailRequestGroup + id).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }
        return null;
      })
    ); ;
  }
}
