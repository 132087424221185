import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ServiceOrderItem } from '../../../../../data/Model';

@Component({
  selector: 'app-service-tech-price',
  templateUrl: './service-tech-price.component.html',
  styleUrls: ['./service-tech-price.component.scss']
})
export class ServiceTechPriceComponent implements OnInit {

  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  public Item: ServiceOrderItem;
  public listEmployees = [];
  public listPositions = [];

  public httpOptions: any;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.Item = new ServiceOrderItem();
    this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
   }

  ngOnInit() {
    //console.log(this.itemDetail)
    if (this.itemDetail) {
      this.Item.Contents = this.itemDetail["Contents"];
      this.Item.UnitName = this.itemDetail["UnitName"];
      this.Item.Quantity = this.itemDetail["Quantity"];
      this.Item.Price = this.itemDetail["Price"];
      this.Item.TotalPrice = this.itemDetail["TotalPrice"];
    }
    else{
      this.Item.Quantity = 1;
      this.Item.Price = 0;
      this.Item.TotalPrice = 0;
    }

  }

  ChangeTotal() {
    //console.log(this.itemDetail)
    if (this.Item.Quantity && this.Item.Price) {
      this.Item.TotalPrice = this.Item.Quantity * this.Item.Price;
    }
    else this.Item.TotalPrice = 0;

  }
  
  onSubmit() {
    if (!this.Item.Contents) {
      this.toastWarning("Vui lòng nhập nội dung công việc");
      return;
    }
    if (!this.Item.Quantity) {
      this.toastWarning("Vui lòng nhập số lượng");
      return;
    } 
    if (this.Item.Price < 0) {
      this.toastWarning("Vui lòng nhập đơn giá");
      return;
    }
    if (this.Item.TotalPrice < 0) {
      this.toastWarning("Vui lòng nhập thành tiền");
      return;
    }
    this.itemCommon.emit(this.Item);
    this.modal.close();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }


  omit_special_char(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return true;
    }
    return false;
  }

  onChangePrice(value) {
    this.Item.Price = Number(value.replace(/[^0-9.-]+/g, ''));
  }

}
