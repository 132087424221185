import { TechnicalNewService } from '../../../../../service/technical-new.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogService } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cancel-technical-request-dialog',
  templateUrl: './cancel-technical-request-dialog.component.html',
  styleUrls: ['./cancel-technical-request-dialog.component.scss']
})
export class CancelTechnicalRequestDialogComponent implements OnInit {
  @Output() response: EventEmitter<any> = new EventEmitter();
  @Input() id: number;
  public isLoadingCancel = false;
  public message = '';

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly technicalService: TechnicalNewService
  ) { }

  ngOnInit() {
    this.message = '';
  }

  onSubmit() {
    if (!this.message) {
      this.toastWarning("Vui lòng nhập lí do từ chối đăng ký thẻ!");
      return;
    }

    this.isLoadingCancel = true;
    // this.technicalService.cancelById(this.id, this.message ).subscribe(
    //   (res) => {
    //     if (res['meta']['error_code'] == 200) {
    //       this.toastSuccess("Hủy thành công!");
    //       this.isLoadingCancel = false;

    //       this.response.emit(res["data"]);
    //       this.modal.close();
    //     } else {
    //       this.isLoadingCancel = false;
    //       this.toastError(res["meta"]["error_message"]);
    //       this.modal.close();
    //     }
    //   },
    //   (error) => {
    //     this.isLoadingCancel = false;
    //     this.toastError(error["meta"]["error_message"]);
    //     this.modal.close();
    //   });
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
  toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
}
