import { domainApi } from './../../../../../data/const';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoUserAssignedCleaning } from './../../../../../data/Model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assignment-work-dialog',
  templateUrl: './assignment-work-dialog.component.html',
  styleUrls: ['./assignment-work-dialog.component.scss']
})
export class AssignmentWorkDialogComponent implements OnInit {
  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  @Input() projectId: number;
  @Input() towerId: number;
  @Input() isC_One: boolean = false;
  @Input() lstUserAssigned: Array<InfoUserAssignedCleaning>;
  public lstUsers = [];
  public item: InfoUserAssignedCleaning;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.item = new InfoUserAssignedCleaning();
   }

  ngOnInit() {
    if (this.itemDetail) {
      this.item = {...this.itemDetail};
    }

    this.getLstUserActive();
  }

  onSelectEmp() {
    if (this.item.userId != undefined) {
      //kiểm tra xem có trùng ko
      const itemEmplyee = this.lstUsers.find(s => s.UserId == this.item.userId);

      if (itemEmplyee) {
        this.item.employeeId = itemEmplyee.EmployeeId;
        this.item.userName = itemEmplyee.FullName;
        this.item.userPhone = itemEmplyee.Phone;
        this.item.userCode = itemEmplyee.Code;
        this.item.userRole = itemEmplyee.PositionName;
      }
		}
  }


  onSubmit() {


    this.itemCommon.emit({
      isCreate: this.itemDetail ? false : true,
      data: this.item
    });
    this.modal.close();
  }

  getLstUserActive() {
    this.http.get(domainApi + '/userRole/GetUserByRoleQuery/'+ this.projectId + '/DVVS?towerId=' + this.towerId).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstUsers = res["data"];
          this.lstUsers.map(s => s['disabled'] = false);

          if (this.itemDetail) {
            const isExistUser = this.lstUsers.find(s => s.UserId == this.itemDetail.userId);

            if (!isExistUser) {
              this.lstUsers.unshift({
                UserId: this.itemDetail.userId,
                FullName: this.itemDetail.userName,
                Phone: this.itemDetail.userPhone,
                EmployeeId: this.itemDetail.employeeId,
                Code: this.itemDetail.userCode,
                PositionId: 0,
                PositionName: this.itemDetail.userRole,
                disabled: true
              })
            }

            if (this.lstUserAssigned.length) {
              for(let i = 0 ; i < this.lstUserAssigned.length; i ++) {
                const isSelected = this.lstUsers.find(s => s.UserId == this.lstUserAssigned[i].userId);

                if (isSelected) {
                  this.lstUserAssigned[i]['disabled'] = true;
                  [...this.lstUserAssigned];
                }
              }
            }
          }

          for(let i = 0 ; i < this.lstUsers.length ; i ++) {
            const isAdded = this.lstUserAssigned.find(s => s.userId == this.lstUsers[i].UserId);

            if (isAdded) {
              this.lstUsers[i]['disabled'] = true;
            }
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
}
