import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Province } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi } from '../../../data/const';
import { Title } from "@angular/platform-browser";

@Component({
	selector: 'app-province',
	templateUrl: './province.component.html',
	styleUrls: ['./province.component.scss']
})
export class ProvinceComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;

	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public countries = [];

	public Item: Province;

	public httpOptions: any;

	public submitted = false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
		this.Item = new Province();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "ProvinceId Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Danh sách tỉnh/thành phố");
		this.GetListCommon();
		this.GetListCountry();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = '';
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and (Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
			}
			else {
				query += '(Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';

			}
		}

		if (this.q["CountryId"] != undefined) {
			if (query != '') {
				query += ' and CountryId=' + this.q["CountryId"];
			}
			else {
				query += 'CountryId=' + this.q["CountryId"];
			}
		}

		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(ProvinceId) {
		if (ProvinceId == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				let data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.ProvinceId);
					}
				});
				if (data.length == 0) {
					this.toastWarning("Chưa chọn mục cần xóa!");
				}
				else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
						data: {
							text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + '/Province/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												this.toastSuccess("Xóa thành công!");
												setTimeout(() => {
                          this.GetListCommon();
                        }, 1500);
												this.ActionId = undefined;
											}
											else {
												this.toastError(res["meta"]["error_message"]);
											}
										},
										(err) => {
											this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	//Get danh sách chức năng
	GetListCommon() {
		this.http.get(domainApi + '/Province/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listCommon = res["data"];
					this.paging.item_count = res["metadata"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenCommonModal(item) {
		this.submitted = false;
		this.Item = new Province();
		if (item == undefined) {
			if (this.q["CountryId"]) {
				this.Item.CountryId = this.q["CountryId"];
			}
		}
		else {
			this.Item = Object.assign(this.Item, item);
		}

		this.modalCommon.show();
	}

	SaveCommon() {
		this.submitted = true;

		let data = Object.assign({}, this.Item);

		if (data.ProvinceId == undefined) {
			this.http.post(domainApi + '/Province', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						setTimeout(() => {
              this.GetListCommon();
            }, 1500);
						this.modalCommon.hide();
						this.toastSuccess("Thêm mới thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.http.put(domainApi + '/Province/' + data.ProvinceId, data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						setTimeout(() => {
              this.GetListCommon();
            }, 1500);
						this.modalCommon.hide();
						this.toastSuccess("Cập nhật thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/Province/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	//Danh sách quốc gia
	GetListCountry() {
		this.http.get(domainApi + '/country/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=CountryId,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.countries = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	ShowCountryName(CountryId) {
		let country = this.countries.find(x => x.CountryId == CountryId);

		return country ? country.Name : "";
	}
}
