import { feedBackInfo } from '../../../../data/Model';
import { ConstructionAcceptanceFormStatus, ExtensionConstructionStatus, ContractorStatus, RegConstructionStatus, ConstructionStatus, DepositType } from './../../../../data/const';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import * as moment from 'moment';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class FeedBackDialogComponent implements OnInit {
    @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
    @Input() itemDetail: feedBackInfo;
    @Input() RegisterConstructionFormId: number;

    public item: feedBackInfo;
    public depositType = DepositType;
    public isLoading = false;

    public constructionAcceptanceFormStatus = ConstructionAcceptanceFormStatus;
    public extensionConstructionStatus = ExtensionConstructionStatus;
    public contractorStatus = ContractorStatus;
    public regConstructionStatus = RegConstructionStatus;
    public constructionStatus = ConstructionStatus;

    constructor(
        public modal: NgbActiveModal,
        private readonly http: HttpClient,
        private readonly toastr: ToastrService,
        private gallery: Gallery
    ) {
        this.item = new feedBackInfo();
    }

    ngOnInit() {
        if (this.itemDetail) {
            this.item = this.itemDetail;
        }
        else {
            this.item.RegisterConstructionFormId = this.RegisterConstructionFormId;
        }
    }

    onSubmit() {
        if(this.item.TypeFeedBack == 1) {
            if(!this.item.ConstructionStatus) {
                this.toastWarning("Chưa có thông tin Trạng thái thi công");
                return;
            }
        }

        if(!this.item.TargetStatus) {
            this.toastWarning("Chưa có thông tin Trạng thái");
            return;
        }
        
        if (this.item.FeedBackToResident == "" || this.item.FeedBackToResident == undefined) {
            this.toastWarning("Vui lòng nhập Thông tin phản hồi");
            return;
        }

        if(this.item.FeedBackToResident.trim() == "") {
            this.toastWarning("Vui lòng nhập Thông tin phản hồi");
            return;
        }

        this.eventEmitter.emit(this.item);
        this.modal.close();
    }

    onCheckboxChanged(value) {

    }

    omit_special_char(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return true;
        }
        return false;
    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
}
