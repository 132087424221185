import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Cash } from '../../../data/Model';
import { FormControl, NgForm } from '@angular/forms';
import { ActionTable, typeCash, domainApi } from '../../../data/const';
import { Title } from "@angular/platform-browser";
import * as _moment from 'moment';
import { Moment } from 'moment';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
    parseInput: 'MM/YYYY',
    fullPickerInput: 'l LT',
    datePickerInput: 'MM/YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};


const moment = (_moment as any).default ? (_moment as any).default : _moment;


@Component({
    selector: 'app-order-service-utilities',
    templateUrl: './order-service-utilities.component.html',
    styleUrls: [],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS }
    ]
})
export class OrderServiceUtilitiesComponent implements OnInit {
    @ViewChild('modalCommon') public modalCommon: ModalDirective;

    public paging: Paging;
    public q: QueryFilter;

    public listCommon = [];
    public listCashItems = [];
    public isShowFilter = false;
    public projects = [];
    public filterTowers = [];
    public filterFloors = [];
    public filterApartments = [];

    public Item: Cash;
    public httpOptions: any;

    public submitted = false;

    public ActionTable = ActionTable;
    public ActionId: number;
    public CheckAll: boolean;

    public date = new FormControl(moment());

    public typeCash = typeCash;

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.date.setValue(ctrlValue);
        datepicker.close();
    }

    constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
        this.Item = new Cash();
        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 10;
        this.paging.query = 'Type!=null and TargetId!=null';
        this.paging.order_by = "CreatedAt Desc";
        this.paging.item_count = 0;

        this.q = new QueryFilter();
        this.q.txtSearch = "";

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Danh sách hóa đơn dịch vụ, tiện ích");
        this.QueryChanged(true);
        this.GetListProject();
    }

    PageChanged(event) {
        this.paging.page = event.page;
        this.QueryChanged(true);
    }
    //Toast cảnh báo
    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    QueryChanged(callApi: boolean) {
        // if(this.date) {
        //     if(this.date.valid != true) {
        //         this.toastWarning("Tháng/Năm không hợp lệ");
        //         return;
        //     }
        // }

        let query = 'Type!=null and TargetId!=null';

        if (this.q["ProjectId"] != undefined) {
            if (query != '') {
                query += ' and ProjectId=' + this.q["ProjectId"];
            }
            else {
                query += 'ProjectId=' + this.q["ProjectId"];
            }
        }

        if (this.q["TowerId"] != undefined) {
            if (query != '') {
                query += ' and TowerId=' + this.q["TowerId"];
            }
            else {
                query += 'TowerId=' + this.q["TowerId"];
            }
        }

        if (this.q["FloorId"] != undefined) {
            if (query != '') {
                query += ' and FloorId=' + this.q["FloorId"];
            }
            else {
                query += 'FloorId=' + this.q["FloorId"];
            }
        }

        if (this.q["ApartmentId"] != undefined) {
            if (query != '') {
                query += ' and ApartmentId=' + this.q["ApartmentId"];
            }
            else {
                query += 'ApartmentId=' + this.q["ApartmentId"];
            }
        }

        if (this.q["Type"] != undefined) {
            if (query != '') {
                query += ' and Type=' + this.q["Type"];
            }
            else {
                query += 'Type=' + this.q["Type"];
            }
        }

        if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
            if (query != '') {
                query += ' and (Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or MerchTxnRef.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
            }
            else {
                query += '(Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or MerchTxnRef.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
            }
        }

        if (query == '')
            this.paging.query = '1=1';
        else
            this.paging.query = query;

        if(callApi) this.GetListCommon();
    }

    SortTable(str) {
        let First = "";
        let Last = "";
        if (this.paging.order_by != "") {
            First = this.paging.order_by.split(" ")[0];
            Last = this.paging.order_by.split(" ")[1];
        }

        if (First != str) {
            this.paging.order_by = str + " Desc";
        }
        else {
            Last = Last == "Asc" ? "Desc" : "Asc";
            this.paging.order_by = str + " " + Last;
        }

        this.GetListCommon();
    }

    GetClassSortTable(str) {
        if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
            return "sorting";
        }
        else {
            if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
            else return "sorting_asc";
        }
    }

    CheckActionTable(CashId) {
        if (CashId == undefined) {
            let CheckAll = this.CheckAll;
            this.listCommon.forEach(item => {
                item.Action = CheckAll;
            });
        }
        else {
            let CheckAll = true;
            for (let i = 0; i < this.listCommon.length; i++) {
                if (!this.listCommon[i].Action) {
                    CheckAll = false;
                    break;
                }
            }

            this.CheckAll = CheckAll == true ? true : false;
        }
    }

    ActionTableFunc() {
        switch (this.ActionId) {
            case 1:
                let data = [];
                this.listCommon.forEach(item => {
                    if (item.Action == true) {
                        data.push(item.CashId);
                    }
                });
                if (data.length == 0) {
                    this.toastWarning("Chưa chọn mục cần xóa!");
                }
                else {
                    this.modalDialogService.openDialog(this.viewRef, {
                        title: 'Xác nhận',
                        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
                        data: {
                            text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
                        },
                        actionButtons: [
                            {
                                text: 'Đồng ý',
                                buttonClass: 'btn btn-success',
                                onAction: () => {
                                    this.http.put(domainApi + '/Cash/deletes', data, this.httpOptions).subscribe(
                                        (res) => {
                                            if (res["meta"]["error_code"] == 200) {
                                                this.toastSuccess("Xóa thành công!");
                                                this.GetListCommon();
                                                this.ActionId = undefined;
                                            }
                                            else {
                                                this.toastError(res["meta"]["error_message"]);
                                            }
                                        },
                                        (err) => {
                                            this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                                        }
                                    );
                                    this.viewRef.clear();
                                }
                            },
                            {
                                text: 'Đóng',
                                buttonClass: 'btn btn-default',
                            }
                        ],
                    });
                }
                break;
            default:
                break;
        }
    }

    GetListCommon() {
        this.http.get(domainApi + '/Invoice/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listCommon = res["data"];
                    this.paging.item_count = res["metadata"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    GetListProject() {
        this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.projects = res["data"];
                    if (this.projects.length == 1) {
                        this.q.ProjectId = this.projects[0].ProjectId;
                        this.QueryChangeProject();
                    }
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    QueryChangeProject() {
        this.q["TowerId"] = undefined;
        this.filterTowers = [];
        if (this.q["ProjectId"]) {
            let query = "ProjectId=" + this.q["ProjectId"];
            this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.filterTowers = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }

        this.QueryChangeTower();
    }

    QueryChangeTower() {
        this.q["FloorId"] = undefined;
        this.filterFloors = [];
        if (this.q["TowerId"]) {
            let query = "TowerId=" + this.q["TowerId"];
            this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.filterFloors = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }

        this.QueryChangeFloor();
    }

    QueryChangeFloor() {
        this.q["ApartmentId"] = undefined;
        this.filterApartments = [];
        if (this.q["TowerId"]) {
            let query = "TowerId=" + this.q["TowerId"];
            query = this.q.FloorId != undefined ? (query + " AND FloorId=" + this.q.FloorId) : query;

            this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.filterApartments = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }
    }

    FindPaymentStatus(id) {
        if (id == 1) {
            return "Chưa thanh toán"
        }
        else if (id == 2) {
            return "Đã thanh toán hết"
        }
        else if (id == 3) {
            return "Thanh toán một phần"
        }
        else if (id == 4) {
            return "Không thanh toán"
        }
        else if (id == 5) {
            return "Thanh toán lỗi"
        }
    }

    OpenCommonModal(item) {
        this.listCashItems = [];

        this.submitted = false;
        this.Item = new Cash();

        if (item == undefined) {
        }
        else {
            let condition = "CashId=" + item.Id + " AND Status!=99";

            this.http.get(domainApi + '/Invoice/GetListCashItem?condition=' + condition, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listCashItems = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
        }

        this.modalCommon.show();
    }
    openToggleFilter(){
		this.isShowFilter = !this.isShowFilter;
	}
}
