import { ApiConstant, domainApi } from './../data/const';
import { Paging, QueryFilter } from './../data/dt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RentalApartmentService {

  constructor(private readonly http: HttpClient) { }

  getByPage(pageOption: QueryFilter, page: Paging, dateAppointment: Date | null, dateFinish: Date | null, statusPayment: number | null): Observable<any> {
    let query = `?ProcessStatus=${pageOption.StatusProcessId || -1}&CurrentPage=${page.page}&PageSize=${page.page_size}`;

    if (pageOption.ProjectId > 0) {
      query = query.concat(`&ProjectId=${pageOption.ProjectId}`);
    }

    if (pageOption.TowerId > 0) {
      query = query.concat(`&TowerId=${pageOption.TowerId}`);
    }

    if (pageOption.FloorId > 0) {
      query = query.concat(`&FloorId=${pageOption.FloorId}`);
    }

    if (pageOption.ApartmentId > 0) {
      query = query.concat(`&ApartmentId=${pageOption.ApartmentId}`);
    }

    if (pageOption.DateTimeStart) {
      query = query.concat(`&DateStart=${ new Date(moment(pageOption.DateTimeStart.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}) }`);
    }

    if (pageOption.DateTimeEnd) {
      query = query.concat(`&DateEnd=${ new Date(moment(pageOption.DateTimeEnd.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}) }`);
    }

    if (page.query) {
      query = query.concat(`&query=${page.query}`);
    }

    if (dateAppointment) {
      query = query.concat(`appointmentDate=${dateAppointment}`);
    }

    if (dateFinish) {
      query = query.concat(`&finishDate=${dateFinish}`);
    }

    if (page.order_by) {
      query = query.concat(`&orderBy=${page.order_by}`);
    }

    if (statusPayment) {
      query = query.concat(`&statusPayment=${statusPayment}`);
    }

    if (pageOption.Type > 0) {
      query = query.concat(`&Type=${pageOption.Type}`);
    }
    else {
      query = query.concat(`&Type=-1`);
    }

    if (pageOption.TypePayment > 0) {
      query = query.concat(`&TypePayment=${pageOption.TypePayment}`);
    }

    if (pageOption.CanCelStatus) {
      query = query.concat(`&Status=10`);
    }
    else {
      // query = query.concat(`&Status=-1`);
    }

    return this.http.get(`${domainApi}/RequestGroup/RequestRentalApartmentPageGet${query}`);
  }

  getDetail(id: number): Observable<any> {
    return this.http.get(domainApi + '/RequestGroup/RequestRentalApartmentGet/' + id).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }
        return null;
      })
    ); ;
  }

  getConfigRentalByPage(pageOption: QueryFilter, page: Paging, dateAppointment: Date | null, dateFinish: Date | null, statusPayment: number | null): Observable<any> {
    let query = `?CurrentPage=${page.page}&PageSize=${page.page_size}`;

    if (pageOption.ProjectId > 0) {
      query = query.concat(`&ProjectId=${pageOption.ProjectId}`);
    }

    if (pageOption.TowerId > 0) {
      query = query.concat(`&TowerId=${pageOption.TowerId}`);
    }

    if (page.query) {
      query = query.concat(`&query=${page.query}`);
    }

    if (page.order_by) {
      query = query.concat(`&orderBy=${page.order_by}`);
    }

    return this.http.get(`${domainApi}/RequestGroup/RequestRentalApartmentConfigPageGet${query}`);
  }
}
