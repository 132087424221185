import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { TypeAttribute, TypeAttributeItem } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domain, domainApi, domainImage } from '../../../data/const';
import { Title } from '@angular/platform-browser';
import { Gallery } from 'angular-gallery';

@Component({
	selector: 'app-type-attribute',
	templateUrl: './type-attribute.component.html',
	styleUrls: ['./type-attribute.component.scss']
})
export class TypeAttributeComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;
	@ViewChild('TypeModal') public TypeModal: ModalDirective;
	@ViewChild('fChild') public formChild: NgForm;
  @ViewChild('file') file: ElementRef;

	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];

	public Item: TypeAttribute;
	public newType: TypeAttributeItem;

	public httpOptions: any;

	public submitted = false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;
  public domainImage = domainImage;

	constructor(
    public http: HttpClient,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    public toastr: ToastrService,
    public titleService: Title,
    private gallery: Gallery) {
		this.Item = new TypeAttribute();
		this.newType = new TypeAttributeItem();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = '1=1';
		this.paging.order_by = 'TypeAttributeId Desc';
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = '';

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem('access_token')
			})
		};
	}

	ngOnInit() {
		this.titleService.setTitle('S Plus - Danh sách quận/huyện');
		this.GetListCommon();
	}

  upload(files) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    for (const file of files) {
      formData.append(file.name, file);
    }

    const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

      } else if (event.type === HttpEventType.Response) {
        if (event.body['data'].length) {
          this.newType.Image = event.body['data'][0];
        }
        if (this.file) { this.file.nativeElement.value = ''; }
      }
    });
  }

	// Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	// Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	// Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	// Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//

  onShowGallery(index: number) {
    let lstImageShow = [];
    lstImageShow.unshift({path: domainImage + this.newType.Image });

    let prop = {
      images: lstImageShow,
      index
    };

    this.gallery.load(prop);
  }

  onRemoveImage() {
    this.newType.Image = null;
  }
	QueryChanged() {
		let query = '';
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';
			} else {
				query += 'Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';

			}
		}

		if (query == '') {
			this.paging.query = '1=1';
		} else {
			this.paging.query = query;
		}

		this.GetListCommon();
	}

	SortTable(str) {
		let First = '';
		let Last = '';
		if (this.paging.order_by != '') {
			First = this.paging.order_by.split(' ')[0];
			Last = this.paging.order_by.split(' ')[1];
		}

		if (First != str) {
			this.paging.order_by = str + ' Desc';
		} else {
			Last = Last == 'Asc' ? 'Desc' : 'Asc';
			this.paging.order_by = str + ' ' + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + ' Desc') && this.paging.order_by != (str + ' Asc')) {
			return 'sorting';
		} else {
			if (this.paging.order_by == (str + ' Desc')) { return 'sorting_desc'; } else { return 'sorting_asc'; }
		}
	}

	CheckActionTable(TypeAttributeId) {
		if (TypeAttributeId == undefined) {
			const CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		} else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				const data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.TypeAttributeId);
					}
				});
				if (data.length == 0) {
					this.toastWarning('Chưa chọn mục cần xóa!');
				} else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
						data: {
							text: 'Bạn có chắc chắn muốn xóa các mục đã chọn?'
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + '/TypeAttribute/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res['meta']['error_code'] == 200) {
												this.toastSuccess('Xóa thành công!');
												setTimeout(() => {
                          this.GetListCommon();
                        }, 1500);
												this.ActionId = undefined;
											} else {
												this.toastError(res['meta']['error_message']);
											}
										},
										(err) => {
											this.toastError('Đã xảy ra lỗi. Xin vui lòng thử lại sau!');
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	// Get danh sách chức năng
	GetListCommon() {
		this.http.get(domainApi + '/TypeAttribute/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
			(res) => {
				if (res['meta']['error_code'] == 200) {
					this.listCommon = res['data'];
					this.paging.item_count = res['metadata'];
				}
			},
			(err) => {
				console.log('Error: connect to API');
			});
	}

	OpenCommonModal(item) {
		this.submitted = false;
		this.Item = new TypeAttribute();
		this.newType = new TypeAttributeItem();
		this.Item.listAttributeItem = [];
		if (item == undefined) {
		} else {
			this.Item = JSON.parse(JSON.stringify(item));
		}

		this.modalCommon.show();
	}

	SaveCommon() {
		this.submitted = true;

		const data = Object.assign({}, this.Item);
		data.UserId = parseInt(localStorage.getItem('userId'));

		if (data.TypeAttributeId == undefined) {
			this.http.post(domainApi + '/TypeAttribute', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res['meta']['error_code'] == 200) {
						setTimeout(() => {
              this.GetListCommon();
            }, 1500);
						this.modalCommon.hide();
						this.toastSuccess('Thêm mới thành công!');
						this.form.resetForm();
					} else {
						this.toastError(res['meta']['error_message']);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError('Đã xảy ra lỗi. Xin vui lòng thử lại sau!');
				}
			);
		} else {
			this.http.put(domainApi + '/TypeAttribute/' + data.TypeAttributeId, data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res['meta']['error_code'] == 200) {
						setTimeout(() => {
              this.GetListCommon();
            }, 1500);
						this.modalCommon.hide();
						this.toastSuccess('Cập nhật thành công!');
						this.form.resetForm();
					} else {
						this.toastError(res['meta']['error_message']);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError('Đã xảy ra lỗi. Xin vui lòng thử lại sau!');
				}
			);
		}
	}

	// Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: 'Bạn có chắc chắn muốn xóa mục này?'
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/TypeAttribute/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res['meta']['error_code'] == 200) {
									setTimeout(() => {
                    this.GetListCommon();
                  }, 1500);
									this.viewRef.clear();
									this.toastSuccess('Xóa thành công!');
								} else {
									this.toastError(res['meta']['error_message']);
								}
							},
							(err) => {
								this.toastError('Đã xảy ra lỗi. Xin vui lòng thử lại sau!');
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	// Mở modal thêm TypeAttributeItem
	OpenTypeModalModal(i) {
		this.newType = new TypeAttributeItem();
		if (i != undefined) {
			this.newType = JSON.parse(JSON.stringify(this.Item.listAttributeItem[i]));
			this.newType['Idx'] = i;
		}
		this.TypeModal.show();
	}

	// Thêm TypeAttributeItem
	SaveTypeItem() {
		if (this.newType['Idx'] == undefined) {
			this.newType.Status = 1;
			this.Item.listAttributeItem.push(JSON.parse(JSON.stringify(this.newType)));
		} else {
			this.Item.listAttributeItem[this.newType['Idx']] = JSON.parse(JSON.stringify(this.newType));
		}

		this.TypeModal.hide();
		this.formChild.resetForm();
	}

	// xóa AttributeItem
	DeleteItem(i) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      settings: {
        footerClass: 'footer-dialog modal-footer',
      },
      childComponent: SimpleModalComponent,
      data: {
        text: "Bạn chắc muốn xóa thuộc tính này không?"
      },
      actionButtons: [
        {
          text: 'Hủy',
          buttonClass: 'btn btn-default',
        },
        {
          text: 'Xác nhận',
          buttonClass: 'btn btn-success',
          onAction: () => {
            if (this.Item.TypeAttributeId == undefined) {
              this.Item.listAttributeItem.splice(i, 1);
            } else {
              if (this.Item.listAttributeItem[i].TypeAttributeItemId == undefined) {
                this.Item.listAttributeItem.splice(i, 1);
              } else {
                this.Item.listAttributeItem[i].Status = 99;
              }
            }

            this.viewRef.clear();
          }
        }
      ],
    });
	}
}
