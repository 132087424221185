import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ServiceOrderEmp } from '../../../../../data/Model';
import { domainApi } from '../../../../../data/const';

@Component({
  selector: 'app-service-tech-emp',
  templateUrl: './service-tech-emp.component.html',
  styleUrls: ['./service-tech-emp.component.scss']
})
export class ServiceTechEmpComponent implements OnInit {
  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  @Input() listEmps: any;
  @Input() listEmployees: any;
  @Input() listPositions: any;
  public Item: ServiceOrderEmp;
  // public listEmployees = [];
  // public listPositions = [];

  public httpOptions: any;
  public isCreate: boolean;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.Item = new ServiceOrderEmp();
    this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
   }

  ngOnInit() {
    this.isCreate = true;
    if (this.itemDetail) {
      this.isCreate = false;
      this.Item.EmployeeId = this.itemDetail["EmployeeId"];
      this.Item.PositionId = this.itemDetail["PositionId"];
      this.Item.EmployeeCode = this.itemDetail["EmployeeCode"];
      this.Item.PositionName = this.itemDetail["PositionName"];
      this.Item.Phone = this.itemDetail["Phone"];
    }

  }

 

  // GetListPositionById(id) {
  
  //   this.http.get(domainApi + '/position/'+id, this.httpOptions).subscribe(
  //     (res) => {
  //       if (res["meta"]["error_code"] == 200) {
  //         this.listPositions = res["data"];
  //       }
  //     },
  //     (err) => {
  //       console.log("Error: connect to API");
  //     });
  // }

  onSelectEmp() {
    if (this.Item.EmployeeId != undefined) {
      //kiểm tra xem có trùng ko
      if(this.isCreate){
        let check=false;
        for (let i = 0; i < this.listEmps.length; i++) {
          if (this.Item.EmployeeId == this.listEmps[i].EmployeeId) {
            check = true;
            break;
          }
        }
        if(check){
          this.toastWarning("Nhân viên đã được chọn!");
          return;
        }
      }
      //
			for (let i = 0; i < this.listEmployees.length; i++) {
				if (this.Item.EmployeeId == this.listEmployees[i].EmployeeId) {
					this.Item.EmployeeName = this.listEmployees[i].FullName;
					this.Item.EmployeeCode = this.listEmployees[i].Code;
					this.Item.Phone = this.listEmployees[i].Phone;
					this.Item.PositionId = this.listEmployees[i].PositionId;
          if (this.Item.PositionId != undefined) {
            for (let j = 0; j < this.listPositions.length; j++) {
              if (this.Item.PositionId == this.listPositions[j].Id) {
                this.Item.PositionName = this.listPositions[j].Name;
                break;
              }
            }
          }
					break;
				}
			}
		}
  }

  
  onSubmit() {
    if (!this.Item.EmployeeId) {
      this.toastWarning("Vui lòng chọn nhân viên!");
      return;
    }

    if (this.Item.EmployeeId != undefined) {
      //kiểm tra xem có trùng ko
      if(this.isCreate){
        let check=false;
        for (let i = 0; i < this.listEmps.length; i++) {
          if (this.Item.EmployeeId == this.listEmps[i].EmployeeId) {
            check = true;
            break;
          }
        }
        if(check){
          this.toastWarning("Nhân viên đã được chọn!");
          return;
        }
      }
      //
			for (let i = 0; i < this.listEmployees.length; i++) {
				if (this.Item.EmployeeId == this.listEmployees[i].EmployeeId) {
					this.Item.EmployeeName = this.listEmployees[i].FullName;
					this.Item.EmployeeCode = this.listEmployees[i].Code;
					this.Item.PositionId = this.listEmployees[i].PositionId;
          if (this.Item.PositionId != undefined) {
            for (let j = 0; j < this.listPositions.length; j++) {
              if (this.Item.PositionId == this.listPositions[j].Id) {
                this.Item.PositionName = this.listPositions[j].Name;
                break;
              }
            }
          }
					break;
				}
			}
		}

		
    this.itemCommon.emit(this.Item);
    this.modal.close();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }

}
