import { ApartmentResident } from '../../../../data/Model';
import { resMotorcycleCard, resResidentCard } from '../../../../data/Model';
import { domain, domainApi, domainImage, typeVehicle } from './../../../../data/const';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-change-motorcycle-card',
  templateUrl: './change-motorcycle-card.component.html',
  styleUrls: ['./change-motorcycle-card.component.scss']
})
export class ChangeMotorcycleCardComponent implements OnInit {
  @Output() itemMotorcycleCard: EventEmitter<any> = new EventEmitter();
  @ViewChild('file') file: ElementRef;
  @Input() itemDetail: resMotorcycleCard;
  @Input() apartmentId: number;
  @Input() oldProcessStatus: number;
  public  progressUpload : number = 0;
  public  dataUpload : any = null;
  public lstVerhicle = [];
  public lstResidentCard = [];
  public lstAllResidentCard = [];
  public item: resMotorcycleCard;
  public domainImage = domainImage;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private gallery: Gallery
  ) {
    this.item = new resMotorcycleCard();
   }

  ngOnInit() {
    this.getLstVehicle();
    this.getResidentCard();
    this.getDataCard(this.itemDetail);
  }

  onSubmit(){
    if (this.item.vehicleTypeId === 0) {
      this.toastWarning("Vui lòng chọn loại phương tiện");
      return;
    } else if (!this.item.vehicleOwner) {
      this.toastWarning("Vui lòng nhập chủ phương tiện");
      return;
    }

    this.itemMotorcycleCard.emit(this.item);
    this.modal.close();
  }

  getDataCard(itemCard) {
    this.item.cardRequestId = itemCard.cardRequestId || 0;
    this.item.residentCardId = itemCard.residentCardId || 0;
    this.item.residentCardIntegratedId = itemCard.residentCardIntegratedId || 0;
    this.item.residentId = itemCard.residentId;
    this.item.residentName = itemCard.residentName;
    this.item.vehicleOwner = itemCard.vehicleOwner;
    this.item.vehicleBrand = itemCard.vehicleBrand;
    this.item.vehicleName = itemCard.vehicleName;
    this.item.vehicleNumerPlate = itemCard.vehicleNumerPlate;
    this.item.vehicleColor = itemCard.vehicleColor;
    this.item.vehicleDes = itemCard.vehicleDes;
    this.item.vehicleTypeId = itemCard.vehicleTypeId;
    this.item.files = itemCard.files || [];
  }

  getResidentCard(){
    this.http.get(domain + 'api/Reg/getResidentCard?apartmentId=' + this.apartmentId).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstAllResidentCard = res["data"];
          this.lstResidentCard.map(s => s.disabled = true);

          const isExist = this.lstAllResidentCard.find(s => s.residentCardId === this.itemDetail.residentCardIntegratedId);

          if (!isExist) {
            this.lstAllResidentCard.unshift({
              residentCardId: this.itemDetail.residentCardIntegratedId,
              cardName: this.itemDetail.cardName,
              residentName: this.itemDetail.residentName,
            });
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  getLstVehicle(){
    this.http.get(domain + 'api/Reg/vehicleType?type=' + typeVehicle.MOTORCLE).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstVerhicle = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
    lstImage.push({path: domainImage + item.url})
    })

    let prop = {
        images: lstImage,
        index
    };
    this.gallery.load(prop);
  }

  upload(files){
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

      }
      else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.files.unshift({
              id: 0,
              fileId: 0,
              fileRef: '',
              url: element.toString()
            });
          });
        }
        if (this.file) this.file.nativeElement.value = "";
      }
    });
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
  }
}
