import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceOrder } from '../../../../../data/Model';
import { ToastrService } from 'ngx-toastr';
import { domainApi, domain, listAcceptanceStatus, typeServiceRate } from '../../../../../data/const';

@Component({
    selector: 'app-cancel-service-rental',
    templateUrl: './cancel-service.component.html'
})
export class CancelServiceComponent implements OnInit {

    @Output() result: EventEmitter<any> = new EventEmitter();
    @Input() id: number;
    public noteCancel: string;
    public isLoading: boolean;
    public httpOptions: any;

    constructor(
        public modal: NgbActiveModal,
        private readonly toastr: ToastrService,
        private readonly http: HttpClient
    ) {
    }

    ngOnInit() {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    onSubmit() {
        if (!this.noteCancel) {
            this.toastr.warning("Vui lòng nhập Lý do hủy", "Cảnh báo");
            return;
        }

        if (this.noteCancel == "" || this.noteCancel.trim() == "") {
            this.toastr.warning("Vui lòng nhập Lý do hủy", "Cảnh báo");
            return;
        }


        this.isLoading = true;

        let data = {
            input: this.id,
            noteCancel: this.noteCancel
        };
        this.http.post(domain + 'api/cms/RequestGroup/RequestRentalApartmentCancel', data, this.httpOptions).subscribe(
            (res) => {
                this.isLoading = false;
                if (res["meta"]["error_code"] == 200) {
                    this.toastr.success("Hủy phiếu thành công!", "Thông báo");
                    this.result.emit(true);
                    this.modal.close();
                }
                else {
                    this.toastr.error(res["meta"]["error_message"], "Lỗi");
                }
            },
            (err) => {
                this.isLoading = false;
                this.toastr.error("Đã xảy ra lỗi. Xin vui lòng thử lại sau!", "Lỗi");
            }
        );
    }
}
