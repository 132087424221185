import { HeaderService } from './../../../service/header.service';
import { registerConstructionForm, feedBackInfo } from '../../../data/Model';
import { CommonService } from './../../../service/common.service';
import { domain, ApiConstant, domainImage, RegConstructionStatus, DepositStatus, ReceiveMoneyStatus, domainFile, ConstructionStatus, regexPhoneNumber, domainApi } from './../../../data/const';
import { RegisterConstructionService } from './../../../service/register-construction.service';
import { QueryFilter } from './../../../data/dt';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders, HttpClient, HttpUrlEncodingCodec, HttpRequest, HttpEventType } from '@angular/common/http';
import { typeCardRequestGroup } from '../../../data/const';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { e, L } from '@angular/core/src/render3';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { RegisterConstructionDocComponent } from '../dialogs/register-construction-doc/register-construction-doc.component';
import { DepositDialogComponent } from '../dialogs/deposit-dialog/deposit-dialog.component';
import { FeedBackDialogComponent } from '../dialogs/feedback-dialog/feedback-dialog.component';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-register-construction-form',
    templateUrl: './register-construction-form.component.html',
    styleUrls: ['./register-construction-form.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class RegisterConstructionFormComponent implements OnInit, OnDestroy {
    @ViewChild('file') file: ElementRef;
    @ViewChild('file1') file1: ElementRef;
    public httpOptions: any;
    public item: registerConstructionForm;
    public apartmentIdChanged: number;
    public q: QueryFilter;
    public isCreatePage: boolean;
    public domainImage = domainImage;
    public domainFile = domainFile;
    public lstProject = [];
    public lstTower = [];
    public lstFloor = [];
    public lstApartment = [];
    public isShowWarning: boolean = false;
    public isLoading = false;
    public regConstructionStatus = RegConstructionStatus;
    public constructionStatus = ConstructionStatus;
    public depositStatus = DepositStatus;
    public receiveMoneyStatus = ReceiveMoneyStatus;
    public regexPhoneNumber = regexPhoneNumber;
    public statusItem: number;
    public minDate: object;
    public maxDate: object;
    public dateNow = new Date();
    public currentDate = {
        year: this.dateNow.getFullYear(),
        month: this.dateNow.getMonth() + 1,
        day: this.dateNow.getDate()
    };

    public isKSTDKTC = localStorage.getItem("roleCode") == "KSTDKTC" ? true : false;
    public isANDKTC = localStorage.getItem("roleCode") == "ANDKTC" ? true : false;

    constructor(
        private route: ActivatedRoute,
        private readonly routerNavigate: Router,
        private location: Location,
        private modalService: NgbModal,
        private readonly registerConstructionService: RegisterConstructionService,
        private readonly commonService: CommonService,
        private readonly routerUrl: Router,
        public modalDialogService: ModalDialogService,
        public viewRef: ViewContainerRef,
        private readonly toastr: ToastrService,
        private readonly http: HttpClient,
        private readonly headerService: HeaderService,
        private gallery: Gallery,
        public titleService: Title,
    ) {
        this.q = new QueryFilter();


        this.item = new registerConstructionForm();
        this.item.RegConstructionStatus = 1;
        this.item.RegisterDate = new Date();
        this.item.IsDeposit = false;
        this.item.objectFiles = [];
        this.item.registerConstructionDocs = [];
        this.item.DepositStatus = 1;
        this.item.ReceiveMoneyStatus = 1;
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Đăng ký thi công");

        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.isCreatePage = false;
            this.onGetDetail(id);
        } else {
            this.isCreatePage = true;
        }

        this.GetListProject();

        setInterval(() => this.onCheckIsShowWarning(), 100);
    }

    ngOnDestroy() {
        clearInterval();
        this.headerService.clear();
        this.headerService.showWarning(false);
    }

    onSubmit() {
        Object.keys(this.item).forEach(k => this.item[k] = typeof this.item[k] == 'string' && this.item[k] ? this.item[k].trim() : this.item[k]);


        if (!this.item.ProjectId) {
            this.toastWarning("Vui lòng chọn dự án");
            return;
        } else if (!this.item.TowerId) {
            this.toastWarning("Vui lòng chọn tòa nhà");
            return;
        } else if (!this.item.FloorId) {
            this.toastWarning("Vui lòng chọn tầng");
            return;
        } else if (!this.item.ApartmentId) {
            this.toastWarning("Vui lòng chọn căn hộ");
            return;
        }

        if (!this.item.ResidentId) {
            this.toastWarning("Chưa có thông tin chủ hộ");
            return;
        }
        else if (this.item.ResidentName == "" || this.item.ResidentName == undefined) {
            this.toastWarning("Chưa có thông tin Tên chủ hộ");
            return;
        }
        else if (this.item.ResidentPhone == "" || this.item.ResidentPhone == undefined) {
            this.toastWarning("Chưa có thông tin Số điện thoại chủ hộ");
            return;
        }

        if(this.item.AuthorizedPersonPhone) {
            if(!this.checkValidate(1)) {
                this.toastWarning("Số điện thoại Người được ủy quyền không hợp lệ!");
                return;
            }
        }

        if(this.item.TransactionDateStart) {
            if(!this.checkValidate(2)) {
                this.toastWarning("Thông tin Ngày bắt đầu thực hiện giao dịch không hợp lệ!");
                return;
            }
        }

        if(this.item.TransactionDateEnd) {
            if(!this.checkValidate(3)) {
                this.toastWarning("Thông tin Ngày kết thúc giao dịch không hợp lệ!");
                return;
            }
        }

        if(!this.item.registerConstructionDocs) {
            this.toastWarning("Chưa có thông tin Hồ sơ đăng ký thi công");
            return;
        }
        else if(this.item.registerConstructionDocs.length == 0) {
            this.toastWarning("Chưa có thông tin Hồ sơ đăng ký thi công");
            return;
        }

        if (!this.item.RegConstructionStatus) {
            this.toastWarning("Vui lòng chọn trạng thái Đơn đăng ký thi công");
            return;
        }

        if(this.item.ConstructionStatus==2 && this.item.Id ) {
            if(!this.item.ConstructionDateStop) {
                this.toastWarning("Vui lòng nhập Ngày tạm dừng thi công");
                return;
            }

            if(!this.item.ConstructionDateContinue) {
                this.toastWarning("Vui lòng nhập Ngày tiếp tục thi công");
                return;
            }
        }
        
        // if (!this.item.ConstructionStatus) {
        //     this.toastWarning("Vui lòng chọn Trạng thái thi công");
        //     return;
        // }

        // if (this.item.AuthorizedPersonName == "" || this.item.AuthorizedPersonName == undefined) {
        //     this.toastWarning("Chưa có thông tin Tên người được ủy quyền");
        //     return;
        // }
        // else if (this.item.AuthorizedPersonPhone == "" || this.item.AuthorizedPersonPhone == undefined) {
        //     this.toastWarning("Chưa có thông tin Số điện thoại người được ủy quyền");
        //     return;
        // }
        // else if (this.item.AuthorizedPersonId == "" || this.item.AuthorizedPersonId == undefined) {
        //     this.toastWarning("Chưa có thông tin Số CMND/CCCD/Hộ chiếu người được ủy quyền");
        //     return;
        // }
        // else if (!this.item.TransactionDateStart) {
        //     this.toastWarning("Chưa có thông tin Ngày bắt đầu thực hiện giao dịch");
        //     return;
        // }
        // else if (!this.item.TransactionDateEnd) {
        //     this.toastWarning("Chưa có thông tin Ngày kết thúc thực hiện giao dịch");
        //     return;
        // }
        // else if (this.item.TransactionNote == "" || this.item.TransactionNote == undefined) {
        //     this.toastWarning("Chưa có thông tin Nội dung giao dịch");
        //     return;
        // }

        if (this.item.IsDeposit) {
            if (this.item.AmountDeposit == undefined) {
                this.toastWarning("Chưa có thông tin Số tiền cọc");
                return;
            }
        }

        if((this.item.RegConstructionStatus == 5 || this.item.RegConstructionStatus == 4) && this.item.IsDeposit) {
            if(this.item.DepositStatus != 2) {
                this.toastWarning("Đơn ở Trạng thái Phê duyệt đăng ký thì Trạng thái đặt cọc phải là Đã đặt cọc");
                return;
            }

            if(this.item.ReceiveMoneyStatus != 2) {
                this.toastWarning("Đơn ở Trạng thái Phê duyệt đăng ký thì Trạng thái nhận tiền cọc phải là BQL đã nhận tiền cọc");
                return;
            }
        }

        this.item.objectFiles = [...this.item.objectFiles];
        this.item.registerConstructionDocs = [...this.item.registerConstructionDocs];

        this.item.RegisterDate = this.item.RegisterDate ?
            new Date(moment(this.item.RegisterDate.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}) : null;

        this.item.IdentityIdDate = this.item.IdentityIdDate ?
            new Date(moment(this.item.IdentityIdDate.toString()).format('YYYY-MM-DD')) : null;

        this.item.TransactionDateStart = this.item.TransactionDateStart ?
            new Date(moment(this.item.TransactionDateStart.toString()).format('YYYY-MM-DD')) : null;

        this.item.TransactionDateEnd = this.item.TransactionDateEnd ?
            new Date(moment(this.item.TransactionDateEnd.toString()).format('YYYY-MM-DD')) : null;

        this.item.ConstructionDateStop = this.item.ConstructionDateStop ?
            new Date(moment(this.item.ConstructionDateStop.toString()).format('YYYY-MM-DD')) : null;

        this.item.ConstructionDateContinue = this.item.ConstructionDateContinue ?
            new Date(moment(this.item.ConstructionDateContinue.toString()).format('YYYY-MM-DD')) : null;

        this.isLoading = true;
        if (this.isCreatePage) {
            this.http.post(ApiConstant.CreateRegisterConstructionForm, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Tạo đơn đăng ký mới thành công");
                        this.isLoading = false;
                        this.isCreatePage = false;

                        this.routerNavigate.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            this.routerNavigate.navigate([`/utilities/registration-construction`]);
                        });
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        } else {
            this.http.put(ApiConstant.UpdateRegisterConstructionForm + this.item.Id, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.isLoading = false;
                        this.routerNavigate.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            this.routerNavigate.navigate([`/utilities/registration-construction`]);
                        });
                        this.toastSuccess("Cập nhật thành công!");
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                }),
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                }
        }

    }

    GetListProject() {
        this.lstProject = [];
        this.lstTower = [];
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.ProjectId = null;
        this.item.TowerId = null;
        this.item.FloorId = null;
        this.item.ApartmentId = null;

        return this.commonService.getLstProject().subscribe(
            (res) => {
                this.lstProject = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    GetListTower() {
        this.lstTower = [];
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.TowerId = null;
        this.item.FloorId = null;
        this.item.ApartmentId = null;
        this.item.ResidentId = null;
        this.item.ResidentPhone = '';
        this.item.ResidentEmail = '';
        this.item.ResidentName = '';
        this.apartmentIdChanged = null;

        return this.commonService.getLstTower(this.item.ProjectId).subscribe(
            (res) => {
                this.lstTower = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    GetListFloor() {
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.FloorId = null;
        this.item.ApartmentId = null;
        this.item.ApartmentName = '';
        this.item.ResidentId = null;
        this.item.ResidentPhone = '';
        this.item.ResidentEmail = '';
        this.item.ResidentName = '';
        this.apartmentIdChanged = null;

        return this.commonService.getLstFloor(this.item.TowerId).subscribe(
            (res) => {
                this.lstFloor = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    GetListApartment() {
        this.lstApartment = [];
        this.item.ApartmentId = null;
        this.item.ApartmentName = '';
        this.item.ResidentId = null;
        this.item.ResidentPhone = '';
        this.item.ResidentEmail = '';
        this.item.ResidentName = '';
        this.apartmentIdChanged = null;

        return this.commonService.getLstApartment(this.item.FloorId).subscribe(
            (res) => {
                if(this.isCreatePage) {
                    for (let i = 0; i < res.length; i ++) {
                        this.commonService.getPurchaserByApartmentId(res[i]['ApartmentId']).subscribe(
                          (lstRs) => {
                            if (lstRs) {
                              this.lstApartment.push(res[i]);
                              this.lstApartment = [...this.lstApartment];
                            }
                          },
                          (err) => {
                            this.toastError(err['meta']['error_message']);
                          });
                      }
                }
                else
                    this.lstApartment = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    getPurchaserByApartmentId(value) {
        this.item.ApartmentId = value.ApartmentId;
        this.item.ApartmentName = value.Name;

        if (this.item.ResidentId > 0) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer'
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Dữ liệu đăng ký bên dưới sẽ bị mất. Bạn có muốn thay đổi không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',
                        onAction: () => {
                            this.apartmentIdChanged = this.item.ApartmentId;

                            this.viewRef.clear();
                        }
                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            this.resetModel();
                            if(value) {
                                this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
                                    (res) => {
                                        if (res) {
                                            this.item.ApartmentId = value.ApartmentId;
                                            this.item.ApartmentName = value.Name;
                                            this.item.ResidentId = res.ResidentId;
                                            this.item.ResidentName = res.FullName;
                                            this.item.ResidentPhone = res.Phone;
                                            this.item.ResidentEmail = res.Email;

                                        } else {
                                            this.item.ApartmentId = null;
                                            this.item.ApartmentName = '';
                                            this.item.ResidentId = null;
                                            this.item.ResidentName = '';
                                            this.item.ResidentPhone = '';
                                            this.item.ResidentEmail = '';
                                            this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                                            return;
                                        }
                                    },
                                    (err) => {
                                        this.item.ApartmentId = null;
                                        this.item.ApartmentName = '';
                                        this.item.ResidentId = null;
                                        this.item.ResidentName = '';
                                        this.item.ResidentPhone = '';
                                        this.item.ResidentEmail = '';
                                        this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                                        return;
                                    }
                                )
                            }
                            else {
                                this.item.ApartmentId = null;
                                this.item.ApartmentName = '';
                                this.item.ResidentId = null;
                                this.item.ResidentName = '';
                                this.item.ResidentPhone = '';
                                this.item.ResidentEmail = '';
                                return;
                            }

                            this.viewRef.clear();
                        }
                    }
                ],
            });
        } else {
            this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
                (res) => {
                    if (res) {
                        this.item.ApartmentId = value.ApartmentId;
                        this.item.ApartmentName = value.Name;
                        this.item.ResidentId = res.ResidentId;
                        this.item.ResidentName = res.FullName;
                        this.item.ResidentPhone = res.Phone;
                        this.item.ResidentEmail = res.Email;

                    } else {
                        this.item.ApartmentId = null;
                        this.item.ApartmentName = '';
                        this.item.ResidentId = null;
                        this.item.ResidentName = '';
                        this.item.ResidentPhone = '';
                        this.item.ResidentEmail = '';
                        this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                        return;
                    }
                },
                (err) => {
                    this.item.ApartmentId = null;
                    this.item.ApartmentName = '';
                    this.item.ResidentId = null;
                    this.item.ResidentName = '';
                    this.item.ResidentPhone = '';
                    this.item.ResidentEmail = '';
                    this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                    return;
                }
            )
        }
    }

    resetModel () {
        this.item.ApartmentName = undefined;
        this.item.ResidentId = undefined;
        this.item.ResidentName = undefined;
        this.item.ResidentPhone = undefined;
        this.item.ResidentEmail = undefined;
        this.item.ResidentIdentityId = undefined;
        this.item.IdentityIdDate = undefined;
        this.item.IdentityIdPlace = undefined;
        this.item.Note = undefined;
        this.item.AuthorizedPersonName = undefined;
        this.item.AuthorizedPersonPhone = undefined;
        this.item.AuthorizedPersonId = undefined;
        this.item.AuthorizedPersonAddress = undefined;
        this.item.TransactionDateStart = undefined;
        this.item.TransactionDateEnd = undefined;
        this.item.TransactionNote = undefined;
        this.item.ConstructionStatus = undefined;
        this.item.RegConstructionStatus = 1;
        this.item.ConstructionDateStart = undefined;
        this.item.ConstructionDateEnd = undefined;
        this.item.ConstructionDateStop = undefined;
        this.item.ConstructionDateContinue = undefined;
        this.item.IsDeposit = false;
        this.item.AmountDeposit = undefined;
        this.item.TotalAmountDeposit = undefined;
        this.item.DepositStatus = 1;
        this.item.ReceiveMoneyStatus = 1;
        this.item.FeedBackToResident = undefined;
        this.item.registerConstructionDocs = [];
        this.item.objectFiles = [];
        this.item.deposits = [];
    }

    onGetDetail(id) {
        this.registerConstructionService.getDetail(id).subscribe(
            (res) => {
                if (res) {
                    this.setDataDetail(res);
                    this.item = new registerConstructionForm();
                    this.item.Id = res['Id'];
                    this.item.Status = res['Status'];
                    this.item.ProjectId = res['ProjectId'];
                    this.item.TowerId = res['TowerId'];
                    this.item.FloorId = res['FloorId'];
                    this.item.ApartmentId = res['ApartmentId'];
                    this.item.ApartmentName = res['ApartmentName'];
                    this.item.ResidentId = res['ResidentId'];
                    this.item.ResidentName = res['ResidentName'];
                    this.item.ResidentPhone = res['ResidentPhone'];
                    this.item.ResidentEmail = res['ResidentEmail'];
                    this.item.ResidentIdentityId = res['ResidentIdentityId'];
                    this.item.IdentityIdDate = res['IdentityIdDate'];
                    this.item.IdentityIdPlace = res['IdentityIdPlace'];
                    this.item.Note = res['Note'];
                    this.item.AuthorizedPersonName = res['AuthorizedPersonName'];
                    this.item.AuthorizedPersonPhone = res['AuthorizedPersonPhone'];
                    this.item.AuthorizedPersonId = res['AuthorizedPersonId'];
                    this.item.AuthorizedPersonAddress = res['AuthorizedPersonAddress'];
                    this.item.TransactionDateStart = res['TransactionDateStart'];
                    this.item.TransactionDateEnd = res['TransactionDateEnd'];
                    this.item.TransactionNote = res['TransactionNote'];
                    this.item.ConstructionStatus = res['ConstructionStatus'];
                    this.item.RegConstructionStatus = res['RegConstructionStatus'];
                    this.item.ConstructionDateStart = res['ConstructionDateStart'];
                    this.item.ConstructionDateEnd = res['ConstructionDateEnd'];
                    this.item.ConstructionDateStop = res['ConstructionDateStop'];
                    if (this.item.ConstructionDateStop) this.checkMinMax(this.item.ConstructionDateStop,1);
                    this.item.ConstructionDateContinue = res['ConstructionDateContinue'];
                    if (this.item.ConstructionDateContinue) this.checkMinMax(this.item.ConstructionDateContinue,2);
                    this.item.IsDeposit = res['IsDeposit'];
                    this.item.AmountDeposit = res['AmountDeposit'];
                    this.item.DepositStatus = res['DepositStatus'];
                    this.item.ReceiveMoneyStatus = res['ReceiveMoneyStatus'];
                    this.item.RegisterDate = res['RegisterDate'];
                    this.item.FeedBackToResident = res['FeedBackToResident'];
                    this.item.NoteSecutiry = res['NoteSecutiry'];
                    this.item.registerConstructionDocs = res['registerConstructionDocs'];
                    this.item.objectFiles = res['objectFiles'];
                    this.item.deposits = res['deposits'];
                    this.statusItem = res['RegConstructionStatus'];
                    this.regConstructionStatus.map((item, index, arr) => {
                        this.statusItem > item.Id ? item["disabled"] = true : item["disabled"] = false;
                        return item;
                    });

                    // this.item.id = res['id'];
                    // this.item.note = res['note'];
                    // this.item.info = res['info'];
                    // this.item.processStatus = res['processStatus'];
                    // this.item.status = res['status'];
                    // this.item.createdAt = res['createdAt'];
                    // this.item.updatedAt = res['updatedAt'];
                    // this.item.updatedById = res['updatedById'];
                    // this.item.createdById = res['createdById'];
                    // this.item.registrationDate = res['registrationDate'];
                    // this.item.type = res['type'];
                    // this.item.reasonId = res['reasonId'];
                    // this.item.reasonName = res['reasonName'];
                    // this.item.files = res['files'];
                    // this.item.carCards = res['carCards'];
                    // this.item.motorcycleCards = res['motorcycleCards'];
                    // this.item.residentCards = res['residentCards'];
                    // this.item.residentRequests = res['residentRequests'];
                    // this.item.responseAdmin = res['responseAdmin'];
                    // this.item.responseAdmin.appointmentDate = new Date(moment(this.item.responseAdmin.appointmentDate).format('YYYY-MM-DD'));

                } else {

                    this.modalDialogService.openDialog(this.viewRef, {
                        title: 'Thông báo',
                        settings: {
                            footerClass: 'footer-dialog modal-footer'
                        },
                        childComponent: SimpleModalComponent,
                        data: {
                            text: "Đơn đăng ký không tồn tại. Vui lòng kiểm tra lại!"
                        },
                        actionButtons: [
                            {
                                text: 'Quay lại',
                                buttonClass: 'btn btn-info',
                                onAction: () => {
                                    this.returnPage();
                                }
                            },
                        ],
                    });
                }

            },
            (err) => {
                this.routerUrl.navigate(["**"])
            });
    }

    QueryChanged() {

    }

    onCheckIsShowWarning() {
        if (this.apartmentIdChanged > 0 && !this.item.Id) {
            this.headerService.showWarning(true);
        } else {
            this.headerService.showWarning(false);
        }
    }

    upload(files) {
        if (files.length === 0)
            return;

        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

            }
            else if (event.type === HttpEventType.Response) {
                if (event.body["data"].length) {
                    event.body["data"].forEach(element => {
                        this.item.objectFiles.unshift({
                            FileId: 0,
                            FileRef: '',
                            Url: element.toString()
                        });
                    });
                }
                if (this.file) this.file.nativeElement.value = "";
            }
        });
    }

    onChangeProject() {
        if (this.item.ProjectId > 0) {
            this.item.TowerId = null;
            this.item.FloorId = null;
            this.item.ApartmentId = null;
            this.item.ApartmentName = '';
            this.item.ResidentId = null;
            this.item.ResidentPhone = '';
            this.item.ResidentEmail = '';
            this.item.ResidentName = '';
            this.apartmentIdChanged = null;
        }

        this.GetListTower();
    }

    setDataDetail(item) {
        this.lstProject = [];
        this.lstTower = [];
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.ProjectId = null;
        this.item.TowerId = null;
        this.item.FloorId = null;
        this.item.ApartmentId = null;

        this.commonService.getLstProject().subscribe(
            (res) => {
                this.lstProject = res;
                this.item.ProjectId = item["ProjectId"];

                this.commonService.getLstTower(this.item.ProjectId).subscribe((res) => {
                    this.lstTower = res;
                    this.item.TowerId = item["TowerId"];

                    this.commonService.getLstFloor(this.item.TowerId).subscribe((res) => {
                        this.lstFloor = res;
                        this.item.FloorId = item["FloorId"];

                        this.commonService.getLstApartment(this.item.FloorId).subscribe((res) => {
                            this.lstApartment = res;

                            this.apartmentIdChanged = item["ApartmentId"];
                            this.item.ApartmentId = item["ApartmentId"];
                            this.item.ResidentId = item["ResidentId"];

                            this.getResidentPhone(this.item.ApartmentId);
                        })
                    })
                })
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    getResidentPhone(apartmentId) {
        this.commonService.getPurchaserByApartmentId(apartmentId).subscribe(
            (res) => {
                if (res) {
                    this.item.ResidentPhone = res.Phone;
                    this.item.ResidentEmail = res.Email;
                } else {
                    this.item.ResidentPhone = '';
                    this.item.ResidentEmail = '';
                }
            },
            (err) => {
                this.item.ResidentPhone = '';
                this.item.ResidentEmail = '';
            }
        )
    }

    onRemoveImage(item) {
        this.item.objectFiles = [...this.item.objectFiles.filter(s => s != item)];
    }

    onShowGallery(index: number) {
        let lstImage = [];

        this.item.objectFiles.forEach(item => {
            lstImage.push({ path: domainImage + item.Url })
        })

        let prop = {
            images: lstImage,
            index
        };
        this.gallery.load(prop);
    }

    returnPage() {
        this.location.back();
    }

    returnPagePaging() {
        if (this.apartmentIdChanged > 0 && !this.item.Id) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer'
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Bạn chưa hoàn tất thông tin. Bạn muốn rời mà không hoàn tất không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',

                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            this.routerUrl.navigate(['/utilities/registration-construction']);
                        }
                    }
                ],
            });
        } else {
            this.location.back();
        }

    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }

    onChangePrice(value) {
        this.item.AmountDeposit = Number(value.replace(/[^0-9.-]+/g, ''));
        this.cacularTotalAmountDeposit();
    }

    openRegisterConstructionDocModal() {
        const dialofRef = this.modalService.open(RegisterConstructionDocComponent, { size: 'lg' });

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            this.item.registerConstructionDocs.push(res);
        });
    }

    openRegisterConstructionDocDetailModal(item, idx) {
        const dialofRef = this.modalService.open(RegisterConstructionDocComponent, { size: 'lg' });
        dialofRef.componentInstance.itemDetail = JSON.parse(JSON.stringify(item));

        if(this.statusItem == 3 || this.statusItem == 4 || this.statusItem == 5) {
            dialofRef.componentInstance.hideBtnSave = true;
        }

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            this.item.registerConstructionDocs[idx] = res;
        });
    }

    deleteRegisterConstructionDoc(idx) {
        this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
                footerClass: 'footer-dialog modal-footer',
            },
            childComponent: SimpleModalComponent,
            data: {
                text: "Bạn chắc muốn xóa đơn thông tin hồ sơ thi công này không?"
            },
            actionButtons: [
                {
                    text: 'Hủy',
                    buttonClass: 'btn btn-default',
                },
                {
                    text: 'Xác nhận',
                    buttonClass: 'btn btn-success',
                    onAction: () => {
                        this.item.registerConstructionDocs.splice(idx, 1);
                        this.viewRef.clear();
                    }
                }
            ],
        });
    }

    openDepositModal() {
        const dialofRef = this.modalService.open(DepositDialogComponent, { size: 'lg' });

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            if (!this.item.deposits) this.item.deposits = [];
            // this.item.deposits.push(res);
            // this.cacularTotalAmountDeposit();
            res.RegisterConstructionFormId = this.item.Id;

            this.http.post(domainApi + "/Deposit/PostData", res).subscribe(
                (resPostDeposit) => {
                    if (resPostDeposit['meta']['error_code'] == 200) {
                        this.toastSuccess("Thêm mới đặt cọc bổ sung thành công");
                        this.item.deposits.unshift(resPostDeposit["data"]);
                        this.cacularTotalAmountDeposit();
                    } else {
                        this.toastError(resPostDeposit["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.toastError("Error Api");
                })
        });
    }

    openUpdateDepositModal(item, idx) {
        const dialofRef = this.modalService.open(DepositDialogComponent, { size: 'lg' });
        dialofRef.componentInstance.itemDetail = JSON.parse(JSON.stringify(item));

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            // this.item.deposits[idx] = res;
            // this.cacularTotalAmountDeposit();
            this.http.put(domainApi + "/Deposit/" + res.Id, res).subscribe(
                (resPostDeposit) => {
                    if (resPostDeposit['meta']['error_code'] == 200) {
                        this.toastSuccess("Cập nhật thông tin đặt cọc bổ sung thành công");
                        this.item.deposits[idx] = resPostDeposit["data"];
                        this.cacularTotalAmountDeposit();
                    } else {
                        this.toastError(resPostDeposit["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.toastError("Error Api");
                })
        });
    }

    deleteDeposit(idx, Id) {
        this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
                footerClass: 'footer-dialog modal-footer',
            },
            childComponent: SimpleModalComponent,
            data: {
                text: "Bạn chắc muốn xóa đơn thông tin đặt cọc bổ sung này không?"
            },
            actionButtons: [
                {
                    text: 'Hủy',
                    buttonClass: 'btn btn-default',
                },
                {
                    text: 'Xác nhận',
                    buttonClass: 'btn btn-success',
                    onAction: () => {
                        this.http.delete(domainApi + "/Deposit/" + Id)
                            .subscribe(
                                (res) => {
                                    if (res["meta"]["error_code"] == 200) {
                                        this.toastSuccess("Xóa thành công!");
                                        this.item.deposits.splice(idx, 1);
                                        this.cacularTotalAmountDeposit();
                                    }
                                },
                                (err) => {
                                    console.log("Error: connect to API");
                                });

                        this.viewRef.clear();
                    }
                }
            ],
        });
    }

    cacularTotalAmountDeposit() {
        if (this.item.deposits) {
            this.item.TotalAmountDeposit = this.item.AmountDeposit + this.item.deposits.reduce((sum, curr) => sum + curr.AmountDeposit, 0);
        }
        else {
            this.item.TotalAmountDeposit = this.item.AmountDeposit;
        }
    }

    showRegConstructionStatus(constructionStatus) {
        let current = this.regConstructionStatus.filter(x => x.Id == constructionStatus)[0];
        return current != undefined ? current.Name : "";
    }

    showConstructionStatus(constructionStatus) {
        let current = ConstructionStatus.filter(x => x.Id == constructionStatus)[0];
        return current != undefined ? current.Name : "";
    }

    pauseConstruction() {
        if (!this.item.ConstructionDateStop) {
            this.toastWarning("Chưa có thông tin Ngày tạm dừng thi công!");
            return;
        }

        let dataApi = {
            RegisterConstructionFormId: this.item.Id,
            ConstructionDateStop: new Date(moment(this.item.ConstructionDateStop.toString()).format('YYYY-MM-DD')),
            ResidentId: this.item.ResidentId
        }

        this.isLoading = true;
        this.http.post(ApiConstant.PauseConstruction, dataApi).subscribe(
            (res) => {
                if (res['meta']['error_code'] == 200) {
                    this.toastSuccess("Tạm dừng thi công thành công!");

                    this.isLoading = false;
                    this.onGetDetail(this.item.Id);
                } else {
                    this.isLoading = false;
                    this.toastError(res["meta"]["error_message"]);
                }
            },
            (error) => {
                this.isLoading = false;
                this.toastError("Error Api");
            })
    }

    managmentFeedBack() {
        const dialofRef = this.modalService.open(FeedBackDialogComponent, { size: 'lg' });
        let item = new feedBackInfo();
        item.RegisterConstructionFormId = this.item.Id;
        item.TypeFeedBack = 1;
        item.ConstructionStatus = this.item.ConstructionStatus;
        item.TargetStatus = this.item.RegConstructionStatus;

        dialofRef.componentInstance.itemDetail = item;

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            this.http.post(ApiConstant.FeedBackRegisterConstructionForm, res).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Thêm thông tin phản hồi tới cư dân thành công!");

                        this.onGetDetail(this.item.Id);
                    } else {
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.toastError("Error Api");
                })
        });
    }

    modelChange() {
        console.log(this.item.TransactionDateStart);
    }

    omit_special_phone(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }

    changeRegConstructionStatus() {
        if((this.item.RegConstructionStatus == 5 || this.item.RegConstructionStatus == 4) && this.item.IsDeposit) {
            this.item.ReceiveMoneyStatus = 2;
            this.item.DepositStatus = 2;
        }
    }

    checkMinMax(event, type) {
        if(event) {
            let currDate = new Date(event);
            if(type == 1) {
                this.minDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
            else {
                this.maxDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
        }
        else {
            if(type == 1) this.minDate = undefined;
            else this.maxDate = undefined;
        }
    }

    ChangeDepositReg() {
        if(this.item.DepositStatus == undefined) this.item.DepositStatus = 1;
        if(this.item.ReceiveMoneyStatus == undefined) this.item.ReceiveMoneyStatus = 1;
    }

    checkValidate(cs) {
        if(cs == 1) {
            if (/(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.AuthorizedPersonPhone) == false && /(84[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.AuthorizedPersonPhone) == false) {
                return false;
            }
        }
        else if(cs == 2) {
            if(this.item.TransactionDateStart) {
                if(moment(this.item.TransactionDateStart.toString()).isValid() == false) return false;
            }
        }
        else if(cs == 3) {
            if(this.item.TransactionDateEnd) {
                if(moment(this.item.TransactionDateEnd.toString()).isValid() == false) return false;
            }
        }
        return true;
    }

    validateAuthorizedPersonName(event) {
        let regex = new RegExp("^[aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZ]+");
        let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    }
}
