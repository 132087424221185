import { environment } from '../../environments/environment';

export const domain = environment.apiUrl;
export const domainApi = domain.concat('api/cms');
// Api for utilities
export class ApiConstant {
  public static GetRequestGroupByPage = domain.concat('api/cms/RequestGroup');
  public static DeleteRequestGroup = domain.concat('api/Reg/RequestGroupDelete?id=');
  public static DetailRequestGroup = domain.concat('api/Reg/requestGroupGet?Id=');
  public static CancelRequestGroup = domain.concat('api/cms/RequestGroup/requestGroupCancel/');
  public static GetDataExportExcelRequestGroup = domain.concat('api/cms/RequestGroup/ExportExcel');

  public static CreateRequestRegistration = domain.concat('api/cms/RequestGroup/requestRegistration');
  public static UpdateRequestRegistration = domain.concat('api/cms/RequestGroup/requestRegistration/');

  public static CreateRequestChange = domain.concat('api/cms/RequestGroup/requestChange');
  public static UpdateRequestChange = domain.concat('api/cms/RequestGroup/requestChange/');

  public static CreateRequestCancel = domain.concat('api/cms/RequestGroup/requestCancel');
  public static UpdateRequestCancel = domain.concat('api/cms/RequestGroup/requestCancel/');

  public static CreateRequestRenew = domain.concat('api/cms/RequestGroup/requestReNew');
  public static UpdateRequestRenew = domain.concat('api/cms/RequestGroup/requestReNew/');

  public static CreateRequestLock = domain.concat('api/cms/RequestGroup/requestLock');
  public static UpdateRequestLock = domain.concat('api/cms/RequestGroup/requestLock/');

  public static CreateRequestUnLock = domain.concat('api/cms/RequestGroup/requestUnLock');
  public static UpdateRequestUnLock = domain.concat('api/cms/RequestGroup/requestUnLock/');

  public static GetCardByApartmentId = domain.concat('api/Reg/getAllCard?apartmentId=');

  public static CheckExistNumberPlate = domain.concat('api/Reg/checkExistVerhicleNumerPlate?projectId=');
  public static CheckExistResidentCard = domain.concat('api/cms/residentCard/checkExistResidentCard/');
  public static GetResidentCardLockByApartmentId = domain.concat('api/cms/residentCard/getResidentCardLockByApartmentId/');
  public static CreateTranspostRequest = domain.concat('api/cms/TranspostRequest');
  public static DetailTranspostRequest = domain.concat('api/cms/TranspostRequest/GetById');
  public static UpdateTranspotRequest = domain.concat('api/cms/TranspostRequest');
  public static GetPageTranspotRequest = domain.concat('api/cms/TranspostRequest/GetByPage');
  public static DeleteTranspotRequest = domain.concat('api/cms/TranspostRequest/');
  public static DeleteTranspotRequests = domain.concat('api/cms/TranspostRequest/deletes');
  public static GetDataExportExcelTranspotRequests = domain.concat('api/cms/TranspostRequest/ExportExcel');

  public static GetRegisterConstructionFormByPage = domain.concat('api/cms/RegisterConstructionForm/GetByPage');
  public static DetailRegisterConstructionForm = domain.concat('api/cms/RegisterConstructionForm/');
  public static CreateRegisterConstructionForm = domain.concat('api/cms/RegisterConstructionForm/PostData');
  public static UpdateRegisterConstructionForm = domain.concat('api/cms/RegisterConstructionForm/');
  public static DeleteRegisterConstructionForm = domain.concat('api/cms/RegisterConstructionForm/');
  public static PauseConstruction = domain.concat('api/cms/RegisterConstructionForm/PauseConstruction');
  public static FeedBackRegisterConstructionForm = domain.concat('api/cms/RegisterConstructionForm/ManagmentFeedBack');
  public static GetDataRegConstructionExportExcel = domain.concat('api/cms/RegisterConstructionForm/ExportExcel');

  public static GetContractorByPage = domain.concat('api/cms/Contractor/GetByPage');
  public static DetailContractor = domain.concat('api/cms/Contractor/');
  public static CreateContractor = domain.concat('api/cms/Contractor/PostData');
  public static UpdateContractor = domain.concat('api/cms/Contractor/');
  public static DeleteContractor = domain.concat('api/cms/Contractor/');
  public static FeedBackContractor = domain.concat('api/cms/Contractor/ManagmentFeedBack');

  public static GetExtensionConstructionFormByPage = domain.concat('api/cms/ExtensionConstructionForm/GetByPage');
  public static DetailExtensionConstructionForm = domain.concat('api/cms/ExtensionConstructionForm/');
  public static CreateExtensionConstructionForm = domain.concat('api/cms/ExtensionConstructionForm/PostData');
  public static UpdateExtensionConstructionForm = domain.concat('api/cms/ExtensionConstructionForm/');
  public static DeleteExtensionConstructionForm = domain.concat('api/cms/ExtensionConstructionForm/');
  public static FeedBackExtensionConstructionForm = domain.concat('api/cms/ExtensionConstructionForm/ManagmentFeedBack');

  public static GetAcceptanceConstructionFormByPage = domain.concat('api/cms/ConstructionAcceptanceForm/GetByPage');
  public static DetailAcceptanceConstructionForm = domain.concat('api/cms/ConstructionAcceptanceForm/');
  public static CreateAcceptanceConstructionForm = domain.concat('api/cms/ConstructionAcceptanceForm/PostData');
  public static UpdateAcceptanceConstructionForm = domain.concat('api/cms/ConstructionAcceptanceForm/');
  public static DeleteAcceptanceConstructionForm = domain.concat('api/cms/ConstructionAcceptanceForm/');
  public static FeedBackConstructionAcceptanceForm = domain.concat('api/cms/ConstructionAcceptanceForm/ManagmentFeedBack');

  public static GetDepositByPage = domain.concat('api/cms/Deposit/GetByPage');
  public static DetailDeposit = domain.concat('api/cms/Deposit/');
  public static CreateDeposit = domain.concat('api/cms/Deposit/PostData');
  public static UpdateDeposit = domain.concat('api/cms/Deposit/');
  public static DeleteDeposit = domain.concat('api/cms/Deposit/');

  //#region "Cleaning Package"
  public static getListCleaningPackage = domain.concat('add-on/api/admin/cleaning-package?page=1&pageSize=20?page=1&pageSize=50');
  public static createCleaningPackage = domain.concat('add-on/api/admin/cleaning-package');
  public static updateCleaningPackage = domain.concat('add-on/api/admin/cleaning-package/');
  public static deleteCleaningPackage = domain.concat('add-on/api/admin/cleaning-package/');
  //#endregion
  
  //#region "Technical Package"
  public static getListTechnicalPackage = domain.concat('add-on/api/admin/technical-package');
  public static createTechnicalPackage = domain.concat('add-on/api/admin/technical-package');
  public static updateTechnicalPackage = domain.concat('add-on/api/admin/technical-package/');
  public static deleteTechnicalPackage = domain.concat('add-on/api/admin/technical-package/');
  //#endregion

  //#region  "Cleaning Service New"
  public static getListCleaning = domain.concat('add-on/api/admin/cleaning?page=1&pageSize=20?page=1&pageSize=50');
  public static getDetailCleaning = domain.concat('add-on/api/admin/cleaning/');
  public static createCleaning = domain.concat('add-on/api/admin/cleaning');
  public static updateCleaning = domain.concat('add-on/api/admin/cleaning/');
  public static deleteCleaning = domain.concat('add-on/api/admin/cleaning/');
  //#endregion

  //#region  "Technical Service New"
  public static getListTechnical = domain.concat('add-on/api/admin/technical?page=1&pageSize=20?page=1&pageSize=50');
  public static getDetailTechnical = domain.concat('add-on/api/admin/technical/');
  public static createTechnical = domain.concat('add-on/api/admin/technical');
  public static updateTechnical = domain.concat('add-on/api/admin/technical/');
  public static deleteTechnical = domain.concat('add-on/api/admin/technical/');
  //#endregion

  //#region "Cleaning service"
  public static getCategoryDocByTypeAndProjectId = domain.concat('api/cms/categoryDocumentCleaning/getByPage?page=1&page_size=50');
  public static getByPageCategoryDoc = domain.concat('api/cms/categoryDocumentCleaning/getByPage');
  public static getByPageGroupCategoryDoc = domain.concat('api/cms/categoryDocumentCleaning/getByPageGroup');
  public static createCategoryDoc = domain.concat('api/cms/categoryDocumentCleaning');
  public static getDetailCategoryDoc = domain.concat('api/cms/categoryDocumentCleaning/');
  public static updateCategoryDoc = domain.concat('api/cms/categoryDocumentCleaning/');
  public static deleteCategoryDoc = domain.concat('api/cms/categoryDocumentCleaning/');

  public static getByPageDocumentCleaning = domain.concat('api/cms/documentCleaning/getByPage');
  public static createDocumentCleaning = domain.concat('api/cms/documentCleaning');
  public static updateDocumentCleaning = domain.concat('api/cms/documentCleaning/');

  public static createRequestCleaning = domain.concat('api/cms/requestCleaning');
  public static getRequestCleaningByPage = domain.concat('api/cms/requestCleaning/getByPage');
  public static deleteRequestCleaning = domain.concat('api/cms/requestCleaning/');
  public static detailRequesCleaning = domain.concat('api/cms/requestCleaning/');
  public static updateRequesCleaning = domain.concat('api/cms/requestCleaning/');
  public static cancelRequestCleaning = domain.concat('api/cms/requestCleaning/cancelRequest/');
  public static confirmAcceptanceRequestCleaning = domain.concat('api/cms/requestCleaning/confirmAcceptance/');
  public static updateConfirmStatusRequestCleaning = domain.concat('api/cms/requestCleaning/confirmStatus/');
  public static GetDataExportExcelRequestCleaning = domain.concat('api/cms/requestCleaning/ExportExcel');

  //#endregion

  //#region "Config Utilities"
  public static getListConfigUtilities = domain.concat('api/cms/utilities/getByPage');
  public static createConfigUtilities = domain.concat('api/cms/utilities');
  public static updateConfigUtilities = domain.concat('api/cms/utilities/');
  //#endregion
}

//Regex
export const regexPhoneNumber =  [/0/, /[3|5|7|8|9]/, /[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];


// export const domain = 'http://tnsplus.vn/';
export const domainImage = domain + 'Uploads/Image/';
export const domainFile = domain + 'Uploads/file/';

export const TypeAttribute_Relationship = 101; // Loại hình mối quan hệ  thành viên 124
export const TypeAttribute_Notification = 102; // Loại hình Loại thông báo 102
export const TypeAttribute_RequireSupport = 5; // Loại hình Loại yêu cầu hỗ trợ 104

const settings = { timestampsInSnapshots: true };
const config = {
  apiKey: 'YOUR_APIKEY',
  authDomain: 'YOUR_AUTH_DOMAIN',
  databaseURL: 'YOUR_DATABASE_URL',
  projectId: 'YOUR_PROJECT_ID',
  storageBucket: 'YOUR_STORAGE_BUCKET',
};

export const filterMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const patternPhone = /^(0|84)([-. ]?[0-9]{1}){9,10}[-. ]?$/;

export const navItems = [
  {
    name: 'Trang tổng quan',
    url: '/dashboard',
    icon: 'icon-home'
  }
];

export const Language = [
  {
    Id: 1,
    Name: 'Tiếng Việt'
  },
  {
    Id: 7,
    Name: 'Tiếng Anh'
  }
];

export const ActionTable = [
  {
    Id: 1,
    Name: 'Xóa đã chọn'
  }
]

//Loại cư dân
export const TypeResident = [
  {
    Id: 1,
    Name: 'Chủ căn hộ'
  },
  {
    Id: 3,
    Name: 'Thành viên căn hộ'
  },
  {
    Id: 2,
    Name: 'Khách thuê căn hộ'
  },
  {
    Id: 4,
    Name: 'Thành viên khách thuê'
  }
]

//Loại tài khoản
export const TypeUser = [
  {
    Id: 1,
    Name: 'Admin'
  },
  {
    Id: 2,
    Name: 'Ban quản lý'
  },
  {
    Id: 3,
    Name: 'Kỹ thuật viên'
  },
  {
    Id: 4,
    Name: 'Chủ hộ'
  },
  {
    Id: 5,
    Name: 'Khách thuê'
  }
]

//Loại tài khoản quản lý
export const TypeUserManager = [
  {
    Id: 2,
    Name: 'Ban quản lý'
  },
  {
    Id: 3,
    Name: 'Nhân viên'
  }
]

//Loại tài khoản cư dân
export const TypeUserResident = [
  {
    Id: 4,
    Name: 'Chủ hộ'
  },
  {
    Id: 5,
    Name: 'Khách thuê'
  }
]

//Loại trạng thái xử lý yêu cầu hỗ trợ
export const TypeRequireSupportStatus = [
  {
    Id: 1,
    Name: 'Chờ tiếp nhận',
    Hidden: true
  },
  {
    Id: 2,
    Name: 'Đang phân công',
    Hidden: true
  },
  {
    Id: 3,
    Name: 'Đang xử lý'
  },
  {
    Id: 4,
    Name: 'Hoàn thành'
  },
  {
    Id: 5,
    Name: 'Hủy'
  }
]

export const TypeNotification = [
  {
    Id: 1,
    Name: 'Thông báo tới khu đô thị'
  },
  {
    Id: 2,
    Name: 'Thông báo tới tòa nhà'
  },
  {
    Id: 3,
    Name: 'Thông báo tới tầng'
  },
  {
    Id: 4,
    Name: 'Thông báo tới căn hộ'
  }
  // {
  //   Id: 5,
  //   Name: 'Thông báo tới cư dân'
  // }
]

export const TypeNotificationFee = [
  {
    Id: 1,
    Name: 'Thông báo tới khu đô thị'
  },
  {
    Id: 2,
    Name: 'Thông báo tới tòa nhà'
  },
  {
    Id: 3,
    Name: 'Thông báo tới tầng'
  },
  {
    Id: 4,
    Name: 'Thông báo tới căn hộ'
  }
]


//Loại phòng Ban
export const TypeDepartment = [
  {
    Id: 1,
    Name: 'Ban quản lý'
  },
  {
    Id: 2,
    Name: 'Phòng ban'
  }
]

//Loại lịch sử xử lý yêu cầu hỗ trợ
export const TypeRequireSupportHistory = [
  {
    Id: 1,
    Name: 'Giao việc'
  },
  {
    Id: 2,
    Name: 'Xử lý'
  },
  {
    Id: 3,
    Name: 'Cư dân hủy yêu cầu',
    Hidden: true
  }
]

//Trạng thái thông báo
export const TypeNotificationStatus = [
  {
    Id: 1,
    Name: 'Đã gửi'
  },
  {
    Id: 3,
    Name: 'Chưa gửi'
  },
  {
    Id: 10,
    Name: 'Đã xem'
  }
]

//Trạng thái gửi thông báo
export const TypeNotificationSend = [
  {
    Id: 1,
    Name: 'Gửi luôn'
  },
  {
    Id: 0,
    Name: 'Lập lịch'
  }
]

//Loại bài viết sổ tay cư dân
export const TypeNotebook = [
  {
    Id: 1,
    Name: 'Bài viết sổ tay cư dân'
  },
  {
    Id: 2,
    Name: 'Bài viết bảng tin'
  },
  {
    Id: 3,
    Name: 'Tin tức'
  },
  {
    Id: 4,
    Name: 'Ưu đãi cư dân'
  },
  {
    Id: 5,
    Name: 'Khuyến mãi'
  },
  {
    Id: 6,
    Name: 'Thông báo'
  }
]

//Loại điều khoản đăng kí
export const TypeTerm = [
  {
    Id: 1,
    Name: 'Đăng ký thẻ/cư dân về ở'
  },
  {
    Id: 2,
    Name: 'Đăng ký chuyển hàng hóa vào/ra'
  },
  {
    Id: 3,
    Name: 'Đăng ký dich vụ vệ sinh'
  },
  {
    Id: 4,
    Name: 'Điều khoản Đăng ký thi công'
  },
  {
    Id: 5,
    Name: 'Thủ tục Đăng ký thi công'
  },
  {
    Id: 6,
    Name: 'Điều khoản Dịch vụ cho thuê/bán nhà'
  },
  {
    Id: 7,
    Name: 'Quy trình sửa chữa dịch vụ kỹ thuật'
  }
]

//Loại phương tiện
export const TypeVehicleParking = [
  {
    Id: 1,
    Name: 'Ô tô'
  },
  {
    Id: 2,
    Name: 'Xe máy'
  },
  {
    Id: 3,
    Name: 'Xe đạp điện'
  },
  {
    Id: 4,
    Name: 'Xe đạp'
  }
]

//Trang thái gửi sms
export const TypeSendSms = [
  {
    Id: 1,
    Name: 'Tất cả'
  },
  {
    Id: 2,
    Name: 'Gửi thành công'
  },
  {
    Id: 3,
    Name: 'Gửi thất bại'
  }
]

//Trang thái gửi sms
export const PaymentMethod = [
  {
    Id: 92,
    Name: 'OnePay'
  },
  {
    Id: 85,
    Name: 'VnPay'
  }
]

export const LstStatusResidentService = [
  {
    Id: -1,
    Name: 'Tất cả'
  },
  {
    Id: 1,
    Name: 'Đang xử lí'
  },
  {
    Id: 2,
    Name: 'Đã duyệt đăng ký'
  },
  {
    Id: 3,
    Name: 'Bổ sung thông tin'
  },
  {
    Id: 4,
    Name: 'Yêu cầu đóng phí'
  },
];

export enum typeCardRequestGroup {
  NEW = 1,
  CANCEL = 2,
  RENEW = 3,
  UPDATE = 4,
  LOCK = 7,
  UNLOCK = 8
}

export enum statusCardRequestGroup {
  ALL = -1,
  DRAFT = 0,
  SENT = 1,
  LACK_INFO = 2,
  INPROCESS = 3,
  UN_APPROVED = 4,
  APPROVED = 5,
  FEE_REQUIRE = 6,
  WAIT_CARD_CODE = 7,
  WAIT_HANDOVER = 8,
  FINISHED = 9
}

export const LstTypeRequest = [
  {
    Id: 1,
    Name: "Đăng ký cấp mới"
  },
  {
    Id: 4,
    Name: "Thay đổi thông tin thẻ"
  },
  {
    Id: 3,
    Name: "Đăng ký cấp lại thẻ"
  },
  {
    Id: 2,
    Name: "Đăng ký hủy thẻ"
  },
  {
    Id: 7,
    Name: "Đăng ký khóa thẻ"
  },
  {
    Id: 8,
    Name: "Đăng ký mở thẻ"
  }
]

export const LstStatusRequestGroup = [
  {
    Id: 1,
    Name: "Đăng ký mới",
    disabled: false
  },
  {
    Id: 2,
    Name: "Bổ sung thông tin",
    disabled: false
  },
  {
    Id: 3,
    Name: "Đang xử lý",
    disabled: false
  },
  {
    Id: 4,
    Name: "Không phê duyệt đăng ký",
    disabled: false
  },
  {
    Id: 5,
    Name: "Phê duyệt đăng ký",
    disabled: false
  },
  {
    Id: 6,
    Name: "Yêu cầu đóng phí",
    disabled: false
  },
  {
    Id: 7,
    Name: "Chờ cấp thẻ",
    disabled: false
  },
  {
    Id: 8,
    Name: "Chờ bàn giao",
    disabled: false
  },
  {
    Id: 9,
    Name: "Hoàn thành",
    disabled: false
  }
]

export const LstStatusPaymentRequestGroup = [
  {
    Id: 0,
    Name: "Chưa thanh toán",
  },
  {
    Id: 1,
    Name: "Đã thanh toán",
  },
]

export const LstStatusRequestGroupCreate = [
  {
    Id: 1,
    Name: "Đăng ký mới",
    disabled: false
  },
  {
    Id: 2,
    Name: "Bổ sung thông tin",
    disabled: false
  },
  {
    Id: 3,
    Name: "Đang xử lý",
    disabled: false
  },
  {
    Id: 4,
    Name: "Không phê duyệt đăng ký",
    disabled: false
  },
  {
    Id: 5,
    Name: "Phê duyệt đăng ký",
    disabled: false
  },
  // {
  //   Id: 6,
  //   Name: "Yêu cầu đóng phí",
  //   disabled: false
  // },
  // {
  //   Id: 7,
  //   Name: "Chờ cấp thẻ",
  //   disabled: false
  // },
  // {
  //   Id: 8,
  //   Name: "Chờ bàn giao",
  //   disabled: false
  // },
  {
    Id: 9,
    Name: "Hoàn thành",
    disabled: true
  }
]

export const LstStatusRequestGroupFilter = [
  {
    Id: 1,
    Name: "Đăng ký mới",
    disabled: false
  },
  {
    Id: 2,
    Name: "Bổ sung thông tin",
    disabled: false
  },
  {
    Id: 3,
    Name: "Đang xử lý",
    disabled: false
  },
  {
    Id: 4,
    Name: "Không phê duyệt đăng ký",
    disabled: false
  },
  {
    Id: 5,
    Name: "Phê duyệt đăng ký",
    disabled: false
  },
  // {
  //   Id: 6,
  //   Name: "Yêu cầu đóng phí",
  //   disabled: false
  // },
  // {
  //   Id: 7,
  //   Name: "Chờ cấp thẻ",
  //   disabled: false
  // },
  // {
  //   Id: 8,
  //   Name: "Chờ bàn giao",
  //   disabled: false
  // },
  {
    Id: 9,
    Name: "Hoàn thành",
    disabled: false
  }
]

export const lstStatusPayment = [
  {
    Id: 0,
    Name: "Chưa thanh toán"
  },
  {
    Id: 1,
    Name: "Đã thanh toán"
  },
]

export const lstStatusConfirmPayment = [
  {
    Id: 0,
    Name: "BQL đang xác nhận"
  },
  {
    Id: 1,
    Name: "BQL đã nhận được tiền"
  },
]

export const lstStatusRequestCleaningConfirmPayment = [
  {
    Id: 0,
    Name: "C-One đang xác nhận"
  },
  {
    Id: 1,
    Name: "C-One đã nhận được tiền"
  },
]


export const lstIdentityType = [
  {
    Id: 0,
    Name: "Chọn loại giấy tờ"
  },
  {
    Id: 1,
    Name: "Căn cước công dân"
  },
  {
    Id: 2,
    Name: "Chứng minh thư nhân dân"
  },
  {
    Id: 3,
    Name: "Hộ chiếu"
  }
]

export enum typeVehicle {
  MOTORCLE = 1,
  CAR = 2,
  BIKE = 3,
  ELECTRICBIKE = 4,
  ELECTRICMOTO = 5,
  ELECTRICCAR = 6
}

export enum typeReason {
  RENEW = 3,
  CANCEL = 2,
  LOCK = 7,
  UNLOCK = 8
}

export enum typePayment {
  DIRECT_PAYMENT = 1,
  ONLINE_PAYMENT = 2
}

export const lstTransferGoodStatus = [
  {
    Id: 0,
    Name: "Đã gửi đăng ký"
  },
  {
    Id: 1,
    Name: "Chờ xác nhận"
  },
  {
    Id: 2,
    Name: "Đã xác nhận"
  }
]


export const lstTranferConverBy = [
  {
    Id: 0,
    Name: "Thang bộ"
  },
  {
    Id: 1,
    Name: "Thang máy"
  },
]

export const lstTransferType = [
  {
    Id: 2,
    Name: "Chuyển ra"
  },
  {
    Id: 1,
    Name: "Chuyển vào"
  },
]


export enum transpostType {
  MoveIn = 1,
  MoveOut = 2
}

export enum transpostBy {
  Elevator = 1,
  Stair = 0
}

export enum transStatuspostRequestGroup {
  /// Lưu nháp
  Draft = 0,
  /// Đăng ký mới
  Registered = 1,
  /// Bổ sung thông tin
  LackInfo = 2,
  /// Đang xử lý
  Inprocess = 3,
  /// Không phê duyệt đăng ký
  UnApproval = 4,
  /// Phê duyệt đăng ký
  Approval = 5,
  /// Yêu cầu đóng phí
  FeesRequired = 6,
  /// Chờ cấp thẻ
  WaitCardCode = 7,
  /// Chờ bàn giao
  WaitHandOver = 8,
  /// Hoàn thành
  Finished = 9
}

export enum CardType {
  Motocycle = 1,
  Car = 2
}

export const listStatusServiceTech = [
  {
    Id: 1,
    Name: "Đăng ký mới"
  },
  {
    Id: 2,
    Name: "Kỹ thuật đang xử lý "
  },
  {
    Id: 3,
    Name: "Chờ xác nhận lịch khảo sát"
  },
  {
    Id: 4,
    Name: "Thực hiện khảo sát"
  },
  {
    Id: 5,
    Name: "Chờ xác nhận phí"
  },
  // {
  //   Id: 6,
  //   Name: "Đã xác nhận phí"
  // },
  {
    Id: 7,
    Name: "Thực hiện dịch vụ"
  },
  {
    Id: 8,
    Name: "Chờ cư dân nghiệm thu"
  },
  {
    Id: 9,
    Name: "Hoàn thành"
  },
  // {
  //   Id: 10,
  //   Name: "Chờ xác nhận thanh toán"
  // },
  // {
  //   Id: 11,
  //   Name: "Đã thanh toán"
  // },
  // {
  //   Id: 12,
  //   Name: "Hủy đăng ký"
  // },

]

export const listPaymentStatus = [
  {
    Id: 1,
    Name: "Chưa thanh toán"
  },
  {
    Id: 2,
    Name: "Đã thanh toán"
  },
  // {
  //   Id: 3,
  //   Name: "Đã nhận thanh toán online"
  // }
]

export const listPaymentMethod = [
  {
    Id: 2,
    Name: "Tiền mặt"
  },
  {
    Id: 1,
    Name: "Online"
  },
]

export const listPaymentType = [
  {
    Id: 1,
    Name: "Tiền mặt"
  },
  {
    Id: 2,
    Name: "Online"
  },
]


export const RegConstructionStatus = [
  {
    Id: 1,
    Name: "Đăng ký mới"
  },
  {
    Id: 2,
    Name: "Bổ sung thông tin"
  },
  {
    Id: 3,
    Name: "Không phê duyệt đăng ký"
  },
  {
    Id: 4,
    Name: "Phê duyệt đăng ký"
  },
  {
    Id: 5,
    Name: "Hoàn thành"
  }
]

export const ConstructionStatus = [
  {
    Id: 1,
    Name: "Đang thi công"
  },
  {
    Id: 2,
    Name: "Tạm dừng thi công"
  }
]

export const DepositStatus = [
  {
    Id: 1,
    Name: "Chưa đặt cọc"
  },
  {
    Id: 2,
    Name: "Đã đặt cọc"
  }
]

export const ReceiveMoneyStatus = [
  {
    Id: 1,
    Name: "BQL đang xác nhận"
  },
  {
    Id: 2,
    Name: "BQL đã nhận được tiền"
  }
]

export const TypeRegisterConstructionDoc = [
  {
    Id: 1,
    Name: "Bản vẽ thi công"
  },
  {
    Id: 2,
    Name: "Hồ sơ khác"
  }
]

//Trạng thái nhà thầu
export const ContractorStatus = [
  {
    Id: 1,
    Name: "Đăng ký mới"
  },
  {
    Id: 2,
    Name: "Không phê duyệt đăng ký"
  },
  {
    Id: 3,
    Name: "Phê duyệt đăng ký"
  }
]

//Trạng thái đơn gia hạn thi công
export const ExtensionConstructionStatus = [
  {
    Id: 1,
    Name: "Đăng ký mới"
  },
  {
    Id: 2,
    Name: "Không phê duyệt đăng ký"
  },
  {
    Id: 3,
    Name: "Phê duyệt đăng ký"
  }
]

//Trạng thái hoàn cọc
export const RefundDepositStatus = [
  {
    Id: 1,
    Name: "Chưa hoàn cọc"
  },
  {
    Id: 2,
    Name: "Đã hoàn cọc"
  }
]

//Hình thức đặt cọc
export const DepositType = [
  {
    Id: 1,
    Name: "Thanh toán Online"
  },
  {
    Id: 2,
    Name: "Thanh toán trực tiếp"
  }
]

//Trạng thái đơn đăng ký nghiệm thu thi công
export const ConstructionAcceptanceFormStatus = [
  {
    Id: 1,
    Name: "Đăng ký mới"
  },
  {
    Id: 2,
    Name: "Không phê duyệt đăng ký"
  },
  {
    Id: 3,
    Name: "Phê duyệt đăng ký"
  },
  // {
  //   Id: 4,
  //   Name: "Hoàn thành"
  // }
]

export const listAcceptanceStatus = [
  {
    Id: 1,
    Name: "Đã nghiệm thu"
  },
  {
    Id: 0,
    Name: "Chưa nghiệm thu"
  },
]

export const RentalApartmentType = [
  {
    Id: 1,
    Name: "Cho thuê nhà"
  },
  {
    Id: 2,
    Name: "Bán nhà"
  }
]

export const RentalApartmentProcessStatus = [
  {
    Id: 21,
    Name: "Đăng ký mới"
  },
  {
    Id: 22,
    Name: "Dịch vụ đang xử lý"
  },
  {
    Id: 23,
    Name: "Đang thực hiện dịch vụ"
  },
  {
    Id: 24,
    Name: "Chờ xác nhận lịch xem nhà"
  },
  {
    Id: 25,
    Name: "Chờ xem nhà"
  },
  {
    Id: 26,
    Name: "Hoàn thành"
  }
]

export enum typeServiceRate {
  SERVICE_TECH = 1,
  CLEANING_SERVICE = 2,
  RENTAL_APARTMENT = 3,
}

//Loại cư dân
export const TypeCountry = [
  {
    Id: 1,
    Name: 'Trong nước'
  },
  {
    Id: 2,
    Name: 'Nước ngoài'
  }
]

export enum TypeRequestCleaning { // Loại dịch vụ vệ sinh
  HourService = 1,
  DayService = 2,
  ComboService = 3,
  RealTimeService = 4
}

export enum ProcessStatusRequestCleaning { // trạng thái dịch vụ vệ sinh
  SentRequest = 1,
  Inprocess = 2,
  WaitSurvey = 3,
  Surveying = 4,
  WaitSchedule = 5,
  Processing = 6,
  WaitFinish = 7,
  WaitPayment = 8,
  Finished = 9
}

export enum TypeUserNote // loại user trong lịch sử trao đổi vệ sinh
{
    BQL = 1,
    Resident = 2,
    C_One = 3
}

export enum RequestServiceUserConfirm // trạng thái xác nhận lịch khảo sát & lịch thực hiện/phí vệ sinh
{
    NoConfirm = 0,
    Confirmed = 1
}

export enum RequestCleaningDateOfWeek // các thứ trong tuần cư dân xác nhận khi chọn dịch vụ dọn dẹp định kì
{
    Monday = 2,
    Tuesday = 3,
    Wednesday = 4,
    Thursday = 5,
    Friday = 6,
    Saturday = 7,
    Sunday = 8
}

export enum RequestCleaningTypeMonthPackage // loại gói tháng khi chọn dịch vụ dọn dẹp đinh kì
{
    OneMonth = 1,
    TwoMonths = 3,
    SixMonths = 6,
    Twelve = 12
}

export enum RequestServicePaymentOption // loại tiền thanh toán. đặt cọc & phí
{
    FeePayment = 1,
    DepositPayment = 2
}

export enum TypePayment // phương thức thanh toán
{
    DIRECT_PAYMENT = 1,
    ONLINE_PAYMENT = 2
}

export enum StatusPayment // trạng thái thanh toán
{
    WAIT_PAYMENT = 0,
    FINISH_PAYMENT = 1
}

export enum StatusAdminConfirmPayment // trạng thái xác nhận tiền của BQL
{
    WAIT_CONFIRM = 0,
    CONFIRMED = 1
}

export enum RequestCleaningNumberOfHours // số lượng giờ thực hiện đối với dọn dẹp theo giờ & định kì
{
    Two = 2,
    Three = 3,
    Four = 4,
    Five = 5,
    Six = 6,
    Seven = 7,
    Eight = 8
}

export const lstHourOfDayServiceCleaning = [ // dsach số lượng giờ thực hiện đối với dọn dẹp theo giờ & định kì
  {
    Id: 2,
    Name: "2 giờ"
  },
  {
    Id: 3,
    Name: "3 giờ"
  },
  {
    Id: 4,
    Name: "4 giờ"
  },
  {
    Id: 5,
    Name: "5 giờ"
  },
  {
    Id: 6,
    Name: "6 giờ"
  }
]

export const lstMonthPackageServiceCleaning = [ // dsach loại gói tháng thực hiện đối với dọn dẹp định kì
  {
    Id: 1,
    Name: "1 tháng"
  },
  {
    Id: 3,
    Name: "3 tháng"
  },
  {
    Id: 6,
    Name: "4 tháng"
  },
  // {
  //   Id: 12,
  //   Name: "12 tháng"
  // }
]

export const lstTypeCleaning = [ // dsach loại dvu vệ sinh
  {
    Id: 1,
    Name: "Dọn dẹp theo giờ"
  },
  {
    Id: 2,
    Name: "Dọn dẹp theo ngày"
  },
  {
    Id: 3,
    Name: "Dọn dẹp trọn gói"
  },
  {
    Id: 4,
    Name: "Dọn dẹp định kỳ"
  }
]

export const lstDateOfWeekServiceCleaning = [ // dsach các thứ trong tuần thực hiện cviec đối với dọn dẹp định kì
  {
    Id: 2,
    Name: "Thứ 2"
  },
  {
    Id: 3,
    Name: "Thứ 3"
  },
  {
    Id: 4,
    Name: "Thứ 4"
  },
  {
    Id: 5,
    Name: "Thứ 5"
  },
  {
    Id: 6,
    Name: "Thứ 6"
  },
  {
    Id: 7,
    Name: "Thứ 7"
  },
  {
    Id: 8,
    Name: "Chủ nhật"
  }
]

export const cancelStatus = [
  {
    Id: 1,
    Name: "Đơn không bị hủy"
  },
  {
    Id: 10,
    Name: "Đơn đã hủy"
  }
];

export const registerConstructionDocStatus = [
  {
    Id: 1,
    Name: "Đăng ký mới"
  },
  {
    Id: 2,
    Name: "Không phê duyệt đăng ký"
  },
  {
    Id: 3,
    Name: "Phê duyệt đăng ký"
  }
];

export const listStatusSla = [
  {
    Id: 1,
    Name: "DVKT-Đăng ký mới",
    Step: 1,
    SlaServiceId: 2
  },
  {
    Id: 2,
    Name: "DVKT-Kỹ thuật đang xử lý",
    Step: 2,
    SlaServiceId: 2
  },
  {
    Id: 3,
    Name: "DVKT-Thực hiện khảo sát",
    Step: 4,
    SlaServiceId: 2
  },
  {
    Id: 4,
    Name: "DVKT-Đã thanh toán",
    Step: 11,
    SlaServiceId: 2
  },
  {
    Id: 5,
    Name: "CDVO-TTT-Đăng ký mới",
    Step: 1,
    SlaServiceId: 80
  },
  {
    Id: 6,
    Name: "CDVO-TTT-Đang xử lý",
    Step: 3,
    SlaServiceId: 80
  },
  {
    Id: 7,
    Name: "Thi công-Phản hồi đơn",
    Step: 1,
    SlaServiceId: 5
  },
  {
    Id: 8,
    Name: "Thi công-Xác nhận đơn duyệt hay không duyệt",
    Step: 2,
    SlaServiceId: 5
  },
  {
    Id: 9,
    Name: "Nghiệm thu thi công-Xác nhận đơn duyệt hay không duyệt",
    Step: 1,
    SlaServiceId: 6
  },
  {
    Id: 10,
    Name: "DVVS - Đăng ký mới",
    Step: 1,
    SlaServiceId: 7
  },
  {
    Id: 11,
    Name: "DVVS - C-One đang xử lý",
    Step: 2,
    SlaServiceId: 7
  },
  {
    Id: 12,
    Name: "DVVS - Thực hiện khảo sát",
    Step: 3,
    SlaServiceId: 7
  },
  {
    Id: 13,
    Name: "DVVS - Hoàn thành sau khi đã thanh toán",
    Step: 4,
    SlaServiceId: 7
  },
  {
    Id: 14,
    Name: "CHHVR - Đăng ký mới",
    Step: 1,
    SlaServiceId: 8
  },
  {
    Id: 15,
    Name: "CHHVR - Đang xử lý",
    Step: 2,
    SlaServiceId: 8
  },
  {
    Id: 16,
    Name: "CHHVR - Hoàn thành sau khi đăng kí",
    Step: 3,
    SlaServiceId: 8
  },
  {
    Id: 17,
    Name: "T/CDVO - Đăng ký mới",
    Step: 1,
    SlaServiceId: 4
  },
  {
    Id: 18,
    Name: "T/CDVO - Đang xử lý",
    Step: 2,
    SlaServiceId: 4
  },
  {
    Id: 19,
    Name: "T/CDVO - Phê duyệt đăng kí",
    Step: 3,
    SlaServiceId: 4
  },
  {
    Id: 20,
    Name: "T/CDVO - Yêu cầu đóng phí",
    Step: 4,
    SlaServiceId: 4
  },
  {
    Id: 21,
    Name: "T/CDVO - Chờ cấp thẻ",
    Step: 5,
    SlaServiceId: 4
  },
  {
    Id: 22,
    Name: "T/CDVO - Chờ bàn giao",
    Step: 6,
    SlaServiceId: 4
  },
  {
    Id: 23,
    Name: "Dv thuê/bán nhà - Đăng ký mới",
    Step: 1,
    SlaServiceId: 1
  },
  {
    Id: 24,
    Name: "Dv thuê/bán nhà - Dịch vụ đang xử lý",
    Step: 2,
    SlaServiceId: 1
  },
  {
    Id: 25,
    Name: "Dv thuê/bán nhà - Hoàn thành",
    Step: 3,
    SlaServiceId: 1
  },
  {
    Id: 26,
    Name: "T/gian xử lí yêu cầu - vệ sinh",
    Step: 7,
    SlaServiceId: 9
  },
  {
    Id: 27,
    Name: "T/gian xử lí yêu cầu - cho thuê nhà",
    Step: 1,
    SlaServiceId: 9
  },
  {
    Id: 28,
    Name: "T/gian xử lí yêu cầu - thẻ/cư dân về ở",
    Step: 4,
    SlaServiceId: 9
  },
  {
    Id: 29,
    Name: "T/gian xử lí yêu cầu - chuyển hàng hóa",
    Step: 8,
    SlaServiceId: 9
  },
  {
    Id: 30,
    Name: "T/gian xử lí yêu cầu - thi công",
    Step: 5,
    SlaServiceId: 9
  },
  {
    Id: 31,
    Name: "T/gian xử lí yêu cầu - kỹ thuật",
    Step: 2,
    SlaServiceId: 9
  },
  {
    Id: 32,
    Name: "T/gian phản hồi yêu cầu - vệ sinh",
    Step: 7,
    SlaServiceId: 10
  },
  {
    Id: 33,
    Name: "T/gian phản hồi yêu cầu - cho thuê nhà",
    Step: 1,
    SlaServiceId: 10
  },
  {
    Id: 34,
    Name: "T/gian phản hồi yêu cầu - thẻ/cư dân về ở",
    Step: 4,
    SlaServiceId: 10
  },
  {
    Id: 35,
    Name: "T/gian phản hồi yêu cầu - chuyển hàng hóa",
    Step: 8,
    SlaServiceId: 10
  },
  {
    Id: 36,
    Name: "T/gian phản hồi yêu cầu - thi công",
    Step: 5,
    SlaServiceId: 10
  },
  {
    Id: 37,
    Name: "T/gian phản hồi yêu cầu - kỹ thuật",
    Step: 2,
    SlaServiceId: 10
  }
];

export const statusDvConfirmPayment = [
  {
    Id: 0,
    Name: "Đang xác nhận"
  },
  {
    Id: 1,
    Name: "Đã nhận được tiền"
  }
];

export const listPaymentStatusRental = [
  {
    Id: 0,
    Name: "Chưa thanh toán"
  },
  {
    Id: 1,
    Name: "Đã thanh toán"
  }
]

export const typeCash = [
  {
    Id: 1,
    Name: "Tiện ích đăng ký thẻ"
  },
  {
    Id: 2,
    Name: "Tiện ích chuyển hàng hóa vào ra"
  },
  {
    Id: 3,
    Name: "Tiện ích đăng ký thi công(đặt cọc)"
  },
  {
    Id: 7,
    Name: "Tiện ích đăng ký thi công(đặt cọc bổ sung)"
  },
  {
    Id: 4,
    Name: "Dịch vụ kỹ thuật"
  },
  {
    Id: 8,
    Name: "Dịch vụ kỹ thuật(đặt cọc)"
  },
  {
    Id: 5,
    Name: "Dịch vụ vệ sinh"
  },
  {
    Id: 6,
    Name: "Dịch vụ thuê/bán nhà"
  }
]

export const listTypeUtilities = [
  {
    Id: 1,
    Name: "Dịch vụ"
  },
  {
    Id: 2,
    Name: "Tiện ích"
  }
]

export enum StatusCleaning { // trạng thái dịch vụ vệ sinh
  SentRequest = 1,
  ReceivedRequest = 2,
  WaitApprove = 3,
  ResidentApproved = 4,
  Processing = 5,
  Done = 6,
  Cancel = 7,
}

export enum FileTypeCleaningEnum {
  Support = 1,
  DocumentAcceptance = 2,
}

export const lstStatusServiceCleaning = [ // dsach trạng thái yêu cầu dvu vệ sinh
  {
    Id: 1,
    Name: "Đã gửi yêu cầu"
  },
  {
    Id: 2,
    Name: "Đã tiếp nhận"
  },
  {
    Id: 3,
    Name: "Chờ xác nhận phiếu"
  },
  {
    Id: 4,
    Name: "Cư dân xác nhận"
  },
  {
    Id: 5,
    Name: "Thực hiện dịch vụ"
  },
  {
    Id: 6,
    Name: "Hoàn thành"
  },
  {
    Id: 7,
    Name: "Huỷ"
  }
]

export enum StatusTechnical { // trạng thái dịch vụ vệ sinh
  SentRequest = 1,
  ReceivedRequest = 2,
  WaitApprove = 3,
  ResidentApproved = 4,
  Processing = 5,
  Done = 6,
  Cancel = 7,
}

export enum FileTypeTechnicalEnum {
  Support = 1,
  DocumentAcceptance = 2,
}

export const lstStatusServiceTechnical = [
  {
    Id: 1,
    Name: "Đã gửi yêu cầu"
  },
  {
    Id: 2,
    Name: "Đã tiếp nhận"
  },
  {
    Id: 3,
    Name: "Chờ xác nhận phiếu"
  },
  {
    Id: 4,
    Name: "Cư dân xác nhận"
  },
  {
    Id: 5,
    Name: "Thực hiện dịch vụ"
  },
  {
    Id: 6,
    Name: "Hoàn thành"
  },
  {
    Id: 7,
    Name: "Huỷ"
  }
]