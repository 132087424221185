import { HeaderService } from './../../../../service/header.service';
import { RentalApartmentForm, RentalApartment, InformRentalApartment, ServiceRate } from '../../../../data/Model';
import { CommonService } from './../../../../service/common.service';
import { domain, ApiConstant, domainImage, RegConstructionStatus, RentalApartmentType, ReceiveMoneyStatus, domainFile, RentalApartmentProcessStatus, regexPhoneNumber, listPaymentStatusRental, domainApi, statusDvConfirmPayment } from './../../../../data/const';
import { RentalApartmentService } from './../../../../service/rental-apartment';
import { QueryFilter } from './../../../../data/dt';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders, HttpClient, HttpUrlEncodingCodec, HttpRequest, HttpEventType } from '@angular/common/http';
import { typeCardRequestGroup } from '../../../../data/const';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { L } from '@angular/core/src/render3';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { RentalApartmentInformComponent } from '../dialogs/rental-apartment-inform/rental-apartment-inform.component';
import { RateServiceRentalApartmentComponent } from '../dialogs/rate-service/rate-service.component';
import { CancelServiceComponent } from '../dialogs/cancel-service/cancel-service.component';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};


export class DefaultIntl extends OwlDateTimeIntl {
    /** A label for the cancel button */
    cancelBtnLabel = 'Hủy';
  
    /** A label for the set button */
    setBtnLabel = 'Đặt';
  };

@Component({
    selector: 'app-action-rental-apartment',
    templateUrl: './action-rental-apartment.component.html',
    styleUrls: ['./action-rental-apartment.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
        { provide: OwlDateTimeIntl, useClass: DefaultIntl }
    ]
})
export class ActionRentalApartmentComponent implements OnInit, OnDestroy {
    @ViewChild('file') file: ElementRef;
    @ViewChild('file1') file1: ElementRef;
    public httpOptions: any;
    public item: RentalApartmentForm;
    public apartmentIdChanged: number;
    public q: QueryFilter;
    public isCreatePage: boolean;
    public domainImage = domainImage;
    public domainFile = domainFile;
    public lstProject = [];
    public lstTower = [];
    public lstFloor = [];
    public lstApartment = [];
    public isShowWarning: boolean = false;
    public isLoading = false;
    public regConstructionStatus = RegConstructionStatus;
    public rentalApartmentProcessStatus = [];
    public rentalApartmentType = RentalApartmentType;
    public receiveMoneyStatus = ReceiveMoneyStatus;
    public regexPhoneNumber = regexPhoneNumber;
    public listPaymentStatus = listPaymentStatusRental;
    public statusItem: number;
    public ItemRate: ServiceRate;
    public typeRates = [];
    public typeRateItems = [];
    public is3rdPart = localStorage.getItem("roleCode") == "DVCTN" ? true : false;
    public configFee: any;
    public configKm: any;

    public dateNow = new Date();
    public currentDate = {
        year: this.dateNow.getFullYear(),
        month: this.dateNow.getMonth() + 1,
        day: this.dateNow.getDate()
    };

    public statusDvConfirmPayment = statusDvConfirmPayment;

    constructor(
        private route: ActivatedRoute,
        private readonly routerNavigate: Router,
        private location: Location,
        private modalService: NgbModal,
        private readonly rentalApartmentService: RentalApartmentService,
        private readonly commonService: CommonService,
        private readonly routerUrl: Router,
        public modalDialogService: ModalDialogService,
        public viewRef: ViewContainerRef,
        private readonly toastr: ToastrService,
        private readonly http: HttpClient,
        private readonly headerService: HeaderService,
        private gallery: Gallery,
        public titleService: Title,
    ) {
        this.q = new QueryFilter();


        this.item = new RentalApartmentForm();
        this.item.processStatus = 21;
        this.item.files = [];
        // this.item.inform = new InformRentalApartment();
        this.item.rentalApartment = new RentalApartment();
        this.item.rentalApartment.type = 1;
        this.item.rentalApartment.statusPayment = 0;
        this.item.rentalApartment.createdAt = new Date();
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Dịch vụ cho thuê/bán nhà");

        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.isCreatePage = false;
            this.onGetDetail(id);
        } else {
            this.rentalApartmentProcessStatus = RentalApartmentProcessStatus;
            this.isCreatePage = true;
        }

        this.GetListProject();

        setInterval(() => this.onCheckIsShowWarning(), 100);
    }

    ngOnDestroy() {
        clearInterval();
        this.headerService.clear();
        this.headerService.showWarning(false);
    }

    onSubmit(type) {
        Object.keys(this.item).forEach(k => this.item[k] = typeof this.item[k] == 'string' && this.item[k] ? this.item[k].trim() : this.item[k]);

        if (this.item.processStatus == undefined) {
            this.toastWarning("Vui lòng chọn trạng thái Đơn đăng ký");
            return;
        }

        if (this.item.processStatus == 26 && this.item.rentalApartment.statusPayment != 1) {
            this.toastWarning("Trạng thái Đơn đăng ký là Hoàn thành chỉ khi đơn đã được thanh toán");
            return;
        }

        if (this.item.rentalApartment.type == undefined) {
            this.toastWarning("Vui lòng chọn Loại dịch vụ");
            return;
        }

        if (!this.item.projectId) {
            this.toastWarning("Vui lòng chọn dự án");
            return;
        } else if (!this.item.towerId) {
            this.toastWarning("Vui lòng chọn tòa nhà");
            return;
        } else if (!this.item.floorId) {
            this.toastWarning("Vui lòng chọn tầng");
            return;
        } else if (!this.item.apartmentId) {
            this.toastWarning("Vui lòng chọn căn hộ");
            return;
        }

        if (!this.item.residentId) {
            this.toastWarning("Chưa có thông tin chủ hộ");
            return;
        }
        else if (this.item.residentName == "" || this.item.residentName == undefined) {
            this.toastWarning("Chưa có thông tin Tên chủ hộ");
            return;
        }

        if(this.item.rentalApartment.acreage == undefined) {
            this.toastWarning("Chưa có thông tin Diện tích căn hộ");
            return;
        }

        if(this.item.rentalApartment.hasFurniture == undefined) {
            this.toastWarning("Chưa có thông tin Tình trạng căn hộ");
            return;
        }

        if(this.item.rentalApartment.totalBedRoom == undefined) {
            this.toastWarning("Chưa có thông tin Số phòng ngủ căn hộ");
            return;
        }

        if(this.item.rentalApartment.totalRestroom == undefined) {
            this.toastWarning("Chưa có thông tin Số phòng vệ sinh");
            return;
        }

        if(this.item.rentalApartment.fee == undefined) {
            this.toastWarning("Chưa có thông tin Giá cho thuê/bán");
            return;
        }

        if(this.item.rentalApartment.rentalApartmentConfigSel == undefined && this.configKm) {
            this.toastWarning("Chưa có thông tin Ưu đãi");
            return;
        }

        if(this.item.processStatus == 24 && !this.item.rentalApartment.schedule) {
            this.toastWarning("Chưa có thông tin Ngày hẹn xem nhà");
            return;
        }

        if(this.item.processStatus == 26 && this.item.rentalApartment.statusAdminConfirm != 1) {
            this.toastWarning("Trạng thái DV nhận tiền không hợp lệ khi trạng thái đơn là Hoàn thành!");
            return;
        }
        // if(this.item.processStatus == 24) {
        //     if(!this.item.inform) {
        //         this.toastWarning("Vui lòng xác nhận Lịch hẹn xem nhà!");
        //         return;
        //     }
        //     else {
        //         if(!this.item.inform.resType) {
        //             this.toastWarning("Vui lòng xác nhận Lịch hẹn xem nhà!");
        //             return;
        //         }
        //     }
        // }

        this.item.files = [...this.item.files];
        // this.item.rentalApartment.schedule = this.item.rentalApartment.schedule ?
        //     new Date(moment(this.item.rentalApartment.schedule.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("vi-VN", {timeZone: "Asia/Ho_Chi_Minh"}) : null;

        this.isLoading = true;
        let data = Object.assign({}, this.item);
        data.rentalApartment.schedule = data.rentalApartment.schedule ?
            new Date(moment(data.rentalApartment.schedule.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}) : null;

        delete data.inform;
        if (this.isCreatePage) {
            this.http.post(domainApi + "/RequestGroup/RequestRentalApartmentPost", data).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Tạo đơn đăng ký mới thành công");
                        this.isLoading = false;
                        this.isCreatePage = false;

                        this.routerNavigate.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            this.routerNavigate.navigate([`/manager-service/rental-apartment`]);
                        });
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        } else {
            this.http.post(domainApi + "/RequestGroup/RequestRentalApartmentPost", data).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.isLoading = false;
                        this.routerNavigate.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            this.routerNavigate.navigate([`/manager-service/rental-apartment`]);
                        });
                        this.toastSuccess("Cập nhật thành công!");
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                }),
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                }
        }

    }

    GetListProject() {
        this.lstProject = [];
        this.lstTower = [];
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.projectId = null;
        this.item.towerId = null;
        this.item.floorId = null;
        this.item.apartmentId = null;

        return this.commonService.getLstProject().subscribe(
            (res) => {
                this.lstProject = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    GetListTower() {
        this.lstTower = [];
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.towerId = null;
        this.item.floorId = null;
        this.item.apartmentId = null;
        this.item.residentId = null;
        this.item.rentalApartment.phone = '';
        this.item.residentName = '';
        this.apartmentIdChanged = null;

        return this.commonService.getLstTower(this.item.projectId).subscribe(
            (res) => {
                this.lstTower = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    GetListFloor() {
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.floorId = null;
        this.item.apartmentId = null;
        this.item.apartmentName = '';
        this.item.residentId = null;
        this.item.rentalApartment.phone = '';
        this.item.residentName = '';
        this.apartmentIdChanged = null;

        return this.commonService.getLstFloor(this.item.towerId).subscribe(
            (res) => {
                this.lstFloor = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
        
    }

    GetListApartment() {
        this.lstApartment = [];
        this.item.apartmentId = null;
        this.item.apartmentName = '';
        this.item.residentId = null;
        this.item.rentalApartment.phone = '';
        this.item.residentName = '';
        this.apartmentIdChanged = null;

        return this.commonService.getLstApartment(this.item.floorId).subscribe(
            (res) => {
                if(this.isCreatePage) {
                    for (let i = 0; i < res.length; i ++) {
                        this.commonService.getPurchaserByApartmentId(res[i]['ApartmentId']).subscribe(
                          (lstRs) => {
                            if (lstRs) {
                              this.lstApartment.push(res[i]);
                              this.lstApartment = [...this.lstApartment];
                            }
                          },
                          (err) => {
                            this.toastError(err['meta']['error_message']);
                          });
                      }
                }
                else 
                    this.lstApartment = res;
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    getPurchaserByApartmentId(value) {
        this.item.apartmentId = value.ApartmentId;
        this.item.apartmentName = value.Name;

        if (this.item.residentId > 0) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer'
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Dữ liệu đăng ký bên dưới sẽ bị mất. Bạn có muốn thay đổi không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',
                        onAction: () => {
                            this.apartmentIdChanged = this.item.apartmentId;

                            this.viewRef.clear();
                        }
                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {

                            this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
                                (res) => {
                                    if (res) {
                                        this.item.apartmentId = value.ApartmentId;
                                        this.item.apartmentName = value.Name;
                                        this.item.residentId = res.ResidentId;
                                        this.item.residentName = res.FullName;
                                        this.item.rentalApartment.phone = res.Phone;

                                    } else {
                                        this.item.apartmentId = null;
                                        this.item.apartmentName = '';
                                        this.item.residentId = null;
                                        this.item.residentName = '';
                                        this.item.rentalApartment.phone = '';
                                        this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                                        return;
                                    }
                                },
                                (err) => {
                                    this.item.apartmentId = null;
                                    this.item.apartmentName = '';
                                    this.item.residentId = null;
                                    this.item.residentName = '';
                                    this.item.rentalApartment.phone = '';
                                    this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                                    return;
                                }
                            )

                            this.viewRef.clear();
                        }
                    }
                ],
            });
        } else {
            this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
                (res) => {
                    if (res) {
                        this.item.apartmentId = value.ApartmentId;
                        this.item.apartmentName = value.Name;
                        this.item.residentId = res.ResidentId;
                        this.item.residentName = res.FullName;
                        this.item.rentalApartment.phone = res.Phone;

                    } else {
                        this.item.apartmentId = null;
                        this.item.apartmentName = '';
                        this.item.residentId = null;
                        this.item.residentName = '';
                        this.item.rentalApartment.phone = '';
                        this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                        return;
                    }
                },
                (err) => {
                    this.item.apartmentId = null;
                    this.item.apartmentName = '';
                    this.item.residentId = null;
                    this.item.residentName = '';
                    this.item.rentalApartment.phone = '';
                    this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                    return;
                }
            )
        }
    }

    onGetDetail(id) {
        this.rentalApartmentService.getDetail(id).subscribe(
            (res) => {
                if (res) {
                    this.setDataDetail(res);
                    this.item = new RentalApartmentForm();
                    this.item.id = res['id'];
                    this.item.projectId = res['projectId'];
                    this.item.towerId = res['towerId'];
                    this.item.floorId = res['floorId'];
                    this.item.apartmentId = res['apartmentId'];
                    this.item.apartmentName = res['apartmentName'];
                    this.item.residentId = res['residentId'];
                    this.item.residentName = res['residentName'];
                    this.item.note = res['note'];
                    this.item.processStatus = res['processStatus'];
                    this.item.rentalApartment = res['rentalApartment'];
                    this.item.inform = res['inform'];
                    this.statusItem = res['processStatus'];
                    this.rentalApartmentProcessStatus = RentalApartmentProcessStatus;

                    this.rentalApartmentProcessStatus.map((item, index, arr) => { 
                        (this.statusItem > item.Id && item.Id < 23) ? item["disabled"] = true : item["disabled"] = false;
                        return item;
                    });

                    this.item.files = res['files'];

                    if(!this.item.inform)
                        this.item.disagreeSurvey = true;
                    else if(this.item.inform.resType !=1) {
                        this.item.disagreeSurvey = true;
                    }

                    this.GetRateServiceInfo();

                    this.RequestRentalApartmentFeeGetBy(this.item.projectId);
                    this.RequestRentalApartmentConfigGetById(this.item.rentalApartment.rentalApartmentConfigId);
                } else {

                    this.modalDialogService.openDialog(this.viewRef, {
                        title: 'Thông báo',
                        settings: {
                            footerClass: 'footer-dialog modal-footer'
                        },
                        childComponent: SimpleModalComponent,
                        data: {
                            text: "Đơn đăng ký không tồn tại. Vui lòng kiểm tra lại!"
                        },
                        actionButtons: [
                            {
                                text: 'Quay lại',
                                buttonClass: 'btn btn-info',
                                onAction: () => {
                                    this.returnPage();
                                }
                            },
                        ],
                    });
                }

            },
            (err) => {
                this.routerUrl.navigate(["**"])
            });
    }

    QueryChanged() {

    }

    onCheckIsShowWarning() {
        if (this.apartmentIdChanged > 0 && !this.item.id) {
            this.headerService.showWarning(true);
        } else {
            this.headerService.showWarning(false);
        }
    }

    upload(files) {
        if (files.length === 0)
            return;

        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

            }
            else if (event.type === HttpEventType.Response) {
                if (event.body["data"].length) {
                    event.body["data"].forEach(element => {
                        this.item.files.unshift({
                            id: 0,
                            fileId: 0,
                            fileRef: '',
                            url: element.toString()
                        });
                    });
                }
                if (this.file) this.file.nativeElement.value = "";
            }
        });
    }

    onChangeProject() {
        if (this.item.projectId > 0) {
            this.item.towerId = null;
            this.item.floorId = null;
            this.item.apartmentId = null;
            this.item.apartmentName = '';
            this.item.residentId = null;
            this.item.rentalApartment.phone = '';
            this.item.residentName = '';
            this.apartmentIdChanged = null;
        }

        this.GetListTower();
        this.RequestRentalApartmentFeeGetBy(this.item.projectId);
        this.RequestRentalApartmentConfigGetBy(this.item.projectId);
    }

    setDataDetail(item) {
        this.lstProject = [];
        this.lstTower = [];
        this.lstFloor = [];
        this.lstApartment = [];
        this.item.projectId = null;
        this.item.towerId = null;
        this.item.floorId = null;
        this.item.apartmentId = null;

        this.commonService.getLstProject().subscribe(
            (res) => {
                this.lstProject = res;
                this.item.projectId = item["projectId"];

                this.commonService.getLstTower(this.item.projectId).subscribe((res) => {
                    this.lstTower = res;
                    this.item.towerId = item["towerId"];

                    this.commonService.getLstFloor(this.item.towerId).subscribe((res) => {
                        this.lstFloor = res;
                        this.item.floorId = item["floorId"];

                        this.commonService.getLstApartment(this.item.floorId).subscribe((res) => {
                            this.lstApartment = res;

                            this.apartmentIdChanged = item["apartmentId"];
                            this.item.apartmentId = item["apartmentId"];
                            this.item.residentId = item["residentId"];

                            this.getResidentPhone(this.item.apartmentId);
                        })
                    })
                })
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    getResidentPhone(apartmentId) {
        this.commonService.getPurchaserByApartmentId(apartmentId).subscribe(
            (res) => {
                if (res) {
                    this.item.rentalApartment.phone = res.Phone;
                } else {
                    this.item.rentalApartment.phone = '';
                }
            },
            (err) => {
                this.item.rentalApartment.phone = '';
            }
        )
    }

    onRemoveImage(item) {
        this.item.files = [...this.item.files.filter(s => s != item)];
    }

    onShowGallery(index: number) {
        let lstImage = [];

        this.item.files.forEach(item => {
            lstImage.push({ path: domainImage + item.url })
        })

        let prop = {
            images: lstImage,
            index
        };
        this.gallery.load(prop);
    }

    returnPage() {
        this.location.back();
    }

    returnPagePaging() {
        if (this.apartmentIdChanged > 0 && !this.item.id) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer'
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Bạn chưa hoàn tất thông tin. Bạn muốn rời mà không hoàn tất không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',

                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            this.routerUrl.navigate(['/utilities/registration-construction']);
                        }
                    }
                ],
            });
        } else {
            this.location.back();
        }

    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }

    showRegConstructionStatus(constructionStatus) {
        let current = this.regConstructionStatus.filter(x => x.Id == constructionStatus)[0];
        return current != undefined ? current.Name : "";
    }

    OpenRentalApartmentInformModal(id) {
        this.http.get(domainApi + '/RequestGroup/RequestRentalApartmentInformAllGet?requestGroupId=' + id, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    const dialofRef = this.modalService.open(RentalApartmentInformComponent, { size: <any>'xxl' });
                    dialofRef.componentInstance.list = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
        });
    }

    onChangePrice(value) {
        this.item.rentalApartment.money = Number(value.replace(/[^0-9.-]+/g, ''));
        this.CalculatorFee();
    }

    RateService(id) {
        const dialofRef = this.modalService.open(RateServiceRentalApartmentComponent, { size: <any>'xl' });
        dialofRef.componentInstance.projectId = this.item.projectId;
        dialofRef.componentInstance.towerId = this.item.towerId;
        dialofRef.componentInstance.serviceOrderId = this.item.id;

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            this.onGetDetail(this.item.id);
        });
    }

    ConfirmSurvey() {
        if(!this.item.resType) {
            this.toastWarning("Vui lòng xác nhận trạng thái Lịch hẹn xem nhà!");
            return;
        }

        let data = {
            "requestGroupId": this.item.id,
            "info": "BQL",
            "resType": this.item.resType,
            "schedule": new Date(),
            "resNote": this.item.resNote
        }

        this.http.post(domainApi + '/RequestGroup/RequestRentalApartmentInformPost', data, this.httpOptions).subscribe(
            (res) => {
              if (res["meta"]["error_code"] == 200) {
                this.toastSuccess("Gửi biểu mẫu khảo sát!");
                this.routerNavigate.navigate([`/manager-service/rental-apartment`]);
              }
              else {
                this.toastError(res["meta"]["error_message"]);
              }
            },
            (err) => {
              this.isLoading = false;
              this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
            }
          );
    }

    GetRateServiceInfo() {
        this.http.get(domain + 'api/app/ServiceRate/' + this.item.id + '/3', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.ItemRate = res["data"]
                    this.GetListTypeRate();
                }
            },
            (err) => {
                console.log("Error: connect to API");
        });
    }

    GetListTypeRate() {
        this.http.get(domainApi + '/typeAttributeItem/codeAttribute/LTCDG', this.httpOptions).subscribe(
          (res) => {
            if (res["meta"]["error_code"] == 200) {
              this.typeRates = res["data"];

              this.typeRates.forEach(element => {
                    if(this.ItemRate.RateAttributeId == element.TypeAttributeItemId) {
                        this.GetListTypeRateItem(element.TypeAttributeItemId, this.item.projectId, this.item.towerId);
                    }
              });
            }
          },
          (err) => {
            console.log("Error: connect to API");
          });
      }

    GetListTypeRateItem(typeRateId, projectId, towerId) {
        let query = "TypeRateId=" + typeRateId + "AND ProjectId=" + projectId + "AND TowerId=" + towerId;
        this.http.get(domainApi + '/rateType/GetByPage?page=1&page_size=100&query=' + query + '&order_by=', this.httpOptions).subscribe(
            (res) => {
            if (res["meta"]["error_code"] == 200) {
                this.typeRateItems = res["data"];
                if(this.ItemRate.LisRateTypeId) {
                    let LisRateTypeId = this.ItemRate.LisRateTypeId.split("-");
                    this.typeRateItems.forEach(element => {
                        if(LisRateTypeId.indexOf(element.Id.toString()) !== -1) {
                            element["Check"] = true;
                        }
                    });
                }
            }
            },
            (err) => {
                console.log("Error: connect to API");
        });
    }

    //Lấy cấu hình tính phí dịch vụ
    RequestRentalApartmentFeeGetBy(projectId) {
        this.configFee = undefined;
        if(projectId) {
            this.http.get(domain + 'api/cms/RequestGroup/RequestRentalApartmentFeeGetBy?ProjectId=' + projectId, this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.configFee = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
            });
        }
    }

    //Lấy cấu hình ưu đãi
    RequestRentalApartmentConfigGetBy(projectId) {
        this.configKm = undefined;
        if(projectId) {
            this.http.get(domain + 'api/cms/RequestGroup/RequestRentalApartmentConfigGetBy?ProjectId=' + projectId, this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.configKm = res["data"];
                        this.item.rentalApartment.rentalApartmentConfigId = this.configKm ? this.configKm.id : undefined;
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
            });
        }
    }

    //Lấy cấu hình ưu đãi
    RequestRentalApartmentConfigGetById(id) {
        this.configKm = undefined;
        if(id) {
            this.http.get(domain + 'api/cms/RequestGroup/RequestRentalApartmentConfigGet/' + id, this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.configKm = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
            });
        }
    }

    //Hàm tính phí dịch vụ
    CalculatorFee() {
        this.item.rentalApartment.fee = undefined;

        if(this.configFee && this.item.rentalApartment.money) {
            this.item.rentalApartment.fee = this.item.rentalApartment.type == 1 ? (this.item.rentalApartment.money * this.configFee.rental / 100) : (this.item.rentalApartment.type == 2 ? (this.item.rentalApartment.money * this.configFee.sell / 100) : undefined);

            if(this.configKm) {
                if(this.item.rentalApartment.rentalApartmentConfigSel == 1 && this.configKm.rentalPromotionPercent) {
                    this.item.rentalApartment.fee = this.item.rentalApartment.fee * (100 - (this.item.rentalApartment.type == 1 ? this.configKm.rentalPromotionPercent : this.configKm.promotionPercent)) / 100;
                }
                else if (this.item.rentalApartment.rentalApartmentConfigSel == 3 && this.configKm.promotionValue) {
                    this.item.rentalApartment.fee =  this.item.rentalApartment.fee - (this.item.rentalApartment.type == 1 ? this.configKm.rentalPromotionValue : this.configKm.promotionValue);
                }
            }

            if(this.item.rentalApartment.fee < 0) this.item.rentalApartment.fee = 0;
        }
    }

    CanCelService(id) {
        this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
                footerClass: 'footer-dialog modal-footer',
            },
            childComponent: SimpleModalComponent,
            data: {
                text: "Bạn chắc muốn hủy phiếu đăng ký dịch vụ này không?"
            },
            actionButtons: [
                {
                    text: 'Hủy',
                    buttonClass: 'btn btn-default',
                },
                {
                    text: 'Xác nhận',
                    buttonClass: 'btn btn-success',
                    onAction: () => {
                        this.http.post(domainApi + "/RequestGroup/RequestRentalApartmentCancel/" + id, undefined)
                            .subscribe(
                                (res) => {
                                    if (res["meta"]["error_code"] == 200) {
                                        this.toastSuccess("Hủy phiếu đăng ký thành công!");
                                        this.routerNavigate.navigate([`/manager-service/rental-apartment`]);
                                    }
                                },
                                (err) => {
                                    console.log("Error: connect to API");
                                });

                        this.viewRef.clear();
                    }
                }
            ],

        });
    }

    omit_special_time(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 58) {
          return false;
        }
        return true;
    }

    keyUpTime(e, id) {
        var item1: any = document.getElementById("" + id);
        var value = e.target.value;
        if (item1 && value) {
          if (e.which === 8) {
            if (value.length == 3) {
              item1.value = value.slice(0, value.length - 1);
            }
          } else {
            if (value.length == 2) {
              if (value && Number(value) && Number(value) >= 24) {
                this.toastWarning("Giờ phải trong khoảng 00 đến 23");
                item1.value = "";
              } else {
                item1.value = value + ":";
              }
            } else if (value.length > 5) {
              item1.value = value.slice(0, 5);
            } else if (value.length == 5) {
              var mins = value.slice(3, 5);
              if (mins && Number(mins) && mins >= 60) {
                this.toastWarning("Vui lòng nhập đúng định dạng hh:mm");
                item1.value = "";
              } else {
                item1.value = value;
              }
            } else {
              item1.value = value;
            }
          }
        }
    }

    OpenCancelModal() {
        const dialofRef = this.modalService.open(CancelServiceComponent, { size: 'lg' });
        dialofRef.componentInstance.id = this.item.id;
        dialofRef.componentInstance.result.subscribe((res) => {
            this.onGetDetail(this.item.id);
        });
    }
}
