import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { FirestoreService } from '../../../service/firestore.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { NotificationDT } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi, TypeAttribute_Notification, TypeNotificationStatus } from '../../../data/const';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-notification-history',
    templateUrl: './notification-history.component.html',
    styleUrls: ['./notification-history.component.scss']
})
export class NotificationHistoryComponent implements OnInit {

    @ViewChild('modalCommon') public modalCommon: ModalDirective;
    @ViewChild('f') public form: NgForm;

    public paging: Paging;
    public q: QueryFilter;

    public listCommon = [];
    public listTypeNotification = [];
    public listTypeNotificationStatus = TypeNotificationStatus;

    public Item: NotificationDT;

    public httpOptions: any;

    public submitted = false;

    public ActionTable = ActionTable;
    public ActionId: number;
    public CheckAll: boolean;
    public CheckBoxStatus: boolean;

    public domainImage = domainImage;

    constructor(public http: HttpClient, public modalDialogService: ModalDialogService,
        public viewRef: ViewContainerRef, public toastr: ToastrService,
        public firestore: FirestoreService, public titleService: Title) {
        this.Item = new NotificationDT();

        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 10;
        this.paging.query = "1=1";
        this.paging.order_by = "";
        this.paging.item_count = 0;
        this.CheckBoxStatus = true;

        this.q = new QueryFilter();
        this.q.txtSearch = "";

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Lịch sử thông báo");
        this.GetListCommon();
        this.GetListTypeNotification();
        //this.getCoffeeOrders();

        //console.log(this.firestore.getConversation());
    }

    //getCoffeeOrders() {
    //    this.firestore.getConversation().subscribe((res) => {
    //        //if (res["meta"]["error_code"] == 200) {
    //        this.listTest = res;
    //        this.GetListCommon();
    //        console.log(res);
    //        //    this.paging.item_count = res["metadata"];
    //        //}
    //    },
    //   (err) => {
    //       console.log("Error: connect to API:" + err);
    //   });
    //}

    //Chuyển trang
    PageChanged(event) {
        this.paging.page = event.page;
        this.GetListCommon();
    }
    //Toast cảnh báo
    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    QueryChanged() {
        let query = '1=1';

        if (this.q.Type != null) {
            query += ' and Type=' + this.q.Type;
        }

        if (this.q.StatusProcessId != null) {
            query += ' and Status=' + this.q.StatusProcessId;
        }

        if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
            //if (query != '') {
            query += ' and Title.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';
            //}
            //else {
            //    query += 'Title.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';

            //}
        }

        //if (query == '')
        //    this.paging.query = '1=1';
        //else
        this.paging.query = query;

        this.GetListCommon();
    }

    SortTable(str) {
        let First = "";
        let Last = "";
        if (this.paging.order_by != "") {
            First = this.paging.order_by.split(" ")[0];
            Last = this.paging.order_by.split(" ")[1];
        }

        if (First != str) {
            this.paging.order_by = str + " Desc";
        }
        else {
            Last = Last == "Asc" ? "Desc" : "Asc";
            this.paging.order_by = str + " " + Last;
        }

        this.GetListCommon();
    }

    GetClassSortTable(str) {
        if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
            return "sorting";
        }
        else {
            if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
            else return "sorting_asc";
        }
    }

    CheckActionTable(TowerId) {
        if (TowerId == undefined) {
            let CheckAll = this.CheckAll;
            this.listCommon.forEach(item => {
                item.Action = CheckAll;
            });
        }
        else {
            let CheckAll = true;
            for (let i = 0; i < this.listCommon.length; i++) {
                if (!this.listCommon[i].Action) {
                    CheckAll = false;
                    break;
                }
            }

            this.CheckAll = CheckAll == true ? true : false;
        }
    }

    ActionTableFunc() {
        switch (this.ActionId) {
            case 1:
                let data = [];
                this.listCommon.forEach(item => {
                    if (item.Action == true) {
                        data.push(item.TowerId);
                    }
                });
                if (data.length == 0) {
                    this.toastWarning("Chưa chọn mục cần xóa!");
                }
                else {
                    this.modalDialogService.openDialog(this.viewRef, {
                        title: 'Xác nhận',
                        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
                        data: {
                            text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
                        },
                        actionButtons: [
                            {
                                text: 'Đồng ý',
                                buttonClass: 'btn btn-success',
                                onAction: () => {
                                    this.http.put(domainApi + '/tower/deletes', data, this.httpOptions).subscribe(
                                        (res) => {
                                            if (res["meta"]["error_code"] == 200) {
                                                this.toastSuccess("Xóa thành công!");
                                                this.GetListCommon();
                                                this.ActionId = undefined;
                                            }
                                            else {
                                                this.toastError(res["meta"]["error_message"]);
                                            }
                                        },
                                        (err) => {
                                            this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                                        }
                                    );
                                    this.viewRef.clear();
                                }
                            },
                            {
                                text: 'Đóng',
                                buttonClass: 'btn btn-default',
                            }
                        ],
                    });
                }
                break;
            default:
                break;
        }
    }

    //Get danh sách chức năng
    GetListCommon() {
        this.http.get(domainApi + '/notification/listHistory?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listCommon = res["data"];
                    this.listCommon.forEach(item => {
                        item.IsShow = item.Status == 1 ? true : false;
                    });
                    this.paging.item_count = res["metadata"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    //Danh sách loại thông báo
    GetListTypeNotification() {
        this.http.get(domainApi + '/TypeAttributeItem/codeAttribute/TYPENOTIFICATION', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listTypeNotification = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    OpenCommonModal(item) {
        this.CheckBoxStatus = true;
        this.submitted = false;
        this.Item = new NotificationDT();
        this.Item.Content = "";
        if (item != undefined) {
            this.Item = Object.assign(this.Item, item);
            //this.CheckBoxStatus = this.Item.Status == 1 ? true : false;
        }

        this.modalCommon.show();
    }

    //SaveCommon() {
    //    this.submitted = true;
    //    this.Item.Status = this.CheckBoxStatus ? 1 : 10;
    //    let data = Object.assign({}, this.Item);
    //    data.UserId = parseInt(localStorage.getItem("userId"));

    //    if (data.NotificationId == undefined) {
    //        this.http.post(domainApi + '/notification/pushNotification', data, this.httpOptions).subscribe(
    //            (res) => {
    //                this.submitted = false;
    //                if (res["meta"]["error_code"] == 200) {
    //                    this.GetListCommon();
    //                    this.modalCommon.hide();
    //                    this.toastSuccess("Thêm mới thành công!");
    //                    this.form.resetForm();
    //                }
    //                else {
    //                    this.toastError(res["meta"]["error_message"]);
    //                }
    //            },
    //            (err) => {
    //                this.submitted = false;
    //                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
    //            }
    //        );
    //    }
    //    //else {
    //    //    this.http.put(domainApi + '/notificationtem/' + data.NotificationTemId, data, this.httpOptions).subscribe(
    //    //        (res) => {
    //    //            this.submitted = false;
    //    //            if (res["meta"]["error_code"] == 200) {
    //    //                this.GetListCommon();
    //    //                this.modalCommon.hide();
    //    //                this.toastSuccess("Cập nhật thành công!");
    //    //                this.form.resetForm();
    //    //            }
    //    //            else {
    //    //                this.toastError(res["meta"]["error_message"]);
    //    //            }
    //    //        },
    //    //        (err) => {
    //    //            this.submitted = false;
    //    //            this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
    //    //        }
    //    //    );
    //    //}
    //}

    //Popup xác nhận xóa
    //ShowConfirmDelete(Id) {
    //    this.modalDialogService.openDialog(this.viewRef, {
    //        title: 'Xác nhận',
    //        settings: {
//           footerClass: 'footer-dialog modal-footer'
//         },
// childComponent: SimpleModalComponent,
    //        data: {
    //            text: "Bạn có chắc chắn muốn xóa mục này?"
    //        },
    //        actionButtons: [
    //            {
    //                text: 'Đồng ý',
    //                buttonClass: 'btn btn-success',
    //                onAction: () => {
    //                    this.http.delete(domainApi + '/notificationtem/' + Id, this.httpOptions).subscribe(
    //                        (res) => {
    //                            if (res["meta"]["error_code"] == 200) {
    //                                this.GetListCommon();
    //                                this.viewRef.clear();
    //                                this.toastSuccess("Xóa thành công!");
    //                            }
    //                            else {
    //                                this.toastError(res["meta"]["error_message"]);
    //                            }
    //                        },
    //                        (err) => {
    //                            this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
    //                        }
    //                    );
    //                }
    //            },
    //            {
    //                text: 'Đóng',
    //                buttonClass: 'btn btn-default',

    //            }
    //        ],
    //    });
    //}

    RePushNotificaton(item) {
        this.http.put(domainApi + '/notification/rePush/' + item.NotificationId, item, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.toastSuccess("Thông báo đang trong quá trình gửi lại!");
                    this.GetListCommon();
                    this.viewRef.clear();
                }
                else {
                    this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }

            },
            (err) => {
                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
            }
        );
    }


}
