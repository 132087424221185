import { map } from 'rxjs/operators';
import { domain, domainImage, lstIdentityType, statusCardRequestGroup, CardType } from './../../../../data/const';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { v1 as uuidv1 } from 'uuid';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { resResidentRequest } from '../../../../data/Model';
import { resResidentCard } from '../../../../data/Model';
import { Gallery } from 'angular-gallery';

export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-resident-card',
  templateUrl: './resident-card.component.html',
  styleUrls: ['./resident-card.component.scss'],
  providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class ResidentCardComponent implements OnInit {
  @ViewChild('file') file: ElementRef;
  @Output() itemResidentCard: EventEmitter<any> = new EventEmitter();
  @Input() apartmentId: number;
  @Input() lstResidentRequest: resResidentRequest[];
  @Input() lstResidentCardRegister : any[];
  @Input() itemDetail: resResidentCard;
  @Input() processStatus: statusCardRequestGroup;
  @Input() lstCard: any[];
  @Input() oldProcessStatus: number;
  @Input() residentRequestId: number;

  public isDisabled = true;
  public lstRelations = [];
  public lstIdentityType = lstIdentityType;
  public lstResident = [];
  public lstResidentCard = [];
  public item: resResidentCard;
  public isCreateDialog = false;
  public domainImage = domainImage;
  public cardType = CardType;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private gallery: Gallery
  ) {
    this.item = new resResidentCard();
    this.item.cardId = 0;
    this.item.residentId = null;
    this.item.residentIdTemporary = null;
    this.item.files = [];
  }

  ngOnInit() {
    this.getRelationShips();

    if (this.itemDetail && this.itemDetail.residentId > 0) {
      this.isCreateDialog = true;

      let isExistCard = this.lstCard.find(s => s.cardId === this.itemDetail.cardId && this.itemDetail.cardId !== 0 );

      if (!isExistCard) {
        this.lstCard.unshift({
          cardId: this.itemDetail.cardId,
          cardNumber: this.itemDetail.cardName,
          cardCode: this.itemDetail.cardName,
          cardType: 1,
          disabled: true
        });
      }
    }

    if (this.itemDetail) {
      this.item.files = this.itemDetail.files;
    }

    this.onCheckAddResident();
    this.getListResident();
  }

  onSubmit(){
    if (!this.item.residentId && !this.item.residentIdTemporary) {
      this.toastWarning("Vui lòng chọn người đăng ký");
      return;
    } else if (this.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE &&
      (!this.item.cardId || this.item.cardId == 0)) {
      this.toastWarning("Vui lòng chọn mã số thẻ");
      return;
    }

    if (this.item.cardId > 0) {
      this.item.cardName = this.lstCard.find(s => s.cardId == this.item.cardId).cardNumber;
    }

    if (this.item.relationshipId > 0) {
      this.item.relationshipName = this.lstRelations.find(s => s.Id == this.item.relationshipId).Name  || '';
    }

    if (!this.itemDetail) {
      this.item.residentCardIdTemporary = uuidv1();
    }

    if (!this.item.identifyType) {
      this.item.identifyType = 2;
    }

    this.itemResidentCard.emit(this.item);
    this.modal.close();
  }

  // getLstCard(){
  //   this.http.get(domain + 'api/cms/Card/getByPage?page=1&page_size=30&query=CardStatus=0').subscribe(
  //     (res) => {
  //       if (res["meta"]["error_code"] == 200) {
  //         this.lstCard =res["data"];
  //       }
  //     },
  //     (err) => {
  //       console.log("Error: connect to API");
  //     });
  // }

  QueryChanged(){

  }

  onCheckAddResident () {
    if (this.lstResidentRequest.length) {
      for(let i = 0; i < this.lstResidentRequest.length ; i ++){
        var itemResidentRequest = this.lstResidentRequest[i];

        if (itemResidentRequest.residentIdTemporary) {
          this.lstResident.push({
            ResidentId: 0,
            ResidentIdTemporary: itemResidentRequest.residentIdTemporary,
            ResidentName: itemResidentRequest.fullName,
            Birthday: itemResidentRequest.birthday,
            Gender: itemResidentRequest.gender,
            Phone: itemResidentRequest.phone,
            IdentifyType: itemResidentRequest.identifyType,
            IdentifyCode: itemResidentRequest.identifyCode,
            IdentifyCreate: itemResidentRequest.identifyCreate,
            IdentifyLoc: itemResidentRequest.identifyLoc,
            RelationshipId: itemResidentRequest.relationshipId,
            RelationshipName: itemResidentRequest.relationshipName,
            disabled: false,
          });
        } else if (itemResidentRequest.residentId) {
          this.lstResident.push({
            ResidentId: itemResidentRequest.residentId,
            ResidentIdTemporary: null,
            ResidentName: itemResidentRequest.fullName,
            Birthday: itemResidentRequest.birthday,
            Gender: itemResidentRequest.gender,
            Phone: itemResidentRequest.phone,
            IdentifyType: itemResidentRequest.identifyType,
            IdentifyCode: itemResidentRequest.identifyCode,
            IdentifyCreate: itemResidentRequest.identifyCreate,
            IdentifyLoc: itemResidentRequest.identifyLoc,
            RelationshipId: itemResidentRequest.relationshipId,
            RelationshipName: itemResidentRequest.relationshipName,
            disabled: false,
          });
        }
      }
    }
  }

  getListResident() {
    this.http.get(domain + 'api/cms/Resident/getResident/' + this.apartmentId + '/1/100').subscribe(
      (res) => {
        if (res["meta"]["error_code"] < 300) {
          this.lstResident = this.lstResident.concat(res["data"]["Results"]);

          [...this.lstResident.map(s => s.disabled = false)];

          if (this.itemDetail) {
            var isExist = this.lstResident.findIndex(s => s.ResidentId == this.itemDetail.residentId);

            if (isExist < 0) {
              this.lstResident.unshift({
                ResidentId: this.itemDetail["residentId"],
                ResidentIdTemporary: this.itemDetail["residentIdTemporary"] || null,
                ResidentName: this.itemDetail["residentName"],
                RelationshipId: this.itemDetail["relationshipId"],
                RelationshipName: this.itemDetail["relationshipName"]  || '',
                Birthday: this.itemDetail["birthday"],
                Phone: this.itemDetail["phone"],
                IdentifyCreate: this.itemDetail["identifyCreate"] || null,
                IdentifyLoc: this.itemDetail["identifyLoc"] || '',
                IdentifyCode: this.itemDetail["identifyCode"],
                IdentifyType: this.itemDetail["identifyType"] || 0,
                Gender: this.itemDetail["gender"],
                CardId: this.itemDetail["cardId"],
                CardName: this.itemDetail["cardName"],
                disabled: false
              });

            }

            this.item.cardRequestId = this.itemDetail["cardRequestId"];
            this.item.birthday = new Date(this.itemDetail["birthday"]);
            this.item.createdById = this.itemDetail["createdById"];
            this.item.gender = this.itemDetail["gender"];
            this.item.identifyCode = this.itemDetail["identifyCode"];
            this.item.identifyCreate = this.itemDetail["identifyCreate"] || null;
            this.item.identifyLoc = this.itemDetail["identifyLoc"] || '';
            this.item.identifyType = this.itemDetail["identifyType"] || 0;
            this.item.phone = this.itemDetail["phone"];
            this.item.relationshipId = this.itemDetail["relationshipId"];
            this.item.relationshipName = this.itemDetail["relationshipName"]  || '';
            this.item.residentCardId = this.itemDetail["residentCardId"];
            this.item.residentCardIdTemporary = this.itemDetail["residentCardIdTemporary"]  || null;
            this.item.residentId = this.itemDetail["residentId"];
            this.item.residentIdTemporary = this.itemDetail["residentIdTemporary"];
            this.item.residentName = this.itemDetail["residentName"];
            this.item.cardId = this.itemDetail["cardId"];
            this.item.cardName = this.itemDetail["cardName"];
          }

          if (this.lstResidentCardRegister.length) {
            for(let l = 0; l < this.lstResidentCardRegister.length; l++) {
                let indexDisabled = this.lstResident.findIndex(s => ((s.ResidentId == this.lstResidentCardRegister[l].residentId && this.lstResidentCardRegister[l].residentId > 0)));

                if (indexDisabled >= 0) {
                  this.lstResident[indexDisabled].disabled = true;
                }

                indexDisabled = this.lstResident.findIndex(s => (this.lstResidentCardRegister[l].residentIdTemporary != null && s.ResidentIdTemporary == this.lstResidentCardRegister[l].residentIdTemporary));

                if (indexDisabled >= 0) {
                  this.lstResident[indexDisabled].disabled = true;
                }

                // if (this.itemDetail) {
                //   indexDisabled = this.lstResident.findIndex(s => ((this.itemDetail.residentId > 0 && s.ResidentId != this.itemDetail.residentId) || (s.residentIdTemporary != this.itemDetail.residentIdTemporary && this.itemDetail.residentIdTemporary != null)));

                //   if (indexDisabled >= 0) {
                //     console.log(3);
                //     this.lstResident[indexDisabled].disabled = true;
                //   }
                // }
            }
          }

          this.getResidentCard();
        }
      },
      (err) => {
        console.log("Error: connect to API")
      });
  }

  getResidentCard(){
    this.http.get(domain + 'api/Reg/getResidentCard?apartmentId=' + this.apartmentId).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstResidentCard = this.lstResidentCard.concat(res["data"]);

          for (let k = 0; k < this.lstResidentCard.length ; k ++){
            let itemRC = this.lstResidentCard[k];

            let indexDisabled = this.lstResident.findIndex(s => s.ResidentId == itemRC.residentId);
            let indexTemporaryDisabled = this.lstResident.findIndex(s => s.ResidentIdTemporary != null && s.ResidentIdTemporary == itemRC.residentIdTemporary);

            if (indexTemporaryDisabled >= 0) {
              this.lstResident[indexTemporaryDisabled].disabled = true;
            }

            if (indexDisabled >= 0) {
              this.lstResident[indexDisabled].disabled = true;
            }

            this.lstResident = [...this.lstResident];

          }

          if (this.residentRequestId > 0) {
            for(var j = 0; j < this.lstResident.length; j ++) {
              if (this.lstResident[j]['ResidentId'] > 0 && this.lstResident[j]['ResidentId'] == this.residentRequestId) {
                this.item.relationshipId = -1;
                this.lstResident[j]['RelationshipId'] = -1;
                this.lstResident[j]['RelationshipName'] = "Là chủ hộ";
              }
            }
          }
          [...this.lstResident];
          console.log(this.lstResident);
          console.log(this.residentRequestId);
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  getRelationShips () {
    this.http.get(domain + 'api/cms/TypeAttributeItem/CodeAttribute/relationship').subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstRelations = res["data"];

          this.lstRelations.unshift({
            Id: -1,
            Name: 'Chủ hộ'
          });
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  onChangeResident(value){
    if (value) {
      this.item.residentIdTemporary = value["ResidentIdTemporary"] || null;
      this.item.relationshipId = value["RelationshipId"];
      this.item.relationshipName = value["RelationshipName"];
      this.item.birthday = value["Birthday"];
      this.item.phone = value["Phone"];
      this.item.identifyType = value["IdentifyType"] || 0;
      this.item.identifyCode = value["IdentifyCode"];
      this.item.identifyLoc = value["IdentifyLoc"];
      this.item.identifyCreate = value["IdentifyCreate"];
      this.item.gender = value["Gender"];
      this.item.residentId = value["ResidentId"];
      this.item.residentName = value["ResidentName"];
      this.item.cardRequestId = value["cardRequestId"] || 0;
    }
  }

  upload(files){
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

      }
      else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.files.unshift({
              id: 0,
              fileId: 0,
              fileRef: '',
              url: element.toString()
            });
          });
        }
        if (this.file) this.file.nativeElement.value = "";
      }
    });
  }

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
    lstImage.push({path: domainImage + item.url})
    })

    let prop = {
        images: lstImage,
        index
    };
    this.gallery.load(prop);
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
  }
}
