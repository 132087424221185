import { resResidentCard, resResidentRequest } from '../../../../data/Model';
import { domain, domainImage, lstIdentityType } from './../../../../data/const';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import * as moment from 'moment';
import { Gallery } from 'angular-gallery';

export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-change-resident-card',
  templateUrl: './change-resident-card.component.html',
  styleUrls: ['./change-resident-card.component.scss'],
  providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class ChangeResidentCardComponent implements OnInit {
  @Output() itemResidentCard: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: resResidentCard;
  @Input() oldProcessStatus: number;
  @ViewChild('file') file: ElementRef;

  public isDisabled = true;
  public lstRelations = [];
  public lstIdentityType = lstIdentityType;
  public lstResident = [];
  public item: resResidentCard;
  public dateNow = new Date();
  public phoneExist: string;
  public maxDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };
  public domainImage = domainImage;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private gallery: Gallery
  ) {
    this.item = new resResidentCard();

  }

  ngOnInit() {
    this.phoneExist = this.itemDetail ? (this.itemDetail.phone || '') : '';

    this.getRelationShips();
    this.getDataCard(this.itemDetail);
  }

  onSubmit(){
    if (!this.item.residentName) {
      this.toastWarning("Vui lòng nhập tên chủ thẻ");
      return;
    } else if (!this.item.relationshipId) {
      this.toastWarning("Vui lòng nhập tên chủ thẻ");
      return;
    }
    // else if (moment(this.item.birthday).isValid()) {
    //   this.toastWarning("Ngày sinh không hợp lệ");
    //   return;
    // } else if (!moment(this.item.identifyCreate).isValid()) {
    //   this.toastWarning("Ngày cấp không hợp lệ");
    //   return;
    // }

    this.item.birthday = new Date(moment(this.item.birthday).format("YYYY-MM-DD"));
    this.item.identifyCreate = this.item.identifyCreate ? new Date(moment(this.item.identifyCreate).format("YYYY-MM-DD")) : null;
    this.item.relationshipName = this.lstRelations.find(s => s.Id == this.item.relationshipId).Name;

    if (this.item.phone && this.item.phone != this.phoneExist) {
      this.http.get(domain + 'api/cms/Resident/checkExistPhone/' + this.item.phone).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 400) {
            this.toastWarning("Số điện thoại đã tồn tại. vui chọn nhập lại.");
            return;
          } else {
            this.itemResidentCard.emit(this.item);
            this.modal.close();
          }
        },
        (err) => {
          console.log("Error: connect to API");
          return;
        });
    } else {
      this.itemResidentCard.emit(this.item);
      this.modal.close();
    }
  }

  getDataCard(itemCard) {
    this.item.cardRequestId = itemCard.cardRequestId || 0;
    this.item.residentId = itemCard.residentId;
    this.item.residentName = itemCard.residentName;
    this.item.birthday = new Date(itemCard.birthday); //moment(itemCard.birthday).format('YYYY-MM-DD');
    this.item.gender = itemCard.gender;
    this.item.phone = itemCard.phone;
    this.item.identifyType = itemCard.identifyType;
    this.item.identifyLoc = itemCard.identifyLoc;
    this.item.identifyCreate = itemCard.identifyCreate;
    this.item.identifyCode = itemCard.identifyCode;
    this.item.relationshipId = itemCard.relationshipId;
    this.item.relationshipName = itemCard.relationshipName;
  }

  getRelationShips () {
    this.http.get(domain + 'api/cms/TypeAttributeItem/CodeAttribute/relationship').subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstRelations = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  omit_special_char(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  omit_special_phone(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (this.item.phone.length > 9) {
      return false;
    }

    return true;
}

omit_special_date(e) {
  const charCode = (e.which) ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 47 || charCode > 57)) {
    return false;
  }

  if (this.item.phone.length >= 10) {
    return false;
  }

  return true;
}

upload(files){
  if (files.length === 0)
    return;

  const formData = new FormData();

  for (let file of files)
    formData.append(file.name, file);

  const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
    reportProgress: true,
  });

  this.http.request(uploadReq).subscribe(event => {
    if (event.type === HttpEventType.UploadProgress) {

    }
    else if (event.type === HttpEventType.Response) {
      if (event.body["data"].length) {
        event.body["data"].forEach(element => {
          this.item.files.unshift({
            id: 0,
            fileId: 0,
            fileRef: '',
            url: element.toString()
          });
        });
      }
      if (this.file) this.file.nativeElement.value = "";
    }
  });
}

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
    lstImage.push({path: domainImage + item.url})
    })

    let prop = {
        images: lstImage,
        index
    };
    this.gallery.load(prop);
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
  }
}
