import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestTechnicalGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.isPermission(next.data.typeUser);
  }

  isPermission(typeUserAccess: string ) {
    const userCode = localStorage.getItem("roleCode");
    const typeUser = userCode == "DVVS" ? "C_One" : "Admin";

    if (!userCode) {
      this.router.navigate(['/**']);
        return false;
    }

    if (typeUserAccess != 'All' && (typeUserAccess != typeUser)) {
      this.router.navigate(['/**']);
      return false;
    } else {
      return true;
    }
  }
}
