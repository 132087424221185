import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Resident, ApartmentResident, UserChangePass } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, TypeResident, patternPhone, domainApi, TypeAttribute_Relationship, TypeCountry } from '../../../data/const';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Md5 } from 'ts-md5';
import { Title } from "@angular/platform-browser";

//Định dạng ngày h trên Modal
export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
	selector: 'app-residents',
	templateUrl: './residents.component.html',
	styleUrls: ['./residents.component.scss'],
	providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class ResidentsComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;
	@ViewChild('file') file: ElementRef;
	@ViewChild('ApartmentModal') public ApartmentModal: ModalDirective;
	@ViewChild('ChangePasswordModalUser') public ChangePasswordModalUser: ModalDirective;
	@ViewChild('fChild') public formChild: NgForm;
	@ViewChild('fChangePass') public formChangePass: NgForm;
	isShowFilter = false;
	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public projects = [];
	public towers = [];
	public floors = [];
	public apartments = [];

	public filterTowers = [];
	public filterFloors = [];
	public filterApartments = [];

	public relationships = [];

	public Item: Resident;
	public Apartment: ApartmentResident;
	public ChangePasswordUser: UserChangePass;

	public httpOptions: any;

	public submitted = false;
	public submittedChangePas = false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	public domainImage = domainImage;
	public typeResident = TypeResident;
	public typeCountry = TypeCountry;
	public patternPhone = patternPhone;

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
		this.Item = new Resident();
		this.Item.CountryId = 1;
		this.Apartment = new ApartmentResident();
		this.ChangePasswordUser = new UserChangePass();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "ResidentId Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Danh sách cư dân");
		this.GetListCommon();
		this.GetListProject();
		this.GetListRelationship();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = '';
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			this.paging.search = this.q.txtSearch;
			//if (query != '') {
			//         query += ' and (FullName.Contains("' + this.q.txtSearch + '") or Phone.Contains("' + this.q.txtSearch + '"))';
			//}
			//else {
			//             query += '(FullName.Contains("' + this.q.txtSearch + '") or Phone.Contains("' + this.q.txtSearch + '"))';
			//}
		}

		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(ResidentId) {
		if (ResidentId == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				let data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.ResidentId);
					}
				});
				if (data.length == 0) {
					this.toastWarning("Chưa chọn mục cần xóa!");
				}
				else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
						data: {
							text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + '/Resident/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												this.toastSuccess("Xóa thành công!");
												this.GetListCommon();
												this.ActionId = undefined;
											}
											else {
												this.toastError(res["meta"]["error_message"]);
											}
										},
										(err) => {
											this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	//Get danh sách
	GetListCommon() {
		let params = "";
		if (this.q.ProjectId) {
			params = "&ProjectId=" + this.q.ProjectId;

			if (this.q.TowerId) {
				params = params + "&TowerId=" + this.q.TowerId;

				if (this.q.FloorId) {
					params = params + "&FloorId=" + this.q.FloorId;

					if (this.q.ApartmentId) {
						params = params + "&ApartmentId=" + this.q.ApartmentId;
					}
				}
			}
		}

		if (this.q.Type != undefined)
			params = params + "&type=" + this.q.Type;

		this.paging.search = "";
		if (this.q.txtSearch != undefined)
			this.paging.search = this.q.txtSearch;

		this.http.get(domainApi + '/Resident/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + params + '&search=' + this.paging.search, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listCommon = res["data"];
					this.paging.item_count = res["metadata"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenCommonModal(item) {
		this.file.nativeElement.value = "";
		this.submitted = false;
		this.Item = new Resident();
		this.Item.CountryId = 1;
		this.Item.apartments = [];
		this.Apartment = new ApartmentResident();
		if (item == undefined) {
			if (this.q["Type"]) {
				this.Item.Type = this.q["Type"];
			}

		}
		else {
			this.Item = JSON.parse(JSON.stringify(item));
			this.GetListTower(true);
		}

		this.modalCommon.show();
	}

	SaveCommon() {
		this.submitted = true;

		let data = Object.assign({}, this.Item);
		data.UserId = parseInt(localStorage.getItem("userId"));

		if (data.Birthday) {
			let obj = new Date(data.Birthday);
			data.Birthday = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " 0:0:0";
		}

		if (data.ResidentId == undefined) {
			this.http.post(domainApi + '/Resident', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Thêm mới thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.http.put(domainApi + '/Resident/' + data.ResidentId, data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Cập nhật thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/Resident/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	//Danh sách dự án
	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.projects = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	//Danh sách tòa nhà
	GetListTower(OpenUpdate) {
		this.towers = [];
		if (!OpenUpdate) this.Apartment.TowerId = undefined;
		if (this.Apartment.ProjectId) {
			let query = "ProjectId=" + this.Apartment.ProjectId;
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.towers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListFloor(OpenUpdate);
	}

	//Danh sách tầng
	GetListFloor(OpenUpdate) {
		this.floors = [];
		if (!OpenUpdate) this.Apartment.FloorId = undefined;
		if (this.Apartment.TowerId) {
			let query = "TowerId=" + this.Apartment.TowerId;
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.floors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListApartment(OpenUpdate);
	}

	//Danh sách tầng
	GetListApartment(OpenUpdate) {
		this.apartments = [];
		if (!OpenUpdate) this.Apartment.ApartmentId = undefined;
		if (this.Apartment.FloorId) {
			let query = "FloorId=" + this.Apartment.FloorId;
			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.apartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

	}

	ChooseApartment() {
		let FullName = "";
		if (this.Apartment.ApartmentId) {
			for (let i = 0; i < this.apartments.length; i++) {
				if (this.Apartment.ApartmentId == this.apartments[i].ApartmentId) {
					FullName = this.apartments[i].Name;
					break
				}
			}

			for (let i = 0; i < this.floors.length; i++) {
				if (this.Apartment.FloorId == this.floors[i].FloorId) {
					FullName = FullName + "-" + this.floors[i].Name;
					break
				}
			}

			for (let i = 0; i < this.towers.length; i++) {
				if (this.Apartment.TowerId == this.towers[i].TowerId) {
					FullName = FullName + "-" + this.towers[i].Name;
					break
				}
			}

			for (let i = 0; i < this.projects.length; i++) {
				if (this.Apartment.ProjectId == this.projects[i].ProjectId) {
					FullName = FullName + "-" + this.projects[i].Name;
					break
				}
			}
		}

		this.Apartment.FullName = FullName;
	}

	QueryChangeProject() {
		this.q["TowerId"] = undefined;
		this.filterTowers = [];
		if (this.q["ProjectId"]) {
			let query = "ProjectId=" + this.q["ProjectId"];
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterTowers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeTower();
	}

	QueryChangeTower() {
		this.q["FloorId"] = undefined;
		this.filterFloors = [];
		if (this.q["TowerId"]) {
			let query = "TowerId=" + this.q["TowerId"];
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterFloors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeFloor();
	}

	QueryChangeFloor() {
		this.q["ApartmentId"] = undefined;
		this.filterApartments = [];
		if (this.q["FloorId"]) {
			let query = "FloorId=" + this.q["FloorId"];
			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterApartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	//upload ảnh hiển thị
	upload(files) {
		if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
				this.Item.Avata = event.body["data"].toString();
				if (this.file) this.file.nativeElement.value = "";
			}
		});
	}

	//Danh sách các mối quan hệ với chủ hộ
	GetListRelationship() {
		let query = "TypeAttributeId=" + TypeAttribute_Relationship;
		this.http.get(domainApi + '/typeAttributeItem/codeAttribute/relationship', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.relationships = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenApartmentModal(Idx) {
		if (Idx == undefined) {
			this.Apartment = new ApartmentResident();
			this.Apartment.Code = "";
			this.Apartment.Name = "";
			this.Apartment.RelationshipName = "";
			if (this.q["ProjectId"]) {
				this.Apartment.ProjectId = this.q["ProjectId"];
			}

			if (this.q["TowerId"]) {
				this.Apartment.TowerId = this.q["TowerId"];
			}

			if (this.q["FloorId"]) {
				this.Apartment.FloorId = this.q["FloorId"];
			}

			if (this.q["ApartmentId"]) {
				this.Apartment.ApartmentId = this.q["ApartmentId"];
			}
		}
		else {
			this.Apartment = JSON.parse(JSON.stringify(this.Item.apartments[Idx]));
			this.Apartment["Idx"] = Idx;
		}

		this.GetListTower(true);
		this.ApartmentModal.show();
	}

	SaveApartment() {
		if (this.Apartment["Idx"] == undefined) {
			for (let i = 0; i < this.Item.apartments.length; i++) {
				if (this.Item.apartments[i].ApartmentId == this.Apartment.ApartmentId) {
					this.toastError("Căn hộ này đã có trong danh sách!");
					return;
				}
			}

			this.Item.apartments.push(JSON.parse(JSON.stringify(this.Apartment)));
		}
		else {
			for (let i = 0; i < this.Item.apartments.length; i++) {
				if (this.Item.apartments[i].ApartmentId == this.Apartment.ApartmentId && i != this.Apartment["Idx"]) {
					this.toastError("Căn hộ này đã có trong danh sách!");
					return;
				}
			}

			this.Item.apartments[this.Apartment["Idx"]] = JSON.parse(JSON.stringify(this.Apartment));
		}

		this.ApartmentModal.hide()
		this.formChild.resetForm();
	}

	DeleteApartment(Idx) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa căn hộ này ra khỏi danh sách?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.Item.apartments.splice(Idx, 1);
						this.viewRef.clear();
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	ShowTypeApartmentMap(Type) {
		if (Type != undefined && Type != null) {
			return this.typeResident.filter(x => x.Id == Type)[0].Name;
		}
		else {
			return "";
		}
	}

	//Popup xác nhận mở/khóa
	ShowConfirmLock(Id, status) {
		let note = "Bạn có chắc chắn muốn khóa người dùng này?"
		if (status == 98) {
			note = "Bạn có chắc chắn muốn mở khóa người dùng này?"
			status = 1;
		}
		else
			status = 98;
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: note
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.put(domainApi + '/user/lockUser/' + Id + '/' + status, null, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									if (status == 98)
										this.toastSuccess("Khóa tài khoản thành công!");
									else
										this.toastSuccess("Mở khóa tài khoản thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	OpenModalResetPassword(userId) {
		this.submittedChangePas = false;
		this.ChangePasswordUser.PasswordNewE = undefined;
		this.ChangePasswordUser.ConfirmPassword = undefined;
		this.ChangePasswordUser.UserId = userId;
		this.ChangePasswordModalUser.show();
	}

	ChangePassword() {
		this.submittedChangePas = true;

		let obj = Object.assign({}, this.ChangePasswordUser);
		obj.PasswordNew = Md5.hashStr(obj.PasswordNewE).toString();

		this.http.put(domainApi + '/user/adminChangePass/' + obj.UserId, obj, this.httpOptions).subscribe(
			(res) => {
				this.submittedChangePas = false;
				if (res["meta"]["error_code"] == 200) {
					this.ChangePasswordModalUser.hide();
					this.toastSuccess("Đổi mật khẩu tài khoản thành công!");
					this.formChangePass.resetForm();
				}
				else {
					this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
				}
			},
			(err) => {
				this.submittedChangePas = false;
				this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
			}
		);
	}
	openToggleFilter(){
		this.isShowFilter = !this.isShowFilter;
	}
}
