import { QueryFilter, Paging } from './../data/dt';
import { RequestCleaning } from '../data/Model';
import { HttpClient } from '@angular/common/http';
import { TypeRequestCleaning, ApiConstant } from '../data/const';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RequestCleaningService {

  constructor(private readonly http: HttpClient) { }

  getDocumentCategoryByType(type: TypeRequestCleaning, towerId: number, projectId: number) {
    return this.http.get(ApiConstant.getCategoryDocByTypeAndProjectId +
      `&type=${type}&projectId=${projectId}&towerId=${towerId}`);
  }

  createCleaningRequest(data: RequestCleaning) {
    return this.http.post(ApiConstant.createRequestCleaning, data);
  }

  updateCleaningRequest(data: any) {
    return this.http.put(ApiConstant.updateRequesCleaning + data.id, data);
  }

  confirmAcceptanceCleaningRequest(id: number, dateAcceptance: Date, note: string) {
    return this.http.put(ApiConstant.confirmAcceptanceRequestCleaning + id,  {
      dateAcceptance : dateAcceptance,
      note : note
    });
  }

  updateConfirmStatus(id: number, payload: any) {
    return this.http.put(ApiConstant.updateConfirmStatusRequestCleaning + id,  payload);
  };

  getByPage(pageOption: QueryFilter, page: Paging, statusPayment: number | null, isCancel: boolean, typePayment:number | null): Observable<any> {
    let query = `?processStatus=${pageOption.StatusProcessId || -1}&type=${pageOption.Type || 0}&currentPage=${page.page}&pageSize=${page.page_size}`;

    if (pageOption.ProjectId > 0) {
      query = query.concat(`&projectId=${pageOption.ProjectId}`);
    }

    if (pageOption.TowerId > 0) {
      query = query.concat(`&towerId=${pageOption.TowerId}`);
    }

    if (pageOption.FloorId > 0) {
      query = query.concat(`&floorId=${pageOption.FloorId}`);
    }

    if (pageOption.ApartmentId > 0) {
      query = query.concat(`&apartmentId=${pageOption.ApartmentId}`);
    }

    if (pageOption.DateTimeStart) {
      query = query.concat(`&dateStart=${pageOption.DateTimeStart}`);
    }

    if (pageOption.DateTimeEnd) {
      query = query.concat(`&DateEnd=${pageOption.DateTimeEnd}`);
    }

    if (page.query) {
      query = query.concat(`&query=${page.query}`);
    }

    if (page.order_by) {
      query = query.concat(`&order_by=${page.order_by}`);
    }

    if (isCancel) {
      query = query.concat(`&isCancel=true`);
    }

    if (typePayment) {
      query = query.concat(`&typePayment=${typePayment}`);
    }

    if (statusPayment >= 0) {
      query = query.concat(`&statusPayment=${statusPayment}`);
    } else {
      query = query.concat(`&statusPayment=`);
    }

    return this.http.get(`${ApiConstant.getRequestCleaningByPage}${query}`);
  }

  detailById(id: number) {
    return this.http.get(ApiConstant.detailRequesCleaning + id);
  }

  updatelById(id: number, item: any) {
    return this.http.put(ApiConstant.updateRequesCleaning + id, item);
  }

  deleteById(id: number) {
    return this.http.delete(ApiConstant.deleteRequestCleaning + id);
  }

  cancelById(id: number, message: string) {
    return this.http.put(ApiConstant.cancelRequestCleaning + id, { note: message});
  }
}
