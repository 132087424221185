import { CustomDatepickerI18n, I18n } from './../../service/date-picker-i18n';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { domainApi, typeCardRequestGroup, statusCardRequestGroup, ApiConstant, LstTypeRequest, LstStatusRequestGroup, LstStatusPaymentRequestGroup, StatusPayment } from './../../data/const';
import { ResidentManagerService } from './../../service/resident-manager.service';
import { Paging, QueryFilter } from './../../data/dt';
import { Component, ElementRef, Injectable, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { InterceptorService } from 'ng2-interceptors';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';
import { saveFile } from '../../service/file-download-helper';

export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-resident-service-manager',
  templateUrl: './resident-service-manager.component.html',
  styleUrls: ['./resident-service-manager.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },

	]
})
export class ResidentServiceManagerComponent implements OnInit {
  @ViewChild('toggleFilter') toggleFilter: ElementRef;

	public q: QueryFilter;
  public statusRequestGroup : statusCardRequestGroup;
  public lstStatus = LstStatusRequestGroup;
  public lstStatusPayment = LstStatusPaymentRequestGroup;
  public lstFloors = [];
  public lstProjects = [];
  public lstTowers = [];
  public lstApartments = [];
  public lstTypeRequire = LstTypeRequest;
  public lstResidentService = [];
  public listCommon = [];
  public lstIdDeletes: number[] = [];
  public paging: Paging;
  public isShowFilter = false;
  public isCheckedDelete = false;
  public isToggleTypeCreate = false;
  public isProcessingExport = false;
  public CheckAll: boolean;
  public httpOptions:any;
  public type = typeCardRequestGroup;
  public status = statusCardRequestGroup;
  public statusPaymentEnum = StatusPayment;
  public isCancel = false;
  public isCheckboxCancel = false;
  public messageError = '';
  public dateAppointment: Date;
  public dateFinish: Date;
  public statusPayment: number;
  public dateNow = new Date();
  public maxDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };

  // @HostListener('click', ['$event'])
  // clickOutSide(e) {
  //   console.log(e);
  // }


  constructor(
    public titleService: Title,
    private renderer: Renderer2,
    private readonly residentService: ResidentManagerService,
    private readonly commonService: CommonService,
    public router: Router,
    private readonly http: HttpClient,
    public modalDialogService: ModalDialogService,
    private readonly toastr: ToastrService,
    public viewRef: ViewContainerRef,
    private _i18n: I18n,
    private httpDownload: InterceptorService,) {
    // if (this.lstTypeRequire.findIndex(s => s.Id == 0) === -1) {
    //   this.lstTypeRequire.unshift({
    //     Id: 0,
    //     Name: "Tất cả"
    //   })
    // };

    this.q = new QueryFilter();
		this.q.txtSearch = "";
    this.q.StatusProcessId = -1;
    this.q.TypeRequireId = 0;
    this.q.DateTimeStart = null;
    this.q.DateTimeEnd = null;

    this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 15;
    this.paging.order_by = 'rq.UpdatedAt Desc';

    this.q.TypeRequireId = null;
    this.q.StatusProcessId = null;

    this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
  }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Quản lý đăng ký dịch vụ cư dân");

    this.lstStatus.map(s => s.disabled = false);
    this.onFilter();
    this.GetListProject();
  }

  set language(language: string) {
    this._i18n.language = language;
  }

  get language() {
    return this._i18n.language;
  }

  GetListProject() {
		return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProjects = res;

        if (this.lstProjects.length == 1) {
          this.q.ProjectId = this.lstProjects[0].ProjectId;
          this.GetListTower(1);
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  //Danh sách tòa nhà
	GetListTower(OpenUpdate) {
		this.lstTowers = [];
		if (!OpenUpdate) this.q.TowerId = undefined;
		if (this.q.ProjectId) {
			let query = "ProjectId=" + this.q.ProjectId;
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.lstTowers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListFloor(OpenUpdate);
	}

  onChangeDate(value) {
    console.log(value);

    switch (value.length) {
      case 2:
        this.q.DateTimeStart = this.q.DateTimeStart.concat('/');
      case 5:
        this.q.DateTimeStart = this.q.DateTimeStart.concat('/');
    }
  }

  //Danh sách tầng
	GetListFloor(OpenUpdate) {
		this.lstFloors = [];
		if (!OpenUpdate) this.q.FloorId = undefined;
		if (this.q.TowerId) {
			let query = "TowerId=" + this.q.TowerId;
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.lstFloors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListApartment(OpenUpdate);
	}

	//Danh sách tầng
	GetListApartment(OpenUpdate) {
		this.lstApartments = [];
		if (!OpenUpdate) this.q.ApartmentId = undefined;
		if (this.q.FloorId) {
			let query = "FloorId=" + this.q.FloorId;
			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.lstApartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

	}

  openToggleFilter(){
    this.isShowFilter = !this.isShowFilter;
  }


  openToggleTypeCreate(){
    this.isToggleTypeCreate = !this.isToggleTypeCreate;
  }

  onDetail(item) {
    if (item.type === 1){
      this.router.navigate(['utilities/resident-service/request-group-registration/', item.id]);
    }
    else if (item.type === 4){
      this.router.navigate(['utilities/resident-service/request-group-change/', item.id]);
    }
    else if (item.type === 2){
      this.router.navigate(['utilities/resident-service/request-group-cancel/', item.id]);
    }
    else if (item.type === 3){
      this.router.navigate(['utilities/resident-service/request-group-renew/', item.id]);
    }
    else if (item.type === 7){
      this.router.navigate(['utilities/resident-service/request-group-lock/', item.id]);
    }
    else if (item.type === 8){
      this.router.navigate(['utilities/resident-service/request-group-unlock/', item.id]);
    }
  }

  onDelete(item: any) {
    if (item["processStatus"] != this.status.FINISHED) {
      this.modalDialogService.openDialog(this.viewRef, {
        //backdrop: 'static',
        //keyboard: false,
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer',
        },
        childComponent: SimpleModalComponent,
        data: {
          text: "Bạn chắc muốn xóa yêu cầu đăng ký này không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',
          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.http.delete(ApiConstant.DeleteRequestGroup + item["id"])
              .subscribe(
                (res) => {
                  if (res["meta"]["error_code"] == 200) {
                    this.toastSuccess("Xóa thành công!");
                    this.onFilter();
                  }
                },
                (err) => {
                  console.log("Error: connect to API");
                });

              this.viewRef.clear();
            }
          }
        ],

      });
    }
    else {
      this.toastWarning("Không thể xóa! yêu cầu này đã khóa.")
    }
  }

  onDeletes() {
    if (this.lstIdDeletes.length > 0) {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
        data: {
          text: "Bạn chắc muốn xóa các yêu cầu đăng ký đã chọn không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',
          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              let lstIdFail = [];

              for( let i = 0; i < this.lstIdDeletes.length; i ++){
                this.http.delete(ApiConstant.DeleteRequestGroup + this.lstIdDeletes[i])
                .subscribe(
                  (res) => {
                    if (res["meta"]["error_code"] == 200) {
                      this.paging.item_count = this.paging.item_count - 1;
                      this.listCommon = [...this.listCommon.filter(s => s.id !== this.lstIdDeletes[i])];
                    }
                  },
                  (err) => {
                    lstIdFail.push(this.lstIdDeletes[i]);
                    return this.toastWarning(`Xóa yêu cầu của căn hộ: ${this.lstIdDeletes[i]['apartmentName']} thất bại. ${err["meta"]["error_message"]}`);
                  });

                  this.lstIdDeletes = [];
                  this.toastSuccess("Xóa thành công!");
              }

              this.onFilter();
              this.viewRef.clear();
            }
          }
        ],
      });
    }
  }

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.onFilter();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

  PageChanged(event) {
		this.paging.page = event.page;
    this.lstIdDeletes = [];
    this.CheckAll = false;

		this.onFilter();
	}

  QueryChangeFloor() {
    this.q["ApartmentId"] = undefined;
		this.lstApartments = [];
		if (this.q["FloorId"]) {
			let query = "FloorId=" + this.q["FloorId"];
			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.lstApartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
  }

  onFilter() {
    this.messageError = '';
    this.listCommon = [];
    this.q.StatusProcessId = this.q.StatusProcessId || null;
    this.q.TypeRequireId = this.q.TypeRequireId || null;

    if (this.q.DateTimeStart && !this.q.DateTimeEnd) {
      this.q.DateTimeEnd = new Date();
    } else if (!this.q.DateTimeStart && this.q.DateTimeEnd) {
      this.toastWarning("Vui lòng chọn ngày bắt đầu lọc");
      return;
    }

    this.residentService.getByPage(this.q, this.paging, this.dateAppointment, this.dateFinish, this.statusPayment, this.isCheckboxCancel).subscribe((res) => {
      if (res["meta"]["error_code"] == 200) {
        if (res["data"]["results"].length) {
          console.log(res["data"]["results"]);
          this.listCommon = res["data"]["results"];
          this.listCommon.map(s => s.isChecked = false);
          this.paging.item_count = res["data"]["rowCount"];
        } else {
          this.listCommon = [];
          this.paging.item_count = 0;
          this.messageError = 'Không có dữ liệu';
        }
      }
      else {
        this.listCommon = [];
        this.paging.item_count = 0;
        this.messageError = 'Không có dữ liệu';
      }
    },
    (error) => {
      this.toastError(error["meta"]["error_message"]);
      this.paging.item_count = 0;
    })
  }

  QueryChangeProject(){
    this.q["TowerId"] = undefined;
		this.lstTowers = [];
		if (this.q["ProjectId"]) {
			let query = "ProjectId=" + this.q["ProjectId"];
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.lstTowers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeTower();
  }

  GetDepartmentFilter() {

  }

  onRemoveDate(typeDate) {
    if (typeDate == 1) {
      this.q.DateTimeStart = null;
    } else {
      this.q.DateTimeEnd = null;
    }
  }

  QueryChangeTower() {

    this.q["FloorId"] = undefined;
		this.lstFloors = [];
		if (this.q["TowerId"]) {
			let query = "TowerId=" + this.q["TowerId"];
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.lstFloors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeFloor();
  }

	CheckActionTable(id) {
    const lstAcceptDelete = this.listCommon.filter(s => s.processStatus < 4 && s.status == 1);

		if (id == undefined) {

      if (this.lstIdDeletes.length > 0 && this.lstIdDeletes.length < lstAcceptDelete.length) {
        this.lstIdDeletes = [];
        this.isCheckedDelete = true;

        this.listCommon.map(s => s.isChecked = (s.processStatus < 4 && s.status == 1 ? true : false));

        lstAcceptDelete.forEach(item => {
          this.lstIdDeletes.push(item.id);
        })
      }
      else if (this.lstIdDeletes.length > 0) {
        this.isCheckedDelete = false;

        lstAcceptDelete.forEach(item => {
          this.lstIdDeletes = [];
        })

        this.listCommon.map(s => s.isChecked = (s.processStatus < 4 && s.status == 1 ? false : undefined));

      } else {
        this.lstIdDeletes = [];
        this.isCheckedDelete = true;

        this.listCommon.map(s => s.isChecked = (s.processStatus < 4 && s.status == 1 ? true : false));

        lstAcceptDelete.forEach(item => {
          this.lstIdDeletes.push(item.id);
        })
      }
		}
		else {
      let isExistIndexId = this.lstIdDeletes.findIndex(s => s === id);
      let indexChecked = this.listCommon.findIndex(s => s.id === id);

      if (isExistIndexId < 0) {
        this.lstIdDeletes.push(id);
      } else {
        this.lstIdDeletes = this.lstIdDeletes.filter(item => item !== id);
      }

      this.listCommon = [...this.listCommon];
      this.isCheckedDelete = this.lstIdDeletes.length > 0 ? true : false;
		}
	}

  onCheckDisabledCheckbox(item) {
    if (item.status == 1) {
      return item.processStatus < 4 ? false : true;
    } else {
      return true;
    }
  }

  exportExcel(){
    this.isProcessingExport = true;

    let query = `?ProcessStatus=${this.q.StatusProcessId || -1}&Type=${this.q.TypeRequireId || 0}&CurrentPage=${this.paging.page}&PageSize=${this.paging.page_size}`;

    if (this.q.ProjectId > 0) {
      query = query.concat(`&ProjectId=${this.q.ProjectId}`);
    }

    if (this.q.TowerId > 0) {
      query = query.concat(`&TowerId=${this.q.TowerId}`);
    }

    if (this.q.FloorId > 0) {
      query = query.concat(`&FloorId=${this.q.FloorId}`);
    }

    if (this.q.ApartmentId > 0) {
      query = query.concat(`&ApartmentId=${this.q.ApartmentId}`);
    }

    if (this.q.DateTimeStart) {
      query = query.concat(`&DateStart=${this.q.DateTimeStart}`);
    }

    if (this.q.DateTimeEnd) {
      query = query.concat(`&DateEnd=${this.q.DateTimeEnd}`);
    }

    if (this.paging.query) {
      query = query.concat(`&query=${this.paging.query}`);
    }

    if (this.dateAppointment) {
      query = query.concat(`appointmentDate=${this.dateAppointment}`);
    }

    if (this.dateFinish) {
      query = query.concat(`&finishDate=${this.dateFinish}`);
    }

    if (this.paging.order_by) {
      query = query.concat(`&orderBy=${this.paging.order_by}`);
    }

    if (this.statusPayment) {
      query = query.concat(`&statusPayment=${this.statusPayment}`);
    }

    query = query.concat(`&isCancel=${this.isCheckboxCancel}`);

    let url = `${ApiConstant.GetDataExportExcelRequestGroup}${query}`;

    const options = new RequestOptions({
      headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      responseType: ResponseContentType.Blob,
    });

    this.httpDownload.get(url, options).subscribe(res => {
        const fileName = "ds-dang-ky-the-cu-dan-ve-o.xlsx";
        saveFile(res.blob(), fileName);
        this.isProcessingExport = false;
    });
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
}
