import { resCarCard, resResidentRequest } from '../../../../data/Model';
import { domain, domainImage, statusCardRequestGroup, typeVehicle, ApiConstant, CardType } from './../../../../data/const';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-car-card',
  templateUrl: './car-card.component.html',
  styleUrls: ['./car-card.component.scss']
})
export class CarCardComponent implements OnInit {
  @Output() itemCarCard: EventEmitter<any> = new EventEmitter();
  @Input() apartmentId: number;
  @Input() projectId: number;
  @Input() lstResidentRequest: resResidentRequest[];
  @Input() itemDetail: resCarCard;
  @Input() oldProcessStatus: number;
  @Input() processStatus: statusCardRequestGroup;
  @ViewChild('file') file: ElementRef;
  @Input() lstCard: any[];

  public lstColors = [];
  public lstResident = [];
  public lstVerhicle = [];
  public domainImage = domainImage;
  public lstCardNumbers = [];
  public lstCardSelected = [];
  public item: resCarCard;
  public cardType = CardType;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private gallery: Gallery
  ) {
    this.item = new resCarCard();
    this.item.files = [];
    this.item.vehicleTypeId = null;
   }

  ngOnInit() {
    this.onCheckAddResident();
    this.getListResident();
    this.getLstVehicle();

    if (this.itemDetail) {
      console.log(this.itemDetail);
      this.item.files = this.itemDetail.files;

      let isExistCard = this.lstCard.find(s => s.cardId === this.itemDetail.cardId && this.itemDetail.cardId !== 0 );

      if (!isExistCard) {
        this.lstCard.unshift({
          cardId: this.itemDetail.cardId,
          cardNumber: this.itemDetail.cardName,
          cardCode: this.itemDetail.cardName,
          cardType: this.itemDetail.vehicleTypeId == 1 ? 3 : this.itemDetail.vehicleTypeId == 2 ? 4 : this.itemDetail.vehicleTypeId == 3 ? 5 : this.itemDetail.vehicleTypeId == 5 ? 2 : 1,
          disabled: true
        });
      }
    }
  }

  onSubmit(){
    if (this.item.residentId == 0 && this.item.residentIdTemporary == null) {
      this.toastWarning("Vui lòng chọn chủ thẻ");
      return;
    }
    else if (!this.item.vehicleOwner) {
      this.toastWarning("Vui lòng nhập chủ phương tiện");
      return;
    }
    else if (this.item.vehicleTypeId >= 3 && !this.item.vehicleNumerPlate) {
      this.toastWarning("Vui lòng nhập biển kiểm soát");
      return;
    }
    else if (this.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE &&
      (!this.item.cardId || this.item.cardId == 0)) {
        this.toastWarning("Vui lòng chọn thẻ tích hợp");
        return;
    }

    if ((!this.itemDetail && this.item.vehicleTypeId >= 3) || (this.itemDetail && this.itemDetail.vehicleNumerPlate !== this.item.vehicleNumerPlate)) {
      this.http.get(ApiConstant.CheckExistNumberPlate + this.projectId + '&verhicleNumberPlate=' + this.item.vehicleNumerPlate).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 400) {
            this.toastWarning(res["meta"]["error_message"]);
            return;
          } else {
            if (this.item.cardId > 0) {
              this.item.cardName = this.lstCard.find(s => s.cardId == this.item.cardId).cardNumber;
            }

            this.itemCarCard.emit(this.item);
            this.modal.close();
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    } else {
      if (this.item.cardId > 0) {
        this.item.cardName = this.lstCard.find(s => s.cardId == this.item.cardId).cardNumber;
      }

      this.itemCarCard.emit(this.item);
      this.modal.close();
    }
  }

  onCheckboxChanged(value){

  }

  // getLstCard(){
  //   this.http.get(domain + 'api/cms/Card/getByPage?page=1&page_size=30&query=CardStatus=0').subscribe(
  //     (res) => {
  //       if (res["meta"]["error_code"] == 200) {
  //         this.lstCard =res["data"];
  //       }
  //     },
  //     (err) => {
  //       console.log("Error: connect to API");
  //     });
  // }

  getCardByVerhicle(cardId, cardName, cardType) {
    const cardIdOld = this.item.cardId;
    if (this.item.vehicleTypeId > 0) {
      this.lstCardSelected = [];
      this.item.cardId = null;

      switch (this.item.vehicleTypeId) {
        case 1: // Xe đạp
          // Xe đạp
          this.lstCardSelected = this.lstCard.filter(s => s['cardType'] == 3);
          break;
        case 2: // Xe đạp điện
          // Xe đạp điện
          this.lstCardSelected = this.lstCard.filter(s => s['cardType'] == 4);
          break;
        case 3: // Xe máy điện
          // Xe máy điện
          this.lstCardSelected = this.lstCard.filter(s => s['cardType'] == 5);
          break;
        case 5: // Ô tô
          // Ô tô
          this.lstCardSelected = this.lstCard.filter(s => s['cardType'] == 2);
          break;
        case 6: // Ô tô điện
          // Ô tô điện
          this.lstCardSelected = this.lstCard.filter(s => s['cardType'] == 6);
          break;
      }

      if (this.itemDetail && cardId) {
        const isExist = this.lstCardSelected.find(s => s.cardId == cardId);

        if (!isExist) {
          this.lstCardSelected.unshift({
            cardCode: cardName,
            cardId: cardId,
            cardNo: "0012772114",
            cardNumber: cardName,
            cardStatus: 0,
            cardType: this.itemDetail.vehicleTypeId == 1 ? 3 : this.itemDetail.vehicleTypeId == 2 ? 4 : this.itemDetail.vehicleTypeId == 3 ? 5 : this.itemDetail.vehicleTypeId == 5 ? 2 : 1,

          })
        }

        this.item.cardId = cardIdOld;
      }
    }
  }

  onCheckAddResident () {
    if (this.lstResidentRequest.length) {
      for(let i = 0; i < this.lstResidentRequest.length ; i ++){
        var itemResidentRequest = this.lstResidentRequest[i];

        if (itemResidentRequest.residentIdTemporary) {
          this.lstResident.push({
            ResidentId: 0,
            ResidentIdTemporary: itemResidentRequest.residentIdTemporary,
            ResidentName: itemResidentRequest.fullName,
            Birthday: itemResidentRequest.birthday,
            Gender: itemResidentRequest.gender,
            Phone: itemResidentRequest.phone,
            IdentifyType: itemResidentRequest.identifyType,
            IdentifyCode: itemResidentRequest.identifyCode,
            IdentifyCreate: itemResidentRequest.identifyCreate,
            IdentifyLoc: itemResidentRequest.identifyLoc,
            RelationshipId: itemResidentRequest.relationshipId,
            RelationshipName: itemResidentRequest.relationshipName
          });
        }
      }
    }
  }

  onChangeResident(value){
    if (value) {
      this.item.residentIdTemporary = value["ResidentIdTemporary"] || null;
      this.item.residentId = value["ResidentId"];
      this.item.residentName = value["ResidentName"];
    }
  }

  getLstVehicle(){
    this.http.get(domain + 'api/Reg/vehicleType?type=' + typeVehicle.CAR).subscribe(
      (res) => {
        if (res["meta"]["error_code"] < 300) {
          this.lstVerhicle = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  getListResident() {
    this.http.get(domain + 'api/cms/Resident/getResident/' + this.apartmentId + '/1/100').subscribe(
      (res) => {
        if (res["meta"]["error_code"] < 300) {
          this.lstResident = this.lstResident.concat(res["data"]["Results"]);

          if (this.itemDetail) {
            this.item.residentIdTemporary = this.itemDetail["residentIdTemporary"];
            this.item.cardId = this.itemDetail["cardId"];
            this.item.cardName = this.itemDetail["cardName"];
            this.item.cardRequestId = this.itemDetail["cardRequestId"];
            this.item.createdById = this.itemDetail["createdById"];
            this.item.files = this.itemDetail["files"];
            this.item.residentCardId = this.itemDetail["residentCardId"];
            this.item.residentCardName = this.itemDetail["residentCardName"];
            this.item.residentId = this.itemDetail["residentId"];
            this.item.residentName = this.itemDetail["residentName"];
            this.item.vehicleBrand = this.itemDetail["vehicleBrand"];
            this.item.vehicleColor = this.itemDetail["vehicleColor"];
            this.item.vehicleDes = this.itemDetail["vehicleDes"];
            this.item.vehicleName = this.itemDetail["vehicleName"];
            this.item.vehicleNumerPlate = this.itemDetail["vehicleNumerPlate"];
            this.item.vehicleOwner = this.itemDetail["vehicleOwner"];
            this.item.vehicleTypeId = this.itemDetail["vehicleTypeId"];

            if (!this.lstResident.find(s => s.residentId === this.itemDetail.residentId) &&
            !this.itemDetail["residentIdTemporary"]) {
              this.lstResident.unshift({
                ResidentId: this.itemDetail.residentId,
                ResidentIdTemporary: null,
                ResidentName: this.itemDetail.residentName
              });
            }

            this.getCardByVerhicle(this.item.cardId, this.item.cardName, this.item.vehicleTypeId);
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  upload(files){
    if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.files.unshift({
              id: 0,
              fileId: 0,
              fileRef: '',
              url: element.toString()
            });
          });
        }
				if (this.file) this.file.nativeElement.value = "";
			}
		});
  }

  omit_special_char(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
    lstImage.push({path: domainImage + item.url})
  })

  let prop = {
      images: lstImage,
      index
  };
  this.gallery.load(prop);
}

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
  }
}
