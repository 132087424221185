import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ServiceOrder, ServiceRate } from '../../../../../data/Model';
import { domainApi, domain, listAcceptanceStatus, typeServiceRate } from '../../../../../data/const';

@Component({
  selector: 'app-service-tech-acceptance',
  templateUrl: './service-tech-acceptance.component.html',
  styleUrls: ['./service-tech-acceptance.component.scss']
})
export class ServiceTechAcceptanceComponent implements OnInit {
  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  @Input() typeUser: any;

  public Item: ServiceOrder;
  public ItemRate: ServiceRate;
  public listAcceptanceStatus = listAcceptanceStatus;
  public typeRates = [];
  public typeRateItems = [];
  public listItemPrices = [];
  public httpOptions: any;
  public isLoading = false;
  public dateNow = new Date();
  public minDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };

  constructor(
    public modal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
  ) {
    this.Item = new ServiceOrder();
    this.ItemRate = new ServiceRate();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
  }

  ngOnInit() {
    //console.log(this.itemDetail);
    if (this.itemDetail) {
      this.Item = this.itemDetail;
      this.Item.IsAcceptanceOld = this.Item.IsAcceptance;
      this.Item.DateAcceptance = this.Item.DateAcceptance != undefined ? this.Item.DateAcceptance : new Date();
      this.Item.TimeAcceptance = this.Item.DateAcceptance;
      this.Item.AcceptanceStatus = this.Item.IsAcceptance == true ? 1 : 0;
      this.Item.NoteAcceptance = this.Item.NoteAcceptance;
      // this.listItemPrices = this.itemDetail["serviceOrderPrices"];
      // if(this.listItemPrices.length <=0){
      //   this.listItemPrices = this.itemDetail["serviceOrderItems"];
      // }
      //console.log(this.Item);
      this.GetListTypeRate();
      
    }
    else {
      this.Item.DateAcceptance = new Date();
    }
  }

  GetListTypeRate() {
    this.http.get(domainApi + '/typeAttributeItem/codeAttribute/LTCDG', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.typeRates = res["data"];
          this.ItemRate.RateAttributeId = this.typeRates[0].TypeAttributeItemId;
          this.GetListTypeRateItem(this.typeRates[0].TypeAttributeItemId, this.Item.ProjectId, this.Item.TowerId);
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  GetListTypeRateItem(typeRateId, projectId, towerId) {
    let query = "TypeRateId=" + typeRateId + "AND ProjectId=" + projectId + "AND TowerId=" + towerId;
    this.http.get(domainApi + '/rateType/GetByPage?page=1&page_size=100&query=' + query + '&order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.typeRateItems = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  onSubmit() {
    if (this.Item.AcceptanceStatus == undefined) {
      this.toastWarning("Vui lòng chọn trạng thái nghiệm thu!");
      return;
    }
    this.Item.IsAcceptance = this.Item.AcceptanceStatus == 1 ? true : false;
    this.itemCommon.emit(this.Item);
    this.modal.close();
  }

  onClose() {
    this.Item.IsAcceptance =  this.Item.IsAcceptanceOld;
    this.modal.close();
  }

  onRate() {
    if (this.typeRateItems.length < 0) {
      this.toastWarning("Vui lòng chọn tiêu chí đánh giá!");
      return;
    }
    //debugger
    this.ItemRate.ProjectId = this.Item.ProjectId;
    this.ItemRate.ServiceOrderId = this.Item.Id;
    this.ItemRate.ResidentId = this.Item.ResidentId;
    this.ItemRate.Type = typeServiceRate.SERVICE_TECH;
    this.ItemRate.LisRateTypeId ="";
    let listRateTypeId="";
    for(let i=0; i <this.typeRateItems.length; i++){
        if(this.typeRateItems[i].Check){
          listRateTypeId+=this.typeRateItems[i].Id+"-";
        }
    }
    this.ItemRate.LisRateTypeId =listRateTypeId.substring(0, listRateTypeId.length -1);
    for(let i=0; i <this.typeRates.length; i++){
      if(this.typeRates[i].TypeAttributeItemId == this.ItemRate.RateAttributeId){
        this.ItemRate.Contents =this.typeRates[i].Name;
        break;
      }
    }
    let data = Object.assign({}, this.ItemRate);
    data.createdById = parseInt(localStorage.getItem("userId"));
    this.http.post(domain + 'api/app/serviceRate', data, this.httpOptions).subscribe(
      (res) => {
        this.isLoading = false;
        if (res["meta"]["error_code"] == 200) {
          this.toastSuccess("Đánh giá thành công!");
          this.Item.IsRate = true;
          this.Item.RateAttributeId = this.ItemRate.RateAttributeId;
        }
        else {
          this.toastError(res["meta"]["error_message"]);
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
      }
    );
  }



  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }

  onChangeStatus() {
    this.Item.IsAcceptance = this.Item.AcceptanceStatus == 1 ? true : false;
  }

  onChangeRate() {
    this.GetListTypeRateItem(this.ItemRate.RateAttributeId, this.Item.ProjectId, this.Item.TowerId);
  }

}
