import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ApiConstant, domain, domainApi, domainFile, domainImage, listPaymentStatus, listStatusServiceTech, TypeAttribute_Relationship } from '../../../../data/const';
import { QueryFilter } from '../../../../data/dt';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Location } from '@angular/common';
import { DateTimeAdapter, OwlDateTimeComponent, OwlDateTimeFormats, OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { CashUtility, CashUtilityItem, ServiceOrder, ServiceRate } from '../../../../data/Model';
import { ServiceTechItemComponent } from '../dialogs/service-tech-item/service-tech-item.component';
import { ServiceTechEmpComponent } from '../dialogs/service-tech-emp/service-tech-emp.component';
import { ServiceTechPriceComponent } from '../dialogs/service-tech-price/service-tech-price.component';
import { ServiceTechPlanComponent } from '../dialogs/service-tech-plan/service-tech-plan.component';
import { ServiceTechProductComponent } from '../dialogs/service-tech-product/service-tech-product.component';
import { CommonService } from './../../../../service/common.service';
import { ServiceTechAcceptanceComponent } from '../dialogs/service-tech-acceptance/service-tech-acceptance.component';
import { ServiceTechHistoryComponent } from '../dialogs/service-tech-history/service-tech-history.component';
import { ServiceTechServiceComponent } from '../dialogs/service-tech-service/service-tech-service.component';
import { ServiceTechNotificationComponent } from '../dialogs/service-tech-notification/service-tech-notification.component';
import { ServiceTechCancelComponent } from '../dialogs/service-tech-cancel/service-tech-cancel.component';
import { e } from '@angular/core/src/render3';


export const MY_CUSTOM_FORMATS: OwlDateTimeFormats = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  cancelBtnLabel = 'Hủy';

  /** A label for the set button */
  setBtnLabel = 'Đặt';
};

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-new-service-tech',
  templateUrl: './new-service-tech.component.html',
  styleUrls: ['./new-service-tech.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl }
  ]
})
export class NewServiceTechComponent implements OnInit {

  @ViewChild('file') file: ElementRef;

  public projects = [];
	public towers = [];
	public floors = [];
	public apartments = [];

  public Item: ServiceOrder;
  public cash: CashUtility;
  public cashItem: CashUtilityItem;
  public ItemRate: ServiceRate;
  public isCreatePage: boolean;
  public isShowResident: boolean;
  public statusProcessOldId: number;
  public typeUser: any;

  public listEmployees = [];
  public relationships = [];
  public listPositions = [];
  public listMembers = [];
  public listCashItem = [];
	public listStatusProcess = listStatusServiceTech;
	public listPaymentStatus = listPaymentStatus;
  public domain = domain;
  public domainImage = domainImage;
  public domainFile = domainFile;
  public listItem = [];
  public listEmp = [];
	public listPlan = [];
	public listProduct = [];
	public listPrice = [];
  public typeRates = [];
  public typeRateItems = [];

  public httpOptions: any;

  public checkList = false;
  public isLoading = false;
  public statusItem: number;
  // public minDate: object;
  public maxDateFrom: object;
  public maxDateTo: object;
  public dateNow = new Date();
  public currentDate = {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth() + 1,
      day: this.dateNow.getDate()
  };
  public minDateFrom = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };
  public minDateTo = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };
  public minDateSurvey = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };
  public minDateStart = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };
  //
  public isWarningProjectId = false;
  public isWarningTower = false;
  public isWarningFloor = false;
  public isWarningApartment = false;
  public isWarningFullName = false;
  public isWarningPhone = false;
  public isWarningDateActive = false;
  public isWarningTimeActive = false;
  public isWarningDateActiveTo = false;
  public isWarningTimeActiveTo = false;
  public isWarningDateSurvey = false;
  public isWarningTimeSurvey = false;
  public isWarningDateStart = false;
  public isWarningTimeStart = false;
  public isWarningPriceDepositPaid = false;
  public isWarningStatusProcessId = false;
  

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal,
    private readonly commonService: CommonService,
    public router: Router,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
    private gallery: Gallery
  ) {
    this.Item = new ServiceOrder();
    this.ItemRate = new ServiceRate();
    this.Item.files = [];
    this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
  }


  ngOnInit() {
    this.statusItem = 0;
    this.typeUser= localStorage.getItem("type");
    const id = this.route.snapshot.paramMap.get('id');
    const statusProcessId = this.route.snapshot.paramMap.get('statusProcessId');
    //debugger;
    if(statusProcessId=="0"){
      this.http.get(domainApi + '/serviceOrder/' + id, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.Item = res["data"];
            //this.statusProcessOldId = this.Item.StatusProcessId;
            let urlRedirct = '/manager-service/service-tech/update/'+ this.Item.Id + '/'+this.Item.StatusProcessId;
            this.redirectTo(urlRedirct);
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }
    else if (id) {
      this.checkList = true;
      this.isCreatePage = false;
      this.onGetDetail(id);
      this.GetListTypeRate();
      this.statusItem = parseInt(statusProcessId);
      //console.log(this.statusItem);
      this.listStatusProcess.map((item, index, arr) => {
        this.statusItem > item.Id ? item["disabled"] = true : item["disabled"] = false;
        return item;
      });
    } else {
      this.isCreatePage = true;
      this.Item.StatusProcessId = 1;
      this.Item.StatusProcessIdOld = 1;
      this.Item.PriceFake=0;
      this.Item.PriceTotal=0;
      this.Item.CreatedAt= new Date();
      // this.Item.NoteCash = " Ghi chú: \n"
      // +"- Đơn giá chưa bao gồm thuế VAT  \n"
      // +"- Đơn giá có hiệu lực 7 ngày từ ngày ban hành  \n";
      this.GetListProject();
      //this.GetListEmployee();
		  this.GetListPosition();
      
    }
		this.GetListRelationship();
   
  }

//Danh sách dự án
GetListProject() {
  this.projects = [];
    this.towers = [];
    this.floors = [];
    this.apartments = [];
    this.Item.ProjectId = null;
    this.Item.TowerId = null;
    this.Item.FloorId = null;
    this.Item.ApartmentId = null;
    this.Item.ResidentName = null;
    this.Item.ResidentPhone = null;
  this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.projects = res["data"];
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
}

//Danh sách tòa nhà
GetListTower(OpenUpdate) {
  this.towers = [];
    this.floors = [];
    this.apartments = [];
  if (!OpenUpdate){
    this.Item.TowerId = null;
    this.Item.FloorId = null;
    this.Item.ApartmentId = null;
    this.Item.ResidentName = null;
    this.Item.ResidentPhone = null;
    this.isShowResident = false;
  }
  if (this.Item.ProjectId) {
    this.GetListEmployee();
    let query = "ProjectId=" + this.Item.ProjectId;
    this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.towers = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  this.GetListFloor(OpenUpdate);
}

//Danh sách tầng
GetListFloor(OpenUpdate) {
  this.floors = [];
  this.apartments = [];
  if (!OpenUpdate){
    this.Item.FloorId = null;
    this.Item.ApartmentId = null;
    this.Item.ResidentName = null;
    this.Item.ResidentPhone = null;
    this.isShowResident = false;
  }
  if (this.Item.TowerId) {
    let query = "TowerId=" + this.Item.TowerId;
    this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.floors = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  this.GetListApartment(OpenUpdate);
}

//Danh sách căn hộ
GetListApartment(OpenUpdate) {
  this.apartments = [];
  if (!OpenUpdate){
    this.Item.ApartmentId = null;
    this.Item.ResidentName = null;
    this.Item.ResidentPhone = null;
    this.isShowResident = false;
  } 
  if (this.Item.FloorId) {
    let query = "FloorId=" + this.Item.FloorId;
    this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          //this.apartments = res["data"];
          if (this.isCreatePage) {
            for (let i = 0; i < res["data"].length; i++) {
              this.commonService.getPurchaserByApartmentId(res["data"][i]['ApartmentId']).subscribe(
                (lstRs) => {
                  if (lstRs) {
                    this.apartments.push(res["data"][i]);
                    this.apartments = [...this.apartments];
                  }
                },
                (err) => {
                  this.toastError(err['meta']['error_message']);
                });
            }
          } else {
            this.apartments = res["data"];
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

}

GetListMemberApartment(OpenUpdate) {
  //this.apartments = [];
  if (!OpenUpdate) this.Item.IdContact = undefined;
  if (this.Item.ApartmentId) {
    //let query = "FloorId=" + this.Item.FloorId;
    this.http.get(domainApi + '/resident/GetMemberApartmentAdmin/'+this.Item.ApartmentId+"/"+this.Item.ResidentId+"/2"+"?page=1&query=1=1&order_by=", this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listMembers = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

}

ChooseApartment(OpenUpdate) {
  console.log(this.Item.IdContact);
  if(this.Item.IdContact){
  this.modalDialogService.openDialog(this.viewRef, {
    title: 'Xác nhận',
    childComponent: SimpleModalComponent,
    data: {
      text: "Dữ liệu đăng ký bên dưới sẽ bị mất. Bạn có muốn thay đổi không?"
    },
    actionButtons: [
      {
        text: 'Xác nhận',
        buttonClass: 'btn btn-success',
        onAction: () => {
          if (!OpenUpdate){
            this.Item.ResidentName = undefined;
            this.Item.ResidentPhone = undefined;
            this.Item.RelationshipId = undefined;
          }
          if (this.Item.ApartmentId) {
            
            this.http.get(domainApi + '/resident/GetMainApartmentAdmin/' + this.Item.ApartmentId, this.httpOptions).subscribe(
              (res) => {
                if (res["meta"]["error_code"] == 200) {
                  //this.apartments = res["data"];
                  this.Item.ResidentId = res["data"]["ResidentId"];
                  this.Item.ResidentName = res["data"]["FullName"];
                  this.Item.ResidentPhone = res["data"]["Phone"];
                  this.GetListMemberApartment(OpenUpdate);
                }
              },
              (err) => {
                console.log("Error: connect to API");
              });
          }
          this.viewRef.clear();
        }
      },
      {
        text: 'Hủy',
        buttonClass: 'btn btn-default',
      }
    ],
  });
  }
  else{
    if (!OpenUpdate){
      this.Item.ResidentName = undefined;
      this.Item.ResidentPhone = undefined;
    }
    if (this.Item.ApartmentId) {
      
      this.http.get(domainApi + '/resident/GetMainApartmentAdmin/' + this.Item.ApartmentId, this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            //this.apartments = res["data"];
            this.Item.ResidentId = res["data"]["ResidentId"];
            this.Item.ResidentName = res["data"]["FullName"];
            this.Item.ResidentPhone = res["data"]["Phone"];
            this.GetListMemberApartment(OpenUpdate);
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }
  }
  
}

ChooseMember(OpenUpdate) {
  if (!OpenUpdate){
    this.Item.RelationshipId = undefined;
    this.Item.NameContact = undefined;
    this.Item.PhoneContact = undefined;
  }
  if (this.Item.IdContact) {
    for(var i=0;i<this.listMembers.length;i++){
      if(this.listMembers[i].ResidentId == this.Item.IdContact){
        if(this.listMembers[i].RelationshipId == undefined){
          if(this.Item.IdContact== this.Item.ResidentId)
            this.Item.RelationshipId = -1;
          else
            this.Item.RelationshipId = 204;
        }
        else
        this.Item.RelationshipId = this.listMembers[i].RelationshipId;
        
        this.Item.NameContact = this.listMembers[i].FullName;
        this.Item.PhoneContact = this.listMembers[i].Phone;
        break;
      }
    }
    
  }
}

onGetDetail(id) {

  this.http.get(domainApi + '/serviceOrder/' + id, this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.Item = res["data"];
        this.statusProcessOldId = this.Item.StatusProcessId;
        // this.statusItem = this.Item.StatusProcessId;
        // console.log(this.statusItem);
        // this.listStatusProcess.map((item, index, arr) => {
        //   this.statusItem > item.Id ? item["disabled"] = true : item["disabled"] = false;
        //   return item;
        // });
        // console.log(this.listStatusProcess);
        this.setDataDetail(this.Item);
        // this.Item.ResidentId = res["data"]["ResidentId"];
        // this.Item.ResidentName = res["data"]["FullName"];
        // this.Item.ResidentPhone = res["data"]["Phone"];
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
  
}

setDataDetail(item) {
  //debugger
  this.projects = [];
  this.towers = [];
  this.floors = [];
  this.apartments = [];
  this.listItem = item.serviceOrderItems;
  this.listEmp = item.listEmps;
  this.listPlan = item.listPlans;
  this.listPrice = item.serviceOrderPrices;
  this.listProduct = item.listItemProducts;
  this.Item.ProjectId = item.ProjectId || null;
  this.Item.TowerId = item.TowerId || null;
  this.Item.FloorId = item.FloorId || null;
  this.Item.ApartmentId = item.ApartmentId || null;
  this.Item.TimeActive = item.DateActive || null;
  this.Item.TimeActiveTo = item.DateActiveTo || null;
  this.Item.TimeCustomer = item.DateCustomer || null;
  this.Item.TimeSurvey = item.DateSurvey || null;
  this.Item.TimeStart = item.DateStart || null;
  this.Item.PriceDepositPaid = item.PriceDeposit || null;
  this.Item.StatusProcessIdOld = item.StatusProcessId || null;
  this.Item.IsStartOld = item.IsStart || null;
  this.Item.IsFeeOld = item.IsFee || null;
  this.Item.IsPayDepositOld = item.IsPayDeposit || null;
  this.Item.NoteCash = item.NoteCash;
  this.Item.PaymentMethod1 = false;
  this.Item.PaymentMethod2 = false;
  if(this.Item.PaymentMethod == 2)
    this.Item.PaymentMethod1= true;
  else if(this.Item.PaymentMethod == 1)
    this.Item.PaymentMethod2= true;

  this.GetDetailRate(this.Item.Id);
  
  //this.Item.makerId = item.makerId || null
  //debugger
  //Lấy thông tin nhân viên thực hiện
  this.http.get(domainApi + '/userRole/GetEmpByRoleAllQuery/'+this.Item.ProjectId, this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.listEmployees = res["data"];
        this.http.get(domainApi + '/position/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
          (res) => {
            if (res["meta"]["error_code"] == 200) {
              this.listPositions = res["data"];
              for (let k = 0; k < this.listEmp.length; k++) {
                for (let i = 0; i < this.listEmployees.length; i++) {
                  if (this.listEmp[k].EmployeeId == this.listEmployees[i].EmployeeId) {
                    this.listEmp[k].EmployeeName = this.listEmployees[i].FullName;
                    this.listEmp[k].EmployeeCode = this.listEmployees[i].Code;
                    this.listEmp[k].PositionId = this.listEmployees[i].PositionId;
                    if (this.listEmp[k].PositionId != undefined) {
                      for (let j = 0; j < this.listPositions.length; j++) {
                        if (this.listEmp[k].PositionId == this.listPositions[j].Id) {
                          this.listEmp[k].PositionName = this.listPositions[j].Name;
                          break;
                        }
                      }
                    }
                    break;
                  }
                }
              }

            }
          },
          (err) => {
            console.log("Error: connect to API");
          });
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
  
  //Lấy ds tòa, tầng, căn hộ, cư dân
  this.commonService.getLstProject().subscribe(
    (res) => {
      this.projects = res;
      // this.item.projectId = item["projectId"];

      this.commonService.getLstTower(this.Item.ProjectId).subscribe((res) => {
        this.towers = res;
        // this.item.towerId = item["towerId"];

        this.commonService.getLstFloor(this.Item.TowerId).subscribe((res) => {
          this.floors = res;
          // this.item.floorId = item["floorId"];

          this.commonService.getLstApartment(this.Item.FloorId).subscribe((res) => {
            this.apartments = res;

            // this.apartmentIdChanged = item["apartmentId"];
            // this.item.apartmentId = item["apartmentId"];
            // this.item.apartmentName = item["apartmentName"];
            // // this.item.residentId = item["residentId"];
            // // this.item.residentName = item["residentName"];
            this.commonService.getPurchaserByApartmentId(item.ApartmentId).subscribe(
              (res) => {
                if (res) {
                  this.Item.ResidentId = res.ResidentId;
                  this.Item.ResidentName = res.FullName;
                  this.Item.ResidentPhone = this.Item.ResidentPhone ? this.Item.ResidentPhone : res.Phone;
                  this.isShowResident = true;
                  if (this.Item.ApartmentId) {
                    //let query = "FloorId=" + this.Item.FloorId;
                    this.http.get(domainApi + '/resident/GetMemberApartmentAdmin/'+this.Item.ApartmentId+"/"+this.Item.ResidentId+"/2"+"?page=1&query=1=1&order_by=", this.httpOptions).subscribe(
                      (res) => {
                        if (res["meta"]["error_code"] == 200) {
                          this.listMembers = res["data"];
                          
                        }
                      },
                      (err) => {
                        console.log("Error: connect to API");
                      });
                  }
                  // if (this.item.typeTranspost == transpostType.MoveIn) {
                    // this.http.get(domainApi + '/Resident/getResident/' + this.Item.ApartmentId + '/1/100').subscribe(
                    //   (res) => {
                    //     if (res["meta"]["error_code"] < 300) {
                    //       this.lstResident = res["data"]["Results"];
                    //     } else {
                    //       this.lstResident = []
                    //       this.toastError("Căn hộ này chưa có thông tin thành viên. Vui lòng kiểm tra lại!");
                    //     }
                    //   }, (err) => {
                    //     this.lstResident = []
                    //     this.toastError("Căn hộ này chưa có thông tin thành viên. Vui lòng kiểm tra lại!");
                    //   }
                    // )
                  // }
                } else {
                  this.isShowResident = false;
                  this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                  return;
                }
              },
              (err) => {
                this.isShowResident = false;
                this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                return;
              }
            )
          })
        })
      })
    },
    (err) => {
      //this.isShowResident = false;
      console.log("Error: connect to API");
    }
  )
}

//upload ảnh hiển thị
upload(files) {
  if (files.length === 0)
      return;

  const formData = new FormData();

  for (let file of files)
      formData.append(file.name, file);

  const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
      reportProgress: true,
  });

  this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

      }
      else if (event.type === HttpEventType.Response) {
          if (event.body["data"].length) {
              event.body["data"].forEach(element => {
                  this.Item.files.unshift({
                      FileId: 0,
                      FileRef: '',
                      Url: element.toString()
                  });
              });
          }
          if (this.file) this.file.nativeElement.value = "";
      }
  });
}

onRemoveImage(item) {
  this.Item.files = [...this.Item.files.filter(s => s != item)];
}

onShowGallery(index: number) {
  let lstImage = [];

  this.Item.files.forEach(item => {
      lstImage.push({ path: domainImage + item.Url })
  })

  let prop = {
      images: lstImage,
      index
  };
  this.gallery.load(prop);
}

//Danh sách các mối quan hệ với chủ hộ
GetListRelationship() {
  let query = "TypeAttributeId=" + TypeAttribute_Relationship;
  this.http.get(domainApi + '/typeAttributeItem/codeAttribute/relationship', this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        //debugger
        this.relationships.push({"Id": -1, "TypeAttributeItemId":-1, "Name": "Là chủ hộ", "Code": "CHUHO", "Image": "", "Location": 0, "Status": 1});
        let relationships = this.relationships.concat(res["data"]);;
        this.relationships= relationships;
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
}

GetListEmployee() {

  this.http.get(domainApi + '/userRole/GetEmpByRoleQuery/'+this.Item.ProjectId, this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.listEmployees = res["data"];
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
}

GetListPosition() {

  this.http.get(domainApi + '/position/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.listPositions = res["data"];
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
}

GetListTypeRate() {
  this.http.get(domainApi + '/typeAttributeItem/codeAttribute/LTCDG', this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.typeRates = res["data"];
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
}

GetListTypeRateItem(typeRateId, projectId, towerId) {
  let query="TypeRateId="+typeRateId+"AND ProjectId="+projectId+"AND TowerId="+towerId;
  this.http.get(domainApi + '/rateType/GetByPage?page=1&page_size=100&query=' + query + '&order_by=', this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.typeRateItems = res["data"];
        let listRates= this.ItemRate.LisRateTypeId.split('-');
        for(let i=0; i <this.typeRateItems.length; i++){
          for(let j=0; j<listRates.length; j++){
            if(this.typeRateItems[i].Id == listRates[j]){
              this.typeRateItems[i].Check=true;
              break;
            }
          }
        }
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
}

GetDetailRate(sid) {
  this.http.get(domain + 'api/app/serviceRate/'+sid+'/1', this.httpOptions).subscribe(
    (res) => {
      if (res["meta"]["error_code"] == 200) {
        this.ItemRate = res["data"];
        if(this.ItemRate.RateAttributeId){
          this.GetListTypeRateItem(this.ItemRate.RateAttributeId, this.Item.ProjectId, this.Item.TowerId);
        }
      }
    },
    (err) => {
      console.log("Error: connect to API");
    });
}


returnPage() {
  this.location.back();
}

toastWarning(msg): void {
  this.toastr.warning(msg, 'Cảnh báo');
}
//Toast thành công
toastSuccess(msg): void {
  this.toastr.success(msg, 'Hoàn thành');
}
//Toast thành công
toastError(msg): void {
  this.toastr.error(msg, 'Lỗi');
}

OpenSelectServiceModal(item) {
  if (!this.Item.ProjectId) {
    this.toastWarning("Vui lòng chọn khu đô thị");
    return;
  } else if (!this.Item.TowerId) {
    this.toastWarning("Vui lòng chọn tòa nhà");
    return;
  }
    const dialofRef = this.modalService.open(ServiceTechItemComponent, { size: 'lg', backdrop: 'static' });
    dialofRef.componentInstance.listServices = this.listItem;
    dialofRef.componentInstance.projectId = this.Item.ProjectId;
    dialofRef.componentInstance.towerId = this.Item.TowerId;
    dialofRef.componentInstance.checkSend.subscribe((res) => {
      console.log(res);
      this.checkList=res;
      if (res) {
        dialofRef.componentInstance.itemCommon.subscribe((res) => {
          if (res.length > 0) {
            this.listItem = res;
            this.Item.PriceFake=0;
            this.listItem.forEach(e=> {
              this.Item.PriceFake += e.TotalPrice;
            });
            this.Item.PriceTotal=this.Item.PriceFake;
          }
        });
     }  
    });
    
}

OpenViewServiceModal(item) {
  //console.log('ddd')
  const dialofRef = this.modalService.open(ServiceTechServiceComponent, { size: 'lg', backdrop: 'static' });
  dialofRef.componentInstance.listServices = this.listItem;
  // dialofRef.componentInstance.itemCommon.subscribe((res) => {
  //   if (res.length > 0) {
  //     this.listItem = res;
  //   }
  // });
}

OpenNotificationServiceModal(item) {
  //console.log(item);
  const dialofRef = this.modalService.open(ServiceTechNotificationComponent, { size: 'lg', backdrop: 'static' });
  dialofRef.componentInstance.itemDetail = item;
  dialofRef.componentInstance.itemCommon.subscribe((res) => {
    console.log(res);
    if (res != undefined) {
      this.SaveData(res, 1);
    }
  });
}

OpenEmployeeModal(item, index) {
    const dialofRef = this.modalService.open(ServiceTechEmpComponent, { size: 'lg', backdrop: 'static' });
    dialofRef.componentInstance.listEmps = this.listEmp;
    dialofRef.componentInstance.listEmployees = this.listEmployees;
    dialofRef.componentInstance.listPositions = this.listPositions;
    if(item != null){
      dialofRef.componentInstance.itemDetail = item;
    }
    dialofRef.componentInstance.itemCommon.subscribe((res) => {
      if (res["EmployeeId"]) {
        if(index==null)
          this.listEmp.push(res);
        else
          this.listEmp[index] = res;
      }
    });
}

DeleteEmployee(item, index) {
  this.modalDialogService.openDialog(this.viewRef, {
    title: 'Xác nhận',
    childComponent: SimpleModalComponent,
    data: {
      text: "Bạn có chắc chắn muốn xóa mục này?"
    },
    actionButtons: [
      {
        text: 'Đồng ý',
        buttonClass: 'btn btn-success',
        onAction: () => {
          this.listEmp = this.listEmp.filter(s => s !== item);
          this.toastSuccess("Xóa thành công!");
          this.viewRef.clear();
        }
      },
      {
        text: 'Đóng',
        buttonClass: 'btn btn-default',
      }
    ],
  });

}

OpenPlanModal(item, index) {
    const dialofRef = this.modalService.open(ServiceTechPlanComponent, { size: 'lg', backdrop: 'static' });
    dialofRef.componentInstance.listPlans = this.listPlan;
    if(item != null){
      dialofRef.componentInstance.itemDetail = item;
    }
    dialofRef.componentInstance.itemCommon.subscribe((res) => {
      if (res["Name"]) {
        if(index==null)
          this.listPlan.push(res);
        else
          this.listPlan[index] = res;
      }
    });
}

DeletePlan(item, index) {
  this.modalDialogService.openDialog(this.viewRef, {
    title: 'Xác nhận',
    childComponent: SimpleModalComponent,
    data: {
      text: "Bạn có chắc chắn muốn xóa mục này?"
    },
    actionButtons: [
      {
        text: 'Đồng ý',
        buttonClass: 'btn btn-success',
        onAction: () => {
          this.listPlan = this.listPlan.filter(s => s !== item);
          this.toastSuccess("Xóa thành công!");
          this.viewRef.clear();
        }
      },
      {
        text: 'Đóng',
        buttonClass: 'btn btn-default',
      }
    ],
  });

}

OpenProductServiceModal(item, index) {
    const dialofRef = this.modalService.open(ServiceTechProductComponent, { size: 'lg', backdrop: 'static' });
    if(item != null){
      dialofRef.componentInstance.itemDetail = item;
    }
    dialofRef.componentInstance.itemCommon.subscribe((res) => {
      if (res["Contents"]) {
        if(index==null)
          this.listProduct.push(res);
        else
          this.listProduct[index] = res;
      }
    });
}

DeleteProductService(item, index) {
  this.modalDialogService.openDialog(this.viewRef, {
    title: 'Xác nhận',
    childComponent: SimpleModalComponent,
    data: {
      text: "Bạn có chắc chắn muốn xóa mục này?"
    },
    actionButtons: [
      {
        text: 'Đồng ý',
        buttonClass: 'btn btn-success',
        onAction: () => {
          this.listProduct = this.listProduct.filter(s => s !== item);
          this.toastSuccess("Xóa thành công!");
          this.viewRef.clear();
        }
      },
      {
        text: 'Đóng',
        buttonClass: 'btn btn-default',
      }
    ],
  });

}

OpenPriceServiceModal(item, index) {
    const dialofRef = this.modalService.open(ServiceTechPriceComponent, { size: 'lg', backdrop: 'static' });
    if(item != null){
      dialofRef.componentInstance.itemDetail = item;
    }
    dialofRef.componentInstance.itemCommon.subscribe((res) => {
      if (res["Contents"]) {
        if(index==null)
          this.listPrice.push(res);
        else
          this.listPrice[index] = res;
      }
      this.Item.PriceTotal=0;
      this.listPrice.forEach(item=>{
        this.Item.PriceTotal += item.TotalPrice;
      })
    });
}

DeletePriceService(item, index) {
  this.modalDialogService.openDialog(this.viewRef, {
    title: 'Xác nhận',
    childComponent: SimpleModalComponent,
    data: {
      text: "Bạn có chắc chắn muốn xóa mục này?"
    },
    actionButtons: [
      {
        text: 'Đồng ý',
        buttonClass: 'btn btn-success',
        onAction: () => {
          this.listPrice = this.listPrice.filter(s => s !== item);
          this.Item.PriceTotal=0;
          this.listPrice.forEach(item=>{
            this.Item.PriceTotal += item.TotalPrice;
          })
          this.toastSuccess("Xóa thành công!");
          this.viewRef.clear();
        }
      },
      {
        text: 'Đóng',
        buttonClass: 'btn btn-default',
      }
    ],
  });

}

OpenAcceptanceModal() {
  const dialofRef = this.modalService.open(ServiceTechAcceptanceComponent, { size: 'lg', backdrop: 'static' });
  dialofRef.componentInstance.itemDetail = this.Item;
  dialofRef.componentInstance.typeUser = this.typeUser;
  // dialofRef.componentInstance.typeRates = this.Item.;
  // dialofRef.componentInstance.typeRates = this.typeRateItems;
  dialofRef.componentInstance.itemCommon.subscribe((res) => {
    if (res != undefined) {
      this.Item = res;
      this.Item.DateAcceptance = this.dateNow;
      this.onSubmit(5);
      this.onGetDetail(this.Item.Id);
    }
  });
}

OpenHistoryModal() {
  const dialofRef = this.modalService.open(ServiceTechHistoryComponent, { size: 'lg', backdrop: 'static' });
  dialofRef.componentInstance.itemDetail = this.Item.listNotes;
  //dialofRef.componentInstance.listServices = this.listItem;
  // dialofRef.componentInstance.itemCommon.subscribe((res) => {
  //   if (res.length > 0) {
  //     this.listItem = res;
  //   }
  // });
}

OpenCancelModal() {
  const dialofRef = this.modalService.open(ServiceTechCancelComponent, { size: 'lg', backdrop: 'static' });
  dialofRef.componentInstance.itemDetail = this.Item;
  dialofRef.componentInstance.itemCommon.subscribe((res) => {
    console.log(res);
    if (res != undefined) {
      this.Item = res;
      this.onSubmit(2);
    }
  });
}

onSubmit(type) {
  this.isWarningProjectId = false;
  this.isWarningTower = false;
  this.isWarningFloor = false;
  this.isWarningApartment = false;
  this.isWarningFullName = false;
  this.isWarningPhone = false;
  this.isWarningDateActive = false;
  this.isWarningTimeActive = false;
  this.isWarningDateActiveTo = false;
  this.isWarningTimeActiveTo = false;
  this.isWarningDateSurvey = false;
  this.isWarningTimeSurvey = false;
  this.isWarningDateStart = false;
  this.isWarningTimeStart = false;
  this.isWarningPriceDepositPaid = false;
  this.isWarningStatusProcessId = false;
  //
  this.isLoading = true;
  this.Item.serviceOrderItems = this.listItem;
  this.Item.listEmps = this.listEmp;
  this.Item.listPlans = this.listPlan;
  this.Item.listItemProducts = this.listProduct;
  this.Item.serviceOrderPrices = this.listPrice;
  // if(type==3)
  //   this.Item.Status = 10;
  // else if(type==3){
  //   this.Item.IsConfirmDeposit = true;
  //   this.Item.IsPayDeposit = true;
  //   this.Item.PriceDeposit = this.Item.PriceDepositPaid;
  // }
  //console.log(this.Item);
  if (!this.Item.ProjectId) {
    this.isWarningProjectId = true;
    this.toastWarning("Vui lòng chọn khu đô thị");
    return;
  } else if (!this.Item.TowerId) {
    this.isWarningTower = true;
    this.toastWarning("Vui lòng chọn tòa nhà");
    return;
  } else if (!this.Item.FloorId) {
    this.isWarningFloor = true;
    this.toastWarning("Vui lòng chọn tầng");
    return;
  } else if (!this.Item.ApartmentId) {
    this.isWarningApartment = true;
    this.toastWarning("Vui lòng chọn căn hộ");
    return;
  } else if (!this.Item.IdContact) {
    this.isWarningFullName = true;
    this.toastWarning("Vui lòng chọn người đăng ký");
    return;
  } 
  // else if (!this.Item.RelationshipId) {
  //   this.toastWarning("Vui lòng chọn mối quan hệ với chủ hộ");
  //   return;
  // }
  else if (!this.Item.PhoneContact) {
    this.isWarningPhone = true;
    this.toastWarning("Vui lòng nhập số điện thoại");
    return;
  } 
  else if (!this.Item.DateActive) {
    this.isWarningDateActive = true;
    this.toastWarning("Vui lòng chọn ngày thực hiện từ");
    return;
  }
  else if (!this.Item.DateActiveTo) {
    this.isWarningDateActiveTo = true;
    this.toastWarning("Vui lòng chọn ngày thực hiện đến");
    return;
  }
  else if (!this.Item.TimeActive) {
    this.isWarningTimeActive = true;
    this.toastWarning("Vui lòng nhập giờ thực hiện từ");
    return;
  } else if (!this.Item.TimeActiveTo) {
    this.isWarningTimeActiveTo = true;
    this.toastWarning("Vui lòng nhập giờ thực hiện đến");
    return;
  }
  else if (!this.Item.StatusProcessId || this.Item.StatusProcessId < 0) {
    this.isWarningStatusProcessId = true;
    this.toastWarning("Vui lòng chọn trạng thái phiếu");
    return;
  } 
  else if (new Date(this.Item.TimeActiveTo) <= new Date(this.Item.TimeActive)) {
    this.isWarningTimeActive = true;
    this.isWarningTimeActiveTo = true;
    this.toastWarning("Thời gian thực hiện không hợp lệ");
    return;
  }
  else if (/(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(this.Item.PhoneContact) == false && /(84[3|5|7|8|9])+([0-9]{8})\b/g.test(this.Item.PhoneContact) == false) {
    this.isWarningPhone = true;
    this.toastWarning("Số điện thoại không hợp lệ");
    return;
  } else if (this.listItem.length <= 0) {
    this.toastWarning("Vui lòng chọn dịch vụ!");
    return;
  } 
  else if (this.Item.DateSurvey && this.Item.TimeSurvey && this.Item.DateStart && this.Item.TimeStart && new Date(this.Item.TimeStart) < new Date(this.Item.TimeSurvey)) {
    this.isWarningDateStart = true;
    this.toastWarning("Ngày thực hiện dịch vụ phải lớn hơn hoặc bằng ngày khảo sát!");
    return;
  } 
  // else if (this.Item.StatusProcessId == 9 && this.Item.PaymentStatus != 2 && this.Item.TypeService != 1) {
  //   this.toastWarning("Đơn dịch vụ chưa được thanh toán hết, không được chuyển trạng thái hoán thành!");
  //   return;
  // } 

  //Nếu chuyển sang trạng thái chờ xác nhận khảo sát thì check thêm
  if(this.Item.StatusProcessId == 3){
    if (this.listEmp.length <= 0) {
      this.toastWarning("Vui lòng nhập nhân viên thực hiện!");
      return;
    } 
    else if (!this.Item.DateSurvey) {
      this.isWarningDateSurvey = true;
      this.toastWarning("Vui lòng chọn thời gian khảo sát");
      return;
    }
    if(this.typeUser==2){
      if(this.Item.IsStart == true){
        if(this.Item.NoteExchange!=undefined && this.Item.NoteExchange!=""){
          this.Item.NoteExchange = "Đồng ý lịch khảo sát - "+this.Item.NoteExchange;
        }
      }
      else{
        this.Item.StatusProcessId = 2;
        if(this.Item.NoteExchange!=undefined && this.Item.NoteExchange!=""){
          this.Item.NoteExchange = "Không đồng ý lịch khảo sát - "+this.Item.NoteExchange;
        }
      }
    }
  }
  else if(this.Item.StatusProcessId == 5){
    if (!this.Item.IsRateBt && !this.Item.IsRateHh) {
      this.toastWarning("Vui lòng chọn đánh giá!");
      return;
    } 
    else if (!this.Item.IsSolutionSc && !this.Item.IsSolutionTt) {
      this.toastWarning("Vui lòng chọn biện pháp!");
      return;
    } 
    else if (this.listPrice.length <= 0) {
      this.toastWarning("Vui lòng nhập báo giá!");
      return;
    } 
    if(this.typeUser==2){
      if(this.Item.IsFee == true){
        if(this.Item.NoteExchange!=undefined && this.Item.NoteExchange!=""){
          this.Item.NoteExchange = "Đồng ý phí dịch vụ - "+this.Item.NoteExchange;
        }
      }
      else{
        this.Item.StatusProcessId = 4;
        if(this.Item.NoteExchange!=undefined && this.Item.NoteExchange!=""){
          this.Item.NoteExchange = "Không đồng ý phí dịch vụ - "+this.Item.NoteExchange;
        }
      }
    }
  }
  else if(this.Item.StatusProcessId ==7){
    if (!this.Item.DateStart) {
      this.isWarningDateStart = true;
      this.toastWarning("Vui lòng chọn thời gian thực hiện!");
      return;
    } 
    else if (this.Item.DateSurvey && this.Item.TimeSurvey && this.Item.DateStart && this.Item.TimeStart && new Date(this.Item.TimeStart) < new Date(this.Item.TimeSurvey)) {
      this.isWarningDateStart = true;
      this.toastWarning("Ngày thực hiện dịch vụ phải lớn hơn hoặc bằng ngày khảo sát!");
      return;
    } 
  }
  else if(this.Item.StatusProcessId == 8){
    if(this.typeUser==2){
      if(this.Item.IsAcceptance == true){
        if(this.Item.NoteExchange!=undefined && this.Item.NoteExchange!=""){
          this.Item.NoteExchange = "Đồng ý nghiệm thu dịch vụ - "+this.Item.NoteExchange;
        }
      }
      else{
        //this.Item.StatusProcessId = 2;
        if(this.Item.NoteExchange!=undefined && this.Item.NoteExchange!=""){
          this.Item.NoteExchange = "Không đồng ý nghiệm thu dịch vụ - "+this.Item.NoteExchange;
        }
      }

      for(let i=0; i <this.typeRateItems.length; i++){
        for(let j=0; j<this.Item.listRates.length; j++){
          if(this.typeRateItems[i].Id == this.Item.listRates[j].RateTypeId){
            this.typeRateItems[i].Check=true;
            break;
          }
        }
      }
    }
  }

  if(type==3){
    if(!this.Item.IsStart==undefined){
      this.toastWarning("Vui lòng chọn xác nhận Đồng ý hoặc Không đồng ý!");
      return;
    }
  }
  if(type==4){
    if(!this.Item.IsFee==undefined){
      this.toastWarning("Vui lòng chọn xác nhận Đồng ý hoặc Không đồng ý!");
      return;
    }
  }

  let data = Object.assign({}, this.Item);
  data.createdById = parseInt(localStorage.getItem("userId"));

  if (data.DateActive ) {
    let obj = new Date(data.DateActive);
    if(data.TimeActive){
      let objT = new Date(data.TimeActive);
      data.DateActive = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + objT.getHours() + ":" + objT.getMinutes() + ":0";
    }
    else{
      //data.DateActive = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() +  " 0:0:0";
      this.isWarningTimeActive = true;
      this.toastWarning("Vui lòng chọn thời gian thực hiện từ!");
      return;
    }
  }
  if (data.DateActiveTo) {
    let obj = new Date(data.DateActiveTo);
    if(data.TimeActiveTo){
      let objT = new Date(data.TimeActiveTo);
      data.DateActiveTo = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + objT.getHours() + ":" + objT.getMinutes() + ":0";
    }
    else{
      //data.DateActiveTo = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() +  " 0:0:0";
      this.isWarningTimeActiveTo = true;
      this.toastWarning("Vui lòng chọn thời gian thực hiện đến!");
      return;
    }
  }
  // if (data.DateCustomer) {
  //   let obj = new Date(data.DateCustomer);
  //   if(data.TimeCustomer){
  //     let objT = new Date(data.TimeCustomer);
  //     data.DateCustomer = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + objT.getHours() + ":" + objT.getMinutes() + ":0";
  //   }
  //   else{
  //     //data.DateCustomer = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() +  " 0:0:0";
  //     this.toastWarning("Vui lòng chọn thời gian khách mong muốn!");
  //     return;
  //   }
  // }
  if (data.DateSurvey) {
    let obj = new Date(data.DateSurvey);
    if(data.TimeSurvey){
      let objT = new Date(data.TimeSurvey);
      data.DateSurvey = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + objT.getHours() + ":" + objT.getMinutes() + ":0";
    }
    else{
      //data.DateSurvey = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() +  " 0:0:0";
      this.isWarningTimeSurvey = true;
      this.toastWarning("Vui lòng chọn thời gian khảo sát!");
      return;
    }
  }
  if (data.DateStart) {
    let obj = new Date(data.DateStart);
    if(data.TimeStart){
      let objT = new Date(data.TimeStart);
      data.DateStart = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + objT.getHours() + ":" + objT.getMinutes() + ":0";
    }
    else{
      //data.DateStart = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() +  " 0:0:0";
      this.isWarningTimeStart = true;
      this.toastWarning("Vui lòng chọn thời gian thực hiện!");
      return;
    }
  }
  if (data.DateAcceptance) {
    let obj = new Date(data.DateAcceptance);
    if(data.TimeAcceptance){
      let objT = new Date(data.TimeAcceptance);
      data.DateAcceptance = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + objT.getHours() + ":" + objT.getMinutes() + ":0";
    }
    else{
      data.DateAcceptance = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() +  " 0:0:0";
    }
  }

  if(data.Id!=undefined){
    // if(this.statusProcessOldId == data.StatusProcessId){
      this.SaveData(data, type);
    // }
    // else{
    //   this.OpenNotificationServiceModal(data);
    // }
  }
  else{
    this.SaveData(data, type);
  }
 
}

SaveData(data, type) {
  //debugger
  if (data.Id == undefined) {
    this.http.post(domainApi + '/serviceOrder', data, this.httpOptions).subscribe(
      (res) => {
        this.isLoading = false;
        if (res["meta"]["error_code"] == 200) {
          this.toastSuccess("Tạo đăng ký mới thành công");
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([`/manager-service/service-tech`]);
          });
        }
        else {
          this.toastError(res["meta"]["error_message"]);
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
      }
    );
  }
  else {
    this.http.put(domainApi + '/serviceOrder/' + data.Id, data, this.httpOptions).subscribe(
      (res) => {
        this.isLoading = false;
        if (res["meta"]["error_code"] == 200) {
          if(type==1 || type==2){
            this.toastSuccess("Cập nhật thành công!");
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([`/manager-service/service-tech`]);
            });
            
          }
          else if(type==3){
            this.Item.IsStartOld = this.Item.IsStart;
            this.onGetDetail(this.Item.Id);
          }
          else if(type==4){
            this.Item.IsFeeOld = this.Item.IsFee;
            this.onGetDetail(this.Item.Id);
          }
        }
        else {
          this.toastError(res["meta"]["error_message"]);
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
      }
    );
  }
}

onPayDeposit() {

  if (!this.Item.PriceDepositPaid) {
    this.toastWarning("Vui lòng nhập số tiền đặt cọc!");
    return;
  }

  this.cash= new CashUtility();
  this.cash.ApartmentId = this.Item.ApartmentId;
  this.cash.ResidentId = this.Item.ResidentId;
  this.cash.Price = this.Item.PriceDepositPaid;
  this.cash.TotalPay = this.Item.PriceDepositPaid;
  this.cash.UserId = parseInt(localStorage.getItem("userId"));
  this.cash.ReturnUrl = this.domain;
  this.cash.PaymentMethodId = 80;
  this.cash.BankCode = "";
  this.cash.Name = "Thanh toán tiền đặt cọc cho đơn dịch vụ kỹ thuật "+ this.Item.Code +" của căn hộ "+ this.Item.ApartmentId;
  this.cash.TargetId = this.Item.Id;
  this.cash.Type = 8;

  this.cashItem = new CashUtilityItem();
  this.cashItem.ServiceName = this.cash.Name;
  this.cashItem.Quantity = 1;
  this.cashItem.Price = this.Item.PriceDepositPaid;
  this.cashItem.TotalPrice = this.Item.PriceDepositPaid;
  this.listCashItem.push(this.cashItem);
  this.cash.cashItems = this.listCashItem;

    this.http.post(domain + 'api/app/cash/PostCashUtilityDirect', this.cash, this.httpOptions).subscribe(
      (res) => {
        this.isLoading = false;
        if (res["meta"]["error_code"] == 200) {

          this.toastSuccess("Xác nhận đặt cọc thành công");
          //this.onSubmit(3);
          this.Item.IsConfirmDeposit = true;
          this.Item.IsPayDepositOld = true;
          this.Item.PaymentMethod = 2;
          this.Item.PriceDeposit = this.Item.PriceDepositPaid;
        }
        else {
          this.toastError(res["meta"]["error_message"]);
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
      }
    );

}

omit_special_phone(e) {
  const charCode = (e.which) ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

omit_special_date(e) {
  const charCode = (e.which) ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 47) {
    return false;
  }
  return true;
}

omit_special_time(e) {
  const charCode = (e.which) ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 58) {
    return false;
  }
  return true;
}

validateNumber(value: any) {
  if (value) {
    if (/^\d+$/.test(value)) {
      return true;
    }
  }
  return false;
}

onChangePrice(value) {
  this.Item.PriceDepositPaid = Number(value.replace(/[^0-9.-]+/g, ''));
}

onChangeDG(type) {
  if(type==1 && this.Item.IsRateBt)this.Item.IsRateBt = false;
  if(type==2 && this.Item.IsRateHh)this.Item.IsRateHh = false;
}

onChangeBP(type) {
  if(type==1 && this.Item.IsSolutionTt)this.Item.IsSolutionTt = false;
  if(type==2 && this.Item.IsSolutionSc)this.Item.IsSolutionSc = false;
}

checkMinMax(event, type) {
  if(event) {
      let currDate = new Date(event);
      if(type == 1) {
          this.minDateTo = {
              year: currDate.getFullYear(),
              month: currDate.getMonth() + 1,
              day: currDate.getDate()
          };
          this.Item.TimeActive = this.Item.DateActive;
      }
      else if(type == 2){
          this.maxDateFrom = {
              year: currDate.getFullYear(),
              month: currDate.getMonth() + 1,
              day: currDate.getDate()
          };
          this.Item.TimeActiveTo = this.Item.DateActiveTo;
      }
      else if(type == 3){
          this.Item.TimeSurvey = this.Item.DateSurvey;
          console.log(this.Item.TimeSurvey);
      }
      else if(type == 4){
          this.Item.TimeStart = this.Item.DateStart;
      }
  }
  // else {
  //     if(type == 1) this.minDate = undefined;
  //     else this.maxDate = undefined;
  // }
}

keyUpTime(e, id) {
  var item1: any = document.getElementById("" + id);
  var value = e.target.value;
  if (item1 && value) {
    if (e.which === 8) {
      if (value.length == 3) {
        item1.value = value.slice(0, value.length - 1);
      }
    } else {
      if (value.length == 2) {
        if (value && Number(value) && Number(value) >= 24) {
          this.toastWarning("Giờ phải trong khoảng 00 đến 23");
          item1.value = "";
        } else {
          item1.value = value + ":";
        }
      } else if (value.length > 5) {
        item1.value = value.slice(0, 5);
      } else if (value.length == 5) {
        var mins = value.slice(3, 5);
        if (mins && Number(mins) && mins >= 60) {
          this.toastWarning("Vui lòng nhập đúng định dạng hh:mm");
          item1.value = "";
        } else {
          item1.value = value;
        }
      } else {
        item1.value = value;
      }
    }
  }
}

redirectTo(uri:string){
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  this.router.navigate([uri]));
}


}
