import { HeaderService } from './../../../service/header.service';
import { requestGroupResponse, resMotorcycleCard, resResidentCard, resResidentRequest, resCarCard } from '../../../data/Model';
import { CommonService } from './../../../service/common.service';
import { LstTypeRequest, LstStatusRequestGroup, domain, ApiConstant, lstStatusPayment, lstStatusConfirmPayment, statusCardRequestGroup, domainImage, typePayment, CardType } from './../../../data/const';
import { ResidentManagerService } from './../../../service/resident-manager.service';
import { CarCardComponent } from './../dialogs/car-card/car-card.component';
import { ResidentCardComponent } from './../dialogs/resident-card/resident-card.component';
import { QueryFilter } from './../../../data/dt';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbDatepickerI18n, NgbDatepickerI18nHebrew, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResidentMoveInComponent } from '../dialogs/resident-move-in/resident-move-in.component';
import { HttpHeaders, HttpClient, HttpUrlEncodingCodec, HttpRequest, HttpEventType } from '@angular/common/http';
import { typeCardRequestGroup } from '../../../data/const';
import { requestGroup } from '../../../data/Model';
import { MotorcycleCardComponent } from '../dialogs/motorcycle-card/motorcycle-card.component';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { L } from '@angular/core/src/render3';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { CancelRequestGroupComponent } from '../dialogs/cancel-request-group/cancel-request-group.component';

export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-edit-resident-service',
  templateUrl: './edit-resident-service.component.html',
  styleUrls: ['./edit-resident-service.component.scss'],
  providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class EditResidentServiceComponent implements OnInit, OnDestroy {
  @ViewChild('file') file: ElementRef;
  @ViewChild('file1') file1: ElementRef;
  public lstTypeRegister = [];
  public httpOptions:any;
  public item: requestGroup;
  public apartmentIdChanged: number;
  public q:QueryFilter;
  public isCreatePage: boolean;
  public isResidentRequest: boolean = true;
  public isResidentCard: boolean = false;
  public isMotorcycleCard: boolean = false;
  public isCarCard: boolean = false;
  public domainImage = domainImage;
  public typeRequest: typeCardRequestGroup;
  public lstResidentRequest: resResidentRequest[] = new Array();
  public lstResidentCard: resResidentCard[] = new Array();
  public lstMotorcleCard: resMotorcycleCard[] = new Array();
  public lstCarCard: resCarCard[] = new Array();
  public lstTypeRequest = [];
  public lstProject = [];
  public lstTower = [];
  public lstFloor = [];
  public lstVehicle = [];
  public lstApartment = [];
  public lstCard = [];
  public lstStatusRequest = LstStatusRequestGroup;
  public lstStatusPayment = lstStatusPayment;
  public lstStatusConfirmPayment = lstStatusConfirmPayment;
  public lstAllResidentCard = [];
  public oldProcessStatus = 0;
  public oldTypePayment = 0;
  public lstCardIdChecked = [];
  public isShowWarning: boolean = false;
  public isLoading = false;
  public isLoadingCancel = false;
  public typePayment: typePayment;
  public cardType = CardType;
  public isWarningDateAppointment = false;
  public isWarningDateFinish = false;
  public isWarningStatus = false;
  public isWarningMoney = false;
  public isWarningPayment = false;
  public isWarningAdminReceived = false;
  public isWarningProjectId = false;
  public isWarningTower = false;
  public isWarningFloor = false;
  public isWarningApartment = false;

  // Quyền duyệt đơn đăng ký thẻ
  public RoleAcceptRequestGroup: boolean;

  constructor(
    private route: ActivatedRoute,
    private readonly routerNavigate: Router,
    private location: Location,
    private modalService: NgbModal,
    private readonly residentService: ResidentManagerService,
    private readonly commonService: CommonService,
    private readonly routerUrl: Router,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
    private readonly headerService: HeaderService,
    private gallery: Gallery,
    public titleService: Title,
  ) {
    this.q = new QueryFilter();

    this.lstTypeRequest = LstTypeRequest.filter((i)=> i.Id != -1);

    this.item = new requestGroup();
    this.item.type = 1;
    this.item.processStatus = 1;
    this.oldProcessStatus = 1;
    this.item.registrationDate = new Date();
    this.item.responseAdmin = new requestGroupResponse();
    this.item.responseAdmin.statusPayment = lstStatusPayment[0].Id;
    this.item.responseAdmin.statusAdminConfirm = lstStatusConfirmPayment[0].Id;
    this.item.responseAdmin.money = 0;
    this.item.files = [];

    this.RoleAcceptRequestGroup = this.commonService.CheckAccessKeyRole("DDKT", 0);
   }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Đăng ký cấp mới thẻ");
    this.oldProcessStatus = 1;
    [...this.lstStatusRequest.map(s => s.disabled = false)];

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.isCreatePage = false;
      this.onGetDetail(id);
    } else {
      this.isCreatePage = true;
    }

    this.GetListProject();
    this.getLstVehicle();
    //this.getLstCard();

    setInterval(() => this.onCheckIsShowWarning(), 100);
  }

  ngOnDestroy() {
    clearInterval();
    this.oldProcessStatus = 1;
    this.headerService.clear();
    this.headerService.showWarning(false);
  }

  onSubmit() {
    this.isWarningDateAppointment = false;
    this.isWarningDateFinish = false;
    this.isWarningStatus = false;
    this.isWarningMoney = false;
    this.isWarningPayment = false;
    this.isWarningAdminReceived = false;
    this.isWarningProjectId = false;
    this.isWarningTower = false;
    this.isWarningFloor = false;
    this.isWarningApartment = false;

    if (!this.item.projectId) {
      this.isWarningProjectId = true;
      this.toastWarning("Vui lòng chọn dự án");
      return;
    } else if (!this.item.towerId) {
      this.isWarningTower = true;
      this.toastWarning("Vui lòng chọn tòa nhà");
      return;
    } else if (!this.item.floorId) {
      this.isWarningFloor = true;
      this.toastWarning("Vui lòng chọn tầng");
      return;
    } else if (!this.item.apartmentId) {
      this.isWarningApartment = true;
      this.toastWarning("Vui lòng chọn căn hộ");
      return;
    } else if (!this.lstResidentRequest.length &&
      !this.lstCarCard.length &&
      !this.lstResidentCard.length &&
      !this.lstMotorcleCard.length) {
      this.toastWarning("Chưa có thông tin danh mục đăng ký");
      return;
    } else if (this.item.responseAdmin.appointmentDate &&
    this.item.registrationDate > this.item.responseAdmin.appointmentDate) {
      this.isWarningDateAppointment = true;
      this.toastWarning("Ngày hẹn trả thẻ không được trước ngày đăng ký");
    return;
    } else if (this.item.responseAdmin.finishDate &&
      this.item.responseAdmin.appointmentDate &&
      this.item.responseAdmin.finishDate < this.item.responseAdmin.appointmentDate) {
      this.isWarningDateFinish = true;
      this.toastWarning("Ngày trả thẻ không được trước ngày hẹn trả");
    return;
    } else if (this.item.processStatus == 6 &&
      (!this.item.responseAdmin.money ||
        this.item.responseAdmin.money === 0)) {
      this.isWarningMoney = true;
      this.toastWarning("Vui lòng nhập số phí phải trả để tiếp tục!");
      return;
    }

    for(let u = 0; u < this.lstResidentCard.length; u ++) {
      if (!this.lstResidentCard[u].identifyType) {
        this.lstResidentCard[u].identifyType = 2;
      }
    }

    this.item.residentRequests = this.getResidentRequest(this.lstResidentRequest);
    this.item.residentCards = this.lstResidentCard;
    this.item.motorcycleCards = this.lstMotorcleCard;
    this.item.carCards = this.lstCarCard;
    this.item.files = [...this.item.files];
    this.item.responseAdmin.appointmentDate = this.item.responseAdmin.appointmentDate ?
     new Date(moment(this.item.responseAdmin.appointmentDate.toString()).format('YYYY-MM-DD')) : null;
    this.item.responseAdmin.finishDate = this.item.responseAdmin.finishDate ?
     new Date(moment(this.item.responseAdmin.finishDate.toString()).format('YYYY-MM-DD')) : null;


    if (this.item.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE) {
      for (let i = 0 ; i < this.item.residentCards.length ; i ++) {
        if (!this.item.residentCards[i].cardId || this.item.residentCards[i].cardId == 0) {
          this.toastWarning(`Vui lòng chọn mã số thẻ cho thẻ cư dân: ${this.item.residentCards[i].residentName}`);
          return;
        }
      }

      for (let i = 0 ; i < this.item.motorcycleCards.length ; i ++) {
        if (!this.item.motorcycleCards[i].cardId || this.item.motorcycleCards[i].cardId == 0) {
          this.toastWarning(`Vui lòng chọn mã số thẻ cho thẻ xe máy của: ${this.item.motorcycleCards[i].vehicleOwner}`);
          return;
        }
      }

      for (let i = 0 ; i < this.item.carCards.length ; i ++) {
        if (!this.item.carCards[i].cardId || this.item.carCards[i].cardId == 0) {
          this.toastWarning(`Vui lòng chọn mã số thẻ cho thẻ xe ôtô của: ${this.item.carCards[i].vehicleOwner}`);
          return;
        }
      }

      if (this.item.responseAdmin.money > 0 && this.item.responseAdmin.statusPayment != this.lstStatusPayment.find(s => s.Id == 1).Id) {
        this.isWarningPayment = true;
        this.toastWarning(`Không thể duyệt khi chưa thanh toán. Vui lòng xác nhận thanh toán`);
        return;
      } else if (this.item.responseAdmin.money > 0 && this.item.responseAdmin.statusAdminConfirm != this.lstStatusConfirmPayment.find(s => s.Id == 1).Id) {
        this.isWarningAdminReceived = true;
        this.toastWarning(`Không thể duyệt khi BQL khi chưa nhận được tiền. Vui lòng xác nhận thanh toán`);
        return;
      }
    }

    this.isLoading = true;
    if (this.isCreatePage) {
      this.http.post(ApiConstant.CreateRequestRegistration, this.item).subscribe(
        (res) => {
          if (res['meta']['error_code'] == 200) {
            this.toastSuccess("Tạo đăng ký mới thành công");
            this.isLoading = false;
            this.isCreatePage = false;

            this.routerNavigate.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
              this.routerNavigate.navigate([`/utilities/resident-service`]);
            });
          } else {
            this.isLoading = false;
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastError(error["meta"]["error_message"]);
        })
    } else {
      this.http.put(ApiConstant.UpdateRequestRegistration  + this.item.id, this.item).subscribe(
        (res) => {
          if (res['meta']['error_code'] == 200) {
            this.isLoading = false;
            this.routerNavigate.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
              this.routerNavigate.navigate([`/utilities/resident-service`]);
            });
            this.toastSuccess("Cập nhật thành công!");
          } else {
            this.isLoading = false;
            this.toastError(res["meta"]["error_message"]);
          }
        }),
        (error) => {
          this.isLoading = false;
          this.toastError(error["meta"]["error_message"]);
        }
    }

  }

  getLstCard(){
    this.http.get(domain + 'api/cms/Card/getByPage?page=1&page_size=20000&CardStatus=0&ProjectId='+ this.item.projectId + '&TowerId='+ this.item.towerId).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstCard =res["data"];
          this.lstCard.map(s => s.disabled = false);
        }
      },
      (err) => {
        this.lstCard = [];
        console.log("Error: connect to API");
      });
  }

  GetListProject() {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.projectId = null;
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;

		return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  getResidentRequest(lst: resResidentRequest[]) {
    let reqLstResidentRequest = [];

    if (lst.length) {
      for(let i = 0; i < lst.length; i ++) {
        var itemResidentRequest = lst[i];

        reqLstResidentRequest.push(
          {
            cardRequestId: itemResidentRequest.cardRequestId || null,
            residentId: itemResidentRequest.residentId || 0,
            residentIdTemporary: itemResidentRequest.residentIdTemporary || null,
            fullName: itemResidentRequest.fullName,
            birthday: itemResidentRequest.birthday,
            gender: itemResidentRequest.gender,
            phone: itemResidentRequest.phone,
            countryId: itemResidentRequest.countryId,
            identifyType: itemResidentRequest.identifyType,
            identifyCode: itemResidentRequest.identifyCode,
            identifyCreate: itemResidentRequest.identifyCreate,
            identifyLoc: itemResidentRequest.identifyLoc,
            relationshipId: itemResidentRequest.relationshipId,
            relationshipName: itemResidentRequest.relationshipName,
            files: itemResidentRequest.files
          }
        )
      }
    }

    return reqLstResidentRequest;
  }

  GetListTower() {
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;
    this.item.residentId = null;
    this.item.residentPhone = '';
    this.item.residentName = '';
    this.lstResidentRequest = [];
    this.lstResidentCard = [];
    this.lstMotorcleCard = [];
    this.lstCarCard = [];
    this.apartmentIdChanged = null;

		return this.commonService.getLstTower(this.item.projectId).subscribe(
      (res) => {
        this.lstTower = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  getLstVehicle(){
    return this.commonService.getLstAllVehicle(0).subscribe(
      (res) => {
        this.lstVehicle = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getVehicleName(vehicleId: number) {
    var itemVehicle = this.lstVehicle.find(s => s["id"] === vehicleId);

    if (itemVehicle) {
      return itemVehicle["name"];
    } else {
      return '';
    }
  }

  GetListFloor() {
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.floorId = null;
    this.item.apartmentId = null;
    this.item.apartmentName = '';
    this.item.residentId = null;
    this.item.residentPhone = '';
    this.item.residentName = '';
    this.lstResidentRequest = [];
    this.lstResidentCard = [];
    this.lstMotorcleCard = [];
    this.lstCarCard = [];
    this.apartmentIdChanged = null;

		this.commonService.getLstFloor(this.item.towerId).subscribe(
      (res) => {
        this.lstFloor = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  GetListApartment() {
    this.lstApartment = [];
    this.item.apartmentId = null;
    this.item.apartmentName = '';
    this.item.residentId = null;
    this.item.residentPhone = '';
    this.item.residentName = '';
    this.lstResidentRequest = [];
    this.lstResidentCard = [];
    this.lstMotorcleCard = [];
    this.lstCarCard = [];
    this.apartmentIdChanged = null;

		return this.commonService.getLstApartment(this.item.floorId).subscribe(
      (res) => {
        if (this.isCreatePage) {
          for (let i = 0; i < res.length; i ++) {
            this.commonService.getPurchaserByApartmentId(res[i]['ApartmentId']).subscribe(
              (lstRs) => {
                if (lstRs) {
                  this.lstApartment.push(res[i]);
                  this.lstApartment = [...this.lstApartment];
                }
              },
              (err) => {
                this.toastError(err['meta']['error_message']);
              });
          }
        } else {
          this.lstApartment = res;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  getPurchaserByApartmentId(value) {
    this.item.apartmentId = value.ApartmentId;
    this.item.apartmentName = value.Name;
    this.lstResidentRequest = [];
    this.lstResidentCard = [];
    this.lstMotorcleCard = [];
    this.lstCarCard = [];

    if (this.item.apartmentId > 0 &&
      (this.lstResidentRequest.length ||
       this.lstResidentCard.length ||
       this.lstMotorcleCard.length ||
       this.lstCarCard.length)) {
        this.modalDialogService.openDialog(this.viewRef, {
          title: 'Xác nhận',
          settings: {
          footerClass: 'footer-dialog modal-footer'
        },
          childComponent: SimpleModalComponent,
          data: {
            text: "Dữ liệu đăng ký thẻ bên dưới sẽ bị mất. Bạn có muốn thay đổi không?"
          },
          actionButtons: [
            {
              text: 'Hủy',
              buttonClass: 'btn btn-default',
              onAction: () => {
                this.apartmentIdChanged = this.item.apartmentId;

                this.viewRef.clear();
              }
            },
            {
              text: 'Xác nhận',
              buttonClass: 'btn btn-success',
              onAction: () => {
                this.lstResidentRequest = [];
                this.lstResidentCard = [];
                this.lstMotorcleCard = [];
                this.lstCarCard = [];
                this.lstAllResidentCard = [];

                this.commonService.getPurchaserByApartmentId(value.Id).subscribe(
                  (res) => {
                    if (res) {
                      this.item.apartmentId = value.ApartmentId;
                      this.item.apartmentName = value.Name;
                      this.item.residentId = res.ResidentId;
                      this.item.residentName = res.FullName;
                      this.item.residentPhone = res.Phone;

                      this.getAllResidentCardByApartmentId(value.ApartmentId);
                    } else {
                      this.item.apartmentId = null;
                      this.item.apartmentName = '';
                      this.item.residentId = null;
                      this.item.residentName = '';
                      this.item.residentPhone = '';
                      this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                      return;
                    }
                  },
                  (err) => {
                    this.item.apartmentId = null;
                    this.item.apartmentName = '';
                    this.item.residentId = null;
                    this.item.residentName = '';
                    this.item.residentPhone = '';
                    this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                    return;
                  }
                )

                this.viewRef.clear();
              }
            }
          ],
        });
       } else {
        this.lstAllResidentCard = [];

        this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
          (res) => {
            if (res) {
              this.item.apartmentId = value.ApartmentId;
              this.item.apartmentName = value.Name;
              this.item.residentId = res.ResidentId;
              this.item.residentName = res.FullName;
              this.item.residentPhone = res.Phone;

              this.getAllResidentCardByApartmentId(value.ApartmentId);
            } else {
              this.item.apartmentId = null;
              this.item.apartmentName = '';
              this.item.residentId = null;
              this.item.residentName = '';
              this.item.residentPhone = '';
              this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
              return;
            }
          },
          (err) => {
            this.item.apartmentId = null;
            this.item.apartmentName = '';
            this.item.residentId = null;
            this.item.residentName = '';
            this.item.residentPhone = '';
            this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
            return;
          }
        )
       }
  }

  onGetDetail(id) {
    this.residentService.getDetail(id).subscribe(
      (res) => {
        if (res) {
          this.setDataDetail(res);
          this.item.id = res['id'];
          this.item.note = res['note'];
          this.item.info = res['info'];
          this.item.processStatus = res['processStatus'];
          this.oldProcessStatus = res['processStatus'];
          this.item.status = res['status'];
          this.item.createdAt = res['createdAt'];
          this.item.updatedAt = res['updatedAt'];
          this.item.updatedById = res['updatedById'];
          this.item.createdById = res['createdById'];
          this.item.registrationDate = res['registrationDate'];
          this.item.type = res['type'];
          this.item.reasonId = res['reasonId'];
          this.item.reasonName = res['reasonName'];
          this.item.files = res['files'];
          this.item.carCards = res['carCards'];
          this.item.motorcycleCards = res['motorcycleCards'];
          this.item.residentCards = res['residentCards'];
          this.item.residentRequests = res['residentRequests'];
          this.lstResidentRequest = res['residentRequests'];
          this.lstResidentCard = res['residentCards'];
          this.lstMotorcleCard = res['motorcycleCards'];
          this.lstCarCard = res['carCards'];
          this.item.responseAdmin = res['responseAdmin'];
          this.oldTypePayment = res['responseAdmin']['typePayment'] || 0;
          this.item.responseAdmin.appointmentDate = new Date(moment(this.item.responseAdmin.appointmentDate).format('YYYY-MM-DD'));
          console.log(this.lstResidentRequest);
          for(let x = 0; x < this.lstStatusRequest.length; x ++) {
            if (this.lstStatusRequest[x].Id < this.item.processStatus) {
              this.lstStatusRequest[x].disabled = true;
            }
          }

          this.lstStatusRequest = [...this.lstStatusRequest];
        } else {

          this.modalDialogService.openDialog(this.viewRef, {
            title: 'Thông báo',
            settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
            data: {
              text: "Yêu cầu đăng ký không tồn tại. Vui lòng kiểm tra lại!"
            },
            actionButtons: [
              {
                text: 'Quay lại',
                buttonClass: 'btn btn-info',
                onAction: () => {
                  this.returnPage();
                }
              },
            ],
          });
        }

      },
      (err) => {
        this.routerUrl.navigate(["**"])
      });
  }

  QueryChanged(){

  }

  getAllResidentCardByApartmentId(apartmentId: number){
    this.http.get(domain + 'api/Reg/getResidentCard?apartmentId=' + apartmentId).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstAllResidentCard = res["data"];
          this.lstAllResidentCard.map(s => s.disabled = false);
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  onCheckAddCardId(value) {
    if (value.Id !== this.oldProcessStatus && this.oldProcessStatus < 7 && value.Id >= 7 && this.item.apartmentId > 0) {
      for(let i = 0; i < this.lstMotorcleCard.length; i ++) {
        let itemMotocycleCard = this.lstMotorcleCard[i];

        let isExistIntergrated = this.lstMotorcleCard.findIndex(s => s.isIntegratedMotorcycleCard == true &&
          s != itemMotocycleCard &&
          s.residentCardIntegratedId == itemMotocycleCard.residentCardIntegratedId) >= 0 ? true : false;

        if (itemMotocycleCard.residentCardIntegratedId &&
          itemMotocycleCard.residentCardIntegratedId > 0 &&
          !isExistIntergrated) {
          this.lstMotorcleCard[i].isIntegratedMotorcycleCard = true;

          if (this.lstAllResidentCard.length) {
            this.lstMotorcleCard[i].isIntegratedMotorcycleCard = true;
            this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)) ?
            true : false;
            this.lstMotorcleCard[i].cardId = this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)) ?
            this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)).cardId : null;
            this.lstMotorcleCard[i].cardName = this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)) ?
            this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)).cardName : '';
          } else {
            this.http.get(domain + 'api/Reg/getResidentCard?apartmentId=' + this.item.apartmentId).subscribe(
              (res) => {
                if (res["meta"]["error_code"] == 200) {
                  this.lstAllResidentCard = res["data"];
                  this.lstAllResidentCard.map(s => s.disabled = false);

                  this.lstMotorcleCard[i].cardId = this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)) ?
                  this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)).cardId : null;
                  this.lstMotorcleCard[i].cardName = this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)) ?
                  this.lstAllResidentCard.find(s => s.residentCardId == itemMotocycleCard.residentCardIntegratedId && !(s.isIntegratedMotorcycleCard)).cardName : '';
                }
              },
              (err) => {
                console.log("Error: connect to API");
              });
          }
        }
      }
    }
  }

  onCheckIsShowWarning() {
    if (this.apartmentIdChanged > 0 &&
      this.isCreatePage &&
      (this.lstResidentRequest.length ||
        this.lstResidentCard.length ||
        this.lstCarCard.length ||
        this.lstMotorcleCard.length)) {
          this.headerService.showWarning(true);
        } else {
          this.headerService.showWarning(false);
        }
  }

  onUnChecked(e) {
    if (!this.item.responseAdmin.typePayment || this.item.responseAdmin.typePayment === 0) {
      this.item.responseAdmin.typePayment = 1;
    } else {
      this.item.responseAdmin.typePayment = this.oldTypePayment == 2 ? 2 : 0;
    }
  }

  // upload(files){
  //   if (files.length === 0)
	// 		return;

	// 	const formData = new FormData();

	// 	for (let file of files)
	// 		formData.append(file.name, file);

	// 	const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
	// 		reportProgress: true,
	// 	});

	// 	this.http.request(uploadReq).subscribe(event => {
	// 		if (event.type === HttpEventType.UploadProgress) {

	// 		}
	// 		else if (event.type === HttpEventType.Response) {
  //       this.item.files.unshift({
  //         id: 0,
  //         fileId: 0,
  //         fileRef: '',
  //         url: event.body["data"][0].toString()
  //       });
	// 			if (this.file) this.file.nativeElement.value = "";
	// 		}
	// 	});
  // }

  upload(files){
    if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.files.unshift({
              id: 0,
              fileId: 0,
              fileRef: '',
              url: element.toString()
            });
          });
        }
				if (this.file) this.file.nativeElement.value = "";
			}
		});
  }

  onChangeProject() {
    if (this.item.projectId > 0) {
      this.lstResidentRequest = [];
      this.lstResidentCard = [];
      this.lstMotorcleCard = [];
      this.lstCarCard = [];
      this.item.towerId = null;
      this.item.floorId = null;
      this.item.apartmentId = null;
      this.item.apartmentName = '';
      this.item.residentId = null;
      this.item.residentPhone = '';
      this.item.residentName = '';
      this.apartmentIdChanged = null;
    }

    this.GetListTower();
  }

  setDataDetail(item) {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.lstCard = [];
    this.item.projectId = null;
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;

		this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
        this.item.projectId = item["projectId"];

        this.commonService.getLstTower(this.item.projectId).subscribe((res) => {
          this.lstTower = res;
          this.item.towerId = item["towerId"];

          this.commonService.getLstFloor(this.item.towerId).subscribe((res) => {
            this.lstFloor = res;
            this.item.floorId = item["floorId"];

            this.commonService.getLstApartment(this.item.floorId).subscribe((res) => {
              this.lstApartment = res;

              this.apartmentIdChanged = item["apartmentId"];
              this.item.apartmentId = item["apartmentId"];
              this.item.apartmentName = item["apartmentName"];
              this.item.residentId = item["residentId"];
              this.item.residentName = item["residentName"];

              this.getResidentPhone(this.item.apartmentId);
              this.getLstCard();
            })
          })
        })
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getResidentPhone(apartmentId) {
    this.commonService.getPurchaserByApartmentId(apartmentId).subscribe(
      (res) => {
        if (res) {
          this.item.residentPhone = res.Phone;
        } else {
          this.item.residentPhone = '';
        }
      },
      (err) => {
        this.item.residentPhone = '';
      }
    )
  }

  deleteCarCard(item, index) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
      data: {
        text: "Bạn có chắc muốn xóa không?"
      },
      actionButtons: [
        {
          text: 'Hủy',
          buttonClass: 'btn btn-default',

        },
        {
          text: 'Xác nhận',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.lstCarCard = this.lstCarCard.filter(s => s !== item);

            this.viewRef.clear();
            this.toastSuccess("Xóa thành công!");
          }
        }
      ],
    });
  }

  deleteMotorcleCard(item, index) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
      data: {
        text: "Bạn có chắc muốn xóa không?"
      },
      actionButtons: [
        {
          text: 'Hủy',
          buttonClass: 'btn btn-default',

        },
        {
          text: 'Xác nhận',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.lstMotorcleCard = this.lstMotorcleCard.filter(s => s !== item);

            this.viewRef.clear();
            this.toastSuccess("Xóa thành công!");
          }
        }
      ],
    });
  }

  deleteResidentCard(item, index) {
    if (this.lstMotorcleCard.find(s => s.residentCardIdTemporary == item["residentCardIdTemporary"])) {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
        data: {
          text: "Thẻ cư dân này đang được đăng ký thẻ xe máy. Dữ liệu đăng ký thẻ xe máy cũng sẽ bị xóa. Bạn có chắc muốn xóa không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',

          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              if (!item.residentId || item.residentId === 0) {
                this.lstResidentCard = this.lstResidentCard.filter(s => s.residentIdTemporary != item["residentIdTemporary"]);
                this.lstMotorcleCard = this.lstMotorcleCard.filter(s => s.residentCardIdTemporary != item["residentCardIdTemporary"]);
              } else {
                this.lstResidentCard = this.lstResidentCard.filter(s => s.residentId != item["residentId"]);
                this.lstMotorcleCard = this.lstMotorcleCard.filter(s => s.residentId != item["residentId"]);
              }

              this.viewRef.clear();
              this.toastSuccess("Xóa thành công!");
            }
          }
        ],
      });
    } else {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
        data: {
          text: "Bạn có chắc muốn xóa không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',

          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.lstResidentCard = this.lstResidentCard.filter(s => s !== item);

              this.viewRef.clear();
              this.toastSuccess("Xóa thành công!");
            }
          }
        ],
      });
    }

  }

  deleteResidentRequest(item, index) {
    if (this.lstResidentCard.find(s => s.residentIdTemporary == item["residentIdTemporary"])) {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
        data: {
          text: "Thông tin cư dân này đang được dùng để đăng ký thẻ cư dân/thẻ xe máy/thẻ xe ô tô. Nếu đồng ý xóa đồng nghĩa với việc sẽ xóa thông tin đăng ký thẻ cư dân/thẻ xe máy/thẻ xe ô tô"
        },
        actionButtons: [
          {
            text: 'Quay lại',
            buttonClass: 'btn btn-default',

          },
          {
            text: 'Đồng ý',
            buttonClass: 'btn btn-success',
            onAction: () => {
              if (!item.residentId || item.residentId == 0) {
                this.lstResidentRequest = this.lstResidentRequest.filter(s => s !== item);
                let itemResidentCardDelete = this.lstResidentCard.find(s => s.residentIdTemporary === item["residentIdTemporary"]);

                this.lstResidentCard = this.lstResidentCard.filter(s => s.residentIdTemporary != item["residentIdTemporary"]);
                this.lstCarCard = this.lstCarCard.filter(s => s.residentIdTemporary != item["residentIdTemporary"]);

                if (itemResidentCardDelete) {

                  this.lstMotorcleCard = this.lstMotorcleCard.filter(s => s.residentCardIdTemporary != itemResidentCardDelete["residentCardIdTemporary"]);
                }
              } else {
                this.lstResidentRequest = this.lstResidentRequest.filter(s => s !== item);
                let itemResidentCardDelete = this.lstResidentCard.find(s => s.residentId === item["residentId"]);

                this.lstResidentCard = this.lstResidentCard.filter(s => s.residentId != item["residentId"]);
                this.lstCarCard = this.lstCarCard.filter(s => s.residentId != item["residentId"]);

                if (itemResidentCardDelete) {

                  this.lstMotorcleCard = this.lstMotorcleCard.filter(s => s.residentId != itemResidentCardDelete["residentId"]);
                }
              }

              this.viewRef.clear();
              this.toastSuccess("Xóa thành công!");
            }
          }
        ],
      });
    } else if (this.lstCarCard.find(s => s.residentIdTemporary == item["residentIdTemporary"])) {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Đồng ý',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
        data: {
          text: "Thông tin thẻ cư dân này đang được dùng để đăng ký thẻ xe máy. Nếu đồng ý xóa đồng nghĩa với việc sẽ xóa thông tin đăng ký thẻ xe máy"
        },
        actionButtons: [
          {
            text: 'Quay lại',
            buttonClass: 'btn btn-default',

          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.lstResidentRequest = this.lstResidentRequest.filter(s => s !== item);

              if (!item.residentId && item.residentId  == 0) {
                this.lstCarCard = this.lstCarCard.filter(s => s.residentIdTemporary == item["residentIdTemporary"]);
              } else {
                this.lstCarCard = this.lstCarCard.filter(s => s.residentId == item["residentId"]);
              }

              this.viewRef.clear();
              this.toastSuccess("Xóa thành công!");
            }
          }
        ],
      });
    } else {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
        data: {
          text: "Bạn có chắc muốn xóa không?"
        },
        actionButtons: [
          {
            text: 'Quay lại',
            buttonClass: 'btn btn-default',

          },
          {
            text: 'Đồng ý',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.lstResidentRequest = this.lstResidentRequest.filter(s => s !== item);

              this.viewRef.clear();
              this.toastSuccess("Xóa thành công!");
            }
          }
        ],
      });
    }
  }


  openResidentMoveInModal(item) {
    const dialofRef = this.modalService.open(ResidentMoveInComponent, { size: 'lg' });
    dialofRef.componentInstance.lstResidentRequest = this.lstResidentRequest;
    dialofRef.componentInstance.oldProcessStatus = this.oldProcessStatus;

    dialofRef.componentInstance.itemResidentMoveIn.subscribe((res) => {
      if (res["residentIdTemporary"]){
        this.lstResidentRequest.push(res);
      }
    });
  }

  openResidentMoveInDetailModal(item, index) {
    const dialofRef = this.modalService.open(ResidentMoveInComponent, { size: 'lg' });
    dialofRef.componentInstance.itemDetail = item;
    dialofRef.componentInstance.lstResidentRequest = this.lstResidentRequest;
    dialofRef.componentInstance.oldProcessStatus = this.oldProcessStatus;

    dialofRef.componentInstance.itemResidentMoveIn.subscribe((res) => {
      if (res["residentIdTemporary"] || res["residentId"]){
        this.lstResidentRequest[index] = res;

        let indexResidentCard = this.lstResidentCard.findIndex(s => s.residentIdTemporary === res["residentIdTemporary"] && s.residentIdTemporary !== null);
        let indexResidentCardUpdate = this.lstResidentCard.findIndex(s => !s.residentIdTemporary && s.residentId === res["residentId"]);
        let indexCarCard = this.lstCarCard.findIndex(s => s.residentIdTemporary == res["residentIdTemporary"]);

        if (indexResidentCard >= 0) {
          this.lstResidentCard[indexResidentCard].birthday = this.lstResidentRequest[index].birthday ? new Date(this.lstResidentRequest[index].birthday) : null;
          this.lstResidentCard[indexResidentCard].residentName = this.lstResidentRequest[index].fullName;
          this.lstResidentCard[indexResidentCard].phone = this.lstResidentRequest[index].phone;
          this.lstResidentCard[indexResidentCard].gender = this.lstResidentRequest[index].gender;
          this.lstResidentCard[indexResidentCard].countryId = this.lstResidentRequest[index].countryId;
          this.lstResidentCard[indexResidentCard].identifyCode = this.lstResidentRequest[index].identifyCode;
          this.lstResidentCard[indexResidentCard].identifyCreate = this.lstResidentRequest[index].identifyCreate;
          this.lstResidentCard[indexResidentCard].identifyLoc = this.lstResidentRequest[index].identifyLoc;
          this.lstResidentCard[indexResidentCard].identifyType = this.lstResidentRequest[index].identifyType;
          this.lstResidentCard[indexResidentCard].relationshipId = this.lstResidentRequest[index].relationshipId;
          this.lstResidentCard[indexResidentCard].relationshipName = this.lstResidentRequest[index].relationshipName;
        } else if (indexResidentCardUpdate >= 0) {
          this.lstResidentCard[indexResidentCardUpdate].birthday = this.lstResidentRequest[index].birthday ? new Date(this.lstResidentRequest[index].birthday) : null;
          this.lstResidentCard[indexResidentCardUpdate].residentName = this.lstResidentRequest[index].fullName;
          this.lstResidentCard[indexResidentCardUpdate].phone = this.lstResidentRequest[index].phone;
          this.lstResidentCard[indexResidentCardUpdate].gender = this.lstResidentRequest[index].gender;
          this.lstResidentCard[indexResidentCardUpdate].countryId = this.lstResidentRequest[index].countryId;
          this.lstResidentCard[indexResidentCardUpdate].identifyCode = this.lstResidentRequest[index].identifyCode;
          this.lstResidentCard[indexResidentCardUpdate].identifyCreate = this.lstResidentRequest[index].identifyCreate;
          this.lstResidentCard[indexResidentCardUpdate].identifyLoc = this.lstResidentRequest[index].identifyLoc;
          this.lstResidentCard[indexResidentCardUpdate].identifyType = this.lstResidentRequest[index].identifyType;
          this.lstResidentCard[indexResidentCardUpdate].relationshipId = this.lstResidentRequest[index].relationshipId;
          this.lstResidentCard[indexResidentCardUpdate].relationshipName = this.lstResidentRequest[index].relationshipName;
        }

        if (indexCarCard >= 0) {
          this.lstCarCard[indexCarCard].residentName = this.lstResidentRequest[index].fullName;
        }
      }
    });
  }

  openResidentCardModal(item) {
    const dialofRefResidentCard = this.modalService.open(ResidentCardComponent, { size: 'lg' });
    dialofRefResidentCard.componentInstance.apartmentId = this.item.apartmentId;
    dialofRefResidentCard.componentInstance.lstResidentRequest = this.lstResidentRequest;
    dialofRefResidentCard.componentInstance.processStatus = this.item.processStatus;
    dialofRefResidentCard.componentInstance.oldProcessStatus = this.oldProcessStatus;
    dialofRefResidentCard.componentInstance.lstCard = this.lstCard.filter(s => s.cardType == 1);
    dialofRefResidentCard.componentInstance.lstResidentCardRegister = this.lstResidentCard;
    dialofRefResidentCard.componentInstance.residentRequestId = !this.isCreatePage ? this.item.residentId : 0;

    dialofRefResidentCard.componentInstance.itemResidentCard.subscribe((res) => {
      if (res) {
        let indexCards = this.lstCard.findIndex(o => o.cardId == res.cardId);

        if (indexCards >= 0) {
          this.lstCard[indexCards].disabled = true;
        }

        this.lstResidentCard.push(res);

        if (res.cardId > 0 && this.lstMotorcleCard.length) {
          let indexMotorCard = this.lstMotorcleCard.findIndex(s => s.residentCardIdTemporary == res.residentCardIdTemporary);

          if (indexMotorCard >= 0) {
            this.lstMotorcleCard[indexMotorCard].cardId = res.cardId;
            this.lstMotorcleCard[indexMotorCard].cardName = res.cardName;
          }
        }
      }
    });
  }

  openResidentCardDetailModal(item, index) {
    const dialofRefResidentCard = this.modalService.open(ResidentCardComponent, { size: 'lg' });
    dialofRefResidentCard.componentInstance.apartmentId = this.item.apartmentId;
    dialofRefResidentCard.componentInstance.lstResidentRequest = this.lstResidentRequest;
    dialofRefResidentCard.componentInstance.itemDetail = item;
    dialofRefResidentCard.componentInstance.processStatus = this.item.processStatus;
    dialofRefResidentCard.componentInstance.oldProcessStatus = this.oldProcessStatus;
    dialofRefResidentCard.componentInstance.lstCard = this.lstCard.filter(s => s.cardType == 1);
    dialofRefResidentCard.componentInstance.lstResidentCardRegister = this.lstResidentCard;
    dialofRefResidentCard.componentInstance.residentRequestId = !this.isCreatePage ? this.item.residentId : 0;

    dialofRefResidentCard.componentInstance.itemResidentCard.subscribe((res) => {
      if (res) {
        let indexCards = this.lstCard.findIndex(o => o.cardId == res.cardId);
        let indexCardOld = this.lstCard.findIndex(o => o.cardId == item.cardId);

        if (indexCardOld >= 0) {
          this.lstCard[indexCardOld].disabled = false;
        }

        if (indexCards >= 0) {
          this.lstCard[indexCards].disabled = true;
        }

        this.lstResidentCard[index] = res;

        if (res.cardId > 0 && this.lstMotorcleCard.length) {
          for(let x = 0; x < this.lstMotorcleCard.length; x++) {
            if (this.lstMotorcleCard[x].residentCardIntegratedId &&
                this.lstMotorcleCard[x].residentCardIntegratedId === res.residentCardId &&
                this.lstMotorcleCard[x].cardRequestId != 0) {
                  let isExistIntegrated = this.lstMotorcleCard.find(s => s.cardId === res.cardId && s.residentCardIntegratedId === res.residentCardId);

                  if (!isExistIntegrated) {
                    this.lstMotorcleCard[x].cardId = res.cardId;
                    this.lstMotorcleCard[x].cardName = res.cardName;
                  }

                  this.lstMotorcleCard[x].isIntegratedMotorcycleCard = true;
            }

            if (this.lstMotorcleCard[x].residentCardIdTemporary !== null &&
              this.lstMotorcleCard[x].residentCardIdTemporary === res.residentCardIdTemporary) {
                let isExistIntegrated = this.lstMotorcleCard.find(s => s.cardId === res.cardId && s.residentCardIdTemporary === res.residentCardIdTemporary);

                if (!isExistIntegrated) {
                  this.lstMotorcleCard[x].cardId = res.cardId;
                  this.lstMotorcleCard[x].cardName = res.cardName;
                }

                this.lstMotorcleCard[x].isIntegratedMotorcycleCard = true;
            }
          }
        }
      }
    });
  }

  openMotorcleCardModal(item) {
    const dialofRefMotorcleCard = this.modalService.open(MotorcycleCardComponent, { size: 'lg' });

    dialofRefMotorcleCard.componentInstance.apartmentId = this.item.apartmentId;
    dialofRefMotorcleCard.componentInstance.projectId = this.item.projectId;
    dialofRefMotorcleCard.componentInstance.lstResidentCardTemporary = this.lstResidentCard;
    dialofRefMotorcleCard.componentInstance.processStatus = this.item.processStatus;
    dialofRefMotorcleCard.componentInstance.oldProcessStatus = this.oldProcessStatus;
    dialofRefMotorcleCard.componentInstance.lstCard = this.lstCard.filter(s => s.cardType == 1);
    dialofRefMotorcleCard.componentInstance.lstMotocycleCard = this.lstMotorcleCard;

    dialofRefMotorcleCard.componentInstance.itemMotorcycleCard.subscribe((res) => {
      if (res) {
        let indexCards = this.lstCard.findIndex(o => o.cardId == res.cardId);

        if (indexCards >= 0) {
          this.lstCard[indexCards].disabled = true;
        }

        this.lstMotorcleCard.push(res);
      }
    });
  }

  openMotorcleCardDetailModal(item, index) {
    const dialofRefMotorcleCard = this.modalService.open(MotorcycleCardComponent, { size: 'lg' });

    dialofRefMotorcleCard.componentInstance.apartmentId = this.item.apartmentId;
    dialofRefMotorcleCard.componentInstance.projectId = this.item.projectId;
    dialofRefMotorcleCard.componentInstance.lstResidentCardTemporary = this.lstResidentCard;
    dialofRefMotorcleCard.componentInstance.itemDetail = item;
    dialofRefMotorcleCard.componentInstance.processStatus = this.item.processStatus;
    dialofRefMotorcleCard.componentInstance.oldProcessStatus = this.oldProcessStatus;
    dialofRefMotorcleCard.componentInstance.lstMotocycleCard = this.lstMotorcleCard;
    dialofRefMotorcleCard.componentInstance.lstCard = this.lstCard.filter(s => s.cardType == 1);

    dialofRefMotorcleCard.componentInstance.itemMotorcycleCard.subscribe((res) => {
      if (res) {
        let indexCards = this.lstCard.findIndex(o => o.cardId == res.cardId);
        let indexCardOld = this.lstCard.findIndex(o => o.cardId == item.cardId);

        if (indexCardOld >= 0) {
          this.lstCard[indexCardOld].disabled = false;
        }

        if (indexCards >= 0) {
          this.lstCard[indexCards].disabled = true;
        }

        this.lstMotorcleCard[index] = res;
      }
    });
  }

  openCarCardModal(item) {
    const dialofRefCarCard = this.modalService.open(CarCardComponent, { size: 'lg' });

    dialofRefCarCard.componentInstance.apartmentId = this.item.apartmentId;
    dialofRefCarCard.componentInstance.projectId = this.item.projectId;
    dialofRefCarCard.componentInstance.lstResidentRequest = this.lstResidentRequest;
    dialofRefCarCard.componentInstance.processStatus = this.item.processStatus;
    dialofRefCarCard.componentInstance.oldProcessStatus = this.oldProcessStatus;
    dialofRefCarCard.componentInstance.lstCard = this.lstCard.filter(s => s.cardType > 1);

    dialofRefCarCard.componentInstance.itemCarCard.subscribe((res) => {
      if (res) {
        let indexCards = this.lstCard.findIndex(o => o.cardId == res.cardId);

        if (indexCards >= 0) {
          this.lstCard[indexCards].disabled = true;
        }

        this.lstCarCard.push(res);
      }
    });
  }

  openCarCardDetailModal(item, index) {
    const dialofRefCarCard = this.modalService.open(CarCardComponent, { size: 'lg' });

    dialofRefCarCard.componentInstance.apartmentId = this.item.apartmentId;
    dialofRefCarCard.componentInstance.projectId = this.item.projectId;
    dialofRefCarCard.componentInstance.lstResidentRequest = this.lstResidentRequest;
    dialofRefCarCard.componentInstance.itemDetail = item;
    dialofRefCarCard.componentInstance.processStatus = this.item.processStatus;
    dialofRefCarCard.componentInstance.oldProcessStatus = this.oldProcessStatus;
    dialofRefCarCard.componentInstance.lstCard = this.lstCard.filter(s => s.cardType > 1);

    dialofRefCarCard.componentInstance.itemCarCard.subscribe((res) => {
      if (res) {
        let indexCards = this.lstCard.findIndex(o => o.cardId == res.cardId);
        let indexCardOld = this.lstCard.findIndex(o => o.cardId == item.cardId);

        if (indexCardOld >= 0) {
          this.lstCard[indexCardOld].disabled = false;
        }

        if (indexCards >= 0) {
          this.lstCard[indexCards].disabled = true;
        }

        this.lstCarCard[index] = res;
      }
    });
  }

  openModelCancel() {
    const dialofRefCancel = this.modalService.open(CancelRequestGroupComponent, { size: 'lg' });
    dialofRefCancel.componentInstance.id = this.item.id;

    dialofRefCancel.componentInstance.response.subscribe((res) => {
      if (res) {
        this.routerNavigate.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
          this.routerNavigate.navigate([`/utilities/resident-service`]);
        });
      }
    });
  }

  onChangePrice(value) {
    this.item.responseAdmin.money = Number(value.replace(/[^0-9.-]+/g, ''));
  }

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
      lstImage.push({path: domainImage + item.url})
    })

    let prop = {
        images: lstImage,
        index
    };
    this.gallery.load(prop);
  }

  returnPage() {
    this.location.back();
  }

  returnPagePaging() {
    if (this.apartmentIdChanged > 0 &&
      this.isCreatePage &&
      (this.lstResidentRequest.length ||
        this.lstResidentCard.length ||
        this.lstCarCard.length ||
        this.lstMotorcleCard.length)){
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
        data: {
          text: "Bạn chưa hoàn tất thông tin. Bạn muốn rời mà không hoàn tất không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',

          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.routerUrl.navigate(['/utilities/resident-service']);
            }
          }
        ],
      });
    } else {
      this.location.back();
    }

  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
}
