import { domain } from './../../../data/const';
import { RegistrationTerm } from '../../../data/Model';
import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { News, Attactment } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi, TypeTerm, domainFile } from '../../../data/const';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-registration-term',
  templateUrl: './registration-term.component.html',
  styleUrls: ['./registration-term.component.scss']
})
export class RegistrationTermComponent implements OnInit {
  @ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;

	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public Item: RegistrationTerm;

	public httpOptions: any;

	public submitted = false;

	public isShowFilter =false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	public TypeTerm = TypeTerm;
	public domainImage = domainImage;
	public domainFile = domainFile;

	public projects = [];
	public towers = [];
	public categories = [];

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
		this.Item = new RegistrationTerm();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Danh sách điều khoản đăng ký");
		this.GetListCommon();
		this.GetListProject();
		// this.GetListCategory();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = '';
		
		if (this.q["ProjectId"] != undefined) {
			if (query != '') {
				query += ' and ProjectId=' + this.q["ProjectId"];
			}
			else {
				query += 'ProjectId=' + this.q["ProjectId"];
			}
		}

		if (this.q["Type"] != undefined) {
			if (query != '') {
				query += ' and Type=' + this.q["Type"];
			}
			else {
				query += 'Type=' + this.q["Type"];
			}
		}

		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and Title.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';
			}
			else {
				query += 'Title.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';

			}
		}

		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(NewsId) {
		if (NewsId == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				let data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.NewsId);
					}
				});
				if (data.length == 0) {
					this.toastWarning("Chưa chọn mục cần xóa!");
				}
				else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
						data: {
							text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + 'registrationTerm/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												this.toastSuccess("Xóa thành công!");
												this.GetListCommon();
												this.ActionId = undefined;
											}
											else {
												this.toastError(res["meta"]["error_message"]);
											}
										},
										(err) => {
											this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	//Get danh sách chức năng
	GetListCommon() {
		this.http.get(domain + 'api/registrationTerm/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listCommon = res["data"]["Results"];
					this.paging.item_count = res["data"]["RowCount"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenCommonModal(item) {
		this.submitted = false;
		this.Item = new RegistrationTerm();
		this.Item.Content = "";
		if (item == undefined) {
			if (this.q.ProjectId) {
				this.Item.ProjectId = this.q.ProjectId;
			}

			if (this.q.Type) {
				this.Item.Type = this.q.Type;
			}

		}
		else {
			this.Item = JSON.parse(JSON.stringify(item));
		}

		this.modalCommon.show();
	}

	SaveCommon() {
		this.submitted = true;

		let data = Object.assign({}, this.Item);

		if (data.Id == undefined) {
			this.http.post(domain + 'api/registrationTerm', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Thêm mới thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.http.put(domain + 'api/registrationTerm/' + data.Id, data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Cập nhật thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
	}

  ShowTypeNotebook(type) {
    return this.TypeTerm.find(s => s.Id == type).Name || '';
  }

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domain + 'api/registrationTerm/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}


	//Danh sách dự án
	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.projects = res["data"];
          this.projects.unshift({
            Name: "Tất cả",
            ProjectId: 0
          })

					if (this.projects.length == 1) {
						this.q.ProjectId = this.projects[0].ProjectId;
					}
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}


	ShowType(Type) {
		let obj = this.TypeTerm.filter(x => x.Id == Type)[0];
		return obj != undefined ? obj.Name : "";
	}

	openToggleFilter(){
		this.isShowFilter = !this.isShowFilter;
	}

	ShowProjectName(ProjectId) {
		let project = this.projects.find(x => x.ProjectId == ProjectId);

		return project ? project.Name : "Tất cả";
	}
}
