import { CleaningNewService } from './../../../../service/cleaning-new.service';
import { InfoUserAssignedCleaning } from './../../../../data/Model';
import { HeaderService } from './../../../../service/header.service';
import { I18n, CustomDatepickerI18n } from './../../../../service/date-picker-i18n';
import { lstStatusServiceCleaning, lstStatusPayment, TypePayment, RequestServiceUserConfirm, domain, ApiConstant, listPaymentType, StatusCleaning } from '../../../../data/const';
import { CommonService } from './../../../../service/common.service';
import { Component, OnInit, ViewContainerRef, ViewChild, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  cancelBtnLabel = 'Hủy';

  /** A label for the set button */
  setBtnLabel = 'Đặt';
};

@Component({
  selector: 'app-create-request-cleaning',
  templateUrl: './create-request-cleaning.component.html',
  styleUrls: ['./create-request-cleaning.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl }
  ]
})
export class CreateRequestCleaningComponent implements OnInit, OnDestroy {
  @ViewChild('fCreate') public fCreate: NgForm;
  public item: any = {};
  public isCreatePage: boolean = true;

  public isLoading = false;

  public lstProject = [];
  public lstTower = [];
  public lstFloor = [];
  public lstApartment = [];
  public lstRelations = [];
  public lstStatusRequest = lstStatusServiceCleaning;
  public lstStatusPayment = lstStatusPayment;
  public typePayment = TypePayment;
  public statusConfirm = RequestServiceUserConfirm;
  public listPaymentType = listPaymentType;

  public timeSurvey: Date;
  public countDocChecked = 0;
  public oldProcessStatus = 0;
  public oldType = 0;
  public typeUser = 0;
  public messageConfirmStatus = '';
  public dateNow = new Date();
  public minDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };
  public maxDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 4,
    day: this.dateNow.getDate()
  };

  public listCleaningPackage = [];
  public selectedFile: File | null = null;
  public displayDocumentAcceptanceFileSelect = false;
  public selectCleaningPackage = null;
  public selectedStatus: number = null;
  public timeActive: Date;
  public listUsers = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private readonly commonService: CommonService,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly toastr: ToastrService,
    public titleService: Title,
    private readonly cleaningService: CleaningNewService,
    private readonly headerService: HeaderService,
    private location: Location,
    private readonly routerUrl: Router,
    private readonly http: HttpClient,
  ) {
    this.item = {};
    this.item.status = 1;
    this.item.estimatePrice = "0";
    this.item.createdDate = new Date();
    this.item.isRequestCms = true;

    this.lstStatusRequest.map(s => s['disabled'] = false);
  }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Đăng ký dịch vụ vệ sinh");
    this.getListProject();
    this.getRelationShips();
    this.isCreatePage = true;
    this.item.CreatedAt = {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth(),
      day: this.dateNow.getDate()
    };
  }

  ngOnDestroy() {
    this.headerService.clear();
    this.headerService.showWarning(false);
  }

  onSubmit() {
    const data = Object.assign({}, this.item);
    console.log(JSON.stringify(data));
    if (!this.selectCleaningPackage) {
      this.toastWarning("Vui lòng chọn ít nhất 1 gói dịch vụ!");
      return;
    }
    if (this.timeActive) {
      data.StartTime = new Date(this.timeActive).getHours() + ":" + new Date(this.timeActive).getMinutes();
    }
    const formData = new FormData();
    formData.append('PackageId', this.selectCleaningPackage);
    formData.append('Apartment', data.ApartmentId);
    formData.append('ApartmentName', data.ApartmentName);
    formData.append('Project', data.ProjectId);
    formData.append('ProjectName', data.ProjectName);
    formData.append('Tower', data.TowerId);
    formData.append('TowerName', data.TowerName);
    formData.append('Floor', data.FloorId);
    formData.append('FloorName', data.FloorName);
    formData.append('HostResidentId', data.HostResidentId);
    formData.append('HostResidentName', data.HostResidentName);
    formData.append('HostResidentPhone', data.HostResidentPhone);
    formData.append('SubscriberResidentId', data.SubscriberResidentId);
    formData.append('SubscriberResidentName', data.SubscriberResidentName);
    formData.append('SubscriberContactPhone', data.SubscriberContactPhone);
    formData.append('SubscriberRelationshipId', data.SubscriberRelationshipId);
    formData.append('SubscriberRelationshipName', data.SubscriberRelationshipName);
    if (data.Description)
      formData.append('Description', data.Description);
    formData.append('StartDay', data.StartDay);
    formData.append('StartTime', data.StartTime);
    if (data.Status)
      formData.append('Status', data.Status);
    if (data.TotalPrice)
      formData.append('TotalPrice', data.TotalPrice);
    if (data.PaymentMethod)
      formData.append('PaymentMethod', data.PaymentMethod);
    if (this.selectedStatus !== null)
      formData.append('IsPaid', this.selectedStatus as any);
    if (data.Note)
      formData.append('Note', data.Note);
    if (this.selectedFile && data.Status === StatusCleaning.Done) {
      formData.append('acceptanceFiles', this.selectedFile);
    }
    this.isLoading = true;
    this.cleaningService.createCleaningRequest(formData).subscribe(
      (res) => {
        this.toastSuccess("Tạo đăng ký mới thành công");
        this.isLoading = false;
        this.isCreatePage = false;

        this.routerUrl.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.routerUrl.navigate([`/manager-service/cleaning-service`]);
        });
      },
      (err) => {
        this.isLoading = false;
        this.toastWarning(err);
      }
    )
  }
  getListProject() {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.ProjectId = null;
    this.item.TowerId = null;
    this.item.FloorId = null;
    this.item.ApartmentId = null;

    return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
      },
      () => {
        console.log("Error: connect to API");
      }
    )
  }

  getRelationShips() {
    this.http.get(domain + 'api/cms/TypeAttributeItem/CodeAttribute/relationship').subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstRelations = res["data"];
          this.lstRelations.unshift({
            Id: -1,
            Name: 'Chủ hộ'
          });
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  onChangeProject(event) {
    this.item.ProjectId = event;
    this.getListTower();
  }

  onChangeTower(event) {
    if (this.item.ProjectId && event)
      this.getListCleaningPackage(this.item.ProjectId, event);
    this.item.TowerId = event;
    this.getListFloor();
  }

  onChangeFloor(event) {
    if (!this.item.FloorId || this.countDocChecked === 0) {
      this.item.FloorId = event;
      this.getListApartment();
    } else {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
        childComponent: SimpleModalComponent,
        data: {
          text: "Bạn có chắc muốn thay đổi tầng không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',
            onAction: () => {
              this.viewRef.clear();
            }
          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.item.FloorId = event;
              this.getListApartment();

              this.viewRef.clear();
            }
          }
        ],
      });
    }
  }

  getListTower() {
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.TowerId = null;
    this.item.FloorId = null;
    this.item.ApartmentId = null;
    this.item.HostResidentName = '';
    this.item.HostResidentPhone = '';
    this.item.SubscriberResidentId = null;
    this.item.SubscriberResidentName = '';
    this.item.SubscriberContactPhone = '';
    this.item.SubscriberRelationshipId = null;
    this.item.SubscriberRelationshipName = '';

    return this.commonService.getLstTower(this.item.ProjectId).subscribe(
      (res) => {
        this.lstTower = res;
      },
      () => {
        console.log("Error: connect to API");
      }
    )
  }

  getListFloor() {
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.FloorId = null;
    this.item.ApartmentId = null;
    this.item.ApartmentName = null;
    this.item.HostResidentName = '';
    this.item.HostResidentPhone = '';
    this.item.SubscriberResidentId = null;
    this.item.SubscriberResidentName = '';
    this.item.SubscriberContactPhone = '';
    this.item.SubscriberRelationshipId = null;
    this.item.SubscriberRelationshipName = '';

    return this.commonService.getLstFloor(this.item.TowerId).subscribe(
      (res) => {
        this.lstFloor = res;
      },
      () => {
        console.log("Error: connect to API");
      }
    )
  }

  getListApartment() {
    this.lstApartment = [];
    this.item.ApartmentId = null;
    this.item.ApartmentName = null;
    this.item.HostResidentName = '';
    this.item.HostResidentPhone = '';
    this.item.SubscriberResidentId = null;
    this.item.SubscriberResidentName = '';
    this.item.SubscriberContactPhone = '';
    this.item.SubscriberRelationshipId = null;
    this.item.SubscriberRelationshipName = '';

    return this.commonService.getLstApartment(this.item.FloorId).subscribe(
      (res) => {
        for (let i = 0; i < res.length; i++) {
          this.commonService.getPurchaserByApartmentId(res[i]['ApartmentId']).subscribe(
            (lstRs) => {
              if (lstRs) {
                this.lstApartment.push(res[i]);
                this.lstApartment = [...this.lstApartment];
              }
            },
            (err) => {
              this.toastError(err);
            });
        }
      },
      () => {
        console.log("Error: connect to API");
      }
    )
  }

  getPurchaserByApartmentId(value) {
    if (!value) {
      this.item.HostResidentName = '';
      this.item.HostResidentPhone = '';
      this.item.SubscriberResidentId = '';
      this.item.SubscriberResidentName = '';
      this.item.SubscriberContactPhone = '';
      this.item.SubscriberRelationshipId = '';
      this.item.SubscriberRelationshipName = '';
      return;
    }
    this.item.ApartmentId = value.ApartmentId;
    this.item.ApartmentName = value.Name;
    this.item.ProjectId = value.ProjectId;
    this.item.ProjectName = value.ProjectName;
    this.item.TowerId = value.TowerId;
    this.item.TowerName = value.TowerName;
    this.item.FloorId = value.FloorId;
    this.item.FloorName = value.FloorName;

    this.item.HostResidentName = '';
    this.item.HostResidentPhone = '';
    this.item.SubscriberResidentId = '';
    this.item.SubscriberResidentName = '';
    this.item.SubscriberContactPhone = '';
    this.item.SubscriberRelationshipId = '';
    this.item.SubscriberRelationshipName = '';
    
    this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
      (res) => {
        if (res) {
          console.log('res', res);
          this.item.HostResidentId = res.ResidentId;
          this.item.HostResidentName = res.FullName;
          this.item.HostResidentPhone = res.Phone;

          this.item.SubscriberResidentId = res.ResidentId;
          this.item.SubscriberResidentName = res.FullName;
          this.item.SubscriberContactPhone = res.Phone;
          this.item.SubscriberRelationshipId = -1;
          this.item.SubscriberRelationshipName = this.lstRelations.find(s => s.Id == -1).Name;
          this.GetListMemberApartment(this.item.ApartmentId, res.ResidentId);
        } else {
          this.item.HostResidentName = '';
          this.item.HostResidentPhone = '';

          this.item.SubscriberResidentId = '';
          this.item.SubscriberResidentName = '';
          this.item.SubscriberContactPhone = '';
          this.item.SubscriberRelationshipId = '';
          this.item.SubscriberRelationshipName = '';
          this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
          return;
        }
      },
      () => {
        this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
        return;
      }
    )
  }

  onChangeStatus(event: any) {
    this.item.Status = event;
    if (event === StatusCleaning.Done) {
      this.displayDocumentAcceptanceFileSelect = true;
    } else {
      this.displayDocumentAcceptanceFileSelect = false;
    }
  }

  onFileChanged(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type === 'application/pdf') {
        this.selectedFile = file;
      } else {
        this.toastWarning('Biên bản nghiệm thu là file PDF.');
        this.selectedFile = null;
      }
    }
  }

  onSelectFile() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onChangePrice(value) {
    this.item.TotalPrice = value.replace(/[^0-9.-]+/g, '');
  }

  onChangeOmitSpecialTime(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 58) {
      return false;
    }
    return true;
  }

  onChangeUserRequest() {
    if (this.item.SubscriberResidentId) {
      const itemResidentRequest = this.listUsers.find(s => s['ResidentId'] == this.item.SubscriberResidentId);
      this.item.SubscriberResidentId = itemResidentRequest ? itemResidentRequest.ResidentId : '';
      this.item.SubscriberResidentName = itemResidentRequest ? itemResidentRequest.FullName : '';
      this.item.SubscriberContactPhone = itemResidentRequest ? itemResidentRequest.Phone : '';
      this.item.SubscriberRelationshipId = itemResidentRequest ? itemResidentRequest.RelationshipId : null;
      this.item.SubscriberRelationshipName = this.item.SubscriberRelationshipId ? (this.lstRelations.find(s => s.Id == this.item.SubscriberRelationshipId).Name || '') : '';

      if (this.item.SubscriberResidentId == this.item.HostResidentId) {
        this.item.SubscriberRelationshipId = -1;
        this.item.SubscriberRelationshipName = this.lstRelations.find(s => s.Id == this.item.SubscriberRelationshipId).Name;
      }
    }
  }

  onKeyPressPhone(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getListCleaningPackage(projectId, towerId) {
    this.http.get(ApiConstant.getListCleaningPackage + '?page=1&pageSize=-1' +
      '&Project=' + projectId + '&Tower=' + towerId).subscribe(
        (res) => {
          this.listCleaningPackage = res["data"].map(item => {
            return {
              ...item,
            }
          });
          console.log('this.listCleaningPackage', this.listCleaningPackage);
        },
        (err) => {
          console.log("Error: connect to API");
        });
  }

  GetListMemberApartment(apartmentId: number, residentId: number) {
    if (apartmentId) {
      this.commonService.getMemberInApartment(apartmentId, residentId).subscribe(
        (res) => {
          if (res) {
            this.listUsers = res;
          } else {
            this.listUsers = [];
          }
        },
        (err) => {
          this.listUsers = [];
        }
      )
    } else {
      this.listUsers = [];
    }
  }

  returnPage() {
    this.location.back();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
}
