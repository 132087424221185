import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ServiceOrderPlan } from '../../../../../data/Model';
import * as _moment from 'moment';

export const MY_CUSTOM_FORMATS: OwlDateTimeFormats = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-service-tech-plan',
  templateUrl: './service-tech-plan.component.html',
  styleUrls: ['./service-tech-plan.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})
export class ServiceTechPlanComponent implements OnInit {

  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  @Input() listPlans: any;
  public Item: ServiceOrderPlan;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.Item = new ServiceOrderPlan();
   }

  ngOnInit() {
    //console.log(this.itemDetail)
    if (this.itemDetail) {
      this.Item.Name = this.itemDetail["Name"];
      this.Item.DatePlan = this.itemDetail["DatePlan"];
      this.Item.Note = this.itemDetail["Note"];
    }
    else{
      this.Item.Name = "Lần "+(this.listPlans.length + 1);
    }

  }

  onSubmit() {
    if (!this.Item.Name) {
      this.toastWarning("Vui lòng nhập lần gia hạn");
      return;
    }
    if (!this.Item.DatePlan) {
      this.toastWarning("Vui lòng nhập thời gian gia hạn");
      return;
    } 
    let data = Object.assign({}, this.Item);
    data.createdById = parseInt(localStorage.getItem("userId"));
    if (data.DatePlan) {
      let obj = new Date(data.DatePlan);
      data.DatePlan = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + obj.getHours() + ":" + obj.getMinutes() + ":0";
    }
    this.itemCommon.emit(data);
    this.modal.close();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }

}
