import { contractor } from '../../../../data/Model';
import { domain, regexPhoneNumber, ApiConstant, ContractorStatus } from './../../../../data/const';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import * as moment from 'moment';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-contractor-dialog',
    templateUrl: './contractor-dialog.component.html',
    styleUrls: ['./contractor-dialog.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class ContractorDialogComponent implements OnInit {
    @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
    @Input() itemDetail: contractor;
    @Input() RegisterConstructionFormId: number;

    public item: contractor;
    public contractorStatus = ContractorStatus;
    public isLoading = false;
    public statusItem: number;
    public regexPhoneNumber = regexPhoneNumber;
    public minDate: object;
    public maxDate: object;
    public isKSTDKTC = localStorage.getItem("roleCode") == "KSTDKTC" ? true : false;

    constructor(
        public modal: NgbActiveModal,
        private readonly http: HttpClient,
        private readonly toastr: ToastrService,
        private gallery: Gallery
    ) {
        this.item = new contractor();
    }

    ngOnInit() {
        this.statusItem = 0;
        if (this.itemDetail) {
            this.item = this.itemDetail;
            this.statusItem = this.item.ContractorStatus;
        }
        else {
            this.item.RegisterConstructionFormId = this.RegisterConstructionFormId;
            this.item.RegisterDate = new Date();
            this.item.ContractorStatus = 1;
        }

        this.contractorStatus.map((item, index, arr) => {
            this.statusItem > item.Id ? item["disabled"] = true : item["disabled"] = false;
            return item;
        });
    }

    onSubmit() {
        Object.keys(this.item).forEach(k => this.item[k] = typeof this.item[k] == 'string' && this.item[k] ? this.item[k].trim() : this.item[k]);

        if(!this.item.RegisterDate) {
            this.toastWarning("Chưa có thông tin Ngày đăng ký");
            return;
        }

        if(!this.item.ContractorStatus) {
            this.toastWarning("Chưa chọn Trạng thái");
            return;
        }

        if (this.item.Name == "" || this.item.Name == undefined) {
            this.toastWarning("Vui lòng nhập Tên nhà thầu");
            return;
        }

        if(this.item.Name.trim() == "") {
            this.toastWarning("Vui lòng nhập Tên nhà thầu");
            return;
        }

        if (this.item.AuthorizedPersonName == "" || this.item.AuthorizedPersonName == undefined) {
            this.toastWarning("Vui lòng nhập Người đại diện");
            return;
        }

        if(this.item.AuthorizedPersonName.trim() == "") {
            this.toastWarning("Vui lòng nhập Người đại diện");
            return;
        }

        if (this.item.AuthorizedPersonPhone == "" || this.item.AuthorizedPersonPhone == undefined) {
            this.toastWarning("Vui lòng nhập Số điện thoại người đại diện");
            return;
        }

        if (/(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.AuthorizedPersonPhone) == false && /(84[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.AuthorizedPersonPhone) == false) {
            this.toastWarning("Số điện thoại người đại diện không hợp lệ");
            return;
        }

        if(this.item.AuthorizedPersonPhone.trim() == "") {
            this.toastWarning("Vui lòng nhập Số điện thoại người đại diện");
            return;
        }

        if(!this.item.ConstructionDateStart) {
            this.toastWarning("Chưa có thông tin Ngày bắt đầu thi công");
            return;
        }

        if(!this.checkValidate(2)) {
            this.toastWarning("Thông tin Ngày bắt đầu thi công Không hợp lệ!");
            return;
        }

        if(!this.item.ConstructionDateEnd) {
            this.toastWarning("Chưa có thông tin Ngày kết thúc thi công");
            return;
        }

        if(!this.checkValidate(3)) {
            this.toastWarning("Thông tin Ngày kết thúc thi công Không hợp lệ!");
            return;
        }

        if (this.item.ConstructionItem == "" || this.item.ConstructionItem == undefined) {
            this.toastWarning("Vui lòng nhập Hạng mục thi công");
            return;
        }

        if(this.item.ConstructionItem.trim() == "") {
            this.toastWarning("Vui lòng nhập Hạng mục thi công");
            return;
        }

        this.item.RegisterDate = this.item.RegisterDate ?
            new Date(moment(this.item.RegisterDate.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}) : null;

        this.item.ConstructionDateStart = this.item.ConstructionDateStart ?
            new Date(moment(this.item.ConstructionDateStart.toString()).format('YYYY-MM-DD')) : null;

        this.item.ConstructionDateEnd = this.item.ConstructionDateEnd ?
            new Date(moment(this.item.ConstructionDateEnd.toString()).format('YYYY-MM-DD')) : null;

        this.isLoading = true;
        if(!this.item.Id) {
            this.http.post(ApiConstant.CreateContractor, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Tạo nhà thầu mới thành công");
                        this.isLoading = false;

                        this.eventEmitter.emit(true);
                        this.modal.close();
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        }
        else {
            this.http.put(ApiConstant.UpdateContractor + this.item.Id, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Cập nhật nhà thầu thành công");
                        this.isLoading = false;

                        this.eventEmitter.emit(true);
                        this.modal.close();
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        }
    }

    onCheckboxChanged(value) {

    }

    omit_special_char(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return true;
        }
        return false;
    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }

    omit_special_phone(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }

    checkMinMax(event, type) {
        if(event) {
            let currDate = new Date(event);
            if(type == 1) {
                this.minDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
            else {
                this.maxDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
        }
        else {
            if(type == 1) this.minDate = undefined;
            else this.maxDate = undefined;
        }
    }

    checkValidate(cs) {
        if(cs == 1) {
            if (/(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.AuthorizedPersonPhone) == false && /(84[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.AuthorizedPersonPhone) == false) {
                return false;
            }
        }
        else if(cs == 2) {
            if(this.item.ConstructionDateStart) {
                if(moment(this.item.ConstructionDateStart.toString()).isValid() == false) return false;
            }
        }
        else if(cs == 3) {
            if(this.item.ConstructionDateEnd) {
                if(moment(this.item.ConstructionDateEnd.toString()).isValid() == false) return false;
            }
        }
        return true;
    }
}
