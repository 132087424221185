import { NgForm } from '@angular/forms';
import { InfoQuotationDetail } from './../../../../../data/Model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-quotation-item-dialog',
  templateUrl: './add-quotation-item-dialog.component.html',
  styleUrls: ['./add-quotation-item-dialog.component.scss']
})
export class AddQuotationItemDialogComponent implements OnInit {
  @ViewChild('fItemQuotation') public fItemQuotation: NgForm;
  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  @Input() isC_One: boolean = false;
  public Item: InfoQuotationDetail;
  public listEmployees = [];
  public listPositions = [];

  public httpOptions: any;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.Item = new InfoQuotationDetail();
   }

  ngOnInit() {
    //console.log(this.itemDetail)
    if (this.itemDetail) {
      this.Item = {...this.itemDetail};
    }
  }

  ChangeTotal() {
    //console.log(this.itemDetail)
    if (this.Item.quantily && this.Item.price) {
      this.Item.totalPrice = (this.Item.quantily * parseInt(this.Item.price)).toFixed(2);
    }
    else this.Item.totalPrice = "0.00";

  }

  onSubmit() {
    this.itemCommon.emit({
      data: this.Item,
      isCreate: this.itemDetail ? false : true
    });
    this.modal.close();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }


  omit_special_char(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return true;
    }
    return false;
  }

  onChangePrice(value) {
    this.Item.price = Number(value.replace(/[^0-9.-]+/g, '')).toString();
  }
}
