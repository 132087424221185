import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { DateTimeAdapter, OwlDateTimeComponent, OwlDateTimeFormats, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { InterceptorService } from 'ng2-interceptors';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiConstant, domainApi, LstStatusRequestGroupFilter, lstTranferConverBy, lstTransferType, statusCardRequestGroup, transpostBy, transpostType, transStatuspostRequestGroup } from '../../data/const';
import { Paging, QueryFilter } from '../../data/dt';
import { CommonService } from '../../service/common.service';
import { ResidentManagerService } from '../../service/resident-manager.service';
import { saveFile } from '../../service/file-download-helper';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';

export const MY_CUSTOM_FORMATS: OwlDateTimeFormats = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-transfer-goods',
  templateUrl: './transfer-goods.component.html',
  styleUrls: ['./transfer-goods.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})
export class TransferGoodsComponent implements OnInit {
  @ViewChild("toggleFilter") toggleFilter;
  @ViewChild("toggleFilterButton") toggleFilterButton;

  public date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  public q: QueryFilter;
  public lstStatus = LstStatusRequestGroupFilter;
  public lstFloors = [];
  public lstProjects = [];
  public lstTowers = [];
  public lstApartments = [];
  public lstTypeRequire = lstTransferType;
  public lstTransferType = lstTransferType;
  public lstResidentService = [];
  public listCommon = [];
  public paging: Paging;
  public isShowFilter = false;
  public isToggleTypeCreate = false;
  public CheckAll: boolean;
  public httpOptions: any;
  public type = statusCardRequestGroup;
  public status = transStatuspostRequestGroup;
  public transpostType = transpostType;
  public listCheck = [];
  public transpostBys = transpostBy;
  public transpostStatus = transStatuspostRequestGroup;
  public lstTranferConverBy = lstTranferConverBy;
  public messageError = '';
  public dateNow = new Date();
  public moveIn=true;
  public moveOut = true;
  public transpostBy = null;
  public isCheckboxCancel = false;
  public isSecurity = false;
  public isProcessingExport = false;
  public maxDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };

  constructor(
    public viewRef: ViewContainerRef,
    private modalService: NgbModal,
    public titleService: Title,
    private renderer: Renderer2,
    private readonly residentService: ResidentManagerService,
    private readonly commonService: CommonService,
    public modalDialogService: ModalDialogService,
    public router: Router,
    public toastr: ToastrService,
    private readonly http: HttpClient,
    private httpDownload: InterceptorService) {
    this.q = new QueryFilter();
    this.q.txtSearch = "";
    this.q.StatusProcessId = null;
    this.q.TypeRequireId = null;
    this.q.DateTimeStart = null;
    this.q.DateTimeEnd = null;
    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 15;
    this.paging.query = "1=1";
    this.paging.order_by = 'rq.UpdatedAt Desc';

    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }

    // this.renderer.listen('window', 'click',(e:Event)=> {
    //   if(e.target !== this.toggleFilterButton.nativeElement && e.target !== this.toggleFilter.nativeElement){
    //     this.isShowFilter = false;
    //   }
    // });
  }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Quản lý đăng ký dịch vụ cư dân");
    this.q.StatusProcessId = null;
    const userCode = localStorage.getItem("roleCode");
    if (userCode === 'ANDKTC') {
      this.isSecurity = true;
    } else {
      this.isSecurity = false;
    }

    this.GetListCommon();
    this.GetListProject();
  }

  getLstResidentService() {
    this.residentService.getByPage(this.q, this.paging, null, null, null, false).subscribe(res => {
      if (res['meta']['error_code'] == 200) {
        this.lstResidentService = res['data'];
      }
    },
      (err) => {
        console.log(err);
      }
    )
  }

  GetListProject() {
    return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProjects = res;

        if (this.lstProjects.length == 1) {
          this.q.ProjectId = this.lstProjects[0].ProjectId;
          this.GetListTower(1);
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  //Danh sách tòa nhà
  GetListTower(OpenUpdate) {
    this.lstTowers = [];
    if (!OpenUpdate) this.q.TowerId = undefined;
    if (this.q.ProjectId) {
      let query = "ProjectId=" + this.q.ProjectId;
      this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstTowers = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.GetListFloor(OpenUpdate);
  }

  //Danh sách tầng
  GetListFloor(OpenUpdate) {
    this.lstFloors = [];
    if (!OpenUpdate) this.q.FloorId = undefined;
    if (this.q.TowerId) {
      let query = "TowerId=" + this.q.TowerId;
      this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstFloors = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.GetListApartment(OpenUpdate);
  }

  //Danh sách tầng
  GetListApartment(OpenUpdate) {
    this.lstApartments = [];
    if (!OpenUpdate) this.q.ApartmentId = undefined;
    if (this.q.FloorId) {
      let query = "FloorId=" + this.q.FloorId;
      this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstApartments = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

  }

  detail(item) {
    if (item) {
      if (item.typeTranspost == transpostType.MoveIn) {
        this.router.navigate(['/utilities/transpost-request/update/in', item.id]);
      } else if (item.typeTranspost == transpostType.MoveOut) {
        this.router.navigate(['/utilities/transpost-request/update/out', item.id]);
      }
    }
  }

  delete(id) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      settings: {
        footerClass: 'footer-dialog modal-footer',
      },
      childComponent: SimpleModalComponent,
      data: {
        text: "Bạn chắc muốn xóa yêu cầu đăng ký này không?"
      },
      actionButtons: [
        {
          text: 'Hủy',
          buttonClass: 'btn btn-default',
        },
        {
          text: 'Xác nhận',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.http.delete(ApiConstant.DeleteTranspotRequest + id, this.httpOptions).subscribe(
              (res) => {
                if (res["meta"]["error_code"] == 200) {
                  this.GetListCommon();
                  this.viewRef.clear();
                  this.toastSuccess("Xóa thành công!");
                  this.listCheck = [];
                  this.CheckAll = false;
                }
                else {
                  this.toastError(res["meta"]["error_message"]);
                }
              },
              (err) => {
                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
              }
            );
          }
        }
      ],
    });
  }

  openToggleFilter() {
    this.isShowFilter = !this.isShowFilter;
  }


  openToggleTypeCreate() {
    this.isToggleTypeCreate = !this.isToggleTypeCreate;
  }

  // openModal() {
  //   const dialofRefCarCard = this.modalService.open(ItemDialogComponent, { size: 'lg' });
  // }

  QueryChanged() {
    let query = '';
    console.log(this.q.txtSearch);
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      this.paging.search = this.q.txtSearch;
    }

    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;

    this.GetListCommon();
  }

  GetListCommon() {
    this.messageError = '';
    if(this.moveIn == true && this.moveOut==false){
      this.q.TypeRequireId = 1;
    }else if(this.moveIn ==false && this.moveOut==true){
      this.q.TypeRequireId = 2;
    }else{
      this.q.TypeRequireId = null;
    }
    this.CheckAll = false;
    // if (this.q.Type != undefined)
    // 	params = params + "&type=" + this.q.Type;
    var fromDate: any = null;
    var toDate: any = null;
    if (this.q.DateTimeStart)
      fromDate = new Date(this.q.DateTimeStart).getFullYear() + "/" + (new Date(this.q.DateTimeStart).getMonth() + 1) + "/" + new Date(this.q.DateTimeStart).getDate();
    if (this.q.DateTimeEnd)
      toDate = new Date(this.q.DateTimeEnd).getFullYear() + "/" + (new Date(this.q.DateTimeEnd).getMonth() + 1) + "/" + new Date(this.q.DateTimeEnd).getDate() + " 23:59:00";
    this.paging.search = "";
    if (this.q.txtSearch != undefined)
      this.paging.search = this.q.txtSearch;
    this.http.get(ApiConstant.GetPageTranspotRequest + '?currentPage=' + this.paging.page +
      '&pageSize=' + this.paging.page_size +
      (this.q.txtSearch ? '&keyword=' + this.q.txtSearch : '') +
      '&projectId=' + (this.q.ProjectId ? this.q.ProjectId : "") +
      '&towerId=' + (this.q.TowerId ? this.q.TowerId : "") +
      '&floorId=' + (this.q.FloorId ? this.q.FloorId : "") +
      '&apartmentId=' + (this.q.ApartmentId ? this.q.ApartmentId : "") +
      '&processStatus=' + (this.q.StatusProcessId ? this.q.StatusProcessId : -1) +
      '&typeTranspost=' + (this.q.TypeRequireId ? this.q.TypeRequireId : -1) +
      '&transpostBy=' + (this.transpostBy != null && this.transpostBy >= 0 ? this.transpostBy : -1) +
       (this.paging.order_by ? '&orderBy='+this.paging.order_by : '') +
      (fromDate ? '&FromDate=' + fromDate : '') +
      (toDate ? '&ToDate=' + toDate : '') +
      '&isCancel=' + this.isCheckboxCancel,
      this.httpOptions)
      .subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            if (res["data"]["results"].length) {
              this.listCommon = res["data"]["results"];

              var count = 0;
              this.listCommon.forEach(item => {
                if (this.listCheck.indexOf(item.id) != -1) {
                  count++;
                }
              });
              if (count == this.listCommon.length && count > 0) {
                this.CheckAll = true;
              }
              this.paging.item_count = res["data"]["rowCount"];
            } else {
              this.listCommon = [];
              this.paging.item_count = 0;
              this.messageError = 'Không có dữ liệu';
            }
          }
        },
        (err) => {
          this.listCommon = [];
          this.paging.item_count = 0;
          this.messageError = 'Không có dữ liệu';
          console.log("Error: connect to API");
        });
  }

  PageChanged(event) {
    this.paging.page = event.page;
    this.GetListCommon();
  }

  SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

  GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

  QueryChangeFloor() {
    this.q["ApartmentId"] = undefined;
    this.lstApartments = [];
    if (this.q["FloorId"]) {
      let query = "FloorId=" + this.q["FloorId"];
      this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstApartments = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }
  }

  QueryChangeProject() {
    this.q["TowerId"] = undefined;
    this.lstTowers = [];
    if (this.q["ProjectId"]) {
      let query = "ProjectId=" + this.q["ProjectId"];
      this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstTowers = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.QueryChangeTower();
  }

  QueryChangeTower() {
    this.q["FloorId"] = undefined;
    this.lstFloors = [];
    if (this.q["TowerId"]) {
      let query = "TowerId=" + this.q["TowerId"];
      this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstFloors = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.QueryChangeFloor();
  }

  CheckActionTable(event) {
    var listCk = [...this.listCheck]
    if (event == undefined) {
      this.listCommon.forEach(item => {
        if (this.CheckAll) {
          if (listCk.indexOf(item.id) == -1 && item.processStatus < this.status.UnApproval) {
            listCk.push(item.id);
          }
        } else {
          if (listCk.indexOf(item.id) != -1) {
            listCk.splice(listCk.indexOf(item.id), 1);
          }
        }
      });
    }
    else {
      if (listCk.indexOf(event) == -1) {
        listCk.push(event);
        var count = 0;
        this.listCommon.forEach(item => {
          if (listCk.indexOf(item.id) != -1) {
            count++;
          }
        });
        if (count == this.listCommon.length) {
          this.CheckAll = true;
        }
      } else if (listCk.indexOf(event) != -1) {
        listCk.splice(listCk.indexOf(event), 1);
        this.CheckAll = false;
      }
    }
    this.listCheck = listCk;
  }

  ActionTableFunc() {
    let data = this.listCheck;
    if (data.length == 0) {
      this.toastWarning("Chưa chọn mục cần xóa!");
    }
    else {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        childComponent: SimpleModalComponent,
        data: {
          text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
        },
        actionButtons: [
          {
            text: 'Đồng ý',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.http.put(ApiConstant.DeleteTranspotRequests, data, this.httpOptions).subscribe(
                (res) => {
                  if (res["meta"]["error_code"] == 200) {
                    this.toastSuccess("Xóa thành công!");
                    this.GetListCommon();
                    this.listCheck = [];
                    this.CheckAll = false;
                  }
                  else {
                    this.toastError(res["meta"]["error_message"]);
                  }
                },
                (err) => {
                  this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }
              );
              this.viewRef.clear();
            }
          },
          {
            text: 'Đóng',
            buttonClass: 'btn btn-default',
          }
        ],
      });
    }
  }

  exportExcel(){
    this.isProcessingExport = true;

    if(this.moveIn == true && this.moveOut==false){
      this.q.TypeRequireId = 1;
    }else if(this.moveIn ==false && this.moveOut==true){
      this.q.TypeRequireId = 2;
    }else{
      this.q.TypeRequireId = null;
    }
    this.CheckAll = false;
    // if (this.q.Type != undefined)
    // 	params = params + "&type=" + this.q.Type;
    var fromDate: any = null;
    var toDate: any = null;
    if (this.q.DateTimeStart)
      fromDate = new Date(this.q.DateTimeStart).getFullYear() + "/" + (new Date(this.q.DateTimeStart).getMonth() + 1) + "/" + new Date(this.q.DateTimeStart).getDate();
    if (this.q.DateTimeEnd)
      toDate = new Date(this.q.DateTimeEnd).getFullYear() + "/" + (new Date(this.q.DateTimeEnd).getMonth() + 1) + "/" + new Date(this.q.DateTimeEnd).getDate() + " 23:59:00";
    this.paging.search = "";
    if (this.q.txtSearch != undefined)
      this.paging.search = this.q.txtSearch;

    let url = `${ApiConstant.GetDataExportExcelTranspotRequests}${'?currentPage=' + this.paging.page +
    '&pageSize=' + this.paging.page_size +
    (this.q.txtSearch ? '&keyword=' + this.q.txtSearch : '') +
    '&projectId=' + (this.q.ProjectId ? this.q.ProjectId : "") +
    '&towerId=' + (this.q.TowerId ? this.q.TowerId : "") +
    '&floorId=' + (this.q.FloorId ? this.q.FloorId : "") +
    '&apartmentId=' + (this.q.ApartmentId ? this.q.ApartmentId : "") +
    '&processStatus=' + (this.q.StatusProcessId ? this.q.StatusProcessId : -1) +
    '&typeTranspost=' + (this.q.TypeRequireId ? this.q.TypeRequireId : -1) +
     (this.paging.order_by ? '&orderBy='+this.paging.order_by : '') +
    (fromDate ? '&FromDate=' + fromDate : '') +
    (toDate ? '&ToDate=' + toDate : '') +
    '&transpostBy=' + (this.transpostBy != null && this.transpostBy >= 0 ? this.transpostBy : -1) +
    '&isCancel=' + this.isCheckboxCancel}`;

    const options = new RequestOptions({
      headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      responseType: ResponseContentType.Blob,
    });

    this.httpDownload.get(url, options).subscribe(res => {
        const fileName = "ds-dang-ky-chuyen-hang-hoa-vao-ra.xlsx";
        saveFile(res.blob(), fileName);
        this.isProcessingExport = false;
    });
  }

  //Toast cảnh báo
  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
}
