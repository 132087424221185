import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { RequireSupport, Attactment, RequireSupportHistory, Conversation } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi, TypeRequireSupportStatus, patternPhone, TypeResident, TypeRequireSupportHistory, TypeAttribute_RequireSupport, domainFile } from '../../../data/const';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DotsConfig, GalleryService, Image, PreviewConfig, PlainGalleryConfig, LineLayout, PlainGalleryStrategy } from '@ks89/angular-modal-gallery';
import { FirestoreService } from '../../../service/firestore.service';
import { Title } from "@angular/platform-browser";
import { Gallery } from 'angular-gallery';

//Định dạng ngày h trên Modal
export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
	selector: 'app-require-support',
	templateUrl: './require-support.component.html',
	styleUrls: ['./require-support.component.scss'],
	providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class RequireSupportComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('modalHandle') public modalHandle: ModalDirective;

	@ViewChild('modalChat') public modalChat: ModalDirective;
	@ViewChild('f') public form: NgForm;
	@ViewChild('fHandle') public formHandle: NgForm;

	@ViewChild('file') file: ElementRef;
	@ViewChild('fileHandle') fileHandle: ElementRef;
	@ViewChild('Chat') Chat: ElementRef;

	@ViewChild('modalHistory') public modalHistory: ModalDirective;

	public paging: Paging;
	public q: QueryFilter;

	public isShowFilter = false;
	public listCommon = [];
	public listChat = [];
	public projects = [];
	public towers = [];
	public floors = [];
	public apartments = [];
	public residents = [];

	public filterTowers = [];
	public filterFloors = [];
	public filterApartments = [];
	public filterResidents = [];


	public Item: RequireSupport;
	public ItemHandle: RequireSupportHistory;
	public ItemChat: Conversation;

	public httpOptions: any;

	public submitted = false;
	public submittedHandle = false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	public domainImage = domainImage;
	public domainFile = domainFile;
	public patternPhone = patternPhone;

	public listTypeRequire = [];

	public TypeRequireSupportStatus = TypeRequireSupportStatus.filter(x => x.Hidden != true);
	public TypeRequireSupportStatusFilter = TypeRequireSupportStatus;

	public TypeRequireSupportHistoryFilter = TypeRequireSupportHistory;
	public TypeRequireSupportHistory = [];

	public typeResident = TypeResident;

	public listDepartmentAssigned = [];
	public listEmployeeAssigned = [];

	public conversations = [];

	plainGalleryRow: PlainGalleryConfig = {
		strategy: PlainGalleryStrategy.ROW,
		layout: new LineLayout({ width: '30px', height: '30px' }, { length: 1, wrap: true }, 'flex-start')
	};

	public UserId: any;
	public TypeUserId: number; // admin=1, bql=2, nhan vien=3

	public listHistory = [];

	//Danh sách phòng ban trên bộ lọc ăn với dự án
	public departments = [];

	public id: any;

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService,
		public viewRef: ViewContainerRef, public toastr: ToastrService,
        private gallery: Gallery,
		public SharedService: SharedService, public router: Router, public route: ActivatedRoute,
		public firestore: FirestoreService, public titleService: Title) {
		this.Item = new RequireSupport();
		this.ItemChat = new Conversation();
		this.ItemHandle = new RequireSupportHistory();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "CreatedAt Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}

		this.UserId = localStorage.getItem("userId");
		this.TypeUserId = parseInt(localStorage.getItem("type"));

		if (this.TypeUserId == 2) {
			// this.TypeRequireSupportHistory = TypeRequireSupportHistory.filter(x => x.Hidden != true && x.Id == 1);
			this.TypeRequireSupportHistory = TypeRequireSupportHistory.filter(x => x.Hidden != true);
		}
		else {
			this.TypeRequireSupportHistory = TypeRequireSupportHistory.filter(x => x.Hidden != true);
		}
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Danh sách yêu cầu hỗ trợ");
		// this.q.StatusProcessId = 1;
		// this.paging.query = "StatusProcessId=" + this.q.StatusProcessId;
			
		this.id = this.route.snapshot.paramMap.get('id');

		if (this.id != undefined && this.id != "") {
			this.paging.query = 'Id.Equals(Guid("' + this.id + '"))';
			this.q.StatusProcessId = undefined;
		}

		// this.GetListCommon();
		this.GetListProject();
		this.GetListTypeRequire();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = '';
		
		if (this.q["ProjectId"] != undefined) {
			if (query != '') {
				query += ' and ProjectId=' + this.q["ProjectId"];
			}
			else {
				query += 'ProjectId=' + this.q["ProjectId"];
			}
		}

		if (this.q["TowerId"] != undefined) {
			if (query != '') {
				query += ' and TowerId=' + this.q["TowerId"];
			}
			else {
				query += 'TowerId=' + this.q["TowerId"];
			}
		}

		if (this.q["FloorId"] != undefined) {
			if (query != '') {
				query += ' and FloorId=' + this.q["FloorId"];
			}
			else {
				query += 'FloorId=' + this.q["FloorId"];
			}
		}

		if (this.q["ApartmentId"] != undefined) {
			if (query != '') {
				query += ' and ApartmentId=' + this.q["ApartmentId"];
			}
			else {
				query += 'ApartmentId=' + this.q["ApartmentId"];
			}
		}

		if (this.q["ResidentId"] != undefined) {
			if (query != '') {
				query += ' and ResidentId=' + this.q["ResidentId"];
			}
			else {
				query += 'ResidentId=' + this.q["ResidentId"];
			}
		}

		if (this.q["TypeRequireId"] != undefined) {
			if (query != '') {
				query += ' and TypeRequireId=' + this.q["TypeRequireId"];
			}
			else {
				query += 'TypeRequireId=' + this.q["TypeRequireId"];
			}
		}

		if (this.q["StatusProcessId"] != undefined) {
			if (query != '') {
				query += ' and StatusProcessId=' + this.q["StatusProcessId"];
			}
			else {
				query += 'StatusProcessId=' + this.q["StatusProcessId"];
			}
		}
		
		// if (this.q["DateTimeRange"] != undefined) {
		// 	if (this.q["DateTimeRange"][0] && this.q["DateTimeRange"][1]) {
		// 		let StartDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][0].add(7, 'hours').toISOString());
		// 		let EndDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][1].add(7, 'hours').toISOString());
		// 		if (query != '') {
		// 			query += ' and InitedAt >= DateTime(' + StartDate[0] + ',' + StartDate[1] + ',' + StartDate[2] + ',0,0,0) and InitedAt <= DateTime(' + EndDate[0] + ',' + EndDate[1] + ',' + EndDate[2] + ',23,59,59)';
		// 		}
		// 		else {
		// 			query += 'InitedAt >= DateTime(' + StartDate[0] + ',' + StartDate[1] + ',' + StartDate[2] + ',0,0,0) and InitedAt <= DateTime(' + EndDate[0] + ',' + EndDate[1] + ',' + EndDate[2] + ',23,59,59)';
		// 		}
		// 	}
		// }

		if (this.q["DateTimeStart"] != undefined) {
			let obj = new Date(this.q["DateTimeStart"]);
			if (query != '')
				query += ' and InitedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
			else
				query += 'InitedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
		}

		if (this.q["DateTimeEnd"] != undefined) {
			let obj = new Date(this.q["DateTimeEnd"]);
			if (query != '')
				query += ' and InitedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',23,59,59)';
			else
				query += 'InitedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',23,59,59)';
		}

		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and Title.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';
			}
			else {
				query += 'Title.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';

			}
		}


		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(Id) {
		if (Id == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				if(item.StatusProcessId == 1)
					item.Action = CheckAll;
			});
		}
		else {
			let deleteCurr = this.listCommon.filter(x => x.Id == Id)[0];
			if(!deleteCurr) return;
			if(deleteCurr.StatusProcessId != 1) return;

			let CheckAll = true;
			let arrCanDelete = this.listCommon.filter(x => x.StatusProcessId == 1);
			for (let i = 0; i < arrCanDelete.length; i++) {
				if (!arrCanDelete[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				let data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.Id);
					}
				});
				if (data.length == 0) {
					this.toastWarning("Chưa chọn mục cần xóa!");
				}
				else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
						data: {
							text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + '/RequireSupport/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												this.toastSuccess("Xóa thành công!");
												this.GetListCommon();
												this.ActionId = undefined;
											}
											else {
												this.toastError(res["meta"]["error_message"]);
											}
										},
										(err) => {
											this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	//Get danh sách
	GetListCommon() {
		let DepartmentId = this.q.DepartmentId ? this.q.DepartmentId : -1;
		this.http.get(domainApi + '/RequireSupport/GetByPageVer2/' + DepartmentId + '?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listCommon = res["data"];
					this.paging.item_count = res["metadata"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenCommonModal(item) {
		this.file.nativeElement.value = "";
		this.submitted = false;
		this.Item = new RequireSupport();

		//Reset biến xử lý yêu cầu hỗ trợ
		this.listDepartmentAssigned = [];
		this.listEmployeeAssigned = [];
		this.ItemHandle = new RequireSupportHistory();
		// this.ItemHandle.RequireSupportId = item.RequireSupportId;
		// this.ItemHandle.TowerId = item.TowerId;



		if (item == undefined) {
			if (this.q["ProjectId"]) {
				this.Item.ProjectId = this.q["ProjectId"];
			}

			if (this.q["TowerId"]) {
				this.Item.TowerId = this.q["TowerId"];
			}

			if (this.q["FloorId"]) {
				this.Item.FloorId = this.q["FloorId"];
			}

			if (this.q["ApartmentId"]) {
				this.Item.ApartmentId = this.q["ApartmentId"];
			}

			if (this.q["ResidentId"]) {
				this.Item.ResidentId = this.q["ResidentId"];
			}

			this.Item.StatusProcessId = 1;
		}
		else {
			this.Item = Object.assign(this.Item, item);
		}

		this.GetListTower(true);

		this.modalCommon.show();
	}

	SaveCommon() {
		this.submitted = true;

		let data = Object.assign({}, this.Item);
		data.UserId = parseInt(localStorage.getItem("userId"));
		if (data.InitedAt) {
			let obj = new Date(data.InitedAt);
			data.InitedAt = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " 0:0:0";
		}

		let dataHandle = Object.assign({}, this.ItemHandle);
		if (dataHandle) {
			dataHandle.StatusProcessId = dataHandle.Type == 1 ? 2 : dataHandle.StatusProcessId;
			if (dataHandle.CompleteTime) {
				let obj = new Date(dataHandle.CompleteTime);
				dataHandle.CompleteTime = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " 0:0:0";
				if (dataHandle.CompleteTime != undefined) {
					let obj1 = new Date(dataHandle.CompleteTime);
					dataHandle.CompleteTime = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + obj1.getHours() + ":" + obj1.getMinutes() + ":0";
				}
			}

			data.RequireSupportHistoryDTO = dataHandle;
		}


		if (data.Id == undefined) {
			this.http.post(domainApi + '/RequireSupport/PostData', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Thêm mới thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.http.put(domainApi + '/RequireSupport/' + data.Id, data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Cập nhật thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/RequireSupport/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	//Danh sách dự án
	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.projects = res["data"];
					if (this.projects.length == 1 && (this.id == undefined || this.id == "")) {
						this.q.ProjectId = this.projects[0].ProjectId;
						this.QueryChangeProject();
						this.GetDepartmentFilter();
					}
					else {
						this.GetListCommon();
					}
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	//Danh sách tòa nhà
	GetListTower(OpenUpdate) {
		this.towers = [];
		if (!OpenUpdate) this.Item.TowerId = undefined;
		if (this.Item.ProjectId) {
			let query = "ProjectId=" + this.Item.ProjectId;
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.towers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListFloor(OpenUpdate);
	}

	//Danh sách tầng
	GetListFloor(OpenUpdate) {
		this.floors = [];
		if (!OpenUpdate) this.Item.FloorId = undefined;
		if (this.Item.TowerId) {
			let query = "TowerId=" + this.Item.TowerId;
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.floors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListApartment(OpenUpdate);
	}

	//Danh sách căn hộ
	GetListApartment(OpenUpdate) {
		this.apartments = [];
		if (!OpenUpdate) this.Item.ApartmentId = undefined;
		if (this.Item.FloorId) {
			let query = "FloorId=" + this.Item.FloorId;
			this.http.get(domainApi + '/apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.apartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		// this.GetListResident(OpenUpdate);
	}

	//Danh sách căn hộ
	GetListResident(OpenUpdate) {
		this.residents = [];
		if (!OpenUpdate) this.Item.ResidentId = undefined;
		if (this.Item.ApartmentId) {
			let query = "&ApartmentId=" + this.Item.ApartmentId;
			this.http.get(domainApi + '/resident/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ResidentId,FullName,Phone' + query, this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.residents = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	QueryChangeProject() {
		this.q["TowerId"] = undefined;
		this.filterTowers = [];
		if (this.q["ProjectId"]) {
			let query = "ProjectId=" + this.q["ProjectId"];
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterTowers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeTower();
	}

	QueryChangeTower() {
		this.q["FloorId"] = undefined;
		this.filterFloors = [];
		if (this.q["TowerId"]) {
			let query = "TowerId=" + this.q["TowerId"];
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterFloors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeFloor();
	}

	QueryChangeFloor() {
		this.q.ApartmentId = undefined;
		this.filterApartments = [];
		if (this.q.FloorId) {
			let query = "FloorId=" + this.q["FloorId"];
			this.http.get(domainApi + '/apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterApartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeApartment();
	}

	QueryChangeApartment() {
		this.q.ResidentId = undefined;
		this.filterResidents = [];
		if (this.q.ApartmentId) {
			let query = "&ApartmentId=" + this.q["ApartmentId"];
			this.http.get(domainApi + '/resident/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ApartmentId,Name' + query, this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterResidents = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	//upload ảnh hiển thị
	upload(files, cs) {
		if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
				if (cs == 1) {
					if (this.Item.images == undefined) this.Item.images = [];

					event.body["data"].forEach(item => {
						let attactment = new Attactment();
						attactment.Url = item;
						attactment.Thumb = item;

						this.Item.images.push(attactment);
					});
					if (this.file) this.file.nativeElement.value = "";
				}
				else if (cs == 2) {
					if (this.ItemHandle.images == undefined) this.ItemHandle.images = [];

					event.body["data"].forEach(item => {
						let attactment = new Attactment();
						attactment.Url = item;
						attactment.Thumb = item;

						this.ItemHandle.images.push(attactment);
					});
					if (this.fileHandle) this.fileHandle.nativeElement.value = "";
				}
			}
		});
	}

	RemoveImage(idx, cs) {
		if (cs == 1)
			this.Item.images.splice(idx, 1);
		else if (cs == 2)
			this.ItemHandle.images.splice(idx, 1);
	}

	//Danh sách các loại yêu cầu hỗ trợ
	GetListTypeRequire() {
		this.http.get(domainApi + '/TypeAttributeItem/codeAttribute/TYPEREQUIREDSUPPORT', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listTypeRequire = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	ChooseResident() {
		if (this.Item.ResidentId != undefined) {
			this.Item.ResidentPhone = this.residents.filter(x => x.ResidentId == this.Item.ResidentId)[0].Phone;
		}
		else this.Item.ResidentPhone = "";
	}

	GoRequireSupportHistory(Id) {
		let query = 'RequireSupportId.Equals(Guid(\" '+ Id + '\"))';
		this.http.get(domainApi + '/RequireSupportHistory/GetByPage?page=1&query=' + query + '&order_by=CreatedAt + asc', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listHistory = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});

		this.modalHistory.show();
	}

	ConvertImage(images) {
		let res = images.map((image, index, images) => {
			return new Image(index, {
				img: domainImage + image.Url
			})
		});

		return res;
	}

	ShowTypeResident(Type) {
		let obj = this.typeResident.filter(x => x.Id == Type)[0];
		return obj != undefined ? obj.Name : "";
	}

	ShowTypeRequire(Type) {
		let obj = this.listTypeRequire.filter(x => x.TypeAttributeItemId == Type)[0]
		return obj != undefined ? obj.Name : "";
	}

	ShowTypeRequireSupportStatus(Type) {
		let obj = this.TypeRequireSupportStatusFilter.filter(x => x.Id == Type)[0]
		return obj != undefined ? obj.Name : "";
	}

	ShowTypeRequireSupportHistory(Type) {
		let obj = this.TypeRequireSupportHistoryFilter.filter(x => x.Id == Type)[0]
		return obj != undefined ? obj.Name : "";
	}

	//chat

	getListChat(id) {
		this.firestore.getConversation(id + "").subscribe((res) => {
			this.conversations = res;
		},
			(err) => {
				console.log("Error: connect to API:" + err);
			});
	}

	OpenChatModal(item) {
		this.conversations = [];
		this.ItemChat = new Conversation();
		this.ItemChat.TargetId = item.Id;
		this.ItemChat.TargetType = 2;
		this.ItemChat.Type = 1;
		this.ItemChat.Title = item.Title;
		this.ItemChat.StatusProcessId = item.StatusProcessId;
		this.getListChat(item.Id);
		this.modalChat.show();
	}

	SaveChat() {
		// console.log("Here!");
		// this.submitted = true;

		let data = Object.assign({}, this.ItemChat);
		data.SendId = parseInt(localStorage.getItem("userId"));
		this.http.post(domainApi + '/conversation/PostData', data, this.httpOptions).subscribe(
			(res) => {
				// this.submitted = false;
				if (res["meta"]["error_code"] == 200) {
					this.ItemChat.Contents = undefined;
					// console.log(res);
					//this.GetListCommon();
					//this.modalChat.hide();
					//this.toastSuccess("Thêm mới thành công!");
					// this.form.resetForm();
				}
				else {
					this.toastError(res["meta"]["error_message"]);
				}
			},
			(err) => {
				// this.submitted = false;
				this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
			}
		);
		//}
	}

	CheckChat(content) {
		this.ItemChat.Contents = content;
	}

	UpdateChat(id, chatId) {
		//this.submitted = true;
		this.ItemChat.ConversationId = id;
		this.ItemChat.SendId = parseInt(localStorage.getItem("userId"));
		this.http.put(domainApi + '/conversation/' + id + '/' + this.ItemChat.TargetId + '/' + chatId, this.ItemChat, this.httpOptions).subscribe(
			(res) => {
				//this.submitted = false;
				if (res["meta"]["error_code"] == 200) {

					console.log(res)
					this.getListChat(this.ItemChat.TargetId);
					this.toastSuccess("Cập nhật thành công!");
					this.form.resetForm();
				}
				else {
					this.toastError(res["meta"]["error_message"]);
				}
			},
			(err) => {
				//this.submitted = false;
				this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
			}
		);
		//}
	}

	DeleteChat(id, chatId) {
		//this.submitted = true;

		this.http.delete(domainApi + '/conversation/' + id + '/' + this.ItemChat.TargetId + '/' + chatId, this.httpOptions).subscribe(
			(res) => {
				//this.submitted = false;
				if (res["meta"]["error_code"] == 200) {

					console.log(res)
					this.getListChat(this.ItemChat.TargetId);
					this.toastSuccess("Thêm mới thành công!");
					this.form.resetForm();
				}
				else {
					this.toastError(res["meta"]["error_message"]);
				}
			},
			(err) => {
				//this.submitted = false;
				this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
			});
		//}
	}

	//Xử lý yêu cầu hỗ trợ
	OpenHandleModal(item) {
		this.listDepartmentAssigned = [];
		this.listEmployeeAssigned = [];
		this.ItemHandle = new RequireSupportHistory();
		// let Type = parseInt(localStorage.getItem("type"));
		this.ItemHandle.RequireSupportId = item.Id;
		this.ItemHandle.TowerId = item.TowerId;
		// this.ItemHandle.Type = Type == 2 ? 1 : (Type == 3 ? 2 : 1);

		let ProjectId = item.ProjectId;
		let TypeRequireSupport = item.TypeRequireId;
		this.GetListDepartmentAssigned(ProjectId, TypeRequireSupport);

		this.modalHandle.show();
	}


	//Lấy danh sách phòng ban theo dự án và loại yêu cầu hỗ trợ
	GetListDepartmentAssigned(ProjectId, TypeRequireSupport) {
		this.listDepartmentAssigned = [];
		if (ProjectId != undefined && TypeRequireSupport != undefined) {
			this.http.get(domainApi + '/Department/GetDepartmentHandle/' + ProjectId + '/' + TypeRequireSupport + '?page=1&query=1=1&order_by=Name+Asc&select=DepartmentId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.listDepartmentAssigned = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	//Lấy danh sách nhân viên theo tòa nhà và Id phòng ban
	GetListEmployeeAssigned() {
		let TowerId = this.ItemHandle.TowerId;
		let DepartmentId = this.ItemHandle.DepartmentAssignedId;

		this.listEmployeeAssigned = [];
		this.ItemHandle.EmployeeAssignedId = undefined;
		if (TowerId != undefined && DepartmentId != undefined) {
			this.http.get(domainApi + '/Employee/GetEmployeeHandle/' + TowerId + '/' + DepartmentId + '?page=1&query=1=1&order_by=FullName+Asc&select=EmployeeId,FullName', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.listEmployeeAssigned = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	SaveHandleRequire() {
		this.submittedHandle = true;

		let data = Object.assign({}, this.ItemHandle);
		data.UserId = parseInt(localStorage.getItem("userId"));
		data.StatusProcessId = data.Type == 1 ? 2 : data.StatusProcessId;
		if (data.CompleteTime) {
			let obj = new Date(data.CompleteTime);
			data.CompleteTime = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + obj.getHours() + ":" + obj.getMinutes() + ":0";
		}

		this.http.post(domainApi + '/RequireSupportHistory/PostData', data, this.httpOptions).subscribe(
			(res) => {
				this.submittedHandle = false;
				if (res["meta"]["error_code"] == 200) {
					this.modalHandle.hide();
					this.toastSuccess("Thêm thành công!");
					this.formHandle.resetForm();
					
					setTimeout(() => { this.GetListCommon() }, 1000);
				}
				else {
					this.toastError(res["meta"]["error_message"]);
				}
			},
			(err) => {
				this.submittedHandle = false;
				this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
			}
		);
	}

	SetListDepartment() {
		this.ItemHandle.DepartmentAssignedId = undefined;
		this.ItemHandle.EmployeeAssignedId = undefined;
		this.listDepartmentAssigned = [];
		this.listEmployeeAssigned = [];
		if (this.Item.ProjectId && this.Item.TypeRequireId) {
			let ProjectId = this.Item.ProjectId;
			let TypeRequireSupport = this.Item.TypeRequireId;
			this.GetListDepartmentAssigned(ProjectId, TypeRequireSupport);
		}
	}

	//Danh sách chat trong yêu cầu này
	GetListConversation(Id) {
		let query = "TargetId=" + Id + "And TargetType=2";
		this.http.get(domainApi + '/Conversation/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.conversations = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	scrollToBottom() {
		//this.Chat.nativeElement.scrollTop = this.Chat.nativeElement.scrollHeight;
	}

	//Thêm file đính kèm vào cuộc hội thoại
	uploadFileChat(files) {
		if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(files.name, file);

		const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadMultiFile', formData, {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {
			}
			else if (event.type === HttpEventType.Response) {
				let ItemChat = new Conversation();
				ItemChat.TargetId = this.ItemChat.TargetId;
				ItemChat.TargetType = 2;
				ItemChat.Type = 3;
				ItemChat.Contents = event.body["data"].toString();
				ItemChat.SendId = parseInt(localStorage.getItem("userId"));

				this.http.post(domainApi + '/conversation/PostData', ItemChat, this.httpOptions).subscribe(
					(res) => {
						if (res["meta"]["error_code"] == 200) {
							this.toastSuccess("Thêm mới thành công!");
						}
						else {
							this.toastError(res["meta"]["error_message"]);
						}
					},
					(err) => {
						this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
					}
				);
			}
		});
	}

	//Thêm file đính kèm vào cuộc hội thoại
	uploadImageChat(files) {
		if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(files.name, file);

		const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {
			}
			else if (event.type === HttpEventType.Response) {
				let ItemChat = new Conversation();
				ItemChat.TargetId = this.ItemChat.TargetId;
				ItemChat.TargetType = 2;
				ItemChat.Type = 2;
				ItemChat.Contents = event.body["data"].toString();
				ItemChat.SendId = parseInt(localStorage.getItem("userId"));

				this.http.post(domainApi + '/conversation/PostData', ItemChat, this.httpOptions).subscribe(
					(res) => {
						if (res["meta"]["error_code"] == 200) {
							this.toastSuccess("Thêm mới thành công!");
						}
						else {
							this.toastError(res["meta"]["error_message"]);
						}
					},
					(err) => {
						this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
					}
				);
			}
		});
	}

	GetDepartmentFilter() {
		this.q.DepartmentId = undefined;
		this.departments = [];

		if (this.q["ProjectId"]) {
			let query = "ProjectId=" + this.q["ProjectId"];
			this.http.get(domainApi + '/department/GetByPage?page=1&query=' + query + '&order_by=Name+Asc&select=DepartmentId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.departments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChanged();
	}

	openToggleFilter(){
		this.isShowFilter = !this.isShowFilter;
	}

	onCheckDisabledCheckbox(status) {
        return status != 1 ? true : false;
    }

	onShowGallery(images, index) {
        let lstImage = [];

        images.forEach(item => {
            lstImage.push({ path: domainImage + item.Url })
        })

        let prop = {
            images: lstImage,
            index: index ? index : 0
        };
        this.gallery.load(prop);
    }
}
