import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _isShowWarning: boolean = false;
  private titleComponent: string = "";

  subject = new Subject();

  showWarning(isShow: boolean) {
    this._isShowWarning = isShow;

    this.subject.next(this._isShowWarning);
  }

  getIsShow() {
    return this._isShowWarning;
  }

  setTitleComponent(titleComponent) {
    this.titleComponent = titleComponent;
  }

  getTitleComponent() {
    return this.titleComponent;
  }

  clear() {
    this._isShowWarning = false;
    this.titleComponent = undefined;
    this.subject.next(this._isShowWarning);
  }
}
