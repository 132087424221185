
import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { User } from '../../../data/Model';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { debug } from 'util';
import { SharedService } from '../../../service/shared.service';
import { domainApi, domainImage, patternPhone, ActionTable, TypeUser } from '../../../data/const';
import { Paging, QueryFilter, UserChangePass } from '../../../data/dt';
import { Md5 } from 'ts-md5/dist/md5';
import { NgForm } from '@angular/forms';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    @ViewChild('UserModal') public userModal: ModalDirective;
    @ViewChild('file') file: ElementRef;
    @ViewChild('ChangePasswordModalUser') public ChangePasswordModalUser: ModalDirective;
    @ViewChild('f') public form: NgForm;
    @ViewChild('fChangePass') public formChangePass: NgForm;

    public paging: Paging;
    public q: QueryFilter;
    public listIssuers = [];
    public listUser = [];
    public listDepartment = [];
    public listCompany = [];
    public listRole = [];
    public listFunc = [];
    public listPosition = [];
    public listTypeUser = TypeUser;
    public ckeConfig: any;
    public Action: any;
    public listProject = [];
    public listTower = [];

    public Item: User;

    public progress: number;
    public message: string;
    public domainImage = domainImage;
    public domainApi = domainApi;

    public httpOptions: any;

    public ChangePasswordUser: UserChangePass;

    public patternPhone = patternPhone;

    public submitted = false;

    public ActionTable = ActionTable;
    public ActionId: number;
    public CheckAll: boolean;
    public roleMax: number;

    public submittedChangePas = false;

    constructor(
        public http: HttpClient,
        public modalDialogService: ModalDialogService,
        public viewRef: ViewContainerRef,
        public toastr: ToastrService,
        public datePipe: DatePipe,
        public common: SharedService, public titleService: Title
    ) {
        this.Item = new User();
        this.ChangePasswordUser = new UserChangePass();

        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 10;
        this.paging.query = "1=1";
        this.paging.order_by = "Id Desc";
        this.paging.item_count = 0;

        this.q = new QueryFilter();
        this.q.txtSearch = "";

        this.Action = {
            View: false,
            Create: false,
            Update: false,
            Delete: false,
            Import: false,
            Export: false,
            Print: false,
            Other: false,
            Menu: false,
        };

        this.roleMax = 99;
        if (localStorage.getItem("roleMax") != undefined)
            this.roleMax = parseInt(localStorage.getItem("roleMax"));

        //console.log(this.roleMax);
        //console.log(localStorage.getItem("roleMax"));
        //this.IsAll = true;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Tài khoản");
        this.GetListUser();
        this.GetListPosition();
        this.GetListDepartment();
        this.GetListRole();
        this.GetListProject();
    }

    //Get danh sách tất cả các đơn vị
    GetListPosition() {
        this.http.get(domainApi + '/position/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listPosition = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    //Get danh sách Department
    GetListDepartment() {
        this.http.get(domainApi + '/department/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listDepartment = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    GetListUser() {
        this.http.get(domainApi + '/userrole/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listUser = res["data"];
                    this.paging.item_count = res["metadata"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    GetListCompany() {
        this.http.get(domainApi + '/company/GetByPage?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listCompany = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    GetListRole() {
        this.http.get(domainApi + '/functionrole/GetByPage?page=1&query=1=1&order_by=&select=RoleId,Name', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listRole = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    GetListProject() {
        this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    //if (this.Item.UserId == undefined) {
                    this.listProject = res["data"];
                    //}
                    //else {
                    //  this.projects = res["data"];
                    //  for (let i = 0; i < this.projects.length; i++) {
                    //    for (let j = 0; j < arr.length; j++) {
                    //      if (this.projects[i].ProjectId == arr[j].TargetId) {
                    //        this.projects[i].Check = true;
                    //        break;
                    //      }
                    //    }
                    //  }

                    //}
                }
            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    GetListTower(item, tower) {
        item.Check = !item.Check;
        console.log(item);
        if (item.Check) {
            let query = "ProjectId=" + item.ProjectId;
            this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=&select=ProjectId,TowerId,Name,', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        let listTower = res["data"];
                        for (let i = 0; i < listTower.length; i++) {
                            let check = true;
                            for (let j = 0; j < this.listTower.length; j++) {
                                if (this.listTower[j].TowerId == listTower[i].TowerId) {
                                    check = false;
                                    this.listTower[j].Check = true;
                                    this.listTower[j].Status = 1;
                                    break;
                                }
                            }
                            if (check) {
                                let tower = {
                                    "ProjectId": item.ProjectId,
                                    "ProjectName": item.Name,
                                    "TowerId": listTower[i].TowerId,
                                    "Name": listTower[i].Name,
                                    "Check": true,
                                    "Status": 1,
                                }
                                this.listTower.push(tower);
                            }
                        }
                        if (this.Item.UserId != undefined && tower != undefined) {
                            for (let i = 0; i < this.listTower.length; i++) {
                                this.listTower[i].Check = false;
                                for (let j = 0; j < tower.length; j++) {
                                    if (this.listTower[i].TowerId == tower[j].TowerId) {
                                        this.listTower[i].Check = true;
                                        break;
                                    }
                                }
                            }
                        }
                        console.log(this.listTower);
                    }
                    else {
                        item.Check = !item.Check;
                    }
                },
                (err) => {
                    item.Check = !item.Check;
                    console.log("Error: connect to API");
                }
            );
        }
        else {
            console.log(this.listTower);
            //let listTower = this.listTower;
            for (let j = 0; j < this.listTower.length; j++) {
                if (this.listTower[j].ProjectId == item.ProjectId) {
                    //const index: number = this.data.indexOf(msg);
                    //if (index !== -1) {
                    //    this.data.splice(index, 1);
                    //}
                    //console.log(j);
                    this.listTower[j].Check = false;
                    this.listTower[j].Status = 99;
                }
            }
        }
    }

    GetResidentOneS() {
        this.http.get(domainApi + '/ServiceCharge/CopyProfileOneS', this.httpOptions).subscribe(
            (res) => {
                //this.listProject = res["data"];
                console.log(res["data"]);

            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    //Chuyển trang
    PageChanged(event) {
        this.paging.page = event.page;
        this.GetListUser();
    }

    //Thông báo
    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }

    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }

    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    QueryChanged() {
        let query = '1=1';

        if (this.q.Type != undefined) {
            query += ' and Type=' + this.q.Type;
        }

        if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
            //if (query != '') {
            query += ' and (UserName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or FullName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
            //}
            //else {
            //    query += '(UserName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or FullName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
            //}
        }

        //if (query == '')
        //    this.paging.query = '1=1';
        //else
        this.paging.query = query;

        this.GetListUser();
    }

    //Mở modal thêm mới
    OpenAddModal() {
        for (let i = 0; i < this.listRole.length; i++) {
            this.listRole[i].Check = false;
        }
        for (let i = 0; i < this.listProject.length; i++) {
            this.listProject[i].Check = false;
        }
        this.listTower = [];
        this.submitted = false;
        this.Item = new User();
        this.Item.IsRoleGroup = true;
        this.file.nativeElement.value = "";
        this.message = undefined;
        this.Action = {
            View: false,
            Create: false,
            Update: false,
            Delete: false,
            Import: false,
            Export: false,
            Print: false,
            Other: false,
            Menu: false,
        };
        this.userModal.show();
    }
    //Thêm mới danh mục trang
    AddUserFunc() {
        this.submitted = true;
        this.Item.TypeThird = 1;
        this.Item.Type = 1;
        this.Item.IsPhoneConfirm = true;
        this.Item.IsEmailConfirm = true;
        this.Item.LanguageId = 1;
        this.Item["listRole"] = [];
        this.Item["listFunction"] = [];
        this.Item["listProject"] = [];
        this.Item["listTower"] = [];

        this.listRole.forEach(item => {
            if (item.Check) {
                this.Item["listRole"].push({ RoleId: item.RoleId, RoleName: item.RoleName });
            }
        });

        //this.Item["listProject"] = this.listProject;
        //this.Item["listTower"] = this.listTower;

        if (this.Item.UserId) {
            this.http.put(domainApi + '/userrole/' + this.Item.UserId, this.Item, this.httpOptions).subscribe(
                (res) => {
                    this.submitted = false;
                    if (res["meta"]["error_code"] == 200) {
                        this.GetListUser();
                        this.userModal.hide();
                        this.toastSuccess("Cập nhật thành công!");
                        this.form.resetForm();
                    }
                    else if (res["meta"]["error_code"] == 2111) {
                        this.toastError("Email đã tồn tại trong hệ thống !");
                    }
                    else {
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (err) => {
                    this.submitted = false;
                    this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }
            );
        }
        else {
            //this.listProject.forEach(item => {
            //    if (item.Check) {
            //        this.Item["listProject"].push({ ProjectId: item.ProjectId, Name: item.Name, Check: true });
            //    }
            //});

            //this.listTower.forEach(item => {
            //    if (item.Check) {
            //        this.Item["listTower"].push({ ProjectId: item.ProjectId, TowerId: item.TowerId, Name: item.Name, Check: true });
            //    }
            //});

            this.http.post(domainApi + '/userrole', this.Item, this.httpOptions).subscribe(
                (res) => {
                    this.submitted = false;
                    if (res["meta"]["error_code"] == 200) {
                        this.GetListUser();
                        this.userModal.hide();
                        this.toastSuccess("Thêm mới thành công!");
                        this.form.resetForm();
                    }
                    else if (res["meta"]["error_code"] == 2111) {
                        this.toastError("Email đã tồn tại trong hệ thống !");
                    }
                    else {
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (err) => {
                    this.submitted = false;
                    this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }
            );
        }
    }

    OpenEditModal(item) {
        this.submitted = false;
        this.Item = new User();
        this.Item = Object.assign(this.Item, item);
        if (this.Item.UserId) {
            let listRole = Object.assign(this.Item["listRole"]);
            console.log(listRole);
            for (let i = 0; i < this.listRole.length; i++) {
                this.listRole[i].Check = false;
            }
            for (let i = 0; i < this.listRole.length; i++) {
                for (let j = 0; j < listRole.length; j++) {
                    if (this.listRole[i].RoleId == listRole[j].RoleId) {
                        this.listRole[i].Check = true;
                        break;
                    }
                }
            }
            //
            //let listProject = Object.assign(this.Item["listProject"]);
            //let listTower = Object.assign(this.Item["listTower"]);
            //for (let i = 0; i < this.listProject.length; i++) {
            //    for (let j = 0; j < listProject.length; j++) {
            //        if (this.listProject[i].ProjectId == listProject[j].ProjectId) {
            //            this.listProject[i].Check = true;
            //            listProject[j].Check = false;
            //            this.GetListTower(listProject[j], listTower);
            //            break;
            //        }
            //    }
            //}
        }
        // this.GetListProject();
        this.file.nativeElement.value = "";
        //this.GetListRole();
        this.userModal.show();
    }

    //Popup xác nhận mở/khóa
    ShowConfirmLock(Id, status) {
        let note = "Bạn có chắc chắn muốn khóa người dùng này?"
        if (status == 98) {
            note = "Bạn có chắc chắn muốn mở khóa người dùng này?"
            status = 1;
        }
        else
            status = 98;
        this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
            data: {
                text: note
            },
            actionButtons: [
                {
                    text: 'Đồng ý',
                    buttonClass: 'btn btn-success',
                    onAction: () => {
                        this.http.put(domainApi + '/user/lockUser/' + Id + '/' + status, null, this.httpOptions).subscribe(
                            (res) => {
                                if (res["meta"]["error_code"] == 200) {
                                    this.GetListUser();
                                    this.viewRef.clear();
                                    if (status == 10)
                                        this.toastSuccess("Khóa tải khoản thành công!");
                                    else
                                        this.toastSuccess("Mở khóa tải khoản thành công!");
                                }
                                else {
                                    this.toastError(res["meta"]["error_message"]);
                                }
                            },
                            (err) => {
                                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                            }
                        );
                    }
                },
                {
                    text: 'Đóng',
                    buttonClass: 'btn btn-default',

                }
            ],
        });
    }

    //Popup xác nhận xóa
    ShowConfirmDelete(Id) {
        this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
            data: {
                text: "Bạn có chắc chắn muốn xóa bản ghi này?"
            },
            actionButtons: [
                {
                    text: 'Đồng ý',
                    buttonClass: 'btn btn-success',
                    onAction: () => {
                        this.http.delete(domainApi + '/userrole/' + Id, this.httpOptions).subscribe(
                            (res) => {
                                if (res["meta"]["error_code"] == 200) {
                                    this.GetListUser();
                                    this.viewRef.clear();
                                    this.toastSuccess("Xóa thành công!");
                                }
                                else {
                                    this.toastError(res["meta"]["error_message"]);
                                }
                            },
                            (err) => {
                                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                            }
                        );
                    }
                },
                {
                    text: 'Đóng',
                    buttonClass: 'btn btn-default',

                }
            ],
        });
    }

    upload(files) {
        if (files.length === 0)
            return;

        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            }),
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
                this.message = event.body["data"].toString();
                this.Item.Avata = this.message;
            }
        });
    }

    GetListFunction(IsNew) {
        this.http.get(domainApi + '/function/listFunction', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listFunc = res["data"];

                    if (IsNew) {
                        this.listFunc.forEach(item => {
                            item.Space = "";
                            item.View = false;
                            item.Create = false;
                            item.Update = false;
                            item.Delete = false;
                            item.Import = false;
                            item.Export = false;
                            item.Print = false;
                            item.Other = false;
                            item.Menu = false;
                            for (var i = 0; i < (item.Level) * 7; i++) {
                                item.Space += "&nbsp;";
                            }
                        })
                    }
                    else {
                        this.changeCell();
                    }
                }
            },
            (err) => {
                console.log("Error: connect to API");
            }
        );
    }

    changeAction(cs) {
        this.listFunc.forEach(item => {
            switch (cs) {
                case 1:
                    item.View = this.Action.View;
                    break;
                case 2:
                    item.Create = this.Action.Create;
                    break;
                case 3:
                    item.Update = this.Action.Update;
                    break;
                case 4:
                    item.Delete = this.Action.Delete;
                    break;
                case 5:
                    item.Import = this.Action.Import;
                    break;
                case 6:
                    item.Export = this.Action.Export;
                    break;
                case 7:
                    item.Print = this.Action.Print;
                    break;
                case 8:
                    item.Other = this.Action.Other;
                    break;
                case 9:
                    item.Menu = this.Action.Menu;
                    break;
                default:
                    break;
            }

            if (item.View && item.Create && item.Update && item.Delete && item.Import && item.Export && item.Print && item.Other && item.Menu) {
                item.Full = true;
            }
            else {
                item.Full = false;
            }

        });
    }

    changeFull(i) {
        if (i != undefined) {
            this.listFunc[i].View = this.listFunc[i].Full;
            this.listFunc[i].Create = this.listFunc[i].Full;
            this.listFunc[i].Update = this.listFunc[i].Full;
            this.listFunc[i].Delete = this.listFunc[i].Full;
            this.listFunc[i].Import = this.listFunc[i].Full;
            this.listFunc[i].Export = this.listFunc[i].Full;
            this.listFunc[i].Print = this.listFunc[i].Full;
            this.listFunc[i].Other = this.listFunc[i].Full;
            this.listFunc[i].Menu = this.listFunc[i].Full;
        }

        if (this.listFunc.filter(l => l.View == false).length > 0) {
            this.Action.View = false;
        }
        else {
            this.Action.View = true;
        }

        if (this.listFunc.filter(l => l.Create == false).length > 0) {
            this.Action.Create = false;
        }
        else {
            this.Action.Create = true;
        }

        if (this.listFunc.filter(l => l.Update == false).length > 0) {
            this.Action.Update = false;
        }
        else {
            this.Action.Update = true;
        }

        if (this.listFunc.filter(l => l.Delete == false).length > 0) {
            this.Action.Delete = false;
        }
        else {
            this.Action.Delete = true;
        }

        if (this.listFunc.filter(l => l.Import == false).length > 0) {
            this.Action.Import = false;
        }
        else {
            this.Action.Import = true;
        }

        if (this.listFunc.filter(l => l.Export == false).length > 0) {
            this.Action.Export = false;
        }
        else {
            this.Action.Export = true;
        }

        if (this.listFunc.filter(l => l.Print == false).length > 0) {
            this.Action.Print = false;
        }
        else {
            this.Action.Print = true;
        }

        if (this.listFunc.filter(l => l.Other == false).length > 0) {
            this.Action.Other = false;
        }
        else {
            this.Action.Other = true;
        }

        if (this.listFunc.filter(l => l.Menu == false).length > 0) {
            this.Action.Menu = false;
        }
        else {
            this.Action.Menu = true;
        }

    }

    changeCell() {
        this.changeAction(10);
        this.changeFull(undefined);
    }

    SortTable(str) {
        let First = "";
        let Last = "";
        if (this.paging.order_by != "") {
            First = this.paging.order_by.split(" ")[0];
            Last = this.paging.order_by.split(" ")[1];
        }

        if (First != str) {
            this.paging.order_by = str + " Desc";
        }
        else {
            Last = Last == "Asc" ? "Desc" : "Asc";
            this.paging.order_by = str + " " + Last;
        }

        this.GetListUser();
    }

    GetClassSortTable(str) {
        if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
            return "sorting";
        }
        else {
            if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
            else return "sorting_asc";
        }
    }

    OpenModalResetPassword(userId) {
        this.submittedChangePas = false;
        this.ChangePasswordUser.PasswordNewE = undefined;
        this.ChangePasswordUser.ConfirmPassword = undefined;
        this.ChangePasswordUser.UserId = userId;
        this.ChangePasswordModalUser.show();
    }

    ChangePassword() {
        this.submittedChangePas = true;

        let obj = Object.assign({}, this.ChangePasswordUser);
        obj.PasswordNew = Md5.hashStr(obj.PasswordNewE).toString();

        this.http.put(domainApi + '/user/adminChangePass/' + obj.UserId, obj, this.httpOptions).subscribe(
            (res) => {
                this.submittedChangePas = false;
                if (res["meta"]["error_code"] == 200) {
                    this.ChangePasswordModalUser.hide();
                    this.toastSuccess("Đổi mật khẩu tài khoản thành công!");
                    this.formChangePass.resetForm();
                }
                else {
                    this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
                }
            },
            (err) => {
                this.submittedChangePas = false;
                this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
            }
        );
    }

    CheckActionTable(UserId) {
        if (UserId == undefined) {
            let CheckAll = this.CheckAll;
            this.listUser.forEach(item => {
                item.Action = CheckAll;
            });
        }
        else {
            let CheckAll = true;
            for (let i = 0; i < this.listUser.length; i++) {
                if (!this.listUser[i].Action) {
                    CheckAll = false;
                    break;
                }
            }

            this.CheckAll = CheckAll == true ? true : false;
        }
    }

    ActionTableFunc() {
        switch (this.ActionId) {
            case 1:
                let data = [];
                this.listUser.forEach(item => {
                    if (item.Action == true) {
                        data.push(item.UserId);
                    }
                });
                if (data.length == 0) {
                    this.toastWarning("Chưa chọn mục cần xóa!");
                }
                else {
                    this.modalDialogService.openDialog(this.viewRef, {
                        title: 'Xác nhận',
                        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
                        data: {
                            text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
                        },
                        actionButtons: [
                            {
                                text: 'Đồng ý',
                                buttonClass: 'btn btn-success',
                                onAction: () => {
                                    this.http.put(domainApi + '/userrole/deletes', data, this.httpOptions).subscribe(
                                        (res) => {
                                            if (res["meta"]["error_code"] == 200) {
                                                this.toastSuccess("Xóa thành công!");
                                                this.GetListUser();
                                                this.ActionId = undefined;
                                            }
                                            else {
                                                this.toastError(res["meta"]["error_message"]);
                                            }
                                        },
                                        (err) => {
                                            this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                                        }
                                    );
                                    this.viewRef.clear();
                                }
                            },
                            {
                                text: 'Đóng',
                                buttonClass: 'btn btn-default',
                            }
                        ],
                    });
                }
                break;
            default:
                break;
        }
    }
}
