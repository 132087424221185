import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Function } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { domainApi, ActionTable } from '../../../data/const';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-function',
  templateUrl: './function.component.html',
  styleUrls: ['./function.component.scss']
})
export class FunctionComponent implements OnInit {
  @ViewChild('modalCommon') public modalCommon: ModalDirective;
  @ViewChild('f') public form: NgForm;

  public paging: Paging;
  public q: QueryFilter;

  public listFunction = [];
  public listFunctionParent = [];

  public Item: Function;

  public httpOptions: any;

  public submitted = false;

  public domainApi = domainApi;
  public ActionTable = ActionTable;
  public ActionId: number;
  public CheckAll: boolean;

  constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
    this.Item = new Function();

    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 10;
    this.paging.query = "1=1";
    this.paging.order_by = "Id Desc";
    this.paging.item_count = 0;

    this.q = new QueryFilter();
    this.q.txtSearch = "";

    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }

  }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Danh sách chức năng");
    this.GetListCommon();
  }

  //Chuyển trang
  PageChanged(event) {
    this.paging.page = event.page;
    this.GetListCommon();
  }
  //Toast cảnh báo
  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
  //
  QueryChanged() {
    let query = '';
    if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
      if (query != '') {
        query += ' and (Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
      }
      else {
        query += '(Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';

      }
    }

    if (query == '')
      this.paging.query = '1=1';
    else
      this.paging.query = query;

    this.GetListCommon();
  }

  SortTable(str) {
    let First = "";
    let Last = "";
    if (this.paging.order_by != "") {
      First = this.paging.order_by.split(" ")[0];
      Last = this.paging.order_by.split(" ")[1];
    }

    if (First != str) {
      this.paging.order_by = str + " Desc";
    }
    else {
      Last = Last == "Asc" ? "Desc" : "Asc";
      this.paging.order_by = str + " " + Last;
    }

    this.GetListCommon();
  }

  GetClassSortTable(str) {
    if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
      return "sorting";
    }
    else {
      if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
      else return "sorting_asc";
    }
  }

  CheckActionTable(FunctionId) {
    if (FunctionId == undefined) {
      let CheckAll = this.CheckAll;
      this.listFunction.forEach(item => {
        item.Action = CheckAll;
      });
    }
    else {
      let CheckAll = true;
      for (let i = 0; i < this.listFunction.length; i++) {
        if (!this.listFunction[i].Action) {
          CheckAll = false;
          break;
        }
      }

      this.CheckAll = CheckAll == true ? true : false;
    }
  }

  ActionTableFunc() {
    switch (this.ActionId) {
      case 1:
        let data = [];
        this.listFunction.forEach(item => {
          if (item.Action == true) {
            data.push(item.FunctionId);
          }
        });
        if (data.length == 0) {
          this.toastWarning("Chưa chọn mục cần xóa!");
        }
        else {
          this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
            data: {
              text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
            },
            actionButtons: [
              {
                text: 'Đồng ý',
                buttonClass: 'btn btn-success',
                onAction: () => {
                  this.http.put(domainApi + '/function/deletes', data, this.httpOptions).subscribe(
                    (res) => {
                      if (res["meta"]["error_code"] == 200) {
                        this.toastSuccess("Xóa thành công!");
                        this.GetListCommon();
                        this.ActionId = undefined;
                      }
                      else {
                        this.toastError(res["meta"]["error_message"]);
                      }
                    },
                    (err) => {
                      this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                    }
                  );
                  this.viewRef.clear();
                }
              },
              {
                text: 'Đóng',
                buttonClass: 'btn btn-default',
              }
            ],
          });
        }
        break;
      default:
        break;
    }
  }

  //Get danh sách chức năng
  GetListCommon() {
    this.http.get(domainApi + '/function/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listFunction = res["data"];
          this.paging.item_count = res["metadata"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  GetListFunctionParent(Id) {
    this.http.get(domainApi + '/function/listFunction', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.listFunctionParent = res["data"];
          this.listFunctionParent.forEach(item => {
            if (item.FunctionId == Id) {
              item.disabled = true;
            }

            item.Space = "";
            for (var i = 0; i < (item.Level) * 7; i++) {
              item.Space += "&nbsp;";
            }
          })
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  OpenCommonModal(item) {
    this.submitted = false;
    this.Item = new Function();
    if (item == undefined) {
      this.GetListFunctionParent(undefined);
    }
    else {
      this.Item = Object.assign(this.Item, item);
      if (this.Item.FunctionParentId == 0) this.Item.FunctionParentId = undefined;
      this.GetListFunctionParent(item.FunctionId);
    }

    this.modalCommon.show();
  }

  SaveCommon() {
    this.submitted = true;

    let data = Object.assign({}, this.Item);
    if (data.FunctionParentId == undefined) {
      data.FunctionParentId = 0;
    }

    if (this.Item.FunctionId == undefined) {
      this.http.post(domainApi + '/function', data, this.httpOptions).subscribe(
        (res) => {
          this.submitted = false;
          if (res["meta"]["error_code"] == 200) {
            this.GetListCommon();
            this.modalCommon.hide();
            this.toastSuccess("Thêm mới thành công!");
            this.form.resetForm();
          }
          else if (res["meta"]["error_code"] == 210) {
            this.toastError(res["meta"]["error_message"]);
          }
          else if (res["meta"]["error_code"] == 211) {
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (err) => {
          this.submitted = false;
          this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
        }
      );
    }
    else {
      this.http.put(domainApi + '/function/' + this.Item.FunctionId, data, this.httpOptions).subscribe(
        (res) => {
          this.submitted = false;
          if (res["meta"]["error_code"] == 200) {
            this.GetListCommon();
            this.modalCommon.hide();
            this.toastSuccess("Cập nhật thành công!");
            this.form.resetForm();
          }
          else if (res["meta"]["error_code"] == 210) {
            this.toastError(res["meta"]["error_message"]);
          }
          else if (res["meta"]["error_code"] == 211) {
            this.toastError(res["meta"]["error_message"]);
          }
        },
        (err) => {
          this.submitted = false;
          this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
        }
      );
    }
  }

  //Popup xác nhận xóa
  ShowConfirmDelete(Id) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
      data: {
        text: "Bạn có chắc chắn muốn xóa mục này?"
      },
      actionButtons: [
        {
          text: 'Đồng ý',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.http.delete(domainApi + '/function/' + Id, this.httpOptions).subscribe(
              (res) => {
                if (res["meta"]["error_code"] == 200) {
                  this.GetListCommon();
                  this.viewRef.clear();
                  this.toastSuccess("Xóa thành công!");
                }
                else {
                  this.toastError(res["meta"]["error_message"]);
                }
              },
              (err) => {
                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
              }
            );
          }
        },
        {
          text: 'Đóng',
          buttonClass: 'btn btn-default',

        }
      ],
    });
  }

}
