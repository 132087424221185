import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { Paging, QueryFilter } from '../../data/dt';
import { FormControl, NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi } from '../../data/const';
import { Title } from "@angular/platform-browser";
import * as _moment from 'moment';
import { Moment } from 'moment';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
    parseInput: 'MM/YYYY',
    fullPickerInput: 'l LT',
    datePickerInput: 'MM/YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};


const moment = (_moment as any).default ? (_moment as any).default : _moment;


@Component({
    selector: 'app-order',
    templateUrl: './log-system.component.html',
    styleUrls: [],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS }
    ]
})
export class LogSystemComponent implements OnInit {
    public paging: Paging;
    public q: QueryFilter;

    public listCommon = [];
    public httpOptions: any;
    public isShowFilter = false;

    constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 10;
        this.paging.query = '1=1';
        this.paging.order_by = "CreatedAt Desc";
        this.paging.item_count = 0;

        this.q = new QueryFilter();
        this.q.txtSearch = "";

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Nhật ký hoạt động");
        this.GetListCommon();
    }

    PageChanged(event) {
        this.paging.page = event.page;
        this.GetListCommon();
    }
    //Toast cảnh báo
    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    QueryChanged() {
        let query = '1=1';

        if (this.q["DateTimeStart"] != undefined) {
			let obj = new Date(this.q["DateTimeStart"]);
			if (query != '')
				query += ' and CreatedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
			else
				query += 'CreatedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
		}

		if (this.q["DateTimeEnd"] != undefined) {
			let obj = new Date(this.q["DateTimeEnd"]);
			if (query != '')
				query += ' and CreatedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',23,59,59)';
			else
				query += 'CreatedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',23,59,59)';
		}

        if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
            if (query != '') {
                query += ' and (ActionName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or ActionName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
            }
            else {
                query += '(ActionName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or ActionName.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';

            }
        }

        if (query == '')
            this.paging.query = '1=1';
        else
            this.paging.query = query;

        this.GetListCommon();
    }


    GetListCommon() {
        this.http.get(domainApi + '/User/GetLogSystem?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listCommon = res["data"];
                    this.paging.item_count = res["metadata"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    FindActionType(actionType: string) {
        if (actionType == "CREATE") {
            return "Thêm mới"
        }
        else if (actionType == "UPDATE") {
            return "Sửa/Cập nhật thông tin"
        }
        else if (actionType == "DELETE") {
            return "Xóa"
        }
        else {
            return ""
        }
    }
}
