// import { NgbDatepickerI18nBuddhist } from './../../../../service/date-picker-i18n';
import { CustomDateParserFormatterService } from './../../../../service/custom-date-parser-formatter.service';
import { CustomAdapterService } from './../../../../service/custom-adapter.service';
import { resResidentRequest } from '../../../../data/Model';
import { domain, domainImage, lstIdentityType, TypeCountry } from './../../../../data/const';
import { QueryFilter } from './../../../../data/dt';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n, NgbDatepickerI18nHebrew } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { v1 as uuidv1 } from 'uuid';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { FormStyle, getLocaleMonthNames, registerLocaleData, TranslationWidth } from '@angular/common';
import { NgbDatepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n';

export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-resident-move-in',
  templateUrl: './resident-move-in.component.html',
  styleUrls: ['./resident-move-in.component.scss'],
  providers: [
		// { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    {provide: NgbDateAdapter, useClass: CustomAdapterService},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService},
    //{provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nBuddhist}
	]
})
export class ResidentMoveInComponent implements OnInit {
  @ViewChild('file') file: ElementRef;
  @Output() itemResidentMoveIn: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  @Input() lstResidentRequest: any[];
  @Input() oldProcessStatus: number;
  public lstRelations = [];
  public lstIdentityType = lstIdentityType;
  public item : resResidentRequest;
  public phoneExist: string = '';
  public domainImage = domainImage;
  public birthday: any;
  public dateNow = new Date();
  public countryEnum = TypeCountry;
  public maxDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private gallery: Gallery
  ) {
    //egisterLocaleData('vi');

    this.item = new resResidentRequest();
    this.item.gender = "Nam";
    this.item.countryId = 1;
    this.item.identifyType = this.lstIdentityType[0].Id;
    this.item.files = [];
  }

  ngOnInit() {
    this.getRelationShips();

    if (this.itemDetail) {

      this.item.residentId = this.itemDetail['residentId'];
      this.item.fullName = this.itemDetail['fullName'];
      this.item.birthday = this.itemDetail['birthday'];
      this.birthday = new Date(this.itemDetail['birthday']);
      this.item.cardRequestId = this.itemDetail['cardRequestId'];
      this.item.gender = this.itemDetail['gender'];
      this.item.countryId = this.itemDetail['countryId'];
      this.item.identifyCode = this.itemDetail['identifyCode'];
      this.item.identifyCreate = this.itemDetail['identifyCreate'];
      this.item.identifyLoc = this.itemDetail['identifyLoc'];
      this.item.identifyType = this.itemDetail['identifyType'];
      this.item.relationshipId = this.itemDetail['relationshipId'];
      this.item.relationshipName = this.itemDetail['relationshipName'];
      this.item.files = this.itemDetail['files'];
      this.item.phone = this.itemDetail['phone'];
      this.item.residentIdTemporary = this.itemDetail['residentIdTemporary'];
      this.phoneExist = this.itemDetail['phone'];
    }
  }

  onSubmit() {
    if (!this.item.fullName) {
      this.toastWarning("Vui lòng nhập tên người đăng ký");
      return;
    } else if (!this.item.gender) {
      this.toastWarning("Vui lòng chọn giới tính");
      return;
    } else if (!this.item.birthday) {
      this.toastWarning("Vui lòng chọn ngày sinh");
      return;
    } else if (!this.item.relationshipId) {
      this.toastWarning("Vui lòng chọn mối quan hệ với chủ hộ");
      return;
    } else if (new Date(this.item.birthday) > this.dateNow) {
      this.toastWarning("Ngày sinh không lớn hơn hiện tại vui lòng kiểm tra lại");
      return;
    } else if (this.item.identifyCreate && (new Date(this.item.identifyCreate) > this.dateNow)) {
      this.toastWarning("Ngày cấp không lớn hơn hiện tại vui lòng kiểm tra lại");
      return;
    }

    if (this.item.phone &&
      this.lstResidentRequest.findIndex(s => s.phone === this.item.phone) >= 0 &&
      (!this.itemDetail || this.itemDetail.phone != this.item.phone)) {
      this.toastWarning("Số điện thoại bị trùng. Vui lòng kiểm tra lại");
      return
    }

    //this.item.birthday = moment(this.birthday).format("YYYY-MM-DD");
    //this.item.identifyCreate = this.item.identifyCreate ? new Date(moment(this.item.identifyCreate).format("YYYY-MM-DD")) : null;
    if (this.item.phone && this.item.phone != this.phoneExist) {
      this.http.get(domain + 'api/cms/Resident/checkExistPhone/' + this.item.phone).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 400) {
            this.toastWarning("Số điện thoại đã tồn tại. vui chọn nhập lại.");
            return;
          } else {
            if (!this.itemDetail) {
              this.item.residentIdTemporary = uuidv1();
            }

            this.itemResidentMoveIn.emit({...this.item});
            this.modal.close();
          }
        },
        (err) => {
          console.log("Error: connect to API");
          return;
        });
    } else {
      if (!this.itemDetail) {
        this.item.residentIdTemporary = uuidv1();
      }

      this.itemResidentMoveIn.emit({...this.item});
      this.modal.close();
    }
  }

  onChangeBirthday(value) {
    //this.item.birthday = moment(value).format("YYYY-MM-DD");
  }

  // onChangeIdentifyCreated(value) {
  //   if (value.length == )
  //   this.item.identifyCreate = new Date(moment(value).format("YYYY-MM-DD"));
  // }

  QueryChanged(){

  }

  getRelationShips () {
    this.http.get(domain + 'api/cms/TypeAttributeItem/CodeAttribute/relationship').subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstRelations = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  omit_special_phone(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (this.item.phone.length > 9) {
      return false;
    }

    return true;
}

omit_special_date(e) {
  const charCode = (e.which) ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 47 || charCode > 57)) {
    return false;
  }

  if (this.item.phone.length >= 10) {
    return false;
  }

  return true;
}

omit_special_char(e) {
  const charCode = (e.which) ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return true;
  }
  return false;
}

upload(files){
  if (files.length === 0)
    return;

  const formData = new FormData();

  for (let file of files)
    formData.append(file.name, file);

  const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
    reportProgress: true,
  });

  this.http.request(uploadReq).subscribe(event => {
    if (event.type === HttpEventType.UploadProgress) {

    }
    else if (event.type === HttpEventType.Response) {
      if (event.body["data"].length) {
        event.body["data"].forEach(element => {
          this.item.files.unshift({
            id: 0,
            fileId: 0,
            fileRef: '',
            url: element.toString()
          });
        });
      }
      if (this.file) this.file.nativeElement.value = "";
    }
  });
}

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
    lstImage.push({path: domainImage + item.url});
  })

  let prop = {
      images: lstImage,
      index
  };
  this.gallery.load(prop);
}

  onChangeRelationship(value) {
    if (value) {
      this.item.relationshipName = value["Name"];
      console.log("change");
    }
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
}
