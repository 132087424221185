import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rental-apartment-inform',
  templateUrl: './rental-apartment-inform.component.html',
  styleUrls: ['./rental-apartment-inform.component.scss']
})
export class RentalApartmentInformComponent implements OnInit {
  @Input() list: any;
  constructor(
    public modal: NgbActiveModal,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.list.forEach(item => {
      // item.createdAt = this.datePipe.transform( item.createdAt,"dd/MM/yyyy HH:mm");
      item.content = item.resType == 1? "Cư dân đồng ý lịch xem nhà" : "Cư dân không đồng ý lịch xem nhà"
    });
  }

}
