import { HeaderService } from './../../service/header.service';
import { Component, Input, ViewChild, ElementRef, ViewContainerRef, HostListener } from '@angular/core';
import { navItems, domainImage, domainApi, ActionTable } from './../../data/const';
import { AuthService } from '../../service/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { UserChangePass, PushToken } from '../../data/Model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Md5 } from 'ts-md5/dist/md5';
import { User } from '../../data/Model';
import { MessagingService } from '../../service/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Paging } from '../../data/dt';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ListenChangeRouterService } from '../../service/listen-change-router.service';
import { FirestoreService } from '../../service/firestore.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs-compat/operator/filter';
import { map, mergeMapTo } from 'rxjs/operators';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public navItems = [];
  public sidebarMinimized = false;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public userChangePass: UserChangePass = new UserChangePass();
  public domainImage = domainImage;
  public Item: User;
  public paging: Paging;
  public listNotification = [];
  public coundNotification: number;
  public returnUrl: string;
  public ItemToken: PushToken;

  public titleRoute: string;

  public message: any;
  public badge: any = 0;
  public broadCast: any;
  currentMessage = new BehaviorSubject(null);

  public httpOptions: any;
  public notififationUnRead: boolean = false;
  isLoadingMore = false;

  date: any;
  seconds: any;
  getDate: any;
  getDay: any;
  timeDay: any;
  getMonth: any;
  getFullYear: any;
  nameDay: string;
  currentLocale: any;
  isHomePage: boolean = false;
  countItemNext = 0;
  isLoadMore = true;
  throttle = 500;
  scrollDistance = 1;
  scrollUpDistance = 2;
  @ViewChild('ChangePasswordModal') public changePasswordModal: ModalDirective;
  @ViewChild('modalMyInfo') public modalMyInfo: ModalDirective;
  @ViewChild('file') file: ElementRef;
  subscription: Subscription;
  constructor(public auth: AuthService, public cookie: CookieService, public toastr: ToastrService, public http: HttpClient,
    private messagingService: MessagingService,
    public router: Router,
    public listenChangeRouterService: ListenChangeRouterService,
    public firestore: FirestoreService,
    private angularFireMessaging: AngularFireMessaging,
    public titleService: Title,
    private location: Location,
    private readonly headerService: HeaderService,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private routerActive: ActivatedRoute) {
    this.Item = new User();
    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 50;
    this.paging.query = "1=1";
    this.paging.order_by = "";
    this.paging.item_count = 0;
    this.countItemNext = this.paging.page * this.paging.page_size;

    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.titleRoute = titleService.getTitle();
        this.headerService.clear();
      }
    });

    setInterval(() => this.titleRoute = this.headerService.getTitleComponent() != undefined && this.headerService.getTitleComponent() != "" ?  this.headerService.getTitleComponent() : this.titleRoute, 100);

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/' || event.url === '/dashboard') {
          this.isHomePage = true;
        } else {
          this.isHomePage = false;
        }
      }
    });

    this.userChangePass.UserId = parseInt(localStorage.getItem("userId"));
    this.userChangePass.UserName = localStorage.getItem("userName");
    this.userChangePass.Avatar = localStorage.getItem("avata");
    this.userChangePass.FullName = localStorage.getItem("fullName");

    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }

    this.subscription = this.listenChangeRouterService.getMessage().subscribe(message => { this.ShowMenu(message.text); });


    setInterval(() => {
      const currentDate = new Date();
      this.date = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', hour12: true, minute: '2-digit' }).replace("AM", "SA").replace("PM", "CH");
    }, 30000);

    this.getDate = new Date().getDate();
    this.getDay = new Date().getDay();
    this.getMonth = new Date().getUTCMonth() + 1;
    this.getFullYear = new Date().getFullYear();
    if (this.getDay == 0) {
      this.nameDay = 'Chủ nhật';
    } else if (this.getDay == 1) {
      this.nameDay = 'Thứ hai';
    } else if (this.getDay == 2) {
      this.nameDay = 'Thứ ba';
    } else if (this.getDay == 3) {
      this.nameDay = 'Thứ tư';
    } else if (this.getDay == 4) {
      this.nameDay = 'Thứ năm';
    } else if (this.getDay == 5) {
      this.nameDay = 'Thứ sáu';
    } else if (this.getDay == 6) {
      this.nameDay = 'Thứ bảy';
    }
  }

  ngOnInit() {

    const currentDate = new Date();
    this.date = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', hour12: true, minute: '2-digit' }).replace("AM", "SA").replace("PM", "CH");

    this.ShowMenu(this.router.url);

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    //firebase
    //this.GetListNotification();

    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.listNotification = [];
        this.currentMessage.next(payload);
        if (payload["data"] != undefined) {
          this.badge = payload["data"]["badge"];
          this.GetListNotification();
        }
        this.GetListNotification();
      });

    this.getBadge();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.router.onSameUrlNavigation = 'ignore';
  }

  onScrollDown(ev) {

    if (this.isLoadMore && this.listNotification.length) {
      this.isLoadingMore = true;
      this.paging.page = this.paging.page + 1;

      this.GetListNotification();
    }
  }


  ShowMenu(url) {
    var json = JSON.parse(localStorage.getItem('menu'));
    let navItem = [];

    const itemHome = {
      name: 'Tổng quan',
      url: '/dashboard',
      icon: 'fa fa-windows',
      //children: [],
    };

    navItem.push(itemHome);

    for (var i = 0; i < json.length; i++) {
      let obj = this.createMenu(json[i], undefined);
      navItem.push(obj);
    }

    this.navItems = navItem;
  }

  GetListNotification() {
    this.paging.query = this.notififationUnRead == true ? "Status=1 AND (Type=2 OR Type=3)" : "Type=2 OR Type=3";
    this.paging.query = "1=1";
    this.http.get(domainApi + '/action/user/' + this.userChangePass.UserId + '?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          res["data"].forEach(element => {
            this.listNotification.push(element);
          });

          this.paging.item_count = res["metadata"];
          this.isLoadingMore = false;

          const indexNow = this.paging.page * this.paging.page_size;

          if (indexNow >= this.paging.item_count) {
            this.isLoadMore = false;
          } else {
            this.isLoadMore = true;
          }

          if ((indexNow + this.paging.page_size) > this.paging.item_count) {
            this.countItemNext = this.paging.item_count - indexNow;
          } else {
            this.paging.item_count = this.paging.page_size;
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  getUrlDetailNoti(item) {
    let urlRedirct = '#';

    if (item.ActionType ==  "REQUESTGROUP_REGISTRATION") {
      urlRedirct =  '/utilities/resident-service/request-group-registration/' + item.TargetId;
    } else if (item.ActionType ==  "REQUESTGROUP_CHANGE") {
      urlRedirct = '/utilities/resident-service/request-group-change/' + item.TargetId;
    } else if (item.ActionType ==  "REQUESTGROUP_RENEW") {
      urlRedirct = '/utilities/resident-service/request-group-renew/' + item.TargetId;
    } else if (item.ActionType ==  "REQUESTGROUP_CANCEL") {
      urlRedirct = '/utilities/resident-service/request-group-cancel/' + item.TargetId;
    } else if (item.ActionType ==  "REQUESTGROUP_LOCK") {
      urlRedirct = '/utilities/resident-service/request-group-lock/' + item.TargetId;
    } else if (item.ActionType ==  "REQUESTGROUP_UNLOCK") {
      urlRedirct = '/utilities/resident-service/request-group-unlock/' + item.TargetId;
    } else if (item.ActionType ==  "TRANSPOST_REQUEST_MOVEIN") {
      urlRedirct = '/utilities/transpost-request/update/in/' + item.TargetId;
    } else if (item.ActionType ==  "TRANSPOST_REQUEST_MOVEOUT") {
      urlRedirct = '/utilities/transpost-request/update/out/' + item.TargetId;
    } else if (item.ActionType ==  "REQUIRESUPPORT") {
      urlRedirct = '/require-support-manager/require-support/' + encodeURI(item.TargetId);
    } else if (item.ActionType ==  "REQUIRESUPPORTHISTORY") {
      urlRedirct = '/require-support-manager/require-support/' + encodeURI(item.TargetId);
    } else if (item.ActionType ==  "CONVERSATION") {
      urlRedirct = '/require-support-manager/require-support/' + encodeURI(item.TargetId);
    } else if (item.ActionType ==  "SERVICE_TECH") {
      urlRedirct = '/manager-service/service-tech/update/'+ item.TargetId+'/0';
    } else if (item.ActionType ==  "REGISTER_CONSTRUCTION_FORM") {
      urlRedirct = '/utilities/registration-construction-form/update/' + item.TargetId;
    } else if (item.ActionType ==  "CLEAN_SERVICE") {
      urlRedirct = 'manager-service/registration-cleaning/detail/' + item.TargetId;
    } else if (item.ActionType ==  "RENT_SERVICE") {
      urlRedirct = '/manager-service/rental-apartment/update/' + item.TargetId;
    } else {
      urlRedirct = '#';
    }

    this.redirectTo(urlRedirct);

    this.http.post(domainApi + '/action/deleteNotificationById/' + this.userChangePass.UserId + '/' + item.NotificationId, null, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          let index = this.listNotification.findIndex(x => x.NotificationId == item.NotificationId);
          this.listNotification[index].Status = 10;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  onCheckedNoti(item) {

  }

  createMenu(item, urlParent: string) {
    item["name"] = item["Name"];
    if (item["IsParamRoute"] == true) {
      item["url"] = urlParent == undefined ? "/" + item["Url"] : urlParent.replace("/0", "") + "/" + item["Url"];
    }
    else {
      item["url"] = urlParent == undefined ? "/" + item["Url"] : urlParent.replace("/0", "") + "/" + item["Url"];
    }
    item["icon"] = item["Icon"];

    delete item["MenuId"];
    delete item["Code"];
    delete item["Name"];
    delete item["MenuParent"];
    delete item["Url"];
    delete item["Icon"];
    delete item["ActiveKey"];
    delete item["Status"];

    if (item["listMenus"].length > 0) {
      item["children"] = [];
      for (var i = 0; i < item["listMenus"].length; i++) {
        item["children"].push(item["listMenus"][i]);
        this.createMenu(item["children"][i], item["url"]);
      }
    }

    delete item["listMenus"];

    return item;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.auth.logout();
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }

  OpenChangePasswordModal() {
    this.userChangePass.PasswordOldE = undefined;
    this.userChangePass.PasswordNewE = undefined;
    this.userChangePass.ConfirmPassword = undefined;
    this.changePasswordModal.show();
  }

  ChangePassword() {
    if (this.userChangePass.PasswordOldE == undefined || this.userChangePass.PasswordOldE == '') {
      this.toastWarning("Chưa nhập Mật khẩu hiện tại!");
      return;
    } else if (this.userChangePass.PasswordNewE == undefined || this.userChangePass.PasswordNewE == '') {
      this.toastWarning("Chưa nhập Mật khẩu mới!");
      return;
    } else if (this.userChangePass.ConfirmPassword == undefined || this.userChangePass.ConfirmPassword == '') {
      this.toastWarning("Chưa nhập Mật khẩu xác nhận!");
      return;
    } else if (this.userChangePass.ConfirmPassword != this.userChangePass.PasswordNewE) {
      this.toastWarning("Mật khẩu xác nhận không đúng!");
      return;
    }

    this.userChangePass.PasswordOld = Md5.hashStr(this.userChangePass.PasswordOldE).toString();
    this.userChangePass.PasswordNew = Md5.hashStr(this.userChangePass.PasswordNewE).toString();

    this.http.put(domainApi + '/user/changePass/' + this.userChangePass.UserId, this.userChangePass, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.changePasswordModal.hide();
          this.toastSuccess("Đổi mật khẩu tài khoản thành công!");
        } else if (res["meta"]["error_code"] == 213) {
          this.toastError("Mật khẩu hiện tại không đúng. Vui lòng thử lại!");
        }
        else {
          this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
        }
      },
      (err) => {
        this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
      }
    );
  }

  listen() {
    this.angularFireMessaging.messages
      .subscribe((message) => { console.log(message); });
  }

  requestPermission() {
    this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
      .subscribe(
        (token) => { console.log('Permission granted! Save to the server!', token); },
        (error) => { console.error(error); },
      );
  }

  // Xóa thông báo
  DeleteNofication() {

    this.http.post(domainApi + '/action/deleteAllNotification/' + this.userChangePass.UserId, null, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.paging.item_count = 0;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  DeleteNoficationItem(NotificationId) {
    this.http.post(domainApi + '/action/deleteNotificationById/' + this.userChangePass.UserId + '/' + NotificationId, null, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          let index = this.listNotification.findIndex(x => x.NotificationId == NotificationId);
          this.listNotification[index].Status = 10;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });

  }

  //Thông báo
  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }

  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }

  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }

  returnPage() {
    let isShow = this.headerService.getIsShow();

    if (isShow) {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        childComponent: SimpleModalComponent,
        data: {
          text: "Bạn chưa hoàn tất thông tin. Bạn muốn rời mà không hoàn tất không?"
        },
        actionButtons: [
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.location.back();
              this.viewRef.clear();
            }
          },
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',

          }
        ],
      });
    } else {
      this.routerActive.children.forEach(child => {
        child.params.subscribe(params => {
          const idDetail = params['id'];

          if (idDetail && parseInt(idDetail) > 0) {
            this.location.back();
          } else {
            let urlCurrent = this.router.url;
            console.log(urlCurrent.split("/").length);
            if (urlCurrent.split("/").length >= 4) {
              this.location.back();
            } else {
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
                this.router.navigate([`/dashboard`]);
              });
            }
          }
        })
      })
    }
  }

  OpenModalInfo() {
    this.Item = new User();
    this.file.nativeElement.value = "";

    this.http.get(domainApi + '/user/infoUser/' + this.userChangePass.UserId, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.Item = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });

    this.modalMyInfo.show();
  }

  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
      }
      else if (event.type === HttpEventType.Response) {
        this.Item.Avata = event.body["data"].toString();
      }
    });
  }

  SaveInfo() {
    if (this.Item.FullName == undefined || this.Item.FullName == '') {
      this.toastWarning("Chưa nhập Tên người dùng!");
      return;
    } else if (this.Item.UserName == undefined || this.Item.UserName == '') {
      this.toastWarning("Chưa nhập Tên tài khoản!");
      return;
    }

    this.http.put(domainApi + '/user/changeInfoUser', this.Item, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.modalMyInfo.hide();
          this.toastSuccess("Lưu thông tin thành công!");
          localStorage.setItem('avata', res["data"]["Avata"] != undefined ? res["data"]["Avata"].toString() : undefined);
          localStorage.setItem('fullName', res["data"]["FullName"] != undefined ? res["data"]["FullName"].toString() : undefined);
          this.userChangePass.UserName = localStorage.getItem("userName");
          this.userChangePass.Avatar = localStorage.getItem("avata");
          this.userChangePass.FullName = localStorage.getItem("fullName");
        }
        else {
          this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
        }
      },
      (err) => {
        this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
      });
  }

  RouterLink(item) {
    if (item.ActionType != "REQUIRESUPPORTHISTORY" && item.ActionType != "REQUIRESUPPORT") return;
    if (this.router.url.split("?")[0] == "/require-support")
      this.ResetCurrentRouter(item.TargetId);
    else
      this.router.navigateByUrl("/require-support-manager/require-support?RequireSupportId=" + item.TargetId);
  }

  ResetCurrentRouter(TargetId) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl("/require-support-manager/require-support?RequireSupportId=" + TargetId);
  }

  ChangeStatus(item) {
    this.http.post(domainApi + '/action/deleteNotificationById/' + this.userChangePass.UserId + '/' + item.ActionId, null, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.GetListNotification();
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  getBadge() {
    this.firestore.getBadge(this.userChangePass.UserId).subscribe((res) => {
      this.paging.page = 1;
      this.listNotification = [];
      this.badge = res.payload.data() ? res.payload.data()["countNotification"] : 0;
      this.GetListNotification();
    },
      (err) => {
        console.log("Error: connect to API:" + err);
      });
  }

}
