import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ApiConstant, domainApi, domainImage, lstIdentityType, lstStatusConfirmPayment, lstStatusPayment, LstStatusRequestGroup, LstStatusRequestGroupCreate, lstTranferConverBy, lstTransferGoodStatus, lstTransferType, LstTypeRequest, statusCardRequestGroup, transpostBy, transStatuspostRequestGroup, transpostType, typeCardRequestGroup } from './../../../data/const';
import { QueryFilter } from './../../../data/dt';
import { Attactment, requestGroup, requestGroupResponse, resCarCard, resGoods, resMotorcycleCard, resResidentCard, resResidentRequest, resTransferGoods } from '../../../data/Model';
import { CarCardComponent } from '../../resident-service-manager/dialogs/car-card/car-card.component';
import { MotorcycleCardComponent } from '../../resident-service-manager/dialogs/motorcycle-card/motorcycle-card.component';
import { ResidentCardComponent } from '../../resident-service-manager/dialogs/resident-card/resident-card.component';
import { ResidentMoveInComponent } from '../../resident-service-manager/dialogs/resident-move-in/resident-move-in.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResidentManagerService } from './../../../service/resident-manager.service';
import { CommonService } from './../../../service/common.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Location } from '@angular/common';
import { DateTimeAdapter, OwlDateTimeComponent, OwlDateTimeFormats, OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { ItemDialogComponent } from '../dialog/item-dialog/item-dialog.component';
import { listChanges } from '@angular/fire/database';
import { TranspostRequestService } from './../../../service/transpost-request.service';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { v } from '@angular/core/src/render3';
import { Title } from '@angular/platform-browser';

export const MY_CUSTOM_FORMATS: OwlDateTimeFormats = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  cancelBtnLabel = 'Hủy';

  /** A label for the set button */
  setBtnLabel = 'Đặt';
};

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-new-transfer-goods',
  templateUrl: './new-transfer-goods.component.html',
  styleUrls: ['./new-transfer-goods.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl }
  ]
})
export class NewTransferGoodsComponent implements OnInit {

  public date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  public httpOptions: any;
  public item: resTransferGoods;
  public apartmentIdChanged: number;
  public isCreatePage: boolean;
  public isCarCard: boolean = false;
  public typeRequest: typeCardRequestGroup;
  public lstGoods: resGoods[] = new Array();
  public lstProject = [];
  public lstTower = [];
  public lstFloor = [];
  public lstApartment = [];
  public lstStatusPayment = lstStatusPayment;
  public lstTranferConverBy = lstTranferConverBy;
  public lstTransferType = lstTransferType;
  public lstIdentityType = lstIdentityType;
  public lstStatus = LstStatusRequestGroupCreate;
  public domainImage = domainImage;
  public responseAdmin = new requestGroupResponse();
  public finishToDate: Date;
  public transpostStatus = transStatuspostRequestGroup;
  public isComplete = false;
  public isShowResident = false;
  public today = new Date();
  public lstResident = [];
  public transpostType = transpostType;
  public isLoading = false;
  public mydebt = "0";
  public isDisplayButton = true;
  public isWarningProjectId = false;
  public isWarningTower = false;
  public isWarningFloor = false;
  public isWarningApartment = false;
  public isWarningFullName = false;
  public isWarningPhone = false;
  public isWarningFromTime = false;
  public isWarningToTime = false;
  public isWarningTranspostBy = false;
  public isWarningTypeTranspost = false;
  public isWarningProcessStatus = false;
  public isWarningDateTransport = false;
  public isWarningMakerId = false;
  public isWarningFinishDate = false;
  public isWarningFinishToDate = false;
  public isSecurity = false;

  @ViewChild('file') file: ElementRef;


  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal,
    private readonly transpostRequestService: TranspostRequestService,
    private readonly commonService: CommonService,
    public router: Router,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
    private gallery: Gallery,
    public titleService: Title,
  ) {
    this.item = new resTransferGoods();
    this.item.responseAdmin = new requestGroupResponse();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    const type = this.route.snapshot.paramMap.get('type');
    const userCode = localStorage.getItem("roleCode");

    this.lstStatus.map(s => s.disabled = false);
    const indexStatusFinish = this.lstStatus.findIndex(s => s.Id == 9);
    if (userCode === 'ANDKTC') {
      this.isSecurity = true;
      this.lstStatus[indexStatusFinish].disabled = false;
    } else {
      this.isSecurity = false;
      this.lstStatus[indexStatusFinish].disabled = true;
    }
    [...this.lstStatus];

    if (id) {
      this.isCreatePage = false;
      this.onGetDetail(id);
    } else {
      this.isCreatePage = true;

      if (this.isSecurity) {
        this.router.navigate(["**"])
      }

      this.item.processStatus = transStatuspostRequestGroup.Registered;
      this.item.createdAt = new Date();
    }
    if (location.pathname && location.pathname.includes("utilities/transpost-request/create/in")) {
      this.item.typeTranspost = transpostType.MoveIn;
    } else if (location.pathname && location.pathname.includes("utilities/transpost-request/create/out")) {
      this.item.typeTranspost = transpostType.MoveOut;
    }
    this.GetListProject();
    this.today.setHours(0, 0, 0, 0);

  }



  onSubmit() {
    this.isWarningProjectId = false;
    this.isWarningTower = false;
    this.isWarningFloor = false;
    this.isWarningApartment = false;
    this.isWarningFullName = false;
    this.isWarningPhone = false;
    this.isWarningFromTime = false;
    this.isWarningToTime = false;
    this.isWarningTranspostBy = false;
    this.isWarningTypeTranspost = false;
    this.isWarningProcessStatus = false;
    this.isWarningDateTransport = false;
    this.isWarningMakerId = false;
    this.isWarningFinishDate = false;
    this.isWarningFinishToDate = false;


    if (!this.item.projectId) {
      this.isWarningProjectId = true;
      this.toastWarning("Vui lòng chọn dự án");
      return;
    } else if (!this.item.towerId) {
      this.isWarningTower = true;
      this.toastWarning("Vui lòng chọn tòa nhà");
      return;
    } else if (!this.item.floorId) {
      this.isWarningFloor = true;
      this.toastWarning("Vui lòng chọn tầng");
      return;
    } else if (!this.item.apartmentId) {
      this.isWarningApartment = true;
      this.toastWarning("Vui lòng chọn căn hộ");
      return;
    } else if (!this.item.fullName) {
      this.isWarningFullName = true;
      this.toastWarning("Vui lòng nhập tên người đăng ký");
      return;
    } else if (!this.item.phone) {
      this.isWarningPhone = true;
      this.toastWarning("Vui lòng nhập số điện thoại");
      return;
    } else if (!this.item.fromTime) {
      this.isWarningFromTime = true;
      this.toastWarning("Vui lòng nhập giờ bắt đầu chuyển");
      return;
    } else if (!this.item.toTime) {
      this.isWarningToTime = true;
      this.toastWarning("Vui lòng nhập giờ kết thúc chuyển");
      return;
    } else if (!this.item.transpostBy && this.item.transpostBy != 0) {
      this.isWarningTranspostBy = true;
      this.toastWarning("Vui lòng chọn phương thức chuyển(chuyển bằng)");
      return;
    } else if (!this.item.typeTranspost && this.item.typeTranspost != 0) {
      this.isWarningTypeTranspost = true;
      this.toastWarning("Vui lòng chọn hình thức chuyển(chuyển ra/vào)");
      return;
    } else if (new Date(this.item.toTime) <= new Date(this.item.fromTime)) {
      this.isWarningToTime = true;
      this.isWarningFromTime = true;
      this.toastWarning("Thời gian chuyển không hợp lệ");
      return;
    } else if (/(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.phone) == false && /(84[3|5|7|8|9])+([0-9]{8})\b/g.test(this.item.phone) == false) {
      this.isWarningPhone = true;
      this.toastWarning("Số điện thoại không hợp lệ");
      return;
    } else if (this.lstGoods.length <= 0) {
      this.toastWarning("Vui lòng nhập hàng hóa chuyển");
      return;
    } else if ((!this.item.processStatus && this.item.processStatus != 0) || this.item.processStatus < 0) {
      this.isWarningProcessStatus = true;
      this.toastWarning("Vui lòng chọn trạng thái");
      return;
    } else if (!this.item.dateTransport) {
      this.isWarningDateTransport = true;
      this.toastWarning("Vui lòng nhập ngày đăng ký đúng định dạng");
      return;
    } else if (this.item.typeTranspost == transpostType.MoveIn && !this.item.makerId) {
      this.isWarningMakerId = true;
      this.toastWarning("Vui lòng nhập tên người đăng ký");
      return;
    } else if (this.isCreatePage && (this.item.dateTransport) < this.today) {
      this.isWarningDateTransport = true;
      this.toastWarning("Ngày đăng ký phải lớn hơn hoặc bằng ngày hiện tại");
      return;
    } else if (this.responseAdmin.finishDate && this.responseAdmin.finishDate < this.today) {
      this.isWarningFinishDate = true;
      this.toastWarning("Ngày chuyển phải lớn hơn hoặc bằng ngày hiện tại");
      return;
    } else if ((!this.responseAdmin.finishDate && this.finishToDate) || (this.responseAdmin.finishDate && !this.finishToDate)) {
      this.isWarningFinishToDate = true;
      this.toastWarning("Vui lòng điền đầy đủ ngày chuyển và thời gian chuyển");
      return
    }
    this.isLoading = true;


    // else if (!this.item.dateTransport) {
    //   this.toastWarning("Vui lòng nhập ngày chuyển");
    //   return;
    // }

    this.item.goodsRequests = this.lstGoods;
    this.item.status = LstStatusRequestGroup.find(s => s.Id == 2).Id;
    this.item.toTime = new Date(this.item.toTime).getHours() + ":" + new Date(this.item.toTime).getMinutes();
    this.item.fromTime = new Date(this.item.fromTime).getHours() + ":" + new Date(this.item.fromTime).getMinutes();
    this.item.responseAdmin = this.responseAdmin;
    this.item.dateTransport = new Date(this.item.dateTransport).getFullYear() + "/" + (new Date(this.item.dateTransport).getMonth() + 1) + "/" + new Date(this.item.dateTransport).getDate();
    if (this.finishToDate && this.responseAdmin.finishDate) {
      var finishTime = new Date(this.responseAdmin.finishDate).getFullYear() + "/" + (new Date(this.responseAdmin.finishDate).getMonth() + 1) + "/" + new Date(this.responseAdmin.finishDate).getDate() + " " + new Date(this.finishToDate).getHours() + ":" + new Date(this.finishToDate).getMinutes();
      this.item.responseAdmin.finishDate = finishTime;
    } else {
      this.item.responseAdmin.finishDate = null;
    }
    if (this.isCreatePage) {
      this.http.post(ApiConstant.CreateTranspostRequest, this.item).subscribe(
        (res) => {
          this.item.fromTime = new Date("2021/02/02 " + this.item.fromTime);
          this.item.toTime = new Date("2021/02/02 " + this.item.toTime);
          if (res['meta']['error_code'] == 200) {
            this.isLoading = false;
            this.toastSuccess("Tạo đăng ký mới thành công");
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([`/utilities/transpost-request`]);
            });
          } else {
            this.item.dateTransport = new Date(this.item.dateTransport);
            this.isLoading = false;
            this.toastError(res["meta"]["error_message"]);
          }
        },
        err => {
          this.item.dateTransport = new Date(this.item.dateTransport);
          this.isLoading = false;
          this.item.fromTime = new Date("2021/02/02 " + this.item.fromTime);
          this.item.toTime = new Date("2021/02/02 " + this.item.toTime);
          this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
        }
      );
    } else {
      this.http.put(ApiConstant.UpdateTranspotRequest, this.item).subscribe(
        (res) => {
          if (res['meta']['error_code'] == 200) {
            this.isLoading = false;
            this.toastSuccess("Cập nhật thành công!");
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([`/utilities/transpost-request`]);
            });
          } else {
            this.item.dateTransport = new Date(this.item.dateTransport);
            this.isLoading = false;
            this.toastError(res["meta"]["error_message"]);
          }
        },
        err => {
          this.isLoading = false;
          this.item.dateTransport = new Date(this.item.dateTransport);
          // this.toastError(err);
          this.toastError("Đã xảy ra lỗi. Vui lòng thử lại!");
        })
    }
  }



  GetListProject() {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.projectId = null;
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;
    this.item.residentName = null;
    this.item.residentPhone = null;

    return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getResidentRequest(lst: resResidentRequest[]) {
    let reqLstResidentRequest = [];

    if (lst.length) {
      for (let i = 0; i < lst.length; i++) {
        var itemResidentRequest = lst[i];
        reqLstResidentRequest.push(
          {
            cardRequestId: itemResidentRequest.cardRequestId || null,
            // residentId: itemResidentRequest.residentId || 0,
            residentIdTemporary: itemResidentRequest.residentIdTemporary || null,
            fullName: itemResidentRequest.fullName,
            birthday: itemResidentRequest.birthday,
            gender: itemResidentRequest.gender,
            phone: itemResidentRequest.phone,
            identifyType: itemResidentRequest.identifyType,
            identifyCode: itemResidentRequest.identifyCode,
            identifyCreate: itemResidentRequest.identifyCreate,
            identifyLoc: itemResidentRequest.identifyLoc,
            relationshipId: itemResidentRequest.relationshipId,
            relationshipName: itemResidentRequest.relationshipName,
            files: itemResidentRequest.files
          }
        )
      }
    }

    return reqLstResidentRequest;
  }

  GetListTower() {
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;
    this.item.residentId = null;
    this.item.residentPhone = '';
    this.item.residentName = '';
    this.apartmentIdChanged = null;
    this.item.residentName = null;
    this.item.residentPhone = null;
    this.isShowResident = false;
    this.item.makerId = null;

    return this.commonService.getLstTower(this.item.projectId).subscribe(
      (res) => {
        this.lstTower = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }



  GetListFloor() {
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.floorId = null;
    this.item.apartmentId = null;
    this.apartmentIdChanged = null;
    this.item.residentName = null;
    this.item.residentPhone = null;
    this.isShowResident = false;
    this.item.residentId = null;
    this.item.makerId = null;

    return this.commonService.getLstFloor(this.item.towerId).subscribe(
      (res) => {
        this.lstFloor = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  GetListApartment() {
    this.lstApartment = [];
    this.item.apartmentId = null;
    this.apartmentIdChanged = null;
    this.item.residentName = null;
    this.item.residentPhone = null;
    this.isShowResident = false;
    this.item.residentId = null;
    this.item.makerId = null;

    return this.commonService.getLstApartment(this.item.floorId).subscribe(
      (res) => {
        if (this.isCreatePage) {
          for (let i = 0; i < res.length; i++) {
            this.commonService.getPurchaserByApartmentId(res[i]['ApartmentId']).subscribe(
              (lstRs) => {
                if (lstRs) {
                  this.lstApartment.push(res[i]);
                  this.lstApartment = [...this.lstApartment];
                }
              },
              (err) => {
                this.toastError(err['meta']['error_message']);
              });
          }
        } else {
          this.lstApartment = res;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getPurchaserByApartmentId(value) {
    this.item.residentName = null;
    this.item.residentPhone = null;
    this.item.residentId = null;
    this.item.makerId = null;
    if (value) {
      this.item.apartmentId = value.ApartmentId;
      this.item.apartmentName = value.Name;
      if (!this.isCreatePage) {
        var postMap = {
          MaCanHo: value.Code,
          IdDuAn: value.ProjectId,
          KyThanhToan: moment(new Date()).format("MM/YYYY")
        }
        this.getDebts(postMap);
      }
    }
    this.isShowResident = false;
    if (this.item.apartmentId > 0) {
      //   (this.lstGoods.length)) {
      //   this.modalDialogService.openDialog(this.viewRef, {
      //     title: 'Xác nhận',
      //     childComponent: SimpleModalComponent,
      //     data: {
      //       text: "Dữ liệu đăng ký thẻ bên dưới sẽ bị mất. Bạn có muốn thay đổi không?"
      //     },
      //     actionButtons: [
      //       {
      //         text: 'Xác nhận',
      //         buttonClass: 'btn btn-success',
      //         onAction: () => {
      //           this.lstGoods = [];
      //           this.item.apartmentId = value.ApartmentId;
      //           this.item.apartmentName = value.Name;

      //           this.commonService.getPurchaserByApartmentId(value.Id).subscribe(
      //             (res) => {
      //               if (res) {
      //                 this.item.residentId = res.ResidentId;
      //                 this.item.residentName = res.FullName;
      //                 this.item.residentPhone = res.Phone;
      //               } else {
      //                 this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
      //                 return;
      //               }
      //             },
      //             (err) => {
      //               this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
      //               return;
      //             }
      //           )

      //           this.viewRef.clear();
      //         }
      //       },
      //       {
      //         text: 'Hủy',
      //         buttonClass: 'btn btn-default',
      //         onAction: () => {
      //           this.apartmentIdChanged = this.item.apartmentId;
      //           this.viewRef.clear();
      //         }
      //       }
      //     ],
      //   });
      // } else {
      this.item.apartmentId = value.ApartmentId;
      this.item.apartmentName = value.Name;
      this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
        (res) => {
          if (res) {
            this.item.residentId = res.ResidentId;
            this.item.residentName = res.FullName;
            this.item.residentPhone = res.Phone;

            if (this.item.typeTranspost == transpostType.MoveOut) {
              this.item.phone = res.Phone;
              this.item.fullName = res.FullName;
              this.item.identifyType = res.IdentifyType;
              this.item.identifyCode = res.IdentifyCode;
            }
            if (res.FullName)
              this.isShowResident = true;
          } else {
            this.isShowResident = false;
            this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
            return;
          }
        },
        (err) => {
          this.isShowResident = false;
          this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
          return;
        }
      )
      if (this.item.typeTranspost == transpostType.MoveIn) {
        this.http.get(domainApi + '/Resident/getResident/' + this.item.apartmentId + '/1/100').subscribe(
          (res) => {
            if (res["meta"]["error_code"] < 300) {
              this.lstResident = res["data"]["Results"];
            } else {
              this.lstResident = []
              this.toastError("Căn hộ này chưa có thông tin thành viên. Vui lòng kiểm tra lại!");
            }
          }, (err) => {
            this.lstResident = []
            this.toastError("Căn hộ này chưa có thông tin thành viên. Vui lòng kiểm tra lại!");
          }
        )
      }
    }
  }

  changeUser(value) {
    if (value) {
      this.item.fullName = value.ResidentName;
      this.item.identifyType = value.IdentifyType;
      this.item.identifyCode = value.IdentifyCode;
      this.item.phone = value.Phone;

    }
  }


  onGetDetail(id) {
    this.transpostRequestService.getDetail(id).subscribe(
      (res) => {
        if (res) {
          this.item = res;
          console.log("test", this.item.createdAt)
          if (this.item.processStatus == this.transpostStatus.Finished || this.item.processStatus == this.transpostStatus.UnApproval || this.item.processStatus == this.transpostStatus.Approval) {
            this.isComplete = true;
          }
          if (this.item.processStatus == this.transpostStatus.Finished || this.item.processStatus == this.transpostStatus.UnApproval) {
            this.isDisplayButton = false;
          }
          var postData = {
            MaCanHo: res.apartmentCode,
            IdDuAn: res.projectId,
            KyThanhToan: moment(new Date()).format("MM/YYYY")
          }
          this.getDebts(postData);
          this.setDataDetail(res);
          this.item.id = id;
          this.item.fromTime = new Date("2021/02/02 " + this.item.fromTime);
          this.item.toTime = new Date("2021/02/02 " + this.item.toTime);

          for (let x = 0; x < this.lstStatus.length; x++) {
            if (this.lstStatus[x].Id < this.item.processStatus) {
              this.lstStatus[x].disabled = true;
            }
          }
          const indexStatusFinish = this.lstStatus.findIndex(s => s.Id == 9);
          if (this.isSecurity == true) {
            this.lstStatus.map(s => s.disabled = true);
            this.lstStatus[indexStatusFinish].disabled = false;
          } else {
            this.lstStatus[indexStatusFinish].disabled = true;
          }

          this.lstStatus = [...this.lstStatus];
          this.lstGoods = this.item.goodsRequests;
          if (this.item.responseAdmin) {
            this.responseAdmin = this.item.responseAdmin;
            this.finishToDate = this.responseAdmin.finishDate || null;
          } else {
            this.responseAdmin = new requestGroupResponse();
          }
          if (this.item && this.item.files)
            this.item.files.map(item => {
              item.Url = item.url;
            })
        } else {
          this.modalDialogService.openDialog(this.viewRef, {
            title: 'Thông báo',
            childComponent: SimpleModalComponent,
            data: {
              text: "Yêu cầu đăng ký không tồn tại. Vui lòng kiểm tra lại!"
            },
            actionButtons: [
              {
                text: 'Quay lại',
                buttonClass: 'btn btn-info',
                onAction: () => {
                  this.returnPage();
                }
              },
            ],
          });
        }
      },
      (err) => {
        this.router.navigate(["**"])
      });
  }

  QueryChanged() {

  }

  getDebts(dataPost) {
    this.http.post(domainApi + '/OneS/GetListFeeFromOneS', dataPost, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          // this.listCommon = res["data"];
          var listItem = res["data"];
          if (listItem && listItem.length > 0) {
            this.responseAdmin.money = 0;
            listItem.map(item => {
              this.responseAdmin.money += item.TotalPrice;
            })
            this.mydebt = (this.responseAdmin.money).toLocaleString(
              undefined,
            );
          } else {
            this.mydebt = "0";
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
        this.mydebt = "0";
      });
  }

  //upload ảnh hiển thị
  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

      }
      else if (event.type === HttpEventType.Response) {
        if (this.item.files == undefined) this.item.files = [];

        event.body["data"].forEach(item => {
          let attactment = new Attactment();
          attactment.Url = item;
          attactment.Thumb = item;

          this.item.files.push(attactment);
        });
        if (this.file) this.file.nativeElement.value = "";
      }
    });
  }

  setDataDetail(item) {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.projectId = item.projectId || null;
    this.item.towerId = item.towerId || null;
    this.item.floorId = item.floorId || null;
    this.item.apartmentId = item.apartmentId || null;
    this.item.makerId = item.makerId || null

    this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
        this.item.projectId = item["projectId"];

        this.commonService.getLstTower(this.item.projectId).subscribe((res) => {
          this.lstTower = res;
          this.item.towerId = item["towerId"];

          this.commonService.getLstFloor(this.item.towerId).subscribe((res) => {
            this.lstFloor = res;
            this.item.floorId = item["floorId"];

            this.commonService.getLstApartment(this.item.floorId).subscribe((res) => {
              this.lstApartment = res;

              this.apartmentIdChanged = item["apartmentId"];
              this.item.apartmentId = item["apartmentId"];
              this.item.apartmentName = item["apartmentName"];
              // this.item.residentId = item["residentId"];
              // this.item.residentName = item["residentName"];
              this.commonService.getPurchaserByApartmentId(item.apartmentId).subscribe(
                (res) => {
                  if (res) {
                    this.item.residentId = res.ResidentId;
                    this.item.residentName = res.FullName;
                    this.item.residentPhone = this.item.residentPhone ? this.item.residentPhone : res.Phone;
                    this.isShowResident = true;
                    if (this.item.typeTranspost == transpostType.MoveIn) {
                      this.http.get(domainApi + '/Resident/getResident/' + this.item.apartmentId + '/1/100').subscribe(
                        (res) => {
                          if (res["meta"]["error_code"] < 300) {
                            this.lstResident = res["data"]["Results"];
                          } else {
                            this.lstResident = []
                            this.toastError("Căn hộ này chưa có thông tin thành viên. Vui lòng kiểm tra lại!");
                          }
                        }, (err) => {
                          this.lstResident = []
                          this.toastError("Căn hộ này chưa có thông tin thành viên. Vui lòng kiểm tra lại!");
                        }
                      )
                    }
                  } else {
                    this.isShowResident = false;
                    this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                    return;
                  }
                },
                (err) => {
                  this.isShowResident = false;
                  this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                  return;
                }
              )
            })
          })
        })
      },
      (err) => {
        this.isShowResident = false;
        console.log("Error: connect to API");
      }
    )
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
      lstImage.push({ path: domainImage + item.Url })
    })

    let prop = {
      images: lstImage,
      index
    };
    this.gallery.load(prop);
  }

  omit_special_phone(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  omit_special_date(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 47) {
      return false;
    }
    return true;
  }

  omit_special_time(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 58) {
      return false;
    }
    return true;
  }

  validateNumber(value: any) {
    if (value) {
      if (/^\d+$/.test(value)) {
        return true;
      }
    }
    return false;
  }

  changeDate(e) {
    var val = e.target.value;
    if (!val || val == "" || this.validateNumber(val.split("/").join(""))) {
      if (val.length === 2) {
        val += '/';
      } else if (val.length === 5) {
        val += '/';
      }
      this.responseAdmin.finishDate = val;
    }
  }



  handleChange = (e) => {
    var val = e.target.value;
    val = val + ""
    if (!val || val == "" || this.validateNumber(val.split("/").join(""))) {
      if (val.length === 2) {
        val += '/';
      } else if (val.length === 5) {
        val += '/';
      }
      this.responseAdmin.finishDate = val;
    }
  }

  deleteResidentRequest(item, index) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      settings: {
        footerClass: 'footer-dialog modal-footer',
      },
      childComponent: SimpleModalComponent,
      data: {
        text: "Bạn có chắc chắn muốn xóa mục này?"
      },
      actionButtons: [
        {
          text: 'Hủy',
          buttonClass: 'btn btn-default',
        },
        {
          text: 'Xác nhận',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.lstGoods = this.lstGoods.filter(s => s !== item);
            this.toastSuccess("Xóa thành công!");
            this.viewRef.clear();
          }
        }
      ],
    });

  }


  openResidentMoveInModal(item) {
    const dialofRef = this.modalService.open(ItemDialogComponent, { size: 'lg' });

    dialofRef.componentInstance.itemGoods.subscribe((res) => {
      if (res["name"]) {
        this.lstGoods.push(res);
      }
    });
  }

  openResidentMoveInDetailModal(item, index) {
    const dialofRef = this.modalService.open(ItemDialogComponent, { size: 'lg' });
    dialofRef.componentInstance.itemDetail = item;
    dialofRef.componentInstance.itemGoods.subscribe((res) => {
      if (res["name"]) {
        this.lstGoods[index] = res;
      }
    });
  }

  RemoveImage(idx) {
    this.item.files.splice(idx, 1);
  }

  keyUpDate(e, id) {
    var item1: any = document.getElementById("" + id);
    var value = e.target.value;
    if (item1 && value) {
      if (e.which === 8) {
        if (value.length == 3 || value.length == 6) {
          item1.value = value.slice(0, value.length - 1);
        }
      } else {
        if (value.length == 2 || value.length == 5) {
          item1.value = value + "/";
        } else if (value.length > 10) {
          item1.value = value.slice(0, 10);
        } else {
          item1.value = value;
        }
      }
    }
  }

  keyUpTime(e, id) {
    var item1: any = document.getElementById("" + id);
    var value = e.target.value;
    if (item1 && value) {
      if (e.which === 8) {
        if (value.length == 3) {
          item1.value = value.slice(0, value.length - 1);
        }
      } else {
        if (value.length == 2) {
          if (value && Number(value) && Number(value) >= 24) {
            this.toastWarning("Giờ phải trong khoảng 00 đến 23");
            item1.value = "";
          } else {
            item1.value = value + ":";
          }
        } else if (value.length > 5) {
          item1.value = value.slice(0, 5);
        } else if (value.length == 5) {
          var mins = value.slice(3, 5);
          if (mins && Number(mins) && mins >= 60) {
            this.toastWarning("Vui lòng nhập đúng định dạng hh:mm");
            item1.value = "";
          } else {
            item1.value = value;
          }
        } else {
          item1.value = value;
        }
      }
    }
  }

  returnPage() {
    if (this.isCreatePage && (this.item.apartmentId || this.item.dateTransport || this.item.fullName ||
      this.item.floorId || this.item.projectId || this.item.towerId || this.item.transpostBy
      || this.item.fromTime || this.item.toTime || this.item.phone || this.item.identifyType ||
      this.item.identifyCode)) {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer',
        },
        childComponent: SimpleModalComponent,
        data: {
          text: "Bạn chưa hoàn tất thông tin. Bạn muốn rời mà không hoàn tất không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',
          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              this.viewRef.clear();
              this.location.back();
            }
          }
        ],
      });
    } else {
      this.location.back();
    }
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
}
