import { resMotorcycleCard, resResidentCard } from '../../../../data/Model';
import { domain, domainApi, domainImage, statusCardRequestGroup, typeVehicle, ApiConstant, CardType } from './../../../../data/const';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-motorcycle-card',
  templateUrl: './motorcycle-card.component.html',
  styleUrls: ['./motorcycle-card.component.scss']
})
export class MotorcycleCardComponent implements OnInit {
  @Output() itemMotorcycleCard: EventEmitter<any> = new EventEmitter();
  @Input() apartmentId: number;
  @Input() projectId: number;
  @Input() lstResidentCardTemporary: resResidentCard[];
  @ViewChild('file') file: ElementRef;
  @Input() itemDetail: resMotorcycleCard;
  @Input() processStatus: statusCardRequestGroup;
  @Input() lstMotocycleCard: any[];
  @Input() lstCard: any[];
  @Input() oldProcessStatus: number;

  public  progressUpload : number = 0;
  public  dataUpload : any = null;
  public domainImage = domainImage;
  public lstColors = [];
  public lstResidents = [];
  public lstVerhicle = [];
  public lstResidentCard = [];
  public lstCardNumbers = [];
  public item: resMotorcycleCard;
  public isIntergrated = false;
  public cardType = CardType;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private gallery: Gallery
  ) {
    this.item = new resMotorcycleCard();
    this.item.files = [];
    this.item.vehicleTypeId = 4;
    //this.file.nativeElement.value = "";
   }

  ngOnInit() {
    this.onCheckAddResidentCard();
    this.getResidentCard();
    this.getLstVehicle();

    if (this.itemDetail) {
      this.item.files = this.itemDetail.files;

      let isExistCard = this.lstCard.find(s => s.cardId === this.itemDetail.cardId && this.itemDetail.cardId !== 0 );

      if (!isExistCard) {
        this.lstCard.unshift({
          cardId: this.itemDetail.cardId,
          cardNumber: this.itemDetail.cardName,
          cardCode: this.itemDetail.cardName,
          cardType: 1,
          disabled: true
        });
      }
    }
  }

  onSubmit(){
    if (this.item.residentCardIntegratedId == 0 && this.item.residentCardIdTemporary == null) {
      this.toastWarning("Vui lòng chọn thẻ cư dân tích hợp");
      return;
    } else if (this.item.vehicleTypeId === 0) {
      this.toastWarning("Vui lòng chọn loại phương tiện");
      return;
    } else if (!this.item.vehicleOwner) {
      this.toastWarning("Vui lòng nhập chủ phương tiện");
      return;
    } else if (!this.item.vehicleNumerPlate) {
      this.toastWarning("Vui lòng chọn biển kiểm soát");
      return;
    }

    if (this.item.vehicleNumerPlate && (!this.itemDetail || (this.itemDetail && this.itemDetail.vehicleNumerPlate != this.item.vehicleNumerPlate))) {
      this.http.get(ApiConstant.CheckExistNumberPlate + this.projectId + '&verhicleNumberPlate=' + this.item.vehicleNumerPlate).subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 400) {
            this.toastWarning(res["meta"]["error_message"]);
            return;
          } else {
            if (this.item.residentCardIntegratedId > 0 &&
              !this.item.residentCardIdTemporary &&
              this.processStatus >= 7 &&
              this.item.cardId == 0) {
                let itemResidentcard = this.lstResidentCard.find(s => s.residentCardId == this.item.residentCardIntegratedId);

                this.item.cardId = itemResidentcard.cardId;
                this.item.cardName = itemResidentcard.cardName;
                this.item.isIntegratedMotorcycleCard = true;
            } else if (this.item.residentCardIntegratedId == 0 &&
              this.item.residentCardIdTemporary &&
              this.processStatus >= 7 &&
              this.item.cardId == 0) {
                let itemResidentcard = this.lstResidentCard.find(s => s.residentCardIdTemporary == this.item.residentCardIdTemporary);

                this.item.cardId = itemResidentcard.cardId;
                this.item.cardName = itemResidentcard.cardName;
                this.item.isIntegratedMotorcycleCard = true;
            }

            if (this.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE &&
              (!this.item.cardId || this.item.cardId == 0)) {
              this.toastWarning("Vui lòng chọn mã số thẻ");
              return;
            }

            this.item.cardName = (this.item.cardId > 0 && this.item.cardName == '') ? this.lstCard.find(s => s.cardId == this.item.cardId).cardNumber : this.item.cardName != '' ? this.item.cardName : '';

            this.itemMotorcycleCard.emit(this.item);
            this.modal.close();
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }
    else if (!this.item.vehicleNumerPlate &&
      (this.item.vehicleTypeId == this.lstVerhicle.find(s => s.id == 1))) {
        if (this.item.residentCardIntegratedId > 0 &&
          !this.item.residentCardIdTemporary &&
          this.processStatus >= 7 &&
          this.item.cardId == 0) {
            let itemResidentcard = this.lstResidentCard.find(s => s.residentCardId == this.item.residentCardIntegratedId);

            this.item.cardId = itemResidentcard.cardId;
            this.item.cardName = itemResidentcard.cardName;
            this.item.isIntegratedMotorcycleCard = true;
        } else if (this.item.residentCardIntegratedId == 0 &&
          this.item.residentCardIdTemporary &&
          this.processStatus >= 7 &&
          this.item.cardId == 0) {
            let itemResidentcard = this.lstResidentCard.find(s => s.residentCardIdTemporary == this.item.residentCardIdTemporary);

            this.item.cardId = itemResidentcard.cardId;
            this.item.cardName = itemResidentcard.cardName;
            this.item.isIntegratedMotorcycleCard = true;
        }

        if (this.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE &&
          (!this.item.cardId || this.item.cardId == 0)) {
          this.toastWarning("Vui lòng chọn mã số thẻ");
          return;
        }

        this.item.cardName = (this.item.cardId > 0 && this.item.cardName == '') ? this.lstCard.find(s => s.cardId == this.item.cardId).cardNumber : this.item.cardName != '' ? this.item.cardName : '';

        this.itemMotorcycleCard.emit(this.item);
        this.modal.close();
      } else {
        if (this.item.residentCardIntegratedId > 0 &&
          !this.item.residentCardIdTemporary &&
          this.processStatus >= 7 &&
          this.item.cardId == 0) {
            let itemResidentcard = this.lstResidentCard.find(s => s.residentCardId == this.item.residentCardIntegratedId);

            this.item.cardId = itemResidentcard.cardId;
            this.item.cardName = itemResidentcard.cardName;
            this.item.isIntegratedMotorcycleCard = true;
        } else if (this.item.residentCardIntegratedId == 0 &&
          this.item.residentCardIdTemporary &&
          this.processStatus >= 7 &&
          this.item.cardId == 0) {
            let itemResidentcard = this.lstResidentCard.find(s => s.residentCardIdTemporary == this.item.residentCardIdTemporary);

            this.item.cardId = itemResidentcard.cardId;
            this.item.cardName = itemResidentcard.cardName;
            this.item.isIntegratedMotorcycleCard = true;
        }

        if (this.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE &&
          (!this.item.cardId || this.item.cardId == 0)) {
          this.toastWarning("Vui lòng chọn mã số thẻ");
          return;
        }

        this.item.cardName = (this.item.cardId > 0 && this.item.cardName == '') ? this.lstCard.find(s => s.cardId == this.item.cardId).cardNumber : this.item.cardName != '' ? this.item.cardName : '';

        this.itemMotorcycleCard.emit(this.item);
        this.modal.close();
      }
  }

  // getLstCard(){
  //   this.http.get(domain + 'api/cms/Card/getByPage?page=1&page_size=30&query=CardStatus=0').subscribe(
  //     (res) => {
  //       if (res["meta"]["error_code"] == 200) {
  //         this.lstCard =res["data"];
  //       }
  //     },
  //     (err) => {
  //       console.log("Error: connect to API");
  //     });
  // }

  upload(files){
    if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.files.unshift({
              id: 0,
              fileId: 0,
              fileRef: '',
              url: element.toString()
            });
          });
        }
				if (this.file) this.file.nativeElement.value = "";
			}
		});
  }

  onCheckAddResidentCard () {
    if (this.lstResidentCardTemporary.length) {
      for(let i = 0; i < this.lstResidentCardTemporary.length ; i ++){
        var itemResidentCard = this.lstResidentCardTemporary[i];

        if (itemResidentCard.residentCardIdTemporary) {
          this.lstResidentCard.unshift({
            residentCardId: itemResidentCard.residentCardId || 0,
            residentCardIdTemporary: itemResidentCard.residentCardIdTemporary || null,
            residentId: itemResidentCard.residentId || 0,
            residentName: itemResidentCard.residentName || '',
            cardId: itemResidentCard.cardId || 0,
            cardName: itemResidentCard.cardName || "",
            birthday: itemResidentCard.birthday,
            gender: itemResidentCard.gender,
            phone: itemResidentCard.phone,
            identifyType: itemResidentCard.identifyType,
            identifyCode: itemResidentCard.identifyCode,
            identifyCreate: itemResidentCard.identifyCreate,
            identifyLoc: itemResidentCard.identifyLoc,
            relationshipId: itemResidentCard.relationshipId,
            relationshipName: itemResidentCard.relationshipName,
            isIntegratedMotorcycleCard: itemResidentCard.isIntegratedMotorcycleCard || false,
          });
        }
      }
    }
  }

  onChangeResidentCard(value) {
    if (value){
      this.item.residentCardIdTemporary = value["residentCardIdTemporary"] || null;
      this.item.residentCardIntegratedId = value["residentCardId"];
      this.item.residentCardName = value["residentName"] + '-' + value["cardName"];
      this.item.residentId = value["residentId"];
      this.item.residentName = value["residentName"];

      if (value.isIntegratedMotorcycleCard == true &&
        this.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE) {
        this.isIntergrated = true;
        this.item.cardId = 0;
        this.item.cardName = '';
        this.item.isIntegratedMotorcycleCard = true;
      } else if (value.isIntegratedMotorcycleCard == false &&
        this.processStatus >= statusCardRequestGroup.WAIT_CARD_CODE) {
        this.isIntergrated = false;
        this.item.cardId = value.cardId;
        this.item.cardName = value.cardName;
        this.item.isIntegratedMotorcycleCard = true;
      } else {
        this.item.cardId = 0;
        this.item.cardName = '';
      }

      let lstCardIntegrated = this.lstMotocycleCard.filter(s => ((s.residentCardId > 0 && s.residentCardId == value.residentCardId) ||
      (s.residentCardIdTemporary != null && s.residentCardIdTemporary == value.residentCardIdTemporary)));

      if (lstCardIntegrated.length >= 1 && this.processStatus >= 7){
        this.isIntergrated = true;
        this.item.isIntegratedMotorcycleCard = true;

        if (!this.itemDetail) {
          this.item.cardId = 0;
          this.item.cardName = '';
        }
      }
    }
  }

  getResidentCard(){
    this.http.get(domain + 'api/Reg/getResidentCard?apartmentId=' + this.apartmentId).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstResidentCard = this.lstResidentCard.concat(res["data"]);
          this.lstResidentCard.map(s => s.disabled = false);

          if (this.lstResidentCardTemporary.length) {
            for(let k = 0; k < this.lstResidentCardTemporary.length; k++) {
              if (res["data"].findIndex(s => s.residentCardId === this.lstResidentCardTemporary[k].residentCardId) < 0 &&
              !this.lstResidentCardTemporary[k].residentCardIdTemporary) {
                this.lstResidentCard.unshift({
                  residentCardId: this.lstResidentCardTemporary[k].residentCardId || 0,
                  residentCardIdTemporary: this.lstResidentCardTemporary[k].residentCardIdTemporary || null,
                  residentId: this.lstResidentCardTemporary[k].residentId || 0,
                  residentName: this.lstResidentCardTemporary[k].residentName || '',
                  cardId: this.lstResidentCardTemporary[k].cardId || 0,
                  cardName: this.lstResidentCardTemporary[k].cardName || "",
                  birthday: this.lstResidentCardTemporary[k].birthday,
                  gender: this.lstResidentCardTemporary[k].gender,
                  phone: this.lstResidentCardTemporary[k].phone,
                  identifyType: this.lstResidentCardTemporary[k].identifyType,
                  identifyCode: this.lstResidentCardTemporary[k].identifyCode,
                  identifyCreate: this.lstResidentCardTemporary[k].identifyCreate,
                  identifyLoc: this.lstResidentCardTemporary[k].identifyLoc,
                  relationshipId: this.lstResidentCardTemporary[k].relationshipId,
                  relationshipName: this.lstResidentCardTemporary[k].relationshipName,
                  isIntegratedMotorcycleCard: this.lstResidentCardTemporary[k].isIntegratedMotorcycleCard || false,
                });
              }
            }
          }

          if (this.itemDetail) {
            let isExist = this.lstResidentCard.findIndex(s => s.residentCardId == this.itemDetail.residentCardIntegratedId);

            if (isExist < 0) {
              this.lstResidentCard.unshift({
                residentCardId : this.itemDetail["residentCardIntegratedId"] || 0,
                residentCardIdTemporary: this.itemDetail['residentCardIdTemporary'] || null,
                residentId : this.itemDetail["residentId"] || 0,
                residentName : this.itemDetail["residentName"],
                cardName : this.itemDetail["cardName"] || '',
                cardId : this.itemDetail["cardId"] || 0,
                isIntegratedMotorcycleCard : this.itemDetail["isIntegratedMotorcycleCard"] || false
              });
            }

            let residentCardChose = this.lstResidentCard.find(s => ((s.residentCardId == this.itemDetail["residentCardIntegratedId"]) ||
            (s.residentCardIdTemporary != null && s.residentCardIdTemporary == this.itemDetail["residentCardIdTemporary"])));

            if (residentCardChose) {
              let lstCardIntegrated = this.lstMotocycleCard.filter(s => ((s.residentCardId > 0 && s.residentCardIntegratedId == residentCardChose.residentCardId) ||
              (s.residentCardIdTemporary != null && s.residentCardIdTemporary == residentCardChose.residentCardIdTemporary)));
              let isCardIntegrated = this.lstMotocycleCard.filter(s => !s.residentCardId && !s.residentCardIdTemporary &&
                s.residentCardIntegratedId === residentCardChose.residentCardId);

              if (lstCardIntegrated.length > 1 && this.processStatus >= 7 && this.itemDetail.cardId !== residentCardChose.cardId){
                this.isIntergrated = true;

                this.item.isIntegratedMotorcycleCard = true;
              } else if (isCardIntegrated.length > 0 && this.processStatus >= 7 && this.itemDetail.cardId !== residentCardChose.cardId) {
                this.isIntergrated = true;
              } else if (this.itemDetail.cardId !== 0 && this.itemDetail.cardId !== residentCardChose.cardId) {
                this.isIntergrated = true;
              }
            }

            this.item.cardId = this.itemDetail["cardId"];
            this.item.cardName = this.itemDetail["cardName"];
            this.item.cardRequestId = this.itemDetail["cardRequestId"];
            this.item.createdById = this.itemDetail["createdById"];
            this.item.files = this.itemDetail["files"];
            this.item.residentCardId = this.itemDetail["residentCardId"];
            this.item.residentCardIntegratedId = this.itemDetail["residentCardIntegratedId"];
            this.item.residentCardIdTemporary = this.itemDetail["residentCardIdTemporary"];
            this.item.residentCardName = this.itemDetail["residentCardName"];
            this.item.residentId = this.itemDetail["residentId"];
            this.item.residentName = this.itemDetail["residentName"];
            this.item.vehicleBrand = this.itemDetail["vehicleBrand"];
            this.item.vehicleColor = this.itemDetail["vehicleColor"];
            this.item.vehicleDes = this.itemDetail["vehicleDes"];
            this.item.vehicleName = this.itemDetail["vehicleName"];
            this.item.vehicleNumerPlate = this.itemDetail["vehicleNumerPlate"];
            this.item.vehicleOwner = this.itemDetail["vehicleOwner"];
            this.item.vehicleTypeId = this.itemDetail["vehicleTypeId"];

            this.lstResidentCard.map(s => s.disabled = ((this.itemDetail.residentCardIntegratedId > 0 && s.residentCardIntegratedId == this.itemDetail.residentCardIntegratedId) ||
            (this.itemDetail.residentCardIdTemporary != null && s.residentCardIdTemporary == this.itemDetail.residentCardIdTemporary) ? false : s.disabled));

            this.lstResidentCard = [...this.lstResidentCard];
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  getLstVehicle(){
    this.http.get(domain + 'api/Reg/vehicleType?type=' + typeVehicle.MOTORCLE).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.lstVerhicle = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  omit_special_char(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
    lstImage.push({path: domainImage + item.url})
  })

  let prop = {
      images: lstImage,
      index
  };
  this.gallery.load(prop);
}

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
  }
}
