import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appFilterDropdown]'
})
export class FilterDropdownDirective {
  @Output() isClickFilter: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    this.isClickFilter.emit(true);
  }
}
