import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service-tech-history',
  templateUrl: './service-tech-history.component.html',
  styleUrls: ['./service-tech-history.component.scss']
})
export class ServiceTechHistoryComponent implements OnInit {
  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  
  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }

}
