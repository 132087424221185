import { ApiConstant, domainApi } from './../data/const';
import { Paging, QueryFilter } from './../data/dt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegisterConstructionService {

  constructor(private readonly http: HttpClient) { }

  getByPage(pageOption: QueryFilter, page: Paging): Observable<any> {
    // let query = `?page=${page.page}&page_size=${page.page_size}`;
    let query = `1=1`;

    if (pageOption.ProjectId > 0) {
      query = query.concat(` AND ProjectId=${pageOption.ProjectId}`)
    }

    if (pageOption.TowerId > 0) {
      query = query.concat(` AND TowerId=${pageOption.TowerId}`)
    }

    if (pageOption.FloorId > 0) {
      query = query.concat(` AND FloorId=${pageOption.FloorId}`)
    }

    if (pageOption.ApartmentId > 0) {
      query = query.concat(` AND ApartmentId=${pageOption.ApartmentId}`)
    }

    if (pageOption.DateTimeStart) {
      let DateTimeStart = new Date(pageOption.DateTimeStart);
      query = query.concat(` AND RegisterDate>=DateTime(${DateTimeStart.getFullYear()},${DateTimeStart.getMonth() + 1},${DateTimeStart.getDate()},0,0,0)`)
    }

    if (pageOption.DateTimeEnd) {
      let DateTimeEnd = new Date(pageOption.DateTimeEnd);
      query = query.concat(` AND RegisterDate<DateTime(${DateTimeEnd.getFullYear()},${DateTimeEnd.getMonth() + 1},${DateTimeEnd.getDate()},23,59,59)`)
    }

    // if (pageOption.DateTimeEnd) {
    //   query = query.concat(`&DateEnd=${pageOption.DateTimeEnd}`)
    // }

    if (pageOption.StatusProcessId) {
      query = query.concat(` AND RegConstructionStatus=${pageOption.StatusProcessId}`)
    }

    if (pageOption.DepositStatus) {
      query = query.concat(` AND DepositStatus=${pageOption.DepositStatus} AND IsDeposit=true`)
    }

    if (page.query) {
      query = query.concat(` AND (ApartmentName.Contains("${page.query}") OR ResidentName.Contains("${page.query}"))`)
    }

    query = `?page=${page.page}&page_size=${page.page_size}&order_by=UpdatedAt Desc&query=` + query;

    // console.log(query)
    return this.http.get(`${ApiConstant.GetRegisterConstructionFormByPage}${query}`);
  }

  getDetail(id: number): Observable<any> {
    return this.http.get(ApiConstant.DetailRegisterConstructionForm + id).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }
        return null;
      })
    );;
  }

  getContractorByPage(pageOption: QueryFilter, page: Paging): Observable<any> {
    // let query = `?page=${page.page}&page_size=${page.page_size}`;
    let query = `1=1`;

    if (pageOption.TargetId) {
      query = query.concat(` AND RegisterConstructionFormId=${pageOption.TargetId}`)
    }

    query = `?page=${page.page}&page_size=${page.page_size}&query=` + query;

    return this.http.get(`${ApiConstant.GetContractorByPage}${query}`);
  }

  getDetailContractor(id: number): Observable<any> {
    return this.http.get(ApiConstant.DetailContractor + id).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }
        return null;
      })
    );;
  }

  getExtensionConstructionFormByPage(pageOption: QueryFilter, page: Paging): Observable<any> {
    let query = `1=1`;

    if (pageOption.TargetId) {
      query = query.concat(`AND ContractorId=${pageOption.TargetId}`)
    }

    query = `?page=${page.page}&page_size=${page.page_size}&query=` + query;

    return this.http.get(`${ApiConstant.GetExtensionConstructionFormByPage}${query}`);
  }

  getAcceptanceByPage(pageOption: QueryFilter, page: Paging): Observable<any> {
    let query = `1=1`;

    if (pageOption.TargetId) {
      query = query.concat(` AND RegisterConstructionFormId=${pageOption.TargetId}`)
    }

    query = `?page=${page.page}&page_size=${page.page_size}&query=` + query;

    return this.http.get(`${ApiConstant.GetAcceptanceConstructionFormByPage}${query}`);
  }

  getDataExportExcel(pageOption: QueryFilter, page: Paging): Observable<any> {
    let query = `1=1`;

    if (pageOption.ProjectId > 0) {
      query = query.concat(` AND ProjectId=${pageOption.ProjectId}`)
    }

    if (pageOption.TowerId > 0) {
      query = query.concat(` AND TowerId=${pageOption.TowerId}`)
    }

    if (pageOption.FloorId > 0) {
      query = query.concat(` AND FloorId=${pageOption.FloorId}`)
    }

    if (pageOption.ApartmentId > 0) {
      query = query.concat(` AND ApartmentId=${pageOption.ApartmentId}`)
    }

    if (pageOption.DateTimeStart) {
      let DateTimeStart = new Date(pageOption.DateTimeStart);
      query = query.concat(` AND RegisterDate>=DateTime(${DateTimeStart.getFullYear()},${DateTimeStart.getMonth() + 1},${DateTimeStart.getDate()},0,0,0)`)
    }

    if (pageOption.DateTimeEnd) {
      let DateTimeEnd = new Date(pageOption.DateTimeEnd);
      query = query.concat(` AND RegisterDate<DateTime(${DateTimeEnd.getFullYear()},${DateTimeEnd.getMonth() + 1},${DateTimeEnd.getDate()},23,59,59)`)
    }

    if (pageOption.StatusProcessId) {
      query = query.concat(` AND RegConstructionStatus=${pageOption.StatusProcessId}`)
    }

    if (pageOption.DepositStatus) {
      query = query.concat(` AND DepositStatus=${pageOption.DepositStatus} AND IsDeposit=true`)
    }

    if (page.query) {
      query = query.concat(` AND (ApartmentName.Contains("${page.query}") OR ResidentName.Contains("${page.query}"))`)
    }

    query = `?page=${page.page}&page_size=${page.page_size}&order_by=UpdatedAt Desc&query=` + query;

    return this.http.get(`${ApiConstant.GetDataRegConstructionExportExcel}${query}`);
  }
}
