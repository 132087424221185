import { ApiConstant, domainApi } from './../data/const';
import { Paging, QueryFilter } from './../data/dt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TranspostRequestService {

    constructor(private readonly http: HttpClient) { }

    getDetail(id: number): Observable<any> {
        return this.http.get(ApiConstant.DetailTranspostRequest + "?Id=" + id).pipe(
            map(res => {
                if (res['meta']['error_code'] == 200) {
                    return res['data'];
                }
                return null;
            })
        );;
    }
}
