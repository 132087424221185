import { InfoRequestCleaning, ConfigUtilities } from './../../../data/Model';
import { listTypeUtilities, ApiConstant, domain, domainImage } from './../../../data/const';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { Paging, QueryFilter } from '../../../data/dt';
import { NgForm } from '@angular/forms';
import { domainApi } from '../../../data/const';
import { Title } from "@angular/platform-browser";
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';


@Component({
  selector: 'app-config-utilities',
  templateUrl: './config-utilities.component.html',
  styleUrls: ['./config-utilities.component.scss'],
  providers: []
})
export class ConfigUtilitiesComponent implements OnInit {
  @ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;
  @ViewChild('file') file: ElementRef;

	public paging: Paging;
  public pagingDoc: Paging;
  public q: QueryFilter;
  public lstConfigUtilities = [];
  public lstProject = [];
	public Item: ConfigUtilities;
  public domainImage = domainImage;

	public submitted = false;
  public isCreatePage = true;
  public isShowFilter = false;
  public lstTypeUtilities = listTypeUtilities;

	constructor(
    public http: HttpClient, 
    public modalDialogService: ModalDialogService, 
    public viewRef: ViewContainerRef, 
    public toastr: ToastrService, 
    public titleService: Title) {
		this.Item = new ConfigUtilities();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "Id Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";
    this.q.ProjectId = null;
    this.q.Type = null;
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Danh sách cấu hình dịch vụ - tiện ích theo dự án");
		this.getListConfigUtilities();
		this.GetListProject();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.getListConfigUtilities();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	QueryChanged() {
		let query = '';
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and Name.Contains("' + this.q.txtSearch.toLowerCase() + '")';
			}
			else {
				query += 'Name.Contains("' + this.q.txtSearch.toLowerCase() + '")';

			}
		}

		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.getListConfigUtilities();
	} 

	//Get danh sách chức năng
	getListConfigUtilities() {
		this.http.get(ApiConstant.getListConfigUtilities + '?page=' + this.paging.page +
    '&page_size=' + this.paging.page_size +
    '&query=' + this.paging.query +
    (this.q.ProjectId > 0 ? ('&projectId=' + this.q.ProjectId) : '') +
    (this.q.TowerId > 0 ? ('&towerId=' + this.q.TowerId) : '') +
    (this.q.Type > 0 ? ('&type=' + this.q.Type) : '')).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.lstConfigUtilities = res["data"];
					this.paging.item_count = res["metadata"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenCommonModal(item) {

		this.submitted = false;
		this.Item = new ConfigUtilities();
    this.lstProject.map(s => s['Selected'] = false);

    if (item) {
      this.isCreatePage = false;
      this.Item = {...item};

      if (this.Item.listProjectId && this.Item.listProjectId.length) {
        for(let i = 0; i < this.Item.listProjectId.length; i ++) {
          const indexProjectSelect = this.lstProject.findIndex(s => s.ProjectId == this.Item.listProjectId[i]);

          if (indexProjectSelect >= 0) {
            this.lstProject[indexProjectSelect]['Selected'] = true;
          }
        }

        this.lstProject = [...this.lstProject];
      }
    } else {
      this.isCreatePage = true;
    }

		this.modalCommon.show();
	}

	SaveCommon() {
    console.log(111);
		this.submitted = true;

    if (!this.Item.url) {
      this.toastWarning("Vui lòng upload ảnh để tiếp tục.");
      return;
    }

    const lstProjectSelected = this.lstProject.filter(s => s['Selected'] == true);

		let data = Object.assign({}, this.Item);
    console.log(lstProjectSelected);
    data.listProjectId = [];

    for(let i = 0; i < lstProjectSelected.length; i ++) {
      data.listProjectId.push(lstProjectSelected[i].ProjectId);
    }

		if (this.isCreatePage) {
			this.http.post(ApiConstant.createConfigUtilities, data).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.getListConfigUtilities();
						this.modalCommon.hide();
						this.toastSuccess("Thêm mới thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.http.put(ApiConstant.updateConfigUtilities + data.id, data).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.getListConfigUtilities();
						this.modalCommon.hide();
						this.toastSuccess("Cập nhật thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(ApiConstant.deleteCategoryDoc + Id).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.getListConfigUtilities();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

  openToggleFilter(){
    this.isShowFilter = !this.isShowFilter;
  }

	//Danh sách dự án
	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name').subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.lstProject = res["data"];
          			this.lstProject.map(s => s['Selected'] = false);
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

  getNameTypeUtilities(type) {
    const itemType = this.lstTypeUtilities.find(s => s.Id == type);

    return itemType ? itemType.Name : ''
  }

  getListProjectName(lstProjectId) {
    if (lstProjectId && lstProjectId.length) {
      let lstProjectName = [];

      for(let i = 0; i < lstProjectId.length; i++) {
        const itemProject = this.lstProject.find(s => s.ProjectId == lstProjectId[i]);
        
        if (itemProject) {
          lstProjectName.push(itemProject.Name);
        }
      }
  
      return lstProjectName.join(', ');
    }

    return '';
  }

  upload(files){
    if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
				if (event.body["data"].length) {
				  this.Item.icon = event.body["data"].toString();
				}
				if (this.file) this.file.nativeElement.value = "";
			}
		});
  }
}
