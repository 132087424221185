import { ApiConstant } from './../../../data/const';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { Paging, QueryFilter } from '../../../data/dt';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi } from '../../../data/const';
import { Title } from "@angular/platform-browser";
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

//Định dạng ngày h trên Modal
export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
	selector: 'app-service-category',
	templateUrl: './service-category.component.html',
	styleUrls: ['./service-category.component.scss'],
	providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class ServiceCategoryComponent implements OnInit {

	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('modalDocument') public modalDocument: ModalDirective;
	@ViewChild('f') public form: NgForm;
	@ViewChild('formDoc') public formDoc: NgForm;

	public paging: Paging;
	public pagingDoc: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public projects = [];
	public towers = [];
	public documents = [];
	public lstTowers = [];
	public lstDocument = [];

	public Item: any;

	public httpOptions: any;

	public submitted = false;
	public isCreatePage = true;
	public isShowFilter = false;
	public isShowCategoryTable = true;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	public domainImage = domainImage;

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
		this.Item = {};
		this.Item.lstTower = [];

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "Id Desc";
		this.paging.item_count = 0;

		this.pagingDoc = new Paging();
		this.pagingDoc.page = 1;
		this.pagingDoc.page_size = 10;
		this.pagingDoc.query = "";
		this.pagingDoc.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";
		this.q.ProjectId = null;
		this.q.Type = null;
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Danh sách cấu hình loại dịch vụ kỹ thuật");
		this.GetListCommon();
		this.GetListProject();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = '';
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and Name.Contains("' + this.q.txtSearch.toLowerCase() + '")';
			}
			else {
				query += 'Name.Contains("' + this.q.txtSearch.toLowerCase() + '")';

			}
		}

		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(TowerId) {
		if (TowerId == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	//Get danh sách chức năng
	GetListCommon() {
		console.log('GetListCommon');
		this.http.get(ApiConstant.getListTechnicalPackage + '?page=' + this.paging.page +
			'&pageSize=' + this.paging.page_size +
			'&query=' + this.paging.query +
			'&order_by=' + this.paging.order_by +
			(this.q.ProjectId > 0 ? ('&project=' + this.q.ProjectId) : '') +
			(this.q.TowerId > 0 ? ('&tower=' + this.q.TowerId) : '')).subscribe(
				(res) => {
					this.listCommon = res["data"].map(item => {
						return {
							...item,
							ProjectId: +item.Project,
							Towers: item.TechnicalPackageTowerMaps
						}
					});
					this.paging.item_count = res["totalRecords"];
					this.paging.page_size = res["pageSize"];
					this.paging.page = res["page"];
				},
				(err) => {
					console.log("Error: connect to API");
				});
	}

	OpenCommonModal(item) {

		this.submitted = false;
		this.Item = {};
		this.Item.lstTower = [];

		this.pagingDoc.page_size = 500;
		this.pagingDoc.page = 1;


		if (item) {
			this.isCreatePage = false;
			this.Item = { ...item };
			this.GetListTower(true, this.Item.Towers);
			this.GetListProject(item);
			console.log('item', this.Item);
		} else {
			this.isCreatePage = true;
			this.lstTowers = [];
			this.GetListTower(true, undefined);
			this.GetListProject();
		}

		this.modalCommon.show();
	}

	OpenDocumentModal(item) {
		this.submitted = false;
		this.Item = {};

		if (item) {
			this.Item = { ...item };
		}

		this.modalDocument.show();
	}

	SaveCommon() {
		this.submitted = true;
		let lstTowerSelected = this.lstTowers.filter(s => s.Selected === true);
		lstTowerSelected = lstTowerSelected.map(item => {
			return {
				Tower: item.TowerId,
				TowerName: item.Name,
			};
		})
		if (!lstTowerSelected || !lstTowerSelected.length) {
			this.submitted = false;
			this.toastWarning("Vui lòng chọn ít nhất 1 tòa nhà.");
			return;
		}
		let data = {
			Name: this.Item.Name,
			Price: +this.Item.Price,
			Unit: this.Item.Unit,
			Description: this.Item.Description || '',
			Towers: lstTowerSelected
		};
		this.http.put(ApiConstant.updateTechnicalPackage + this.Item.Id, data).subscribe(
			(res) => {
				this.submitted = false;
				this.GetListCommon();
				this.modalCommon.hide();
				this.toastSuccess("Cập nhật thành công!");
				this.form.resetForm();
			},
			(err) => {
				this.submitted = false;
				this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
			}
		);
	}

	SaveDocument() {
		this.submitted = true;
		let project = this.projects.find(item => item.ProjectId === this.Item.ProjectId);
		let lstTowerSelected = this.lstTowers.filter(s => s.Selected === true);
		lstTowerSelected = lstTowerSelected.map(item => {
			return {
				Tower: item.TowerId,
				TowerName: item.Name,
			};
		})
		if (!lstTowerSelected || !lstTowerSelected.length) {
			this.submitted = false;
			this.toastWarning("Vui lòng chọn ít nhất 1 tòa nhà.");
			return;
		}
		const id = this.Item.Id || undefined;
		let data = {
			Name: this.Item.Name,
			Price: this.Item.Price,
			Unit: this.Item.Unit,
			Description: this.Item.Description || '',
			Project: project.ProjectId,
			ProjectName: project.Name,
			TechnicalPackageTowerMaps: lstTowerSelected
		};
		if (id == undefined) {
			this.http.post(ApiConstant.createTechnicalPackage, data).subscribe(
				(res) => {
					this.submitted = false;
					this.GetListCommon();
					this.pagingDoc.page = 1;
					this.pagingDoc.page_size = 10;

					this.modalDocument.hide();
					this.toastSuccess("Thêm mới thành công!");
					this.formDoc.resetForm();
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		} else {
			this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(ApiConstant.deleteTechnicalPackage + Id).subscribe(
							(res) => {
								this.GetListCommon();
								this.viewRef.clear();
								this.toastSuccess("Xóa thành công!");
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	openToggleFilter() {
		this.isShowFilter = !this.isShowFilter;
	}

	//Danh sách dự án
	GetListProject(itemSelected?) {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name').subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.projects = res["data"];
					this.projects = this.projects.map(item => {
						const findItem = this.projects.find(item => +item.ProjectId === item.Project);
						return {
							...item,
							selected: findItem ? true : false
						}
					})
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	//Danh sách tòa nhà
	GetListTower(OpenUpdate, listData) {
		this.lstTowers = [];
		const projectId = this.Item.ProjectId | this.q.ProjectId;
		if (projectId) {
			let query = "ProjectId=" + projectId;
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.lstTowers = res["data"];
						this.lstTowers.forEach(item => {
							if (listData != undefined) {
								item.Selected = false;
								const find = listData.find(i => +i.Tower === +item.TowerId);
								if (find) {
									item.Selected = true;
								}
							} else {
								item.Selected = true;
							}
						});
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	onChangePrice(value) {
		this.Item.Price = value.replace(/[^0-9.-]+/g, '');
	}

}
