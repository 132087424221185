import { QueryFilter, Paging } from '../data/dt';
import { RequestCleaning } from '../data/Model';
import { HttpClient } from '@angular/common/http';
import { TypeRequestCleaning, ApiConstant } from '../data/const';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CleaningNewService {

  constructor(private readonly http: HttpClient) { }

  getDocumentCategoryByType(type: TypeRequestCleaning, towerId: number, projectId: number) {
    return this.http.get(ApiConstant.getCategoryDocByTypeAndProjectId +
      `&type=${type}&projectId=${projectId}&towerId=${towerId}`);
  }

  createCleaningRequest(data: FormData) {
    return this.http.post(ApiConstant.createCleaning, data);
  }

  updateCleaningRequest(id: any, formData: FormData) {
    return this.http.put(ApiConstant.updateCleaning + id, formData);
  }

  confirmAcceptanceCleaningRequest(id: number, dateAcceptance: Date, note: string) {
    return this.http.put(ApiConstant.confirmAcceptanceRequestCleaning + id, {
      dateAcceptance: dateAcceptance,
      note: note
    });
  }

  updateConfirmStatus(id: number, payload: any) {
    return this.http.put(ApiConstant.updateConfirmStatusRequestCleaning + id, payload);
  };

  getByPage(pageOption: QueryFilter, page: Paging, statusPayment: number | null, typePayment: number | null): Observable<any> {
    let query = `?page=${page.page}&pageSize=${page.page_size}`;

    if (pageOption.ProjectId > 0) {
      query = query.concat(`&Project=${pageOption.ProjectId}`);
    }

    if (pageOption.TowerId > 0) {
      query = query.concat(`&Tower=${pageOption.TowerId}`);
    }

    if (pageOption.FloorId > 0) {
      query = query.concat(`&Floor=${pageOption.FloorId}`);
    }

    if (pageOption.ApartmentId > 0) {
      query = query.concat(`&Apartment=${pageOption.ApartmentId}`);
    }

    if (pageOption.DateTimeStart) {
      query = query.concat(`&StartDate=${pageOption.DateTimeStart}`);
    }

    if (pageOption.DateTimeEnd) {
      query = query.concat(`&EndDate=${pageOption.DateTimeEnd}`);
    }
    if (pageOption.StatusProcessId) {
      query = query.concat(`&Status=${pageOption.StatusProcessId}`);
    }
    if (statusPayment !== null && statusPayment !== undefined) {
      query = query.concat(`&IsPaid=${statusPayment}`);
    }
    if (typePayment !== null && typePayment !== undefined) {
      query = query.concat(`&PaymentMethod=${typePayment}`);
    }

    if (page.order_by) {
      query = query.concat(`&order_by=${page.order_by}`);
    }
    if (page.query) {
      query = query.concat(`&KeySearch=${page.query}`);
    }

    return this.http.get(`${ApiConstant.getListCleaning}${query}`);
  }

  detailById(id: number) {
    return this.http.get(ApiConstant.getDetailCleaning + id);
  }

  updatelById(id: number, item: any) {
    return this.http.put(ApiConstant.updateCleaning + id, item);
  }

  deleteById(id: number) {
    return this.http.delete(ApiConstant.deleteCleaning + id);
  }

  cancelById(id: number, message: string) {
    // return this.http.put(ApiConstant.cancelRequestCleaning + id, { note: message});
  }
}
