import { HeaderService } from './../../../service/header.service';
import { requestGroupResponse, resMotorcycleCard, resResidentCard, resCarCard } from '../../../data/Model';
import { CommonService } from './../../../service/common.service';
import { LstTypeRequest, LstStatusRequestGroup, ApiConstant, lstStatusPayment, lstStatusConfirmPayment, typeReason, statusCardRequestGroup, domainImage, domain } from './../../../data/const';
import { ResidentManagerService } from './../../../service/resident-manager.service';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { typeCardRequestGroup } from '../../../data/const';
import { requestGroup } from '../../../data/Model';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { Title } from '@angular/platform-browser';
import { CancelRequestGroupComponent } from '../dialogs/cancel-request-group/cancel-request-group.component';


export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-edit-card-lock',
  templateUrl: './edit-card-lock.component.html',
  styleUrls: ['./edit-card-lock.component.scss'],
  providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class EditCardLockComponent implements OnInit {
  @ViewChild('file') file: ElementRef;
  public lstTypeRegister = [];
  public item: requestGroup;
  public apartmentIdChanged: number;
  public isCreatePage: boolean;
  public isResidentRequest: boolean = true;
  public isResidentCard: boolean = false;
  public isMotorcycleCard: boolean = false;
  public isCarCard: boolean = false;
  public typeRequest: typeCardRequestGroup;
  public lstResidentCard: resResidentCard[] = new Array();
  public lstMotorcleCard: resMotorcycleCard[] = new Array();
  public lstCarCard: resCarCard[] = new Array();
  public lstTypeRequest = [];
  public lstProject = [];
  public lstTower = [];
  public lstFloor = [];
  public lstVehicle = [];
  public lstApartment = [];
  public lstReason = [];
  public lstStatusRequest = LstStatusRequestGroup;
  public lstStatusPayment = lstStatusPayment;
  public lstStatusConfirmPayment = lstStatusConfirmPayment;
  public lstResidentCardEdit = new Array<number>();
  public lstMotorcycleCardEdit = new Array<number>();
  public lstCarCardEdit = new Array<number>();
  public oldProcessStatus = 0;
  public oldTypePayment = 0;
  public isLoading = false;
  public isLoadingLock = false;
  public domainImage = domainImage;
  public isWarningDateAppointment = false;
  public isWarningDateFinish = false;
  public isWarningStatus = false;
  public isWarningMoney = false;
  public isWarningPayment = false;
  public isWarningAdminReceived = false;
  public isWarningProjectId = false;
  public isWarningTower = false;
  public isWarningFloor = false;
  public isWarningApartment = false;
  public isWarningReason = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal,
    private readonly residentService: ResidentManagerService,
    private readonly commonService: CommonService,
    private readonly routerUrl: Router,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
    private gallery: Gallery,
    private readonly headerService: HeaderService,
    public titleService: Title,
  ) {
    this.lstTypeRequest = LstTypeRequest.filter((i)=> i.Id != -1);
    console.log('this.lstTypeRequest');
    console.log(this.lstTypeRequest);

    this.item = new requestGroup();
    this.item.type = 7;
    this.item.files = [];
    this.item.processStatus = 1;
    this.lstStatusRequest = this.lstStatusRequest.filter(s => s.Id >= 1 && s.Id != 6 && s.Id != 7 && s.Id != 8);

    this.item.registrationDate = new Date();
    this.item.responseAdmin = new requestGroupResponse();
    this.item.responseAdmin.statusPayment = lstStatusPayment[0].Id;
    this.item.responseAdmin.statusAdminConfirm = lstStatusConfirmPayment[0].Id;
    this.item.responseAdmin.money = 0;
   }

  ngOnInit() {
    this.item.processStatus = 1;
    [...this.lstStatusRequest.map(s => s.disabled = false)];

    this.titleService.setTitle("S Plus - Đăng ký khóa thẻ");
    const id = this.route.snapshot.paramMap.get('id');
    this.GetListProject();
    this.getLstVehicle();
    this.getLstReason();

    if (id) {
      this.isCreatePage = false;
      this.onGetDetail(id);
    } else {
      this.isCreatePage = true;
    }

    setInterval(() => this.onCheckIsShowWarning(), 200);
  }

  onSubmit() {
    this.isWarningDateAppointment = false;
    this.isWarningDateFinish = false;
    this.isWarningStatus = false;
    this.isWarningMoney = false;
    this.isWarningPayment = false;
    this.isWarningAdminReceived = false;
    this.isWarningProjectId = false;
    this.isWarningTower = false;
    this.isWarningFloor = false;
    this.isWarningApartment = false;
    this.isWarningReason = false;

    if (!this.item.projectId) {
      this.isWarningProjectId = true;
      this.toastWarning("Vui lòng chọn dự án");
      return;
    } else if (!this.item.towerId) {
      this.isWarningTower = true;
      this.toastWarning("Vui lòng chọn tòa nhà");
      return;
    } else if (!this.item.floorId) {
      this.isWarningFloor = true;
      this.toastWarning("Vui lòng chọn tầng");
      return;
    } else if (!this.item.apartmentId) {
      this.isWarningApartment = true;
      this.toastWarning("Vui lòng chọn căn hộ");
      return;
    } else if (!this.item.reasonId) {
      this.isWarningReason = true;
      this.toastWarning("Vui lòng chọn lý do khóa thẻ");
      return;
    } else if (this.lstResidentCard.findIndex(s => s.isChecked == true) < 0 &&
          this.lstMotorcleCard.findIndex(s => s.isChecked == true) < 0 &&
          this.lstCarCard.findIndex(s => s.isChecked == true) < 0) {
      this.toastWarning("Vui lòng chọn ít nhất 1 thẻ khóa");
      return;
    }
    else if (this.item.responseAdmin.appointmentDate &&
    this.item.registrationDate > this.item.responseAdmin.appointmentDate) {
      this.isWarningDateAppointment = true;
      this.toastWarning("Ngày hẹn trả thẻ không được trước ngày đăng ký");
      return;
    } else if (this.item.responseAdmin.finishDate &&
      this.item.responseAdmin.appointmentDate &&
      this.item.responseAdmin.finishDate < this.item.responseAdmin.appointmentDate) {
      this.isWarningDateFinish = true;
      this.toastWarning("Ngày trả thẻ không được trước ngày hẹn trả");
      return;
    } else if (this.item.processStatus == 6 &&
      (!this.item.responseAdmin.money ||
        this.item.responseAdmin.money === 0)) {
      this.isWarningMoney = true;
      this.toastWarning("Vui lòng nhập số phí phải trả để tiếp tục!");
      return;
    }

    this.item.status = LstStatusRequestGroup.find(s => s.Id == 6).Id;
    this.item.reasonName = this.lstReason.find(s => s.id == this.item.reasonId).name;

    this.item.residentCards = this.setResidentCards();
    this.item.motorcycleCards = this.setMotorcycleCards();
    this.item.carCards = this.setCarCards();
    this.item.files = [...this.item.files];

    this.isLoading = true;
    if (this.isCreatePage) {
      this.http.post(ApiConstant.CreateRequestLock, this.item).subscribe(
        (res) => {
          this.isLoading = false;
          this.isCreatePage = false;

          if (res['meta']['error_code'] == 200) {
            this.toastSuccess("Tạo yêu cầu khóa thẻ thành công");

            this.routerUrl.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
              this.routerUrl.navigate([`/utilities/resident-service`]);
            });
          } else {
            this.toastError(res["meta"]["error_message"]);
          }
        },(err) => {
          this.isLoading = false;
          this.toastError(err["meta"]["error_message"]);
        })
    } else {
      this.http.put(ApiConstant.UpdateRequestLock + this.item.id, this.item).subscribe(
        (res) => {
          this.isLoading = false;
          if (res['meta']['error_code'] == 200) {
            this.toastSuccess("Cập nhật thành công!");

            this.routerUrl.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
              this.routerUrl.navigate([`/utilities/resident-service`]);
            });
          } else {
            this.toastError(res["meta"]["error_message"]);
          }
        },(err) => {
          this.isLoading = false;
          this.toastError(err["meta"]["error_message"]);
        })
    }

  }

  onCheckIsShowWarning() {
    if (this.apartmentIdChanged > 0 &&
      this.isCreatePage &&
      ( this.lstResidentCard.findIndex(s => s.isChecked === true) >= 0 ||
        this.lstCarCard.findIndex(s => s.isChecked === true) >= 0 ||
        this.lstMotorcleCard.findIndex(s => s.isChecked === true) >= 0)) {
          this.headerService.showWarning(true);
        } else {
          this.headerService.showWarning(false);
        }
  }

  onChangeProject() {
    if (this.item.projectId > 0) {
      this.lstResidentCard = [];
      this.lstMotorcleCard = [];
      this.lstCarCard = [];
      this.item.towerId = null;
      this.item.floorId = null;
      this.item.apartmentId = null;
      this.item.apartmentName = '';
      this.item.residentId = null;
      this.item.residentPhone = '';
      this.item.residentName = '';
      this.apartmentIdChanged = null;
    }

    this.GetListTower();
  }

  setResidentCards() {
    let lstCard = [];

    for(let i = 0; i < this.lstResidentCard.length; i ++) {
      let itemCard = this.lstResidentCard[i];

      if (itemCard.isChecked) {
        lstCard.push({
          residentCardId: itemCard.residentCardId,
          residentId: itemCard.residentId,
          residentName: itemCard.residentName,
          cardId: itemCard.cardId,
          cardName: itemCard.cardName,
          birthday: itemCard.birthday || null,
          gender: itemCard.gender || '',
          phone: itemCard.phone,
          identifyType: itemCard.identifyType || 0,
          identifyLoc: itemCard.identifyLoc || '',
          identifyCode: itemCard.identifyCode || '',
          identifyCreate: itemCard.identifyCreate || null,
          relationshipId: itemCard.relationshipId || 0,
          relationshipName: itemCard.relationshipName || '',
          files: [],
        });
      }
    }

    return lstCard;
  }

  setMotorcycleCards() {
    let lstCard = [];

    for(let i = 0; i < this.lstMotorcleCard.length; i ++) {
      let itemCard = this.lstMotorcleCard[i];

      if (itemCard.isChecked) {
        lstCard.push({
          residentCardId: itemCard.residentCardId,
          residentCardIntegratedId: itemCard.residentCardIntegratedId,
          residentId: itemCard.residentId,
          residentName: itemCard.residentName,
          cardId: itemCard.cardId,
          cardName: itemCard.cardName,
          vehicleOwner: itemCard.vehicleOwner || null,
          vehicleBrand: itemCard.vehicleBrand || '',
          vehicleName: itemCard.vehicleName || '',
          vehicleNumerPlate: itemCard.vehicleNumerPlate || '',
          vehicleColor: itemCard.vehicleColor || '',
          vehicleDes: itemCard.vehicleDes || '',
          vehicleTypeId: itemCard.vehicleTypeId || 0,
          files: [],
        });
      }
    }

    return lstCard;
  }

  setCarCards() {
    let lstCard = [];

    for(let i = 0; i < this.lstCarCard.length; i ++) {
      let itemCard = this.lstCarCard[i];

      if(itemCard.isChecked) {
        lstCard.push({
          residentCardId: itemCard.residentCardId,
          residentId: itemCard.residentId,
          residentName: itemCard.residentName,
          cardId: itemCard.cardId,
          cardName: itemCard.cardName,
          vehicleOwner: itemCard.vehicleOwner || null,
          vehicleBrand: itemCard.vehicleBrand || '',
          vehicleName: itemCard.vehicleName || '',
          vehicleNumerPlate: itemCard.vehicleNumerPlate || '',
          vehicleColor: itemCard.vehicleColor || '',
          vehicleDes: itemCard.vehicleDes || '',
          vehicleTypeId: itemCard.vehicleTypeId || 0,
          files: [],
        })
      }
    }

    return lstCard;
  }

  getLstCardByApartmentId(apartmentId: number) {
    this.http.get(ApiConstant.GetCardByApartmentId + apartmentId).subscribe(
      (res) => {
        if (res['meta']['error_code'] == 200) {
          if (!res['data'] ||
          (!res['data']['residentCards'].length && !res['data']['motorcycleCards'].length && !res['data']['carCards'].length)
          ) {
            if (this.isCreatePage){
              this.toastWarning("Căn hộ này chưa có dữ liệu thẻ, vui lòng kiểm tra lại!");
              return;
            } else {
              this.lstMotorcleCard = [...this.item['motorcycleCards']];
              this.lstMotorcleCard.map(s => s.isChecked = true);
              this.lstResidentCard = [...this.item['residentCards']];
              this.lstResidentCard.map(s => s.isChecked = true);
              this.lstCarCard = [...this.item['carCards']];
              this.lstCarCard.map(s => s.isChecked = true);
            }
          } else {
            if (!this.isCreatePage) {
              for(let j = 0; j < res['data']['residentCards'].length; j ++) {
                let itemCard = res['data']['residentCards'][j];
                itemCard.isChecked = false;

                let isExistCreated = this.item.residentCards.findIndex(s => s.residentCardId === itemCard.residentCardId);

                if (isExistCreated >= 0) {
                  this.item.residentCards[isExistCreated].cardId = itemCard.cardId || 0;
                  this.item.residentCards[isExistCreated].cardName = itemCard.cardName || '';
                  this.item.residentCards[isExistCreated]['isChecked'] = true;
                  this.item.residentCards[isExistCreated].relationshipName = itemCard.relationshipName;
                  this.item.residentCards[isExistCreated].residentName = itemCard.residentName;
                  this.item.residentCards[isExistCreated].birthday = itemCard.birthday;
                  this.item.residentCards[isExistCreated].phone = itemCard.phone;
                  this.item.residentCards[isExistCreated].identifyCode = itemCard.identifyCode;
                  this.item.residentCards[isExistCreated].gender = itemCard.gender;
                } else {
                  this.item.residentCards.push(itemCard);
                }
              }

              for(let k = 0; k < res['data']['motorcycleCards'].length; k ++) {
                let itemCard = res['data']['motorcycleCards'][k];
                itemCard.isChecked = false;

                let isExistCreated = this.item.motorcycleCards.findIndex(s => s.residentCardId == itemCard.residentCardId);

                if (isExistCreated >= 0) {
                  this.item.motorcycleCards[isExistCreated].cardId = itemCard.cardId || 0;
                  this.item.motorcycleCards[isExistCreated].cardName = itemCard.cardName || '';
                  this.item.motorcycleCards[isExistCreated]['isChecked'] = true;

                  let isIntergrated = this.item.residentCards.findIndex(s => s.residentCardId == itemCard.residentCardIntegratedId && s.cardId == itemCard.cardId);

                  if (isIntergrated >= 0) {
                    this.item.motorcycleCards[isExistCreated].isChecked = true;
                    this.item.motorcycleCards[isExistCreated].disabled = true;
                  }
                } else {
                  this.item.motorcycleCards.push(itemCard);
                }
              }

              for(let l = 0; l < res['data']['carCards'].length; l ++) {
                let itemCard = res['data']['carCards'][l];
                itemCard.isChecked = false;

                let isExistCreated = this.item.carCards.findIndex(s => s.residentCardId == itemCard.residentCardId);

                if (isExistCreated >= 0) {
                  this.item.carCards[isExistCreated].cardId = itemCard.cardId || 0;
                  this.item.carCards[isExistCreated].cardName = itemCard.cardName || '';
                  this.item.carCards[isExistCreated]['isChecked'] = true;
                } else {
                  this.item['carCards'].push(itemCard);
                }
              }

              this.lstMotorcleCard = [...this.item['motorcycleCards']];
              this.lstResidentCard = [...this.item['residentCards']];
              this.lstCarCard = [...this.item['carCards']];
            } else {
              this.lstMotorcleCard = res['data']['motorcycleCards'];
              this.lstMotorcleCard.map(s => s.isChecked == false);
              this.lstResidentCard = res['data']['residentCards'];
              this.lstResidentCard.map(s => s.isChecked == false);
              this.lstCarCard = res['data']['carCards'];
              this.lstCarCard.map(s => s.isChecked == false);
            }

            // if (!this.isCreatePage) {
            //   for(let j = 0; j < res['data']['residentCards'].length; j ++) {
            //     let itemCard = res['data']['residentCards'][j];

            //     let isExistCreated = this.item.residentCards.findIndex(s => s.residentCardId == itemCard.residentCardId);

            //     if (isExistCreated >= 0) {
            //       this.item['residentCards'][isExistCreated].cardId = itemCard.cardId || 0;
            //       this.item['residentCards'][isExistCreated].cardName = itemCard.cardName || '';
            //     }
            //   }

            //   for(let j = 0; j < res['data']['motorcycleCards'].length; j ++) {
            //     let itemCard = res['data']['motorcycleCards'][j];

            //     let isExistCreated = this.item.motorcycleCards.find(s => s.residentCardId == itemCard.residentCardId);

            //     if (isExistCreated >= 0) {
            //       this.item['motorcycleCards'][isExistCreated].cardId = itemCard.cardId || 0;
            //       this.item['motorcycleCards'][isExistCreated].cardName = itemCard.cardName || '';
            //     }
            //   }

            //   for(let j = 0; j < res['data']['carCards'].length; j ++) {
            //     let itemCard = res['data']['carCards'][j];

            //     let isExistCreated = this.item.carCards.find(s => s.residentCardId == itemCard.residentCardId);

            //     if (isExistCreated >= 0) {
            //       this.item['carCards'][isExistCreated].cardId = itemCard.cardId || 0;
            //       this.item['carCards'][isExistCreated].cardName = itemCard.cardName || '';
            //     }
            //   }

            //   this.item.motorcycleCards.map(s => s.isChecked == true);
            //   this.item.residentCards.map(s => s.isChecked == true);
            //   this.item.carCards.map(s => s.isChecked == true);

            //   this.lstMotorcleCard = [...this.item.motorcycleCards];
            //   console.log(this.item.residentCards);
            //   this.lstResidentCard = [...this.item.residentCards];
            //   this.lstCarCard = [...this.item.carCards];
            // } else {
            //   this.lstMotorcleCard = res['data']['motorcycleCards'];
            //   this.lstMotorcleCard.map(s => s.isChecked == false);
            //   this.lstResidentCard = res['data']['residentCards'];
            //   this.lstResidentCard.map(s => s.isChecked == false);
            //   this.lstCarCard = res['data']['carCards'];
            //   this.lstCarCard.map(s => s.isChecked == false);
            // }


          }
        } else {
          this.toastError(res["meta"]["error_message"]);
        }
      })
  }

  onChangeResidentCard(itemCard, index){
    if (itemCard.isChecked) {
      let itemCardIntegrated = this.lstMotorcleCard.findIndex(s => s.cardId === itemCard.cardId && s.residentCardIntegratedId === itemCard.residentCardId);

      if (itemCardIntegrated >= 0) {
        this.modalDialogService.openDialog(this.viewRef, {
          title: 'Xác nhận',
          settings: {
          footerClass: 'footer-dialog modal-footer'
        },
          childComponent: SimpleModalComponent,
          data: {
            text: `Thẻ xe máy <u>${this.lstMotorcleCard[itemCardIntegrated].vehicleNumerPlate}</u> của <b>${this.lstMotorcleCard[itemCardIntegrated].vehicleOwner}</b> tích hợp vào thẻ cư dân này cũng sẽ bị khóa. Bạn có chắc không?`
          },
          actionButtons: [
            {
              text: 'Quay lại',
              buttonClass: 'btn btn-default',
              onAction: () => {
                this.lstResidentCard[index].isChecked = false;
                this.lstMotorcleCard[itemCardIntegrated].disabled = false;

                this.viewRef.clear();
              }
            },
            {
              text: 'Đồng ý',
              buttonClass: 'btn btn-success',
              onAction: () => {
                this.lstMotorcleCard[itemCardIntegrated].isChecked = true;
                this.lstMotorcleCard[itemCardIntegrated].disabled = true;

                this.viewRef.clear();
              }
            }
          ],
        });
      }
    } else {
      let itemCardIntegrated = this.lstMotorcleCard.findIndex(s => s.cardId === itemCard.cardId && s.residentCardIntegratedId === itemCard.residentCardId);

      if (itemCardIntegrated >= 0) {
        this.lstMotorcleCard[itemCardIntegrated].isChecked = false;
        this.lstMotorcleCard[itemCardIntegrated].disabled = false;
      }
    }
  }

  GetListProject() {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.projectId = null;
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;

		return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  GetListTower() {
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;
    this.item.residentId = null;
    this.item.residentPhone = '';
    this.item.residentName = '';
    this.lstResidentCard = [];
    this.lstMotorcleCard = [];
    this.lstCarCard = [];

		return this.commonService.getLstTower(this.item.projectId).subscribe(
      (res) => {
        this.lstTower = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  getLstVehicle(){
    return this.commonService.getLstAllVehicle(0).subscribe(
      (res) => {
        this.lstVehicle = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getLstReason(){
    return this.commonService.getLstReason(typeReason.LOCK).subscribe(
      (res) => {
        this.lstReason = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getVehicleName(vehicleId: number) {
    var itemVehicle = this.lstVehicle.find(s => s["id"] === vehicleId);

    if (itemVehicle) {
      return itemVehicle["name"];
    } else {
      return '';
    }
  }

  GetListFloor() {
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.floorId = null;
    this.item.apartmentId = null;

		return this.commonService.getLstFloor(this.item.towerId).subscribe(
      (res) => {
        this.lstFloor = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  GetListApartment() {
    this.lstApartment = [];
    this.item.apartmentId = null;

		return this.commonService.getLstApartment(this.item.floorId).subscribe(
      (res) => {
        if (this.isCreatePage) {
          for (let i = 0; i < res.length; i ++) {
            this.http.get(ApiConstant.CheckExistResidentCard + res[i]['ApartmentId']).subscribe(
              (lstRs) => {
                if (lstRs['data'].length) {
                  this.lstApartment.push(res[i]);
                  this.lstApartment = [...this.lstApartment];
                }
              },
              (err) => {
                this.toastError(err['meta']['error_message']);
              });
          }
        } else {
          this.lstApartment = res;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  getPurchaserByApartmentId(value) {
    this.item.apartmentId = value.ApartmentId;
    this.item.apartmentName = value.Name;

    if (this.item.apartmentId > 0 &&
      (this.lstResidentCard.findIndex(s => s.isChecked) >= 0 ||
       this.lstMotorcleCard.findIndex(s => s.isChecked) >= 0 ||
       this.lstCarCard.findIndex(s => s.isChecked) >= 0)) {
        this.modalDialogService.openDialog(this.viewRef, {
          title: 'Xác nhận',
          settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
          data: {
            text: "Dữ liệu đăng ký thẻ bên dưới sẽ bị mất. Bạn có muốn thay đổi không?"
          },
          actionButtons: [
            {
              text: 'Hủy',
              buttonClass: 'btn btn-default',
              onAction: () => {
                this.apartmentIdChanged = this.item.apartmentId;

                this.viewRef.clear();
              }
            },
            {
              text: 'Xác nhận',
              buttonClass: 'btn btn-success',
              onAction: () => {
                this.lstResidentCard = [];
                this.lstMotorcleCard = [];
                this.lstCarCard = [];

                this.commonService.getPurchaserByApartmentId(value.Id).subscribe(
                  (res) => {
                    if (res) {
                      this.item.apartmentId = value.ApartmentId;
                      this.item.apartmentName = value.Name;
                      this.item.residentId = res.ResidentId;
                      this.item.residentName = res.FullName;
                      this.item.residentPhone = res.Phone;

                      this.getLstCardByApartmentId(value.ApartmentId);
                    } else {
                      this.item.apartmentId = null;
                      this.item.apartmentName = '';
                      this.item.residentId = null;
                      this.item.residentName = '';
                      this.item.residentPhone = '';
                      this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                      return;
                    }
                  },
                  (err) => {
                    this.item.apartmentId = null;
                      this.item.apartmentName = '';
                      this.item.residentId = null;
                      this.item.residentName = '';
                      this.item.residentPhone = '';
                    this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                    return;
                  }
                )

                this.viewRef.clear();
              }
            }
          ],
        });
       } else {
        this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
          (res) => {
            if (res) {
              this.item.apartmentId = value.ApartmentId;
              this.item.apartmentName = value.Name;
              this.item.residentId = res.ResidentId;
              this.item.residentName = res.FullName;
              this.item.residentPhone = res.Phone;

              this.getLstCardByApartmentId(value.ApartmentId);
            } else {
              this.item.apartmentId = null;
              this.item.apartmentName = '';
              this.item.residentId = null;
              this.item.residentName = '';
              this.item.residentPhone = '';
              this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
              return;
            }
          },
          (err) => {
            this.item.apartmentId = null;
            this.item.apartmentName = '';
            this.item.residentId = null;
            this.item.residentName = '';
            this.item.residentPhone = '';
            this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
            return;
          }
        )
       }
  }

  onGetDetail(id) {
    this.residentService.getDetail(id).subscribe(
      (res) => {
        if (res) {
          this.item.id = res['id'];
          this.item.note = res['note'];
          this.item.info = res['info'];
          this.item.processStatus = res['processStatus'];
          this.item.status = res['status'];
          this.item.createdAt = res['createdAt'];
          this.item.updatedAt = res['updatedAt'];
          this.item.updatedById = res['updatedById'];
          this.item.createdById = res['createdById'];
          this.item.registrationDate = res['registrationDate'];
          this.item.type = res['type'];
          this.item.reasonId = res['reasonId'];
          this.item.reasonName = res['reasonName'];
          this.item.files = res['files'];
          this.item.carCards = res['carCards'];
          this.item.carCards.map(s => s.isChecked = true);
          this.item.motorcycleCards = res['motorcycleCards'];
          this.item.motorcycleCards.map(s => s.isChecked = true);
          this.item.residentCards = res['residentCards'];
          this.item.residentCards.map(s => s.isChecked = true);
          this.item.responseAdmin = res['responseAdmin'];
          this.oldProcessStatus = res['processStatus'];
          this.oldTypePayment = res['responseAdmin']['typePayment'] || 0;
          this.setDataDetail(res);

          for(let x = 0; x < this.lstStatusRequest.length; x ++) {
            if (this.lstStatusRequest[x].Id < this.item.processStatus) {
              this.lstStatusRequest[x].disabled = true;
            }
          }

          this.lstStatusRequest = [...this.lstStatusRequest];
        } else {

          this.modalDialogService.openDialog(this.viewRef, {
            title: 'Thông báo',
            settings: {
          footerClass: 'footer-dialog modal-footer'
        },
            childComponent: SimpleModalComponent,
            data: {
              text: "Yêu cầu đăng ký không tồn tại. Vui lòng kiểm tra lại!"
            },
            actionButtons: [
              {
                text: 'Quay lại',
                buttonClass: 'btn btn-info',
                onAction: () => {
                  this.returnPage();
                }
              },
            ],
          });
        }

      },
      (err) => {
        this.routerUrl.navigate(["**"])
      });
  }

  upload(files){
    if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.files.unshift({
              id: 0,
              fileId: 0,
              fileRef: '',
              url: element.toString()
            });
          });
        }
				if (this.file) this.file.nativeElement.value = "";
			}
		});
  }

  setDataDetail(item) {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.projectId = null;
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;

		this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
        this.item.projectId = item["projectId"];

        this.commonService.getLstTower(this.item.projectId).subscribe((res) => {
          this.lstTower = res;
          this.item.towerId = item["towerId"];

          this.commonService.getLstFloor(this.item.towerId).subscribe((res) => {
            this.lstFloor = res;
            this.item.floorId = item["floorId"];

            this.commonService.getLstApartment(this.item.floorId).subscribe((res) => {
              this.lstApartment = res;

              this.apartmentIdChanged = item["apartmentId"];
              this.item.apartmentId = item["apartmentId"];
              this.item.apartmentName = item["apartmentName"];
              this.item.residentId = item["residentId"];
              this.item.residentName = item["residentName"];

              this.getLstCardByApartmentId(this.item.apartmentId);

              this.getResidentPhone(this.item.apartmentId);
            })
          })
        })
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  openModelLock() {
    const dialofRefLock = this.modalService.open(CancelRequestGroupComponent, { size: 'lg' });
    dialofRefLock.componentInstance.id = this.item.id;

    dialofRefLock.componentInstance.response.subscribe((res) => {
      if (res) {
        this.routerUrl.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
          this.routerUrl.navigate([`/utilities/resident-service`]);
        });
      }
    });
  }

  getResidentPhone(apartmentId) {
    this.commonService.getPurchaserByApartmentId(apartmentId).subscribe(
      (res) => {
        if (res) {
          this.item.residentPhone = res.Phone;
        } else {
          this.item.residentPhone = '';
        }
      },
      (err) => {
        this.item.residentPhone = '';
      }
    )
  }

  onChangePrice(value) {
    this.item.responseAdmin.money = Number(value.replace(/[^0-9.-]+/g, ''));
  }

  returnPage() {
    this.location.back();
  }

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
      lstImage.push({path: domainImage + item.url})
    })

    let prop = {
        images: lstImage,
        index
    };
    this.gallery.load(prop);
  }

  onUnChecked(e) {
    if (!this.item.responseAdmin.typePayment || this.item.responseAdmin.typePayment === 0) {
      this.item.responseAdmin.typePayment = 1;
    } else {
      this.item.responseAdmin.typePayment = this.oldTypePayment == 2 ? 2 : 0;
    }
  }

  returnPagePaging() {
    this.routerUrl.navigate(['/utilities/resident-service']);
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}

}
