import { domain, domainApi } from './../data/const';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private readonly http: HttpClient) { }

  getLstProject() {
    return this.http.get(domainApi + '/project/GetActiveApartmentByPage?page=1').pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }

        return [];
      })
    );
  }

  getLstTower(projectId: number) {
    var query = projectId > 0 ? '&query=ProjectId=' + projectId : '';

    return this.http.get(domainApi + '/tower/GetByPage?page=1&page_size=50' + query).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }
        return [];
      })
    );
  };

  getLstFloor(towerId: number) {
    var query = towerId > 0 ? '&query=TowerId=' + towerId : '';

    return this.http.get(domainApi + '/floor/GetByPage?page=1' + query).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }

        return [];
      })
    );
  }

  getLstAllVehicle(type: number) {
    return this.http.get(domain + 'api/Reg/vehicleType?type=' + type).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }

        return [];
      })
    );
  }

  getLstReason(type: number) {
    return this.http.get(domain + 'api/Reg/cardReasons?type=' + type).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }

        return [];
      })
    );
  }

  getLstApartment(floorId: number) {
    var query = floorId > 0 ? '&query=FloorId=' + floorId : '';

    return this.http.get(domainApi + '/apartment/GetByPage?page=1&page_size=50' + query).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }

        return [];
      })
    );
  }

  getPurchaserByApartmentId(apartmentId: number) {
    return this.http.get(domain + 'api/app/category/GetMainApartmentAdmin/' + apartmentId).pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }

        return null;
      })
    );
  }

  getMemberInApartment(apartmentId: number, residentId: number) {
    return this.http.get(domainApi + '/resident/GetMemberApartmentAdmin/'+ apartmentId +"/"+ residentId +"/2?page=1&query=1=1&order_by=").pipe(
      map(res => {
        if (res['meta']['error_code'] == 200) {
          return res['data'];
        }

        return null;
      })
    );
  }

  CheckAccessKeyRole(Code: string, index: number) {
    let Str = localStorage.getItem("access_key");
    let Arr = [];
    Arr = Str.split('-');
    for (let i = 0; i < Arr.length; i++) {
      let ConvertArr = Arr[i].split(':');
      if (Code == ConvertArr[0]) {
        let check = ConvertArr[1].substr(index, 1);
        if (check == "1") {
          return true;
        }
        else {
          return false;
        }
      }
    }

    return false;
  }

  ConvertUrl(str) {
    str = str.toLowerCase();
    str = str.replace(/á|à|ả|ã|ạ|â|ấ|ầ|ẩ|ẫ|ậ|ă|ắ|ằ|ẳ|ẵ|ặ"/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    str = str.replace(/[^a-zA-Z0-9 ]/g, "");
    str = str.replace(/ + /g, " ");
    str = str.trim();
    str = str.replace(/ /g, "-");

    return str;
  }

}
