import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RentalApartmentProcessStatus, domainApi, RegConstructionStatus, statusCardRequestGroup, listPaymentMethod, RentalApartmentType, LstStatusRequestGroup, LstStatusPaymentRequestGroup, cancelStatus } from './../../../data/const';
import { RentalApartmentService } from './../../../service/rental-apartment';
import { Paging, QueryFilter } from './../../../data/dt';
import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { CommonService } from '../../../service/common.service';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { InterceptorService } from 'ng2-interceptors';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';
import { getFileNameFromResponseContentDisposition, saveFile } from '../../../service/file-download-helper';
import * as moment from 'moment';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-rental-apartment',
    templateUrl: './rental-apartment.component.html',
    styleUrls: ['./rental-apartment.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class RentalApartmentComponent implements OnInit {
    @ViewChild('toggleFilter') toggleFilter: ElementRef;

    public q: QueryFilter;
    public lstStatus = LstStatusRequestGroup;
    public lstStatusPayment = LstStatusPaymentRequestGroup;
    public lstFloors = [];
    public lstProjects = [];
    public lstTowers = [];
    public lstApartments = [];
    public lstResidentService = [];
    public listCommon = [];
    public paging: Paging;
    public isShowFilter = false;
    public numberItemDelete = 0;
    public isToggleTypeCreate = false;
    public CheckAll: boolean;
    public httpOptions: any;
    public regConstructionStatus = RegConstructionStatus;
    public status = statusCardRequestGroup;
    public messageError = '';
    public dateAppointment: Date;
    public dateFinish: Date;
    public statusPayment: number;
    public rentalApartmentType = RentalApartmentType;
    public is3rdPart = localStorage.getItem("roleCode") == "DVCTN" ? true : false;
    public rentalApartmentProcessStatus = RentalApartmentProcessStatus;
    public cancelStatus = cancelStatus;
    public minDate: object;
    public maxDate: object;
    public isProcessingExport: boolean;
    public typePayments = listPaymentMethod;
    
    constructor(
        public titleService: Title,
        private renderer: Renderer2,
        private readonly rentalApartmentService: RentalApartmentService,
        private readonly commonService: CommonService,
        public router: Router,
        private readonly http: HttpClient,
        public modalDialogService: ModalDialogService,
        private readonly toastr: ToastrService,
        private httpDownload: InterceptorService,
        public viewRef: ViewContainerRef,) {

        this.q = new QueryFilter();
        this.q.txtSearch = "";
        this.q.StatusProcessId = -1;
        this.q.TypeRequireId = 0;
        this.q.DateTimeStart = null;
        this.q.DateTimeEnd = null;

        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 15;

        this.q.TypeRequireId = null;
        this.q.StatusProcessId = null;

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Danh sách phiếu đăng kí dv cho thuê/bán nhà");
        this.onFilter();
        this.GetListProject();
        this.rentalApartmentProcessStatus.forEach(element => {
            element["disabled"] = false;
        })
    }

    GetListProject() {
        return this.commonService.getLstProject().subscribe(
            (res) => {
                this.lstProjects = res;
                if(this.lstProjects.length == 1) {
                    this.q.ProjectId = this.lstProjects[0].ProjectId;
                }

                this.QueryChangeProject();
                this.GetDepartmentFilter();
            },
            (err) => {
                console.log("Error: connect to API");
            }
        )
    }

    //Danh sách tòa nhà
    GetListTower(OpenUpdate) {
        this.lstTowers = [];
        if (!OpenUpdate) this.q.TowerId = undefined;
        if (this.q.ProjectId) {
            let query = "ProjectId=" + this.q.ProjectId;
            this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.lstFloors = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }

        this.GetListFloor(OpenUpdate);
    }

    //Danh sách tầng
    GetListFloor(OpenUpdate) {
        this.lstFloors = [];
        if (!OpenUpdate) this.q.FloorId = undefined;
        if (this.q.TowerId) {
            let query = "TowerId=" + this.q.TowerId;
            this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.lstFloors = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }

        this.GetListApartment(OpenUpdate);
    }

    //Danh sách tầng
    GetListApartment(OpenUpdate) {
        this.lstApartments = [];
        if (!OpenUpdate) this.q.ApartmentId = undefined;
        if (this.q.FloorId) {
            let query = "FloorId=" + this.q.FloorId;
            this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.lstApartments = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }

    }

    openToggleFilter() {
        this.isShowFilter = !this.isShowFilter;
    }


    openToggleTypeCreate() {
        this.isToggleTypeCreate = !this.isToggleTypeCreate;
    }

    onDetail(item) {
        this.router.navigate(['manager-service/rental-apartment/update/', item.id]);
    }

    onDelete(item: any) {
        if (item["processStatus"] == 21) {
            this.modalDialogService.openDialog(this.viewRef, {
                //backdrop: 'static',
                //keyboard: false,
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer',
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Bạn chắc muốn xóa đơn đăng ký này không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',
                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            this.http.delete(domainApi + "/RequestGroup/RequestRentalApartmentDelete/" + item["id"])
                                .subscribe(
                                    (res) => {
                                        if (res["meta"]["error_code"] == 200) {
                                            this.toastSuccess("Xóa thành công!");
                                            this.onFilter();
                                        }
                                    },
                                    (err) => {
                                        console.log("Error: connect to API");
                                    });

                            this.viewRef.clear();
                        }
                    }
                ],

            });
        }
        else {
            this.toastWarning("Không thể xóa! yêu cầu này đã khóa.")
        }
    }

    onDeletes() {
        let data = [];
        this.listCommon.forEach(item => {
            if (item.isChecked == true) {
                data.push(item.id);
            }
        });

        if (data.length > 0) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer'
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Bạn chắc muốn xóa các đơn đăng ký dv cho thuê/bán nhà đã chọn không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',
                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            for (let i = 0; i < data.length; i++) {
                                this.http.delete(domainApi + "/RequestGroup/RequestRentalApartmentDelete/" + data[i])
                                    .subscribe(
                                        (res) => {
                                            if (res["meta"]["error_code"] == 200) {
                                                this.toastSuccess("Xóa thành công!");
                                                if (i === (data.length - 1)) {
                                                    this.onFilter();
                                                }
                                            }
                                        },
                                        (err) => {
                                            this.toastWarning(`Xóa danh sách Đơn đăng ký dv cho thuê/bán nhà thất bại. ${err["meta"]["error_message"]}`);
                                            if (i === (data.length -1)) {
                                                this.onFilter();
                                            }
                                        });
                            }

                            this.viewRef.clear();
                        }
                    }
                ],
            });
        }
    }

    PageChanged(event) {
        this.paging.page = event.page;
        this.CheckAll = false;

        this.onFilter();
    }

    QueryChangeFloor() {
        this.q["ApartmentId"] = undefined;
        this.lstApartments = [];
        if (this.q["FloorId"]) {
            let query = "FloorId=" + this.q["FloorId"];
            this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.lstApartments = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }
    }

    onFilter() {
        this.CheckAll = false;
        this.messageError = '';
        this.listCommon = [];
        this.numberItemDelete = 0;
        this.q.StatusProcessId = this.q.StatusProcessId || null;

        if (this.q.DateTimeStart && !this.q.DateTimeEnd) {
            this.q.DateTimeEnd = new Date();
        } else if (!this.q.DateTimeStart && this.q.DateTimeEnd) {
            this.toastWarning("Vui lòng chọn ngày bắt đầu lọc");
            return;
        }

        this.rentalApartmentService.getByPage(this.q, this.paging, this.dateAppointment, this.dateFinish, this.statusPayment).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                if (res["data"]["results"].length) {
                    this.listCommon = res["data"]["results"];
                    this.listCommon.map(s => s.isChecked = false);
                    this.paging.item_count = res["data"]["rowCount"];
                } else {
                    this.listCommon = [];
                    this.paging.item_count = 0;
                    this.messageError = 'Không có dữ liệu';
                }
            }
            else {
                this.listCommon = [];
                this.paging.item_count = 0;
                this.messageError = 'Không có dữ liệu';
            }
        },
            (error) => {
                this.toastError('Api Error!');
                this.paging.item_count = 0;
            })
    }

    QueryChangeProject() {
        this.q["TowerId"] = undefined;
        this.lstTowers = [];
        if (this.q["ProjectId"]) {
            let query = "ProjectId=" + this.q["ProjectId"];
            this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.lstTowers = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }

        this.QueryChangeTower();
    }

    GetDepartmentFilter() {

    }

    onRemoveDate(typeDate) {
        if (typeDate == 1) {
            this.q.DateTimeStart = null;
        } else {
            this.q.DateTimeEnd = null;
        }
    }

    QueryChangeTower() {

        this.q["FloorId"] = undefined;
        this.lstFloors = [];
        if (this.q["TowerId"]) {
            let query = "TowerId=" + this.q["TowerId"];
            this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
                (res) => {
                    if (res["meta"]["error_code"] == 200) {
                        this.lstFloors = res["data"];
                    }
                },
                (err) => {
                    console.log("Error: connect to API");
                });
        }

        this.QueryChangeFloor();
    }

    CheckActionTable(id) {
        if (id == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
                if(item.processStatus == 21 && item.rentalApartment.status != 10)
				    item.isChecked = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].isChecked && this.listCommon[i].processStatus == 21 && this.listCommon[i].rentalApartment.status != 10) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}

        this.numberItemDelete = this.listCommon.filter(x => x.isChecked == true).length;
    }

    onCheckDisabledCheckbox(status, rentalStatus) {
        return status == 21 && rentalStatus != 10 ? false : true;
    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    showRegConstructionStatus(constructionStatus) {
        let current = this.regConstructionStatus.filter(x => x.Id == constructionStatus)[0];
        return current != undefined ? current.Name : "";
    }

    showRentalApartmentType(Id) {
        let current = RentalApartmentType.filter(x => x.Id == Id)[0];
        return current != undefined ? current.Name : "";
    }

    checkMinMax(event, type) {
        if(event) {
            let currDate = new Date(event);
            if(type == 1) {
                this.minDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
            else {
                this.maxDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
        }
        else {
            if(type == 1) this.minDate = undefined;
            else this.maxDate = undefined;
        }
    }

    exportExcel() {
        this.isProcessingExport = true;

        let pageOption = this.q;
        let page = this.paging;

        let query = `?ProcessStatus=${pageOption.StatusProcessId || -1}&CurrentPage=${page.page}&PageSize=${page.page_size}`;

        if (pageOption.ProjectId > 0) {
        query = query.concat(`&ProjectId=${pageOption.ProjectId}`);
        }

        if (pageOption.TowerId > 0) {
        query = query.concat(`&TowerId=${pageOption.TowerId}`);
        }

        if (pageOption.FloorId > 0) {
        query = query.concat(`&FloorId=${pageOption.FloorId}`);
        }

        if (pageOption.ApartmentId > 0) {
        query = query.concat(`&ApartmentId=${pageOption.ApartmentId}`);
        }

        if (pageOption.DateTimeStart) {
        query = query.concat(`&DateStart=${ new Date(moment(pageOption.DateTimeStart.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}) }`);
        }

        if (pageOption.DateTimeEnd) {
        query = query.concat(`&DateEnd=${ new Date(moment(pageOption.DateTimeEnd.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}) }`);
        }

        if (page.query) {
        query = query.concat(`&query=${page.query}`);
        }

        if (this.dateAppointment) {
        query = query.concat(`appointmentDate=${this.dateAppointment}`);
        }

        if (this.dateFinish) {
        query = query.concat(`&finishDate=${this.dateFinish}`);
        }

        if (page.order_by) {
        query = query.concat(`&orderBy=${page.order_by}`);
        }

        if (this.statusPayment) {
        query = query.concat(`&statusPayment=${this.statusPayment}`);
        }

        if (pageOption.Type > 0) {
        query = query.concat(`&Type=${pageOption.Type}`);
        }
        else {
        query = query.concat(`&Type=-1`);
        }

        if (pageOption.CanCelStatus) {
        query = query.concat(`&Status=10`);
        }
        else {
        // query = query.concat(`&Status=-1`);
        }

        let url = `${domainApi}/RequestGroup/RequestRentalApartmentExportExcel${query}`;
        const options = new RequestOptions({
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            }),
            responseType: ResponseContentType.Blob,
        });

        this.httpDownload.get(url, options).subscribe(res => {
            const fileName = "ds-dang-ky-cho-thue-ban-nha.xlsx";
            saveFile(res.blob(), fileName);
            this.isProcessingExport = false;
        });
    }
}
