import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Notification, NotificationItem, NotificationDT } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi, TypeNotificationFee, TypeAttribute_Notification, TypeNotificationSend, TypeResident } from '../../../data/const';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-notification-fee',
    templateUrl: './notification-fee.component.html',
    styleUrls: ['./notification-fee.component.scss']
})
export class NotificationFeeComponent implements OnInit {

    @ViewChild('modalCommon') public modalCommon: ModalDirective;
    @ViewChild('modalHistory') public modalHistory: ModalDirective;
    @ViewChild('modalDetail') public modalDetail: ModalDirective;
    @ViewChild('f') public form: NgForm;
    @ViewChild('fDetail') public formDetail: NgForm;
    @ViewChild('file') file: ElementRef;

    public paging: Paging;
    public pagingHistory: Paging;
    public q: QueryFilter;

    public listCommon = [];
    public listType = TypeNotificationFee;
    public listTypeNotification = [];
    public listNotificationTemp = [];
    public listNotificationItem = [];
    public listProject = [];
    public listTower = [];
    public listFloor = [];
    public listApartment = [];
    public listResident = [];
    public listAttactment = [];
    public listHistory = [];
    public listMonth = [];
    public listYear = [];
    public listTypeNotificationSend = TypeNotificationSend;

    public Item: Notification;
    public ItemChild: NotificationItem;
    public ItemDetail: NotificationDT;

    public httpOptions: any;

    public submitted = false;

    public ActionTable = ActionTable;
    public ActionId: number;
    public CheckAll: boolean;

    public domainImage = domainImage;
    public typeResident = TypeResident;

    constructor(public http: HttpClient, public modalDialogService: ModalDialogService,
        public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
        this.Item = new Notification();
        this.ItemDetail = new NotificationDT();

        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 10;
        this.paging.query = "IsFee=true";
        // this.paging.order_by = "CreatedAt Desc";
        this.paging.item_count = 0;

        this.pagingHistory = new Paging();
        this.pagingHistory.page = 1;
        this.pagingHistory.page_size = 10;
        this.pagingHistory.query = "1=1";
        // this.pagingHistory.order_by = "CreatedAt Desc";
        this.pagingHistory.item_count = 0;

        this.q = new QueryFilter();
        this.q.txtSearch = "";

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Danh sách thông báo phí");
        this.GetListCommon();
        this.GetListProject();
        this.ListYear();
        this.ListMonth();
        this.GetListTypeNotification();
    }

    ListYear() {
        var date = new Date();
        for (let i = date.getFullYear(); i > 2015; i--) {
            let year = { "Name": "Năm " + i, "Id": i }
            this.listYear.push(year);
        }
    }

    ListMonth() {
        for (let i = 1; i < 13; i++) {
            let month = { "Name": "Tháng " + i, "Id": i }
            this.listMonth.push(month);
        }
    }

    ChangeDate() {
        if (this.Item.Year != undefined && this.Item.Month != undefined)
            this.Item.Title = "Thông báo phí dịch vụ tháng " + this.Item.Month + "/" + this.Item.Year;
    }

    //Chuyển trang
    PageChanged(event) {
        this.paging.page = event.page;
        this.GetListCommon();
    }
    //Toast cảnh báo
    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    QueryChanged() {
        let query = 'IsFee=true';
        if (this.q.Type != undefined) {
            query += ' and TypeNotificationId=' + this.q.Type;
        }
        if (this.q.StatusProcessId != undefined) {
            let type = true;
            if (this.q.StatusProcessId == 0)
                type = false;
            query += ' and SendNow=' + type;
        }
        if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
            query += ' and Title.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '")';
        }

        if (query == '')
            this.paging.query = '1=1';
        else
            this.paging.query = query;

        this.GetListCommon();
    }

    SortTable(str) {
        let First = "";
        let Last = "";
        if (this.paging.order_by != "") {
            First = this.paging.order_by.split(" ")[0];
            Last = this.paging.order_by.split(" ")[1];
        }

        if (First != str) {
            this.paging.order_by = str + " Desc";
        }
        else {
            Last = Last == "Asc" ? "Desc" : "Asc";
            this.paging.order_by = str + " " + Last;
        }

        this.GetListCommon();
    }

    GetClassSortTable(str) {
        if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
            return "sorting";
        }
        else {
            if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
            else return "sorting_asc";
        }
    }

    CheckActionTable(ProjectId) {
        if (ProjectId == undefined) {
            let CheckAll = this.CheckAll;
            this.listCommon.forEach(item => {
                item.Action = CheckAll;
            });
        }
        else {
            let CheckAll = true;
            for (let i = 0; i < this.listCommon.length; i++) {
                if (!this.listCommon[i].Action) {
                    CheckAll = false;
                    break;
                }
            }

            this.CheckAll = CheckAll == true ? true : false;
        }
    }

    ActionTableFunc() {
        switch (this.ActionId) {
            case 1:
                let data = [];
                this.listCommon.forEach(item => {
                    if (item.Action == true) {
                        data.push(item.NotificationId);
                    }
                });
                if (data.length == 0) {
                    this.toastWarning("Chưa chọn mục cần xóa!");
                }
                else {
                    this.modalDialogService.openDialog(this.viewRef, {
                        title: 'Xác nhận',
                        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
                        data: {
                            text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
                        },
                        actionButtons: [
                            {
                                text: 'Đồng ý',
                                buttonClass: 'btn btn-success',
                                onAction: () => {
                                    this.http.put(domainApi + '/notification/deletes', data, this.httpOptions).subscribe(
                                        (res) => {
                                            if (res["meta"]["error_code"] == 200) {
                                                this.toastSuccess("Xóa thành công!");
                                                this.GetListCommon();
                                                this.ActionId = undefined;
                                            }
                                            else {
                                                this.toastError(res["meta"]["error_message"]);
                                            }
                                        },
                                        (err) => {
                                            this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                                        }
                                    );
                                    this.viewRef.clear();
                                }
                            },
                            {
                                text: 'Đóng',
                                buttonClass: 'btn btn-default',
                            }
                        ],
                    });
                }
                break;
            default:
                break;
        }
    }

    //Get danh sách chức năng
    GetListCommon() {
        this.http.get(domainApi + '/notification/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listCommon = res["data"];
                    this.paging.item_count = res["metadata"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    OpenCommonModal(item) {
        //this.file.nativeElement.value = "";
        for (let i = 0; i < this.listProject.length; i++) {
            this.listProject[i].Check = false;
        }
        this.listAttactment = [];
        this.listApartment = [];
        this.listTower = [];
        this.listFloor = [];
        this.listResident = [];

        this.submitted = false;
        this.Item = new Notification();
        this.Item.SendNow = false;
        this.Item.TypeNotificationId = 200;
        this.Item["IsCheck"] = false;
        this.Item.Contents = "";

        if (item == undefined) {
        }
        else {
            this.Item = Object.assign(this.Item, item);
            this.Item.TimeSend = this.Item.DateSend;
            if (this.Item.Type != 1) {
                //    for (let i = 0; i < this.listProject.length; i++) {
                //        for (let j = 0; j < this.Item.notificationItems.length; j++) {
                //            if (this.listProject[i].ProjectId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 1) {
                //                this.listProject[i].Check = true;
                //            }
                //        }
                //    }
                //}
                //else {
                //    for (let i = 0; i < this.listProject.length; i++) {
                //        this.listProject[i].Check = false;
                //        for (let j = 0; j < this.Item.notificationItems.length; j++) {
                //            if (this.listProject[i].ProjectId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 1) {
                //                this.listProject[i].Check = true;
                this.GetListTower(1);
                //            break;
                //        }
                //    }
                //}

                //console.log(this.listProject);
            }
        }

        this.modalCommon.show();
    }

    SaveCommon() {
        this.submitted = true;
        //this.listProject.length
        this.listNotificationItem = [];
        if (this.Item.Type == 1) {
            //console.log(this.listProject);
            //this.listProject.forEach(item => {
            //    if (item.Check) {
            if (this.Item.ProjectId == undefined) {
                this.submitted = false;
                this.toastWarning("Bạn chưa chọn đối tượng nhận thông báo!");
                return;
            }
            this.ItemChild = new NotificationItem();
            this.ItemChild.TargetId = this.Item.ProjectId;
            this.ItemChild.TargetType = this.Item.Type;
            this.listNotificationItem.push(this.ItemChild);
            //    }
            //});
        }
        else if (this.Item.Type == 2) {
            this.listProject.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.ProjectId;
                    this.ItemChild.TargetType = 1;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listTower.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.TowerId;
                    this.ItemChild.TargetType = this.Item.Type;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
        }
        else if (this.Item.Type == 3) {
            this.listProject.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.ProjectId;
                    this.ItemChild.TargetType = 1;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listTower.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.TowerId;
                    this.ItemChild.TargetType = 2;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listFloor.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.FloorId;
                    this.ItemChild.TargetType = this.Item.Type;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
        }
        else if (this.Item.Type == 4) {
            this.listProject.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.ProjectId;
                    this.ItemChild.TargetType = 1;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listTower.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.TowerId;
                    this.ItemChild.TargetType = 2;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listFloor.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.FloorId;
                    this.ItemChild.TargetType = 3;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listApartment.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.ApartmentId;
                    this.ItemChild.TargetType = this.Item.Type;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
        }
        else if (this.Item.Type == 5) {
            this.listProject.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.ProjectId;
                    this.ItemChild.TargetType = 1;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listTower.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.TowerId;
                    this.ItemChild.TargetType = 2;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listFloor.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.FloorId;
                    this.ItemChild.TargetType = 3;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listApartment.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.ApartmentId;
                    this.ItemChild.TargetType = 4;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
            this.listResident.forEach(item => {
                if (item.Check) {
                    this.ItemChild = new NotificationItem();
                    this.ItemChild.TargetId = item.ResidentId;
                    this.ItemChild.TargetType = this.Item.Type;
                    this.listNotificationItem.push(this.ItemChild);
                }
            });
        }

        this.Item.notificationItems = this.listNotificationItem;
        if (this.Item.notificationItems != undefined) {
            if (this.Item.notificationItems.length <= 0) {
                this.submitted = false;
                this.toastWarning("Bạn chưa chọn đối tượng nhận thông báo!");
                return;
            }
        }

        let typeNotification = this.listTypeNotification.filter(x => x.Code == "TBF")[0];
        if(typeNotification)
            this.Item.TypeNotificationId = typeNotification.TypeAttributeItemId;

        if (this.listAttactment != undefined) {
            this.Item["attactments"] = [];
            this.listAttactment.forEach(item => {
                this.Item["attactments"].push({ Name: item, Url: item, Thumb: item });
            });
        }

        //console.log(this.Item);
        let data = Object.assign({}, this.Item);
        data.UserId = parseInt(localStorage.getItem("userId"));
        //data.ProjectId = parseInt(localStorage.getItem("projectId"));
        if (data.DateSend) {
            let obj = new Date(data.DateSend);
            data.DateSend = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " 0:0:0";
            if (data.TimeSend != undefined) {
                let obj1 = new Date(data.TimeSend);
                data.DateSend = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + obj1.getHours() + ":" + obj1.getMinutes() + ":0";
            }
        }
        if (this.Item.NotificationId == undefined) {
            this.http.post(domainApi + '/notification/notificationFee', data, this.httpOptions).subscribe(
                (res) => {
                    this.submitted = false;
                    if (res["meta"]["error_code"] == 200) {
                        this.GetListCommon();
                        this.modalCommon.hide();
                        this.toastSuccess("Thêm mới thành công!");
                        this.form.resetForm();
                    }
                    else {
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (err) => {
                    this.submitted = false;
                    this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }
            );
        }
        else {
            this.http.put(domainApi + '/notification/' + this.Item.NotificationId, data, this.httpOptions).subscribe(
                (res) => {
                    this.submitted = false;
                    if (res["meta"]["error_code"] == 200) {
                        this.GetListCommon();
                        this.modalCommon.hide();
                        this.toastSuccess("Cập nhật thành công!");
                        this.form.resetForm();
                    }
                    else {
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (err) => {
                    this.submitted = false;
                    this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }
            );
        }
    }

    //Popup xác nhận xóa
    ShowConfirmDelete(Id) {
        this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
            data: {
                text: "Bạn có chắc chắn muốn xóa mục này?"
            },
            actionButtons: [
                {
                    text: 'Đồng ý',
                    buttonClass: 'btn btn-success',
                    onAction: () => {
                        this.http.delete(domainApi + '/notification/' + Id, this.httpOptions).subscribe(
                            (res) => {
                                if (res["meta"]["error_code"] == 200) {
                                    this.GetListCommon();
                                    this.viewRef.clear();
                                    this.toastSuccess("Xóa thành công!");
                                }
                                else {
                                    this.toastError(res["meta"]["error_message"]);
                                }
                            },
                            (err) => {
                                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                            }
                        );
                    }
                },
                {
                    text: 'Đóng',
                    buttonClass: 'btn btn-default',

                }
            ],
        });
    }

    //Danh sách dự án
    GetListProject() {
        this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listProject = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    GetListTower(type) {
        if (this.Item.Type == 2 || this.Item.Type == 3 || this.Item.Type == 4 || this.Item.Type == 5) {
            this.listTower = [];
            this.listFloor = [];
            this.listApartment = [];
            this.listResident = [];

            if (type == 1) {
                if (this.Item.ProjectId != undefined) {
                    let projectName = "";
                    for (let i = 0; i < this.listProject.length; i++) {
                        if (this.listProject[i].ProjectId == this.Item.ProjectId) {
                            projectName = this.listProject[i].Name;
                        }
                    }

                    let query = "ProjectId=" + this.Item.ProjectId;
                    this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=&select=ProjectId,TowerId,Name,', this.httpOptions).subscribe(
                        (res) => {
                            if (res["meta"]["error_code"] == 200) {
                                let listTower = res["data"];
                                for (let i = 0; i < listTower.length; i++) {
                                    let check = true;
                                    for (let j = 0; j < this.listTower.length; j++) {
                                        if (this.listTower[j].TowerId == listTower[i].TowerId) {
                                            check = false;
                                            this.listTower[j].Status = 1;
                                            break;
                                        }
                                    }
                                    if (check) {
                                        let tower = {
                                            "ProjectId": this.Item.ProjectId,
                                            "ProjectName": projectName,
                                            "TowerId": listTower[i].TowerId,
                                            "Name": listTower[i].Name,
                                            "Check": false,
                                            "Status": 1,
                                        }
                                        this.listTower.push(tower);
                                    }
                                }

                                if (this.Item.NotificationId != undefined && this.Item.notificationItems != undefined) {
                                    if (this.Item.Type == 2) {
                                        for (let i = 0; i < this.listTower.length; i++) {
                                            this.listTower[i].Check = false;
                                            for (let j = 0; j < this.Item.notificationItems.length; j++) {
                                                if (this.listTower[i].TowerId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 2) {
                                                    this.listTower[i].Check = true;
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        for (let i = 0; i < this.listTower.length; i++) {
                                            this.listTower[i].Check = false;
                                            for (let j = 0; j < this.Item.notificationItems.length; j++) {
                                                if (this.listTower[i].TowerId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 2) {
                                                    this.listTower[i].Check = true;
                                                    this.GetListFloor(this.listTower[i], 2);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            //else {
                            //    item.Check = !item.Check;
                            //}
                        },
                        (err) => {
                            //item.Check = !item.Check;
                            console.log("Error: connect to API");
                        }
                    );
                }
                else {
                    for (let j = 0; j < this.listTower.length; j++) {
                        if (this.listTower[j].ProjectId == this.Item.ProjectId) {
                            this.listTower[j].Check = false;
                            this.listTower[j].Status = 99;
                        }
                    }
                }
            }
        }
    }

    GetListFloor(item, type) {
        if (this.Item.Type == 3 || this.Item.Type == 4 || this.Item.Type == 5) {
            if (type == 1)
                item.Check = !item.Check;
            if (item.Check) {
                let query = "TowerId=" + item.TowerId;
                this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=&select=TowerId,FloorId,Name,', this.httpOptions).subscribe(
                    (res) => {
                        if (res["meta"]["error_code"] == 200) {
                            let listFloor = res["data"];
                            for (let i = 0; i < listFloor.length; i++) {
                                let check = true;
                                for (let j = 0; j < this.listFloor.length; j++) {
                                    if (this.listFloor[j].FloorId == listFloor[i].FloorId) {
                                        check = false;
                                        this.listFloor[j].Status = 1;
                                        break;
                                    }
                                }
                                if (check) {
                                    let tower = {
                                        "ProjectId": item.ProjectId,
                                        "ProjectName": item.ProjectName,
                                        "TowerId": item.TowerId,
                                        "TowerName": item.Name,
                                        "FloorId": listFloor[i].FloorId,
                                        "Name": listFloor[i].Name,
                                        "Check": false,
                                        "Status": 1,
                                    }
                                    this.listFloor.push(tower);
                                }
                            }

                            if (this.Item.NotificationId != undefined && this.Item.notificationItems != undefined) {
                                if (this.Item.Type == 3) {
                                    for (let i = 0; i < this.listFloor.length; i++) {
                                        this.listFloor[i].Check = false;
                                        for (let j = 0; j < this.Item.notificationItems.length; j++) {
                                            if (this.listFloor[i].FloorId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 3) {
                                                this.listFloor[i].Check = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                                else {
                                    for (let i = 0; i < this.listFloor.length; i++) {
                                        this.listFloor[i].Check = false;
                                        for (let j = 0; j < this.Item.notificationItems.length; j++) {
                                            if (this.listFloor[i].FloorId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 3) {
                                                this.listFloor[i].Check = true;
                                                this.GetListApartment(this.listFloor[i], 2);
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            item.Check = !item.Check;
                        }
                    },
                    (err) => {
                        item.Check = !item.Check;
                        console.log("Error: connect to API");
                    }
                );
            }
            else {
                for (let j = 0; j < this.listFloor.length; j++) {
                    if (this.listFloor[j].TowerId == item.TowerId) {
                        this.listFloor[j].Check = false;
                        this.listFloor[j].Status = 99;
                        //xóa các căn hộ tương ứng
                        for (let k = 0; k < this.listApartment.length; k++) {
                            if (this.listApartment[k].FloorId == this.listFloor[j].FloorId) {
                                this.listApartment[k].Check = false;
                                this.listApartment[k].Status = 99;
                                //Xóa các cư dân tương ứng
                                for (let l = 0; l < this.listResident.length; l++) {
                                    if (this.listResident[l].ApartmentId == this.listApartment[k].ApartmentId) {
                                        this.listResident[l].Check = false;
                                        this.listResident[l].Status = 99;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    GetListApartment(item, type) {
        if (this.Item.Type == 4 || this.Item.Type == 5) {
            if (type == 1)
                item.Check = !item.Check;
            if (item.Check) {
                let query = "FloorId=" + item.FloorId;
                this.http.get(domainApi + '/apartment/GetByPage?page=1&query=' + query + '&order_by=&select=FloorId,ApartmentId,Name,', this.httpOptions).subscribe(
                    (res) => {
                        if (res["meta"]["error_code"] == 200) {
                            let listApartment = res["data"];
                            for (let i = 0; i < listApartment.length; i++) {
                                let check = true;
                                for (let j = 0; j < this.listApartment.length; j++) {
                                    if (this.listApartment[j].ApartmentId == listApartment[i].ApartmentId) {
                                        check = false;
                                        this.listApartment[j].Status = 1;
                                        break;
                                    }
                                }
                                if (check) {
                                    let tower = {
                                        "ProjectId": item.ProjectId,
                                        "ProjectName": item.ProjectName,
                                        "TowerId": item.TowerId,
                                        "TowerName": item.TowerName,
                                        "FloorId": item.FloorId,
                                        "FloorName": item.Name,
                                        "ApartmentId": listApartment[i].ApartmentId,
                                        "Name": listApartment[i].Name,
                                        "Check": false,
                                        "Status": 1,
                                    }
                                    this.listApartment.push(tower);
                                }
                            }

                            if (this.Item.NotificationId != undefined && this.Item.notificationItems != undefined) {
                                if (this.Item.Type == 4) {
                                    for (let i = 0; i < this.listApartment.length; i++) {
                                        this.listApartment[i].Check = false;
                                        for (let j = 0; j < this.Item.notificationItems.length; j++) {
                                            if (this.listApartment[i].ApartmentId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 4) {
                                                this.listApartment[i].Check = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                                else {
                                    for (let i = 0; i < this.listApartment.length; i++) {
                                        this.listApartment[i].Check = false;
                                        for (let j = 0; j < this.Item.notificationItems.length; j++) {
                                            if (this.listApartment[i].ApartmentId == this.Item.notificationItems[j].TargetId && this.Item.notificationItems[j].TargetType == 4) {
                                                this.listApartment[i].Check = true;
                                                this.GetListResident(this.listApartment[i], 2);
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            item.Check = !item.Check;
                        }
                    },
                    (err) => {
                        item.Check = !item.Check;
                        console.log("Error: connect to API");
                    }
                );
            }
            else {
                for (let j = 0; j < this.listApartment.length; j++) {
                    if (this.listApartment[j].FloorId == item.FloorId) {
                        this.listApartment[j].Check = false;
                        this.listApartment[j].Status = 99;
                        //Xóa các cư dân tương ứng
                        for (let l = 0; l < this.listResident.length; l++) {
                            if (this.listResident[l].ApartmentId == this.listApartment[j].ApartmentId) {
                                this.listResident[l].Check = false;
                                this.listResident[l].Status = 99;
                            }
                        }
                    }
                }
            }
        }
    }

    GetListResident(item, type) {
        if (this.Item.Type == 5) {
            if (type == 1)
                item.Check = !item.Check;
            if (item.Check) {
                let params = "";
                if (item.ProjectId) {
                    params = "&ProjectId=" + item.ProjectId;

                    if (item.TowerId) {
                        params = params + "&TowerId=" + item.TowerId;

                        if (item.FloorId) {
                            params = params + "&FloorId=" + item.FloorId;

                            if (item.ApartmentId) {
                                params = params + "&ApartmentId=" + item.ApartmentId;
                            }
                        }
                    }
                }
                this.http.get(domainApi + '/resident/GetByPage?page=1&query=1=1&order_by=' + params, this.httpOptions).subscribe(
                    (res) => {
                        if (res["meta"]["error_code"] == 200) {
                            let listResident = res["data"];
                            for (let i = 0; i < listResident.length; i++) {
                                let check = true;
                                for (let j = 0; j < this.listResident.length; j++) {
                                    if (this.listResident[j].ResidentId == listResident[i].ResidentId) {
                                        check = false;
                                        this.listResident[j].Status = 1;
                                        break;
                                    }
                                }
                                if (check) {
                                    let tower = {
                                        "ApartmentId": item.ApartmentId,
                                        "ProjectName": item.ProjectName,
                                        "TowerName": item.TowerName,
                                        "FloorName": item.FloorName,
                                        "ApartmentName": item.Name,
                                        "ResidentId": listResident[i].ResidentId,
                                        "Name": listResident[i].FullName,
                                        "Check": false,
                                        "Status": 1,
                                    }
                                    this.listResident.push(tower);
                                }
                            }
                            if (this.Item.NotificationId != undefined && this.Item.notificationItems != undefined) {
                                if (this.Item.Type == 5) {
                                    for (let i = 0; i < this.listResident.length; i++) {
                                        this.listResident[i].Check = false;
                                        for (let j = 0; j < this.Item.notificationItems.length; j++) {
                                            if (this.listResident[i].ResidentId == this.Item.notificationItems[j].TargetId) {
                                                this.listResident[i].Check = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            item.Check = !item.Check;
                        }
                    },
                    (err) => {
                        item.Check = !item.Check;
                        console.log("Error: connect to API");
                    }
                );
            }
            else {
                console.log(this.listResident);
                for (let j = 0; j < this.listResident.length; j++) {
                    if (this.listResident[j].ApartmentId == item.ApartmentId) {
                        this.listResident[j].Check = false;
                        this.listResident[j].Status = 99;
                    }
                }
            }
        }
    }

    NotificationTemChanged() {
        if (this.Item.NotificationTemId == undefined) {
            this.Item.Contents = undefined;
            this.Item.Title = undefined;
        }
        else {
            for (var i = 0; i < this.listNotificationTemp.length; i++) {
                if (this.listNotificationTemp[i].NotificationTemId == this.Item.NotificationTemId) {
                    this.Item.Contents = this.listNotificationTemp[i].Contents;
                    this.Item.Title = this.listNotificationTemp[i].Title;
                    break;
                }
            }
        }
    }

    //upload ảnh hiển thị
    upload(files) {
        this.listApartment = [];
        if (files.length === 0)
            return;

        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadMultiFile', formData, {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            }),
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

            }
            else if (event.type === HttpEventType.Response) {
                this.listAttactment = event.body["data"];
            }
        });
    }

    OpenHistoryModal(item) {
        this.GetListHistory(item.NotificationId);
        this.modalHistory.show();
    }

    //Get danh sách lịch sử thông báo
    GetListHistory(id) {
        this.listHistory = [];
        this.pagingHistory.query = 'TargetId.Equals("' + id + '")';
        this.http.get(domainApi + '/notification/listHistory?page=' + this.pagingHistory.page + '&query=' + this.pagingHistory.query + '&order_by=' + this.pagingHistory.order_by, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listHistory = res["data"];
                    //this.listHistory.forEach(item => {
                    //    item.IsShow = item.Status == 1 ? true : false;
                    //});
                    this.pagingHistory.item_count = res["metadata"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    RePushNotificaton(item) {
        this.http.put(domainApi + '/notification/rePush/' + item.NotificationId, item, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.toastSuccess("Thông báo đang trong quá trình gửi lại!");
                    this.GetListHistory(item.TargetId);
                    this.viewRef.clear();
                }
                else {
                    this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }

            },
            (err) => {
                this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
            }
        );
    }

    OpenDetailModal(item) {
        this.submitted = false;
        console.log(item);
        this.ItemDetail = new NotificationDT();
        if (item != undefined) {
            this.ItemDetail = Object.assign(this.ItemDetail, item);
        }

        this.modalDetail.show();
    }

    SaveDetail() {
        this.submitted = true;

        //if (this.Item.notificationItems != undefined) {
        //    if (this.Item.notificationItems.length <= 0) {
        //        this.submitted = false;
        //        this.toastWarning("Bạn chưa chọn đối tượng nhận thông báo!");
        //        return;
        //    }
        //}

        //console.log(this.Item);
        let data = Object.assign({}, this.ItemDetail);
        data.UserId = parseInt(localStorage.getItem("userId"));

        console.log(data);

        if (this.ItemDetail.NotificationId != undefined) {
            this.http.put(domainApi + '/notification/updateNotificationFee/' + this.ItemDetail.NotificationId, data, this.httpOptions).subscribe(
                (res) => {
                    this.submitted = false;
                    if (res["meta"]["error_code"] == 200) {
                        this.GetListHistory(this.ItemDetail.TargetId);
                        this.modalDetail.hide();
                        this.toastSuccess("Cập nhật thành công!");
                        this.formDetail.resetForm();
                    }
                    else {
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (err) => {
                    this.submitted = false;
                    this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
                }
            );
        }
    }

    ShowTypeApartmentMap(Type) {
        if (Type != undefined && Type != null) {
            return this.typeResident.filter(x => x.Id == Type)[0].Name;
        }
        else {
            return "";
        }
    }

    RemoveDate() {
        this.Item.DateSend = undefined;
    }

    //Danh sách loại thông báo
    GetListTypeNotification() {
        this.http.get(domainApi + '/TypeAttributeItem/codeAttribute/TYPENOTIFICATION', this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listTypeNotification = res["data"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }
}
