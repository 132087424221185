import { TypeUserNote, ProcessStatusRequestCleaning, lstStatusServiceCleaning } from './../../../../../data/const';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogService } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-histories-message-dialog',
  templateUrl: './histories-message-dialog.component.html',
  styleUrls: ['./histories-message-dialog.component.scss']
})
export class HistoriesMessageDialogComponent implements OnInit {
  @Input() lstHistoriesMessage: Array<any>;

  public typeUser = TypeUserNote;
  public lstProcessStatus = lstStatusServiceCleaning;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
  ) { }

  ngOnInit() {
    this.lstHistoriesMessage.map(s => s.processStatusName = this.lstProcessStatus.find(o => o.Id == s.processStatus).Name)
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
  toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
}
