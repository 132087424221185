import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { AuthGuard } from '../../auth.guard';
import { PushToken } from '../../data/Model';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MessagingService } from '../../service/messaging.service';
import { domainApi } from '../../data/const';
import { Title } from "@angular/platform-browser";

const httpOptions = {
	headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Headers': 'Origin'
  })
}

const md5 = new Md5();

const formData = new FormData();

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
	message: string;
	returnUrl: string;
  submitted: Boolean;
  public domainApi = domainApi;
  public isLogging = false
  public Item: PushToken;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public authService: AuthService,
    public http: HttpClient,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private modalDialogService: ModalDialogService,
    private viewRef: ViewContainerRef,
    private authGuard: AuthGuard,
    private messagingService: MessagingService,
    private angularFireMessaging: AngularFireMessaging,
    public titleService: Title
  ) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Trang đăng nhập");
    this.submitted = false;
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)])
    });

    this.Item = new PushToken();
    let link = localStorage.getItem("Url");
    this.returnUrl = '/dashboard';
  }

  get f() { return this.loginForm.controls };

  login() {
    this.submitted = false;
    if (this.loginForm.invalid) {
      this.submitted = true;
      return;
    }
    else {
      let email = this.f.username.value;
      let password = Md5.hashStr(this.f.password.value);
      this.isLogging = true;

      this.http.post(domainApi + '/user/login', JSON.stringify({ email, password }), httpOptions).subscribe(
        (res) => {
          this.isLogging = false;

          if (res["meta"]["error_code"] == 200) {
            localStorage.setItem('isLoggedIn', "true");
            localStorage.setItem('data', res.toString());
            localStorage.setItem('access_token', res["data"]["access_token"].toString());
            localStorage.setItem('access_key', res["data"]["access_key"].toString());
            localStorage.setItem('userId', res["data"]["userId"].toString());
            localStorage.setItem('userName', res["data"]["userName"].toString());
            localStorage.setItem('avata', res["data"]["avata"] != undefined ? res["data"]["avata"].toString() : undefined);
            localStorage.setItem('fullName', res["data"]["fullName"] != undefined ? res["data"]["fullName"].toString() : undefined);
            localStorage.setItem('companyId', res["data"]["companyId"] != undefined ? res["data"]["companyId"].toString() : undefined);
            localStorage.setItem('branchId', res["data"]["branchId"] != undefined ? res["data"]["branchId"].toString() : undefined);
            localStorage.setItem('departmentId', res["data"]["departmentId"] != undefined ? res["data"]["departmentId"].toString() : undefined);
            localStorage.setItem('languageId', res["data"]["languageId"] ? res["data"]["languageId"].toString() : undefined);
            localStorage.setItem('type', res["data"]["type"] != undefined ? res["data"]["type"].toString() : undefined);
            localStorage.setItem('projectId', res["data"]["projectId"] != undefined ? res["data"]["projectId"].toString() : undefined);
            localStorage.setItem('roleMax', res["data"]["roleMax"] != undefined ? res["data"]["roleMax"].toString() : undefined);
            localStorage.setItem('roleLevel', res["data"]["roleLevel"] != undefined ? res["data"]["roleLevel"].toString() : undefined);
            localStorage.setItem('roleCode', res["data"]["roleCode"] != undefined ? res["data"]["roleCode"].toString() : undefined);
            
            this.cookieService.set('Expire', Date.now().toLocaleString(), 0.5);

            //get token fcm
            const userId = res["data"]["userId"].toString();
            this.angularFireMessaging.requestToken.subscribe(
              (token) => {
                console.log(token);
                this.messagingService.updateToken(token);
                //push token
                this.Item.UserId = userId;
                this.Item.Token = token;
                this.Item.TypeOs = 1;
                this.http.post(domainApi + '/user/pushToken', this.Item, httpOptions).subscribe(
                  (res) => {
                    if (res["meta"]["error_code"] == 200) {
                      localStorage.setItem('token_notification', token);
                    }
                    else {
                    }
                  },
                  (err) => {
                  }
                );
                //return token;
              },
              (err) => {
                console.error('Unable to get permission to notify.', err);
              }
            );

            //get config utilities-service with project
            if (res["data"]["projectId"] && res["data"]["projectId"] > 0) {
              let listMenu = res["data"]["listMenus"];

              let indexMenuService = listMenu.findIndex(s => s.Code == "DVC");
              let indexMenuUtilities = listMenu.findIndex(s => s.Code == "UTILITIES");

              let itemMenuService = indexMenuService >= 0 ? listMenu[indexMenuService] : null;
              let itemMenuUtilities = indexMenuUtilities >= 0 ? listMenu[indexMenuUtilities] : null;

              const projectId = res["data"]["projectId"];

              this.http.get(domainApi + '/utilities/getConfig?projectId=' + projectId, httpOptions).subscribe(
                (res) => {
                  if (res["meta"]["error_code"] == 200) {
                    const lstConfig = res["data"];
                    let itemConfigService = lstConfig.find(s => s.type == 1);
                    let itemConfigUtilities = lstConfig.find(s => s.type == 2);

                    let lstConfigService = itemConfigService ? itemConfigService.listUtilitiesChild : [];
                    let lstConfigUtilities = itemConfigUtilities ? itemConfigUtilities.listUtilitiesChild : [];

                    if (itemMenuService) {
                      const listMenuServiceChild = itemMenuService.listMenus || [];
                      const itemMenuServiceCurrent = itemMenuService;
                      itemMenuServiceCurrent.listMenus = [];
                      
                      for(let i = 0; i < listMenuServiceChild.length; i ++) {
                        const isExistConfig = lstConfigService.find(s => s.code == listMenuServiceChild[i].Code);

                        if (isExistConfig) {
                          itemMenuServiceCurrent.listMenus.push(listMenuServiceChild[i])
                        }
                      }

                      listMenu[indexMenuService] = itemMenuServiceCurrent;
                    }

                    if (itemMenuUtilities) {
                      const listMenuUtilitiesChild = itemMenuUtilities.listMenus || [];
                      const itemMenuUtilitiesCurrent = itemMenuUtilities;
                      itemMenuUtilitiesCurrent.listMenus = [];
                      
                      for(let j = 0; j < listMenuUtilitiesChild.length; j ++) {
                        const isExistConfig = lstConfigUtilities.find(s => s.code == listMenuUtilitiesChild[j].Code);

                        if (isExistConfig) {
                          itemMenuUtilitiesCurrent.listMenus.push(listMenuUtilitiesChild[j])
                        }
                      }
                      console.log(itemMenuUtilitiesCurrent);
                      listMenu[indexMenuUtilities] = itemMenuUtilitiesCurrent;
                    }

                    listMenu = [...listMenu];
                    localStorage.setItem('menu', JSON.stringify(listMenu));
                    this.router.navigateByUrl(this.returnUrl);
                  }
                },
                (err) => {
                  localStorage.setItem('menu', JSON.stringify(res["data"]["listMenus"]));
                }
              );
            }
            else {
              localStorage.setItem('menu', JSON.stringify(res["data"]["listMenus"]));
              this.router.navigateByUrl(this.returnUrl);
            }
          }
          else {
            this.submitted = true;
            this.message = "Tài khoản hoặc mật khẩu không đúng";
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          this.isLogging = false;
          this.showConfirm("Đăng nhập không thành công. Xin vui lòng thử lại sau!");
        }
      );
    }
  }


  toat(): void {
    this.toastr.info('Phần này tạm thời chưa có nhé', 'Thông báo');
  }

  showConfirm(message) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Thông báo',
      settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
      data: {
        text: message
      },
      actionButtons: [
        {
          text: 'Xác nhận',
          buttonClass: 'btn btn-success'
        }
      ],
    });
  }

}
