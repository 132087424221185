import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { RequireSupportHistory, RequireSupport, Attactment } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi, TypeRequireSupportStatus, TypeRequireSupportHistory } from '../../../data/const';
import { ActivatedRoute, Router } from '@angular/router';
import { DotsConfig, GalleryService, Image, PreviewConfig, PlainGalleryConfig, LineLayout, PlainGalleryStrategy } from '@ks89/angular-modal-gallery';


@Component({
	selector: 'app-require-support-history',
	templateUrl: './require-support-history.component.html',
	styleUrls: ['./require-support-history.component.scss']
})
export class RequireSupportHistoryComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;

	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public Item: RequireSupportHistory;

	public httpOptions: any;

	public submitted = false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	public domainImage = domainImage;

	public RequireSupport: RequireSupport;

	public TypeRequireSupportStatus = TypeRequireSupportStatus;

	public TypeRequireSupportHistory = TypeRequireSupportHistory;

	public listEmployeeAssigned = [];

	plainGalleryRow: PlainGalleryConfig = {
		strategy: PlainGalleryStrategy.ROW,
		layout: new LineLayout({ width: '30px', height: '30px' }, { length: 1, wrap: true }, 'flex-start')
	};

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public route: ActivatedRoute, public router: Router, public galleryService: GalleryService) {
		this.Item = new RequireSupportHistory();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "CreatedAt Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.route.queryParams.filter(params => params.RequireSupportId).subscribe(params => {
			let RequireSupportId = params.RequireSupportId;
			this.http.get(domainApi + '/RequireSupport/' + RequireSupportId, this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.RequireSupport = res["data"];
						this.paging.query = 'RequireSupportId.ToString().Contains("' + RequireSupportId + '")';
						this.GetListCommon();
						this.GetListEmployeeAssigned();
					} else this.router.navigate(['/require-support']);
				},
				(err) => {
					console.log("Error: connect to API");
					this.router.navigate(['/require-support']);
				});
		});
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = 'RequireSupportId.ToString().Contains("' + this.RequireSupport.Id + '")';
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and (Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
			}
			else {
				query += '(Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';

			}
		}

		if (this.q["Type"] != undefined) {
			if (query != '') {
				query += ' and Type=' + this.q["Type"];
			}
			else {
				query += 'Type=' + this.q["Type"];
			}
		}


		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(RequireSupportHistoryId) {
		if (RequireSupportHistoryId == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				let data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.RequireSupportHistoryId);
					}
				});
				if (data.length == 0) {
					this.toastWarning("Chưa chọn mục cần xóa!");
				}
				else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
						data: {
							text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + '/RequireSupportHistory/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												this.toastSuccess("Xóa thành công!");
												this.GetListCommon();
												this.ActionId = undefined;
											}
											else {
												this.toastError(res["meta"]["error_message"]);
											}
										},
										(err) => {
											this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	//Get danh sách chức năng
	GetListCommon() {
		this.http.get(domainApi + '/RequireSupportHistory/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listCommon = res["data"];
					this.paging.item_count = res["metadata"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenCommonModal(item) {
		this.submitted = false;
		this.Item = new RequireSupportHistory();
		if (item == undefined) {
		}
		else {
			this.Item = Object.assign(this.Item, item);
		}

		this.modalCommon.show();
	}

	SaveCommon() {
		this.submitted = true;

		let data = Object.assign({}, this.Item);
		data.UserId = parseInt(localStorage.getItem("userId"));

		if (data.RequireSupportHistoryId == undefined) {
			data.RequireSupportId = this.RequireSupport.Id;
			this.http.post(domainApi + '/RequireSupportHistory/PostData', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Thêm mới thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.http.put(domainApi + '/RequireSupportHistory/' + data.RequireSupportHistoryId, data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Cập nhật thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/RequireSupportHistory/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	//upload ảnh hiển thị
	upload(files) {
		if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {

				if (this.Item.images == undefined) this.Item.images = [];

				event.body["data"].forEach(item => {
					let attactment = new Attactment();
					attactment.Url = item;
					attactment.Thumb = item;

					this.Item.images.push(attactment);
				});
			}
		});
	}

	RemoveImageRequire(idx) {
		this.Item.images.splice(idx, 1);
	}

	ShowTypeRequireSupportStatus(Type) {
		return this.TypeRequireSupportStatus.filter(x => x.Id == Type)[0].Name;
	}

	ShowTypeRequireSupportHistory(Type) {
		let obj = this.TypeRequireSupportHistory.filter(x => x.Id == Type)[0]
		return obj != undefined ? obj.Name : "";
	}

	ConvertImage(images) {
		let res = images.map((image, index, images) => {
			return new Image(index, {
				img: domainImage + image.Url
			})
		});

		return res;
	}

	//Get danh sách nhân viên map với phòng ban có trách nhiệm xử lý
	GetListEmployeeAssigned() {
		this.http.get(domainApi + '/Employee/GetEmployeeHandle/' + this.RequireSupport.ProjectId + '/' + this.RequireSupport.TypeRequireId + '?page=1&query=1=1&order_by=', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listEmployeeAssigned = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	Back() {
		this.router.navigate(['/require-support']);
	}
}
