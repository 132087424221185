import { TechnicalNewService } from '../../../service/technical-new.service';
import { CommonService } from './../../../service/common.service';
import { CustomDatepickerI18n, I18n } from './../../../service/date-picker-i18n';
import { lstStatusServiceTechnical, LstStatusPaymentRequestGroup, domainApi, RequestServiceUserConfirm, StatusPayment, TypePayment, listPaymentType, StatusTechnical } from './../../../data/const';
import { QueryFilter, Paging } from './../../../data/dt';
import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { InterceptorService } from 'ng2-interceptors';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@Component({
  selector: 'app-technical-service-manager',
  templateUrl: './technical-service-manager.component.html',
  styleUrls: ['./technical-service-manager.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ]
})
export class TechnicalServiceManagerComponent implements OnInit {
  public isShowFilter = false;
  @ViewChild('toggleFilter') toggleFilter: ElementRef;

  public q: QueryFilter;
  public lstStatus = lstStatusServiceTechnical;
  public lstStatusPayment = LstStatusPaymentRequestGroup;
  public lstTypePayment = listPaymentType;
  public lstFloors = [];
  public lstProjects = [];
  public lstTowers = [];
  public lstApartments = [];
  public lstTechnicalRequest = Array<any>();

  public lstIdDeletes: number[] = [];
  public paging: Paging;
  public messageError = '';

  public isCheckedDelete = false;
  public isCheckAll = false;
  public isProcessingExport = false;
  public isC_One: boolean;
  public CheckAll: boolean;

  public statusPaymentEnum = StatusPayment;
  public typePaymentEnum = TypePayment;
  public statusPayment: number;
  public typePayment: number;
  public statusTechnicalRequest = StatusTechnical;
  public statusUserConfirm = RequestServiceUserConfirm;

  public dateNow = new Date();
  public maxDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };

  constructor(
    public titleService: Title,
    private readonly commonService: CommonService,
    public router: Router,
    private readonly http: HttpClient,
    public modalDialogService: ModalDialogService,
    private readonly toastr: ToastrService,
    public viewRef: ViewContainerRef,
    private _i18n: I18n,
    private readonly technicalService: TechnicalNewService,
    private httpDownload: InterceptorService,
  ) {
    this.q = new QueryFilter();
    this.q.txtSearch = "";
    this.q.StatusProcessId = -1;
    this.q.TypeRequireId = 0;
    this.q.DateTimeStart = null;
    this.q.DateTimeEnd = null;

    this.paging = new Paging();
    this.paging.page = 1;
    this.paging.page_size = 15;
    this.paging.order_by = 'rq.UpdatedAt Desc';

    this.q.TypeRequireId = null;
    this.q.StatusProcessId = null;

  }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Danh sách đăng ký dịch vụ kỹ thuật");

    this.lstStatus.map(s => s['disabled'] = false);
    this.onFilter();
    this.getListProject();
    this.getPermissionUser();
  }

  onFilter() {
    this.messageError = '';
    this.lstTechnicalRequest = [];
    this.q.StatusProcessId = this.q.StatusProcessId || null;
    this.q.TypeRequireId = this.q.TypeRequireId || null;

    if (this.q.DateTimeStart && !this.q.DateTimeEnd) {
      this.q.DateTimeEnd = new Date();
    } else if (!this.q.DateTimeStart && this.q.DateTimeEnd) {
      this.toastWarning("Vui lòng chọn ngày bắt đầu lọc");
      return;
    }

    this.technicalService.getByPage(this.q, this.paging, this.statusPayment, this.typePayment).subscribe((res) => {
      if (res["data"] && res["data"].length) {
        this.lstTechnicalRequest = res["data"].map(item => {
          return {
            ...item,
            ProjectId: item.Project,
            TowerId: item.Tower,
            FloorId: item.Floor,
            ApartmentId: item.Apartment,
          }
        });
        this.lstTechnicalRequest.map(s => s['isChecked'] = false);
        this.paging.item_count = res["totalRecords"];
        this.isCheckAll = false;
      } else {
        this.lstTechnicalRequest = [];
        this.paging.item_count = 0;
        this.isCheckAll = false;
        this.messageError = 'Không có dữ liệu';
      }
    },
      (error) => {
        this.toastError(error);
        this.paging.item_count = 0;
      })
  }

  getPermissionUser() {
    const userCode = localStorage.getItem("roleCode");

    if (userCode === 'DVVS') {
      this.isC_One = true;
    } else {
      this.isC_One = false;
    }
  }

  getStatusName(processStatus) {
    return this.lstStatus.find(s => s.Id == processStatus).Name;
  }

  onDetail(item) {
    this.router.navigate(['manager-service/registration-technical/detail/', item.Id]);// todo
  }

  onDelete(item: any) {
    this.modalDialogService.openDialog(this.viewRef, {
      //backdrop: 'static',
      //keyboard: false,
      title: 'Xác nhận',
      settings: {
        footerClass: 'footer-dialog modal-footer',
      },
      childComponent: SimpleModalComponent,
      data: {
        text: "Bạn chắc muốn xóa yêu cầu đăng ký này không?"
      },
      actionButtons: [
        {
          text: 'Hủy',
          buttonClass: 'btn btn-default',
        },
        {
          text: 'Xác nhận',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.technicalService.deleteById(item.id)
              .subscribe(
                (res) => {
                  if (res["meta"]["error_code"] == 200) {
                    this.toastSuccess("Xóa thành công!");
                    this.onFilter();
                  }
                },
                (err) => {
                  console.log("Error: connect to API");
                });

            this.viewRef.clear();
          }
        }
      ],

    });
  }

  onDeletes() {
    if (this.lstIdDeletes.length > 0) {
      this.modalDialogService.openDialog(this.viewRef, {
        title: 'Xác nhận',
        settings: {
          footerClass: 'footer-dialog modal-footer'
        },
        childComponent: SimpleModalComponent,
        data: {
          text: "Bạn chắc muốn xóa các yêu cầu đăng ký đã chọn không?"
        },
        actionButtons: [
          {
            text: 'Hủy',
            buttonClass: 'btn btn-default',
          },
          {
            text: 'Xác nhận',
            buttonClass: 'btn btn-success',
            onAction: () => {
              let lstIdFail = [];

              for (let i = 0; i < this.lstIdDeletes.length; i++) {
                this.technicalService.deleteById(this.lstIdDeletes[i])
                  .subscribe(
                    (res) => {
                      if (res["meta"]["error_code"] == 200) {
                        this.paging.item_count = this.paging.item_count - 1;

                        if (i == this.lstIdDeletes.length - 1) {
                          this.lstIdDeletes = [];
                          this.toastSuccess("Xóa thành công!");
                          this.lstTechnicalRequest = [];
                          this.onFilter();
                          this.viewRef.clear();
                        }
                      }
                    },
                    (err) => {
                      lstIdFail.push(this.lstIdDeletes[i]);
                      return this.toastWarning(`Xóa yêu cầu kỹ thuật của căn hộ: ${this.lstIdDeletes[i]['apartmentName']} thất bại. ${err["meta"]["error_message"]}`);
                    });
              }
            }
          }
        ],
      });
    }
  }


  PageChanged(event) {
    this.paging.page = event.page;
    this.lstIdDeletes = [];
    this.CheckAll = false;

    this.onFilter();
  }

  getListProject() {
    return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProjects = res;

        if (this.lstProjects.length == 1) {
          this.q.ProjectId = this.lstProjects[0].ProjectId;
          this.queryChangeProject();
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  //Danh sách tòa nhà
  getListTower(OpenUpdate) {
    this.lstTowers = [];
    if (!OpenUpdate) this.q.TowerId = undefined;
    if (this.q.ProjectId) {
      let query = "ProjectId=" + this.q.ProjectId;
      this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name').subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstFloors = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.getListFloor(OpenUpdate);
  }

  //Danh sách tầng
  getListFloor(OpenUpdate) {
    this.lstFloors = [];
    if (!OpenUpdate) this.q.FloorId = undefined;
    if (this.q.TowerId) {
      let query = "TowerId=" + this.q.TowerId;
      this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name').subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstFloors = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.getListApartment(OpenUpdate);
  }

  //Danh sách tầng
  getListApartment(OpenUpdate) {
    this.lstApartments = [];
    if (!OpenUpdate) this.q.ApartmentId = undefined;
    if (this.q.FloorId) {
      let query = "FloorId=" + this.q.FloorId;
      this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name').subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstApartments = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

  }

  queryChangeTower() {

    this.q["FloorId"] = undefined;
    this.lstFloors = [];
    if (this.q["TowerId"]) {
      let query = "TowerId=" + this.q["TowerId"];
      this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name').subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstFloors = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.queryChangeFloor();
  }

  queryChangeProject() {
    this.q["TowerId"] = undefined;
    this.lstTowers = [];
    if (this.q["ProjectId"]) {
      let query = "ProjectId=" + this.q["ProjectId"];
      this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name').subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstTowers = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }

    this.queryChangeTower();
  }

  queryChangeFloor() {
    this.q["ApartmentId"] = undefined;
    this.lstApartments = [];
    if (this.q["FloorId"]) {
      let query = "FloorId=" + this.q["FloorId"];
      this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name').subscribe(
        (res) => {
          if (res["meta"]["error_code"] == 200) {
            this.lstApartments = res["data"];
          }
        },
        (err) => {
          console.log("Error: connect to API");
        });
    }
  }

  getClassSortTable(str) {
    if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
      return "sorting";
    }
    else {
      if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
      else return "sorting_asc";
    }
  }

  exportExcel() {
    this.isProcessingExport = true;

    let query = `?processStatus=${this.q.StatusProcessId || -1}&type=${this.q.Type || 0}&currentPage=${this.paging.page}&pageSize=${this.paging.page_size}`;

    if (this.q.ProjectId > 0) {
      query = query.concat(`&projectId=${this.q.ProjectId}`);
    }

    if (this.q.TowerId > 0) {
      query = query.concat(`&towerId=${this.q.TowerId}`);
    }

    if (this.q.FloorId > 0) {
      query = query.concat(`&floorId=${this.q.FloorId}`);
    }

    if (this.q.ApartmentId > 0) {
      query = query.concat(`&apartmentId=${this.q.ApartmentId}`);
    }

    if (this.q.DateTimeStart) {
      query = query.concat(`&dateStart=${this.q.DateTimeStart}`);
    }

    if (this.q.DateTimeEnd) {
      query = query.concat(`&DateEnd=${this.q.DateTimeEnd}`);
    }

    if (this.paging.query) {
      query = query.concat(`&query=${this.paging.query}`);
    }

    if (this.paging.order_by) {
      query = query.concat(`&order_by=${this.paging.order_by}`);
    }

    if (this.statusPayment) {
      query = query.concat(`&statusPayment=${this.statusPayment}`);
    }

    // let url = `${ApiConstant.GetDataExportExcelRequestTechnical}${query}`;

    // const options = new RequestOptions({
    //   headers: new Headers({
    //     'Authorization': 'bearer ' + localStorage.getItem("access_token")
    //   }),
    //   responseType: ResponseContentType.Blob,
    // });

    // this.httpDownload.get(url, options).subscribe(res => {
    //   const fileName = "ds-dang-ky-dich-vu-ve-sinh.xlsx";
    //   saveFile(res.blob(), fileName);
    //   this.isProcessingExport = false;
    // });
  }
  sortTable(str) {
    let First = "";
    let Last = "";
    if (this.paging.order_by != "") {
      First = this.paging.order_by.split(" ")[0];
      Last = this.paging.order_by.split(" ")[1];
    }

    if (First != str) {
      this.paging.order_by = str + " Desc";
    }
    else {
      Last = Last == "Asc" ? "Desc" : "Asc";
      this.paging.order_by = str + " " + Last;
    }

    this.onFilter();
  }

  openToggleFilter() {
    this.isShowFilter = !this.isShowFilter;
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
}
