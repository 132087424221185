import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { VehicleParking, Attactment } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, TypeResident, patternPhone, domainApi, TypeAttribute_Relationship, TypeVehicleParking } from '../../../data/const';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Md5 } from 'ts-md5';
import { Title } from "@angular/platform-browser";

//Định dạng ngày h trên Modal
export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
	selector: 'app-car-parking',
	templateUrl: './car-parking.component.html',
	styleUrls: ['./car-parking.component.scss'],
	providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class CarParkingComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;
	@ViewChild('file') file: ElementRef;

	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public projects = [];
	public towers = [];
	public floors = [];
	public apartments = [];

	public isShowFilter = false;
	public filterTowers = [];
	public filterFloors = [];
	public filterApartments = [];

	public relationships = [];

	public Item: VehicleParking;

	public httpOptions: any;

	public submitted = false;


	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	public domainImage = domainImage;
	public patternPhone = patternPhone;
	public typeVehicleParking = TypeVehicleParking;

	public listTypeTicketCarParking = [];
	public listTypeCardCarParking = [];

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
		this.Item = new VehicleParking();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "TypeActionCarParking=1";
		this.paging.order_by = "VehicleParkingId Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.titleService.setTitle("Danh sách đăng ký thẻ xe");
		this.GetListCommon();
		this.GetListProject();
		this.GetListRelationship();

		// this.GetListTypeTicketCarParking();
		// this.GetListTypeCardCarParking();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = 'TypeActionCarParking=1';
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and (VehicleOwner.Contains("' + this.q.txtSearch + '") or LicensePlate.Contains("' + this.q.txtSearch + '"))';
			}
			else {
				query += '(VehicleOwner.Contains("' + this.q.txtSearch + '") or LicensePlate.Contains("' + this.q.txtSearch + '"))';
			}
		}

		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(VehicleParkingId) {
		if (VehicleParkingId == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				let data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.VehicleParkingId);
					}
				});
				if (data.length == 0) {
					this.toastWarning("Chưa chọn mục cần xóa!");
				}
				else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
						data: {
							text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + '/VehicleParking/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												this.toastSuccess("Xóa thành công!");
												this.GetListCommon();
												this.ActionId = undefined;
											}
											else {
												this.toastError(res["meta"]["error_message"]);
											}
										},
										(err) => {
											this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	//Get danh sách
	GetListCommon() {
		this.http.get(domainApi + '/VehicleParking/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listCommon = res["data"];
					this.paging.item_count = res["metadata"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	OpenCommonModal(item) {
		this.file.nativeElement.value = "";
		this.submitted = false;
		this.Item = new VehicleParking();
		if (item == undefined) {
			if (this.q["ProjectId"]) {
				this.Item.ProjectId = this.q["ProjectId"];
			}

			if (this.q["TowerId"]) {
				this.Item.TowerId = this.q["TowerId"];
			}

			if (this.q["FloorId"]) {
				this.Item.FloorId = this.q["FloorId"];
			}

			if (this.q["ApartmentId"]) {
				this.Item.ApartmentId = this.q["ApartmentId"];
			}

			this.GetListTower(true);

		}
		else {
			this.Item = JSON.parse(JSON.stringify(item));
			this.GetListTower(true);
		}

		this.modalCommon.show();
	}

	SaveCommon() {
		if (this.Item.ActionSync) {
			this.submitted = true;

			let data = Object.assign({}, this.Item);

			this.http.post(domainApi + '/VehicleParking/SyncCreateCarParking', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.GetListCommon();
						this.modalCommon.hide();
						this.toastSuccess("Đồng bộ thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.submitted = true;

			let data = Object.assign({}, this.Item);
			data.UserId = parseInt(localStorage.getItem("userId"));

			data.TypeActionCarParking = 1;

			if (data.BirthDay) {
				let obj = new Date(data.BirthDay);
				data.BirthDay = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " 0:0:0";
			}

			if (data.VehicleParkingId == undefined) {
				this.http.post(domainApi + '/VehicleParking', data, this.httpOptions).subscribe(
					(res) => {
						this.submitted = false;
						if (res["meta"]["error_code"] == 200) {
							this.GetListCommon();
							this.modalCommon.hide();
							this.toastSuccess("Thêm mới thành công!");
							this.form.resetForm();
						}
						else {
							this.toastError(res["meta"]["error_message"]);
						}
					},
					(err) => {
						this.submitted = false;
						this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
					}
				);
			}
			else {
				this.http.put(domainApi + '/VehicleParking/' + data.VehicleParkingId, data, this.httpOptions).subscribe(
					(res) => {
						this.submitted = false;
						if (res["meta"]["error_code"] == 200) {
							this.GetListCommon();
							this.modalCommon.hide();
							this.toastSuccess("Cập nhật thành công!");
							this.form.resetForm();
						}
						else {
							this.toastError(res["meta"]["error_message"]);
						}
					},
					(err) => {
						this.submitted = false;
						this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
					}
				);
			}
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/VehicleParking/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	//Danh sách dự án
	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.projects = res["data"];
					if (this.projects.length == 1) {
						this.q.ProjectId = this.projects[0].ProjectId;
						this.QueryChangeProject();
						this.GetListTower(false);
					}
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	//Danh sách tòa nhà
	GetListTower(OpenUpdate) {
		this.towers = [];
		if (!OpenUpdate) this.Item.TowerId = undefined;
		if (this.Item.ProjectId) {
			let query = "ProjectId=" + this.Item.ProjectId;
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.towers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListFloor(OpenUpdate);
	}

	//Danh sách tầng
	GetListFloor(OpenUpdate) {
		this.floors = [];
		if (!OpenUpdate) this.Item.FloorId = undefined;
		if (this.Item.TowerId) {
			let query = "TowerId=" + this.Item.TowerId;
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.floors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.GetListApartment(OpenUpdate);
		this.GetListTypeTicketCarParking(OpenUpdate);
		this.GetListTypeCardCarParking(OpenUpdate);
	}

	//Danh sách tầng
	GetListApartment(OpenUpdate) {
		this.apartments = [];
		if (!OpenUpdate) this.Item.ApartmentId = undefined;
		if (this.Item.FloorId) {
			let query = "FloorId=" + this.Item.FloorId;
			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.apartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

	}

	QueryChangeProject() {
		this.q["TowerId"] = undefined;
		this.filterTowers = [];
		if (this.q["ProjectId"]) {
			let query = "ProjectId=" + this.q["ProjectId"];
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterTowers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeTower();
	}

	QueryChangeTower() {
		this.q["FloorId"] = undefined;
		this.filterFloors = [];
		if (this.q["TowerId"]) {
			let query = "TowerId=" + this.q["TowerId"];
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterFloors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeFloor();
	}

	QueryChangeFloor() {
		this.q["ApartmentId"] = undefined;
		this.filterApartments = [];
		if (this.q["FloorId"]) {
			let query = "FloorId=" + this.q["FloorId"];
			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterApartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	//upload ảnh hiển thị
	upload(files) {
		if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
				if (this.Item.attactments == undefined) this.Item.attactments = [];

				event.body["data"].forEach(item => {
					let attactment = new Attactment();
					attactment.Url = item;
					attactment.Thumb = item;

					this.Item.attactments.push(attactment);
				});
				if (this.file) this.file.nativeElement.value = "";
			}
		});
	}

	//Danh sách các mối quan hệ với chủ hộ
	GetListRelationship() {
		let query = "TypeAttributeId=" + TypeAttribute_Relationship;
		this.http.get(domainApi + '/typeAttributeItem/codeAttribute/relationship', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.relationships = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	RemoveImage(idx) {
		this.Item.attactments.splice(idx, 1);
	}

	GetListTypeTicketCarParking(OpenUpdate) {
		this.listTypeTicketCarParking = [];
		if (!OpenUpdate) this.Item.TypeTicketCpId = undefined;

		if(this.Item.TowerId) {
			this.http.get(domainApi + '/VehicleParking/ListTypeTicketCarParking/' + this.Item.TowerId, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listTypeTicketCarParking = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
		}
	}

	GetListTypeCardCarParking(OpenUpdate) {
		this.listTypeCardCarParking = [];
		if (!OpenUpdate) this.Item.TypeCardCpId = undefined;

		if(this.Item.TowerId) {
			this.http.get(domainApi + '/VehicleParking/ListTypeCardCarParking/' + this.Item.TowerId, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listTypeCardCarParking = res["data"];
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
		}
	}

	OpenSyncCarparkingModal(item) {
		this.Item = new VehicleParking();
		if (!item) return;

		this.Item = JSON.parse(JSON.stringify(item));
		this.Item.ActionSync = true;

		this.GetListTower(true);

		this.modalCommon.show();
	}
	openToggleFilter(){
		this.isShowFilter = !this.isShowFilter;
	}


}
