import { constructionAcceptanceForm } from '../../../../data/Model';
import { domain, domainFile, ApiConstant, ConstructionAcceptanceFormStatus, RefundDepositStatus, listPaymentStatus, ReceiveMoneyStatus } from '../../../../data/const';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import * as moment from 'moment';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-acceptance-dialog',
    templateUrl: './acceptance-dialog.component.html',
    styleUrls: ['./acceptance-dialog.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class AcceptanceDialogComponent implements OnInit {
    @ViewChild('file') file: ElementRef;
    @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
    @Input() itemDetail: constructionAcceptanceForm;
    @Input() RegisterConstructionFormId: number;
    @Input() IsDeposit: boolean;
    @Input() AmountDeposit: number;

    public item: constructionAcceptanceForm;
    public constructionAcceptanceFormStatus = ConstructionAcceptanceFormStatus;
    public refundDepositStatus = RefundDepositStatus;
    public listPaymentStatus = listPaymentStatus;
    public receiveMoneyStatus = ReceiveMoneyStatus;
    public isLoading = false;
    public statusItem: number;
    public maxDate = {
    };

    public domainFile = domainFile;
    public isKSTDKTC = localStorage.getItem("roleCode") == "KSTDKTC" ? true : false;

    constructor(
        public modal: NgbActiveModal,
        private readonly http: HttpClient,
        private readonly toastr: ToastrService,
        private gallery: Gallery
    ) {
        this.item = new constructionAcceptanceForm();
    }

    ngOnInit() {
        this.statusItem = 0;
        if (this.itemDetail) {
            this.item = this.itemDetail;
            this.statusItem = this.item.ConstructionAcceptanceFormStatus;
        }
        else {
            this.item.RegisterConstructionFormId = this.RegisterConstructionFormId;
            this.item.RegistrationDate = new Date();
            this.item.IsDeposit = this.IsDeposit;
            if (this.item.IsDeposit) {
                this.item.AmountDeposit = this.AmountDeposit;
                this.item.RemainingFee = this.AmountDeposit;
                this.item.RefundDepositStatus = 1;
            }
            this.item.ConstructionAcceptanceFormStatus = 1;
            this.item.objectFiles = [];
        }

        this.constructionAcceptanceFormStatus.map((item, index, arr) => {
            this.statusItem > item.Id ? item["disabled"] = true : item["disabled"] = false;
            return item;
        });

        let RegistrationDate = new Date(this.item.RegistrationDate);

        this.maxDate = {
            year: RegistrationDate.getFullYear(),
            month: RegistrationDate.getMonth() + 1,
            day: RegistrationDate.getDate()
        };
    }

    onSubmit() {
        if (!this.item.RegistrationDate) {
            this.toastWarning("Chưa có thông tin Ngày đăng ký");
            return;
        }

        if (!this.item.AcceptanceDate) {
            this.toastWarning("Chưa có thông tin Ngày nghiệm thu");
            return;
        }

        if(!this.checkValidate(1)) {
            this.toastWarning("Thông tin Ngày nghiệm thu không hợp lệ!");
            return;
        }

        if (!this.item.ActualAcceptanceDate && this.isKSTDKTC) {
            this.toastWarning("Chưa có thông tin Ngày nghiệm thu thực tế");
            return;
        }

       if(this.item.ActualAcceptanceDate) {
        if(!this.checkValidate(2)) {
            this.toastWarning("Thông tin Ngày nghiệm thu thực tế không hợp lệ!");
            return;
        }
       }

        if (!this.item.ConstructionAcceptanceFormStatus && !this.isKSTDKTC) {
            this.toastWarning("Chưa chọn Trạng thái đơn đăng ký");
            return;
        }

        if (this.item.IsDeposit && !this.isKSTDKTC) {
            if (!this.item.AmountDeposit) {
                this.toastWarning("Chưa có thông tin Tiền cọc");
                return;
            }

            if (this.item.ConstructionCost == undefined) {
                this.toastWarning("Chưa có thông tin Phí thi công");
                return;
            }

            if (this.item.Incurred == undefined) {
                this.toastWarning("Chưa có thông tin Phí phát sinh");
                return;
            }

            if (this.item.RemainingFee == undefined) {
                this.toastWarning("Chưa có thông tin Tiền còn lại");
                return;
            }
        }

        if (this.item.IsDeposit && this.item.RemainingFee > 0 && !this.item.RefundDepositStatus && !this.isKSTDKTC) {
            this.toastWarning("Chưa chọn Trạng thái hoàn cọc");
            return;
        }

        if (this.item.IsDeposit && this.item.RemainingFee < 0 && !this.item.PaymentStatus && !this.isKSTDKTC) {
            this.toastWarning("Chưa chọn Trạng thái thanh toán");
            return;
        }

        if (this.item.IsDeposit && this.item.RemainingFee < 0 && !this.item.ReceiveMoneyStatus && !this.isKSTDKTC) {
            this.toastWarning("Chưa chọn Trạng thái nhận tiền");
            return;
        }

        // if (this.item.ConstructionAcceptanceFormStatus == 4 && this.item.IsDeposit && this.item.RefundDepositStatus != 2) {
        //     this.toastWarning("Đơn ở Trạng thái Phê duyệt đăng kí thì Trạng thái hoàn cọc phải là Đã hoàn cọc");
        //     return;
        // }

        this.item.RegistrationDate = this.item.RegistrationDate ?
            new Date(moment(this.item.RegistrationDate.toString()).format('YYYY-MM-DD HH:mm')).toLocaleString("en-US", { timeZone: "Asia/Ho_Chi_Minh" }) : null;

        this.item.AcceptanceDate = this.item.AcceptanceDate ?
            new Date(moment(this.item.AcceptanceDate.toString()).format('YYYY-MM-DD')) : null;

        this.item.ActualAcceptanceDate = this.item.ActualAcceptanceDate ?
            new Date(moment(this.item.ActualAcceptanceDate.toString()).format('YYYY-MM-DD')) : null;

        this.isLoading = true;
        if (!this.item.Id) {
            this.http.post(ApiConstant.CreateAcceptanceConstructionForm, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Tạo đơn đăng ký nghiệm thu mới thành công");
                        this.isLoading = false;

                        this.eventEmitter.emit(true);
                        this.modal.close();
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        }
        else {
            this.http.put(ApiConstant.UpdateAcceptanceConstructionForm + this.item.Id, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Cập nhật đơn đăng ký nghiệm thu thành công");
                        this.isLoading = false;

                        this.eventEmitter.emit(true);
                        this.modal.close();
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        }
    }

    onChangePrice(value, cs) {
        if (cs == 1) {
            this.item.AmountDeposit = Number(value.replace(/[^0-9.-]+/g, ''));
            this.CaculatorRemainingFee();
        }
        else if (cs == 2) {
            this.item.ConstructionCost = Number(value.replace(/[^0-9.-]+/g, ''));
            this.CaculatorRemainingFee();
        }
        else if (cs == 3) {
            this.item.Incurred = Number(value.replace(/[^0-9.-]+/g, ''));
            this.CaculatorRemainingFee();
        }
        else if (cs == 4)
            this.item.RemainingFee = Number(value.replace(/[^0-9.-]+/g, ''));
    }

    CaculatorRemainingFee() {
        this.item.RemainingFee = (this.item.AmountDeposit ? this.item.AmountDeposit : 0) - (this.item.ConstructionCost ? this.item.ConstructionCost : 0) - (this.item.Incurred ? this.item.Incurred : 0);
    }

    omit_special_char(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return true;
        }
        return false;
    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }

    changeStatus() {
        // if (this.item.ConstructionAcceptanceFormStatus == 4 && this.item.IsDeposit) {
        //     this.item.RefundDepositStatus = 2;
        // }
    }

    upload(files) {
        if (files.length === 0)
            return;

        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        const uploadReq = new HttpRequest('POST', domain + 'api/cms/Upload/uploadMultiFile', formData, {
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

            }
            else if (event.type === HttpEventType.Response) {
                if (event.body["data"].length) {
                    event.body["data"].forEach(element => {
                        this.item.objectFiles.unshift({
                            FileId: 0,
                            FileRef: '',
                            Url: element.toString()
                        });
                    });
                }
                if (this.file) this.file.nativeElement.value = "";
            }
        });
    }

    onRemoveFile(item) {
        this.item.objectFiles = [...this.item.objectFiles.filter(s => s != item)];
    }

    checkValidate(cs) {
        if(cs == 1) {
            if(this.item.AcceptanceDate) {
                if(moment(this.item.AcceptanceDate.toString()).isValid() == false) return false;
            }
        }
        else if(cs == 2) {
            if(this.item.ActualAcceptanceDate) {
                if(moment(this.item.ActualAcceptanceDate.toString()).isValid() == false) return false;
            }
        }
        return true;
    }
}
