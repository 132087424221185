import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { QueryFilter } from '../../../data/dt';
import { FormControl, NgForm } from '@angular/forms';
import { domainImage, domainApi } from '../../../data/const';
import { Title } from "@angular/platform-browser";
import * as _moment from 'moment';
import { Moment } from 'moment';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
	parseInput: 'MM/YYYY',
	fullPickerInput: 'l LT',
	datePickerInput: 'MM/YYYY',
	timePickerInput: 'LT',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY',
};

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
	selector: 'app-search-fee',
	templateUrl: './search-fee.component.html',
	styleUrls: ['./search-fee.component.scss'],
	providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS }
	]
})
export class SearchFeeComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;

	public q: QueryFilter;

	public listCommon = [];
	public projects = [];

	public filterTowers = [];
	public filterFloors = [];
	public filterApartments = [];

	public httpOptions: any;

	public domainImage = domainImage;

	public date = new FormControl(moment());

	chosenYearHandler(normalizedYear: Moment) {
		const ctrlValue = this.date.value;
		ctrlValue.year(normalizedYear.year());
		this.date.setValue(ctrlValue);
	}

	chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
		const ctrlValue = this.date.value;
		ctrlValue.month(normalizedMonth.month());
		this.date.setValue(ctrlValue);
		datepicker.close();
	}

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title) {
		this.q = new QueryFilter();

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Tra cứu phí");
		this.GetListProject();
	}

	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}

	//Danh sách dự án
	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.projects = res["data"];
					if (this.projects.length == 1) {
						this.q.ProjectId = this.projects[0].ProjectId;
						this.QueryChangeProject();
					}
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	QueryChangeProject() {
		this.q["TowerId"] = undefined;
		this.filterTowers = [];
		if (this.q["ProjectId"]) {
			let query = "ProjectId=" + this.q["ProjectId"];
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterTowers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeTower();
	}

	QueryChangeTower() {
		this.q["FloorId"] = undefined;
		this.filterFloors = [];
		if (this.q["TowerId"]) {
			let query = "TowerId=" + this.q["TowerId"];
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=FloorId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterFloors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeFloor();
	}

	QueryChangeFloor() {
		this.q["ApartmentId"] = undefined;
		this.filterApartments = [];
		if (this.q["TowerId"]) {
			let query = "TowerId=" + this.q["TowerId"];
			query = this.q.FloorId != undefined ? (query + " AND FloorId=" + this.q.FloorId) : query;

			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=ApartmentId,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterApartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	SearchFee() {
		this.listCommon = [];
		if (!this.q.ApartmentId) {
			this.toastWarning("Chưa chọn Căn hộ");
			return;
		}

		if (!this.date) {
			this.toastWarning("Chưa chọn tháng/năm");
			return;
		}

		let year = this.date.value._d.getFullYear();
		let month = this.date.value._d.getMonth() + 1;

		let choosenProject = this.projects.filter(x => x.ProjectId == this.q.ProjectId)[0];
		let choosenApartment = this.filterApartments.filter(x => x.ApartmentId == this.q.ApartmentId)[0];

		let dataPost = {
			MaCanHo: choosenApartment.Code,
			IdDuAn: this.q.ProjectId,
			KyThanhToan: month + "/" + year
		};


		this.http.post( domainApi + '/OneS/GetListFeeFromOneS', dataPost , this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listCommon = res["data"];
				}
				else {
					this.toastError(res["meta"]["error_message"]);
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}
}
