import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
	name: 'filterStatus',
	pure: false
})
export class FilterStatusPipe implements PipeTransform {

	transform(items: any[], Status: number): any {
		if (!items) {
			return items;
		}

		return items.filter(x => x.Status != Status);
	}

}
