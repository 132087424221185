import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { lstTransferGoodStatus } from '../../../../data/const';
import { resGoods } from '../../../../data/Model';

export const MY_CUSTOM_FORMATS: OwlDateTimeFormats = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  styleUrls: ['./item-dialog.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})
export class ItemDialogComponent implements OnInit {
  @Output() itemGoods: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: any;
  public lstStatus = lstTransferGoodStatus;
  public item: resGoods;
  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService
  ) {
    this.item = new resGoods();
  }

  ngOnInit() {
    console.log(this.itemDetail)
    if (this.itemDetail) {
      this.item.name = this.itemDetail["name"];
      this.item.quantity = this.itemDetail["quantity"];
      this.item.note = this.itemDetail["note"];
    }
  }

  onSubmit() {
    if (!this.item.name) {
      this.toastWarning("Vui lòng nhập hàng hóa/tài sản");
      return;
    }
    if (!this.item.quantity) {
      this.toastWarning("Vui lòng nhập số lượng");
      return;
    } else if (this.item.quantity <= 0) {
      this.toastWarning("Vui lòng nhập số lượng > 0");
      return;
    }
    this.itemGoods.emit(this.item);
    this.modal.close();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
}
