// import { Directive, HostListener } from '@angular/core';
// import { NgControl } from '@angular/forms';

// @Directive({
//   selector: '[appPhoneMask]',
// })
// export class PhoneMaskDirective {

//     ngOnInit() {
//         this.onInputChange(this.ngControl.value, false);
//     }

//   constructor(public ngControl: NgControl) { }

//   @HostListener('ngModelChange', ['$event'])
//   onModelChange(event) {
//     this.onInputChange(event, false);
//   }

//   @HostListener('keydown.backspace', ['$event'])
//   keydownBackspace(event) {
//     this.onInputChange(event.target.value, true);
//   }
  

//   onInputChange(event, backspace) {
//     let newVal = event.replace(/\D/g, '');
//     if (backspace && newVal.length <= 6) {
//       newVal = newVal.substring(0, newVal.length - 1);
//     }
//     if (newVal.length === 0) {
//       newVal = '';
//     } else if (newVal.length <= 3) {
//       newVal = newVal.replace(/^(\d{0,3})/, '($1)');
//     } else if (newVal.length <= 6) {
//       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) ($2)');
//     } else if (newVal.length <= 10) {
//       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) ($2)-$3');
//     } else {
//       newVal = newVal.substring(0, 10);
//       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) ($2)-$3');
//     }
//     this.ngControl.valueAccessor.writeValue(newVal);
//   }
// }

import { Directive, ElementRef, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
	selector: '[appPhoneMask]'
})

export class PhoneMaskDirective implements OnDestroy {

	mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; // dd/mm/yyyy
	maskedInputController;

	constructor(private element: ElementRef) {
		this.maskedInputController = textMask.maskInput({
			inputElement: this.element.nativeElement,
			mask: this.mask
		});
	}

	ngOnDestroy() {
		this.maskedInputController.destroy();
	}

}