import { Injectable } from '@angular/core';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { InterceptorService } from 'ng2-interceptors';
import { getFileNameFromResponseContentDisposition, saveFile } from '../service/file-download-helper';

@Injectable()
export class DownloadService {

  constructor(
    private http: InterceptorService,
  ) { }

  //download file từ bảng Attachment
  downloadFile(id: any) {
    const url = '/api/download/downloadAttachment/' + id;
    const options = new RequestOptions({
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      responseType: ResponseContentType.Blob
    });

    // Process the file downloaded
    this.http.get(url, options).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      saveFile(res.blob(), fileName);
    });
  }

  //download file tử bảng MonitoringAttachment
  downloadFileMonitoring(id: any) {
    const url = '/api/download/downloadMonitoringAttachment/' + id;
    const options = new RequestOptions({
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      responseType: ResponseContentType.Blob
    });

    // Process the file downloaded
    this.http.get(url, options).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      saveFile(res.blob(), fileName);
    });
  }

  //download file đính kèm
  downloadFileOther(str: string) {
    const url = '/api/download/downloadFileOther';
    const options = new RequestOptions({
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      responseType: ResponseContentType.Blob
    });

    let obj = { Url: str };

    this.http.post(url, obj, options).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      saveFile(res.blob(), fileName);
    });
  }

  downloadAttachmentItems(id: any) {
    const url = '/api/download/downloadAttachmentItem/' + id;
    const options = new RequestOptions({
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      }),
      responseType: ResponseContentType.Blob
    });

    // Process the file downloaded
    this.http.get(url, options).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      saveFile(res.blob(), fileName);
    });
  }
}



