import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { ActionTable, domainImage, domainApi, TypeSendSms, patternPhone, domainFile } from '../../../data/const';
import { Router } from '@angular/router';
import { getFileNameFromResponseContentDisposition, saveFile } from '../../../service/file-download-helper';
import { RequestOptions, ResponseContentType, Headers } from '@angular/http';
import { InterceptorService } from 'ng2-interceptors';
import { FilterReport } from '../../../data/Model';
import { Title } from "@angular/platform-browser";
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
//import moment = require('moment');
import { DatePipe } from '@angular/common';

//Định dạng ngày h trên Modal
export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-report-sms',
  templateUrl: './report-sms.component.html',
    styleUrls: ['./report-sms.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS }
    ]
})
export class ReportSmsComponent implements OnInit {

    @ViewChild('modalCommon') public modalCommon: ModalDirective;

    public paging: Paging;
    public q: QueryFilter;

    public listCommon = [];
    public projects = [];

    public httpOptions: any;

    public submitted = false;
    public submittedHandle = false;

    public ActionTable = ActionTable;
    public ActionId: number;
    public CheckAll: boolean;

    public domainImage = domainImage;
    public domainFile = domainFile;
    public patternPhone = patternPhone;

    //public listTypeRequire = [];

    //public TypeRequireSupportStatus = TypeRequireSupportStatus.filter(x => x.Hidden != true);
    public TypeSendSms = TypeSendSms;

    public UserId: any;

    //public date = new FormControl(moment());

    //chosenYearHandler(normalizedYear: Moment) {
    //    const ctrlValue = this.date.value;
    //    ctrlValue.year(normalizedYear.year());
    //    this.date.setValue(ctrlValue);
    //}

    //chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    //    const ctrlValue = this.date.value;
    //    ctrlValue.month(normalizedMonth.month());
    //    this.date.setValue(ctrlValue);
    //    datepicker.close();
    //}


    constructor(public http: HttpClient, public modalDialogService: ModalDialogService,
        public viewRef: ViewContainerRef, public toastr: ToastrService,
        private httpDownload: InterceptorService,
        public datePipe: DatePipe,
        public SharedService: SharedService, public router: Router, public titleService: Title) {

        this.paging = new Paging();
        this.paging.page = 1;
        this.paging.page_size = 10;
        this.paging.query = "1=1";
        this.paging.order_by = "CreatedAt Desc";
        this.paging.item_count = 0;

        this.q = new QueryFilter();
        this.q.txtSearch = "";

        this.httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            })
        }

        //this.UserId = localStorage.getItem("userId");
    }

    ngOnInit() {
        this.titleService.setTitle("S Plus - Báo cáo sms");
        //this.GetListCommon();
        //this.GetListProject();
        //this.GetListTypeRequire();
    }

    //Chuyển trang
    PageChanged(event) {
        this.paging.page = event.page;
        this.GetListCommon();
    }
    //Toast cảnh báo
    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }
    //
    QueryChanged() {
        let query = '1=1';

        //if (this.q["ProjectId"] != undefined) {
        //    if (query != '') {
        //        query += ' and ProjectId=' + this.q["ProjectId"];
        //    }
        //    else {
        //        query += 'ProjectId=' + this.q["ProjectId"];
        //    }
        //}

        //if (this.q["TypeRequireId"] != undefined) {
        //    if (query != '') {
        //        query += ' and TypeRequireId=' + this.q["TypeRequireId"];
        //    }
        //    else {
        //        query += 'TypeRequireId=' + this.q["TypeRequireId"];
        //    }
        //}

        //if (this.q["StatusProcessId"] != undefined) {
        //    if (query != '') {
        //        query += ' and StatusProcessId=' + this.q["StatusProcessId"];
        //    }
        //    else {
        //        query += 'StatusProcessId=' + this.q["StatusProcessId"];
        //    }
        //}

        if (this.q["DateTimeStart"] != undefined) {
            //if (this.q["DateTimeRange"][0] && this.q["DateTimeRange"][1]) {
            //let StartDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][0].add(7, 'hours').toISOString());
            //let EndDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][1].add(7, 'hours').toISOString());
            //if (query != '') {
            let obj = new Date(this.q["DateTimeStart"]);
            //data.Birthday = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " 0:0:0";
            query += ' and InitedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
            //}
            //else {
            //    query += 'InitedAt >= DateTime(' + StartDate[0] + ',' + StartDate[1] + ',' + StartDate[2] + ',0,0,0) and InitedAt <= DateTime(' + EndDate[0] + ',' + EndDate[1] + ',' + EndDate[2] + ',23,59,59)';
            //}
            //}
        }

        if (this.q["DateTimeEnd"] != undefined) {
            //if (this.q["DateTimeRange"][0] && this.q["DateTimeRange"][1]) {
            //    let StartDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][0].add(7, 'hours').toISOString());
            //let EndDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][1].add(7, 'hours').toISOString());
            //if (query != '') {
            let obj = new Date(this.q["DateTimeEnd"]);
            query += ' and InitedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',23,59,59)';
            //    }
            //    else {
            //        query += 'InitedAt >= DateTime(' + StartDate[0] + ',' + StartDate[1] + ',' + StartDate[2] + ',0,0,0) and InitedAt <= DateTime(' + EndDate[0] + ',' + EndDate[1] + ',' + EndDate[2] + ',23,59,59)';
            //    }
            //}
        }

        if (query == '')
            this.paging.query = '1=1';
        else
            this.paging.query = query;

        this.GetListCommon();
    }

    //Get danh sách
    GetListCommon() {
        this.http.get(domainApi + '/report/ViewReportSMS?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by, this.httpOptions).subscribe(
            (res) => {
                if (res["meta"]["error_code"] == 200) {
                    this.listCommon = res["data"];
                    this.paging.item_count = res["metadata"];
                }
            },
            (err) => {
                console.log("Error: connect to API");
            });
    }

    ////Danh sách dự án
    //GetListProject() {
    //    this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=CreatedAt+Desc&select=ProjectId,Name', this.httpOptions).subscribe(
    //        (res) => {
    //            if (res["meta"]["error_code"] == 200) {
    //                this.projects = res["data"];
    //                if (this.projects.length == 1) this.q.ProjectId = this.projects[0].ProjectId;
    //            }
    //        },
    //        (err) => {
    //            console.log("Error: connect to API");
    //        });
    //}

    PreViewReport() {
        //if (this.projects.length > 1) {
        //    if (this.q.ProjectId == undefined) {
        //        this.toastWarning("Chưa chọn dự án!");
        //        return;
        //    }
        //}

        //if (!this.date) {
        //    this.toastWarning("Chưa chọn tháng/năm");
        //    return;
        //}

        //let year = this.date.value._d.getFullYear();
        //let month = this.date.value._d.getMonth();
        //let dateStart = new Date(year, month, 1, 0, 0, 0);
        //let dateS = moment(dateStart);
        //let dateE = dateS.add(1, 'months')._d;
        //dateE = moment(dateE).add(-1, 'days')._d;
        //let dateEnd = new Date(year, month, dateE.getDate(), 23, 59, 59);

        if (!this.q.DateTimeStart) {
            this.toastWarning("Chưa chọn ngày bắt đầu");
            return;
        }

        if (!this.q.DateTimeEnd) {
            this.toastWarning("Chưa chọn ngày kết thúc");
            return;
        }

        //console.log(this.q.DateTimeStart);

        let year = this.q.DateTimeStart._d.getFullYear();
        let month = this.q.DateTimeStart._d.getMonth();
        let date = this.q.DateTimeStart._d.getDate();
        let dateStart = new Date(year, month, date, 0, 0, 0);
        //let dateS = moment(dateStart);
        //let dateE = dateS.add(1, 'months')._d;
        //dateE = moment(dateE).add(-1, 'days')._d;
        let yeare = this.q.DateTimeEnd._d.getFullYear();
        let monthe = this.q.DateTimeEnd._d.getMonth();
        let datee = this.q.DateTimeEnd._d.getDate();
        let dateEnd = new Date(yeare, monthe, datee, 23, 59, 59);

        let query = '1=1';

        if (this.q.StatusProcessId != undefined) {
            if (this.q.StatusProcessId == 1) {
                query = '1=1';
            }
            else if (this.q.StatusProcessId == 2) {
                query += ' and ErrorCode="000"';
            }
            else if (this.q.StatusProcessId == 3){
                query += ' and ErrorCode!="000"';
            }
        }

        //if (this.q["TypeRequireId"] != undefined) {
        //    if (query != '') {
        //        query += ' and TypeRequireId=' + this.q["TypeRequireId"];
        //    }
        //    else {
        //        query += 'TypeRequireId=' + this.q["TypeRequireId"];
        //    }
        //}
        query += ' and CreatedAt >= DateTime(' + dateStart.getFullYear() + ',' + (dateStart.getMonth() + 1) + ',' + dateStart.getDate() + ',0,0,0)';
        query += ' and CreatedAt <= DateTime(' + dateEnd.getFullYear() + ',' + (dateEnd.getMonth() + 1) + ',' + dateEnd.getDate() + ',23,59,59)';

        //if (this.q["StatusProcessId"] != undefined) {
        //    if (query != '') {
        //        query += ' and StatusProcessId=' + this.q["StatusProcessId"];
        //    }
        //    else {
        //        query += 'StatusProcessId=' + this.q["StatusProcessId"];
        //    }
        //}

        //if (this.q["DateTimeStart"] != undefined) {
        //    //if (this.q["DateTimeRange"][0] && this.q["DateTimeRange"][1]) {
        //    //let StartDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][0].add(7, 'hours').toISOString());
        //    //let EndDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][1].add(7, 'hours').toISOString());
        //    //if (query != '') {
        //    let obj = new Date(this.q["DateTimeStart"]);
        //    //data.Birthday = obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " 0:0:0";
        //    query += ' and InitedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
        //    //}
        //    //else {
        //    //    query += 'InitedAt >= DateTime(' + StartDate[0] + ',' + StartDate[1] + ',' + StartDate[2] + ',0,0,0) and InitedAt <= DateTime(' + EndDate[0] + ',' + EndDate[1] + ',' + EndDate[2] + ',23,59,59)';
        //    //}
        //    //}
        //}

        //if (this.q["DateTimeEnd"] != undefined) {
        //    //if (this.q["DateTimeRange"][0] && this.q["DateTimeRange"][1]) {
        //    //    let StartDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][0].add(7, 'hours').toISOString());
        //    //let EndDate = this.SharedService.ConvertDateQuery(this.q["DateTimeRange"][1].add(7, 'hours').toISOString());
        //    //if (query != '') {
        //    let obj = new Date(this.q["DateTimeEnd"]);
        //    query += ' and InitedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',23,59,59)';
        //    //    }
        //    //    else {
        //    //        query += 'InitedAt >= DateTime(' + StartDate[0] + ',' + StartDate[1] + ',' + StartDate[2] + ',0,0,0) and InitedAt <= DateTime(' + EndDate[0] + ',' + EndDate[1] + ',' + EndDate[2] + ',23,59,59)';
        //    //    }
        //    //}
        //}

        if (query == '')
            this.paging.query = '1=1';
        else
            this.paging.query = query;

        this.GetListCommon();
    }

    ExportReport() {
        //if (this.projects.length > 1) {
        //    if (this.q.ProjectId == undefined) {
        //        this.toastWarning("Chưa chọn dự án!");
        //        return;
        //    }
        //}

        let url = domainApi + '/report/ExportReportSMS?';

        let query = "1=1";
        //if (this.q.TypeRequireId) {
        //    query = "TypeRequireId=" + this.q.TypeRequireId;
        //}

        //if (!this.date) {
        //    this.toastWarning("Chưa chọn tháng/năm");
        //    return;
        //}

        //let year = this.date.value._d.getFullYear();
        //let month = this.date.value._d.getMonth();
        //let dateStart = new Date(year, month, 1, 0, 0, 0);
        //let dateS = moment(dateStart);
        //let dateE = dateS.add(1, 'months')._d;
        //dateE = moment(dateE).add(-1, 'days')._d;
        //let dateEnd = new Date(year, month, dateE.getDate(), 23, 59, 59);
        if (!this.q.DateTimeStart) {
            this.toastWarning("Chưa chọn ngày bắt đầu");
            return;
        }

        if (!this.q.DateTimeEnd) {
            this.toastWarning("Chưa chọn ngày kết thúc");
            return;
        }

        if (this.q.StatusProcessId != undefined) {
            if (this.q.StatusProcessId == 1) {
                query = '1=1';
            }
            else if (this.q.StatusProcessId == 2) {
                query += ' and ErrorCode="000"';
            }
            else if (this.q.StatusProcessId == 3) {
                query += ' and ErrorCode!="000"';
            }
        }

        //console.log(this.q.DateTimeStart);

        let year = this.q.DateTimeStart._d.getFullYear();
        let month = this.q.DateTimeStart._d.getMonth();
        let date = this.q.DateTimeStart._d.getDate();
        let dateStart = new Date(year, month, date, 0, 0, 0);
        //let dateS = moment(dateStart);
        //let dateE = dateS.add(1, 'months')._d;
        //dateE = moment(dateE).add(-1, 'days')._d;
        let yeare = this.q.DateTimeEnd._d.getFullYear();
        let monthe = this.q.DateTimeEnd._d.getMonth();
        let datee = this.q.DateTimeEnd._d.getDate();
        let dateEnd = new Date(yeare, monthe, datee, 23, 59, 59);

        query += ' and CreatedAt >= DateTime(' + dateStart.getFullYear() + ',' + (dateStart.getMonth() + 1) + ',' + dateStart.getDate() + ',0,0,0)';
        query += ' and CreatedAt <= DateTime(' + dateEnd.getFullYear() + ',' + (dateEnd.getMonth() + 1) + ',' + dateEnd.getDate() + ',23,59,59)';

        //let month = dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate();
        //let  = dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate();

        //if (this.q.DateTimeStart) {
        //    let obj = new Date(this.q["DateTimeStart"]);
        //    if (query != "")
        //        query += ' and InitedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
        //    else
        //        query += ' InitedAt >= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
        //    DateTimeStart = obj.getFullYear() + '-' + (obj.getMonth() + 1) + '-' + obj.getDate();
        //}

        //if (this.q.DateTimeEnd) {
        //    let obj = new Date(this.q["DateTimeEnd"]);
        //    if (query != "")
        //        query += ' and InitedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
        //    else
        //        query += ' InitedAt <= DateTime(' + obj.getFullYear() + ',' + (obj.getMonth() + 1) + ',' + obj.getDate() + ',0,0,0)';
        //    DateTimeEnd = obj.getFullYear() + '-' + (obj.getMonth() + 1) + '-' + obj.getDate();
        //}

        if (query == "") query = "1=1";
        url += "Query=" + query + "&DateTimeStart=" + this.datePipe.transform(dateStart, 'dd/MM/yyyy') + "&DateTimeEnd=" + this.datePipe.transform(dateEnd, 'dd/MM/yyyy');

        const options = new RequestOptions({
            headers: new Headers({
                'Authorization': 'bearer ' + localStorage.getItem("access_token")
            }),
            responseType: ResponseContentType.Blob,
        });

        //let obj = { Url: str };

        this.httpDownload.get(url, options).subscribe(res => {

            //if (res.type == ResponseType.Cors HttpEventType.DownloadProgress)
            //  let progress = Math.round(100 * res.bytesLoaded / res.totalBytes);
            //else if (res.type == HttpEventType.Response) {
            //  if (event.json["data"] != undefined) {

            //this.message = event.body["data"].toString();
            //this.ItemHardWorkDT.Attachment = this.message;
            //console.log(this.ItemHardWorkDT.Attachment);
            const fileName = "Bao-cao-sms.xlsx";
            //const fileName = getFileNameFromResponseContentDisposition(res);
            //console.log(fileName);
            saveFile(res.blob(), fileName);
            //  }
            //}


        });
    }

}
