import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service-tech-service',
  templateUrl: './service-tech-service.component.html',
  styleUrls: ['./service-tech-service.component.scss']
})
export class ServiceTechServiceComponent implements OnInit {
  @Input() listServices: any;
  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
    console.log(this.listServices)
  }

}
