import { ProcessStatusRequestCleaning, RequestCleaningDateOfWeek, RequestCleaningNumberOfHours, RequestCleaningTypeMonthPackage, RequestServicePaymentOption, RequestServiceUserConfirm, StatusAdminConfirmPayment, statusCardRequestGroup, StatusPayment, TypePayment, TypeRequestCleaning, TypeUserNote } from "./const";

export class PushToken {
	UserId: number;
	Token: string;
	TypeOs: number;
}

export class FunctionRole {
	FunctionRoleId: number;
	TargetId: number;
	FunctionId: number;
	ActiveKey: string;
	Type: number;
	CreatedAt: Date;
	UpdatedAt: Date;
	UserId: number;
	Status: number;
}

export class Function {
	FunctionId: number;
	Name: string;
	Code: string;
	FunctionParentId: number;
	Url: string;
	Note: string;
	Location: number;
	Icon: string;
	CreatedAt: Date;
	UpdatedAt: Date;
	UserId: number;
	Status: number;
	IsParamRoute: boolean;
}

export class Role {
	RoleId: number;
	Code: string;
	Name: string;
	Note: string;
	CreatedAt: Date;
	UpdatedAt: Date;
	UserEditId: number;
	UserId: number;
	LevelRole: number;
	Status: number;
	listFunction: Array<FuncRole>;
}

export class FuncRole {
	FunctionRoleId: number;
	TargetId: number;
	FunctionId: number;
	ActiveKey: string;
}

export class UserRole {
	UserRoleId: number;
	UserId: number;
	RoleId: number;
	CreatedAt: Date;
	Status: number;
}

export class User {
	UserId: number;
	ProjectId: number;
	TowerId: number;
	FloorId: number;
	ApartmentId: number;
	FullName: string;
	UserName: string;
	Password: string;
	ConfirmPassword: string;
	Email: string;
	Birthday: any;
	Code: string;
	CardId: string;
	Avata: string;
	//ProjectId: number;
	PositionId: number;
	DepartmentId: number;
	CompanyId: number;
	Address: string;
	Phone: string;
	Note: string;
	KeyRandom: string;
	TypeThird: number;
	UserMapId: number;
	Type: number;
	TypeResident: number;
	CreatedAt: Date;
	UpdatedAt: Date;
	LastLoginAt: Date;
	BranchId: any;
	RegEmail: string;
	RoleMax: number;
	RoleLevel: number;
	IsRoleGroup: boolean;
	IsPhoneConfirm: boolean;
	IsEmailConfirm: boolean;
	LanguageId: number;
	CountLogin: number;
	UserCreateId: number;
	UserEditId: number;
	Status: number;
}

export class UserChangePass {
	UserId: number;
	PasswordOld: string;
	PasswordOldE: string;
	PasswordNew: string;
	PasswordNewE: string;
	UserName: string;
	Avatar: string;
	FullName: string;
	ConfirmPassword: string;
}

export class Authorizedperson {
	Names: string;
	Id: number;
	Creator: number;
	Datecreated: number;
}

export class Action {
	ActionId: any;
	ActionName: string;
	ActionType: string;
	TargetId: string;
	TargetType: string
	Logs: string;
	Ipaddress: string;
	Time?: number;
	Type?: number;
	CreatedAt?: any;
	UserPushId?: number;
	UserId?: number;
	Status?: number;
}

export class Apartment {
	ApartmentId: number;
	FloorId: number;
	TowerId: number;
	ProjectId: number;
	Code: string;
	Name: string;
	Address: string;
	Note: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
}

export class Attactment {
	AttactmentId: number;
	Name: string;
	TargetId: string;
	TargetType: number;
	Url: string;
	Thumb: string;
	CreatedAt: any;
	CorrectionDate: any;
	StartDate: any;
	EndDate: any;
	EndDateReal: any;
	CompleteStatus: number;
	GroupDesignFile: number;
	Note: string;
	UnitSupport: string;
	UserId: number;
	Status: number;
	RelatedId: number;
	WarningStart: number;
	WarningEnd: number;
	IsCheck: boolean;
}

export class Cash {
	CashId: number;
	ProjectId: number;
	TowerId: number;
	FloorId: number;
	ApartmentId: number;
	TypeCashId: number;
	ResidentId: number;
	Year: number;
	Quarter: number;
	Month: number;
	Week: number;
	Code: string;
	DateCreate: any;
	Price: number;
	Tax: number;
	Discount: number;
	TotalPrice: number;
	TotalPay: number;
	Note: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	UserEditId: number;
	Status: number;
	listCashItems: Array<CashItem>;
}

export class CashFund {
	CashFundId: number;
	TargetId: number;
	FundsId: number;
	Type: number;
	ValueConstrustion: number;
	ValueDevice: number;
	ValueOther: number;
	CurrencyId: number;
	ExchangeRate: number;
	ValueNoTax: number;
	ValueTax: number;
	ValueTotal: number;
	CreatedAt: any;
	Status: number;
}

export class CashItem {
	CashItemId: number;
	CashId: number;
	ProjectId: number;
	Type: number;
	Name: string;
	Code: string;
	DateCreate: any;
	PaymentTypeId: number;
	PaymentStatusId: number;
	PaymentMethodId: number;
	ResidentId: number;
	EmployeeId: number;
	ValuePay: number;
	Note: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	UserEditId: number;
	Status: number;
}

export class Category {
	CategoryId: number;
	Name: string;
	ProjectId: number;
	Location: number;
	CategoryParentId: number;
	Description: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
	StartDate: any;
	EndDate: any;
}

export class Config {
	ConfigId: number;
	IsLog: boolean;
	EmailHost: string;
	EmailSender: string;
	EmailEnableSSL: boolean;
	EmailUserName: string;
	EmailDisplayName: string;
	EmailPasswordHash: string;
	EmailPort: number;
	ConpanyId: number;
	CreatedAt: any;
	Status: number;
	AboutUs: string;
}

export class Contact {
	ContactId: number;
	TowerId: number;
	ProjectId: number;
	PositionId: number;
	DepartmentId: number;
	EmployeeName: string;
	Phone: string;
	Image: string;
	Note: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
}

export class Conversation {
	ConversationId: string;
	SendId: number;
	Contents: string;
	Title: string;
	TargetId: string;
	TargetType: number;
	StatusProcessId: number;
	Type: number;
	CreatedAt: any;
	Status: number;
}

export class Country {
	CountryId: number;
	Code: string;
	Name: string;
	Priority: number;
	LanguageId: number;
	Region: number;
	Note: string;
	Location: number;
	CreatedAt: any;
	Status: number;
}

export class Department {
	DepartmentId: number;
	Code: string;
	Name: string;
	ProjectId: number;
	Phone: string;
	Email: string;
	Type: number;
	CompanyId: number;
	Location: number;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
	departmentMaps: Array<{ TypeAttributeItemId: number; Name: string; Selected: boolean; }>;
}

export class DeviceMapping {
	DeviceMappingId: string;
	UserId: number;
	TokenFcm: string;
	TypeOS: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class District {
	DistrictId: number;
	ProvinceId: number;
	CountryId: number;
	Code: string;
	Name: string;
	Note: string;
	Priority: number;
	CreatedAt: any;
	Status: number;
}

export class Employee {
	EmployeeId: number;
	FullName: string;
	Code: string;
	Avata: string;
	ProjectId: number;
	PositionId: number;
	DepartmentId: number;
	Birthday: any;
	TypeEmployee: number;
	CardId: string;
	Phone: string;
	Email: string;
	Address: string;
	Note: string;
	IsMain: boolean;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
	employeeMaps: Array<{ TowerId: number; Name: string; Selected: boolean; }>;
}

export class Floor {
	FloorId: number;
	TowerId: number;
	ProjectId: number;
	Code: string;
	Name: string;
	Address: string;
	Note: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
}

export class Hotline {
	HotlineId: number;
	TowerId: number;
	ProjectId: number;
	HotlineNumber: string;
	CskhNumber: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
}

export class Language {
	LanguageId: number;
	Name: string;
	Code: string;
	Flag: string;
	IsMain: boolean;
	CompanyId: number;
	UserId: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class News {
	NewsId: number;
	Title: string;
	Description: string;
	Contents: string;
	Image: string;
	Url: string;
	DateStartActive: any;
	DateStartOn: any;
	DateEndOn: any;
	ViewNumber: number;
	Location: number;
	IsHome: boolean;
	IsHot: boolean;
	TypeNotebook: number;
	MetaTitle: string;
	MetaKeyword: string;
	MetaDescription: string;
	TowerId: number;
	ProjectId: number;
	UserId: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
	// newsMappings: Array<{ TowerId: number; Name: string; Selected: boolean; }>;
	categoryMappings: Array<{ CategoryId: number; Name: string; Selected: boolean; }>;
	attactments: Array<Attactment>;
}

export class RegistrationTerm {
	Id: string;
	Title: string;
	Description: string;
	Content: string;
	Type: number;
	ProjectId: number;
	CreatedById: number;
  UpdatedById: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class Notification {
	NotificationId: number;
	Title: string;
	Contents: string;
	TypeNotificationId: number;
	NotificationTemId: number;
	Note: string;
	SendNow: boolean;
	Type: number;
	Month: number;
	Year: number;
	ProjectId: number;
	DateSend: any;
	TimeSend: any;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
	notificationItems: Array<NotificationItem>;
	IsCheck: boolean;
	Image: string;
}

export class NotificationItem {
	NotificationItemId: number;
	NotificationId: number;
	TargetId: any;
	TargetType: any;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
}

export class NotificationTem {
	NotificationTemId: number;
	Name: string;
	Title: string;
	Description: string;
	Contents: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
}

export class NotificationDT {
	NotificationId: string;
	Title: string;
	Content: string;
	Type: string;
	TargetId: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	UserPushId: number;
	Status: number;
}

export class Position {
	Id: number;
	Name: string;
	Code: string;
	ProjectId: number;
	LevelId: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class Project {
	ProjectId: number;
	Code: string;
	Name: string;
	Address: string;
	ProvinceId: number;
	DistrictId: number;
	WardId: number;
	FullAddress: string;
	PersonContact: string;
	PhoneContact: string;
	Note: string;
	Image: string;
	LatLong: string;
	CarPakingRegulations: string;
	EmailUserName: string;
	EmailPasswordHash: string;
	EmailHost: string;
	EmailPort: number;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
	ApiOneS: string;
	UserNameOneS: string;
	PasswordOneS: string;
	ApiCarParking: string;
	UserNameCarParking: string;
	PasswordCarParking: string;
	IsConfirmed: boolean;
}

export class Province {
	ProvinceId: number;
	CountryId: number;
	Code: string;
	Name: string;
	Priority: number;
	Note: string;
	Lang: string;
	Region: number;
	CreatedAt: any;
	Status: number;
}

export class RequireSupportHistory {
	RequireSupportHistoryId: string;
	RequireSupportId: string;
	ResidentId: number;
	EmployeeId: number;
	EmployeeAssignedId: number;
	DepartmentAssignedId: number;
	TowerId: number;
	Type: number;
	StatusProcessId: number;
	Title: string;
	Note: string;
	CompleteTime: any;
	CreatedAt: any;
	UserId: number;
	Status: number;
	images: Array<Attactment>;
}

export class RequireSupport {
	Id: string;
	ProjectId: number;
	TowerId: number;
	FloorId: number;
	ApartmentId: number;
	TypeRequireId: number;
	Title: string;
	Note: string;
	ResidentId: number;
	ResidentPhone: string;
	EmployeeId: number;
	StatusProcessId: number;
	FeedbackContent: string;
	FeedbackStar: number;
	Solution: string;
	Hard: string;
	Year: number;
	Quarter: number;
	Month: number;
	Week: number;
	CreatedAt: any;
	UpdatedAt: any;
	FinishedAt: any;
	InitedAt: any;
	UserId: number;
	Status: number;
	images: Array<Attactment>;
	RequireSupportHistoryDTO: RequireSupportHistory;
}

export class Resident {
	ResidentId: number;
	// ApartmentId: number;
	// FloorId: number;
	// TowerId: number;
	// ProjectId: number;
	ResidentParentId: number;
	FullName: string;
	Birthday: any;
	CardId: string;
	Phone: string;
	Email: string;
	Address: string;
	Avata: string;
	Sex: string;
	Note: string;
	Type: number;
	CountryId: number;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
	apartments: Array<ApartmentResident>;
}

export class ApartmentResident {
	ApartmentMapId: string;
	ApartmentId: number;
	FloorId: number;
	TowerId: number;
	ProjectId: number;
	Code: string;
	Name: string;
	FullName: string;
	RelationshipName: string;
	DateRent: any;
	DateStart: any;
	DateEnd: any;
	Type: number;
	RelationshipId: number;
}

export class Tower {
	TowerId: number;
	ProjectId: number;
	Code: string;
	Name: string;
	Address: string;
	PersonContact: string;
	PhoneContact: string;
	Note: string;
	LatLong: string;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
	ApiCarParking: string;
	UserNameCarParking: string;
	PasswordCarParking: string;
  IsConfirmed: boolean;
}

export class TypeAttributeItem {
	TypeAttributeItemId: number;
	Code: string;
	Name: string;
	TypeAttributeId: number;
	Icon: string;
  Image: string;
	Location: number;
	IsActive: boolean;
	UserId: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class TypeAttribute {
	TypeAttributeId: number;
	Name: string;
	Code: string;
	IsUpdate: boolean;
	IsDelete: boolean;
	TypeAttribuiteParentId: number;
	UserId: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
	listAttributeItem: Array<TypeAttributeItem>;
}

export class UserMapping {
	UserMappingId: number;
	UserId: number;
	TargetId: number;
	TargetType: number;
	UserIdCreatedId: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class Ward {
	WardId: number;
	DistrictId: number;
	CountryId: number;
	ProvinceId: number;
	Name: string;
	Code: string;
	Note: string;
	CreatedAt: any;
	Status: number;
}

export class Utility {
	UtilityId: number;
	Name: string;
	Description: string;
	Contents: string;
	ProjectId: number;
	Image: string;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class FilterReport {
	ProjectId: number;
	TypeRequireId: number;
	DateTimeStart: any;
	DateTimeEnd: any;
}

export class Banner {
	BannerId: number;
	Title: string;
	Url: string;
	Image: string;
	Description: string;
	Contents: string;
	ProjectId: number;
	UserId: number;
	UpdatedAt: any;
	CreatedAt: any;
	Status: number;
}

export class VehicleParking {
	VehicleParkingId: number;
	ProjectId: number;
	TowerId: number;
	FloorId: number;
	ApartmentId: number;
	FullName: string;
	BirthDay: any;
	CmndCode: string;
	Phone: string;
	RelationshipId: number;
	VehicleOwner: string;
	LicensePlate: string;
	Brand: string;
	Color: string;
	TypeVehicle: number;
	CmndCopy: string;
	VehicleRegistrationCopy: string;
	OtherCopy: string;
	UserId: number;
	UpdatedAt: any;
	CreatedAt: any;
	Status: number;
	CardNo: string;
	CardNumber: string;
	CarParkingStatusConfirm: boolean;
	TypeActionCarParking: number;
	DestroyedDate: any;
	RegisterDate: any;
	RenewDate: any;
	TypeTicketCpId: string;
	TypeTicketCpName: string;
	TypeCardCpId: string;
	TypeCardCpName: string;
	UserCpId: string;
	ActionSync: boolean;
	Note: string;
	attactments: Array<Attactment>;
}

export class ServicePlus {
	ServicePlusId: number;
	Name: string;
	Note: string;
	ProjectId: number;
	CreatedAt: number;
	UpdatedAt: number;
	UserId: number;
	Status: number;
}

export class ServicePlusMapping {
	ServicePlusMappingId: number;
	ProjectId: number;
	TowerId: number;
	FloorId: number;
	ApartmentId: number;
	FullName: string;
	Phone: string;
	BirthDay: any;
	Email: string;
	CmndCode: string;
	RelationshipId: number;
	ServicePlusId: number;
	DateRegister: any;
	CreatedAt: any;
	UpdatedAt: any;
	UserId: number;
	Status: number;
}

export class ConfigPayment {
	Id: number;
	ProjectId: number;
	PaymentMethodId: number;
	Name: string;
	Image: string;
	UrlPay: string;
	UrlQuery: string;
	Merchant: string;
	AccessCode: string;
	AccessKey: string;
	Username: string;
	Password: string;
	PriceFee: number;
	ExchangRate: number;
	CardList: string;
	Phone: string;
	CreatedById: number;
	UpdatedById: number;
	UserId: number;
	CreatedAt: any;
	UpdatedAt: any;
	Status: number;
}

export class requestGroup {
	id: number;
	status: number;
	createdAt: Date;
	updatedAt: Date;
	createdById: number;
	createdName: string;
	updatedById: number;
	updatedName: string;
	projectId: number;
	towerId: number;
	floorId: number;
	apartmentId: number;
	apartmentName: string;
	residentId: number;
	residentName: string;
	residentPhone: string;
	info: string;
	note: string;
	type: number;
	reasonId: number;
	reasonName: string;
	processStatus: statusCardRequestGroup;
	registrationDate: Date;
	residentRequests: any[];
	residentCards: any[];
	motorcycleCards: any[];
	carCards: any[];
	files: any[];
	responseAdmin: requestGroupResponse;
}

export class resFiles {
	id: number;
	fileId: number;
	fileRef: string;
	url: string;
}

export class requestGroupResident {
	id: number;
	status: number;
	createdAt: Date;
	updatedAt: Date;
	createdById: number;
	updatedById: number;
	objectOwner: object;
	objectCode: object;
	objectName: object;
	objectDes: object;
	requestGroupId: number;
	type: number;
	info: string;
	residentId: number;
	cardId: number;
	name: string;
	files: [];
	resident: object;
}

export class requestGroupResidentCard {
	id: number;
	status: number;
	createdAt: Date;
	updatedAt: Date;
	createdById: number;
	updatedById: number;
	objectOwner: object;
	objectCode: object;
	objectName: object;
	objectDes: object;
	requestGroupId: number;
	type: number;
	info: string;
	residentId: number;
	cardId: number;
	name: string;
	files: [];
	resident: object;
}

export class requestGroupVehicleCard {
	id: number;
	status: number;
	createdAt: Date;
	updatedAt: Date;
	createdById: number;
	updatedById: number;
	objectOwner: object;
	objectCode: object;
	objectName: object;
	objectDes: object;
	requestGroupId: number;
	type: number;
	info: string;
	residentId: number;
	cardId: number;
	name: string;
	files: [];
	resident: object;
}

export class requestGroupResponse {
	id: number;
	money: number;
	isPaid: boolean;
	cardRevokedNote: string;
	isCardRevoked: boolean;
	appointmentDate: Date;
	finishDate: any;
	feedBack: string;
	typePayment: number;
	statusPayment: number;
	statusAdminConfirm: number;
	isSendAdminApproved: boolean;
}

export class resResidentCard {
	residentIdTemporary: string;
	residentCardIdTemporary: string;
	isIntegratedMotorcycleCard: boolean;
	residentCardId: number;
	cardRequestId: number;
	residentId: number;
	residentName: string;
	cardId: number;
	cardName: string;
	birthday: Date;
	gender: string;
  countryId: number;
	phone: string;
	identifyType: number;
	identifyLoc: string;
	identifyCode: string;
	identifyCreate: Date;
	relationshipId: number;
	relationshipName: string;
	createdById: number;
	files: any[];
	isChecked: boolean;
	disabled: boolean;
	dtCardOld: any;
}

export class resResidentRequest {
	residentIdTemporary: string;
	fullName: string;
	cardRequestId: number;
	residentId: number;
	birthday: string;
	gender: string;
  countryId: number;
	phone: string;
	identifyType: number;
	identifyLoc: string;
	identifyCode: string;
	identifyCreate: Date;
	relationshipId: number;
	relationshipName: string;
	files: any[]
}


export class resMotorcycleCard {
	residentCardIdTemporary: string;
	residentCardId: number;
	residentCardIntegratedId: number;
	residentCardName: string;
	cardRequestId: number;
	residentId: number;
	residentName: string;
	cardId: number;
	cardName: string;
	vehicleTypeId: number;
	vehicleOwner: string;
	vehicleName: string;
	vehicleBrand: string;
	vehicleNumerPlate: string;
	vehicleColor: string;
	vehicleDes: string;
	files: any[];
	createdById: number;
	isChecked: boolean;
	isIntegratedMotorcycleCard: boolean;
	disabled: boolean;
	dtCardOld: any;
}

export class resCarCard {
	residentIdTemporary: string;
	residentCardId: number;
	residentCardName: string;
	cardRequestId: number;
	residentId: number;
	residentName: string;
	cardId: number;
	cardName: string;
	vehicleTypeId: number;
	vehicleOwner: string;
	vehicleName: string;
	vehicleBrand: string;
	vehicleNumerPlate: string;
	vehicleColor: string;
	vehicleDes: string;
	files: any[];
	createdById: number;
	isChecked: boolean;
	disabled: boolean;
	dtCardOld: any;
}

export class resGoods {
	name: string;
	note?: string;
	quantity: number;
}

export class ServiceFee {
	Id: number;
	status: number;
	createdAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	ProjectId: number;
	TowerId: number;
	Name: string;
	Type: number;
	CategoryId: number;
	UnitName: string;
	PriceVt: number;
	PriceNc: number;
	TotalPrice: number;
	DateActive: any;
	Note: string;
	IdIndex: number;
	listTower: number[];
}

export class RateType {
	Id: number;
	Status: number;
	createdAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	Name: string;
	TypeRateId: number;
	ProjectId: number;
	TowerId: number;
}

export class ServiceOrder {
	Id: number;
	Status: number;
	CreatedAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	ProjectId: number;
	TowerId: number;
	FloorId: number;
	ApartmentId: number;
	ResidentId: number;
	ResidentName: string;
	IdContact: number;
	ResidentPhone: string;
	NameContact: string;
	RelationshipId: number;
	PhoneContact: string;
	Note: string;
	NoteCash: string;
	NoteConfirm: string;
	NoteAcceptance: string;
	NoteRate: string;
	NoteSolution: string;
	NoteExchange: string;
	DateActive: any;
	TimeActive: any;
	DateActiveTo: any;
	TimeActiveTo: any;
	DateCustomer: any;
	TimeCustomer: any;
	DateSurvey: any;
	TimeSurvey: any;
	DateStart: any;
	TimeStart: any;
	DateAcceptance: any;
	TimeAcceptance: any;
	PriceFake: number;
	PriceDeposit: number;
	PriceDepositPaid: number;
	PricePay: number;
	PriceTotal: number;
	StatusProcessId: number;
	StatusProcessIdOld: number;
	IsStart: boolean;
	IsStartOld: boolean;
	IsFee: boolean;
	IsFeeOld: boolean;
	IsPayDeposit: boolean;
	IsPayDepositOld: boolean;
	IsConfirmDeposit: boolean;
	IsAcceptance: boolean;
	IsAcceptanceOld: boolean;
	IsCash: boolean;
	IsRate: boolean;
	TypeService: number;
	PaymentMethod: number;
	PaymentMethod1: boolean;
	PaymentMethod2: boolean;
	PaymentStatus: number;
	AcceptanceStatus: number;
	Code: string;
	IsRateHh: boolean;
	IsRateBt: boolean;
	IsSolutionSc: boolean;
	IsSolutionTt: boolean;
	RateAttributeId: number;
	serviceOrderItems: any[];
	listEmps: any[];
	listPlans: any[];
	listItemProducts: any[];
	serviceOrderPrices: any[];
	files: any[];
	listNotes: any[];
	listRates: any[];
}

export class ServiceOrderEmp {
	Id: number;
	Status: number;
	createdAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	ServiceOrderId: number;
	EmployeeId: number;
	EmployeeCode: string;
	EmployeeName: string;
	PositionId: number;
	PositionName: string;
	Phone: string;
}

export class ServiceOrderItem {
	Id: number;
	Status: number;
	createdAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	ServiceOrderId: number;
	ServiceId: number;
	Name: string;
	UnitName: string;
	Quantity: number;
	Price: number;
	TotalPrice: number;
	Contents: string;
	Type: number;
	TypeService: number;
}

export class ServiceOrderPlan {
	Id: number;
	Status: number;
	createdAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	ServiceOrderId: number;
	Name: string;
	DatePlan: any;
	Note: string;
}

export class ServiceRate {
	Id: number;
	Status: number;
	createdAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	ServiceOrderId: number;
	RateAttributeId: number;
	ProjectId: number;
	ResidentId: number;
	LisRateTypeId: string;
	Type: number;
	Contents: string;
  RateAttributeName: string;
}

export class resTransferGoods {
	id: number;
	projectId: number;
	towerId: number;
	floorId: number;
	apartmentId: number;
	dateTransport: any;
	fromTime: any;
	toTime: any;
	typeTranspost: number;
	transpostBy: number;
	fullName: string;
	phone: string;
	identifyType: number;
	identifyCode: string;
	goodsRequests: resGoods[];
	files: any[];
	responseAdmin: requestGroupResponse;
	apartmentName: string;
	residentId: number;
	residentName: string;
	residentPhone: string;
	status: number;
	processStatus: any;
	makerId: number;
	createdAt: any;
}

export class registerConstructionForm {
	Id: number;
	Status: number;
	UserId: number;
	ProjectId: number;
	TowerId: number;
	FloorId: number;
	ApartmentId: number;
	ApartmentName: string;
	ResidentId: number;
	ResidentName: string;
	ResidentPhone: string;
	ResidentEmail: string;
	ResidentIdentityId: string;
	IdentityIdDate: any;
	IdentityIdPlace: string;
	Note: string;
	AuthorizedPersonName: string;
	AuthorizedPersonPhone: string;
	AuthorizedPersonId: string;
	AuthorizedPersonAddress: string;
	TransactionDateStart: any;
	TransactionDateEnd: any;
	TransactionNote: string;
	ConstructionStatus: number;
	RegConstructionStatus: number;
	ConstructionDateStart: any;
	ConstructionDateEnd: any;
	ConstructionDateStop: any;
	ConstructionDateContinue: any;
	IsDeposit: boolean;
	AmountDeposit: number;
	TotalAmountDeposit: number;
	DepositStatus: number;
	ReceiveMoneyStatus: number;
	RegisterDate: any;
	FeedBackToResident: string;
	NoteSecutiry: string;
	registerConstructionDocs: registerConstructionDoc[];
	objectFiles: any[];
	deposits: deposit[];
}

export class registerConstructionDoc {
	Id: number;
	Status: number;
	UserId: number;
	TargetId: number;
	Name: string;
	Type: number;
	Quantity: number;
	Note: string;
	TypeMapping: number;
	RegisterConstructionDocStatus: number;
	Reason: string;
	objectFiles: any[];
}

export class contractor {
	Id: number;
	Status: number;
	UserId: number;
	RegisterConstructionFormId: number;
	Name: string;
	AuthorizedPersonName: string;
	AuthorizedPersonPhone: string;
	ConstructionDateStart: any;
	ConstructionDateEnd: any;
	ConstructionItem: string;
	FeedBackToResident: string;
	ContractorStatus: number;
	RegisterDate: any;
}

export class extensionConstructionForm {
	Id: number;
	Status: number;
	UserId: number;
	RegisterConstructionFormId: number;
	ContractorId: number;
	OldConstructionDateStart: any;
	OldConstructionDateEnd: any;
	ConstructionDateStart: any;
	ConstructionDateEnd: any;
	ResonExtend: string;
	ConstructionItem: string;
	FeedBackToResident: string;
	ExtensionConstructionStatus: number;
	RegisterDate: any;
	Index: number;
	registerConstructionDocs: registerConstructionDoc[];
	objectFiles: any[];
}

export class constructionAcceptanceForm {
	Id: number;
	Status: number;
	UserId: number;
	RegisterConstructionFormId: number;
	ApartmentId: number;
	RegistrationDate: any;
	AcceptanceDate: any;
	ActualAcceptanceDate: any;
	ConstructionAcceptanceFormStatus: number;
	FeedBack: string;
	AmountDeposit: number;
	ConstructionCost: number;
	Incurred: number;
	RemainingFee: number;
	Note: string;
	RefundDepositStatus: number;
	PaymentStatus: number;
	ReceiveMoneyStatus: number;
	IsDeposit: boolean;
	objectFiles: any[];
}

export class deposit {
	Id: number;
	Status: number;
	UserId: number;
	RegisterConstructionFormId: number;
	DepositDate: any;
	AmountDeposit: number;
	DepositType: number;
	Note: string;
}


export class feedBackInfo {
	RegisterConstructionFormId: number;
	ExtensionConstructionFormId: number;
	ContractorId: number;
	ConstructionAcceptanceFormId: number;
	FeedBackToResident: string;
	TargetStatus: number;
	ConstructionStatus: number;
	TypeFeedBack: number;
	UserId: number;
	ResidentId: number;
}

export class SlaServiceDto {
	id: number;
	// Status: number;
	// createdAt: any;
	// updatedAt: any;
	// createdById: number;
	// updatedById: number;
	name: string;
	description: string;
	steps: SlaServiceStepDto[];
}

export class SlaServiceStepDto {
	id: number;
	status: number;
	step: number;
	warningTime: number;
	seriousTime: number;
	description: string;
}

export class RentalApartmentForm {
	id: number;
	projectId: number;
	towerId: number;
	floorId: number;
	apartmentId: number;
	apartmentName: string;
	residentId: number;
	residentName: string;
	note: string;
	processStatus: number;
	rentalApartment: RentalApartment;
	inform: InformRentalApartment;
	files: Array<resFiles>;
	resType: number;
	resNote: string;
	disagreeSurvey: boolean;
}

export class RentalApartment {
	id:number;
	status: number;
	createdAt: any;
	updatedAt: any;
	createdById: number;
	updatedById: number;
	requestGroupId: number;
	phone: string;
	type: number;
	money: number;
	fee: number;
	rentalApartmentConfigId: number;
	rentalApartmentConfigSel: number;
	promotion: string;
	acreage: number;
	floor: number;
	totalBedRoom: number;
	totalKitchen: number;
	totalLivingRoom: number;
	totalRestroom: number;
	hasFurniture: number;
	note: string;
	cashId: number;
	cashValue: number;
	isPaid: boolean;
	typePayment: number;
	statusPayment: number;
	statusAdminConfirm: number;
	schedule: any;
	paymentStatus: number;
	paymentDate: any;
	paymentMethod: number;
	noteCancel: string;
	showTemPlate: boolean;
}

export class ConfigRentalApartment {
	id: number;
	projectId: number;
	towerId: number;
	rentalPromotionPercent: number;
	rentalPromotionValue: number;
	rentalPromotionGift: string;
	rentalNote: string;
	promotionPercent: number;
	promotionValue: number;
	promotionGift: string;
	note: string;
	fromTime: any;
	toTime: any;
}

export class CashUtility {
	ApartmentId: number;
	ResidentId: number;
	Price: number;
	TotalPay: number;
	UserId: number;
	ReturnUrl: string;
	IpAdress: string;
	Locale: string;
	PaymentMethodId: number;
	BankCode: string;
	Name: string;
	TargetId: number;
	Type: number;
	Status: number;
	cashItems: Array<CashUtilityItem>;
}

export class CashUtilityItem {
	ServiceName: string;
	Quantity: number;
	Price: number;
	TotalPrice: number;
}

export class InformRentalApartment {
	info: string;
	resType: number;
	schedule: any;
	resNote: string;
}

export class HistoriesNote { // lịch sử trang đổi dki dvu vệ sinh
  public message: string;
  public createdTypeUser: TypeUserNote;
  public createdBy: string;
  public createdById: number;
  public createdDate: Date;
}

export class InfoDatePackageCleaning { // thông tin các thứ trong tuần & loại gói tháng đối với dọn dẹp định kì
  public daysOfWeek: Array<RequestCleaningDateOfWeek>;
  public typeMonthPackage: RequestCleaningTypeMonthPackage;
}

export class InfoRequestPayment { // thông tin thanh toán của ycau đki dvu vệ sinh
  public optionPayment: RequestServicePaymentOption;
  public typePayment: TypePayment;
  public statusPayment: StatusPayment;
  public statusAdminConfirm: StatusAdminConfirmPayment;
  public paidById: number;
  public paidByName: string;
  public infoQuotation: Array<InfoQuotationDetail>; // dsach chi tiết báo giá của đơn đki
  public note: string;
}

export class InfoQuotationDetail { // chi tiết báo giá của đơn đki
  public name: string;
  public unit: string;
  public priceFrom: string;
  public priceTo: string;
  public price: string;
  public totalPrice: string;
  public quantily: number;
  public minQuantily: number;
  public maxQuantily: number;
  public note: string;
  public createdByName: string;
  public createdById: number;
  public createdByDate: Date;
}

export class InfoRequestCleaning { // thông tin các hạng mục lấy ra tưng ướng với loại vệ sinh & dự án, hạng mục đã đki của cư dân
  public documentId: number;
  public name: string;
  public unit: string;
  public priceFrom: string;
  public priceTo: string;
  public price: string;
  public quantily: number;
  public minQuantily: number;
  public maxQuantily: number;
  public note: string;
}

export class DocumentCleaningService extends InfoRequestCleaning {
  public id: string;
  public createdDate: Date;
  public updatedDate: Date;
}

export class TowerDocument {
  public Id: string;
  public towerId: number;
  public towerName: string;
}

export class CategoryDocumentCleaningService {
  public id: string;
  public type: TypeRequestCleaning;
  public projectId: number;
  public towerId: number;
  public lstTower: Array<number>;
  public documentId: number;
  public idsIndex: number;
  public name: string;
  public unit: string;
  public priceFrom: string;
  public priceTo: string;
  public price: string;
  public quantily: number;
  public minQuantily: number;
  public maxQuantily: number;
  public note: string;
  public createdDate: Date;
  public updatedDate: Date;
  public info: Array<InfoRequestCleaning>;
}

export class InfoAcceptanceCleaning{ // thông tin về nghiệm thu của đơn đki dvu vsinh
  public info: string;
  public note: string;
  public isAcceptance: boolean;
  public acceptanceByName: string;
  public dateAcceptance: Date;
}

export class InfoUserAssignedCleaning{ // thông tin về nhân viên thực hiện
  public userId: number;
  public employeeId: number;
  public userName: string;
  public userCode: string;
  public userPhone: string;
  public userRole: string;
  public note: string;
  public createdById: number;
  public createdDate: Date;
  public createdByName: string;
}

export class RequestCleaning { // thông tin chi tiết về đơn đăng kí dvu vệ sinh
  public id: number;
  public projectId: number;
  public towerId: number;
  public floorId: number;
  public apartmentId: number;
  public apartmentName: string;
  public residentId: number;
  public residentName: string;
  public residentPhone: string;
  public userRequestId: number;
  public relationshipId: number;
  public relationshipName: string;
  public userRequestName: string;
  public userRequestPhone: string;
  public type: TypeRequestCleaning;
  public processStatus: ProcessStatusRequestCleaning;
  public statusUserConfirmSurvey: RequestServiceUserConfirm;
  public statusUserConfirmFee: RequestServiceUserConfirm;
  public info: Array<InfoRequestCleaning>;
  public totalPrice: string;
  public estimatePrice: string;
  public numberHours: RequestCleaningNumberOfHours;
  public timeActive: string;
  public dateActive: Date;
  public dateSurvey: Date;
  public timeSurvey: string;
  public infoPayment: InfoRequestPayment;
  public infoDatePackage: InfoDatePackageCleaning;
  public infoAcceptance: InfoAcceptanceCleaning;
  public infoUserAssigned: Array<InfoUserAssignedCleaning>;
  public historiesNote: Array<HistoriesNote>;
  public note: string;
  public noteFromUser: string;
  public noteFromAdmin: string;
  public dateConfirmed: Date;
  public confirmedBy: string;
  public status: number;
  public createdBy: string;
  public updatedBy: string;
  public createdById: number;
  public createdDate: Date;
  public updatedDate: Date;
  public isRequestCms: boolean;
  public isChecked : boolean;
}

export class ConfigUtilities {
	public id: number;
	public name: string; 
	public code: string;
	public type: number;
	public icon: string;
	public url: string;
	public description: string;
	public order: number;
	public createdAt: Date;
	public updatedAt: Date;
	public listProjectId: Array<Number>;
}