import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ListenChangeRouterService } from './service/listen-change-router.service';

@Injectable({
  providedIn: 'root' // ADDED providedIn root here.
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private cookie: CookieService, private listenChangeRouterService: ListenChangeRouterService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.verifyLogin(url);
  }

  verifyLogin(url): boolean {

    if (!this.isLoggedIn()) {
      if (url != '/login') {
        localStorage.setItem('Url', url);
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }
    else {
      if (this.isLoggedIn()) {
        if (url == '/login') {
          this.router.navigate(['/dashboard']);
          return false;
        }

        let arr = new Array();
        var json = JSON.parse(localStorage.getItem('menu'));

        json.push({
          ActiveKey: "111111111",
          Code: "",
          Icon: "fa fa-tachometer",
          MenuId: undefined,
          MenuParent: 0,
          Name: "Trang tổng quan",
          Status: undefined,
          Url: "dashboard",
          listMenus: []
        });

        json.push({
          ActiveKey: "111111111",
          Code: "",
          Icon: "",
          MenuId: undefined,
          MenuParent: 0,
          Name: "Lịch sử xử lý yêu cầu",
          Status: undefined,
          Url: "require-support-history",
          listMenus: []
        });

        for (var i = 0; i < json.length; i++) {
          this.checkUrlPermission(arr, undefined, json[i]);
        }

        let arrayCustom = url.split("?");
        arrayCustom = arrayCustom[0].split("/");
        let lastIdex = arrayCustom[arrayCustom.length - 1];
        if (!isNaN(Number(lastIdex))) {
          arrayCustom[arrayCustom.length - 1] = 0;
        }

        arrayCustom = arrayCustom.join();
        let urlCheck = arrayCustom.replace(new RegExp(',', 'g'), "/");

        if (arr.indexOf(urlCheck) == -1) {
          this.router.navigate(['/404']);
        }
        else {
          this.listenChangeRouterService.sendMessage(url);
          return true;
        }

        return true;
      }
    }
  }

  public isLoggedIn(): boolean {
    let status = false;
    if (localStorage.getItem('access_token') && localStorage.getItem('access_token').length >= 20) {
      status = true;
    }
    else {
      status = false;
    }
    return status;
  }

  checkUrlPermission(arr, urlParent, item): void {
    let url = "";
    if (item["IsParamRoute"] == true) {

      url = urlParent == undefined ? "/" + item["Url"] : urlParent.replace("/0", "") + "/" + item["Url"];
    }
    else {
      url = urlParent == undefined ? "/" + item["Url"] : urlParent.replace("/0", "") + "/" + item["Url"];
    }

    arr.push(url);
    if (item["listMenus"].length > 0) {
      for (var i = 0; i < item["listMenus"].length; i++) {
        this.checkUrlPermission(arr, url, item["listMenus"][i]);
      }
    }
  }

}
