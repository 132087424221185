// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB_yZP30GbpQjZO5K2-zeGgv_PNzyb0NJo",
    authDomain: "splus-residents.firebaseapp.com",
    databaseURL: "https://splus-residents.firebaseio.com",
    projectId: "splus-residents",
    storageBucket: "splus-residents.appspot.com",
    messagingSenderId: "836008896147",
    appId: "1:836008896147:web:c4fd378feac312f8bd0f66",
    measurementId: "G-34LZC1ZCT7"
  },
  apiUrl: 'https://api.tnsplus.vn/'
};
