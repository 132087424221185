import { HeaderService } from './../../../service/header.service';
import { requestGroupResponse, resMotorcycleCard, resResidentCard, resCarCard } from '../../../data/Model';
import { CommonService } from './../../../service/common.service';
import { LstTypeRequest, LstStatusRequestGroup, domain, ApiConstant, lstStatusPayment, lstStatusConfirmPayment, domainImage } from './../../../data/const';
import { ResidentManagerService } from './../../../service/resident-manager.service';
import { QueryFilter } from './../../../data/dt';
import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { typeCardRequestGroup } from '../../../data/const';
import { requestGroup } from '../../../data/Model';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { ChangeResidentCardComponent } from '../dialogs/change-resident-card/change-resident-card.component';
import { ChangeMotorcycleCardComponent } from '../dialogs/change-motorcycle-card/change-motorcycle-card.component';
import { ChangeCarCardComponent } from '../dialogs/change-car-card/change-car-card.component';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Gallery } from 'angular-gallery';
import { Title } from '@angular/platform-browser';
import { CancelRequestGroupComponent } from '../dialogs/cancel-request-group/cancel-request-group.component';

export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-edit-card-change-info',
  templateUrl: './edit-card-change-info.component.html',
  styleUrls: ['./edit-card-change-info.component.scss'],
  providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class EditCardChangeInfoComponent implements OnInit {
  @ViewChild('file') file: ElementRef;
  public lstTypeRegister = [];
  public httpOptions:any;
  public item: requestGroup;
  public apartmentIdChanged: number;
  public q:QueryFilter;
  public isCreatePage: boolean;
  public isResidentRequest: boolean = true;
  public isResidentCard: boolean = false;
  public isMotorcycleCard: boolean = false;
  public isCarCard: boolean = false;
  public typeRequest: typeCardRequestGroup;
  public lstResidentCard: resResidentCard[] = new Array();
  public lstMotorcleCard: resMotorcycleCard[] = new Array();
  public lstCarCard: resCarCard[] = new Array();
  public lstTypeRequest = [];
  public lstProject = [];
  public lstTower = [];
  public lstFloor = [];
  public lstVehicle = [];
  public lstApartment = [];
  public lstStatusRequest = LstStatusRequestGroup;
  public lstStatusPayment = lstStatusPayment;
  public lstStatusConfirmPayment = lstStatusConfirmPayment;
  public lstResidentCardEdit = new Array<number>();
  public lstMotorcycleCardEdit = new Array<number>();
  public lstCarCardEdit = new Array<number>();
  public oldProcessStatus = 0;
  public isLoading = false;
  public domainImage = domainImage;
  public isLoadingCancel = false;
  public isWarningProjectId = false;
  public isWarningTower = false;
  public isWarningFloor = false;
  public isWarningApartment = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal,
    private readonly residentService: ResidentManagerService,
    private readonly commonService: CommonService,
    private readonly routerUrl: Router,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
    private gallery: Gallery,
    private readonly headerService: HeaderService,
    public titleService: Title,
  ) {
    this.q = new QueryFilter();

    this.lstTypeRequest = LstTypeRequest.filter((i)=> i.Id != -1);

    this.item = new requestGroup();
    this.item.type = 4;
    this.item.files = [];
    this.item.processStatus = 1;
    this.lstStatusRequest = this.lstStatusRequest.filter(s => s.Id >= 1 && s.Id != 6 && s.Id != 7);

    this.item.registrationDate = new Date();
    this.item.responseAdmin = new requestGroupResponse();
    this.item.responseAdmin.statusPayment = lstStatusPayment[0].Id;
    this.item.responseAdmin.statusAdminConfirm = lstStatusConfirmPayment[0].Id;
    this.item.responseAdmin.money = 0;
   }

  ngOnInit() {
    this.item.processStatus = 1;
    [...this.lstStatusRequest.map(s => s.disabled = false)];

    this.titleService.setTitle("S Plus - Đăng ký thay đổi thông tin thẻ");
    const id = this.route.snapshot.paramMap.get('id');
    this.GetListProject();
    this.getLstVehicle();

    if (id) {
      this.isCreatePage = false;
      this.onGetDetail(id);
    } else {
      this.isCreatePage = true;
    }

    setInterval(() => this.onCheckIsShowWarning(), 200);
  }

  onSubmit() {
    this.isWarningProjectId = false;
    this.isWarningTower = false;
    this.isWarningFloor = false;
    this.isWarningApartment = false;

    if (!this.item.projectId) {
      this.isWarningProjectId = true;
      this.toastWarning("Vui lòng chọn dự án");
      return;
    } else if (!this.item.towerId) {
      this.isWarningTower = true;
      this.toastWarning("Vui lòng chọn tòa nhà");
      return;
    } else if (!this.item.floorId) {
      this.isWarningFloor = true;
      this.toastWarning("Vui lòng chọn tầng");
      return;
    } else if (!this.item.apartmentId) {
      this.isWarningApartment = true;
      this.toastWarning("Vui lòng chọn căn hộ");
      return;
    } else if (this.lstCarCard.findIndex(s => s.isChecked) === -1 &&
      this.lstResidentCard.findIndex(s => s.isChecked) === -1 &&
      this.lstMotorcleCard.findIndex(s => s.isChecked) === -1) {
      this.toastWarning("Chưa có thông tin danh mục đăng ký");
      return;
    }

    this.item.status = LstStatusRequestGroup.find(s => s.Id == 6).Id;

    this.item.residentCards = this.setResidentCards();
    this.item.motorcycleCards = this.setMotorcycleCards();
    this.item.carCards = this.setCarCards();
    this.item.files = [...this.item.files];

    this.isLoading = true;
    if (this.isCreatePage) {
      this.http.post(ApiConstant.CreateRequestChange, this.item).subscribe(
        (res) => {
          this.isLoading = false;
          this.isCreatePage = false;

          if (res['meta']['error_code'] == 200) {
            this.toastSuccess("Tạo yêu càu thay đổi thông tin thẻ thành công");

            this.routerUrl.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
              this.routerUrl.navigate([`/utilities/resident-service`]);
            });
          } else {
            this.toastError(res["meta"]["error_message"]);
          }
        },(err) => {
          this.isLoading = false;
          this.toastError(err["meta"]["error_message"]);
        })
    } else {
      this.http.put(ApiConstant.UpdateRequestChange + this.item.id, this.item).subscribe(
        (res) => {
          this.isLoading = false;
          if (res['meta']['error_code'] == 200) {
            this.toastSuccess("Cập nhật thành công!");

            this.routerUrl.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
              this.routerUrl.navigate([`/utilities/resident-service`]);
            });
          } else {
            this.toastError(res["meta"]["error_message"]);
          }
        },(err) => {
          this.isLoading = false;
          this.toastError(err["meta"]["error_message"]);
        })
    }

  }

  onChangeProject() {
    if (this.item.projectId > 0) {
      this.lstResidentCard = [];
      this.lstMotorcleCard = [];
      this.lstCarCard = [];
      this.item.towerId = null;
      this.item.floorId = null;
      this.item.apartmentId = null;
      this.item.apartmentName = '';
      this.item.residentId = null;
      this.item.residentPhone = '';
      this.item.residentName = '';
      this.apartmentIdChanged = null;
    }

    this.GetListTower();
  }


  onCheckIsShowWarning() {
    if (this.apartmentIdChanged > 0 &&
      this.isCreatePage &&
      (this.lstResidentCard.findIndex(s => s.isChecked === true) >= 0 ||
        this.lstCarCard.findIndex(s => s.isChecked === true) >= 0 ||
        this.lstMotorcleCard.findIndex(s => s.isChecked === true) >= 0)) {
          this.headerService.showWarning(true);
        } else {
          this.headerService.showWarning(false);
        }
  }

  setResidentCards() {
    let lstCard = [];

    for(let i = 0; i < this.lstResidentCardEdit.length; i ++) {
      let itemIndex = this.lstResidentCardEdit[i];

      lstCard.push({
        residentCardId: this.lstResidentCard[itemIndex].residentCardId,
        residentId: this.lstResidentCard[itemIndex].residentId,
        residentName: this.lstResidentCard[itemIndex].residentName,
        cardId: this.lstResidentCard[itemIndex].cardId,
        cardName: this.lstResidentCard[itemIndex].cardName,
        birthday: this.lstResidentCard[itemIndex].birthday || null,
        gender: this.lstResidentCard[itemIndex].gender || '',
        phone: this.lstResidentCard[itemIndex].phone,
        identifyType: this.lstResidentCard[itemIndex].identifyType || 0,
        identifyLoc: this.lstResidentCard[itemIndex].identifyLoc || '',
        identifyCode: this.lstResidentCard[itemIndex].identifyCode || '',
        identifyCreate: this.lstResidentCard[itemIndex].identifyCreate || null,
        relationshipId: this.lstResidentCard[itemIndex].relationshipId || 0,
        relationshipName: this.lstResidentCard[itemIndex].relationshipName || '',
        files: this.lstResidentCard[itemIndex].files || [],
      })
    }

    return lstCard;
  }

  setMotorcycleCards() {
    let lstCard = [];

    for(let i = 0; i < this.lstMotorcycleCardEdit.length; i ++) {
      let itemIndex = this.lstMotorcycleCardEdit[i];

      lstCard.push({
        residentCardId: this.lstMotorcleCard[itemIndex].residentCardId,
        residentId: this.lstMotorcleCard[itemIndex].residentId,
        residentCardIntegratedId: this.lstMotorcleCard[itemIndex].residentCardIntegratedId,
        residentName: this.lstMotorcleCard[itemIndex].residentName,
        cardId: this.lstMotorcleCard[itemIndex].cardId,
        cardName: this.lstMotorcleCard[itemIndex].cardName,
        vehicleOwner: this.lstMotorcleCard[itemIndex].vehicleOwner || null,
        vehicleBrand: this.lstMotorcleCard[itemIndex].vehicleBrand || '',
        vehicleName: this.lstMotorcleCard[itemIndex].vehicleName || '',
        vehicleNumerPlate: this.lstMotorcleCard[itemIndex].vehicleNumerPlate || '',
        vehicleColor: this.lstMotorcleCard[itemIndex].vehicleColor || '',
        vehicleDes: this.lstMotorcleCard[itemIndex].vehicleDes || '',
        vehicleTypeId: this.lstMotorcleCard[itemIndex].vehicleTypeId || 0,
        files: this.lstMotorcleCard[itemIndex].files || [],
      })
    }

    return lstCard;
  }

  setCarCards() {
    let lstCard = [];

    for(let i = 0; i < this.lstCarCardEdit.length; i ++) {
      let itemIndex = this.lstCarCardEdit[i];

      lstCard.push({
        residentCardId: this.lstCarCard[itemIndex].residentCardId,
        residentId: this.lstCarCard[itemIndex].residentId,
        residentName: this.lstCarCard[itemIndex].residentName,
        cardId: this.lstCarCard[itemIndex].cardId,
        cardName: this.lstCarCard[itemIndex].cardName,
        vehicleOwner: this.lstCarCard[itemIndex].vehicleOwner || null,
        vehicleBrand: this.lstCarCard[itemIndex].vehicleBrand || '',
        vehicleName: this.lstCarCard[itemIndex].vehicleName || '',
        vehicleNumerPlate: this.lstCarCard[itemIndex].vehicleNumerPlate || '',
        vehicleColor: this.lstCarCard[itemIndex].vehicleColor || '',
        vehicleDes: this.lstCarCard[itemIndex].vehicleDes || '',
        vehicleTypeId: this.lstCarCard[itemIndex].vehicleTypeId || 0,
        files: this.lstCarCard[itemIndex].files || [],
      })
    }

    return lstCard;
  }

  getLstCardByApartmentId(apartmentId: number) {
    this.http.get(ApiConstant.GetCardByApartmentId + apartmentId).subscribe(
      (res) => {
        if (res['meta']['error_code'] == 200) {
          if (!res['data'] ||
          (!res['data']['residentCards'].length && !res['data']['motorcycleCards'].length && !res['data']['carCards'].length)
          ) {
            this.toastWarning("Căn hộ này chưa có dữ liệu thẻ, vui lòng kiểm tra lại!");
            return;
          } else {
            if (!this.isCreatePage) {
              for(let j = 0; j < res['data']['residentCards'].length; j ++) {
                let itemCard = res['data']['residentCards'][j];

                let isExistCreated = this.item.residentCards.findIndex(s => s.residentCardId == itemCard.residentCardId);

                if (isExistCreated >= 0) {
                  this.item['residentCards'][isExistCreated].cardId = itemCard.cardId || 0;
                  this.item['residentCards'][isExistCreated].cardName = itemCard.cardName || '';
                }
              }

              for(let j = 0; j < res['data']['motorcycleCards'].length; j ++) {
                let itemCard = res['data']['motorcycleCards'][j];

                let isExistCreated = this.item.motorcycleCards.find(s => s.residentCardId == itemCard.residentCardId);

                if (isExistCreated >= 0) {
                  this.item['motorcycleCards'][isExistCreated].cardId = itemCard.cardId || 0;
                  this.item['motorcycleCards'][isExistCreated].cardName = itemCard.cardName || '';
                }
              }

              for(let j = 0; j < res['data']['carCards'].length; j ++) {
                let itemCard = res['data']['carCards'][j];

                let isExistCreated = this.item.carCards.find(s => s.residentCardId == itemCard.residentCardId);

                if (isExistCreated >= 0) {
                  this.item['carCards'][isExistCreated].cardId = itemCard.cardId || 0;
                  this.item['carCards'][isExistCreated].cardName = itemCard.cardName || '';
                }
              }

              this.item.motorcycleCards.map(s => s.isChecked == true);
              this.item.residentCards.map(s => s.isChecked == true);
              this.item.carCards.map(s => s.isChecked == true);

              this.lstMotorcleCard = [...this.item.motorcycleCards];
              this.lstResidentCard = [...this.item.residentCards];
              this.lstCarCard = [...this.item.carCards];
            } else {
              this.lstMotorcleCard = res['data']['motorcycleCards'];
              this.lstResidentCard = res['data']['residentCards'];
              this.lstCarCard = res['data']['carCards'];
            }
          }
        } else {
          this.toastError(res["meta"]["error_message"]);
        }
      })
  }

  GetListProject() {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.projectId = null;
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;

		return this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  GetListTower() {
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;
    this.item.residentId = null;
    this.item.residentPhone = '';
    this.item.residentName = '';
    this.lstResidentCard = [];
    this.lstMotorcleCard = [];
    this.lstCarCard = [];

		return this.commonService.getLstTower(this.item.projectId).subscribe(
      (res) => {
        this.lstTower = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  getLstVehicle(){
    return this.commonService.getLstAllVehicle(0).subscribe(
      (res) => {
        this.lstVehicle = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getVehicleName(vehicleId: number) {
    console.log(vehicleId);
    var itemVehicle = this.lstVehicle.find(s => s["id"] === vehicleId);

    if (itemVehicle) {
      return itemVehicle["name"];
    } else {
      return '';
    }
  }

  GetListFloor() {
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.floorId = null;
    this.item.apartmentId = null;

		return this.commonService.getLstFloor(this.item.towerId).subscribe(
      (res) => {
        this.lstFloor = res;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  GetListApartment() {
    this.lstApartment = [];
    this.item.apartmentId = null;

		return this.commonService.getLstApartment(this.item.floorId).subscribe(
      (res) => {
        if (this.isCreatePage) {
          for (let i = 0; i < res.length; i ++) {
            this.http.get(ApiConstant.CheckExistResidentCard + res[i]['ApartmentId']).subscribe(
              (lstRs) => {
                if (lstRs['data'].length) {
                  this.lstApartment.push(res[i]);
                  this.lstApartment = [...this.lstApartment];
                }
              },
              (err) => {
                this.toastError(err['meta']['error_message']);
              });
          }
        } else {
          this.lstApartment = res;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
	}

  getPurchaserByApartmentId(value) {
    this.item.apartmentId = value.ApartmentId;
    this.item.apartmentName = value.Name;

    if (this.item.apartmentId > 0 &&
      (this.lstResidentCard.findIndex(s => s.isChecked) >= 0 ||
       this.lstMotorcleCard.findIndex(s => s.isChecked) >= 0 ||
       this.lstCarCard.findIndex(s => s.isChecked) >= 0)) {
        this.modalDialogService.openDialog(this.viewRef, {
          title: 'Xác nhận',
          settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
          data: {
            text: "Dữ liệu đăng ký thẻ bên dưới sẽ bị mất. Bạn có muốn thay đổi không?"
          },
          actionButtons: [
            {
              text: 'Hủy',
              buttonClass: 'btn btn-default',
              onAction: () => {
                this.apartmentIdChanged = this.item.apartmentId;

                this.viewRef.clear();
              }
            },
            {
              text: 'Xác nhận',
              buttonClass: 'btn btn-success',
              onAction: () => {
                this.lstResidentCard = [];
                this.lstMotorcleCard = [];
                this.lstCarCard = [];

                this.commonService.getPurchaserByApartmentId(value.Id).subscribe(
                  (res) => {
                    if (res) {
                      this.item.apartmentId = value.ApartmentId;
                      this.item.apartmentName = value.Name;
                      this.item.residentId = res.ResidentId;
                      this.item.residentName = res.FullName;
                      this.item.residentPhone = res.Phone;

                      this.getLstCardByApartmentId(value.ApartmentId);
                    } else {
                      this.item.apartmentId = null;
                      this.item.apartmentName = '';
                      this.item.residentId = null;
                      this.item.residentName = '';
                      this.item.residentPhone = '';
                      this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                      return;
                    }
                  },
                  (err) => {
                    this.item.apartmentId = null;
                    this.item.apartmentName = '';
                    this.item.residentId = null;
                    this.item.residentName = '';
                    this.item.residentPhone = '';
                    this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
                    return;
                  }
                )

                this.viewRef.clear();
              }
            }
          ],
        });
       } else {
        this.commonService.getPurchaserByApartmentId(value.ApartmentId).subscribe(
          (res) => {
            if (res) {
              this.item.apartmentId = value.ApartmentId;
              this.item.apartmentName = value.Name;
              this.item.residentId = res.ResidentId;
              this.item.residentName = res.FullName;
              this.item.residentPhone = res.Phone;

              this.getLstCardByApartmentId(value.ApartmentId);
            } else {
              this.item.apartmentId = null;
              this.item.apartmentName = '';
              this.item.residentId = null;
              this.item.residentName = '';
              this.item.residentPhone = '';
              this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
              return;
            }
          },
          (err) => {
            this.item.apartmentId = null;
            this.item.apartmentName = '';
            this.item.residentId = null;
            this.item.residentName = '';
            this.item.residentPhone = '';
            this.toastError("Căn hộ này chưa có thông tin chủ hộ. Vui lòng kiểm tra lại!");
            return;
          }
        )
       }
  }

  onGetDetail(id) {
    this.residentService.getDetail(id).subscribe(
      (res) => {
        if (res) {
          this.setDataDetail(res);
          this.item.id = res['id'];
          this.item.note = res['note'];
          this.item.info = res['info'];
          this.item.processStatus = res['processStatus'];
          this.item.status = res['status'];
          this.item.createdAt = res['createdAt'];
          this.item.updatedAt = res['updatedAt'];
          this.item.updatedById = res['updatedById'];
          this.item.createdById = res['createdById'];
          this.item.registrationDate = res['registrationDate'];
          this.item.type = res['type'];
          this.item.reasonId = res['reasonId'];
          this.item.reasonName = res['reasonName'];
          this.item.files = res['files'];
          this.item.carCards = res['carCards'];
          this.item.motorcycleCards = res['motorcycleCards'];
          this.item.residentCards = res['residentCards'];
          this.item.responseAdmin = res['responseAdmin'];
          this.oldProcessStatus = res['processStatus'];

          res['residentCards'].forEach((element, index) => {
            this.lstResidentCardEdit.unshift(index);
          });
          res['motorcycleCards'].forEach((element, index) => {
            this.lstMotorcycleCardEdit.unshift(index);
          });
          res['carCards'].forEach((element, index) => {
            this.lstCarCardEdit.unshift(index);
          });

          for(let x = 0; x < this.lstStatusRequest.length; x ++) {
            if (this.lstStatusRequest[x].Id < this.item.processStatus) {
              this.lstStatusRequest[x].disabled = true;
            }
          }

          this.lstStatusRequest = [...this.lstStatusRequest];
        } else {

          this.modalDialogService.openDialog(this.viewRef, {
            title: 'Thông báo',
            settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
            data: {
              text: "Yêu cầu đăng ký không tồn tại. Vui lòng kiểm tra lại!"
            },
            actionButtons: [
              {
                text: 'Quay lại',
                buttonClass: 'btn btn-info',
                onAction: () => {
                  this.returnPage();
                }
              },
            ],
          });
        }

      },
      (err) => {
        this.routerUrl.navigate(["**"])
      });
  }

  QueryChanged(){

  }

  upload(files){
    if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.files.unshift({
              id: 0,
              fileId: 0,
              fileRef: '',
              url: element.toString()
            });
          });
        }
				if (this.file) this.file.nativeElement.value = "";
			}
		});
  }

  setDataDetail(item) {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    this.item.projectId = null;
    this.item.towerId = null;
    this.item.floorId = null;
    this.item.apartmentId = null;

		this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
        this.item.projectId = item["projectId"];

        this.commonService.getLstTower(this.item.projectId).subscribe((res) => {
          this.lstTower = res;
          this.item.towerId = item["towerId"];

          this.commonService.getLstFloor(this.item.towerId).subscribe((res) => {
            this.lstFloor = res;
            this.item.floorId = item["floorId"];

            this.commonService.getLstApartment(this.item.floorId).subscribe((res) => {
              this.lstApartment = res;

              this.apartmentIdChanged = item["apartmentId"];
              this.item.apartmentId = item["apartmentId"];
              this.item.apartmentName = item["apartmentName"];
              this.item.residentId = item["residentId"];
              this.item.residentName = item["residentName"];

              this.getResidentPhone(this.item.apartmentId);

              if (this.isCreatePage) {
                this.getLstCardByApartmentId(this.item.apartmentId);
              } else {
                this.item['residentCards'].map(s => s.isChecked = true);
                this.item['motorcycleCards'].map(s => s.isChecked = true);
                this.item['carCards'].map(s => s.isChecked = true);

                this.lstMotorcleCard = [...this.item.motorcycleCards];
                this.lstResidentCard = [...this.item.residentCards];
                this.lstCarCard = [...this.item.carCards];
              }
            })
          })
        })
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  openResidentCardDetailModal(item, index) {
    let isDtDetail = this.item.residentCards ? this.item.residentCards.find(s => s.residentCardId == item.residentCardId) : null;
    const dialofRefResidentCard = this.modalService.open(ChangeResidentCardComponent, { size: 'lg' });
    dialofRefResidentCard.componentInstance.itemDetail = (isDtDetail && isDtDetail.cardRequestId > 0) ? isDtDetail : item;
    dialofRefResidentCard.componentInstance.oldProcessStatus = this.oldProcessStatus;

    dialofRefResidentCard.componentInstance.itemResidentCard.subscribe((res) => {
      if (res) {
        if (!this.lstResidentCardEdit.find(s => s == index)) {
          this.lstResidentCardEdit.push(index);
        }
        this.checkEditResidentCard(index);

        this.lstResidentCard[index].residentName = res.residentName;
        this.lstResidentCard[index].phone = res.phone;
        this.lstResidentCard[index].gender = res.gender;
        this.lstResidentCard[index].relationshipId = res.relationshipId;
        this.lstResidentCard[index].relationshipName = res.relationshipName;
        this.lstResidentCard[index].birthday = res.birthday;
        this.lstResidentCard[index].identifyType = res.identifyType;
        this.lstResidentCard[index].identifyCode = res.identifyCode;
        this.lstResidentCard[index].identifyCreate = res.identifyCreate;
        this.lstResidentCard[index].identifyLoc = res.identifyLoc;
        this.lstResidentCard[index].isChecked = true;
      }
    });
  }

  openMotorcleCardDetailModal(item, index) {
    const dialofRefMotorcleCard = this.modalService.open(ChangeMotorcycleCardComponent, { size: 'lg' });
    dialofRefMotorcleCard.componentInstance.itemDetail = item;
    dialofRefMotorcleCard.componentInstance.apartmentId = this.apartmentIdChanged;
    dialofRefMotorcleCard.componentInstance.oldProcessStatus = this.oldProcessStatus;

    dialofRefMotorcleCard.componentInstance.itemMotorcycleCard.subscribe((res) => {
      if (res) {
        if (!this.lstMotorcycleCardEdit.find(s => s == index)  && this.isCreatePage) {
          this.lstMotorcycleCardEdit.push(index);
        }

        this.checkEditMotorcycleCard(index);

        this.lstMotorcleCard[index].vehicleOwner = res.vehicleOwner;
        this.lstMotorcleCard[index].vehicleBrand = res.vehicleBrand;
        this.lstMotorcleCard[index].vehicleColor = res.vehicleColor;
        this.lstMotorcleCard[index].vehicleName = res.vehicleName;
        this.lstMotorcleCard[index].vehicleDes = res.vehicleDes;
        this.lstMotorcleCard[index].vehicleNumerPlate = res.vehicleNumerPlate;
        this.lstMotorcleCard[index].files = res.files;
        this.lstMotorcleCard[index].vehicleTypeId = res.vehicleTypeId;
        this.lstMotorcleCard[index].isChecked = true;
      }
    });
  }

  openCarCardDetailModal(item, index) {
    console.log(item);
    console.log(this.lstCarCard);
    console.log(this.lstCarCardEdit);
    console.log(index);
    const dialofRefCarCard = this.modalService.open(ChangeCarCardComponent, { size: 'lg' });
    dialofRefCarCard.componentInstance.itemDetail = item;
    dialofRefCarCard.componentInstance.oldProcessStatus = this.oldProcessStatus;

    dialofRefCarCard.componentInstance.itemCarCard.subscribe((res) => {
      if (res) {
        if (!this.lstCarCardEdit.find(s => s == index) && this.isCreatePage) {
          this.lstCarCardEdit.push(index);
        }

        this.checkEditCarCard(index);

        this.lstCarCard[index].vehicleOwner = res.vehicleOwner;
        this.lstCarCard[index].vehicleBrand = res.vehicleBrand;
        this.lstCarCard[index].vehicleColor = res.vehicleColor;
        this.lstCarCard[index].vehicleName = res.vehicleName;
        this.lstCarCard[index].vehicleDes = res.vehicleDes;
        this.lstCarCard[index].vehicleNumerPlate = res.vehicleNumerPlate;
        this.lstCarCard[index].files = res.files;
        this.lstCarCard[index].vehicleTypeId = res.vehicleTypeId;
        this.lstCarCard[index].isChecked = true;
      }
    });
  }

  getResidentPhone(apartmentId) {
    this.commonService.getPurchaserByApartmentId(apartmentId).subscribe(
      (res) => {
        if (res) {
          this.item.residentPhone = res.Phone;
        } else {
          this.item.residentPhone = '';
        }
      },
      (err) => {
        this.item.residentPhone = '';
      }
    )
  }

  openModelCancel() {
    const dialofRefCancel = this.modalService.open(CancelRequestGroupComponent, { size: 'lg' });
    dialofRefCancel.componentInstance.id = this.item.id;

    dialofRefCancel.componentInstance.response.subscribe((res) => {
      if (res) {
        this.routerUrl.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
          this.routerUrl.navigate([`/utilities/resident-service`]);
        });
      }
    });
  }

  returnPage() {
    this.location.back();
  }

  onRemoveImage(item) {
    this.item.files = [...this.item.files.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.files.forEach(item => {
      lstImage.push({path: domainImage + item.url})
    })

    let prop = {
        images: lstImage,
        index
    };
    this.gallery.load(prop);
  }

  returnPagePaging() {
    this.routerUrl.navigate(['/utilities/resident-service']);
  }

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}

  checkEditResidentCard(index) {
    let indexEdit = this.lstResidentCardEdit.find(s => s == index);

    if (indexEdit) {
      return true;
    } else {
      return false;
    }
  }

  checkEditMotorcycleCard(index) {
    let indexEdit = this.lstMotorcycleCardEdit.find(s => s == index);

    if (indexEdit) {
      return true;
    } else {
      return false;
    }
  }

  checkEditCarCard(index) {
    let indexEdit = this.lstCarCardEdit.find(s => s == index);

    if (indexEdit) {
      return true;
    } else {
      return false;
    }
  }
}
