import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
	providedIn: 'root'
})
export class ListenChangeRouterService {

	private subject = new Subject<any>();

	sendMessage(message: string) {
		this.subject.next({ text: message });
	}

	clearMessage() {
		this.subject.next();
	}

	getMessage(): Observable<any> {
		return this.subject.asObservable();
	}
}
