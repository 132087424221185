import { extensionConstructionForm } from '../../../../data/Model';
import { domain, domainImage, ApiConstant, ExtensionConstructionStatus, domainFile } from './../../../../data/const';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterConstructionDocComponent } from '../register-construction-doc/register-construction-doc.component';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-extension-construction-dialog',
    templateUrl: './extension-construction-dialog.component.html',
    styleUrls: ['./extension-construction-dialog.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class ExtensionConstructionDialogComponent implements OnInit {
    @ViewChild('file') file: ElementRef;
    @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
    @Input() item: extensionConstructionForm;
    @Input() RegisterConstructionFormId: number;
    @Input() ContractorId: number;

    public extensionConstructionStatus = ExtensionConstructionStatus;
    public isLoading = false;
    public domainFile = domainFile;
    public domainImage = domainImage;
    public statusItem: number;
    public OldConstructionDateStart: any;
    public minDate: object;
    public maxDate: object;
    public isKSTDKTC = localStorage.getItem("roleCode") == "KSTDKTC" ? true : false;

    constructor(
        public modal: NgbActiveModal,
        private modalService: NgbModal,
        private readonly http: HttpClient,
        private readonly toastr: ToastrService,
        private gallery: Gallery,
        public modalDialogService: ModalDialogService,
        public viewRef: ViewContainerRef
    ) {
        this.item = new extensionConstructionForm();
    }

    ngOnInit() {
        this.statusItem = 0;
        if(this.item.Id) {
            this.statusItem = this.item.ExtensionConstructionStatus
        }
        else this.item.RegisterDate = new Date();

        this.extensionConstructionStatus.map((item, index, arr) => {
            this.statusItem > item.Id ? item["disabled"] = true : item["disabled"] = false;
            return item;
        });

        let OlDate = new Date(this.item.OldConstructionDateStart);
        this.OldConstructionDateStart = {
            year: OlDate.getFullYear(),
            month: OlDate.getMonth() + 1,
            day: OlDate.getDate() + 1
        };
    }

    onSubmit() {
        if(!this.item.OldConstructionDateStart) {
            this.toastWarning("Chưa có thông tin Ngày bắt đầu thi công cũ");
            return;
        }

        if(!this.item.OldConstructionDateEnd) {
            this.toastWarning("Chưa có thông tin Ngày kết thúc thi công cũ");
            return;
        }

        if(!this.item.ConstructionDateStart) {
            this.toastWarning("Chưa có thông tin Ngày bắt đầu gia hạn thi công");
            return;
        }

        if(!this.checkValidate(2)) {
            this.toastWarning("Thông tin Ngày bắt đầu gia hạn thi công không hợp lệ!");
            return;
        }

        if(!this.item.ConstructionDateEnd) {
            this.toastWarning("Chưa có thông tin Ngày kết thúc gia hạn thi công");
            return;
        }

        if(!this.checkValidate(3)) {
            this.toastWarning("Thông tin Ngày kết thúc gia hạn thi công không hợp lệ!");
            return;
        }

        if(!this.item.RegisterDate) {
            this.toastWarning("Chưa có thông tin Ngày đăng ký");
            return;
        }

        if(!this.checkValidate(1)) {
            this.toastWarning("Thông tin Ngày đăng ký không hợp lệ!");
            return;
        }

        if(!this.item.ExtensionConstructionStatus) {
            this.toastWarning("Chưa chọn Trạng thái");
            return;
        }

        if (this.item.ConstructionItem == "" || this.item.ConstructionItem == undefined) {
            this.toastWarning("Vui lòng nhập Hạng mục thi công");
            return;
        }

        if(this.item.ConstructionItem.trim() == "") {
            this.toastWarning("Vui lòng nhập Hạng mục thi công");
            return;
        }

        this.item.OldConstructionDateStart = this.item.OldConstructionDateStart ?
            new Date(moment(this.item.OldConstructionDateStart.toString()).format('YYYY-MM-DD')) : null;

        this.item.OldConstructionDateEnd = this.item.OldConstructionDateEnd ?
            new Date(moment(this.item.OldConstructionDateEnd.toString()).format('YYYY-MM-DD')) : null;

        this.item.ConstructionDateStart = this.item.ConstructionDateStart ?
            new Date(moment(this.item.ConstructionDateStart.toString()).format('YYYY-MM-DD')) : null;

        this.item.ConstructionDateEnd = this.item.ConstructionDateEnd ?
            new Date(moment(this.item.ConstructionDateEnd.toString()).format('YYYY-MM-DD')) : null;

        this.item.RegisterDate = this.item.RegisterDate ?
            new Date(moment(this.item.RegisterDate.toString()).format('YYYY-MM-DD')) : null;

        this.isLoading = true;
        if(!this.item.Id) {
            this.http.post(ApiConstant.CreateExtensionConstructionForm, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Tạo đơn gia hạn mới thành công");
                        this.isLoading = false;

                        this.eventEmitter.emit(true);
                        this.modal.close();
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        }
        else {
            this.http.put(ApiConstant.UpdateExtensionConstructionForm + this.item.Id, this.item).subscribe(
                (res) => {
                    if (res['meta']['error_code'] == 200) {
                        this.toastSuccess("Cập nhật đơn gia hạn thành công");
                        this.isLoading = false;

                        this.eventEmitter.emit(true);
                        this.modal.close();
                    } else {
                        this.isLoading = false;
                        this.toastError(res["meta"]["error_message"]);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.toastError("Error Api");
                })
        }
    }

    onCheckboxChanged(value) {

    }

    omit_special_char(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return true;
        }
        return false;
    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }

    openRegisterConstructionDocModal() {
        const dialofRef = this.modalService.open(RegisterConstructionDocComponent, { size: 'lg' });

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            if(!this.item.registerConstructionDocs) this.item.registerConstructionDocs = [];
            this.item.registerConstructionDocs.push(res);
        });
    }

    openRegisterConstructionDocDetailModal(item, idx) {
        const dialofRef = this.modalService.open(RegisterConstructionDocComponent, { size: 'lg' });
        dialofRef.componentInstance.itemDetail = JSON.parse(JSON.stringify(item));

        if(this.statusItem == 3 || this.statusItem == 4 || this.statusItem == 2) {
            dialofRef.componentInstance.hideBtnSave = true;
        }

        dialofRef.componentInstance.eventEmitter.subscribe((res) => {
            this.item.registerConstructionDocs[idx] = res;
        });
    }

    deleteRegisterConstructionDoc(idx) {
        this.modalDialogService.openDialog(this.viewRef, {
            title: 'Xác nhận',
            settings: {
                footerClass: 'footer-dialog modal-footer',
            },
            childComponent: SimpleModalComponent,
            data: {
                text: "Bạn chắc muốn xóa đơn thông tin hồ sơ thi công này không?"
            },
            actionButtons: [
                {
                    text: 'Hủy',
                    buttonClass: 'btn btn-default',
                },
                {
                    text: 'Xác nhận',
                    buttonClass: 'btn btn-success',
                    onAction: () => {
                        this.item.registerConstructionDocs.splice(idx, 1);
                        this.viewRef.clear();
                    }
                }
            ],
        });
    }

    upload(files) {
        if (files.length === 0)
            return;

        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        const uploadReq = new HttpRequest('POST', domain + 'api/app/Upload/uploadImage', formData, {
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {

            }
            else if (event.type === HttpEventType.Response) {
                if (event.body["data"].length) {
                    event.body["data"].forEach(element => {
                        if(!this.item.objectFiles) this.item.objectFiles = [];
                        this.item.objectFiles.unshift({
                            FileId: 0,
                            FileRef: '',
                            Url: element.toString()
                        });
                    });
                }
                if (this.file) this.file.nativeElement.value = "";
            }
        });
    }

    onRemoveImage(item) {
        this.item.objectFiles = [...this.item.objectFiles.filter(s => s != item)];
    }

    onShowGallery(index: number) {
        let lstImage = [];

        this.item.objectFiles.forEach(item => {
            lstImage.push({ path: domainImage + item.Url })
        })

        let prop = {
            images: lstImage,
            index
        };
        this.gallery.load(prop);
    }

    checkMinMax(event, type) {
        if(event) {
            let currDate = new Date(event);
            if(type == 1) {
                this.minDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
            else {
                this.maxDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
        }
        else {
            if(type == 1) this.minDate = undefined;
            else this.maxDate = undefined;
        }
    }

    checkValidate(cs) {
        if(cs == 1) {
            if(this.item.RegisterDate) {
                if(moment(this.item.RegisterDate.toString()).isValid() == false) return false;
            }
        }
        else if(cs == 2) {
            if(this.item.ConstructionDateStart) {
                if(moment(this.item.ConstructionDateStart.toString()).isValid() == false) return false;
            }
        }
        else if(cs == 3) {
            if(this.item.ConstructionDateEnd) {
                if(moment(this.item.ConstructionDateEnd.toString()).isValid() == false) return false;
            }
        }
        return true;
    }
}
