import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../../service/shared.service';
import { Paging, QueryFilter } from '../../../../data/dt';
import { ConfigRentalApartment } from '../../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, domainApi, TypeDepartment, TypeAttribute_RequireSupport, PaymentMethod } from '../../../../data/const';
import { Title } from "@angular/platform-browser";
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { RentalApartmentService } from './../../../../service/rental-apartment';

//Định dạng ngày h trên Modal
export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
	selector: 'app-config-rental-apartment',
	templateUrl: './config-rental-apartment.component.html',
	styleUrls: ['./config-rental-apartment.component.scss'],
    providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class ConfigRentalApartmentComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;
	@ViewChild('file') file: ElementRef;

	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public projects = [];
	public listProject = [];
	public listTower = [];
	public Item: ConfigRentalApartment;

	public httpOptions: any;

	public submitted = false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;

	public domainImage = domainImage;

	public listRequireType = [];

	public typeDepartment = TypeDepartment;

	public paymentMethod = PaymentMethod;

	public minDate: object;
    public maxDate: object;

    public messageError = '';
    public numberItemDelete = 0;

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService, public viewRef: ViewContainerRef, public toastr: ToastrService, public titleService: Title, private readonly rentalApartmentService: RentalApartmentService) {
		this.Item = new ConfigRentalApartment();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.titleService.setTitle("S Plus - Cấu hình ưu đãi dv cho thuê/bán nhà");
		this.onFilter();
		this.GetListProject();
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.onFilter();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = '';

		if (this.q["ProjectId"] != undefined) {
			if (query != '') {
				query += ' and ProjectId=' + this.q["ProjectId"];
			}
			else {
				query += 'ProjectId=' + this.q["ProjectId"];
			}
		}

		if (this.q["TypeDepartment"] != undefined) {
			if (query != '') {
				query += ' and Type=' + this.q["TypeDepartment"];
			}
			else {
				query += 'Type=' + this.q["TypeDepartment"];
			}
		}

		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			if (query != '') {
				query += ' and (Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';
			}
			else {
				query += '(Code.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '") or Name.ToLower().Contains("' + this.q.txtSearch.toLowerCase() + '"))';

			}
		}

		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.onFilter();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.onFilter();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(Id) {
		if (Id == undefined) {
			let CheckAll = this.CheckAll;
			this.listCommon.forEach(item => {
				item.Action = CheckAll;
			});
		}
		else {
			let CheckAll = true;
			for (let i = 0; i < this.listCommon.length; i++) {
				if (!this.listCommon[i].Action) {
					CheckAll = false;
					break;
				}
			}

			this.CheckAll = CheckAll == true ? true : false;
		}

		this.numberItemDelete = this.listCommon.filter(x => x.isChecked == true).length;
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				this.onDeletes();
				break;
			default:
				break;
		}
	}

	onDeletes() {
        let data = [];
        this.listCommon.forEach(item => {
            if (item.isChecked == true) {
                data.push(item.id);
            }
        });

        if (data.length > 0) {
            this.modalDialogService.openDialog(this.viewRef, {
                title: 'Xác nhận',
                settings: {
                    footerClass: 'footer-dialog modal-footer'
                },
                childComponent: SimpleModalComponent,
                data: {
                    text: "Bạn chắc muốn xóa các đơn cấu hình ưu đãi dv cho thuê/bán nhà đã chọn không?"
                },
                actionButtons: [
                    {
                        text: 'Hủy',
                        buttonClass: 'btn btn-default',
                    },
                    {
                        text: 'Xác nhận',
                        buttonClass: 'btn btn-success',
                        onAction: () => {
                            for (let i = 0; i < data.length; i++) {
                                this.http.delete(domainApi + "/RequestGroup/RequestRentalApartmentConfigDelete/" + data[i])
                                    .subscribe(
                                        (res) => {
                                            if (res["meta"]["error_code"] == 200) {
                                            }
                                        },
                                        (err) => {
                                            this.toastWarning(`Xóa danh sách Đơn cấu hình ưu đãi dv cho thuê/bán nhà thất bại. ${err["meta"]["error_message"]}`);
                                        });

                                if (i === data.length) {
                                    this.onFilter();
                                    this.toastSuccess("Xóa thành công!");
                                }
                            }

                            this.viewRef.clear();
                        }
                    }
                ],
            });
        }
    }

	onFilter() {
        this.messageError = '';
        this.listCommon = [];
        this.numberItemDelete = 0;
        this.q.StatusProcessId = this.q.StatusProcessId || null;

        this.rentalApartmentService.getConfigRentalByPage(this.q, this.paging, null, null, null).subscribe((res) => {
            if (res["meta"]["error_code"] == 200) {
                if (res["data"]["results"].length) {
                    this.listCommon = res["data"]["results"];
                    this.listCommon.map(s => s.isChecked = false);
                    this.paging.item_count = res["data"]["rowCount"];
                } else {
                    this.listCommon = [];
                    this.paging.item_count = 0;
                    this.messageError = 'Không có dữ liệu';
                }
            }
            else {
                this.listCommon = [];
                this.paging.item_count = 0;
                this.messageError = 'Không có dữ liệu';
            }
        },
            (error) => {
                this.toastError('Api Error!');
                this.paging.item_count = 0;
            })
    }

	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=&select=ProjectId,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.listProject = res["data"];
					if (this.listProject.length == 1) {
						this.q.ProjectId = this.listProject[0].ProjectId;
					}
				}
			},
			(err) => {
				console.log("Error: connect to API");
			}
		);
	}

    GetListTower(OpenUpdate) {
		this.listTower = [];
		if (!OpenUpdate) this.Item.towerId = undefined;
		if (this.Item.projectId) {
			let query = "ProjectId=" + this.Item.projectId;
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=CreatedAt+Desc&select=TowerId,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.listTower = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	OpenCommonModal(item) {
		this.submitted = false;
		this.minDate = undefined;
		this.maxDate = undefined;
		this.Item = new ConfigRentalApartment();
		if (item == undefined) {
			if (this.q.ProjectId) {
				this.Item.projectId = this.q.ProjectId;
			}
		}
		else {
			this.Item = JSON.parse(JSON.stringify(item));
			if (this.Item.fromTime) this.checkMinMax(this.Item.fromTime,1);
			if (this.Item.toTime) this.checkMinMax(this.Item.toTime,2);

		}

        this.GetListTower(true);

		this.modalCommon.show();
	}

	SaveCommon() {
		this.submitted = true;

		let data = Object.assign({}, this.Item);

		if (data.id == undefined) {
			this.http.post(domainApi + '/RequestGroup/RequestRentalApartmentConfigPost', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.onFilter();
						this.modalCommon.hide();
						this.toastSuccess("Thêm mới thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
		else {
			this.http.post(domainApi + '/RequestGroup/RequestRentalApartmentConfigPost', data, this.httpOptions).subscribe(
				(res) => {
					this.submitted = false;
					if (res["meta"]["error_code"] == 200) {
						this.onFilter();
						this.modalCommon.hide();
						this.toastSuccess("Cập nhật thành công!");
						this.form.resetForm();
					}
					else {
						this.toastError(res["meta"]["error_message"]);
					}
				},
				(err) => {
					this.submitted = false;
					this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
				}
			);
		}
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
          footerClass: 'footer-dialog modal-footer'
        },
childComponent: SimpleModalComponent,
			data: {
				text: "Bạn có chắc chắn muốn xóa mục này?"
			},
			actionButtons: [
				{
					text: 'Đồng ý',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/RequestGroup/RequestRentalApartmentConfigDelete/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.onFilter();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				},
				{
					text: 'Đóng',
					buttonClass: 'btn btn-default',

				}
			],
		});
	}

	ShowDepartment(Type) {
		let obj = this.typeDepartment.filter(x => x.Id == Type)[0]
		return obj != undefined ? obj.Name : "";
	}

	//upload ảnh hiển thị
	upload(files) {
		if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domainApi + '/upload/uploadImage', formData, {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
				// this.Item.Image = event.body["data"].toString();
				// if (this.file) this.file.nativeElement.value = "";
			}
		});
	}

	onChangePrice(value, type) {
		if(value) {
			if(type==1)
        		this.Item.rentalPromotionValue = Number(value.replace(/[^0-9.-]+/g, ''));
			else
				this.Item.promotionValue = Number(value.replace(/[^0-9.-]+/g, ''));
		}
    }

	checkMinMax(event, type) {
        if(event) {
            let currDate = new Date(event);
            if(type == 1) {
                this.minDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
            else {
                this.maxDate = {
                    year: currDate.getFullYear(),
                    month: currDate.getMonth() + 1,
                    day: currDate.getDate()
                };
            }
        }
        else {
            if(type == 1) this.minDate = undefined;
            else this.maxDate = undefined;
        }
    }
}
