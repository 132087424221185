import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
	public domainImage: string = "https://localhost:44304/";

  constructor() { }

  ConvertUrl(str) {
    str = str.toLowerCase();
    str = str.replace(/á|à|ả|ã|ạ|â|ấ|ầ|ẩ|ẫ|ậ|ă|ắ|ằ|ẳ|ẵ|ặ"/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    str = str.replace(/[^a-zA-Z0-9 ]/g, "");
    str = str.replace(/ + /g, " ");
    str = str.trim();
    str = str.replace(/ /g, "-");

    return str;
  }

  ConvertVitoEn(str) {
    str = str.replace("/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/", 'a', str);
    str = str.replace("/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/", 'e', str);
    str = str.replace("/(ì|í|ị|ỉ|ĩ)/", 'i', str);
    str = str.replace("/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/", 'o', str);
    str = str.replace("/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/", 'u', str);
    str = str.replace("/(ỳ|ý|ỵ|ỷ|ỹ)/", 'y', str);
    str = str.replace("/(đ)/", 'd', str);
    str = str.replace("/(À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ)/", 'A', str);
    str = str.replace("/(È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ)/", 'E', str);
    str = str.replace("/(Ì|Í|Ị|Ỉ|Ĩ)/", 'I', str);
    str = str.replace("/(Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ)/", 'O', str);
    str = str.replace("/(Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ)/", 'U', str);
    str = str.replace("/(Ỳ|Ý|Ỵ|Ỷ|Ỹ)/", 'Y', str);
    str = str.replace("/(Đ)/", 'D', str);
    return str;
  }


  CheckAccessKey(Str: string, Code: string, index: number) {
    let Arr = [];
    Arr = Str.split('-');
    for (let i = 0; i < Arr.length; i++) {
      let ConvertArr = Arr[i].split(':');
      if (Code == ConvertArr[0]) {
        let check = ConvertArr[1].substr(index, 1);
        if (check == "1") {
          return true;
        }
        else {
          return false;
        }
      }
    }

    return false;
  }

  ConvertDateTime(obj: Date) {
    return obj.getFullYear() + "-" + (obj.getMonth() + 1) + "-" + obj.getDate() + " " + obj.getHours() + ":" + obj.getMinutes() + ":" + obj.getSeconds();
  }

  ConvertDateQuery(str: string) {
    let string = str.split("T")[0];
    return string.split("-");
  }

  ConvertHourQuery(str: string) {
    let string = str.split("T")[1];
    return string.split(":");
  }

}
