import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OwlDateTimeFormats, OwlDateTimeComponent, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ServiceOrder, ServiceRate } from '../../../../../data/Model';
import { domainApi, domain, listAcceptanceStatus, typeServiceRate } from '../../../../../data/const';
import { s } from '@angular/core/src/render3';

@Component({
  selector: 'app-add-quatily-evalution-dialog',
  templateUrl: './add-quatily-evalution-dialog.component.html',
  styleUrls: ['./add-quatily-evalution-dialog.component.scss']
})
export class AddQuatilyEvalutionDialogComponent implements OnInit {
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
  @Input() projectId: number;
  @Input() towerId: number;
  @Input() serviceOrderId: number;

  public ItemRate: ServiceRate;
  public typeRates = [];
  public typeRateItems = [];
  public httpOptions: any;
  public isLoading = false;

  constructor(
    public modal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly http: HttpClient,
  ) {
    this.ItemRate = new ServiceRate();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
  }

  ngOnInit() {
    this.GetListTypeRate();
  }

  GetListTypeRate() {
    this.http.get(domainApi + '/typeAttributeItem/codeAttribute/LTCDG', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.typeRates = res["data"];
          this.ItemRate.RateAttributeId = this.typeRates[0].TypeAttributeItemId;
          this.GetListTypeRateItem(this.typeRates[0].TypeAttributeItemId, this.projectId, this.towerId);
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  GetListTypeRateItem(typeRateId, projectId, towerId) {
    let query = "TypeRateId=" + typeRateId + "AND ProjectId=" + projectId + "AND TowerId=" + towerId;
    this.http.get(domainApi + '/rateType/GetByPage?page=1&page_size=100&query=' + query + '&order_by=', this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.typeRateItems = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  onSubmit() {
    this.eventEmitter.emit(this.ItemRate);
    this.modal.close();
  }

  onRate() {
    this.ItemRate.ProjectId = this.projectId;
    this.ItemRate.ServiceOrderId = this.serviceOrderId;
    this.ItemRate.Type = typeServiceRate.CLEANING_SERVICE;
    this.ItemRate.LisRateTypeId ="";
    // this.ItemRate.Contents = this.typeRates.find(s => s.TypeAttributeItemId == this.ItemRate.RateAttributeId) ?
    // this.typeRates.find(s => s.TypeAttributeItemId == this.ItemRate.RateAttributeId).Name : '';
    let listRateTypeId="";
    for(let i=0; i < this.typeRateItems.length; i++){
        if(this.typeRateItems[i].Check){
          listRateTypeId += this.typeRateItems[i].Id + "-";
        }
    }
    this.ItemRate.LisRateTypeId =listRateTypeId.substring(0, listRateTypeId.length -1);
    let data = Object.assign({}, this.ItemRate);
    data.createdById = parseInt(localStorage.getItem("userId"));
    this.http.post(domain + 'api/app/serviceRate', data, this.httpOptions).subscribe(
      (res) => {
        this.isLoading = false;
        if (res["meta"]["error_code"] == 200) {
          this.toastSuccess("Đánh giá thành công!");
          this.eventEmitter.emit(true);
          this.modal.close();
        }
        else {
          this.toastError(res["meta"]["error_message"]);
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
      }
    );
  }



  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }

  onChangeRate() {
    this.GetListTypeRateItem(this.ItemRate.RateAttributeId, this.projectId, this.towerId);

    const typeRateChecked = this.typeRates.find(s => s['TypeAttributeItemId'] == this.ItemRate.RateAttributeId);
    this.ItemRate.Contents = typeRateChecked ? typeRateChecked.Name : '';
  }
}
