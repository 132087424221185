import { deposit } from '../../../../data/Model';
import { domain, domainImage, ApiConstant, DepositType } from './../../../../data/const';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import * as moment from 'moment';

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: ' HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
    selector: 'app-deposit-dialog',
    templateUrl: './deposit-dialog.component.html',
    styleUrls: ['./deposit-dialog.component.scss'],
    providers: [
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
    ]
})
export class DepositDialogComponent implements OnInit {
    @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
    @Input() itemDetail: deposit;
    @Input() RegisterConstructionFormId: number;

    public item: deposit;
    public depositType = DepositType;
    public isLoading = false;

    constructor(
        public modal: NgbActiveModal,
        private readonly http: HttpClient,
        private readonly toastr: ToastrService,
        private gallery: Gallery
    ) {
        this.item = new deposit();
    }

    ngOnInit() {
        if (this.itemDetail) {
            this.item = this.itemDetail;
        }
        else {
            this.item.RegisterConstructionFormId = this.RegisterConstructionFormId;
        }
    }

    onSubmit() {
        if(!this.item.DepositDate) {
            this.toastWarning("Chưa có thông tin Ngày đặt cọc");
            return;
        }

        if(!this.checkValidate(1)) {
            this.toastWarning("Thông tin Ngày đặt cọc không hợp lệ!");
            return;
        }

        if(!this.item.AmountDeposit) {
            this.toastWarning("Chưa có thông tin Số tiền cọc");
            return;
        }

        if(!this.item.DepositType) {
            this.toastWarning("Chưa có thông tin Hình thức đặt cọc");
            return;
        }

        this.item.DepositDate = this.item.DepositDate ?
            new Date(moment(this.item.DepositDate.toString()).format('YYYY-MM-DD')) : null;

        this.eventEmitter.emit(this.item);
        this.modal.close();
    }

    onCheckboxChanged(value) {

    }

    omit_special_char(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return true;
        }
        return false;
    }

    toastWarning(msg): void {
        this.toastr.warning(msg, 'Cảnh báo');
    }
    //Toast thành công
    toastSuccess(msg): void {
        this.toastr.success(msg, 'Hoàn thành');
    }
    //Toast thành công
    toastError(msg): void {
        this.toastr.error(msg, 'Lỗi');
    }

    onChangePrice(value) {
        this.item.AmountDeposit = Number(value.replace(/[^0-9.-]+/g, ''));
    }

    checkValidate(cs) {
        if(cs == 1) {
            if(this.item.DepositDate) {
                if(moment(this.item.DepositDate.toString()).isValid() == false) return false;
            }
        }
        return true;
    }
}
