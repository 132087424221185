import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { domainApi, domain } from '../../data/const';
import * as Chart from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Title } from "@angular/platform-browser";
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, map } from 'rxjs/operators';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { QueryFilter } from '../../data/dt';
import * as moment from 'moment';
import { CommonService } from './../../service/common.service';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { jsPDF } from "jspdf";
import * as saveAs from 'file-saver';
import { forEach } from '@angular/router/src/utils/collection';
// import DataLabelsPlugin from 'chartjs-plugin-datalabels';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: ' HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})
export class DashboardComponent implements OnInit {
  @ViewChild(BaseChartDirective) barChart: BaseChartDirective;

  public listProjects = [];
  public projectId: number;
  public startDate: any;
  public endDate: any;
  public type: number;

  public data: any = {};
  public dataRate: any = {};
  public Item: QueryFilter;
  public serviceTechActive: number;
  public serviceTechTotal: number;
  public serviceCleaningTotal: number;
  //public labelChart: any;
  public httpOptions: any;

  //Loại yêu cầu
  public typeServices = [{ Id: 1, Name: "Dịch vụ" }, { Id: 2, Name: "Tiện ích" }];
  public typeService = { ToTalService: 1, SlaAverageProcess: 1, SlaAverageResponse: 1 };

  //Pie Chart for Box Nationality
  public nationalityChartData = [70, 30];
  public nationalityChartLabels: string[] = ['Việt Nam', 'Nước ngoài'];

  public nationalityChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = (value * 100 / sum).toFixed(0) + "%";
          return percentage;
        },
        color: 'black'
      }
    }
  };

  public nationalityChartColor = [
    {
      backgroundColor: ['#34b5de', '#a1d2ed'],
    },
  ];

  //End Pie Chart for Box Nationality
  // barChart
  public barChartOptions: any = {
    legend: {
      labels: {
        usePointStyle: true,
      }
    },
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return '';
        },
        color: 'black'
      }
    }
  };
  public barChartLabels: string[] = ['X'];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData: any[] = [
    { data: [0], label: 'Tiện ích' },
    { data: [0], label: 'Dịch vụ' },
    { data: [0], label: 'Yêu cầu hỗ trợ' },
    { data: [0], label: 'Đánh giá' }
  ];

  public pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'left',
      labels: {
        fontSize: 12,
        usePointStyle: true,
        generateLabels: function (chart) {
          var data = chart.data;
          var theHelp = Chart.helpers;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map(function(label, i) {
              var meta = chart.getDatasetMeta(0);
              var ds = data.datasets[0];
              var arc = meta.data[i];
              var custom = arc && arc.custom || {};
              var getValueAtIndexOrDefault = theHelp.getValueAtIndexOrDefault;
              var arcOpts = chart.options.elements.arc;
              var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
              var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
              var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
                return {
                // And finally :
                text: label + ": " + ds.data[i],
                fillStyle: fill,
                strokeStyle: stroke,
                lineWidth: bw,
                hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                index: i
              };
            });
          }
          return [];
        }
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = (value * 100 / sum).toFixed(0) + "%";
          return percentage;
        },
        color: 'black'
      }
    }
  };

  public pieChartColors = [
    {
      backgroundColor: ['#36c', '#909', '#dc3912', '#6dc6f9', '#109618', '#dd4477', '#36cc', '#90909', '#dc1232']
    }
  ];
  //Pie Chart Reg Card
  public pieRegCardChartLabels: string[] = ['Đăng ký mới', "Bổ sung thông tin", "Đang xử lý", "Không phê duyệt đăng ký", "Phê duyệt đăng ký", "Yêu cầu đóng phí", "Chờ cấp thẻ", "Chờ bàn giao", "Hoàn thành", 'Hủy dịch vụ'];
  public pieRegCardChartData: number[] = [];

  //Pie Chart Transpost Card
  public pieTranspostChartLabels: string[] = ['Đăng ký mới', 'Bổ sung thông tin', "Đang xử lý" , "Không phê duyệt đăng ký", 'Phê duyệt đăng ký', 'Hoàn thành'];
  public pieTranspostChartData: number[] = [];

  //Pie Chart Service Tech
  // public pieServiceTechChartLabels: string[] = ['Đăng ký mới', 'Kỹ thuật đang xử lý', 'Chờ xác nhận lịch khảo sát', 'Thực hiện khảo sát', 'Chờ xác nhận phí', 'Thực hiện dịch vụ', 'Chờ cư dân nghiệm thu', 'Hoàn thành', 'Hủy dịch vụ'];
  public pieServiceTechChartLabels: string[] = ['Đã gửi yêu cầu', "Đã tiếp nhận", "Chờ xác nhận phiếu", "Cư dân xác nhận", "Thực hiện dịch vụ", "Hoàn thành", 'Hủy dịch vụ'];
  public pieServiceTechChartData: number[] = [];

  //Pie Chart Reg Construction
  public pieChartRentalLabels: string[] = ['Đăng ký mới', 'Dịch vụ đang xử lý', 'Đang thực hiện dịch vụ', 'Chờ xác nhận lịch xem nhà', 'Chờ xem nhà', 'Hoàn thành', 'Hủy'];
  public pieChartRentalData: number[] = [];

  //Pie Chart Reg Construction
  public pieChartRegConstructionLabels: string[] = ['Đăng ký mới', 'Bổ sung thông tin', 'Không phê duyệt đăng ký', 'Phê duyệt đăng ký', 'Hoàn thành'];
  public pieChartRegConstructionData: number[] = [];

  //Pie Chart Request cleaning
  public pieChartRequestCleaningLabels: string[] = ['Đã gửi yêu cầu', "Đã tiếp nhận", "Chờ xác nhận phiếu", "Cư dân xác nhận", "Thực hiện dịch vụ", "Hoàn thành", 'Hủy dịch vụ'];
  public pieChartRequestCleaningData: number[] = [];

  //
  public pieChartPlugins = [pluginDataLabels];

  public radioModel: string = '1';

  //Biểu đồ Tổng yêu cầu đăng ký dịch vụ, tiện ích
  public barChartTotalServiceOptions: any = {
    legend: {
      labels: {
        usePointStyle: true,
      }
    },
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return '';
        },
        color: 'black'
      }
    }
  };
  public barChartTotalServiceLabels: string[] = ['X'];
  public barChartTotalServiceType = 'bar';
  public barChartTotalServiceLegend = true;

  public barChartTotalServiceData: any[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
    { data: [0], label: '' }
  ];

  //SLA
  public slaAverageProcessData: any;
  public slaAverageResponseData: any;

  //Biểu đồ Đánh giá dịch vụ
  public barChartRateServiceOptions: any = {
    legend: {
      labels: {
        usePointStyle: true,
      }
    },
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        }
      }]
    }
  };
  public barChartRateServiceLabels: string[] = ['X'];
  public barChartRateServiceType = 'bar';
  public barChartRateServiceLegend = true;

  public barChartRateServiceData: any[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
    { data: [0], label: '' }
  ];

  //Thống kê duyệt trên tổng số yêu cầu
  public dataAccept: any;
  public dataApartmentbyTypeResident: any;

  //Biểu đồ SLA xử lý phản hồi so với tiêu chuẩn
  public barChartSlaOptions: any = {
    legend: {
      labels: {
        usePointStyle: true,
      }
    },
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        }
      }],
      xAxes: [{
        ticks: {
          // min: "Gold Mark",
          // max: "Mandarin"
          // min: function(chart) {
          //   var data = chart.data;
          //   if (data.labels.length) {
          //     return data.labels[1];
          //   }
          //   return '';
          // },
          // max: function(chart) {
          //   var data = chart.data;
          //   if (data.labels.length) {
          //     return data.labels[data.labels.length - 1];
          //   }
          //   return '';
          // }
        }
      }]
    },
   plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return '';
        },
        color: 'black'
      }
    }
  };

  public barChartSlaProcessLabels: string[] = ['.', '.'];

  public barChartSlaProcessData: any[] = [
    { data: [undefined, undefined], label: 'Dịch vụ kỹ thuật' },
    { data: [undefined, undefined], label: 'Dịch vụ vệ sinh' },
    { data: [undefined, undefined], label: 'Dịch vụ cho thuê/bán nhà' },
    { data: [0, 0], label: 'Dịch vụ kỹ thuật tiêu chuẩn', type: 'line', backgroundColor:'#f2f5f700', borderColor: '#fd8181' },
    { data: [0, 0], label: 'Dịch vụ vệ sinh tiêu chuẩn', type: 'line', backgroundColor:'#f2f5f700', borderColor: '#8ad1fa' },
    { data: [0, 0], label: 'Dịch vụ cho thuê/bán nhà tiêu chuẩn', type: 'line', backgroundColor:'#f2f5f700', borderColor: '#5d81a0' }
  ];

  public barChartColors = [
    {
      backgroundColor: ['#fd8181', '#fd8181', '#fd8181', '#fd8181', '#fd8181', '#fd8181', '#fd8181', '#fd8181', '#fd8181']
    },
     {
      backgroundColor: ['#8ad1fa', '#8ad1fa', '#8ad1fa', '#8ad1fa', '#8ad1fa', '#8ad1fa', '#8ad1fa', '#8ad1fa', '#8ad1fa']
    },
    {
      backgroundColor: ['#5d81a0', '#5d81a0', '5d81a0', '#5d81a0', '#5d81a0', '#5d81a0', '#5d81a0', '#5d81a0', '#5d81a0']
    }
  ];

  public barChartSlaResponseLabels: string[] = ['.', '.'];

  public barChartSlaResponseData: any[] = [
    { data: [undefined, undefined], label: 'Dịch vụ kỹ thuật' },
    { data: [undefined, undefined], label: 'Dịch vụ vệ sinh' },
    { data: [undefined, undefined], label: 'Dịch vụ cho thuê/bán nhà' },
    { data: [0, 0], label: 'Dịch vụ kỹ thuật tiêu chuẩn', type: 'line', backgroundColor:'#f2f5f700', borderColor: '#fd8181' },
    { data: [0, 0], label: 'Dịch vụ vệ sinh tiêu chuẩn', type: 'line', backgroundColor:'#f2f5f700', borderColor: '#8ad1fa' },
    { data: [0, 0], label: 'Dịch vụ cho thuê/bán nhà tiêu chuẩn', type: 'line', backgroundColor:'#f2f5f700', borderColor: '#5d81a0' }
  ];

  public behaviorUserChartData: any[] = [
    { code: 'home', data: [], label: 'Trang chủ', type: 'line', borderColor:'#1f77b4', backgroundColor: 'rgba(0,0,0,0)' },
    { code: 'technicalService', data: [], label: 'Dịch vụ kỹ thuật', type: 'line', borderColor:'#d62728', backgroundColor: 'rgba(0,0,0,0)' },
    { code: 'cleaningService', data: [], label: 'Dịch vụ vệ sinh', type: 'line', borderColor:'#e377c2', backgroundColor: 'rgba(0,0,0,0)' },
    { code: 'registerShipping', data: [], label: 'Đăng ký chuyển hàng', type: 'line', borderColor:'#2ca02c', backgroundColor: 'rgba(0,0,0,0)' },
    { code: 'reflect', data: [], label: 'Phản ánh', type: 'line', borderColor:'#ff7f0e', backgroundColor: 'rgba(0,0,0,0)' },
    { code: 'residentCardRegistration', data: [], label: 'Đăng ký thẻ cư dân về ở', type: 'line', borderColor:'#5cadff', backgroundColor: 'rgba(0,0,0,0)' },
    { code: 'constructionRegistration', data: [], label: 'Đăng ký thi công', type: 'line', borderColor:'#bcbd22', backgroundColor: 'rgba(0,0,0,0)' },
    {  code: 'banner', data: [], label: 'Banner', type: 'line', borderColor:'#8c564b', backgroundColor: 'rgba(0,0,0,0)' }
  ];
  public behaviorUserChartLabels: string[] = ['03/08','04/08', '05/08','06/08', '07/08','08/08', '09/08'];
  public behaviorUserChartColors = [
    {
      backgroundColor: ['rgba(0,0,0,0)','rgba(0,0,0,0)']
    }
  ];
  public behaviorUserChartOptions: any = {
    legend: {
      labels: {
        usePointStyle: true,
      }
    },
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        }
      }],
      xAxes: [{
        ticks: {
          // min: "Gold Mark",
          // max: "Mandarin"
          // min: function(chart) {
          //   var data = chart.data;
          //   if (data.labels.length) {
          //     return data.labels[1];
          //   }
          //   return '';
          // },
          // max: function(chart) {
          //   var data = chart.data;
          //   if (data.labels.length) {
          //     return data.labels[data.labels.length - 1];
          //   }
          //   return '';
          // }
        }
      }]
    },
   plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return '';
        },
        color: 'black'
      }
    }
  };

  constructor(
    public http: HttpClient,
    public titleService: Title,
    public commonService: CommonService
  ) {
    this.Item = new QueryFilter();
    this.Item.ProjectId = -1;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer ' + localStorage.getItem("access_token")
      })
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle("S Plus - Tổng quan");
    this.getProjects();
    // this.GetDataDashBoard();
    // this.GetDataPieChart();
    // this.GetServiceTechStatus();
    // this.GetSlaAverageProcessData();
    // this.GetSlaAverageResponseData();
    this.filter();
  }

  // GetDataDashBoard() {
  //   this.http.get(domainApi + '/Dashboard/GetDataDashBoard', this.httpOptions).subscribe(
  //     (res) => {
  //       if (res["meta"]["error_code"] == 200) {
  //         this.data = res["data"];
  //       }
  //     },
  //     (err) => {
  //       console.log("Error: connect to API");
  //     });
  // }

  GetDataPieChart() {
    this.http.get(domainApi + '/RequireSupport/GetDataPieChart?Type=' + this.radioModel, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          // this.labelChart = res["meta"]["error_message"];
          // this.pieChartData = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  // GetServiceTechStatus(data) {
  //   this.http.post(domainApi + '/Dashboard/GetServiceTechStatus', data, this.httpOptions).subscribe(
  //     (res) => {
  //       if (res["meta"]["error_code"] == 200) {
  //         //this.pieServiceTechChartLabels = res["data"]["labels"];
  //         this.pieServiceTechChartData = res["data"]["datas"];
  //         var active = (res["data"]["active"] * 100) / res["data"]["total"];
  //         this.serviceTechActive = parseInt(active.toFixed());
  //         this.serviceTechTotal = res["data"]["total"];
  //         //console.log(this.serviceTechActive);
  //       }
  //       else {
  //         console.log("Error: connect to API");
  //       }
  //     },
  //     (err) => {
  //       console.log("Error: connect to API");
  //     }
  //   );
  // }

  GetServiceCleaningAndTechnicalServices(data) {
    let startDate: string;
    let endDate: string;
    if(typeof data.DateTimeStart == 'undefined' || typeof data.DateTimeEnd == 'undefined'){
      var date = new Date();
      startDate = moment(new Date(date.getFullYear(), ((this.quarter_of_the_year(date) - 1) * 3) + 0, 1).toUTCString()).format("YYYY-MM-DD");
      endDate = moment(new Date(date.getFullYear(), this.quarter_of_the_year(date) * 3 + 0, 0).toUTCString()).format("YYYY-MM-DD");
    }else{
      startDate = moment(data.DateTimeStart).format("YYYY-MM-DD");
      endDate = moment(data.DateTimeEnd).format("YYYY-MM-DD");
    }
    this.http.get(domain + 'add-on/api/admin/dashboard/services?startDate='+ startDate +'&endDate='+ endDate).subscribe(
      (res) => {
        // Dịch vụ kỹ thuật
        const technical = res["technical"];
        let dataTechnicalChart = [];
        technical.StatusDetail.forEach(item => {
          dataTechnicalChart.push(item.CountStatus);
        });
        this.pieServiceTechChartData = dataTechnicalChart;
        this.serviceTechTotal = technical.TotalRate;

        // Dịch vụ vệ sinh
        const cleaning = res["cleaning"];
        let dataCleaningChart = [];
        cleaning.StatusDetail.forEach(item => {
          dataCleaningChart.push(item.CountStatus);
        });
        this.pieChartRequestCleaningData = dataCleaningChart
        this.serviceCleaningTotal = cleaning.TotalRate;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  //SLA thời gian trung bình xử lý yêu cầu
  GetSlaAverageProcessData(data) {
    this.http.post(domainApi + '/dashboard/GetMediumProcessSla', data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.slaAverageProcessData = res["data"];
          this.slaAverageProcessData.forEach(element => {
            if (element.SpentTime == 0 || element.TotalWarningTime == 0)
            {
              element.colorElm = '#4fbaf0';
              element.percent = 0;
            }
            else if ((element.SpentTime * 100) / element.TotalWarningTime <= 100) {
              element.colorElm = '#4fbaf0';
              element.percent = Math.round((element.SpentTime * 100) / element.TotalWarningTime);
            }
            else {
              element.colorElm = '#f05050';
              element.percent = Math.round((element.SpentTime * 100) / element.TotalWarningTime);
            }
          });
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  //SLA tổng thời gian xử lý yêu cầu
  GetSlaAverageResponseData(data) {
    this.http.post(domainApi + '/dashboard/GetMediumResponseSla', data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.slaAverageResponseData = res["data"];
          this.slaAverageResponseData.forEach(element => {
            if (element.SpentTime == 0 || element.TotalWarningTime == 0) {
              element.colorElm = '#4fbaf0';
              element.percent = 0;
            }
            else if ((element.SpentTime * 100) / element.TotalWarningTime <= 100) {
              element.colorElm = '#4fbaf0';
              element.percent = Math.round((element.SpentTime * 100) / element.TotalWarningTime);
            }
            else {
              element.colorElm = '#f05050';
              element.percent = Math.round((element.SpentTime * 100) / element.TotalWarningTime);
            }
          });
        }
      },
      (err) => {
        console.log("Error: connect to API");
      });
  }

  chooseTime(type) {
    switch (type) {
      case 1:
        if (this.type != type) {
          this.type = type;
          let curr = new Date;
          let first = curr.getDate() - curr.getDay();
          let last = first + 6;
          this.startDate = new Date(curr.setDate(first)).toUTCString();
          this.endDate = new Date(curr.setDate(last)).toUTCString();
        }
        else {
          this.startDate = undefined;
          this.endDate = undefined;
          this.type = undefined;
        }
        break;
      case 2:
        if (this.type != type) {
          this.type = type;
          var date = new Date();
          this.startDate = new Date(date.getFullYear(), date.getMonth(), 1).toUTCString();
          this.endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toUTCString();
        }
        else {
          this.startDate = undefined;
          this.endDate = undefined;
          this.type = undefined;
        }
        break;
      case 3:
        if (this.type != type) {
          this.type = type;
          var date = new Date();
          this.startDate = new Date(date.getFullYear(), ((this.quarter_of_the_year(date) - 1) * 3) + 0, 1).toUTCString();
          this.endDate = new Date(date.getFullYear(), this.quarter_of_the_year(date) * 3 + 0, 0).toUTCString();
        }
        else {
          this.startDate = undefined;
          this.endDate = undefined;
          this.type = undefined;
        }
        break;
      default:
        break;
    }
  }

  quarter_of_the_year(date) {
    let month = date.getMonth() + 1;
    return (Math.ceil(month / 3));
  }

  getProjects() {
    return this.commonService.getLstProject().subscribe(
      (res) => {
        this.listProjects = res;
        if(this.listProjects.length == 1) {
          this.projectId = this.listProjects[0].ProjectId;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  getGeneralInfoQuery(data) {
    this.http.post(domainApi + '/dashboard/GetGeneralInfoQuery', data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.data.TotalProjects = res["data"].TotalProjects;
          this.data.TotalDomesticResidents = res["data"].TotalDomesticResidents;
          this.data.TotalForeignResidents = res["data"].TotalForeignResidents;
          this.data.TotalResidents = res["data"].TotalResidents;

          this.nationalityChartData = [this.data.TotalDomesticResidents, this.data.TotalForeignResidents];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  GetStatRequireQuery(data) {
    this.http.post(domainApi + '/dashboard/GetStatRequireQuery', data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.barChartData = res["data"]["barChartData"];
          this.barChartLabels.length = 0;
          for (let i = 0; i < res["data"]["barChartLabel"].length; i++) {
            this.barChartLabels.push(res["data"]["barChartLabel"][i]);
          }

          this.data.TotalUtlities = this.barChartData[0].data.reduce((total, currentValue) => {
            return total + currentValue;
          });

          // this.data.TotalServices = this.barChartData[1].data.reduce((total, currentValue) => {
          //   return total + currentValue;
          // });

          this.data.TotalServices = this.serviceCleaningTotal + this.serviceTechTotal;

          this.data.TotalRequireSps = this.barChartData[2].data.reduce((total, currentValue) => {
            return total + currentValue;
          });

          this.data.TotalRateRequireSp = res["data"]["TotalRateRequireSp"];

          this.data.TotalRate = this.barChartData[3].data.reduce((total, currentValue) => {
            return total + currentValue;
          });

          this.data.TotalRequestReceives = this.data.TotalUtlities + this.data.TotalServices + this.data.TotalRequireSps;
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  getTotalServiceOrUtilitiesQuery(data) {
    this.http.post(domainApi + '/dashboard/GetTotalServiceOrUtilitiesQuery/' + this.typeService.ToTalService, data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.barChartTotalServiceData = res["data"]["barChartData"];
          this.barChartTotalServiceLabels.length = 0;
          for (let i = 0; i < res["data"]["barChartLabel"].length; i++) {
            this.barChartTotalServiceLabels.push(res["data"]["barChartLabel"][i]);
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  changeTypeServiceId(type) {
    let data = { ProjectId: this.projectId, DateTimeStart: (this.startDate ? this.startDate.toString() : undefined), DateTimeEnd: (this.endDate ? this.endDate.toString() : undefined) };
    if(type == 1)
      this.getTotalServiceOrUtilitiesQuery(data);
    else if(type == 2) {
      data["Type"] = this.typeService.SlaAverageProcess;
      this.getMediumProcessSlaGroupByProject(data);
    }
    else if(type == 3) {
      data["Type"] = this.typeService.SlaAverageResponse;
      this.getMediumResponseSlaGroupByProject(data);
    }
  }

  getServiceRate2(data) {
    this.http.post(domainApi + '/dashboard/GetServiceRate', data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.dataRate.TotalRateServiceTech = res["data"][0].TotalRate;
          this.dataRate.TotalRateServiceCleaning = res["data"][1].TotalRate;
          this.dataRate.TotalRateServiceRentalApartment = res["data"][2].TotalRate;

          let barChartRateServiceData = [];
          this.barChartRateServiceLabels.length = 0;

          res["data"].forEach((dataItem, index) => {
            if(index < 3)
            {
              this.barChartRateServiceLabels.push(dataItem.ServiceName);

              dataItem.RateDetail.forEach((rateDetail, rateDetailIndex) => {
                if (index == 0) {
                  let rateDetailObj = { data: [rateDetail.CountRate], label: rateDetail.Name };
                  barChartRateServiceData.push(rateDetailObj);
                }
                else {
                  barChartRateServiceData[rateDetailIndex].data.push(rateDetail.CountRate);
                }
              });
            }
          });

          this.barChartRateServiceData = barChartRateServiceData;
          console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj');
          console.log(this.barChartRateServiceLabels);
          console.log(this.barChartRateServiceData);
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
  
  getServiceRate(data){
    enum Rate {
      GOOD = 'Chấp nhận',
      VERYGOOD = 'Rất tốt',
      UNSATISFIED = 'Không hài lòng',
    };
    let startDate: string;
    let endDate: string;
    if(typeof data.DateTimeStart == 'undefined' || typeof data.DateTimeEnd == 'undefined'){
      var date = new Date();
      startDate = moment(new Date(date.getFullYear(), ((this.quarter_of_the_year(date) - 1) * 3) + 0, 1).toUTCString()).format("YYYY-MM-DD");
      endDate = moment(new Date(date.getFullYear(), this.quarter_of_the_year(date) * 3 + 0, 0).toUTCString()).format("YYYY-MM-DD");
    }else{
      startDate = moment(data.DateTimeStart).format("YYYY-MM-DD");
      endDate = moment(data.DateTimeEnd).format("YYYY-MM-DD");
    }
    this.http.get(domain + 'add-on/api/admin/dashboard/feedback?startDate='+ startDate +'&endDate='+ endDate).subscribe(
      (res) => {
        this.dataRate.TotalRateServiceTech = res["technical"].TotalRate;
        this.dataRate.TotalRateServiceCleaning = res["cleaning"].TotalRate;
        this.dataRate.TotalRateServiceRentalApartment = 0;

        let barChartRateServiceData = [];
        this.barChartRateServiceLabels.length = 0;

        this.barChartRateServiceLabels.push(res["technical"].ServiceName);
        this.barChartRateServiceLabels.push(res["cleaning"].ServiceName);
        this.barChartRateServiceLabels.push("Dịch vụ cho thuê nhà");

        let tech = [{
          RateAttributeId: '',
          ContentFeedback: Rate.GOOD,
          CountRate: '0',
        },{
          RateAttributeId: '',
          ContentFeedback: Rate.VERYGOOD,
          CountRate: '0',
        },{
          RateAttributeId: '',
          ContentFeedback: Rate.UNSATISFIED,
          CountRate: '0',
        }];
        let clean = [{
          RateAttributeId: '',
          ContentFeedback: Rate.GOOD,
          CountRate: '0',
        },{
          RateAttributeId: '',
          ContentFeedback: Rate.VERYGOOD,
          CountRate: '0',
        },{
          RateAttributeId: '',
          ContentFeedback: Rate.UNSATISFIED,
          CountRate: '0',
        }];
        let house = [{
          RateAttributeId: '',
          ContentFeedback: Rate.GOOD,
          CountRate: '0',
        },{
          RateAttributeId: '',
          ContentFeedback: Rate.VERYGOOD,
          CountRate: '0',
        },{
          RateAttributeId: '',
          ContentFeedback: Rate.UNSATISFIED,
          CountRate: '0',
        }];

        for (let i = 0; i < tech.length; i++) {
          for (let j = 0; j < res["technical"].RateDetail.length; j++) {
            if(tech[i].ContentFeedback == res["technical"].RateDetail[j].ContentFeedback){
              tech[i].CountRate = res["technical"].RateDetail[j].CountRate;
            }
          }
        }
        for (let i = 0; i < clean.length; i++) {
          for (let j = 0; j < res["cleaning"].RateDetail.length; j++) {
            if(clean[i].ContentFeedback == res["cleaning"].RateDetail[j].ContentFeedback){
              clean[i].CountRate = res["cleaning"].RateDetail[j].CountRate;
            }
          }
        }

        let good = [];
        let veryGood = [];
        let unsatisfied = [];

        tech.forEach(techItem => {
          if(techItem.ContentFeedback == Rate.GOOD){
            good.push(techItem.CountRate);
          }
          if(techItem.ContentFeedback == Rate.VERYGOOD){
            veryGood.push(techItem.CountRate);
          }
          if(techItem.ContentFeedback == Rate.UNSATISFIED){
            unsatisfied.push(techItem.CountRate);
          }
        });
        clean.forEach(cleanItem => {
          if(cleanItem.ContentFeedback == Rate.GOOD){
            good.push(cleanItem.CountRate);
          }
          if(cleanItem.ContentFeedback == Rate.VERYGOOD){
            veryGood.push(cleanItem.CountRate);
          }
          if(cleanItem.ContentFeedback == Rate.UNSATISFIED){
            unsatisfied.push(cleanItem.CountRate);
          }
        });
        house.forEach(houseItem => {
          if(houseItem.ContentFeedback == Rate.GOOD){
            good.push(houseItem.CountRate);
          }
          if(houseItem.ContentFeedback == Rate.VERYGOOD){
            veryGood.push(houseItem.CountRate);
          }
          if(houseItem.ContentFeedback == Rate.UNSATISFIED){
            unsatisfied.push(houseItem.CountRate);
          }
        });

        let rateDetailGood = { data: good, label: Rate.GOOD };
        let rateDetailVeryGood = { data: veryGood, label: Rate.VERYGOOD };
        let rateDetailUnsatisfied = { data: unsatisfied, label: Rate.UNSATISFIED };
        barChartRateServiceData.push(rateDetailGood);
        barChartRateServiceData.push(rateDetailVeryGood);
        barChartRateServiceData.push(rateDetailUnsatisfied);

        this.barChartRateServiceData = barChartRateServiceData;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  GetRegConstructionQuery(data) {
    this.http.post(domainApi + '/dashboard/GetRegConstructionQuery' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.pieChartRegConstructionData = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  // GetRequestCleaningQuery(data) {
  //   this.http.post(domainApi + '/dashboard/GetRequestCleaningQuery' , data, this.httpOptions).subscribe(
  //     (res) => {
  //       if (res["meta"]["error_code"] == 200) {
  //         this.pieChartRequestCleaningData = res["data"];
  //       }
  //     },
  //     (err) => {
  //       console.log("Error: connect to API");
  //     }
  //   );
  // }

  GetRequestCardQuery(data) {
    this.http.post(domainApi + '/dashboard/GetRequestCardQuery' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.pieRegCardChartData = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  GetRequestTransportQuery(data) {
    this.http.post(domainApi + '/dashboard/GetRequestTransportQuery' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.pieTranspostChartData = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  GetPercentAcceptQuery(data) {
    this.http.post(domainApi + '/dashboard/GetPercentAcceptQuery' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.dataAccept = res["data"];
          this.dataAccept.forEach(dataAcceptItem => {
            if(dataAcceptItem.data) {
              dataAcceptItem.data.forEach(item => {
                item.Percentage = (item.TotalAccept * 100 / item.Total).toFixed(0)
              })
            }
          });
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  exportAll(name: string) {
    let height = document.getElementsByName(name)[0].offsetHeight;
    let width = document.getElementsByName(name)[0].offsetWidth;

    htmlToImage.toJpeg(document.getElementsByName(name)[0], { quality: 0.95 })
    .then(function (dataUrl) {
      // var link = document.createElement('a');
      // link.download = name + '.jpeg';
      // link.href = dataUrl;
      // link.click();
      // const pdf = new jsPDF('p', 'pt', 'a4');
      // pdf.addImage(dataUrl, 'png', 40, 40, 515, 515 * height / width);
      // pdf.save(name + '.pdf');
      let imgWidth = 210;
      let pageHeight = 297;
      let imgHeight =
        ((height * imgWidth) / width);
      var heightLeft = imgHeight;

      const imgData = dataUrl;

      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [210, 297],
      });
      let position = 10;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
          position = heightLeft - imgHeight + 10;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save(name + ".pdf");
    });
  }

  exportBehaviorUser(){
    let startDate: string;
    let endDate: string;
    let data = { DateTimeStart: (this.startDate ? this.startDate.toString() : undefined), DateTimeEnd: (this.endDate ? this.endDate.toString() : undefined) };

    if(typeof data.DateTimeStart == 'undefined' || typeof data.DateTimeEnd == 'undefined'){
      var date = new Date();
      startDate = moment(new Date(date.getFullYear(), ((this.quarter_of_the_year(date) - 1) * 3) + 0, 1).toUTCString()).format("YYYY-MM-DD");
      endDate = moment(new Date(date.getFullYear(), this.quarter_of_the_year(date) * 3 + 0, 0).toUTCString()).format("YYYY-MM-DD");
    }else{
      startDate = moment(data.DateTimeStart).format("YYYY-MM-DD");
      endDate = moment(data.DateTimeEnd).format("YYYY-MM-DD");
    }

    this.http.get(domain +'/tracking/api/admin/dashboard/export?startDate='+ startDate +'&endDate='+ endDate, {responseType: 'arraybuffer'}).subscribe(
      (res) => {
        var b = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(b,"bao-cao-do-luong-hanh-vi-nguoi-dung.xlsx");
      },
      (err) => {
        console.log("Error: connect to API export");
      }
    );
  }

  GetRentalApartmentQuery(data) {
    this.http.post(domainApi + '/dashboard/GetRentalApartmentQuery' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.pieChartRentalData = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  CountApartmentbyTypeResident(data) {
    this.http.post(domainApi + '/resident/countApartmentbyTypeResident' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.dataApartmentbyTypeResident = res["data"];
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  filter() {
    let data = { ProjectId: this.projectId, DateTimeStart: (this.startDate ? this.startDate.toString() : undefined), DateTimeEnd: (this.endDate ? this.endDate.toString() : undefined) };

    this.getGeneralInfoQuery(data);
    this.GetStatRequireQuery(data);
    this.getTotalServiceOrUtilitiesQuery(data);
    this.getServiceRate(data);
    this.GetSlaAverageProcessData(data);
    this.GetSlaAverageResponseData(data);
    //this.GetServiceTechStatus(data);
    this.GetRegConstructionQuery(data);
    this.GetRentalApartmentQuery(data);
    this.GetPercentAcceptQuery(data);
    //this.GetRequestCleaningQuery(data);
    this.GetRequestCardQuery(data);
    this.GetRequestTransportQuery(data);
    this.CountApartmentbyTypeResident(data);
    this.getMediumProcessSlaGroupByProject(data);
    this.getMediumResponseSlaGroupByProject(data);
    this.getBehaviorUserChartData(data);
    this.GetServiceCleaningAndTechnicalServices(data);
  }

  sumTotal(list) {
    return list.reduce((sum, a) => sum + a, 0);
  }

  getBehaviorUserChartData(data){
    let startDate: string;
    let endDate: string;
    if(typeof data.DateTimeStart == 'undefined' || typeof data.DateTimeEnd == 'undefined'){
      var date = new Date();
      startDate = moment(new Date(date.getFullYear(), ((this.quarter_of_the_year(date) - 1) * 3) + 0, 1).toUTCString()).format("YYYY-MM-DD");
      endDate = moment(new Date(date.getFullYear(), this.quarter_of_the_year(date) * 3 + 0, 0).toUTCString()).format("YYYY-MM-DD");
    }else{
      startDate = moment(data.DateTimeStart).format("YYYY-MM-DD");
      endDate = moment(data.DateTimeEnd).format("YYYY-MM-DD");
    }
    this.http.get(domain + 'tracking/api/admin/dashboard?startDate='+ startDate +'&endDate='+ endDate).subscribe(
      (res) => {
        let behaviorLable = [];
        let home = [];
        let technicalService = [];
        let cleaningService = [];
        let registerShipping = [];
        let reflect = [];
        let residentCardRegistration = [];
        let constructionRegistration = [];
        let banner = [];
    
        if(res != null){
          var result = Object.keys(res).map((key) => [key, res[key]]);
          for (let index = 0; index < result.length; index++) {
            const item = res[index];
            behaviorLable.push(item.date);
            home.push(item.home);
            technicalService.push(item.technicalService);
            cleaningService.push(item.cleaningService);
            registerShipping.push(item.registerShipping);
            reflect.push(item.reflect);
            residentCardRegistration.push(item.residentCardRegistration);
            constructionRegistration.push(item.constructionRegistration);
            banner.push(item.banner);
          }
        }

        this.behaviorUserChartLabels = behaviorLable;
        this.behaviorUserChartData[0].data = home;
        this.behaviorUserChartData[1].data = technicalService;
        this.behaviorUserChartData[2].data = cleaningService;
        this.behaviorUserChartData[3].data = registerShipping;
        this.behaviorUserChartData[4].data = reflect;
        this.behaviorUserChartData[5].data = residentCardRegistration;
        this.behaviorUserChartData[6].data = constructionRegistration;
        this.behaviorUserChartData[7].data = banner;
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  getMediumProcessSlaGroupByProject(data) {
    data.Type = this.typeService.SlaAverageProcess;
    this.http.post(domainApi + '/dashboard/getMediumProcessSlaGroupByProject' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.barChartSlaProcessLabels.length = 0;
          this.barChartSlaProcessLabels.push('.');
          for (let i = 0; i < res["data"]["ListProjects"].length; i++) {
            this.barChartSlaProcessLabels.push(res["data"]["ListProjects"][i]);
          }
          this.barChartSlaProcessLabels.push('.');

          this.barChartSlaProcessData = [];
          for (let i = 0; i < res["data"]["Data"].length; i++) {
            let data = {};
            if(i < 3) {
              data["data"] = [];
              data["data"].push(undefined);
              data["data"] = data["data"].concat(res["data"]["Data"][i]["Data"]);
              data["data"].push(undefined);
              data["label"] = res["data"]["Data"][i]["Name"];
            }
            else {
              data["data"] = [];
              data["data"].push(res["data"]["Data"][i]["Data"][0]);
              data["data"] = data["data"].concat(res["data"]["Data"][i]["Data"]);
              data["data"].push(res["data"]["Data"][i]["Data"][0]);
              data["label"] = res["data"]["Data"][i]["Name"];

              data["line"] = 'line';
              data["backgroundColor"] = '#f2f5f700';
              data["borderColor"] = i == 3 ? '#fd8181' : ( i == 4 ? '#8ad1fa' : '#5d81a0');
            }

            this.barChartSlaProcessData.push(data);
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }

  getMediumResponseSlaGroupByProject(data) {
    data.Type = this.typeService.SlaAverageResponse;
    this.http.post(domainApi + '/dashboard/getMediumResponseSlaGroupByProject' , data, this.httpOptions).subscribe(
      (res) => {
        if (res["meta"]["error_code"] == 200) {
          this.barChartSlaResponseLabels.length = 0;
          this.barChartSlaResponseLabels.push('.');
          for (let i = 0; i < res["data"]["ListProjects"].length; i++) {
            this.barChartSlaResponseLabels.push(res["data"]["ListProjects"][i]);
          }
          this.barChartSlaResponseLabels.push('.');

          this.barChartSlaResponseData = [];
          for (let i = 0; i < res["data"]["Data"].length; i++) {
            let data = {};
            if(i < 3) {
              data["data"] = [];
              data["data"].push(undefined);
              data["data"] = data["data"].concat(res["data"]["Data"][i]["Data"]);
              data["data"].push(undefined);
              data["label"] = res["data"]["Data"][i]["Name"];
            }
            else {
              data["data"] = [];
              data["data"].push(res["data"]["Data"][i]["Data"][0]);
              data["data"] = data["data"].concat(res["data"]["Data"][i]["Data"]);
              data["data"].push(res["data"]["Data"][i]["Data"][0]);
              data["label"] = res["data"]["Data"][i]["Name"];

              data["line"] = 'line';
              data["backgroundColor"] = '#f2f5f700';
              data["borderColor"] = i == 3 ? '#fd8181' : ( i == 4 ? '#8ad1fa' : '#5d81a0');
            }

            this.barChartSlaResponseData.push(data);
          }
        }
      },
      (err) => {
        console.log("Error: connect to API");
      }
    );
  }
}
