export class UserInfo {
  userId: number;
  access_token: string;
}

export class UserChangePass {
  UserId: number;
  PasswordOld: string;
  PasswordOldE: string;
  PasswordNew: string;
  PasswordNewE: string;
  UserName: string;
  Avatar: string;
  FullName: string;
  ConfirmPassword: string;
}

export class Paging {
  page: number;
  page_size: number;
  query: string;
  order_by: string;
  item_count: number;
  select: string;
  search: string;
}

export class QueryFilter {
  txtSearch: string;
  Code: string;
  Type: number;
  Title: number;
  ProjectId: number;
  TowerId: number;
  FloorId: number;
  ApartmentId: number;
  DepartmentId: number;
  PositionId: number;
  CountryId: number;
  ProvinceId: number;
  DistrictId: number;
  WardId: number;
  ResidentId: number;
  DateTimeRange: any;
  TypeRequireId: number;
  StatusProcessId: number;
  TypeDepartment: number;
  DateTimeStart: any;
  DateTimeEnd: any;
  TargetId: number;
  CanCelStatus: number;
  DepositStatus: number;
  TypePayment: number;
  PaymentMethod: number;
}

