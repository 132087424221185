import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../service/shared.service';
import { Paging, QueryFilter } from '../../../data/dt';
import { Resident, ApartmentResident, UserChangePass, ServiceOrder, ServiceOrderEmp, ServiceOrderPlan, ServiceOrderItem } from '../../../data/Model';
import { NgForm } from '@angular/forms';
import { ActionTable, domainImage, TypeResident, patternPhone, domainApi, TypeAttribute_Relationship, listStatusServiceTech, listPaymentMethod } from '../../../data/const';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Md5 } from 'ts-md5';
import { Title } from "@angular/platform-browser";
import { CommonService } from '../../../service/common.service';
import { Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { InterceptorService } from 'ng2-interceptors';
import { saveFile } from '../../../service/file-download-helper';

// //Định dạng ngày h trên Modal
export const MY_CUSTOM_FORMATS = {
	parseInput: 'DD/MM/YYYY HH:mm',
	fullPickerInput: 'DD/MM/YYYY HH:mm',
	datePickerInput: 'DD/MM/YYYY',
	timePickerInput: ' HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};


@Component({
	selector: 'app-service-tech',
	templateUrl: './service-tech.component.html',
	styleUrls: ['./service-tech.component.scss'],
	providers: [
		{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
	]
})
export class ServiceTechComponent implements OnInit {
	@ViewChild('modalCommon') public modalCommon: ModalDirective;
	@ViewChild('f') public form: NgForm;
	@ViewChild('file') file: ElementRef;
	@ViewChild('EmpModal') public EmpModal: ModalDirective;
	@ViewChild('PlanModal') public PlanModal: ModalDirective;
	@ViewChild('ProductModal') public ProductModal: ModalDirective;
	@ViewChild('PriceModal') public PriceModal: ModalDirective;
	@ViewChild('ServiceModal') public ServiceModal: ModalDirective;
	@ViewChild('fChild') public formChild: NgForm;
	@ViewChild('fChangePass') public formChangePass: NgForm;

	public dateNow = new Date();
	public maxDate = {
		year: this.dateNow.getFullYear(),
		month: this.dateNow.getMonth() + 1,
		day: this.dateNow.getDate()
	};

	isShowFilter = false;
	public paging: Paging;
	public q: QueryFilter;

	public listCommon = [];
	public projects = [];
	public towers = [];
	public floors = [];
	public apartments = [];

	public filterTowers = [];
	public filterFloors = [];
	public filterApartments = [];

	public listEmployees = [];
	public listEmp = [];
	public listPositions = [];
	public listPlan = [];
	public listProduct = [];
	public listPrice = [];

	public lstIdDeletes: number[] = [];

	public relationships = [];
	public listStatusProcess = listStatusServiceTech;
	public listPaymentMethod = listPaymentMethod;
	public paymentStatus = [];

	public Item: ServiceOrder;

	public httpOptions: any;

	public submitted = false;
	public submittedChangePas = false;

	public ActionTable = ActionTable;
	public ActionId: number;
	public CheckAll: boolean;
	public isCheckedDelete = false;

	public domainImage = domainImage;
	public typeResident = TypeResident;
	public patternPhone = patternPhone;
	public messageError = '';
	public typeUser: any;
	public isCheckboxCancel = false;
	public statusItem: number;

	constructor(public http: HttpClient, public modalDialogService: ModalDialogService,
		public viewRef: ViewContainerRef, public toastr: ToastrService,
		public common: CommonService, public titleService: Title,
		private httpDownload: InterceptorService,) {
		this.Item = new ServiceOrder();

		this.paging = new Paging();
		this.paging.page = 1;
		this.paging.page_size = 10;
		this.paging.query = "1=1";
		this.paging.order_by = "UpdatedAt Desc";
		this.paging.item_count = 0;

		this.q = new QueryFilter();
		this.q.txtSearch = "";
		this.q.ProjectId = parseInt(localStorage.getItem("projectId"));

		this.httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			})
		}
	}

	ngOnInit() {
		this.typeUser = localStorage.getItem("type");
		this.titleService.setTitle("S Plus - Danh sách đăng ký dịch vụ kỹ thuật");
		this.GetListCommon();
		this.GetListProject();
		this.statusItem = 0;
		this.listStatusProcess.map((item, index, arr) => {
			this.statusItem > item.Id ? item["disabled"] = true : item["disabled"] = false;
			return item;
		});
	}

	//Chuyển trang
	PageChanged(event) {
		this.paging.page = event.page;
		this.GetListCommon();
	}
	//Toast cảnh báo
	toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
	}
	//
	QueryChanged() {
		let query = '1=1';
		if (this.q.ProjectId) {
			query += ' and ProjectId=' + this.q.ProjectId;
		}
		if (this.q.TowerId) {
			query += ' and TowerId=' + this.q.TowerId;
		}
		if (this.q.FloorId) {
			query += ' and FloorId=' + this.q.FloorId;
		}
		if (this.q.ApartmentId) {
			query += ' and ApartmentId=' + this.q.ApartmentId;
		}
		if (this.q.StatusProcessId) {
			query += ' and StatusProcessId=' + this.q.StatusProcessId;
		}
		if (this.q.PaymentMethod != undefined) {
			query += ' and PaymentMethod=' + this.q.PaymentMethod;
		}
		if (this.q.Code != undefined && this.q.Code != '') {
			query += ' and Code.Contains("' + this.q.Code + '")';
		}
		if (this.q.txtSearch != undefined && this.q.txtSearch != '') {
			let querynew = this.common.ConvertUrl(this.q.txtSearch);
			query += ' and TextSearch.Contains("' + querynew.toLowerCase() + '")';
		}
		if (this.q.DateTimeStart != undefined) {
			let StartDate = new Date(this.q.DateTimeStart);
			let EndDate = new Date();
			if (this.q.DateTimeEnd != undefined)
				EndDate = new Date(this.q.DateTimeEnd);

			query += ' and CreatedAt >= DateTime(' + StartDate.getFullYear() + ',' + (StartDate.getMonth() + 1) + ',' + StartDate.getDate() + ',0,0,0)';
			query += ' and CreatedAt <= DateTime(' + EndDate.getFullYear() + ',' + (EndDate.getMonth() + 1) + ',' + EndDate.getDate() + ',23,59,0)';
		}

		if (this.isCheckboxCancel == true) {
			query += ' and Status=10';
		}
		if (query == '')
			this.paging.query = '1=1';
		else
			this.paging.query = query;

		this.GetListCommon();
	}

	SortTable(str) {
		let First = "";
		let Last = "";
		if (this.paging.order_by != "") {
			First = this.paging.order_by.split(" ")[0];
			Last = this.paging.order_by.split(" ")[1];
		}

		if (First != str) {
			this.paging.order_by = str + " Desc";
		}
		else {
			Last = Last == "Asc" ? "Desc" : "Asc";
			this.paging.order_by = str + " " + Last;
		}

		this.GetListCommon();
	}

	GetClassSortTable(str) {
		if (this.paging.order_by != (str + " Desc") && this.paging.order_by != (str + " Asc")) {
			return "sorting";
		}
		else {
			if (this.paging.order_by == (str + " Desc")) return "sorting_desc";
			else return "sorting_asc";
		}
	}

	CheckActionTable(Id) {
		const lstAcceptDelete = this.listCommon.filter(s => s.StatusProcessId < 2);
		//debugger
		if (Id == undefined) {
			if (this.lstIdDeletes.length > 0) {
				this.isCheckedDelete = false;

				lstAcceptDelete.forEach(item => {
					this.lstIdDeletes = [];
				})

				this.listCommon.map(s => s.isChecked = (s.StatusProcessId < 2 ? false : undefined));

			} else {
				this.lstIdDeletes = [];
				this.isCheckedDelete = true;

				this.listCommon.map(s => s.isChecked = (s.StatusProcessId < 2 ? true : false));

				lstAcceptDelete.forEach(item => {
					this.lstIdDeletes.push(item.Id);
				})
			}
		}
		else {
			let isExistIndexId = this.lstIdDeletes.findIndex(s => s === Id);
			let indexChecked = this.listCommon.findIndex(s => s.Id === Id);

			if (isExistIndexId < 0) {
				this.lstIdDeletes.push(Id);
			} else {
				this.lstIdDeletes = this.lstIdDeletes.filter(item => item !== Id);
			}

			if (indexChecked >= 0) {
				this.listCommon[indexChecked]['isChecked'] = true;
			} else {
				this.listCommon[indexChecked]['isChecked'] = false;
			}

			this.listCommon = [...this.listCommon];
			this.isCheckedDelete = this.lstIdDeletes.length > 0 ? true : false;
		}
		console.log(this.listCommon);
	}

	ActionTableFunc() {
		switch (this.ActionId) {
			case 1:
				let data = [];
				this.listCommon.forEach(item => {
					if (item.Action == true) {
						data.push(item.ResidentId);
					}
				});
				if (data.length == 0) {
					this.toastWarning("Chưa chọn mục cần xóa!");
				}
				else {
					this.modalDialogService.openDialog(this.viewRef, {
						title: 'Xác nhận',
						settings: {
							footerClass: 'footer-dialog modal-footer'
						},
						childComponent: SimpleModalComponent,
						data: {
							text: "Bạn có chắc chắn muốn xóa các mục đã chọn?"
						},
						actionButtons: [
							{
								text: 'Đồng ý',
								buttonClass: 'btn btn-success',
								onAction: () => {
									this.http.put(domainApi + '/ServiceOrder/deletes', data, this.httpOptions).subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												this.toastSuccess("Xóa thành công!");
												this.GetListCommon();
												this.ActionId = undefined;
											}
											else {
												this.toastError(res["meta"]["error_message"]);
											}
										},
										(err) => {
											this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
										}
									);
									this.viewRef.clear();
								}
							},
							{
								text: 'Đóng',
								buttonClass: 'btn btn-default',
							}
						],
					});
				}
				break;
			default:
				break;
		}
	}

	//Get danh sách
	GetListCommon() {
		this.messageError = '';
		let params = "";
		if (this.q.ProjectId) {
			params = "&ProjectId=" + this.q.ProjectId;

			if (this.q.TowerId) {
				params = params + "&TowerId=" + this.q.TowerId;

				if (this.q.FloorId) {
					params = params + "&FloorId=" + this.q.FloorId;

					if (this.q.ApartmentId) {
						params = params + "&ApartmentId=" + this.q.ApartmentId;
					}
				}
			}
		}

		if (this.q.Type != undefined)
			params = params + "&type=" + this.q.Type;

		this.paging.search = "";
		if (this.q.txtSearch != undefined)
			this.paging.search = this.q.txtSearch;

		this.http.get(domainApi + '/serviceOrder/GetByPage?page=' + this.paging.page + '&page_size=' + this.paging.page_size + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + params + '&search=' + this.paging.search, this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					//debugger
					if (res["data"].length > 0) {
						this.listCommon = res["data"];
						this.listCommon.map(s => s['isChecked'] = false);
						this.paging.item_count = res["metadata"];
					} else {
						this.listCommon = [];
						this.paging.item_count = 0;
						this.messageError = 'Không có dữ liệu';
					}
				}
				else {
					this.listCommon = [];
					this.paging.item_count = 0;
					this.messageError = 'Không có dữ liệu';
				}

			},
			(err) => {
				console.log("Error: connect to API");
				this.messageError = 'Không có dữ liệu';
			});
	}

	ExportExcel() {
		this.messageError = '';
		let params = "";
		if (this.q.ProjectId) {
			params = "&ProjectId=" + this.q.ProjectId;

			if (this.q.TowerId) {
				params = params + "&TowerId=" + this.q.TowerId;

				if (this.q.FloorId) {
					params = params + "&FloorId=" + this.q.FloorId;

					if (this.q.ApartmentId) {
						params = params + "&ApartmentId=" + this.q.ApartmentId;
					}
				}
			}
		}

		if (this.q.Type != undefined)
			params = params + "&type=" + this.q.Type;

		this.paging.search = "";
		if (this.q.txtSearch != undefined)
			this.paging.search = this.q.txtSearch;

		let url = domainApi + '/serviceOrder/ExportExcel?page=' + this.paging.page + '&query=' + this.paging.query + '&order_by=' + this.paging.order_by + params + '&search=' + this.paging.search;
		const options = new RequestOptions({
			headers: new Headers({
				'Authorization': 'bearer ' + localStorage.getItem("access_token")
			}),
			responseType: ResponseContentType.Blob,
		});

		this.httpDownload.get(url, options).subscribe(res => {
			const fileName = "Danh-sach-dvkt.xlsx";
			saveFile(res.blob(), fileName);
		});
	}

	//Danh sách dự án
	GetListProject() {
		this.http.get(domainApi + '/project/GetByPage?page=1&query=1=1&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
			(res) => {
				if (res["meta"]["error_code"] == 200) {
					this.projects = res["data"];
					if (this.projects.length == 1) {
						this.QueryChangeProject();
					}
				}
			},
			(err) => {
				console.log("Error: connect to API");
			});
	}

	FindStatus(id) {
		for (let i = 0; i < this.listStatusProcess.length; i++) {
			if (id == this.listStatusProcess[i].Id) {
				return this.listStatusProcess[i].Name;
			}
		}

	}

	OpenCommonModal(item) {
		this.file.nativeElement.value = "";
		this.submitted = false;
		this.Item = new ServiceOrder();
		if (item == undefined) {
		}
		else {
			this.Item = JSON.parse(JSON.stringify(item));
		}

		this.modalCommon.show();
	}

	//Popup xác nhận xóa
	ShowConfirmDelete(Id) {
		this.modalDialogService.openDialog(this.viewRef, {
			title: 'Xác nhận',
			settings: {
				footerClass: 'footer-dialog modal-footer'
			},
			childComponent: SimpleModalComponent,
			data: {
				text: "Bạn chắc muốn xóa yêu cầu đăng ký này không?"
			},
			actionButtons: [
				{
					text: 'Hủy',
					buttonClass: 'btn btn-default',

				},
				{
					text: 'Xác nhận',
					buttonClass: 'btn btn-success',
					onAction: () => {
						this.http.delete(domainApi + '/serviceOrder/' + Id, this.httpOptions).subscribe(
							(res) => {
								if (res["meta"]["error_code"] == 200) {
									this.GetListCommon();
									this.viewRef.clear();
									this.toastSuccess("Xóa thành công!");
								}
								else {
									this.toastError(res["meta"]["error_message"]);
								}
							},
							(err) => {
								this.toastError("Đã xảy ra lỗi. Xin vui lòng thử lại sau!");
							}
						);
					}
				}

			],
		});
	}

	QueryChangeProject() {
		this.q["TowerId"] = undefined;
		this.filterTowers = [];
		if (this.q["ProjectId"]) {
			let query = "ProjectId=" + this.q["ProjectId"];
			this.http.get(domainApi + '/tower/GetByPage?page=1&query=' + query + '&order_by=Id+Desc&select=Id,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterTowers = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeTower();
	}

	QueryChangeTower() {
		this.q["FloorId"] = undefined;
		this.filterFloors = [];
		if (this.q["TowerId"]) {
			let query = "TowerId=" + this.q["TowerId"];
			this.http.get(domainApi + '/floor/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterFloors = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}

		this.QueryChangeFloor();
	}

	QueryChangeFloor() {
		this.q["ApartmentId"] = undefined;
		this.filterApartments = [];
		if (this.q["FloorId"]) {
			let query = "FloorId=" + this.q["FloorId"];
			this.http.get(domainApi + '/Apartment/GetByPage?page=1&query=' + query + '&order_by=Code+Asc&select=Id,Code,Name', this.httpOptions).subscribe(
				(res) => {
					if (res["meta"]["error_code"] == 200) {
						this.filterApartments = res["data"];
					}
				},
				(err) => {
					console.log("Error: connect to API");
				});
		}
	}

	ShowTypeApartmentMap(Type) {
		if (Type != undefined && Type != null) {
			return this.typeResident.filter(x => x.Id == Type)[0].Name;
		}
		else {
			return "";
		}
	}

	openToggleFilter() {
		this.isShowFilter = !this.isShowFilter;
	}

	onDeletes() {
		if (this.lstIdDeletes.length > 0) {
			this.modalDialogService.openDialog(this.viewRef, {
				title: 'Xác nhận',
				settings: {
					footerClass: 'footer-dialog modal-footer'
				},
				childComponent: SimpleModalComponent,
				data: {
					text: "Bạn chắc muốn xóa các yêu cầu đăng ký đã chọn không?"
				},
				actionButtons: [
					{
						text: 'Hủy',
						buttonClass: 'btn btn-default',
					},
					{
						text: 'Xác nhận',
						buttonClass: 'btn btn-success',
						onAction: () => {
							let lstIdFail = [];
							//debugger;
							for (let i = 0; i < this.lstIdDeletes.length; i++) {
								this.http.delete(domainApi + '/serviceOrder/' + this.lstIdDeletes[i])
									.subscribe(
										(res) => {
											if (res["meta"]["error_code"] == 200) {
												if (i == this.lstIdDeletes.length - 1) {
													this.lstIdDeletes = [];
													this.toastSuccess("Xóa thành công!");
													this.listCommon = [];
													this.QueryChanged();
													this.viewRef.clear();
												}
											}
										},
										(err) => {
											lstIdFail.push(this.lstIdDeletes[i]);
											return this.toastWarning(`Xóa yêu cầu dịch vụ kỹ thuật của căn hộ: ${this.lstIdDeletes[i]['apartmentName']} thất bại. ${err["meta"]["error_message"]}`);
										});
							}
						}
					}
				],
			});
		}
	}

}
