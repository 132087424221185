import { RequestCleaningService } from './../../../../../service/request-cleaning.service';
import { NgForm } from '@angular/forms';
import { InfoQuotationDetail, InfoAcceptanceCleaning } from './../../../../../data/Model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-acceptance-request-dialog',
  templateUrl: './acceptance-request-dialog.component.html',
  styleUrls: ['./acceptance-request-dialog.component.scss']
})
export class AcceptanceRequestDialogComponent implements OnInit {
  @ViewChild('fAcceptance') public fAcceptance: NgForm;
  @Output() itemCommon: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: InfoAcceptanceCleaning;
  @Input() idRequestCleaning: number;
  @Input() infoQuotation: Array<InfoQuotationDetail>;
  @Input() isC_One: boolean = false;
  public item: InfoAcceptanceCleaning;
  public isLoading = false;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private readonly requestCleaningService: RequestCleaningService,
  ) {
    this.item = new InfoAcceptanceCleaning();
   }

  ngOnInit() {
    //console.log(this.itemDetail)
    if (this.itemDetail) {
      this.item = {...this.itemDetail};
    }
  }

  onSubmit() {
    if (this.item.dateAcceptance) {
      this.isLoading = true;
      this.requestCleaningService.confirmAcceptanceCleaningRequest(this.idRequestCleaning, this.item.dateAcceptance, this.item.note).subscribe(
        (res) => {
          if (res['meta']['error_code'] == 200) {
            this.toastSuccess("Xác nhận nghiệm thu thành công!");
            this.isLoading = false;
            this.itemDetail.isAcceptance = true;
            this.item.isAcceptance = true;
            this.itemCommon.emit(this.item);

            this.modal.close();
          } else {
            this.isLoading = false;
            this.toastWarning(res['meta']['error_message']);
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastWarning(err);
        }
      )
    }
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }

  omit_special_char(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return true;
    }
    return false;
  }

}
