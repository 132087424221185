import { CleaningNewService } from './../../../../service/cleaning-new.service';
import { InfoUserAssignedCleaning } from '../../../../data/Model';
import { HeaderService } from '../../../../service/header.service';
import { I18n, CustomDatepickerI18n } from '../../../../service/date-picker-i18n';
import { TypeRequestCleaning, lstStatusServiceCleaning, lstStatusPayment, ProcessStatusRequestCleaning, lstHourOfDayServiceCleaning, lstMonthPackageServiceCleaning, lstDateOfWeekServiceCleaning, TypePayment, RequestServiceUserConfirm, lstStatusRequestCleaningConfirmPayment, StatusCleaning, ApiConstant, listPaymentType, FileTypeCleaningEnum } from '../../../../data/const';
import { CommonService } from '../../../../service/common.service';
import { InfoRequestPayment, InfoDatePackageCleaning, InfoAcceptanceCleaning, HistoriesNote, ServiceRate } from '../../../../data/Model';
import { Component, OnInit, ViewContainerRef, ViewChild, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CancelCleaningRequestDialogComponent } from '../dialogs/cancel-cleaning-request-dialog/cancel-cleaning-request-dialog.component';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  cancelBtnLabel = 'Hủy';

  /** A label for the set button */
  setBtnLabel = 'Đặt';
};

@Component({
  selector: 'app-detail-request-cleaning',
  templateUrl: './detail-request-cleaning.component.html',
  styleUrls: ['./detail-request-cleaning.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl }
  ]
})
export class DetailRequestCleaningComponent implements OnInit, OnDestroy {
  @ViewChild('formUpdate') public formUpdate: NgForm;
  public item: any = {};
  public itemRate: ServiceRate;

  public selectedStatus: number = null;
  public isLoading = false;

  public lstProject = [];
  public lstTower = [];
  public lstFloor = [];
  public lstApartment = [];
  public lstStatusRequest = lstStatusServiceCleaning;
  public lstStatusPayment = lstStatusPayment;
  public listPaymentType = listPaymentType;
  public lstStatusConfirmPayment = lstStatusRequestCleaningConfirmPayment;
  public lstHourOfDay = lstHourOfDayServiceCleaning;
  public lstDateOfWeek = lstDateOfWeekServiceCleaning;
  public lstMonthPackage = lstMonthPackageServiceCleaning;
  public typeCleaning = TypeRequestCleaning;
  public typePayment = TypePayment;
  public processStatus = StatusCleaning;
  public statusConfirm = RequestServiceUserConfirm;

  public dateNow = new Date();
  public timeActive: Date;
  public minDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate()
  };
  public maxDate = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 4,
    day: this.dateNow.getDate()
  };

  public listCleaningPackage = [];
  public selectCleaningPackage = null;
  public fileDocumentAcceptance = null;
  public checkDeleteStatus = false;
  public selectedFile: File | null = null;
  public displayDocumentAcceptanceFileSelect = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private readonly commonService: CommonService,
    public modalDialogService: ModalDialogService,
    public viewRef: ViewContainerRef,
    private readonly toastr: ToastrService,
    public titleService: Title,
    private readonly cleaningService: CleaningNewService,
    private readonly headerService: HeaderService,
    private modalService: NgbModal,
    private location: Location,
    private readonly routerUrl: Router,
    private readonly http: HttpClient,
  ) {
    this.item = {};
    this.item.status = 1;
    this.item.estimatePrice = "0";
    this.item.processStatus = ProcessStatusRequestCleaning.SentRequest;
    this.item.createdDate = new Date();
    this.item.infoPayment = new InfoRequestPayment();
    this.item.infoDatePackage = new InfoDatePackageCleaning();
    this.item.historiesNote = new Array<HistoriesNote>();
    this.item.infoAcceptance = new InfoAcceptanceCleaning();
    this.item.infoUserAssigned = new Array<InfoUserAssignedCleaning>();
    this.item.isRequestCms = true;

    this.itemRate = new ServiceRate();
    this.lstStatusRequest.map(s => s['disabled'] = false);
  }

  ngOnInit() {
    this.titleService.setTitle("S Plus - Đăng ký dịch vụ vệ sinh");
    const id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.onDetail(parseInt(id));
    }
  }

  ngOnDestroy() {
    // clearInterval();
    this.headerService.clear();
    this.headerService.showWarning(false);
  }

  onDetail(id: number) {
    this.cleaningService.detailById(id).subscribe(
      (res: any) => {
        if (res) {
          this.item = { ...res };
          this.checkDeleteStatus = [StatusCleaning.SentRequest, StatusCleaning.Cancel].includes(res.Status);
          this.item.isRequestCms = true;
          this.setDataDetail(res);
          this.GetListCleaningPackage(res.Project, res.Tower);
          this.selectCleaningPackage = res.PackageId;
        } else {

          this.modalDialogService.openDialog(this.viewRef, {
            title: 'Thông báo',
            settings: {
              footerClass: 'footer-dialog modal-footer'
            },
            childComponent: SimpleModalComponent,
            data: {
              text: "Yêu cầu đăng ký không tồn tại. Vui lòng kiểm tra lại!"
            },
            actionButtons: [
              {
                text: 'Quay lại',
                buttonClass: 'btn btn-info',
                onAction: () => {
                  this.returnPage();
                }
              },
            ],
          });
        }

      },
      (err) => {
        this.routerUrl.navigate(["**"])
      });
  }

  setDataDetail(item) {
    this.lstProject = [];
    this.lstTower = [];
    this.lstFloor = [];
    this.lstApartment = [];
    if (item.CleaningFiles && item.CleaningFiles.length) {
      const findFileDocumentAcceptance = item.CleaningFiles.find(item => item.Type === FileTypeCleaningEnum.DocumentAcceptance);
      if (findFileDocumentAcceptance) {
        this.fileDocumentAcceptance = findFileDocumentAcceptance.Content;
      }
    }

    if (item.Status === StatusCleaning.Done) {
      this.displayDocumentAcceptanceFileSelect = true;
    }

    if (item.StartTime) {
      this.timeActive = new Date();
      this.timeActive.setHours(parseInt(item.StartTime.slice(0, 2)));
      this.timeActive.setMinutes(parseInt(item.StartTime.substring(3, 5)));
    }

    this.selectedStatus = item.IsPaid === true ? 1 : 0;
    console.log(this.selectedStatus, item.IsPaid);
    this.commonService.getLstProject().subscribe(
      (res) => {
        this.lstProject = res;
        this.item.ProjectId = +item["Project"];
        this.commonService.getLstTower(this.item.ProjectId).subscribe((res) => {
          this.lstTower = res;
          this.item.TowerId = +item["Tower"];

          this.commonService.getLstFloor(this.item.TowerId).subscribe((res) => {
            this.lstFloor = res;
            this.item.FloorId = +item["Floor"];
            this.commonService.getLstApartment(this.item.FloorId).subscribe((res) => {
              this.lstApartment = res;
              this.item.ApartmentId = +item["Apartment"];


              this.lstStatusRequest.map((itemStatus) => {
                itemStatus["disabled"] = (itemStatus.Id < item.processStatus) ? true : false;
              });
            })
          })
        })
      },
      (err) => {
        console.log("Error: connect to API");
      }
    )
  }

  onSubmit() {
    const data = Object.assign({}, this.item);
    this.isLoading = false;
    if (this.timeActive) {
      data.StartTime = new Date(this.timeActive).getHours() + ":" + new Date(this.timeActive).getMinutes();
    }
    
    let cleaningFiles = data.CleaningFiles;
    const formData = new FormData();
    formData.append('PackageId', this.selectCleaningPackage);
    formData.append('StartDay', data.StartDay);
    formData.append('StartTime', data.StartTime);
    if (data.TotalPrice)
      formData.append('TotalPrice', data.TotalPrice);
    if (data.PaymentMethod)
      formData.append('PaymentMethod', data.PaymentMethod);
    if (this.selectedStatus !== null)
      formData.append('IsPaid', this.selectedStatus as any);
    if (data.Note)
      formData.append('Note', data.Note);
    if (data.Status)
      formData.append('Status', data.Status);
    if (this.selectedFile) {
      formData.append('acceptanceFiles', this.selectedFile);
      if (cleaningFiles) {
        cleaningFiles = cleaningFiles.filter(item => item.Type !== FileTypeCleaningEnum.DocumentAcceptance);
      }
    }
    formData.append('TechnicalFiles', cleaningFiles.map(item => item.Id));
    this.cleaningService.updateCleaningRequest(this.item.Id, formData).subscribe(
      (res) => {
        this.toastSuccess("Cập nhật thành công");
        this.isLoading = false;
        this.routerUrl.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.routerUrl.navigate([`/manager-service/cleaning-service`]);
        });
      },
      (err) => {
        this.isLoading = false;
        this.toastWarning(err);
      }
    )
  }

  GetListCleaningPackage(projectId, towerId) {
    this.http.get(ApiConstant.getListCleaningPackage + '?page=1&pageSize=-1' +
      '&Project=' + projectId + '&Tower=' + towerId).subscribe(
        (res) => {
          this.listCleaningPackage = res["data"].map(item => {
            return {
              ...item,
            }
          });
        },
        (err) => {
          console.log("Error: connect to API");
        });
  }

  openModelCancel() {
    const dialofRefCancel = this.modalService.open(CancelCleaningRequestDialogComponent, { size: 'lg' });
    dialofRefCancel.componentInstance.id = this.item.id;

    dialofRefCancel.componentInstance.response.subscribe((res) => {
      if (res) {
        this.routerUrl.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.routerUrl.navigate([`/manager-service/cleaning-service`]);
        });
      }
    });
  }

  onChangeStatus(event: any) {
    this.item.Status = event;
    if (event === StatusCleaning.Done) {
      this.displayDocumentAcceptanceFileSelect = true;
    } else {
      this.displayDocumentAcceptanceFileSelect = false;
    }
  }

  onChangePrice(value) {
    this.item.TotalPrice = value.replace(/[^0-9.-]+/g, '');
  }

  onChangeOmitSpecialTime(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 58) {
      return false;
    }
    return true;
  }

  onDownloadFile(base64String: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = (`bien_ban_nghiem_thu_${+new Date()}.pdf`).toString();
    a.click();
    window.URL.revokeObjectURL(url);
  }

  onDeleteItem(Id) {
    this.modalDialogService.openDialog(this.viewRef, {
      title: 'Xác nhận',
      settings: {
        footerClass: 'footer-dialog modal-footer'
      },
      childComponent: SimpleModalComponent,
      data: {
        text: "Bạn có chắc chắn muốn xóa mục này?"
      },
      actionButtons: [
        {
          text: 'Đồng ý',
          buttonClass: 'btn btn-success',
          onAction: () => {
            this.isLoading = true;
            this.http.delete(`${ApiConstant.deleteCleaning}/${Id}`).subscribe(
              (res) => {
                this.toastSuccess('Xoá bản ghi thành công');
                setTimeout(() => {
                  this.isLoading = false;
                  this.routerUrl.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.routerUrl.navigate([`/manager-service/cleaning-service`]);
                  });
                }, 2000);
              },
              (err) => {
                this.isLoading = false;
                console.log("Error: connect to API");
              });
          }
        },
        {
          text: 'Đóng',
          buttonClass: 'btn btn-default',

        }
      ],
    });
  }

  onFileChanged(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type === 'application/pdf') {
        this.selectedFile = file;
      } else {
        this.toastWarning('Biên bản nghiệm thu là file PDF.');
        this.selectedFile = null;
      }
    }
  }

  onSelectFile() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  returnPage() {
    this.location.back();
  }

  toastWarning(msg): void {
    this.toastr.warning(msg, 'Cảnh báo');
  }
  //Toast thành công
  toastSuccess(msg): void {
    this.toastr.success(msg, 'Hoàn thành');
  }
  //Toast thành công
  toastError(msg): void {
    this.toastr.error(msg, 'Lỗi');
  }
}
