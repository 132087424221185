import { registerConstructionDoc } from '../../../../data/Model';
import { domain, domainImage, registerConstructionDocStatus, TypeRegisterConstructionDoc, domainFile } from './../../../../data/const';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Gallery } from 'angular-gallery';

@Component({
  selector: 'app-register-construction-doc',
  templateUrl: './register-construction-doc.component.html',
  styleUrls: ['./register-construction-doc.component.scss']
})
export class RegisterConstructionDocComponent implements OnInit {
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter();
  @Input() itemDetail: registerConstructionDoc;
  @Input() hideBtnSave: registerConstructionDoc;
  @ViewChild('file') file: ElementRef;

  public domainImage = domainImage;
  public item: registerConstructionDoc;
  public typeRegisterConstructionDoc = TypeRegisterConstructionDoc;
  public registerConstructionDocStatus = registerConstructionDocStatus;
  public isKSTDKTC = localStorage.getItem("roleCode") == "KSTDKTC" ? true : false;
  public isANDKTC = localStorage.getItem("roleCode") == "ANDKTC" ? true : false;
  public domainFile = domainFile;
  public statusItem: number;

  constructor(
    public modal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private gallery: Gallery
  ) {
    this.item = new registerConstructionDoc();
    this.item.objectFiles = [];
    this.item.RegisterConstructionDocStatus = 1;
   }

  ngOnInit() {
    if (this.itemDetail) {
      this.item = this.itemDetail;
      this.statusItem = this.item.RegisterConstructionDocStatus
    }
  }

  onSubmit(){
    if (this.item.Name == "" || this.item.Name == undefined) {
      this.toastWarning("Vui lòng nhập Tên hồ sơ!");
      return;
    } else if(!this.item.Type) {
      this.toastWarning("Vui lòng chọn Loại hồ sơ!");
      return;
    }
    else if(!this.item.Quantity || this.item.Quantity < 1) {
      this.toastWarning("Vui lòng nhập Số lượng!");
      return;
    }
    // else if (!this.item.objectFiles) {
    //   this.toastWarning("Chưa có file đính kèm");
    //   return;
    // } else if (this.item.objectFiles.length == 0) {
    //     this.toastWarning("Chưa có file đính kèm");
    //     return;
    // }

    if(!this.item.RegisterConstructionDocStatus) {
      this.toastWarning("Vui lòng chọn Trạng thái hồ sơ!");
      return;
    }

    if(this.item.RegisterConstructionDocStatus == 2) {
      if(this.item.Reason == undefined || this.item.Reason == "") {
        this.toastWarning("Vui lòng nhập Lý do Không phê duyệt!");
        return;
      }
      else if(this.item.Reason.trim() == "") {
        this.toastWarning("Vui lòng nhập Lý do Không phê duyệt!");
        return;
      }
    }

    this.eventEmitter.emit(this.item);
      this.modal.close();
  }

  onCheckboxChanged(value){

  }

  upload(files){
    if (files.length === 0)
			return;

		const formData = new FormData();

		for (let file of files)
			formData.append(file.name, file);

		const uploadReq = new HttpRequest('POST', domain + 'api/cms/Upload/uploadMultiFile', formData, {
			reportProgress: true,
		});

		this.http.request(uploadReq).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {

			}
			else if (event.type === HttpEventType.Response) {
        if (event.body["data"].length) {
          event.body["data"].forEach(element => {
            this.item.objectFiles.unshift({
              FileId: 0,
              FileRef: '',
              Url: element.toString()
            });
          });
        }
				if (this.file) this.file.nativeElement.value = "";
			}
		});
  }

  omit_special_char(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  onRemoveFile(item) {
    this.item.objectFiles = [...this.item.objectFiles.filter(s => s != item)];
  }

  onShowGallery(index: number) {
    let lstImage = [];

    this.item.objectFiles.forEach(item => {
    lstImage.push({path: domainImage + item.url})
  })

  let prop = {
      images: lstImage,
      index
  };
  // this.gallery.load(prop);
}

  toastWarning(msg): void {
		this.toastr.warning(msg, 'Cảnh báo');
	}
	//Toast thành công
	toastSuccess(msg): void {
		this.toastr.success(msg, 'Hoàn thành');
	}
	//Toast thành công
	toastError(msg): void {
		this.toastr.error(msg, 'Lỗi');
  }
}
